import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "date-fns/locale/nl";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { alpha } from "@mui/material/styles";
import RBCToolbar from "./Components/CustomToolbar";
import PreviewPageToolbar from "./Components/PreviewPageToolbar";
import AgendaListView from "./Components/AgendaListView";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/nl";
import {
  AgendaAppointmentParticipantStatus,
  tabsViewTypes,
  viewTypes,
} from "../../_helpers";
import CustomEvent from "./Components/CustomEvent";
import CustomEventPopup from "./Components/CustomEventPopup";
import clsx from "clsx";
import { useSelector } from "react-redux";

export default function FullCalendar({
  filteredEvents,
  filters,
  handleFilters,
  getAgendaAppointments,
  loading,
  activeStep,
}) {
  const [view, setView] = useState();
  const [datesByView, setDatesByView] = useState({});
  const [showMoreEvents, setShowMoreEvents] = useState();
  const { all } = useSelector((state) => state.buildings);
  const onView = useCallback(
    (newView) => {
      setDatesByView((v) => ({
        ...v,
        [tabsViewTypes[view]]: filters.startDate,
      }));
      setView(viewTypes[newView]);
    },
    [view, filters.startDate]
  );

  const classes = useStyles({ view, activeStep });
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const localizer = momentLocalizer(moment);
  const { defaultDate, tooltipAccessor, scrollToTime, formats } = useMemo(
    () => ({
      defaultDate: new Date(filters.startDate),
      tooltipAccessor: (e) => {
        const buildingId =
          e.participants && e.participants.length
            ? e.participants[0].buildingId
            : null;
        const building = all.find(
          (p) =>
            (!buildingId || p.buildingId === buildingId) &&
            p.projectId === e.projectId
        );
        const participants = e.participant
          ? e.participants.map((x) => x.name).join(",")
          : "";
        const address = e.address
          ? typeof e.address === "string"
            ? e.address
            : `${e.address.street} ${e.address.houseNo} ${
                e.address.houseNoAddition || ""
              } ${e.address.postcode} ${e.address.place}`
          : "";
        return [
          buildingId && building && building.buildingNoIntern,
          e.title,
          participants,
          address,
        ]
          .filter(Boolean)
          .join(",");
      },
      formats: {
        weekdayFormat: (date, culture, localizer) =>
          localizer.format(date, "dddd", culture),
        dayFormat: (date, culture, localizer) =>
          localizer.format(date, "dd D", culture),
        timeGutterFormat: (date, culture, localizer) =>
          localizer.format(date, "HH:mm", culture),
      },
    }),
    [filters.startDate]
  );

  useEffect(() => {
    const start = datesByView[tabsViewTypes[view]] || filters.startDate;
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(start).format("YYYY-MM-DD");
    switch (tabsViewTypes[view]) {
      case Views.WEEK: {
        startDate = moment(start).startOf("week").format("YYYY-MM-DD");
        endDate = moment(start).endOf("week").format("YYYY-MM-DD");
        break;
      }
      case Views.MONTH: {
        startDate = moment(start).startOf("month").format("YYYY-MM-DD");
        endDate = moment(start).endOf("month").format("YYYY-MM-DD");
        break;
      }
      default: {
      }
    }
    if (tabsViewTypes[view] !== Views.AGENDA) {
      handleFilters({ startDate, endDate });
    }
  }, [view]);

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    let className = "availableSlot";
    if (!event.published) className = "draftSlot";
    else if (!event.participants) className = "availableSlot";
    else if (
      event.participants.some(
        (p) => p.status === AgendaAppointmentParticipantStatus.Confirmed
      )
    )
      className = "eventSuccess";
    else if (
      event.participants.some(
        (p) => p.status === AgendaAppointmentParticipantStatus.Cancelled
      )
    )
      className = "eventCancel";
    return { className };
  }, []);
  const onDrillDown = useCallback((newDate) => {}, []);
  return (
    <div
      className={clsx(
        classes.calendarContainer,
        activeStep && classes.containerActiveStep,
        !activeStep && classes.calendarDeactivateStep,
        view === "day" && classes.calendarViewType
      )}
    >
      {view !== Views.AGENDA && loading && (
        <div className={classes.loading}>
          <CircularProgress size={22} />
        </div>
      )}
      <Calendar
        culture={i18n.language}
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        date={defaultDate}
        defaultDate={defaultDate}
        defaultView={tabsViewTypes[view]}
        onView={onView}
        onDrillDown={onDrillDown}
        formats={formats}
        eventPropGetter={eventPropGetter}
        selectable
        onShowMore={(events, date) => setShowMoreEvents({ events, date })}
        scrollToTime={scrollToTime}
        activeStep={activeStep}
        getAgendaAppointments={getAgendaAppointments}
        loading={loading}
        filters={filters}
        min={new Date(1972, 0, 1, 8, 0, 0, 0)}
        tooltipAccessor={tooltipAccessor}
        components={{
          toolbar: activeStep ? PreviewPageToolbar : RBCToolbar,
          event: (props) => (
            <CustomEvent {...props} view={tabsViewTypes[view]} />
          ),
        }}
        popup={false}
        views={{
          week: true,
          month: true,
          day: true,
          agenda: AgendaListView,
        }}
        messages={{
          agenda: t("agenda.title"),
          showMore: (target) => (
            <CustomEventPopup showMoreEvents={showMoreEvents} target={target} />
          ),
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2.4),
  },
  loading: {
    position: "absolute",
    left: "50%",
    top: "50%",
  },
  calendarDeactivateStep: {
    "& .rbc-month-view": {
      border: "none",
    },
  },
  containerActiveStep: {
    boxShadow: "none !important",
    [theme.breakpoints.only("xs")]: {
      height: "100% !important",
    },
    "& .rbc-month-view": {
      padding: theme.spacing(2),
    },
    "& .rbc-time-view": {
      padding: theme.spacing(2),
    },
  },
  calendarViewType: {
    "& .rbc-time-view .rbc-time-content > * + * > *": {
      borderLeft: "none",
    },
  },
  calendarContainer: {
    position: "relative",
    height: "100%",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: 6,
    [theme.breakpoints.only("xs")]: {
      height: "calc(100% - 318px)",
    },

    "& .rbc-header": {
      borderBottom: "none",
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.grey[900],
    },

    "& .rbc-month-view": {
      borderTop: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
      alignSelf: "center",
      width: "100%",
    },
    "& .rbc-time-view": {
      alignSelf: "center",
      width: "100%",
    },

    "& .rbc-day-bg + .rbc-day-bg": {
      borderLeft: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-date-cell": {
      paddingRight: 0,
      textAlign: "center",
      paddingTop: theme.spacing(1.3),
      paddingBottom: theme.spacing(1.3),
      color: theme.palette.grey[400],
      fontSize: 12,
    },

    "& .rbc-date-cell.rbc-now.rbc-current > .rbc-button-link": {
      color: theme.palette.common.white,
    },

    "& .rbc-today": {
      backgroundColor: "transparent",
    },

    "& .rbc-current": {
      position: "relative",
    },

    "& .rbc-date-cell.rbc-now.rbc-current::before": {
      content: '""',
      display: "block",
      height: 28,
      width: 28,
      background: theme.palette.primary.main,
      position: "absolute",
      zIndex: -1,
      top: 4,
      left: 0,
      right: 0,
      margin: "auto",
      borderRadius: 50,
    },

    "& .rbc-off-range-bg": {
      background: alpha(theme.palette.grey[300], 0.2),
    },

    "& .rbc-time-view .rbc-time-content > * + * > *": {
      borderLeft: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-time-view .rbc-header + .rbc-time-view .rbc-header": {
      borderLeft: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-time-view .rbc-day-slot .rbc-time-slot": {
      borderTop: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-time-view .rbc-day-bg + .rbc-time-view .rbc-day-bg": {
      borderLeft: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-time-view .rbc-timeslot-group": {
      borderBottom: "none",
    },

    "& .rbc-time-view .rbc-time-view": {
      border: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
    },

    "& .rbc-time-view .rbc-time-content": {
      borderTop: `2px solid ${alpha(theme.palette.grey[600], 0.17)}`,
      overflow: "auto",
    },

    "& .rbc-time-view .rbc-header": {
      paddingTop: theme.spacing(1.3),
      borderBottom: "none",
    },

    "& .rbc-time-view .rbc-header span": {
      fontSize: 16,
      fontWeight: 500,
    },

    "& .rbc-time-view .rbc-time-view .rbc-row": {
      minHeight: 55,
    },

    "& .rbc-time-view .rbc-row": {
      minHeight: 0,
    },

    "& .rbc-time-view .rbc-row div": {
      height: "40px !important",
    },

    "& .rbc-allday-cell": {
      display: "none",
    },

    "& .rbc-time-view .rbc-label": {
      color: theme.palette.grey[400],
      fontWeight: "normal",
      fontSize: 16,
    },
    "& .rbc-timeslot-group": {
      minHeight: 86,
    },
    "& .rbc-event": {
      borderRadius: 6,
    },
    "& .rbc-event-label": {
      display: "none",
    },
    "& .eventSuccess": {
      backgroundColor: theme.palette.green.light,
      border: `1px solid ${theme.palette.green.dark}`,
    },
    "& .eventCancel": {
      backgroundColor: theme.palette.red.light,
      border: `1px solid ${theme.palette.red.dark}`,
    },
    "& .availableSlot": {
      backgroundColor: theme.palette.indigo.light,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .draftSlot": {
      backgroundColor: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[900]}`,
    },
    "& .rbc-time-slot": {
      paddingTop: theme.spacing(1.4),
    },

    "& .rbc-time-header .rbc-header.rbc-today": {
      borderTop: `4px solid ${theme.palette.primary.main}`,
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    "& .rbc-show-more": {
      color: theme.palette.grey[900],
      fontSize: 12,
      fontWeight: "bold",
    },
  },
}));
