import axios from "axios";
import { URLS } from "./urls";

export const getLoginRequestDetails = (loginRequestId) => axios.get(URLS.GET_LOGIN_REQUEST_DETAILS + loginRequestId);
export const validateLoginRequestObjectAddress = (data) => axios.post(URLS.VALIDATE_LOGIN_REQUEST_OBJECT_ADDRESS, data)
export const addLoginRequest = (data) => axios.post(URLS.ADD_LOGIN_REQUEST, data);
export const getLoginRequests = (data) => axios.post(URLS.GET_LOGIN_REQUESTS, data);
export const getLoginRequestNavigationInfo = (loginRequestId) => axios.get(URLS.GET_LOGIN_REQUEST_NAVIGATION_INFO + loginRequestId);
export const validateLoginRequestEmail = (data) => axios.post(URLS.VALIDATE_LOGIN_REQUEST_EMAIL, data)
export const approveOrRejectLoginRequest = (data) => axios.post(URLS.APPROVE_OR_REJECT_LOGIN_REQUEST, data)
export const updateLoginRequestObject = (data) => axios.post(URLS.UPDATE_LOGIN_REQUEST_OBJECT, data)
export const updateLoginRequestStatus = (loginRequestId) => axios.patch(URLS.UPDATE_LOGIN_REQUEST_STATUS + loginRequestId)

