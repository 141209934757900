import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getAllTemplates } from '../../../apis/contractApis'
import Loader from './Loader'
import { useTranslation } from 'react-i18next'

const Template = ({contractDetails, updateContractDetails , loadingKey, isDisabledEditing}) => {

  const [templates, setTemplates] = useState({loading:false, data:[], selectedtemplate:undefined})
  const {t} = useTranslation()

  useEffect(()=>{
    (async ()=>{
    try{
      setTemplates({loading:true, data:[]})
      const response = await getAllTemplates();
        setTemplates({loading:false, data: response.data.map((item)=>({label: item?.name , value:item})) , selectedtemplate:undefined})
    }catch(error){
      console.log(error)
      setTemplates({loading:false, data:[], selectedtemplate:undefined})
    }
    })()
  },[])

  return (
    <Stack mt={3}>
                <Typography mb={1.5} variant='subtitle1'>{t("general.template")}</Typography>
               {loadingKey === "template" ? <Loader /> :  <Autocomplete
                disablePortal
                size="small"
                id="template"
                options={templates.data}
                freeSolo
                renderInput={(params) => <TextField {...params}  fullWidth
                    label={t("contract.details.template.name")}
                    type="text"
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                     />
              }
              value={[templates.data?.find((item) => item.value?.id?.toLowerCase() === contractDetails?.templateId?.toLowerCase())?.label ]}
              onChange={(event, newValue) => {
                updateContractDetails("template" , newValue?.value?.id , "templateId" )
              }}
              loading={templates?.loading}
              disabled={isDisabledEditing}
            />
            }
               
              </Stack>
  )
}

export default Template