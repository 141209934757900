import {
  alpha,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Slide,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { CalendarTodayOutlined, ChevronLeft, ChevronRight, Close, KeyboardArrowDown } from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import { groupBy, history } from "../../../_helpers";
import { CalendarContext } from "../Agenda";
import { DatePicker } from "@mui/x-date-pickers";

function RBCToolbar({ onView, date, onNavigate }) {
  const [tabView, setTabView] = useState("month");
  const classes = useStyles()
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleFilters, events, updateView, getView, isBuyer, filters } = useContext(CalendarContext);
  const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
  const matchWidthDownSm = useMediaQuery(theme.breakpoints.down('md'));
  const matchWidthUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [activeTab, setActiveTab] = useState();
  const [filterPopover, setFilterPopover] = useState(false)
  const [filterButtonRef, setFilterButtonRef] = useState(null)
  const [openFilter, setOpenFilter] = useState(false);
  const [hideDateSmallView, setHideDateSmallView] = useState(false);

  useEffect(() => {
    getView().then(p => setActiveTab(p.data || 0));
  }, [])

  useEffect(() => {
    if (openFilter && matchWidthDownSm) {
      setHideDateSmallView(true)
    } else {
      setHideDateSmallView(false)
    }
  }, [matchWidthDownSm, matchWidthUpSm])

  const resetFilters = () => {
    setOpenFilter(a => !a);
    if (matchWidthDownSm) setHideDateSmallView(a => !a);
    handleFilters({ query: '' });
  };

  const handleSearchChange = ({ target: { value } }) => {
    handleFilters({ query: value });
  };

  const filterPopoverHandler = (e) => {
    setFilterButtonRef(e.currentTarget)
    setFilterPopover(!filterPopover)
  }

  const handleFilterChange = (e) => {
    handleFilters({ [e.target.name]: e.target.value })
  }

  const selectFilterOptions = (key) => {
    const groupedArray = groupBy(events, key)
    const filteredArray = [{ value: '', label: t('general.all') }]
    Object.keys(groupedArray).forEach(p => filteredArray.push({ value: p, label: p }))
    return filteredArray
  }

  const filterPopoverData = [
    { name: 'category', title: t('general.category'), option: selectFilterOptions('categoryTitle') },
    {
      name: 'appointmentLocation', title: t('agenda.detailModal.modeOfMeeting'), option: [
        { value: '', label: t('general.all') },
        { value: 1, label: t('agenda.meetingMode.1') },
        { value: 0, label: t('agenda.meetingMode.0') }
      ]
    },
    {
      name: 'type', title: t('general.type'), option: [{ value: '', label: t('general.all') },
      { label: t('agenda.appointmentType.0'), value: 0 },
      { label: t('agenda.appointmentType.1'), value: 1 },
      { label: t('agenda.appointmentType.2'), value: 2 }]
    },
    { name: 'title', title: t('general.subject'), option: selectFilterOptions('title') },
  ]

  useEffect(() => {
    if (matchWidthDownXs) {
      goToDayView()
    } else if (activeTab !== undefined) {
      tabsHandleChange('', activeTab)
    }
  }, [matchWidthDownXs, activeTab])



  const tabsHandleChange = (event, newValue) => {
    if (newValue === 0) {
      goToDayView()
    } else if (newValue === 1) {
      goToWeekView()
    } else if (newValue === 2) {
      goToMonthView()
    } else if (newValue === 3) {
      goToAgendaView()
    }
    setActiveTab(newValue);
    updateView(newValue);
  };

  const goToDayView = () => {
    onView("day");
    setTabView("day");
  };
  const goToWeekView = () => {
    onView("week");
    setTabView("week");
  };
  const goToMonthView = () => {
    onView("month");
    setTabView("month");
  };
  const goToAgendaView = () => {
    onView("agenda");
    setTabView("agenda");
  };

  const goToPreviousDate = () => {
    let view = tabView;

    let newDate;
    if (view === "month") {
      newDate = moment(date).add(-1, 'month').startOf('month')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('month').format('YYYY-MM-DD')
      })
    } else if (view === "week") {
      newDate = moment(date).add(-1, 'week').startOf('week')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('week').format('YYYY-MM-DD')
      })
    } else {
      newDate = moment(date).add(-1, 'day').startOf('day')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('day').format('YYYY-MM-DD')
      })
    }
    onNavigate("prev", newDate.toDate());
  };

  const goToNextDate = () => {
    let view = tabView;

    let newDate;
    if (view === "month") {
      newDate = moment(date).add(1, 'month').startOf('month');
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('month').format('YYYY-MM-DD')
      })
    } else if (view === "week") {
      newDate = moment(date).add(1, 'week').startOf('week');
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('week').format('YYYY-MM-DD')
      })
    } else {
      newDate = moment(date).add(1, 'day').startOf('day')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('day').format('YYYY-MM-DD')
      })
    }
    onNavigate("next", newDate);
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

  const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 13,
      '&:focus': {
        opacity: 1,
      },
    },
  }))((props) => <Tab disableRipple {...props} />);

  const startOfWeek = moment(date).startOf('week').format(!matchWidthDownSm ? "DD MMMM YYYY" : "DD-MM-YY");
  const endOfWeek = moment(date).endOf('week').format(!matchWidthDownSm ? "DD MMMM YYYY" : "DD-MM-YY");
  const monthLabel = moment(date).format("MMMM");
  const open = Boolean(filterPopover);

  const createEventHandler = () => {
    history.push('/agenda/maak')
  }

  const filterOpenHandler = () => {
    setOpenFilter(true)
    if (matchWidthDownSm)
      setHideDateSmallView(true)
  }
  return (
    <>
      <Hidden only={["xs", "xl"]}>
        <div className={clsx(classes.toolbarTabsMainContainer, classes.toolbarTabsMdDownContainer)}>
          <div className={classes.toolbarTabsContainer}>
            <StyledTabs value={activeTab || 0} onChange={tabsHandleChange} aria-label="styledTabs">
              <StyledTab label={t("agenda.toolbar.tab.day")} />
              <StyledTab label={t("agenda.toolbar.tab.week")} />
              <StyledTab label={t("agenda.toolbar.tab.months")} />
              <StyledTab label={t("agenda.toolbar.tab.list")} />
            </StyledTabs>
            <Typography className={classes.padding} />
          </div>
        </div>
      </Hidden>
      <div className={clsx(classes.toolbarContainer, hideDateSmallView && classes.alignToolbarContainer)}>
        {!hideDateSmallView && (
          <div className={clsx(classes.toolbarMonth, classes.toolbarMainContainer)}>
            {activeTab !== 3 && (
              <div className={classes.toolbar_btn_group}>
                <div className={classes.squareButton} onClick={goToPreviousDate}>
                  <ChevronLeft className={classes.chevronIcon} />
                </div>
                <div className={classes.squareButton} onClick={goToNextDate}>
                  <ChevronRight className={classes.chevronIcon} />
                </div>
              </div>
            )}
            {(activeTab === 0 || activeTab === 3) && (
              <>
                {activeTab === 0 ? (
                  <DatePicker
                    label={t("general.end")}
                    format="dd-MM-yyyy"
                    variant="standard"
                    id="date-picker"
                    value={date ? new Date(date) : date}
                    onChange={(date) => {
                      handleFilters({
                        startDate: moment(date).format("YYYY-MM-DD"),
                        endDate: moment(date).clone().endOf("day").format("YYYY-MM-DD"),
                      })
                    }}
                    autoOk
                    ampm={false}
                    slotProps={{
                      openPickerIcon: { className: classes.toolbarCalendarIcon },
                      openPickerButton: { edge: "end", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "0",
                        style: { width: "0", marginRight: "48px", marginBottom: "16px" },
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: CalendarTodayOutlined,
                    }}
                  />
                ) : (
                  <IconButton size="large">
                    <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                  </IconButton>
                )}
              </>
            )}

            {activeTab === 0 && (
              <Typography className={classes.toolbarDay}>
                {moment(date).format("dddd")}
                <span className={classes.toolbarFullDate}>{moment(date).format("D MMMM YYYY")}</span>
              </Typography>
            )}
            {activeTab === 3 && (
              <Typography className={classes.toolbarDay}>
                {moment(date).format("dddd")}
                <span className={classes.toolbarFullDate}>{moment(date).format("D MMMM YYYY")}</span>
              </Typography>
            )}
            {activeTab === 1 && (
              <>
                <IconButton size="large">
                  <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                </IconButton>
                <Typography className={classes.toolbarFullDate}>
                  {startOfWeek} - {endOfWeek}
                </Typography>
              </>
            )}
            {activeTab === 2 && (
              <>
                <IconButton size="large">
                  <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                </IconButton>
                <Typography className={classes.toolbarCalendarMonthText}>{monthLabel}</Typography>
              </>
            )}
          </div>
        )}
        <Hidden mdDown>
          {!openFilter && (
            <div className={classes.toolbarTabsMainContainer}>
              <div className={classes.toolbarTabsContainer}>
                <StyledTabs value={activeTab || 0} onChange={tabsHandleChange} aria-label="styledTabs">
                  <StyledTab label={t("agenda.toolbar.tab.day")} />
                  <StyledTab label={t("agenda.toolbar.tab.week")} />
                  <StyledTab label={t("agenda.toolbar.tab.months")} />
                  <StyledTab label={t("agenda.toolbar.tab.list")} />
                </StyledTabs>
                <Typography className={classes.padding} />
              </div>
            </div>
          )}
        </Hidden>
        <div className={clsx(classes.toolbarMonth, classes.customToolbarMonths)}>
          {activeTab !== 3 && (
            <div className={clsx({ [classes.toolbarSearch]: openFilter })}>
              {openFilter && (
                <Slide direction="right" mountOnEnter unmountOnExit in={openFilter}>
                  <InputBase
                    placeholder={t("general.search") + "..."}
                    value={filters.query}
                    onChange={handleSearchChange}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Slide>
              )}
              <IconButton color="primary" onClick={() => (openFilter ? resetFilters() : filterOpenHandler())} size="large">
                {openFilter ? <Close /> : <SearchIcon className={classes.searchIcon} />}
              </IconButton>
            </div>
          )}
          {!isBuyer && (
            <div className={classes.squareButton} onClick={createEventHandler}>
              <AddOutlinedIcon className={classes.chevronIcon} />
            </div>
          )}
          {activeTab !== 3 && (
            <div className={classes.squareButton} aria-describedby={"toolbar-filter"} onClick={filterPopoverHandler}>
              <FilterListIcon className={classes.chevronIcon} />
            </div>
          )}
        </div>
        <Popover
          id="toolbar-filter"
          open={open}
          anchorEl={filterButtonRef}
          onClose={filterPopoverHandler}
          classes={{ root: classes.ctmPopoverMainContainer }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.ctmPopover}>
            <div className={classes.ctmHeaderTex}>
              <Typography className={classes.ctmPopoverLabel}>{t("datatable.label.filter.title")}</Typography>
              <Typography
                onClick={() =>
                  handleFilters({
                    startDate: moment().format("YYYY-MM-DD"),
                    endDate: moment().format("YYYY-MM-DD"),
                    query: "",
                    category: "",
                    title: "",
                    appointmentLocation: "",
                    type: "",
                  })
                }
                className={classes.ctmPopoverLabelRest}
              >
                {t("datatable.label.filter.reset")}
              </Typography>
            </div>
            <CancelIcon className={classes.ctmPopoverIcon} onClick={filterPopoverHandler} />
          </div>
          <hr />
          <Grid container spacing={2}>
            {filterPopoverData.map((p, index) => {
              return (
                <Grid item xs={6}>
                  <FormControl sx={{ m: 1}} className={classes.ctnPopoverFormControl}>
                    <InputLabel className={classes.ctnPopoverFormControlLabel} shrink id={p.name}>
                      {p.title}
                    </InputLabel>
                    <Select
                      labelId={p.name}
                      id={index}
                      name={p.name}
                      classes={{
                        root: classes.ctmSelectDropDown,
                        icon: classes.ctmSelectIcon,
                      }}
                      IconComponent={(props) => <KeyboardArrowDown {...props} />}
                      value={filters[p.name]}
                      onChange={handleFilterChange}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      {p.option.map((x, index) => {
                        return (
                          <MenuItem value={x.value} key={index}>
                            {x.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )
            })}
          </Grid>
        </Popover>
      </div>
    </>
  )
}

export default RBCToolbar;

const useStyles = makeStyles((theme) => ({
  ctmSelectIcon: {
    height: 22,
    width: 22,
    background: theme.palette.grey[100],
    borderRadius: 50,
    fill: theme.palette.grey[900],
    right: theme.spacing(1.2)
  },
  ctnPopoverFormControl: {
    width: "100%"
  },
  ctnPopoverFormControlLabel: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  ctmPopoverIcon: {
    fill: theme.palette.grey[700],
    fontSize: 28,
    cursor: 'pointer'
  },
  ctmPopoverLabel: {
    fontSize: 16,
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    paddingRight: theme.spacing(1.2)
  },
  ctmPopoverLabelRest: {
    fontSize: 16,
    color: alpha(theme.palette.primary.main, 0.80),
    fontWeight: 'bold'
  },
  ctmPopover: {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between'
  },
  ctmHeaderTex: {
    display: 'flex',
  },
  ctmPopoverMainContainer: {
    "& label + .MuiInput-formControl": {
      marginTop: theme.spacing(2.5)
    },
    "& .MuiPopover-paper": {
      width: "100%",
      boxShadow: theme.shadows[1],
      background: theme.palette.common.white,
      borderRadius: 6,
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 510,
      },
    },
    "& .MuiInputBase-root": {
      height: 42,
      background: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      borderRadius: 6,
      "&:before": {
        borderBottom: 'none !important'
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: 'none !important'
    },
    "& .MuiSelect-select:focus": {
      borderRadius: 0,
      backgroundColor: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.grey[700],
      fontSize: 14
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.grey[700],
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingLeft: theme.spacing(1.3),
      paddingRight: theme.spacing(4.6),
      fontSize: 14
    },
  },
  toolbarMainContainer: {
    [theme.breakpoints.only("xs")]: {
      display: 'none !important'
    },
  },
  toolbarTabsMainContainer: {
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
    [theme.breakpoints.up("xl")]: {
      position: 'absolute',
      left: "50%",
      transform: "translate(-50%, 0)",
    },
  },
  toolbarTabsMdDownContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  alignToolbarContainer: {
    marginLeft: "auto !important"
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2.1),
    margin: theme.spacing(2, 0),
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 2),
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.only("md")]: {
      padding: theme.spacing(0, 1),
    },
  },
  toolbarCalendarIcon: {
    width: 18,
    fill: theme.palette.primary.main,
  },
  toolbarCalendarMonthText: {
    fontSize: 17,
    fontWeight: 500,
    color: theme.palette.grey[900],
    alignSelf: 'center',
  },
  toolbarMonth: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      justifyContent: 'flex-end'
    },
    "& .MuiIconButton-root": {
      padding: `${theme.spacing(1.5, 1)} !important`,
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(0, 1)} !important`,
      },
      [theme.breakpoints.only("md")]: {
        padding: theme.spacing(1.5, 0.7)
      },
    },
  },
  toolbar_btn_group: {
    display: 'flex',
    [theme.breakpoints.only("md")]: {
      marginLeft: theme.spacing(0),
    },
  },
  squareButton: {
    height: 30,
    width: 30,
    minHeight: 30,
    minWidth: 30,
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    margin: theme.spacing(0, 0.5),
    cursor: 'pointer'
  },
  chevronIcon: {
    fill: theme.palette.common.white,
    fontSize: 18
  },
  toolbarTabsContainer: {
    borderRadius: 50,
    height: 36,
    background: alpha(theme.palette.primary.main, 0.05),
    [theme.breakpoints.down('md')]: {
      width: 'fit-content'
    },
    "& .Mui-selected": {
      background: theme.palette.primary.main,
      borderRadius: 50,
      margin: theme.spacing(0.9, 1.2),
      fontWeight: '500 !important',
      color: `${theme.palette.common.white} !important`
    },
    "& .MuiTab-root": {
      minHeight: 22,
      padding: 0,
      minWidth: 70,
      fontWeight: 'normal',
      color: theme.palette.grey[600]
    },
  },
  toolbarSearch: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    background: alpha(theme.palette.primary.main, 0.05),
    paddingLeft: theme.spacing(2),
    borderRadius: 50,
    maxWidth: 165,
    height: 36,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      maxWidth: "100%",
      justifyContent: 'flex-end',
      "& .MuiInputBase-root": {
        width: "100%"
      },
    },
  },
  searchIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18
  },
  inputInput: {
    fontSize: 13
  },
  toolbarDay: {
    fontSize: 17,
    color: theme.palette.grey[500],
  },
  toolbarFullDate: {
    fontSize: 17,
    color: theme.palette.grey[900],
    fontWeight: 500,
    paddingLeft: theme.spacing(0.5)
  },
}))
