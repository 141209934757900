import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'

function ConfirmBox({title, onOk, onCancel, open, description, okText, cancelText, fullWidth=true,...rest}) {
  return (
    <Box> 
      <Dialog {...rest} open={open} handleClose={onCancel} title={title} fullWidth={fullWidth}>
        {title && <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>}
      {description && <DialogContent>
        {description}
      </DialogContent>}
      <DialogActions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button variant="contained" onClick={onOk}>{okText}</Button>
      </DialogActions>
  </Dialog>
  </Box>
  )
}

export default ConfirmBox