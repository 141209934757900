import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Container,
  useMediaQuery,
  useTheme,
  Snackbar, 
  Alert
} from "@mui/material";

import {
  getRepairRequestStatus,
  getRepairRequests,
  updateResponsiblePersonForRepairRequests,
} from "../../../apis/aftercareApis";
import {
  getAfterCareRepairRequestVisibleColumns,
  getModifiedResponse,
  getAfterCareRepairRequestHiddenColumns ,
  ADDITIONAL_FIELDS_REQUIRED_IN_PRINT_AND_DOWNLOAD,
  isHiddenResponsibleMenuItem,
} from "../../../Pages/Aftercare/Grid/util";

import useGridDataModalHandler from "../../../_hooks/useGridDataModalHandler";
import {
  getOrganizations,
  getRepairRequestCarryOutAsTypeList,
  getRepairRequestLocations,
} from "../../../apis/surveyingApis";
import MobileCustomRow from "../../../_hooks/MobileCustomRow";
import DataGridV2 from "../../../components/DataGridV2";
import {
  groupBy,
  history,
  setLocalStorageItem,
  formatDate,
  repairRequestStatusValue,
  getLocalStorageItem,
  repairRequestReceivedVia,
  buildingManagerRole
} from "../../../_helpers";
import { apps, LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../../_constants";
import AddRepairRequest from "../AddRepairRequest";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getColumnsOrderFromLocalStorage } from "../../../_helpers/getGridContextMenuList";
import SelectResponsiblePerson from "../../Survey/components/SelectResponsiblePerson";
import { Add, Chat } from "@mui/icons-material";

// FIXME: rename this file, move to common place and accodrdingly update routes for aftercare and construction quality
export const AfterCareRepairRequests = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpenObjectModal, setIsOpenObjectModal] = useState(null);
  const [locations, setLocations] = useState([]);
  const [optionsDataLoading, setOptionsDataLoading] = useState({
    location: false,
    carryOutAsType: false,
    statusList: false,
  });
  const [carryOutAsTypeList, setCarryOutAsTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const apiRef = useGridApiRef();

  const [currentView, setCurrentView] = useState("list");
  const [openExportModal, setOpenExportModal] = useState(false);
  const muiTableRef = useRef();
  const isPageLoad = useRef(true)
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [responsiblePopupPosition, setResponsiblePopupPosition] = useState({ x: 0, y: 0 })
  const [organizationsLoading, setOrganizationsLoading] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "error"
  })

  const app = useSelector((state) => state.app);
  const { all: buildings } = useSelector((state) => state.buildings);
  const handleExportModal = useCallback(() => { setOpenExportModal(!openExportModal) }, [openExportModal])

  const isAfterCareApp = app === apps.aftercare;
  const isConstructionQualityApp = app === apps.constructionQuality

  const resetResponsiblePopupPosition = () => {
    setResponsiblePopupPosition({
      x: 0,
      y: 0
    })
  }

  const handleResponsiblePerson = async (data) => {
    try {
      const response = await updateResponsiblePersonForRepairRequests(app, data.loginId, isTabletOrMobile ? checkedItems : rowSelectionModel)
      if (response.status === 200) {
        setToast({
          open: true,
          message: t("repairRequest.updateResponsiblePerson.success"),
          type: "success"
        });
      } else {
        setToast({
          open: true,
          message: t("general.failedToUpdate"),
          type: "error"
        });
      }
      resetResponsiblePopupPosition()
      setIsSubmitting(false)
    } catch (error) {
      setToast({
        open: true,
        message: t("general.failedToUpdate"),
        type: "error"
      });
      resetResponsiblePopupPosition()
      setIsSubmitting(false)
    }
  }
  const {
    loading,
    loadedRows,
    sortModel,
    handleSort,
    handleCellSort,
    filterModel,
    handleFilter,
    resetData,
    handleSearch,
    searchText,
    getAllRecords,
    handleCellFilter,
    totalRecords,
    paginationModel,
    onPaginationModelChange,
    getAllExportRecords
  } = useGridDataModalHandler({
    modifyResponse: getModifiedResponse(buildings, t),
    additionalParams: { app },
    rowIdentifier : "requestId",
    apiCall: getRepairRequests,
    sortModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
    ),
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
    ),
    paginationModel: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
    ),
    searchText: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_SEARCH_TEXT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], ""
    ),
    dateTimeFields: ["createdOn" , "modifiedOn"]
  });

  const selectedRowsForDesktop = loadedRows.filter(row => rowSelectionModel.includes(row.requestId));
  const isRepairRequestsCompletedForDesktop= selectedRowsForDesktop.some(row => row?.completed);
  const selectedRowsForMobileOrTab = loadedRows.filter(row => checkedItems.includes(row.requestId));
  const isRepairRequestsCompletedForMobileOrTab = selectedRowsForMobileOrTab.some(row => row?.completed);

  const handleChangeView = useCallback((_, newAlignment) => {
    setCurrentView(newAlignment);
  }, []);

  const projectIds = useMemo(
    () => groupBy(buildings, "projectId") || {},
    [buildings]
  );


  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    // handle filter applied from dashboard
    const locationState = props?.location?.state
    const statusNames = locationState?.status ?? [];
    const newFilterModelItems = [];
    if (locationState?.selectedProject?.projectId) {
      newFilterModelItems.push(
        {
          field: "projectId",
          operator: "is",
          value: locationState.selectedProject.projectId
        },
      )
    }
    if (locationState?.selectedProject?.buildingNoIntern && locationState.filter === "buildingNoIntern" ) {
      newFilterModelItems.push(
        {
          field: "buildingNoIntern",
          operator: "equals",
          value: locationState.selectedProject.buildingNoIntern
        },
      )
    }
    if(statusNames?.length > 0){
       newFilterModelItems.push(
        {
          field: "status",
          operator: "isAnyOf",
          value: statusNames
        },
      )
    }
    if(locationState?.isOverDue){
      newFilterModelItems.push(
        {
          field: "overdue",
          operator: "is",
          value: "true"
        },
      )
    }
    // if user directly visit page, apply old filterModel else override old filter with new filter model(newFilterModel)
    handleFilter(newFilterModelItems.length > 0 ? {
      items: newFilterModelItems,
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    } : filterModel ?? {
      "items": [],
      "logicOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    })
  }, [props?.location?.state])
  

  useEffect(() => {
    if (Object.keys(projectIds)?.length > 0) {
      getOrganizations(Object.keys(projectIds))
        .then(({ data }) => {
          setOrganizations(
            data.map((singleOrganization) => {
              return {
                label: singleOrganization.name,
                value: singleOrganization.id,
              };
            })
          );
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [projectIds]);

  const isSiteManagerOrAfterCareEmployeeForBuildings = useMemo(() => {
    let count = 0
    buildings.forEach((building) => {
      if (building.roles && (building.roles.includes(userAccountRoleConstants.aftercareEmployee) || building.roles.includes(userAccountRoleConstants.siteManager))) {
        count++;
      }
    })

    return {
      ALL: count === buildings.length,
      NO_ONE: count === 0,
      MIX: count > 0 && count !== buildings.length
    }
  }, [buildings])

  const getFilterOptions = async () => {
    try {
      setOptionsDataLoading({
        location: true,
        carryOutAsType: true,
        statusList: true,
      });

      const [locationRes, typeListRes, requestStatusRes] = await Promise.all([
        getRepairRequestLocations(),
        getRepairRequestCarryOutAsTypeList(),
        getRepairRequestStatus(),
      ]);

      const locations = locationRes.data.map((x) => {
        return { label: x.name, value: x.name };
      });
      const typeList = typeListRes.data.map((x) => {
        return { label: x.name, value: x.name };
      });
      const status = requestStatusRes.data.map((x) => {
        return { label: x.name, value: x.name };
      });

      setLocations(locations);
      setCarryOutAsTypeList(typeList);
      setStatusList(status);
    } catch (e) {
      console.log(e);
    } finally {
      setOptionsDataLoading({
        location: false,
        carryOutAsType: false,
        statusList: false,
      });
    }
  };

  const listToMapRedirectHandler = useCallback(
    async (_, selectedRequest) => {
      history.push({
        pathname: isConstructionQualityApp ? "kwaliteitsborging/meldingen/map" :"/meldingen/map",
        state: {
          selectedRequest,
          requests: loadedRows,
          buildingId: selectedRequest.buildingId,
        },
      });
    },
    [loadedRows]
  );

    // NOTE: Whenever we change key name of column, then this must be update.
  const mappingOfOldFieldToNewField = {
    locationId: "location",
    statusId: "status",
    attachments: "firstFileId",
    carryOutAsTypeId: "carryOutAsType",
  }
  
  const columns = useMemo(() => {
    const projectNames = Object.keys(projectIds).map((projectId) => ({
      value: projectId,
      label: projectIds?.[projectId]?.[0]?.projectName,
    })).sort((a, b) => (a.label < b.label ? -1 : 1))

    const columns = getAfterCareRepairRequestVisibleColumns({
      t,
      i18n,
      listToMapRedirectHandler,
      projectNames,
      locations,
      optionsDataLoading,
      carryOutAsTypeList,
      statusList,
      organizations,
      classes,
      handleCellFilter,
      apiRef,
      handleCellSort,
      isSiteManagerOrAfterCareEmployeeForBuildings,
      isAfterCareApp
    });
    return [...columns , ...getAfterCareRepairRequestHiddenColumns(t , !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE)];

  }, [projectIds, isSiteManagerOrAfterCareEmployeeForBuildings, apiRef, sortModel, t, i18n, listToMapRedirectHandler, locations, optionsDataLoading, carryOutAsTypeList, statusList, organizations, classes, handleCellFilter]);

  const handleCheckboxChange = useCallback((itemId) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(itemId)) {
        return prevCheckedItems.filter((id) => id !== itemId);
      }
      return [...prevCheckedItems, itemId];
    });
  }, []);

  const handleTooLateFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal?.items?.length > 0) {
      const tooLateFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "overdue"
      );
      if (tooLateFilterIndex !== -1 && cloneFilterModal?.items[tooLateFilterIndex].value) {
        if (cloneFilterModal?.items[tooLateFilterIndex].value === "true") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "overdue"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[tooLateFilterIndex].value = "true"
        }

      } else {
        cloneFilterModal.items.push({
          field: "overdue",
          operator: "is",
          value: "true",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "overdue",
            operator: "is",
            value: "true",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, handleFilter]);

  const handleAttentionFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const attentionFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "isRequiredHandling"
      );
      if (attentionFilterIndex !== -1 && cloneFilterModal?.items[attentionFilterIndex].value) {
        if (cloneFilterModal?.items[attentionFilterIndex].value === "true") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "isRequiredHandling"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[attentionFilterIndex].value = "true"
        }

      } else {
        cloneFilterModal.items.push({
          field: "isRequiredHandling",
          operator: "is",
          value: "true",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "isRequiredHandling",
            operator: "is",
            value: "true",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, handleFilter]);

  const handleUnreadMessageFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const unreadMessageFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "unreadMessagesCount"
      );
      if (unreadMessageFilterIndex !== -1 && cloneFilterModal?.items[unreadMessageFilterIndex].value) {
        if (cloneFilterModal?.items[unreadMessageFilterIndex].value === "0") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "unreadMessagesCount"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        } else {
          cloneFilterModal.items[unreadMessageFilterIndex].value = "0"
          cloneFilterModal.items[unreadMessageFilterIndex].operator = ">"
        }

      } else {
        cloneFilterModal.items.push({
          field: "unreadMessagesCount",
          operator: ">",
          value: "0",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "unreadMessagesCount",
            operator: ">",
            value: "0",
          }
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, handleFilter]);

  const handleNewRequestFilter = useCallback(() => {
    let cloneFilterModal = filterModel;

    // step1 filter is available not undefined
    if (cloneFilterModal?.items?.length > 0) {
      //step3 check status is available or not in filter
      const isStatusFilterAwaialableIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "status"
      );
      const isStatusFilterAwaialable =
        cloneFilterModal?.items?.[isStatusFilterAwaialableIndex];
      // step4 if available
      if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
        // step6 check new status is available or not
        const isNewStatusPresent = Array.isArray(isStatusFilterAwaialable.value)
          ? isStatusFilterAwaialable.value.includes(repairRequestStatusValue.NEW)
          : isStatusFilterAwaialable.value === repairRequestStatusValue.NEW;

        // step7 if new status available
        if (isNewStatusPresent) {
          // step 10 check value is array or not, if array then check lenght is > 2 then remove from it else filter that status
          if (
            Array.isArray(isStatusFilterAwaialable.value) &&
            isStatusFilterAwaialable.value.length > 1
          ) {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => val !== repairRequestStatusValue.NEW
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          } else {
            // step 11 if stystus length 1 or only value then
            const newFilterItems = cloneFilterModal?.items?.filter(
              (item) => item.field !== "status"
            );
            cloneFilterModal = {
              ...cloneFilterModal,
              items: newFilterItems,
            };
          }
        } else {
          // step8 if new status not available
          if (
            Array.isArray(
              cloneFilterModal.items[isStatusFilterAwaialableIndex].value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value.push(
              repairRequestStatusValue.NEW
            );
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "status",
              operator: "isAnyOf",
              value: [
                repairRequestStatusValue.NEW,
                cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              ],
            };
          }
        }
      } else {
        // step5 if not available then add that
        if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.NEW,
          };
        } else {
          cloneFilterModal.items.push({
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.NEW,
          });
        }
      }
    } else {
      // step2 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.NEW,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, statusList, handleFilter]);

  const handleInProgressRequestFilter = useCallback(() => {
    let cloneFilterModal = filterModel;

    // step1 filter is available not undefined
    if (cloneFilterModal?.items?.length > 0) {
      //step3 check status is available or not in filter
      const isStatusFilterAwaialableIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "status"
      );
      const isStatusFilterAwaialable =
        cloneFilterModal?.items?.[isStatusFilterAwaialableIndex];
      // step4 if available
      if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
        // step6 check new status is available or not
        const isInProgresstatusPresent = Array.isArray(
          isStatusFilterAwaialable.value
        )
          ? isStatusFilterAwaialable.value.includes(repairRequestStatusValue.IN_PROGRESS)
          : isStatusFilterAwaialable.value === repairRequestStatusValue.IN_PROGRESS;

        // step7 if new status available
        if (isInProgresstatusPresent) {
          // step 9 check value is array or not, if array then check lenght is > 2 then remove from it else filter that status
          if (
            Array.isArray(isStatusFilterAwaialable.value) &&
            isStatusFilterAwaialable.value.length > 1
          ) {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => val !== repairRequestStatusValue.IN_PROGRESS
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          } else {
            // step 10 if stystus length 1 or only value then
            const newFilterItems = cloneFilterModal?.items?.filter(
              (item) => item.field !== "status"
            );
            cloneFilterModal = {
              ...cloneFilterModal,
              items: newFilterItems,
            };
          }
        } else {
          // step8 if new status not available
          if (
            Array.isArray(
              cloneFilterModal.items[isStatusFilterAwaialableIndex].value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value.push(
              repairRequestStatusValue.IN_PROGRESS
            );
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "status",
              operator: "isAnyOf",
              value: [
                repairRequestStatusValue.IN_PROGRESS,
                cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              ],
            };
          }
        }
      } else {
        // step5 if not available then add that
        if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.IN_PROGRESS,
          };
        } else {
          cloneFilterModal.items.push({
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.IN_PROGRESS,
          });
        }
      }
    } else {
      // step2 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "status",
            operator: "is",
            value: repairRequestStatusValue.IN_PROGRESS,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, statusList, handleFilter]);

  const handleCompletedRequestFilter = useCallback(() => {
    let cloneFilterModal = JSON.parse(JSON.stringify(filterModel));
    // step1 filter is available not undefined
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      //step3 check status is available or not in filter
      const isStatusFilterAwaialableIndex = filterModel?.items?.findIndex(
        (item) => item.field === "status"
      );
      const isStatusFilterAwaialable =
        filterModel?.items?.[isStatusFilterAwaialableIndex];
      // step4 if available and its array
      if (
        isStatusFilterAwaialable &&
        isStatusFilterAwaialable.value &&
        Array.isArray(isStatusFilterAwaialable.value)
      ) {
        // step6 check both present in value or not
        if (
          isStatusFilterAwaialable.value.includes(repairRequestStatusValue.COMPLETED) &&
          isStatusFilterAwaialable.value.includes(repairRequestStatusValue.TURNED_DOWN)
        ) {
          if (isStatusFilterAwaialable.value.length === 2) {
            const newFilterItems = filterModel?.items?.filter(
              (item) => item.field !== "status"
            );
            cloneFilterModal = {
              ...filterModel,
              items: newFilterItems,
            };
          } else {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => ![repairRequestStatusValue.COMPLETED, repairRequestStatusValue.TURNED_DOWN].includes(val)
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          }
        } else {
          cloneFilterModal.items[isStatusFilterAwaialableIndex].value = [
            ...new Set([
              ...cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              repairRequestStatusValue.COMPLETED,
              repairRequestStatusValue.TURNED_DOWN,
            ]),
          ];
        }
      } else {
        // step5 if ststus available and its value also available
        if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
          // if value is completed or rejected
          if (
            [repairRequestStatusValue.COMPLETED, repairRequestStatusValue.TURNED_DOWN].includes(
              isStatusFilterAwaialable.value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "status",
              operator: "isAnyOf",
              value: [repairRequestStatusValue.COMPLETED, repairRequestStatusValue.TURNED_DOWN],
            };
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "status",
              operator: "isAnyOf",
              value: [
                repairRequestStatusValue.COMPLETED,
                repairRequestStatusValue.TURNED_DOWN,
                isStatusFilterAwaialable.value,
              ],
            };
          }
        } else if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "status",
            operator: "isAnyOf",
            value: [repairRequestStatusValue.COMPLETED, repairRequestStatusValue.TURNED_DOWN],
          };
        } else {
          cloneFilterModal.items.push({
            field: "status",
            operator: "isAnyOf",
            value: [repairRequestStatusValue.COMPLETED, repairRequestStatusValue.TURNED_DOWN],
          });
        }
      }
    } else {
      // step2 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "status",
            operator: "isAnyOf",
            value: [repairRequestStatusValue.TURNED_DOWN, repairRequestStatusValue.COMPLETED],
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
       LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newFilter
      );
    });
  }, [filterModel, statusList, handleFilter]);

  const getRepairRequestsForPrintAndDownload = async ({ selectedColumnsNames = [], isAllSelected = true }) => {
    let hiddenFields = getAfterCareRepairRequestHiddenColumns(t, !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE)
    let exportRistrictedField = [...hiddenFields.map((f) => f.field), "priority", "targetCompletionDate", "overdue"]
    const allColumns = [...columns, ...ADDITIONAL_FIELDS_REQUIRED_IN_PRINT_AND_DOWNLOAD, ...hiddenFields];
    const columnNames = allColumns.reduce(
      (p, c) => ({ ...p, [c.field]: c.field }),
      {}
    );

    const {
      data: { results },
    } = await (selectedColumnsNames.length > 0 ? getAllExportRecords({
      additionalObjectPayload: { exportColumns: selectedColumnsNames.concat(ADDITIONAL_FIELDS_REQUIRED_IN_PRINT_AND_DOWNLOAD.map((f) => f.field)) },
      additionalCondition: isAllSelected ? [] : [{
        field: "requestId",
        operator: "isAnyOf",
        value: [
          ...(isTabletOrMobile ? checkedItems : rowSelectionModel)
        ],
      }]
    }) : getAllRecords());
    const modifiedData = getModifiedResponse(buildings, t)(results);
    const data = modifiedData?.map((p) => {
      return Object.keys(columnNames).reduce((prev, key) => {
        const obj = { ...prev };
        const isDateField = !!allColumns.find(x => x.field === key && x.type === 'date');
        const isDateTimeField = !!allColumns.find(x => x.field === key && x.type === 'dateTime');
        if (key === "requestId") obj["id"] = p["requestId"];
        if (key === "isRequiredHandling") {
          const array = [];
          if (p.isRequiredHandling) {
            array.push({ icon: "PriorityHigh" });
          }
          obj[key] = array;
        }
        else if (exportRistrictedField.includes(key)) {
          if (p.isAfterCareEmployeeOrSiteManager) {
            if (key === "overdue") {
              const array = [];
              if (p.overdue) array.push({ icon: "Schedule", color: "error" });
              else if (p.is48HoursReminder)
                array.push({ icon: "Schedule", color: "warning" });
              obj[key] = array;
            } else if (key === "reporterRole") {
              obj[key] = buildingManagerRole[p[key]]
            } else if (key === "receivedVia") {
              obj[key] = repairRequestReceivedVia[p[key]]
            } else {
              obj[key] = isDateField || isDateTimeField ? p[key] && formatDate(new Date(p[key]), isDateTimeField) : p[key]
            }
          }
        }
        else if (isDateField || isDateTimeField)
          obj[key] = p[key] && formatDate(new Date(p[key]), isDateTimeField);
        else if (key === "resolvers")
          obj[key] = p["resolvers"]
            ? [...new Set(p["resolvers"].map((a) => a.name))]
            : [];
        else if (key === "projectId") obj[key] = p["projectName"];
        else obj[key] = p[key];
        return obj;
      }, {});
    });
    return data;
  };

  const getToolbarProps = useMemo(() => {
    const isTooLate = filterModel?.items?.find(
      (item) => item.field === "overdue"
    )?.value === "true";


    const status = filterModel?.items?.find(
      (item) => item.field === "status"
    )?.value;

    const isNewFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(repairRequestStatusValue.NEW)
        : repairRequestStatusValue.NEW === status
      : undefined;

    const isAttentionFilterAvailable = filterModel?.items?.find(
      (item) => item.field === "isRequiredHandling"
    )?.value === "true";

    const unreadMessageFilterItem = filterModel?.items?.find(
      (item) => item.field === "unreadMessagesCount"
    )
    const isUnreadMessageFilterAvailable = unreadMessageFilterItem?.value == "0" &&  unreadMessageFilterItem?.operator == ">"


    const isInProgressFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(repairRequestStatusValue.IN_PROGRESS)
        : repairRequestStatusValue.IN_PROGRESS === status
      : undefined;

    const isCompletedFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(repairRequestStatusValue.COMPLETED)
        : repairRequestStatusValue.COMPLETED === status
      : undefined;
    const isRejectedFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(repairRequestStatusValue.TURNED_DOWN)
        : repairRequestStatusValue.TURNED_DOWN === status
      : undefined;

    const quickFilterItems = [{
      title: t("chat.messages"),
      onClick: handleUnreadMessageFilter,
      isSelected: isUnreadMessageFilterAvailable,
      icon: <Chat fontSize="small"/>
    },
    {
      title: t("repairRequest.status.new"),
      onClick: handleNewRequestFilter,
      isSelected: isNewFilterAvailable,
      iconText: "N"
    },
    {
      title: t("general.attention.label"),
      onClick: handleAttentionFilter,
      isSelected: isAttentionFilterAvailable,
      iconText: "!"
    },
    {
      title: t("general.inProcess"),
      onClick: handleInProgressRequestFilter,
      isSelected: isInProgressFilterAvailable,
      icon: <i
      aria-hidden="true"
      className={"fa fa-spinner"}
      ></i>
    },
    {
      title: t("general.overdue"),
      onClick: handleTooLateFilter,
      isSelected: isTooLate,
      icon: <i
      aria-hidden="true"
      className={"fa fa-clock-o"}
    ></i>
    },
    {
      title: t("repairRequest.quickfilter.complete"),
      onClick: handleCompletedRequestFilter,
      isSelected: isCompletedFilterAvailable && isRejectedFilterAvailable,
      icon: <i
      aria-hidden="true"
      className={"fa fa-check-circle-o"}
    ></i>
    }]

    const toolbarProps = {
      apiRef: apiRef,
      columns: columns,
      title: t("general.repairRequests"),
      totalRecords: totalRecords,
      getData: getRepairRequestsForPrintAndDownload,
      handleChangeView: handleChangeView,
      currentView: currentView,
      handleSearch: (value) => {
        handleSearch(value, (value) => {
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_SEARCH_TEXT[
              isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"
            ],
            value
          );
        });
      },
      searchText: searchText,
      quickFilterItems: quickFilterItems,
      selectedRows: isTabletOrMobile ? checkedItems : rowSelectionModel,
      taskMenuListItems:  
        [...!isHiddenResponsibleMenuItem({
          // TODO: Need to improve this, we need to maintain one list for selected items not conditionally like for mobile checkedItems and for grid rowSelectionModel. And for that we need to use apiref for adding items in common selected list(which is rowSelectionModel).
          selectedItems: isTabletOrMobile ? checkedItems : rowSelectionModel,
          allBuildings: buildings,
          repairRequests: loadedRows,
        }) ? [{
          title: t("general.responsible"),
          onClick: (event) => {
            setResponsiblePopupPosition({
              x: event.clientX,
              y: event.clientY
            });
          },
          disabled: isTabletOrMobile
            ? isRepairRequestsCompletedForMobileOrTab || checkedItems?.length === 0
            : isRepairRequestsCompletedForDesktop || rowSelectionModel?.length === 0
        }] : []]
      ,
      toolbarStartActions: app === apps.aftercare ? [
        {
          title: t("general.add"),
          icon: Add,
          onClick: () => setIsOpenObjectModal(true)
        }
      ] : [],
    };
    return toolbarProps;
  }, [rowSelectionModel,
    handleExportModal,
    checkedItems,
    filterModel,
    statusList,
    isTabletOrMobile,
    currentView,
    columns,
    handleChangeView,
    handleSearch,
    isOpenObjectModal,
    searchText,
    handleTooLateFilter,
    handleAttentionFilter,
    handleNewRequestFilter,
    handleInProgressRequestFilter,
    handleCompletedRequestFilter,
    handleCheckboxChange,
    listToMapRedirectHandler,
    handleCellSort,
    totalRecords
  ]);

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          ...(getLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_VISIBILITY[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], {})),
          ...getAfterCareRepairRequestHiddenColumns (t, !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE).reduce((acc, column) => {
            return { ...acc, [column.field]: false, };
          }, {}),
        },
      },
    }),
    [getAfterCareRepairRequestHiddenColumns , t, isSiteManagerOrAfterCareEmployeeForBuildings]
  );

  const getTogglableColumns = (columns) => {
    const hiddenFields = getAfterCareRepairRequestHiddenColumns (t, !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE)
    return columns.filter(column => !hiddenFields.some(hiddenFields => hiddenFields.field === column.field)).map((column) => column.field);
  };

  const slotProps = {
    columnsPanel: {
      getTogglableColumns,
    },
  }

  const onFilterModelChange = useCallback((newfilterModalModel) => {
    handleFilter(newfilterModalModel, (newfilterModalModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
        newfilterModalModel
      );
    })
  }, [handleFilter])

  // First time loading we need to display page loadi  grid loading
  useEffect(() => {
    if (!loading) {
      isPageLoad.current = loading
    }
  }, [loading])


  const columnsForExport = (isAllDisplay = false) => {
    const hiddenFields = isAllDisplay ? [] : getAfterCareRepairRequestHiddenColumns(t, true)

    return columns.filter(column => !hiddenFields.some(hiddenFields => hiddenFields.field === column.field))
  }

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        mappingOfOldFieldToNewField={mappingOfOldFieldToNewField}
        columnsForExport={columnsForExport}
        MobileCustomRowComponent={(props) => {
          return <MobileCustomRow
            selectedCheckBoxs={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
            listToMapRedirectHandler={listToMapRedirectHandler}
            item={props.row}
            key={props.rowId}
          />
        }}
        apiRef={apiRef}
        rowHeight={isTabletOrMobile ? 80 : 34}
        ref={muiTableRef}
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_SEARCH_TEXT[
          isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_ORDER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_WIDTH[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_VISIBILITY[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
        columns={columns}
        toolbarProps={getToolbarProps}
        rows={loadedRows}
        filterMode="server"
        pagination
        rowCount={totalRecords}
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModal) =>
          onPaginationModelChange(paginationModal, (paginationModal) => {
            setLocalStorageItem(
             LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
              paginationModal
            );
          })}
        paginationMode="server"
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) =>
          handleSort(newSortModel, (newSortModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
              newSortModal
            );
          })
        }
        onFilterModelChange={onFilterModelChange}
        initialState={initialState}
        currentView={currentView}
        slotProps={slotProps}
        {...(!!filterModel && { filterModel })}
      />
      <AddRepairRequest
        repairRequestCreateCallBack={resetData}
        isOpenObjectModal={isOpenObjectModal}
        setIsOpenObjectModal={(value) => setIsOpenObjectModal(value)}
      />

      <SelectResponsiblePerson
        isSubmitting={isSubmitting}
        anchorOrigin={{
          vertical: responsiblePopupPosition?.y,
          horizontal: responsiblePopupPosition?.x,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        customTitle={t("general.responsible")}
        onSubmit={async (values, setSelectResponsiblePersonState) => {
          await handleResponsiblePerson(values)
          setSelectResponsiblePersonState?.((prevState) => ({
            ...prevState,
            relationId: ""
          }))
        }
        }
        setLoading={(val) => {
          setOrganizationsLoading(val);
          setIsSubmitting(true)
        }}
        loading={organizationsLoading}
        forRepairRequests
        repairRequestIds={isTabletOrMobile ? checkedItems : rowSelectionModel}
        anchorEl={responsiblePopupPosition.x}
        onClose={() => {
          resetResponsiblePopupPosition()
        }}
      />
          <Snackbar
            open={toast?.open}
            autoHideDuration={6000}
            onClose={() =>
              setToast({
                open: false,
                message: "",
                type: "error"
              })
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            key={toast?.message}
          >
            <Alert
              onClose={() =>
                setToast({
                  open: false,
                  message: "",
                  type: "error"
                })
              }
              severity={toast?.type}
            >
              {toast?.message}
            </Alert>
          </Snackbar>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "hidden ",
    padding: 0,
  },
}));

export default AfterCareRepairRequests