import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Close from '@mui/icons-material/Close';
import { Done } from '@mui/icons-material';
import cls from 'classnames'
import { NumericFormat } from 'react-number-format';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TextFieldDialogPopup({
  open,
  dialogTitle,
  textFieldtitle,
  textFieldKey = "",
  isUpdating = true,
  type = "text",
  multiline = false,
  inputProps = {},
  value = "",
  handleClose,
  handleUpdate,
  fullScreen = true
}) {
  const classes = useStyles();
  const [textFieldValue, setTextFieldValue] = useState("");

  useEffect(() => {
    setTextFieldValue(value);
  }, [value])

  const handleChangeTextInput = (e) => {
    if (type === "number") {
      let numericValue = e.target.value ? Number(e.target.value) : 0;
      var reg = /^\d*$/;
        if (!isNaN(numericValue) && reg.test(e.target.value) === true) {
            setTextFieldValue(e.target.value === "" ? null : e.target.value)
        }
        else {
            setTextFieldValue(null)
        }
    } else {
      setTextFieldValue(e.target.value)
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition} fullWidth
        maxWidth='sm'
        aria-labelledby="form-dialog-title" scroll="paper">
        <DialogTitle className={classes.dialogTitle} id="dialog-objects-title">
          <Grid className={classes.dialogTitleContent} container spacing={1}>
            <Grid item className={classes.grow}>
              <Typography variant="h5"
                className={classes.dialogTitleTypo}>{dialogTitle}</Typography>
            </Grid>
            <Grid>
              <IconButton
                type='button'
                onClick={(e) => handleUpdate(e, textFieldValue)}
                className={classes.doneButton}
                color="inherit"
                size="large">
                <Done className={classes.iconStyle} />
              </IconButton>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
                color="inherit"
                aria-label="close"
                size="large">
                <Close className={classes.iconStyle} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid className={classes.detailGridContainer}>
            <Typography className={classes.detailSubHeader}>{textFieldtitle}</Typography>
            <div className={classes.textAreaContainer}>
              {
                textFieldKey === "meterReading_CityHeating" ?
                  <NumericFormat 
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fixedDecimalScale={true}
                    decimalScale={3}
                    size="small"
                    fullWidth
                    customInput={TextField}
                    allowNegative={false}
                    thousandSeparator="."
                    decimalSeparator=","
                    variant="outlined"
                    align="center"
                    id="outlined-basic"
                    name={textFieldtitle}
                    inputProps={inputProps}
                    value={textFieldValue}
                    onValueChange={(values) => {
                      const { formattedValue, value: changedValue } = values;
                      const value = Number(changedValue)
                      setTextFieldValue(value)
                    }}
                    isAllowed={(values)=>{
                      const { formattedValue, value:changedValue } = values;
                      const value = Number(changedValue)
                      return value <= 999999999.999 && value>=0
                    }}
                  />
                  : 
                <TextField
                maxRows={20}
                id="outlined-basic"
                variant="outlined"
                name={textFieldtitle}
                type="text"//Keep this as "text"" and not change it to "number"(coming from prop "type") because it gives issues in IOS SAFARI BROWSER
                fullWidth
                fullHeight
                multiline={multiline}
                inputProps={inputProps}
                value={textFieldValue || ""}
                className={cls({ [classes.numberTextField]: type === "number", [classes.textArea]: type != "number" })}
                onChange={handleChangeTextInput}
              />
              }
              {isUpdating ?
                <CircularProgress className={classes.loader} size={20} /> : null}
            </div>
          </Grid>
        </DialogContent>

      </Dialog>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  dialogTitle: {
    padding: theme.spacing(.625),
    background: theme.palette.primary.main,
    '& .MuiIconButton-label > .MuiSvgIcon-root': {
      fill: theme.palette.grey[300]
    },
  },
  dialogTitleContent: {
    padding: theme.spacing(1.25),
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  dialogTitleTypo: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold'
  },
  closeButton: {
    margin: 0,
    padding: theme.spacing(0)
  },
  textArea: {
    height: "100%",

    "& .MuiInputBase-root": {
      height: "100%",
      display: "flex",
      alignItems: "start"
    }
  },
  doneButton: {
    margin: 0,
    marginRight: theme.spacing(1.25),
    padding: theme.spacing(0)
  },
  iconStyle: {
    fill: theme.palette.common.white,
    width: '1em',
    height: '1em',
  },
  dialogContent: {
    padding: theme.spacing(1.25),
    display: 'flex',
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.only('xs')]: {
      maxHeight: '100%'
    },
  },
  detailSubHeader: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  textAreaContainer: {
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    top: -25,
    right: 5
  },
  detailGridContainer: {
    margin: theme.spacing(1.5, 1),
    width: "100%"
  },
  numberTextField: {
    '& input': {
      height: '0.9em'
    },
    fontSize: 12,
    color: theme.palette.grey[800],
    padding: theme.spacing(0.3, 1, 1, 1),
  },
}))
