import React from 'react'
import { Accordion, AccordionSummary, Divider, Box, Typography, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ParticipantsList from './ParticipantsList';
import { useTranslation } from 'react-i18next';

const ParticipantsAccordion = ({ data, handleSelectParticipant, selectedParticipants }) => {
  return <>
    {Object.keys(data).map((organisationId) => <Accordion dense >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography px={2} variant='subtitle2'>{data?.[organisationId]?.[0]?.organisationName}</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <ParticipantsList handleSelectParticipant={handleSelectParticipant} list={data?.[organisationId]} isCheckBoxVisible={true} selectedParticipants={selectedParticipants} />
      </AccordionDetails>
    </Accordion>)}
  </>
}

const InvolvePartyList = ({ handleSelectParticipant, data, selectedParticipants }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%' }}>
      {(Object.keys(data.involvedSubContractors).length + Object.keys(data.otherSubContractors).length) <= 0 && <Typography p={2}>{t("general.noLoginFound")}</Typography>}
      {Object.keys(data.involvedSubContractors).length > 0 && <>
        <Typography py={1} px={4} variant='subtitle1' color="primary">{t("chat.add.relatedToProject")}</Typography>
        <Divider />
        <ParticipantsAccordion data={data.involvedSubContractors} handleSelectParticipant={handleSelectParticipant} selectedParticipants={selectedParticipants} />
      </>}

      {Object.keys(data.otherSubContractors).length > 0 && <>
        <Typography py={1} px={4} variant='subtitle1' color="primary">{t("chat.add.otherContacts")}</Typography>
        <Divider />
        <ParticipantsAccordion data={data.otherSubContractors} handleSelectParticipant={handleSelectParticipant} selectedParticipants={selectedParticipants} />
      </>}
    </Box>
  )
}

export default InvolvePartyList
