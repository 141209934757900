import { Stack, TextField, Typography } from '@mui/material'
import React  from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const General = () => {
  const { selected} = useSelector(state => state.buildings)
  const {t} = useTranslation()

  return (
    <div><Typography mb={1.5} variant='subtitle1'>{t("general.general")}</Typography>
    <Stack rowGap={2}>
      <TextField
        fullWidth
        label={t("general.projectName")}
        type="text"
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={selected?.projectName}
      />
      <TextField
        fullWidth
        size='small'
        label={t("general.objectNumber")}
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={selected?.buildingNoIntern}
      />
      <TextField
        fullWidth
        label={t("general.building.type")}
        size='small'
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={selected?.buildingType}
      />
      <TextField
        fullWidth
        label={t("general.type")}
        size='small'
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={selected?.propertyType}
      />
    
    </Stack></div>
  )
}

export default General