import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { getDataTableTextLabels, groupBy } from "../../_helpers";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import clsx from "clsx";
import { apps } from "../../_constants";

const { webApiUrl } = window.appConfig;

export default function SubContractorOverview(props) {
  const theme = useTheme();
  const { history } = props;
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [requests, setRequests] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { selected } = useSelector((state) => state.buildings);
  const app = useSelector((state) => state.app);
  const classes = useStyles();
  let [columnsVisibility, setColumnsVisibility] = useState([
    { name: "subContractorName", visible: true },
    { name: "newWorkOrder", visible: true },
    { name: "inProgressRepairRequests", visible: true },
    { name: "overdueRepairRequests", visible: true },
    { name: "openWorkOrders", visible: true },
    { name: "overdueWorkOrders", visible: true },
  ]);

  useEffect(() => {
    if (selected)
      (async () => {
        setLoading(true);
        const { data: requests } = await axios.get(
          `${webApiUrl}api/RepairRequest/GetRepairRequestsByProject/${selected.projectId}`
        );
        const { data: workOrdersData } = await axios.get(
          `${webApiUrl}api/RepairRequest/GetWorkOrdersByProjectId/${encodeURI(
            selected.projectId
          )}?app=${app}`
        );
        const workOrdersByContracters =
          Array.isArray(workOrdersData) && groupBy(workOrdersData, "name");
        const data = Object.values(workOrdersByContracters).map((ws) => {
          const openWorkOrders = ws.filter((p) => p.status === 1).length;
          const overdueWorkOrders = ws.filter((p) => p.overdue).length;
          const repairRequestIds = ws.map((p) => p.repairRequestId);
          const newWorkOrder = ws.filter((p) => p.status === 0).length;
          const inProgressRepairRequests = requests.filter(
            (p) =>
              repairRequestIds.includes(p.requestId) &&
              p.status === "In behandeling"
          ).length;
          const overdueRepairRequests = requests.filter(
            (p) => repairRequestIds.includes(p.requestId) && p.overdue
          ).length;
          return {
            newWorkOrder,
            inProgressRepairRequests,
            overdueRepairRequests,
            openWorkOrders,
            overdueWorkOrders,
            subContractorName: ws[0].name,
          };
        });
        setWorkOrders(workOrdersData);
        setRequests(data);
        setLoading(false);
      })();
  }, [selected]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("contractor_ColumnsVisibility")) ==
      undefined
    ) {
      localStorage.setItem(
        "contractor_ColumnsVisibility",
        JSON.stringify(columnsVisibility)
      );
    } else {
      columnsVisibility = JSON.parse(
        localStorage.getItem("contractor_ColumnsVisibility")
      );
    }

    var rowsPerPage = parseInt(
      localStorage.getItem("CQRepairRequests_RowsPerPage")
    );
    if (!!rowsPerPage && rowsPerPage > 0) {
      setRowsPerPage(rowsPerPage);
    }
  }, []);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true; //isWidthDown('sm', width);
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  const redirectToWorkOrderPage = (
    cellValue,
    columnName,
    subContractorName
  ) => {
    let url = "/werk/" + selected.projectNo + "/werkbonnen";
    let contractor = cellValue;
    if (app === apps.aftercare) {
      url = "/nazorg/werkbonnen";
      const workOrder = workOrders.find((a) => a.name === subContractorName);
      contractor = Object.assign(workOrder, { id: workOrder.organisatonId });
    }
    history.push({
      pathname: url,
      state: { filter: columnName, contractor, subContractorName },
    });
  };

  const columns = [
    {
      name: "subContractorName",
      label: t("general.name"),
      options: {
        display: isColumnVisible("subContractorName"),
        filter: true,
        customBodyRender: (value, { rowData }, updateValue) => {
          return (
            <Tooltip placement="bottom-start" title={value}>
              <Typography
                onClick={(e) =>
                  redirectToWorkOrderPage(value, "subContractorName", value)
                }
                className={clsx(
                  classes.customTableCell,
                  classes.momentLabelStyle
                )}
              >
                {value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "newWorkOrder",
      label: t("general.workOrdersNew"),
      options: {
        display: isColumnVisible("newWorkOrder"),
        filter: true,
        customBodyRender: (value, { rowData }, updateValue) => {
          return (
            <Tooltip placement="bottom-start" title={value}>
              <Typography
                onClick={(e) =>
                  redirectToWorkOrderPage(value, "newWorkOrder", rowData[0])
                }
                className={clsx(
                  classes.customTableCell,
                  classes.momentLabelStyle
                )}
              >
                {value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "openWorkOrders",
      label: t("site.manager.open.work.orders"),
      options: {
        display: isColumnVisible("openWorkOrders"),
        filter: true,
        customBodyRender: (value, { rowData }, updateValue) => {
          return (
            <Tooltip placement="bottom-start" title={value}>
              <Typography
                onClick={(e) =>
                  redirectToWorkOrderPage(value, "openWorkOrders", rowData[0])
                }
                className={clsx(
                  classes.customTableCell,
                  classes.momentLabelStyle
                )}
              >
                {value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "overdueWorkOrders",
      label: t("site.manager.overdue.work.orders"),
      options: {
        display: isColumnVisible("overdueWorkOrders"),
        filter: true,
        customBodyRender: (value, { rowData }, updateValue) => {
          return (
            <Tooltip placement="bottom-start" title={value}>
              <Typography
                onClick={(e) =>
                  redirectToWorkOrderPage(
                    value,
                    "overdueWorkOrders",
                    rowData[0]
                  )
                }
                className={clsx(
                  classes.customTableCell,
                  classes.momentLabelStyle
                )}
              >
                {value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
  ];

  let columnOrder = [];
  columnOrder =
    JSON.parse(localStorage.getItem("contractor_ColumnOrder")) == undefined
      ? columns.forEach((column, index) => {
          columnOrder.push(index);
        })
      : JSON.parse(localStorage.getItem("contractor_ColumnOrder"));

  const options = {
    draggableColumns: { enabled: true },
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPageOptions: [25, 50, 100],
    rowsPerPage: rowsPerPage,
    download: !isExtraSmallScreen,
    print: !isExtraSmallScreen,
    columnOrder,
    customToolbarSelect: (selectedRows) => <></>,
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      localStorage.setItem(
        "contractor_ColumnOrder",
        JSON.stringify(newColumnOrder)
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility({ columnsVisibility: listToUpdate });
        localStorage.setItem(
          "contractor_ColumnsVisibility",
          JSON.stringify(listToUpdate)
        );
      } else if (action == "remove") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility({ columnsVisibility: listToUpdate });
        localStorage.setItem(
          "contractor_ColumnsVisibility",
          JSON.stringify(listToUpdate)
        );
      }
    },
    textLabels: getDataTableTextLabels(t, loading),
    onChangeRowsPerPage: (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
    },
  };

  return (
    <div>
      <Container maxWidth={false} className={classes.mainContainer}>
        <MUIDataTable
          className={classes.dataTable}
          title={
            <div className={classes.customHeaderTitle}>
              <Box component="span">
                <IconButton
                  edge="start"
                  aria-label="GoBack"
                  color="inherit"
                  onClick={() => history.goBack()}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
              </Box>
              <Typography className={classes.headerTitle} variant="h5">
                {t("subcontractor.overview.page.title")}
              </Typography>
            </div>
          }
          data={loading ? [] : requests}
          columns={columns}
          options={options}
        />
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  momentLabelStyle: {
    [theme.breakpoints.up("sm")]: {
      wordBreak: "break-all",
    },
  },
  customTableCell: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px !important",
    },
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
    },
    fontSize: "14px !important",
  },
  tableText: {
    cursor: "pointer",
  },
  headerTitle: {
    color: theme.palette.grey[900],
    fontWeight: "bold",
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
    },
  },
  customHeaderTitle: {
    display: "flex",
    alignItems: "center",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
}));
