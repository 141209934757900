import { commonConstants } from '../_constants';
import { getRights, getUser } from '../_helpers';
var prevSelected = localStorage.getItem('building');
var prevBuildings = localStorage.getItem('buildings');
const initialState = {
    all: (prevBuildings && prevBuildings !== 'undefined') ? JSON.parse(prevBuildings) : [],
    selected: (prevSelected && prevSelected !== 'undefined') ? JSON.parse(prevSelected) : '',
    loading: true,
    rights: {},
    buyerGuidesBuildings: []
}
export function buildings(state = initialState, action) {
    const user = JSON.parse(getUser());

    switch (action.type) {
        case commonConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
                buyerGuidesBuildings: []
            };
        case commonConstants.GETALL_SUCCESS:
            const allBuildings = (prevBuildings && !window.navigator.onLine) ? JSON.parse(prevBuildings) : action.buildings;
            var prevSelected = localStorage.getItem('building');
            window.navigator.onLine && localStorage.setItem('buildings', JSON.stringify(action.buildings));
            const building = prevSelected && prevSelected !== 'undefined' && JSON.parse(prevSelected);
            let selectedBuilding = null;
            const selectedBuildings = allBuildings && allBuildings.filter(x => building && x.buildingId === building.buildingId);
            if (selectedBuildings && selectedBuildings.length > 0) {
                selectedBuilding = selectedBuildings.find(b => b.roles && b.roles.some(r => r === 'BuyersGuide' || r === 'Spectator')) || selectedBuildings[0];
            } else {
                selectedBuilding = allBuildings && (allBuildings.find(b => b.roles && b.roles.some(r => r === 'BuyersGuide' || r === 'Spectator')) || allBuildings[0]);
            }
            const roles = (selectedBuilding && selectedBuilding.roles) ? selectedBuilding.roles : [(user && user.type === 1) ? 'Buyer' : ''];
            const dossierRights = getRights(roles);
            return {
                ...state,
                all: allBuildings,
                selected: selectedBuilding,
                loading: false,
                rights: { ...state.rights, ...dossierRights },
                buyerGuidesBuildings: allBuildings.filter(b => b.roles && b.roles.some(r => r === 'BuyersGuide' || r === 'Spectator'))
            };
        case commonConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case commonConstants.CHANGE_SUCCESS: {
            const selectedBuilding = action.building;
            localStorage.setItem('building', JSON.stringify(selectedBuilding));
            const roles = selectedBuilding.roles ? selectedBuilding.roles : [user.type === 1 ? 'Buyer' : ''];
            const dossierRights = getRights(roles);
            return {
                ...state,
                selected: selectedBuilding,
                loading: false,
                rights: { ...state.rights, ...dossierRights }

            };
        }
        case commonConstants.UPDATE_RIGHTS_SUCCESS: {
            const { selected } = state;
            let selectedBuilding = null;
            if (!action.buildingId) {
                const selectedBuildings = state.all.filter(x => selected && x.buildingId === selected.buildingId);
                if (selectedBuildings && selectedBuildings.length > 0) {
                    selectedBuilding = selectedBuildings.find(b => b.roles && b.roles.some(r => r === 'BuyersGuide' || r === 'Spectator')) || selectedBuildings[0];
                } else {
                    selectedBuilding = state.all.find(b => b.roles && b.roles.some(r => r === 'BuyersGuide' || r === 'Spectator')) || action.buildings[0];
                }
            } else {
                selectedBuilding = state.all.find(x => x.buildingId === action.buildingId);
            }
            if (selectedBuilding) {
                const roles = selectedBuilding.roles ? selectedBuilding.roles : [user.type === 1 ? 'Buyer' : ''];
                const rights = getRights(roles, 'selected.object.write');
                return { ...state, rights: { ...state.rights, ...rights } }
            }
            return { ...state }
        }
        default:
            return state
    }
}


export function dashboardCount(state = {}, action) {
    switch (action.type) {
        case commonConstants.GETCOUNT_SUCCESS: {
            return {
                ...state,
                ...action.dashboardCount,
                isLoading: false,
                retryAction:true
            }
        }
        case commonConstants.GETCOUNT_ERROR: {
            return {
                ...state,
                isLoading: false,
                retryAction:true
            }
        }
        case commonConstants.GETCOUNT_LOADING: {
            return {
                ...state,
                isLoading: true,
                retryAction:false
            }
        }
        default:
            return state
    }
}

export function workOrderCounts(state = {}, action) {
    switch (action.type) {
        case commonConstants.GET_WORK_ORDERS_COUNTS_LOADING: {
            return {
                ...state,
                workOrderCounts: 0,
                getWorkOrderCountsLoading: true
            }
        }
        case commonConstants.GET_WORK_ORDERS_COUNTS_SUCCESS: {
            return {
                ...state,
                workOrderCounts: action.workOrderCounts,
                getWorkOrderCountsLoading: false
            }
        }
        case commonConstants.GET_WORK_ORDERS_COUNTS_ERROR: {
            return {
                ...state,
                workOrderCounts: 0,
                getWorkOrderCountsLoading: false
            }
        }
        default:
            return state
    }
}

const localApp = localStorage.getItem('app')
const initialAppState = JSON.parse(localApp);

export function app(state = initialAppState, action) {
    switch (action.type) {
        case commonConstants.CHANGE_APP_SUCCESS:
            localStorage.setItem('app', JSON.stringify(action.app));
            if (action.app !== undefined || action.app !== null)
                return action.app;
            return state;
        default:
            return state;
    }
}

export function currentVersionDate(state = null, action) {
    switch (action.type) {
        case commonConstants.GET_CURRENT_VERSION_DATE:
            return action.currentVersionDate;
        default:
            return state;
    }
}

const availableModules = localStorage.getItem('availableSurveyModules')
const initialModuleState = JSON.parse(availableModules);

export function availableSurveyModules(state = { availableSurveyModules: initialModuleState }, action) {
    switch (action.type) {
        case commonConstants.GET_AVAILABLE_SURVEY_MODULES:
            localStorage.setItem('availableSurveyModules', JSON.stringify(action.modules));
            if (action.module !== undefined || action.module !== null)
                return {
                    ...state,
                    availableSurveyModules: action.modules,
                    loadingModules: false
                };
            return {
                ...state,
                loadingModules: false
            }
        case commonConstants.GET_AVAILABLE_SURVEY_MODULES_LOADING:
            return {
                ...state,
                loadingModules: true
            }
        default:
            return state;
    }
}

export function meldingenFilter(state = null, action) {
    switch (action.type) {
        case commonConstants.CHANGE_MELDINGEN_FILTER_SUCCESS:
            return action.list;
        default:
            return state;
    }
}

