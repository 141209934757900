import {  Clear, DateRange,  } from '@mui/icons-material';
import {  Box,  Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { getBanks, getIndustries, getPersonMiddleNames, getPersonTitleBehind, getPersonTitleFor,} from '../../../apis/profileApis';
import { useState } from 'react';
import { deNormalizePhoneNumber, } from '../utils';
import { DatePicker } from '@mui/x-date-pickers';

const ProfileBody = ({ profileData, updateProfileData, errors, clearError }) => {
  const { t } = useTranslation();

  const [dropdownValues, setDropdownValues] = useState({})


  useEffect(() => {
    (async () => {
      const calls = [getPersonTitleFor(), getPersonMiddleNames(), getPersonTitleBehind(), getBanks(), getIndustries()]
      const results = await Promise.all(calls);

      setDropdownValues({ titleFor: results[0].data, middleNames: results[1].data, titleBehind: results[2].data, banks: results[3].data, industries: results[4].data })

    })()
  }, [])

  const formatAddress = (addressObject) => {
    const { houseNo = "", houseNoAddition = "", place = "", postcode = "", street = "" } = addressObject ?? {}
    let address = ""
    if (street) address += `${street} `
    if (houseNo) address += `${houseNo} `
    if (houseNoAddition) address += `${houseNoAddition} `
    if (postcode) address += `, ${postcode}`
    if (place) address += `${place}`
    return address
  }

  const isDisabledProfile = useMemo(() => profileData?.lastName?.toUpperCase()?.replaceAll(".", "") === "NVT", [profileData?.lastName])

  return (
    <>
      <Stack rowGap={2.5} mt={2.5}>
        <Box>
          <Typography variant='body2' fontWeight={600}>{t("general.person")}</Typography>
          <Typography variant='caption'>{t("general.general")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <TextField
          fullWidth
          label={t("general.lastName")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 250 }} 
          value={profileData?.lastName}
          onChange={(e) => {
            updateProfileData({ lastName: e.target.value })
            clearError("lastName")
          }}
          disabled={isDisabledProfile}
          error={errors?.lastName}
          helperText={errors?.lastName}
        />
        <FormControl size="small" fullWidth>
          <InputLabel>{t("general.namePrefixes")}</InputLabel>
          <Select
            value={profileData?.middleName?.id}
            label={t("general.namePrefixes")}
            size='small'
            disabled={isDisabledProfile}
            onChange={(e) => { updateProfileData({ middleName: dropdownValues?.middleNames?.find((item) => item?.id === e.target.value) }) }}
            sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.middleName?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            endAdornment={<IconButton sx={{ visibility: profileData?.middleName?.id ? "visible" : "hidden" }}
              onClick={(e) => {
                if (!isDisabledProfile) {
                  e.stopPropagation()
                  updateProfileData({ middleName: null })
                }
              }}
            ><Clear /></IconButton>}
          >
            {dropdownValues?.middleNames?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}

          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t("general.initials")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={profileData?.initials}
          disabled={isDisabledProfile}
          onChange={(e) => {
            updateProfileData({ initials: e.target.value })
            clearError("initials")
          }}
          error={errors?.initials}
          helperText={errors?.initials}
        />
        <TextField
          fullWidth
          label={t("general.firstName")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabledProfile}
          value={profileData?.firstName}
          onChange={(e) => { updateProfileData({ firstName: e.target.value }) }}
        />
        <FormControl size="small" fullWidth>
          <InputLabel>{t("general.gender")}</InputLabel>
          <Select
            value={profileData?.sex}
            label={t("general.gender")}
            size='small'
            onChange={(e) => { updateProfileData({ sex: e.target.value }) }}
            disabled={isDisabledProfile}
            sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.sex?.toString() ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            
          >

            <MenuItem value="0">{t("general.male")}</MenuItem>
            <MenuItem value="1">{t("general.female")}</MenuItem>
            <MenuItem value="2">{t("general.unknown")}</MenuItem>
          </Select>
        </FormControl>


        <FormControl size="small" fullWidth>
          <InputLabel>{t("user.profile.general.titleFor")}</InputLabel>
          <Select
            value={profileData?.titleFor?.id}
            label={t("user.profile.general.titleFor")}
            size='small'
            onChange={(e) => { updateProfileData({ titleFor: dropdownValues?.titleFor?.find((item) => item?.id === e.target.value) }) }}
            disabled={isDisabledProfile}
            sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.titleFor?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            endAdornment={<IconButton sx={{ visibility: profileData?.titleFor?.id ? "visible" : "hidden" }}
              onClick={(e) => {
                if (!isDisabledProfile) {
                  e.stopPropagation()
                  updateProfileData({ titleFor: null })
                }
              }}
            ><Clear /></IconButton>}
          >
            {dropdownValues?.titleFor?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}
         
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth>
          <InputLabel>{t("user.profile.general.titleBehind")}</InputLabel>
          <Select
            value={profileData?.titleBehind?.id}
            label={t("user.profile.general.titleBehind")}
            size='small'
            disabled={isDisabledProfile}
            onChange={(e) => { updateProfileData({ titleBehind: dropdownValues?.titleBehind?.find((item) => item?.id === e.target.value) }) }}
            sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.titleBehind?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            endAdornment={<IconButton sx={{ visibility: profileData?.titleBehind?.id ? "visible" : "hidden" }}
              onClick={(e) => {
                if (!isDisabledProfile) {
                  e.stopPropagation()
                  updateProfileData({ titleBehind: null })
                }
              }}
            ><Clear /></IconButton>}
          >
            {dropdownValues?.titleBehind?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}
          </Select>
        </FormControl>

     

        <FormControl size="small" fullWidth>
          <InputLabel>{t("general.maritalStatus")}</InputLabel>
          <Select
            value={profileData?.maritalStatus}
            label={t("general.maritalStatus")}
            size='small'
            disabled={isDisabledProfile}
            onChange={(e) => { updateProfileData({ maritalStatus: e.target.value }) }}
            sx={{ "& .MuiSelect-iconOutlined": { display:profileData?.maritalStatus? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            endAdornment={<IconButton sx={{ visibility:profileData?.maritalStatus? "visible" : "hidden" }}
              onClick={(e) => {
                if (!isDisabledProfile) {
                  e.stopPropagation()
                  updateProfileData({ maritalStatus: "" })
                }
              }}
            ><Clear /></IconButton>}
      
          >
            <MenuItem value="Ongehuwd">{t("general.maritalStatus.unMarried")}</MenuItem>
            <MenuItem value="Samenwonend">{t("general.maritalStatus.livingTogether")}</MenuItem>
            <MenuItem value="Gehuwd">{t("general.maritalStatus.married")} </MenuItem>
            <MenuItem value="Voornemens te Huwen">{t("general.maritalStatus.intendingToMarry")}</MenuItem>
            <MenuItem value="Geregistreerd Partnerschap">{t("general.maritalStatus.registeredPartnership")}</MenuItem>
          </Select>
        </FormControl>
      </Stack>


      <Stack rowGap={2.5} mt={2.5}>
        <Box>
          <Typography variant='body2' fontWeight={600}>{t("user.profile.privateData.title")}</Typography>
          <Typography variant='caption'>{t("general.contact")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <TextField
          fullWidth
          label={t("user.profile.privateData.address")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={formatAddress(profileData?.address)}
          disabled
        />
        <TextField
          fullWidth
          label={t("user.profile.privateData.email")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={profileData?.email}
          disabled
        />
        <TextField
          fullWidth
          label={t("user.profile.privateData.telePhone")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          value={deNormalizePhoneNumber(profileData?.telephone)}
          disabled={isDisabledProfile}
          onChange={(e)=>{
            updateProfileData({telephone: deNormalizePhoneNumber(e.target.value)})
            clearError("telephone")
          }
          }
          inputProps={{ maxLength: 10 }}
          error={errors?.telephone}
          helperText={errors?.telephone}
        />
        <TextField
          fullWidth
          label={t("user.profile.privateData.mobile")}
          size='small'
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 10 }}
          value={deNormalizePhoneNumber(profileData?.mobile)}
          disabled={isDisabledProfile}
          onChange={(e) => { 
            updateProfileData({ mobile: deNormalizePhoneNumber(e.target.value) }) 
            clearError("mobile")
          }
          }
          error={errors?.mobile}
          helperText={errors?.mobile}
        />
      </Stack>

      <Stack rowGap={2.5} mt={2.5}>
        <Box>
          <Typography variant='body2' fontWeight={600}>{t("user.profile.miscellaneous.title")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>
         <DatePicker
        variant="standard"
        format="dd-MM-yyyy"
        id="birth-date-picker"
        label={t("user.profile.miscellaneous.dob")}
        autoOk
        maxDate={Date.now()}
        slotProps={{
          openPickerButton: { edge: "end", size: "small" },
          textField: {
            variant: "outlined",
            size: "small",
            InputLabelProps: {
              shrink: true,
            }
          },
        }}
        slots={{
          openPickerButton: IconButton,
          openPickerIcon: DateRange,
        }}
        value={profileData?.birthDate ? new Date(profileData?.birthDate) : profileData?.birthDate}
        onChange={(date) => { 
          updateProfileData({ birthDate: new Date(date).toJSON() })
         }}
      />
        <TextField
          fullWidth
          label={t("user.profile.miscellaneous.birthPlace")}
          size='small'
          type="text"
          inputProps={{ maxLength: 40 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={profileData?.birthPlace}
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ birthPlace: e.target.value }) }}
        />
      </Stack>

      <Stack rowGap={2.5} mt={2.5}>
        <Box>
          <Typography variant='body2' fontWeight={600}>{t("general.financial")}</Typography>
          <Typography variant='caption'>{t("general.bankDetails")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <FormControl size="small" fullWidth>
          <InputLabel>{t("general.bank")}</InputLabel>
          <Select
            value={profileData?.bank?.id}
            label={t("general.bank")}
            size='small'
            disabled={isDisabledProfile}
            onChange={(e) => { updateProfileData({ bank: dropdownValues?.banks?.find((item) => item?.id === e.target.value) }) }}
            sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.bank?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
            endAdornment={<IconButton sx={{ visibility: profileData?.bank?.id ? "visible" : "hidden" }}
              onClick={(e) => {
                if (!isDisabledProfile) {
                  e.stopPropagation()
                  updateProfileData({ bank: null })
                }
              }}
            ><Clear /></IconButton>}
          >
            {dropdownValues?.banks?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}

          </Select>
        </FormControl>
        <TextField
          fullWidth
          label={t("general.bic")}
          size='small'
          type="text"
          inputProps={{ maxLength: 15 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={profileData?.bic}
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ bic: e.target.value }) }}
        />
        <TextField
          fullWidth
          label={t("general.iban")}
          size='small'
          type="text"
          inputProps={{ maxLength: 34 }}
          InputLabelProps={{
            shrink: true,
          }}
          value={profileData?.iban}
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ iban: e.target.value }) }}
        />
      </Stack>
    </>
  )
}

export default ProfileBody