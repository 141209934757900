import { Checkbox, ListItemText, ListItemIcon, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { sortAlphaNumerically } from '../_helpers';

export default function Select({ sortDirection = "asc", selectedList, idKey, labelKey, selectProps= {}, options, searchKey = '', label, ...props }) {
    const classes = useStyles();
    const [filteredOptions, setFilteredOptions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setFilteredOptions(options.sort((a, b) => sortAlphaNumerically(t(a[labelKey]), t(b[labelKey]), sortDirection)))
    }, [options]);



    return (
        <>
            <Autocomplete
                {...selectProps}
                classes={{ inputRoot: classes.autocompleteInputRoot, endAdornment: classes.autocompleteEndAdornment, popper: classes.autocompleteList, option: classes.autocompleteOption }}
                id="tags-standard"
                options={filteredOptions}
                getOptionLabel={(option) => t(option[labelKey])}
                renderInput={(params) => (
                    <TextField
                        className={classes.autocompleteTextField}
                        {...params}
                        variant="standard"
                        label={label}
                    />
                )}
                ListboxProps={{ style: {padding: 0}}}
                renderTags={(value, getTagProps) => <span className={classes.tags}>{value.map((option, index) => t(option[labelKey])).join(", ")}</span>}
                renderOption={(props, option, { selected }) => {
                    //const isSelected = !!selectProps.multiple && selectedList && selectedList.some(a => a[idKey] === item[idKey]);
                    return (
                        <li {...props}>
                            {
                                !!selectProps.multiple ?
                                    <>
                                        <ListItemIcon style={{ minWidth: 'auto', marginLeft: -8 }}>
                                            <Checkbox color="primary" checked={selected} />
                                        </ListItemIcon>
                                        <ListItemText primary={t(option[labelKey])} />
                                    </>
                                    :
                                    <ListItemText primary={t(option[labelKey])} />
                            }
                        </li>
                    )
                }}
            />
        </>
    )
}
const useStyles = makeStyles(theme => ({
    menuListRoot: {
        paddingTop: theme.spacing(0),
    },
    listSubHeader: {
        paddingTop: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        lineHeight: "40px",
    },
    notFoundLabel: {
        textAlign: "center"
    },
    onlyDomOption: {
        visibility: "hidden",
        height: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    autocompleteTextField: {
        padding: `${theme.spacing(0)} !important`,
        boxShadow: "none !important",
    },
    autocompleteEndAdornment: {
        padding: `${theme.spacing(0)} !important`,
        boxShadow: "none !important",
    },
    autocompleteList: {
        // width: "auto !important"
    },
    autocompleteOption: {
        "& span": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    },
    autocompleteInputRoot: {
        flexWrap: "nowrap"
    },
    tags: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));