import React, { useEffect, useRef, useState } from 'react';
import { Divider, Grid, Tabs, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dossiersActions } from '../../_actions/dossiers.actions';
import { userAccountTypeConstants } from '../../_constants';
import { createDndContext, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PriorityHigh } from '@mui/icons-material';
import TabButton from './Common/TabButton';
import { Attachments } from './components/Attachments';
import { GeneralSection } from './GeneralSection';
import ContactList from './Common/ContactList';

const isShow = (type, activeTab, selectedDossier, isBuyer, externalRights, internalRights) => {
    if (type === 'external')
        return ((activeTab === 0 && externalRights) || (isBuyer && selectedDossier.isExternal))
    else if (type === 'internal')
        return ((activeTab === 1 && externalRights || !externalRights && activeTab === 0) && internalRights && !isBuyer);
}

const DossierInformation = (props) => {
    const { canDrawingDossier, updateDossier, selectedDossierBuilding, accessRights, selectedDossier,
        buildings, selected, fileUploading, handleSelectFiles,
        handlePreviewOfFiles, loggedInUserFromRights,
        handleGetAllImages, buildingId } = props;
    const { t } = useTranslation();
    const { user } = useSelector(state => state.authentication);
    const isBuyer = user.type === userAccountTypeConstants.buyer;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const internalRights = accessRights && accessRights.isInternal && (buildingId ? selectedDossier.hasObjectBoundFiles : selectedDossier.hasGeneralFiles);
    const externalRights = accessRights && accessRights.isExternal && (buildingId ? selectedDossier.hasObjectBoundFiles : selectedDossier.hasGeneralFiles);
    const isReadOnlyExternal = !accessRights.hasExternalEditRights || selectedDossier.isArchived || selectedDossier.status === 2 || selectedDossierBuilding.status === 2
    const isReadOnlyInternal = selectedDossier.isArchived || selectedDossier.status === 2 || selectedDossierBuilding.status === 2;
    const [sortingType, setSortingType] = useState('date');
    const [canStartDragging, setCanStartDragging] = useState(false)
    const DndContext = createDndContext(HTML5Backend);
    const managerRef = useRef(DndContext);

    useEffect(() => {
        if (selectedDossier.id && (!selectedDossierBuilding || !selectedDossierBuilding.buildingId) && selectedDossier.hasUpdates) {
            markFileAsViewed(null, null, null, null);
        }
    }, [selectedDossier.id]);

    useEffect(() => {
        if (selectedDossierBuilding.buildingId && selectedDossierBuilding.hasUpdates) {
            markFileAsViewed(null, selectedDossierBuilding.buildingId, null, null);
        }
    }, [selectedDossierBuilding.buildingId, selectedDossier.id]);

    const markFileAsViewed = (dossierFileId, objId, type, subType, isGeneralFile) => {
        const data = {
            dossierId: selectedDossier.id,
            buildingId: objId,
            dossierFileId
        };
        dispatch(dossiersActions.updateDossierLastRead(data, {
            type: type,
            subType,
            dossierId: selectedDossier.id,
            buildingId: objId ? objId : buildingId,
            isBuyer,
            isGeneralFile
        }));
    };

    const exclamationIconRender = (type) => {
        const attachments = buildingId ? selectedDossierBuilding : selectedDossier;
        let key = type === 'internal' ? 'internalFiles' : 'externalFiles';;
        if (buildingId)
            key = type === 'internal' ? 'internalObjectFiles' : 'externalObjectFiles'

        const isUpdate = attachments[key] && [].concat(attachments[key].uploadedFiles || [], attachments[key].archivedFiles || [], attachments[key].deletedFiles || []).some(p => p.hasUpdates)

        return (
            isUpdate &&
            <Tooltip Tooltip title={t('dossier.sidebar.priority.high')}>
                <PriorityHigh color="error" size={'small'} style={{ position: 'relative', left: 6, marginBottom: 0 }} />
            </Tooltip>
        )
    }

    const getFiles = (type, key) => {
        const mainDossierKey = type === 'internal' ? 'internalFiles' : 'externalFiles';
        const mainObjectKey = type === 'internal' ? 'internalObjectFiles' : 'externalObjectFiles';
        if (isBuyer) {
            const generalFiles = ((selectedDossier[mainDossierKey] || {})[key] || []).map(p => ({ ...p, isGeneralFile: true }));
            return [].concat(
                buildingId && selectedDossier.hasObjectBoundFiles ?
                    (selectedDossierBuilding[mainObjectKey] || {})[key] || [] : [],
                (generalFiles)).sort((a, b) => new Date(b.lastModifiedOn) - new Date(a.lastModifiedOn));
        }

        return ((buildingId ? selectedDossierBuilding[mainObjectKey] : selectedDossier[mainDossierKey]) || {})[key];
    }

   return (
        <Grid container className={classes.block}>
            <GeneralSection
                {...props}
                updateDossier={updateDossier}
                canDrawingDossier={canDrawingDossier}
                isReadOnly={selectedDossier.isArchived || selectedDossier.status === 2 || selectedDossierBuilding.status === 2}
                selectedDossier={selectedDossier}
                selectedDossierBuilding={selectedDossierBuilding}
                buildingId={buildingId}
                markFileAsViewed={markFileAsViewed}
                buyerContactInfo={selectedDossierBuilding.buyerContactInfo}
                selectedDossierContacts={selectedDossier.userList} />

            <DndProvider backend={HTML5Backend} manager={managerRef.current.dragDropManager}>
                <Grid container className={classes.attachmentsBlock}>
                    <Grid xs={12} md={canDrawingDossier ? 12 : 8} className={classes.cardContainer}>
                        {!isBuyer && <Grid xs={12}>
                            <Tabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={(e, tab) => setActiveTab(tab)}>
                                {externalRights && <TabButton
                                    activeTab={activeTab}
                                    classes={{ wrapper: classes.tabWrapper }}
                                    setActiveTab={(tab) => setActiveTab(tab)}
                                    viewType={buildingId ? 'object' : 'dossier'}
                                    className={classes.tabs} icon={exclamationIconRender('external')}
                                    tab={0} label={t('dossier.objects.rights.external.title')} />}
                                {internalRights && <TabButton
                                    activeTab={activeTab}
                                    classes={{ wrapper: classes.tabWrapper }}
                                    setActiveTab={(tab) => setActiveTab(tab)}
                                    viewType={buildingId ? 'object' : 'dossier'}
                                    className={classes.tabs} icon={exclamationIconRender('internal')}
                                    tab={1} label={t('dossier.objects.rights.internal.title')} />}
                            </Tabs>
                            <Divider />
                        </Grid>}

                        <Grid item xs={12}>
                            {['external', 'internal'].map(p => {
                                const show = isShow(p, activeTab, selectedDossier, isBuyer, externalRights, internalRights);
                                return show && (
                                    <Attachments key={p} currentTab={activeTab}
                                        canDrawingDossier={canDrawingDossier}
                                        buildingId={buildingId}
                                        canStartDragging={canStartDragging}
                                        setCanStartDragging={(value) => setCanStartDragging(value)}
                                        selectedDossier={selectedDossier} handlePreviewOfFiles={handlePreviewOfFiles}
                                        selected={selected} isObjectView={!!buildingId} fileUploading={fileUploading}
                                        viewType={buildingId ? 'object' : 'dossier'} buildings={buildings}
                                        active={getFiles(p, 'uploadedFiles')} archived={getFiles(p, 'archivedFiles')}
                                        removed={getFiles(p, 'deletedFiles')} handleSelectFiles={handleSelectFiles}
                                        isBuyer={isBuyer} handleGetAllImages={handleGetAllImages}
                                        isReadOnly={p === 'external' ? isReadOnlyExternal : isReadOnlyInternal}
                                        markFileAsViewed={markFileAsViewed} type={p}
                                        sortingType={sortingType} setSortingType={(type) => setSortingType(type)}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>



                    {!canDrawingDossier && <Grid item xs={12} md={4} className={classes.contactList}>
                        <ContactList
                            loggedInUserFromRights={loggedInUserFromRights}
                            selectedDossierContacts={selectedDossier.userList}
                            buyerContactInfo={selectedDossierBuilding.buyerContactInfo} />
                    </Grid>}
                </Grid>
            </DndProvider>
        </Grid>
    )
};

const useStyles = makeStyles((theme) => ({
    block: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.grey[100],
        },

    },
    attachmentsBlock: {
        borderRadius: 6,

        [theme.breakpoints.up("md")]: {
            background: theme.palette.common.white,
            margin: theme.spacing(0, 2),
            boxShadow: theme.shadows[5]
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1, 1, 3),
        }
    },
    tabs: {
        minHeight: 'auto !important',
        padding: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            flexGrow: 1,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12
        },
    },
    tabWrapper: {
        flexDirection: 'row'
    },
    cardContainer: {
        [theme.breakpoints.down('md')]: {
            boxShadow: theme.shadows[5],
            margin: theme.spacing(0, 1),
            background: theme.palette.common.white,
            borderRadius: 5
        }
    },
    contactList: {
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(1, 1, 3),
        }
    }
}));

export default DossierInformation;
