import makeStyles from "@mui/styles/makeStyles";
import { apps } from "../_constants";

export const useRowStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  listViewMargin: {
    margin: 0,
  },
  fullWidth: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    color: theme.palette.grey[900],
    fontWeight: "bold",
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
      "& .MuiButtonBase-root": {
        padding: theme.spacing(1.2),
        "&:nth-last-child()": {
          paddingRight: 0,
        },
      },
      display: "flex",
      padding: theme.spacing(0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: theme.spacing(0),
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 1px",
      marginTop: "auto",
      padding: theme.spacing(0.5, 1.5),
      "& caption": {
        display: "none",
      },
    },
    "& .MuiTableCell-root": {
      [theme.breakpoints.between("xs", "md")]: {
        paddingBottom: 9,
      },
      "& .datatables-noprint": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      },
      position: "relative",
      color: theme.palette.grey[700],
      fontWeight: "normal",
      fontSize: 14,
      padding: `${theme.spacing(0.6, 0.5, 0.6, 0.5)} !important`,
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            paddingRight: "5px",
          },
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
          "& .MuiSvgIcon-root": {
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
          },
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        "& .MuiButtonBase-root": {
          "& .MuiButton-label": {
            color: theme.palette.grey[700],
            fontWeight: "bold",
            [theme.breakpoints.only("xs")]: {
              fontSize: 14,
            },
            [theme.breakpoints.only("sm")]: {
              fontSize: 16,
            },
          },
        },
      },
    },
    "& .MuiTablePagination-actions": {
      "& > button": {
        padding: theme.spacing(0.2),
      },
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "fit-content !important",
      paddingLeft: theme.spacing(0),
    },
    "& .MuiTableCell-footer": {
      padding: `${theme.spacing(0)} !important`,
    },
  },
  customCheckbox: {
    "@media print": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      // borderRight: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0,
      paddingRight: "0px !important",
      "& .MuiSvgIcon-root": {
        height: 18,
      },
    },
  },
  customGridRow: {
    borderRadius: 4,
    "& .MuiTableCell-root": {
      "&:first-child": {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      "&:last-child": {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
      fontSize: "14px",
    },
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[100],
      "& > td > div > div > div > span > aside > div": {
        height: "60px",
        width: "60px",
        opacity: 1,
        zIndex: theme.zIndex.drawer,
      },
    },
  },
  topBorder: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  expandableRow: {
    background: theme.palette.common.white,
    // boxShadow: 'rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px',
    boxShadow: "2px 3px 3px #ccc",
    display: "table-row",
    position: "relative",
    top: 0,
    borderRadius: 4,
    "& .MuiTableCell-root": {
      "&:first-child": {
        borderBottomLeftRadius: 4,
      },
      "&:last-child": {
        borderBottomRightRadius: 4,
      },
    },
  },
  customCell: {
    position: "relative",
    whiteSpace: "break-spaces",
    padding: `${theme.spacing(0.1, 0.5, 0.1, 0.5)} !important`,
  },
  minCellWidth: {
    minWidth: 100,
  },
  wrapMomentContainer: {
    display: "flex",
    alignItems: "center",
    // width: 'calc(100% - 9px)',
    justifyContent: "space-between",
  },
  wrapMomentTitle: {
    whiteSpace: "nowrap",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  papers: {
    // minWidth: 100,
    flexGrow: 1,
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("sm")]: {
      // borderRight: `1px solid ${theme.palette.grey[300]}`,
      "&:nth-last-child(1)": {
        borderRight: `none`,
      },
    },
  },
  paperSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 22,
  },
  cardSubTitle: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    fontWeight: "bold",
  },
  customSmallDeviceRowContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
  detailContainer: {
    marginLeft: 8,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  thumbnail: {
    width: 50,
    height: 50,
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    position: "relative",
    [theme.breakpoints.only("xs")]: {
      width: ({ app }) => (app === apps.aftercare ? 75 : 50),
      height: ({ app }) => (app === apps.aftercare ? 75 : 50),
    },
  },
  requestTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  requestTypeLabel: {
    fontSize: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  requestStatusLabel: {
    fontSize: 12,
    whiteSpace: "nowrap",
    marginBottom: "auto",
  },
  requestBuildingLabel: {
    fontSize: 12,
    color: theme.palette.grey[800],
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  imageBadgeContainer: {
    padding: 3,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.common.white,
    position: "absolute",
    bottom: 3,
    right: 3,
    width: 18,
    height: 18,
    "& span": {
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  locationIconContainer: {
    height: 14,
    width: 14,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: 5,
    fontSize: 10,
  },
  werkbonnenContainer: {
    height: 14,
    // width: 14,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[800],
    marginRight: 2,
    fontSize: 12,
  },
  locationIcon: {
    fontSize: 10,
    fill: theme.palette.common.white,
  },
  borderRight: {
    borderRight: "1px solid grey",
    marginRight: 5,
  },
  groen: {
    border: `1px solid ${theme.palette.success.main}`,
    color: `${theme.palette.success.main} !important`,
    "& > .MuiButton-label": {
      color: `${theme.palette.success.main} !important`,
    },
    "&::before": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  rood: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: `${theme.palette.secondary.main} !important`,
    "& > .MuiButton-label": {
      color: `${theme.palette.secondary.main} !important`,
    },
    "&::before": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  oranje: {
    border: `1px solid ${theme.palette.warning.main}`,
    color: `${theme.palette.warning.main} !important`,
    "& > .MuiButton-label": {
      color: `${theme.palette.warning.main} !important`,
    },
    "&::before": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  requestTypeContainer: {
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipses",
    overflow: "hidden",
  },
  horizontallyCenterContainer: {
    display: "flex",
    paddingLeft: theme.spacing(0.75),
    // justifyContent: "center"
  },
  verticallyCenterContainer: {
    display: "flex",
    alignItems: "center",
  },
  rightAlignedContainer: {
    marginLeft: "auto",
    alignItems: "flex-end",
    flexGrow: 0,
  },
  flexContainer: {
    display: "flex",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  textLeftContainer: {
    textAlign: "left",
  },
  noPaddingContainer: {
    padding: theme.spacing(0),
  },
  relativeContainer: {
    position: "relative",
  },
  maxWidth_100: {
    maxWidth: "100%",
  },
  maxWidth_70: {
    maxWidth: "70%",
  },
  marginRight_5: {
    marginRight: 5,
  },
}));
