import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const LinkButton = ({ label, to, target = '_self', isExternalLink, ...rest }) => {
    const relValue = target === '_blank' ? 'noopener noreferrer' : undefined;
    const buttonProps = {
        sx: {
            padding: 0,
            textTransform: 'none',
            minWidth: 'auto',
            fontSize: '12px',
            wordBreak: 'break-word',
            ...rest.sx
        },
    };

    if (isExternalLink) {
        return (
            <Button
                component="a"
                href={to}
                target={target}
                rel={relValue}
                {...rest}
                {...buttonProps}
            >
                {label}
            </Button>
        );
    }

    return (
        <Button
            component={Link}
            to={to}
            {...rest}
            {...buttonProps}
        >
            {label}
        </Button>
    );
};

export default LinkButton;
