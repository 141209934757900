import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function PageNotFound(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container className={classes.errorContainer}>
            <Typography className={classes.errorMessage} noWrap>
                {t('general.pageNotFound')}
            </Typography>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
    },
    errorMessage: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        fontWeight: 'bold',
        boxShadow: theme.shadows[5],
        borderRadius: 4
    }
}));
