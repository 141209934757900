import { Checkbox, Stack, Typography, styled } from "@mui/material"

const StyledTypography = styled(Typography)({
  color: "#666666"
});

const MethodOfFinance = ({ setFinanceCheckBoxs = () => { }, financeCheckBoxs, disabled, updateContractDetails = () => { } }) => {

  const checkboxHandler = (e) => {
    // if(!financeCheckBoxs.financeownfunds){
    //   setProxyCheckBoxValue(null)
      // }
      setFinanceCheckBoxs({ ...financeCheckBoxs, [e.target.name]: e.target.checked })
      updateContractDetails(e.target.name, e.target.checked ? 'true' : 'false', e.target.name);
  }


  // console.log(updateContractDetails)
  return (
    <Stack rowGap={1}>
      <Typography variant='subtitle1'>Wijze van financiering</Typography>
      <StyledTypography variant='subtitle1'>Graag aankruisen welke opties van toepassing zijn</StyledTypography>
      <StyledTypography variant='subtitle1'>U bent voornemens om het gekochte aan te kopen middels:</StyledTypography>
          <StyledTypography variant='subtitle1'><Checkbox size="small" sx={{ p: 0, m: 0, pr: 1 }} disabled={disabled} name="financeOwnFunds" checked={financeCheckBoxs?.financeOwnFunds} onChange={checkboxHandler} />eigen middelen (welke afkomstig zijn van de rekening van de kopende partij al dan niet middels een onderhandse geldlening);</StyledTypography>
          <StyledTypography variant='subtitle1'><Checkbox size="small" sx={{ p: 0, m: 0, pr: 1 }} disabled={disabled} name="financeMorgageBank" checked={financeCheckBoxs?.financeMorgageBank} onChange={checkboxHandler} />een hypotheek welke zal worden afgesloten bij een bank;</StyledTypography>
          <StyledTypography variant='subtitle1'><Checkbox size="small" sx={{ p: 0, m: 0, pr: 1 }} disabled={disabled} name="financeMorgagePrivate" checked={financeCheckBoxs?.financeMorgagePrivate} onChange={checkboxHandler} />een “particuliere” hypotheek welke zal worden afgesloten bij een derden.</StyledTypography>
      <StyledTypography variant='subtitle1'>Indien u met hypotheek gaat aankopen dient de feitelijke hypotheekopdracht circa 2 weken voor de overdracht in ons bezit te zijn.</StyledTypography>
    </Stack>
  )
}

export default MethodOfFinance