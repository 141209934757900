import { Avatar, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import clsx from "clsx";
import { formatDate } from "../../../_helpers";
import { GetApp, LocationOnOutlined, Lock, PriorityHigh, Schedule, LinkOff, Warning, Link, CheckCircle, Cancel } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../../components/CustomRowStyles";
import { useSelector } from "react-redux";

export const AssignmentCustomRow = (props) => {
    const { setRowsSelected, objectModalListHandler, followUpAssignmentsHandler, rowsSelected, columns, data, identifier, currentRow, dataIndex, onRowClick } = props;
    const classes = { ...useRowStyles(), ...useStyles() };
    const { t } = useTranslation();
    const { assignmentLockIds = [] } = useSelector(state => state.surveying);
    const { user } = useSelector(state => state.authentication);
    const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
    const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};
    if (!selectedRow || !columns.length) return "";
    const { linkedAssignment, isCracked, lockId } = selectedRow;
    const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);
    let answer = selectedRow.answerOptions.find(answer => answer.answerOption === selectedRow.answer) || {};

    return (
        <React.Fragment >
                <TableRow onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(selectedRow);
                }} className={clsx(classes.customGridRow)}>
                    <TableCell>
                        <div className={classes.customSmallDeviceRowContainer}>
                            <div className={classes.verticallyCenterContainer}>
                                <div className={classes.relativeContainer}>
                                    {selectedRow.surveyingMomentDetails.objectId && !selectedRow.surveyingMomentDetails.isBuildingMapped ?
                                        <Tooltip title={t("wkb.objectIsNotLinked")}>
                                            <Avatar className={clsx(classes.thumbnail, classes.avatarThumbnailBroke, 'thumb-exception')} onClick={objectModalListHandler}>
                                                <IconButton size="large">
                                                    <LinkOff className={classes.linkIcon} />
                                                </IconButton>
                                            </Avatar>
                                        </Tooltip>
                                        :
                                        <div className={classes.avatarIconContainer}>
                                            <div className={classes.thumbnail}>
                                                <Avatar
                                                    className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')}>
                                                    {isNaN(selectedRow.object) ? selectedRow.object[0] : selectedRow.object}
                                                    {answer.answerOptionLabel &&
                                                        <div className={clsx(classes.customStatusIcon, classes[answer.answerOptionColor])}>
                                                            <Typography className={clsx(classes.customStatusText,)}>
                                                                {t(answer.answerOptionLabel).charAt(0)}
                                                            </Typography>
                                                        </div>
                                                    }
                                                </Avatar>
                                            </div>
                                            <div className={classes.offlineIconContainer}>
                                                {lockInfo && lockInfo.userId === user.id && lockId && <Tooltip title={t(`general.downloaded`,{moduleType:t(`wkb.assignment.label`)})}>
                                                    <GetApp color='primary' className={classes.offlineIcon} />
                                                </Tooltip>}
                                                {isCracked && <Tooltip title={t(`general.locked`,{moduleType:t(`wkb.assignment.label`)})}>
                                                    <Warning color='primary' className={classes.offlineIcon} />
                                                </Tooltip>}
                                                {!lockInfo && lockId && <Tooltip title={t(`general.lockBroken`,{moduleType:t(`wkb.assignment.label`)})}>
                                                    <Lock color='primary' className={classes.offlineIcon} />
                                                </Tooltip>}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={clsx(classes.requestTitle, classes.grow)}>
                                            {selectedRow.controlPointDesc}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography noWrap className={classes.flexContainer}>

                                            {selectedRow.hasUpdate && (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                                                        <PriorityHigh color="secondary" fontSize="small" size="small" />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            )}
                                            {selectedRow.overdue ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : selectedRow.is48HoursReminder ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" className={clsx(classes.warning, classes.marginRight_5)} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : ("")}
                                            {linkedAssignment &&
                                                <>
                                                    <Link className={clsx(classes.assignmentCustomIcon, classes.marginRight_5)} onClick={followUpAssignmentsHandler} />
                                                    <div className={classes.borderRight} />
                                                </>
                                            }
                                            <Checkbox size="small" className={clsx(classes.customCheckbox, classes.noPaddingContainer)} checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                                                const list = Object.assign([], rowsSelected.list);
                                                const isExist = list.findIndex(p => p === dataIndex);
                                                const rowsData = Object.assign([], rowsSelected.data);
                                                if (isExist >= 0) {
                                                    list.splice(isExist, 1)
                                                    rowsData.splice(isExist, 1)
                                                } else {
                                                    rowsData.push({ [identifier]: selectedRow[identifier] })
                                                    list.push(dataIndex);
                                                };

                                                setRowsSelected({ ...rowsSelected, data: rowsData, list });
                                            }} color='primary' onClick={(e) => e.stopPropagation()} />
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={classes.requestTypeLabel}>
                                            {`${selectedRow.text}`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            className={classes.requestStatusLabel}
                                            style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                                            {selectedRow.wbStatus ?
                                                <>
                                                    {
                                                        (selectedRow.wbStatus === "[WB akkoord]" || selectedRow.wbStatus === "Akkoord") ?
                                                            <CheckCircle fontSize="small" className={classes.colorSuccess} /> :
                                                            (selectedRow.wbStatus === "[WB niet akkoord]" || selectedRow.wbStatus === "Niet akkoord") ?
                                                                <Cancel fontSize="small" color="error" /> :
                                                                (!!selectedRow.wbStatus && selectedRow.wbStatus !== "") ?
                                                                    <PriorityHigh fontSize="small" className={classes.warning} /> :
                                                                    selectedRow.wbStatus
                                                    }
                                                    {selectedRow.wbStatus}
                                                </>
                                                : t(`wkb.assignment.internalStatus.${selectedRow.internalStatus}`)}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel}>
                                            <div className={classes.locationIconContainer}>
                                                <LocationOnOutlined className={classes.locationIcon} />
                                            </div>
                                            <span>
                                                {selectedRow.object}
                                            </span>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel} >
                                            {formatDate(new Date(selectedRow.startDate))} - {formatDate(new Date(selectedRow.endDate))}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    descriptionContainer: {
        flexDirection: "row"
    },
    offlineIconContainer: {
        position: "absolute",
        right: 0,
        top: 0
    },
    offlineIcon: {
        fontSize: 14,
        fill: theme.palette.common.white
    },
    avatarThumbnail: {
        background: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 'bold',
        borderRadius: 6,
        boxShadow: "0px 0px 3px #eee"
    },
    avatarThumbnailBroke: {
        background: '#F63B00',
        fontSize: 25,
        fontWeight: 'bold',
        borderRadius: 6,
        boxShadow: "0px 0px 3px #eee"
    },
    linkIcon: {
        fill: theme.palette.common.white,
        fontSize: 30
    },
    imageBadgeContainer1: {
        bottom: 0,
        right: 0,
        borderRadius: 0,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 6,
        fontSize: 10,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    thumbnail: {
        position: 'relative',
    },
    avatarIconContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    customStatusText: {
        fontSize: 10,
    },
    customStatusIcon: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: '#fff',
        border: '1px solid red',
        borderRadius: '4px 0px 4px 0px',
        width: 15,
        height: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    maxWidth_100: {
        maxWidth: '100%'
    },
    groen: {
        border: `1px solid ${theme.palette.success.main}`,
        '& p': {
            color: theme.palette.success.main,
        }
    },
    rood: {
        border: `1px solid ${theme.palette.secondary.main}`,
        '& p': {
            color: theme.palette.secondary.main,
        }
    },
    oranje: {
        border: `1px solid ${theme.palette.warning.main}`,
        '& p': {
            color: theme.palette.warning.main,
        }
    },
    colorSuccess: {
        color: theme.palette.success.main
    },
    overflowEllipse: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
    },
}));
