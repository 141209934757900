export const dossiersConstants = {
    GET_DRAWING_FILES_LOADING: 'GET_DRAWING_FILES_LOADING',
    GET_DRAWING_FILES_SUCCESS: 'GET_DRAWING_FILES_SUCCESS',
    GET_DRAWING_FILES_ERROR: 'GET_DRAWING_FILES_ERROR',
    GET_DRAWING_SUCCESS: 'GET_DRAWING_SUCCESS',
    GET_DRAWING_ERROR: 'GET_DRAWING_ERROR',
    GET_DOSSIERS_SUCCESS: 'GET_DOSSIERS_SUCCESS',
    GET_DRAWING_DOSSIERS_SUCCESS: 'GET_DRAWING_DOSSIERS_SUCCESS',
    GET_DRAWING_DOSSIERS_ERROR: 'GET_DRAWING_DOSSIERS_ERROR',
    GET_DOSSIERS_ERROR: 'GET_DOSSIERS_ERROR',
    GET_BUILDING_LIST_WITH_DOSSIERS_SUCCESS: 'GET_BUILDING_LIST_WITH_DOSSIERS_SUCCESS',
    GET_BUILDING_LIST_WITH_DOSSIERS_ERROR: 'GET_BUILDING_LIST_WITH_DOSSIERS_ERROR',
    GET_BUILDING_LIST_WITH_DOSSIERS_LOADING: 'GET_BUILDING_LIST_WITH_DOSSIERS_LOADING',
    GET_ALL_DOSSIERS_SUCCESS: 'GET_ALL_DOSSIERS_SUCCESS',
    GET_ALL_DOSSIER_LOADING: 'GET_ALL_DOSSIER_LOADING',
    GET_DEADLINE_DOSSIERS_ERROR: 'GET_DEADLINE_DOSSIERS_ERROR',
    GET_ALL_DOSSIERS_ERROR: 'GET_ALL_DOSSIERS_ERROR',
    GET_DOSSIER_LOADING: 'GET_DOSSIER_LOADING',
    GET_SELECTED_DOSSIERS_SUCCESS: 'GET_SELECTED_DOSSIERS_SUCCESS',
    GET_SELECTED_DOSSIERS_ERROR: 'GET_SELECTED_DOSSIERS_ERROR',
    GET_SELECTED_DOSSIER_LOADING: 'GET_SELECTED_DOSSIER_LOADING',
    GET_SELECTED_DOSSIERS_BUILDING_SUCCESS: 'GET_SELECTED_DOSSIERS_BUILDING_SUCCESS',
    GET_SELECTED_DOSSIERS_BUILDING_ERROR: 'GET_SELECTED_DOSSIERS_BUILDING_ERROR',
    GET_SELECTED_DOSSIER_BUILDING_LOADING: 'GET_SELECTED_DOSSIER_BUILDING_LOADING',
    GET_AVAILABLE_ROLES_LOADING: 'GET_AVAILABLE_ROLES_LOADING',
    GET_AVAILABLE_ROLES_ERROR: 'GET_AVAILABLE_ROLES_ERROR',
    GET_AVAILABLE_ROLES_SUCCESS: 'GET_AVAILABLE_ROLES_SUCCESS',
    ADD_DOSSIER_LOADING: 'ADD_DOSSIER_LOADING',
    UPDATE_DOSSIER_INFORMATION_SUCCESS: 'UPDATE_DOSSIER_INFORMATION_SUCCESS',
    UPDATE_DOSSIER_INFORMATION_ERROR: 'UPDATE_DOSSIER_INFORMATION_ERROR',
    UPDATE_DOSSIER_INFORMATION_LOADING: 'UPDATE_DOSSIER_INFORMATION_LOADING',
    ADD_DOSSIER_SUCCESS: 'ADD_DOSSIER_SUCCESS',
    ADD_DOSSIER_ERROR: 'ADD_DOSSIER_ERROR',
    DELETE_DOSSIER_LOADING: 'DELETE_DOSSIER_LOADING',
    DELETE_DOSSIER_SUCCESS: 'DELETE_DOSSIER_SUCCESS',
    DELETE_DOSSIER_ERROR: 'DELETE_DOSSIER_ERROR',
    GET_IMAGES_FROM_REPAIR_REQUEST_LOADING: 'GET_IMAGES_FROM_REPAIR_REQUEST_LOADING',
    GET_IMAGES_FROM_REPAIR_REQUEST_SUCCESS: 'GET_IMAGES_FROM_REPAIR_REQUEST_SUCCESS',
    GET_IMAGES_FROM_REPAIR_REQUEST_ERROR: 'GET_IMAGES_FROM_REPAIR_REQUEST_ERROR',
    GET_IMAGES_LOADING: 'GET_IMAGES_LOADING',
    GET_IMAGES_SUCCESS: 'GET_IMAGES_SUCCESS',
    GET_IMAGES_ERROR: 'GET_IMAGES_ERROR',
    UPDATE_DOSSIER_RIGHTS_SUCCESS: 'UPDATE_DOSSIER_RIGHTS_SUCCESS',
    UPDATE_DOSSIER_RIGHTS_ERROR: 'UPDATE_DOSSIER_RIGHTS_ERROR',
    UPDATE_DOSSIER_RIGHTS_LOADING: 'UPDATE_DOSSIER_RIGHTS_LOADING',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_ERROR: 'UPDATE_STATUS_ERROR',
    UPDATE_STATUS_LOADING: 'UPDATE_STATUS_LOADING',
    GET_BACKGROUND_IMAGE_SUCCESS: 'GET_BACKGROUND_IMAGE_SUCCESS',
    GET_BACKGROUND_IMAGE_ERROR: 'GET_BACKGROUND_IMAGE_ERROR',
    GET_BACKGROUND_IMAGE_LOADING: 'GET_BACKGROUND_IMAGE_LOADING',
    LINK_FILE_SUCCESS: 'LINK_FILE_SUCCESS',
    LINK_FILE_ERROR: 'LINK_FILE_ERROR',
    UPDATE_DOSSIER_BUILDING_ERROR: 'UPDATE_DOSSIER_BUILDING_ERROR',
    UPDATE_DOSSIER_BUILDING_LOADING: 'UPDATE_DOSSIER_BUILDING_LOADING',
    UPDATE_DOSSIER_BUILDING_SUCCESS: 'UPDATE_DOSSIER_BUILDING_SUCCESS',
    MOVE_FILES_SUCCESS: 'MOVE_FILES_SUCCESS',
    MOVE_FILES_ERROR: 'MOVE_FILES_ERROR',
    MOVE_FILES_LOADING: 'MOVE_FILES_LOADING',
    UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
    UPLOAD_FILES_ERROR: 'UPLOAD_FILES_ERROR',
    UPLOAD_FILES_LOADING: 'UPLOAD_FILES_LOADING',
    UPDATE_DOSSIER_LAST_VIEW_SUCCESS: 'UPDATE_DOSSIER_LAST_VIEW_SUCCESS',
    UPDATE_DOSSIER_LAST_VIEW_ERROR: 'UPDATE_DOSSIER_LAST_VIEW_ERROR',
    UPDATE_DOSSIER_LAST_VIEW_LOADING: 'UPDATE_DOSSIER_LAST_VIEW_LOADING',
    UPDATE_DOSSIER_DEADLINE_ERROR: 'UPDATE_DOSSIER_DEADLINE_ERROR',
    UPDATE_DOSSIER_DEADLINE_SUCCESS: 'UPDATE_DOSSIER_DEADLINE_SUCCESS',
    UPDATE_DOSSIER_DEADLINE_LOADING: 'UPDATE_DOSSIER_DEADLINE_LOADING',
    SELECT_ALL_DOSSIER_BUILDINGS: 'SELECT_ALL_DOSSIER_BUILDINGS',
    SELECT_DOSSIER_BUILDING: 'SELECT_DOSSIER_BUILDING',
    SELECT_ALL_BUILDINGS_COLUMN: 'SELECT_ALL_BUILDINGS_COLUMN',
    MOVE_DOSSIER_LOADING: 'MOVE_DOSSIER_LOADING',
    MOVE_DOSSIER_ERROR: 'MOVE_DOSSIER_ERROR',
    MOVE_DOSSIER_SUCCESS: 'MOVE_DOSSIER_SUCCESS',
    FILTER_DOSSIERS: 'FILTER_DOSSIERS',
    GET_ALL_PROJECTS_DOSSIERS_LOADING: 'GET_ALL_PROJECTS_DOSSIERS_LOADING',
    GET_ALL_PROJECTS_DOSSIERS_SUCCESS: 'GET_ALL_PROJECTS_DOSSIERS_SUCCESS',
    GET_ALL_PROJECTS_DOSSIERS_ERROR: 'GET_ALL_PROJECTS_DOSSIERS_ERROR',
    PIN_PROJECT_SUCCESS: 'PIN_PROJECT_SUCCESS',
    UPDATE_PIN_UNPIN_DOSSIER_PROJECT_LOADING: 'UPDATE_PIN_UNPIN_DOSSIER_PROJECT_LOADING',
    SET_DOSSIER_FILTERD_PROJECTS_DATA: 'SET_DOSSIER_FILTERD_PROJECTS_DATA',
    SET_DOSSIER_PROJECT_DATA_WITH_PINNED_INFO: 'SET_DOSSIER_PROJECT_DATA_WITH_PINNED_INFO',
    UPDATE_PIN_UNPIN_DOSSIER_PROJECT_SUCCESS: 'UPDATE_PIN_UNPIN_DOSSIER_PROJECT_SUCCESS',
    UPDATE_PIN_UNPIN_DOSSIER_PROJECT_ERROR: 'UPDATE_PIN_UNPIN_DOSSIER_PROJECT_ERROR',
    GET_PINNED_DOSSIER_PROJECTS_LOADING: 'GET_PINNED_DOSSIER_PROJECTS_LOADING',
    GET_PINNED_DOSSIER_PROJECTS_SUCCESS: 'GET_PINNED_DOSSIER_PROJECTS_SUCCESS',
    GET_PINNED_DOSSIER_PROJECTS_ERROR: 'GET_PINNED_DOSSIER_PROJECTS_ERROR',
};
