import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  TextField,
  DialogContent,
  DialogActions,
  Dialog,
  DialogContentText,
  CircularProgress,
  styled,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { getRecipientsEmails } from "../../../apis/surveyingApis";
import { useDispatch } from "react-redux";
import { surveyActions } from "../../../_actions";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useInternetStatusDetector } from "../../../_helpers";

export default function PreviewAndSign({
  open,
  closeModalHandler,
  setIsOpenSignature,
  openEmailModal,
  isSecondSignature = false,
  setId = () => 0,
  setIsEmailPage = () => 0,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailsList, setEmailsList] = useState({ to: [], cc: [], bcc: [] });
  const [backdrop, setBackdrop] = useState(false);
  const { selectedInspection, canSendMailSuccessfully, canSendMailError } =
    useSelector((state) => state.surveying);
  const [validEmails, setValidEmails] = useState({
    toEmails: false,
    ccEmails: false,
    bccEmails: false,
  });
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (openEmailModal && selectedInspection)
      getRecipientsEmails(selectedInspection.surveyId)
        .then((data) => {
          setEmailsList({ ...data.data, surveyId: undefined });
        })
        .catch((er) => {
          console.log(er);
        });
  }, [openEmailModal, selectedInspection]);

  useEffect(() => {
    validation();
  }, [emailsList]);

  useEffect(() => {
    if (isSendingMail && !canSendMailSuccessfully && !canSendMailError) {
      setBackdrop(true);
      setIsSendingMail(false);
      setError(null);
    } else if (isSendingMail && !canSendMailSuccessfully && canSendMailError) {
      setIsSendingMail(false);
      setError(canSendMailError.message);
    }
  }, [canSendMailSuccessfully, canSendMailError]);

  const validation = () => {
    const validations = Object.keys(emailsList).reduce((prev, key) => {
      let isValid = true;
      if (emailsList[key] && emailsList[key].length) {
        const emails = emailsList[key];
        isValid = emails.every((email) => {
          const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            email
          );
          return valid;
        });
      }
      return { ...prev, [key + "Emails"]: isValid };
    }, {});
    setValidEmails(validations);
  };

  const handleChange = ({ target: { value, name } }) => {
    const emails = value ? value.split(",") : [];
    setEmailsList((p) => ({ ...p, [name]: emails }));
  };

  const handleSendMails = () => {
    dispatch(
      surveyActions.sendMails(
        selectedInspection.surveyId,
        emailsList,
        selectedInspection.surveyType,
        isSecondSignature
      )
    );
    setIsSendingMail(true);
  };

  const handleBackDropClose = () => {
    setBackdrop(false);
    setIsOpenSignature(false);
  };
  const isOnline = useInternetStatusDetector();
  return (
    <>
      <BootstrapDialog
        onClose={closeModalHandler}
        aria-labelledby="customized-dialog-title"
        open={openEmailModal}
      >
        <div className={classes.emailContainer}>
          <Paper elevation={0} className={classes.emailSubContainer}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={closeModalHandler}
              size="large"
            >
              <Close />
            </IconButton>
            <div className={classes.emailLogo}>
              <img src='/Content/Group_533.png'  alt="emailLogo" />
              <Typography className={classes.emailText}>
                {t("survey.preview.enterEmail")}
              </Typography>
            </div>
            <div className={classes.emailTextContainer}>
              <TextField
                label={t("general.email.to")}
                id="outlined-size-small"
                value={emailsList.to.length ? emailsList.to.join(",") : ""}
                variant="outlined"
                size="small"
                name="to"
                onChange={handleChange}
                className={clsx(
                  classes.emailTextField,
                  !validEmails.toEmails && classes.invalidEmail
                )}
                helperText={
                  !validEmails.toEmails
                    ? t("survey.preview.invalidEmail")
                    : null
                }
                error={!validEmails.toEmails}
              />
              <TextField
                label={t("general.email.cc")}
                id="outlined-size-small"
                value={emailsList.cc.length ? emailsList.cc.join(",") : ""}
                variant="outlined"
                size="small"
                name="cc"
                onChange={handleChange}
                className={clsx(
                  classes.emailTextField,
                  !validEmails.ccEmails && classes.invalidEmail
                )}
                helperText={
                  !validEmails.ccEmails
                    ? t("survey.preview.invalidEmail")
                    : null
                }
                error={!validEmails.ccEmails}
              />
              <TextField
                label={t("general.email.bcc")}
                id="outlined-size-small"
                value={emailsList.bcc.length ? emailsList.bcc.join(",") : ""}
                variant="outlined"
                size="small"
                name="bcc"
                onChange={handleChange}
                className={clsx(
                  classes.emailTextField,
                  !validEmails.bccEmails && classes.invalidEmail
                )}
                helperText={
                  !validEmails.bccEmails
                    ? t("survey.preview.invalidEmail")
                    : null
                }
                error={!validEmails.bccEmails}
              />
            </div>
            <div className={classes.emailButtonContain}>
              <Button
                disabled={
                  canSendMailSuccessfully ||
                  !emailsList.to.length ||
                  !validEmails.toEmails ||
                  !validEmails.ccEmails ||
                  !validEmails.bccEmails
                }
                variant="outlined"
                className={classes.emailButton}
                onClick={handleSendMails}
                color="primary"
              >
                {canSendMailSuccessfully ? (
                  <CircularProgress size={20} />
                ) : (
                  t("general.send")
                )}
              </Button>
            </div>
            <Dialog
              className={classes.backdrop}
              open={backdrop}
              onClose={handleBackDropClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContextText}
                >
                  {isOnline
                    ? t("survey.preview.emailSentInfo")
                    : t("survey.preview.emailSentInfoOffline")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.dialogButtonPrimary}
                  onClick={handleBackDropClose}
                >
                  {t("general.close")}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              className={classes.backdrop}
              open={!!error}
              onClose={() => setError(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContextText}
                >
                  {t(error)}
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </Paper>
        </div>
      </BootstrapDialog>
    </>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialog-paper": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "60%",
    },
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    padding: theme.spacing(0.875, 1.25),
  },
  dialogContextText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  backdrop: {
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  emailButtonContain: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
  },
  emailButton: {
    fontSize: 16,
    fontWeight: "bold",
    padding: theme.spacing(0.9, 3),
    borderRadius: 10,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 14,
      borderRadius: 5,
    },
  },
  emailTextField: {
    "& .MuiInputBase-input": {
      height: 30,
      [theme.breakpoints.between("xs", "md")]: {
        height: 20,
      },
    },
  },
  emailText: {
    marginTop: 10,
    color: theme.palette.grey[800],
    fontSize: 16,
    fontWeight: "bold",
    margin: theme.spacing(3),
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 14,
    },
  },
  emailTextContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
  },
  emailContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  emailLogo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 30,
  },
  emailSubContainer: {
    position: "relative",
    maxWidth: "620px",
    width: "620px",
    height: "auto",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "90%",
      width: "90%",
    },
    [theme.breakpoints.only("sm")]: {
      maxWidth: "80%",
      width: "80%",
    },
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
}));
