import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  Card,
  Chip,
  CircularProgress,
  alpha,
  Grid,
  Hidden,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ArrowBack,
  ArrowUpward,
  CheckCircle,
  Close,
  Description,
  House,
  MoreHoriz,
  PriorityHigh,
  Search,
  SwapVert,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate, history, sortAlphaNumerically } from "../../_helpers";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ViewSwitcher from "./components/ViewSwitcher";
import { getPathForDossierDetail } from "../../_helpers/redirectHelper";
import { apps } from "../../_constants";

const SORTING_TYPES = [
  { key: "name", label: "general.name" },
  { key: "deadline", label: "dossier.general.deadline.title" },
];
const SORTING_TYPES_FOR_BUILDING = [
  { key: "buyerRenterName", label: "dossier.buyerName" },
  { key: "address", label: "layout.navbar.address" },
  { key: "buildingNoIntern", label: "general.objects" },
];
const DOSSIER_TYPE_FILTERS = [
  { key: "all", label: "general.all" },
  { key: "external", label: "dossier.external" },
  { key: "internal", label: "general.internal" },
];
const DashboardCardView = ({ ...props }) => {
  const classes = useStyles();
  const {
    dossiers,
    drawingDossier,
    isDossiersLoading,
    buildingList,
    loading,
    getBuildingLoading,
  } = useSelector((state) => state.dossier);
  const { all, rights } = useSelector((state) => state.buildings);
  const app = useSelector((state) => state.app);
  const { viewType } = useParams();
  const [selectedProjectDossier] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const searchBoxRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDossierType, setSelectedDossierType] = useState(null);
  const [filterAnchorElement, setFilterAnchorElement] = useState(null);
  const [sortingEl, setSortingEl] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const buildingIdParam = params.get("buildingId");
  const [selectedBuilding, setSelectedBuilding] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingType, setSortingType] = useState(null);
  const { t } = useTranslation();
  const [buildingData, setBuildingData] = useState([]);

  useEffect(() => {
    if (viewType !== "dossier") {
      setBuildingData(buildingList);
    }
  }, [viewType, buildingList, all]);

  useEffect(() => {
    if (openFilter && searchBoxRef && searchBoxRef.current)
      searchBoxRef.current.focus();
  }, [openFilter, searchBoxRef]);

  useEffect(() => {
    if (buildingIdParam) {
      setSelectedBuilding(
        selectedProjectDossier &&
          selectedProjectDossier.dossiersForBuildings &&
          selectedProjectDossier.dossiersForBuildings.find(
            (a) => a.buildingId === buildingIdParam
          )
      );
      setIsLoading(false);
    } else if (selectedBuilding && selectedBuilding.length)
      setSelectedBuilding([]);
  }, [buildingIdParam, selectedProjectDossier]);

  const resetFilters = () => {
    setOpenFilter((a) => !a);
    setSearchQuery("");
  };

  const handleSelectOption = (option) => {
    setSelectedDossierType(option);
    setFilterAnchorElement(null);
  };

  const handleMore = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectBuilding = (building) => {
    setIsLoading(true);
    history.push("?buildingId=" + building.buildingId);
  };

  const handleSearchDossiers = ({ target: { value } }) => {
    setSearchQuery(value);
  };

  const handleSort = (key) => {
    setSortingType({
      key,
      type: sortingType && sortingType.type === "asc" ? "desc" : "asc",
    });
  };

  const canShowFilter =
    viewType === "dossier" || (buildingIdParam && selectedBuilding);

  return (
    <Grid container className={classes.block}>
      <Hidden mdUp>
        {
          <IconButton
            onClick={history.goBack}
            color="primary"
            className={clsx(classes.arrowDownIcon, classes.backButton)}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        }
        {
          <Slide direction="right" mountOnEnter unmountOnExit in={openFilter}>
            <AppBar position="sticky" className={classes.appbar}>
              <Toolbar
                className={clsx(classes.toolbar, classes.smallDeviceToolbar)}
              >
                <div className={classes.filterContainer}>
                  <TextField
                    autoComplete="off"
                    inputRef={searchBoxRef}
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchDossiers}
                    variant="standard"
                    placeholder={t(
                      canShowFilter
                        ? "dossier.serchbarPlaceholder.dossierLabel"
                        : "general.search" 
                    )+ !canShowFilter ?"..." :""}
                    id="dossier-name"
                    className={classes.filterTextField}
                    InputProps={{
                      endAdornment: canShowFilter && (
                        <InputAdornment InputAdornment position="end">
                          {selectedDossierType ? (
                            <Chip
                              label={t(selectedDossierType.label)}
                              onDelete={() => setSelectedDossierType(null)}
                            />
                          ) : (
                            <IconButton
                              onClick={(e) =>
                                setFilterAnchorElement(e.currentTarget)
                              }
                              className={classes.iconButton}
                              aria-label="more"
                              size="large"
                            >
                              <MoreHoriz />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    className={classes.iconButton}
                    color="primary"
                    onClick={resetFilters}
                    size="large"
                  >
                    <Close />
                  </IconButton>
                  <IconButton
                    className={classes.toolbarActionButton}
                    onClick={(e) => setSortingEl(e.currentTarget)}
                    size="large"
                  >
                    <SwapVert />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>
          </Slide>
        }
      </Hidden>
      <Hidden mdDown>
        <Grid item xs={12} className={classes.toolbar}>
          {canShowFilter && selectedDossierType && (
            <Chip
              className={classes.filterChip}
              label={t(selectedDossierType.label)}
              onDelete={() => setSelectedDossierType(null)}
            />
          )}
          <div className={classes.searchAnimation}>
            {
              <Slide
                direction="left"
                mountOnEnter
                unmountOnExit
                in={openFilter}
                timeout={{ enter: 2 }}
              >
                <TextField
                  autoComplete="off"
                  inputRef={searchBoxRef}
                  fullWidth
                  value={searchQuery}
                  onChange={handleSearchDossiers}
                  variant="standard"
                  placeholder={t(
                    viewType === "dossier" ||
                      (buildingIdParam && selectedBuilding)
                      ? "dossier.serchbarPlaceholder.dossierLabel"
                      : "general.search" 
                  ) + (viewType === "dossier" ||
                  (buildingIdParam && selectedBuilding)) ? "": "..."}
                  id="dossier-name"
                  className={classes.searchBox}
                />
              </Slide>
            }

            {!openFilter ? (
              <IconButton
                className={classes.toolbarActionButton}
                onClick={() => setOpenFilter((a) => !a)}
                size="large"
              >
                <Typography className={classes.toolbarText}>
                  <Search className={classes.toolbarIcon} />
                </Typography>
              </IconButton>
            ) : (
              <IconButton
                className={classes.toolbarActionButton}
                onClick={resetFilters}
                size="large"
              >
                <Close />
              </IconButton>
            )}
          </div>
          <IconButton
            className={classes.toolbarActionButton}
            onClick={(e) => setSortingEl(e.currentTarget)}
            size="large"
          >
            <Typography className={classes.toolbarText}>
              <SwapVert className={classes.toolbarIcon} />
            </Typography>
          </IconButton>
          {canShowFilter && (
            <IconButton
              className={classes.toolbarActionButton}
              onClick={(e) => setFilterAnchorElement(e.currentTarget)}
              size="large"
            >
              <Typography className={classes.toolbarText}>
                <Icon className={classes.toolbarIcon}>filter_alt</Icon>
              </Typography>
            </IconButton>
          )}
        </Grid>
      </Hidden>
      <Slide direction="left" mountOnEnter unmountOnExit in={!isLoading}>
        <Grid container xs={12} className={classes.cardsContainer}>
          {isLoading ||
          loading ||
          isDossiersLoading ||
          (viewType === "building" && getBuildingLoading) ? (
            <div className={classes.loader}>
              <CircularProgress size={30} />
            </div>
          ) : (
            (viewType === "dossier" || buildingIdParam) &&
            !isLoading && (
              <DossierCard
                searchQuery={searchQuery}
                selectedDossierType={selectedDossierType}
                handleMore={handleMore}
                drawingDossier={drawingDossier}
                sort={sortingType}
                buildingId={buildingIdParam}
                selectedProjectDossier={selectedProjectDossier}
                dossiers={
                  selectedBuilding && buildingIdParam
                    ? selectedBuilding.dossierList
                    : dossiers.openOrClosedDossiers
                }
              />
            )
          )}
          {!buildingIdParam && viewType === "building" && !isLoading && (
            <BuildingCard
              handleSelectBuilding={handleSelectBuilding}
              searchQuery={searchQuery}
              sort={sortingType}
              handleMore={handleMore}
              buildingList={buildingData}
              all={all}
              selectedProjectDossier={selectedProjectDossier}
            />
          )}
        </Grid>
      </Slide>
      <Hidden mdUp>
        <div className={classes.speedDialActions}>
          {!props.isBuyer &&
            (rights["dossier.canSwitchView"] || apps.aftercare === app) && (
              <ViewSwitcher />
            )}
          <Button
            className={classes.speedDialIconButton}
            variant="contained"
            color="primary"
            onClick={() => setOpenFilter((a) => !a)}
          >
            <Icon>filter_alt</Icon>
          </Button>
        </div>
      </Hidden>
      <Popover
        id={"select-sort"}
        open={!!sortingEl}
        anchorEl={sortingEl}
        onClose={() => setSortingEl(null)}
        disableRestoreFocus
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className={classes.filterPopover}
        classes={{
          root: classes.filterPopover,
        }}
      >
        <Paper className={classes.paper}>
          <MenuList>
            {canShowFilter &&
              SORTING_TYPES.map((el) => (
                <MenuItem
                  className={classes.sortingItem}
                  selected={sortingType && sortingType.key === el.key}
                  onClick={() => handleSort(el.key)}
                >
                  {sortingType && sortingType.key === el.key && (
                    <ArrowUpward
                      fontSize="small"
                      className={clsx(
                        classes.expandCollapseIcon,
                        sortingType.type === "asc" && classes.expandLess
                      )}
                    />
                  )}
                  {t(el.label)}
                </MenuItem>
              ))}
            {viewType === "building" &&
              !buildingIdParam &&
              SORTING_TYPES_FOR_BUILDING.map((el) => (
                <MenuItem
                  className={classes.sortingItem}
                  selected={sortingType && sortingType.key === el.key}
                  onClick={() => handleSort(el.key)}
                >
                  {sortingType && sortingType.key === el.key && (
                    <ArrowUpward
                      fontSize="small"
                      className={clsx(
                        classes.expandCollapseIcon,
                        sortingType.type === "asc" && classes.expandLess
                      )}
                    />
                  )}
                  {t(el.label)}
                </MenuItem>
              ))}
          </MenuList>
        </Paper>
      </Popover>
      {canShowFilter && (
        <Popover
          className={classes.filterOptions}
          id={"filter-popover"}
          open={Boolean(filterAnchorElement)}
          anchorEl={filterAnchorElement}
          onClose={() => setFilterAnchorElement(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          {DOSSIER_TYPE_FILTERS.map((p) => (
            <MenuItem key={p} value={p} onClick={() => handleSelectOption(p)}>
              {t(p.label)}
            </MenuItem>
          ))}
        </Popover>
      )}
    </Grid>
  );
};

const DossierCard = ({
  sort,
  searchQuery,
  selectedDossierType,
  handleMore,
  buildingId,
  dossiers,
  drawingDossier,
  ...props
}) => {
  const customSmallDeviceWidth = useMediaQuery("(max-width:700px)");
  const classes = useStyles();
  const { ...project } = props.selectedProjectDossier;
  const { t } = useTranslation();
  const { viewType, selectedDataId } = useParams();
  const [filteredDossiers, setFilteredDossiers] = useState([]);
  useEffect(() => {
    handleFilter();
  }, [selectedDossierType, dossiers, searchQuery, sort]);

  const handleFilter = () => {
    if (dossiers) {
      let data = dossiers.filter((d) => {
        const searchWithName =
          !searchQuery || d.name.toLowerCase().includes(searchQuery);
        const searchWithType =
          !selectedDossierType ||
          selectedDossierType.key === "all" ||
          (selectedDossierType.key === "external"
            ? d.isExternal
            : !d.isExternal);
        return searchWithName && searchWithType;
      });
      if (sort) {
        data = data.sort((a, b) =>
          sortAlphaNumerically(a[sort.key], b[sort.key], sort.type)
        );
      }
      if (drawingDossier) data.unshift(drawingDossier);
      setFilteredDossiers(data);
    }
  };

  const handleNavigate = (e, buildingId, dossierId) => {
    e.preventDefault();
    e.stopPropagation();
    let url = `/nazorg/${viewType}`;
    url += `/${selectedDataId}${getPathForDossierDetail(
      viewType,
      buildingId,
      dossierId
    )}`;
    history.push(url, { comesFromDashboard: true });
  };

  return (
    <>
      {filteredDossiers.map((dossier, i) => {
        return (
          <Grid
            item
            xs={6}
            sm={customSmallDeviceWidth ? 4 : 3}
            md={3}
            lg={3}
            xl={2}
            className={classes.cardGrid}
          >
            <Card
              key={dossier.id}
              elevation={4}
              className={classes.card}
              onClick={(e) => handleNavigate(e, null, dossier.id)}
            >
              <Description className={classes.floatingIcon} />
              <div className={classes.actionContainer}>
                <IconButton
                  className={classes.moreAction}
                  onClick={(e) => handleMore(e)}
                  size="large"
                >
                  <MoreHoriz className={classes.moreActionIcon} />
                </IconButton>
              </div>
              <div className={classes.informationContainer}>
                <Typography noWrap className={classes.title}>
                  {dossier.name}
                </Typography>
                <Typography noWrap className={classes.deadline}>
                  {!!dossier.deadline && formatDate(new Date(dossier.deadline))}
                  &nbsp;
                </Typography>
              </div>
              <div className={classes.chipContainer}>
                {(dossier.status === 2 || dossier.hasUpdates) && (
                  <div className={classes.objectInfoChip}>
                    {dossier.status === 2 && (
                      <Tooltip title={t("dossier.status.2")}>
                        <CheckCircle className={classes.completedIcon} />
                      </Tooltip>
                    )}
                    <div className={classes.objectContainer}>
                      {dossier.hasUpdates && (
                        <PriorityHigh className={classes.dangerColor} />
                      )}
                    </div>
                  </div>
                )}
                <IconButton className={classes.visibilityIcon} size="large">
                  {dossier.isExternal ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </div>
            </Card>
          </Grid>
        );
      })}
      {!filteredDossiers.length && !project && (
        <Grid item xs={12} className={classes.cardGrid}>
          <Typography className={classes.textAlignCenter}>
            {t("dossier.notFound")}
          </Typography>
        </Grid>
      )}
    </>
  );
};

const BuildingCard = ({
  sort,
  searchQuery,
  handleSelectBuilding,
  drawingDossier,
  handleMore,
  selectedProjectDossier,
  buildingList,
  all,
  ...props
}) => {
  const customSmallDeviceWidth = useMediaQuery("(max-width:700px)");
  const classes = useStyles();
  const { dossiersForBuildings, ...project } = selectedProjectDossier;
  const [filteredBuildings, setFilteredBuildings] = useState([]);
  const { t } = useTranslation();
  const { viewType, selectedDataId } = useParams();

  useEffect(() => {
    handleFilter();
  }, [searchQuery, sort, buildingList]);

  const handleFilter = () => {
    if (buildingList) {
      let data = buildingList.filter((b) => {
        const searchWithBuyerName =
          !searchQuery ||
          (b.buyerRenterName &&
            b.buyerRenterName.toLowerCase().includes(searchQuery));
        const searchWithAddress =
          !searchQuery ||
          (b.address && b.address.toLowerCase().includes(searchQuery));
        const searchWithBuildingNoIntern =
          !searchQuery ||
          (b.buildingNoIntern &&
            b.buildingNoIntern.toLowerCase().includes(searchQuery));
        return (
          searchWithBuyerName || searchWithAddress || searchWithBuildingNoIntern
        );
      });
      if (sort) {
        data = data.sort((a, b) =>
          sortAlphaNumerically(a[sort.key], b[sort.key], sort.type)
        );
      }
      setFilteredBuildings(data);
    }
  };

  return (
    <>
      {filteredBuildings.map((building, i) => {
        const completedCount = building.dossierList.filter(
          (p) => p.status === 2
        ).length;
        const hasUpdatesCount = building.dossierList.filter(
          (p) => p.hasUpdates
        ).length;
        return (
          <Grid
            item
            xs={6}
            sm={customSmallDeviceWidth ? 4 : 3}
            md={3}
            lg={3}
            xl={2}
            className={classes.cardGrid}
          >
            <Card
              onClick={() => handleSelectBuilding(building)}
              key={building.buildingId}
              elevation={4}
              className={clsx(classes.card, classes.buildingCard)}
            >
              <House className={classes.floatingIcon} />
              <div className={classes.actionContainer}>
                <IconButton
                  className={classes.moreAction}
                  onClick={(e) => handleMore(e)}
                  size="large"
                >
                  <MoreHoriz className={classes.moreActionIcon} />
                </IconButton>
              </div>
              <div
                className={clsx(
                  classes.informationContainer,
                  classes.buildingInfo
                )}
              >
                <Typography noWrap className={classes.buildingCardTitle}>
                  {building.buyerRenterName}
                </Typography>
                <Typography noWrap className={classes.buildingCardTitle}>
                  {building.address}
                </Typography>
                <div className={classes.buildingIconContainer}>
                  {(hasUpdatesCount && completedCount) > 0 && (
                    <>
                      <Typography
                        noWrap
                        className={clsx(
                          classes.buildingCardTitle,
                          classes.buildingSpacingRight
                        )}
                      >
                        {building.buildingNoIntern}
                      </Typography>
                      {hasUpdatesCount > 0 && (
                        <Typography
                          noWrap
                          className={clsx(
                            classes.buildingCardTitle,
                            classes.buildingSpacingLeft
                          )}
                        >
                          {hasUpdatesCount}
                          <PriorityHigh className={classes.dangerColor} />
                        </Typography>
                      )}
                      {completedCount > 0 && (
                        <Typography
                          noWrap
                          className={classes.buildingCardTitle}
                        >
                          {completedCount}
                          <CheckCircle className={classes.completedIcon} />
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Card>
          </Grid>
        );
      })}
      {!filteredBuildings.length && !project && (
        <Grid item xs={12} className={classes.cardGrid}>
          <Typography className={classes.textAlignCenter}>
            {t("dossier.notFound")}
          </Typography>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  expandCollapseIcon: {
    fill: theme.palette.grey[700],
    transition: "0.6s all",
    transform: "rotate(0deg)",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    position: "absolute",
    left: 2,
  },
  expandLess: {
    transform: "rotate(-180deg)",
  },
  sortingItem: {
    paddingLeft: theme.spacing(2.5),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  filterChip: {
    marginRight: "auto",
    marginLeft: theme.spacing(1),
  },
  filterOptions: {
    "& svg": {
      color: `${theme.palette.grey[500]} !important`,
    },
    "& .MuiIcon-root": {
      color: `${theme.palette.grey[500]} !important`,
    },
    "& .MuiPopover-paper": {
      color: `${theme.palette.grey[500]} !important`,
      minWidth: 160,
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingLeft: theme.spacing(2),
    minHeight: 48,
  },
  filterTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0 !important",
    },
    "& input": {
      textOverflow: "ellipsis",
    },
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  speedDialActions: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    bottom: 10,
    right: 16,
    zIndex: theme.zIndex.speedDial,
    ["@media (orientation: portrait)"]: {
      bottom: 64,
    },
  },
  searchBox: {
    paddingLeft: theme.spacing(1),
    "& input": {
      padding: theme.spacing(0, 1),
      height: 30,
      background: theme.palette.common.white,
      borderRadius: 4,
      marginRight: 5,
    },
    "& .MuiInputBase-root:before": {
      borderBottom: "none !important",
    },
    "& .MuiInputBase-root:after": {
      borderBottom: "none !important",
    },
  },
  speedDialIconButton: {
    margin: 4,
    borderRadius: 25,
    maxWidth: 50,
    maxHeight: 50,
    minWidth: 50,
    minHeight: 50,
    padding: theme.spacing(1),
  },
  block: {
    width: "100%",
  },
  appbar: {
    position: "absolute",
  },
  toolbar: {
    [theme.breakpoints.up("md")]: {
      marginBottom: 5,
      marginTop: 10,
      height: 32,
    },
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(1),
    overflow: "hidden",
  },
  smallDeviceToolbar: {
    backgroundColor: theme.palette.common.white,
    minHeight: 0,
    padding: theme.spacing(0),
  },
  toolbarText: {
    fontSize: 14,
    color: theme.palette.common.black,
    display: "flex",
    alignItems: "center",
  },
  toolbarIcon: {
    fill: theme.palette.common.black,
    color: theme.palette.common.black,
    fontSize: 18,
  },
  searchAnimation: {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    overflow: "hidden",
  },
  toolbarActionButton: {
    margin: theme.spacing(0, 0.4),
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(0.5, 1.2),
    "&:hover": {
      background: theme.palette.common.white,
    },
  },

  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  cardGrid: {
    padding: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0.5),
    },
  },
  card: {
    width: "100%",
    height: 95,
    borderRadius: 5,
    background: theme.palette.common.white,
    position: "relative",
    padding: theme.spacing(1),
  },
  buildingCard: {
    display: "flex",
    flexDirection: "column",
  },
  floatingIcon: {
    position: "absolute",
    height: 45,
    width: 45,
    color: alpha(theme.palette.grey[500], 0.2),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: -4,
  },
  moreAction: {
    padding: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    height: 24,
    width: 24,
  },
  moreActionIcon: {
    height: 16,
    width: 16,
  },
  informationContainer: {
    width: "100%",
  },
  buildingInfo: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: 1,
  },
  projectBuildingInfo: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
  },
  title: {
    textAlign: "center",
    lineHeight: "16px",
    fontSize: 14,
  },
  buildingCardTitle: {
    textAlign: "center",
    fontSize: 12,
    height: 16,
  },
  deadline: {
    textAlign: "center",
    fontSize: 12,
    color: theme.palette.grey[500],
    marginBottom: 2,
  },
  objectInfoChip: {
    minWidth: 66,
    height: 26,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${alpha(theme.palette.grey[500], 0.2)}`,
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1),
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  completedIcon: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.success.main} !important`,
    },
    height: `12px !important`,
    width: `12px !important`,
    fontSize: `12px !important`,
  },
  objectContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(0.4),
  },
  objectNumber: {
    color: `${theme.palette.common.black} !important`,
    fontSize: `10px !important`,
  },
  dangerColor: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.error.main} !important`,
    },
    height: `12px !important`,
    width: `12px !important`,
    fontSize: `12px !important`,
  },
  overdueIcon: {
    marginLeft: 3,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  visibilityIcon: {
    padding: theme.spacing(0),
    "& svg": {
      fontSize: 20,
    },
  },
  buildingIconContainer: {
    display: "flex",
    height: 16,
    justifyContent: "center",
  },
  buildingSpacingRight: {
    marginRight: theme.spacing(0.7),
  },
  buildingSpacingLeft: {
    marginLeft: theme.spacing(0.7),
  },
}));

export default DashboardCardView;
