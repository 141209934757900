import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const SVGFileRenderer = ({ mainState: { currentDocument } }) => {
    const classes = useStyles();
    if (!currentDocument) return null;

    return (
        <div className={classes.customSvgRenderer} id="my-svg-renderer">
            <img alt='' src={currentDocument.fileData} />
        </div>
    );
};

SVGFileRenderer.fileTypes = ["svg", "image/svg+xml"];
SVGFileRenderer.weight = 1;

const useStyles = makeStyles(theme => ({
    customSvgRenderer: {
        width: "100%",
    }
}));

export default SVGFileRenderer;
