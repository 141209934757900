import React, { useEffect, useState } from "react";
import { Box, Container, IconButton, Popover, TextField, Typography,Stack,Button, Menu, MenuItem, ListItemIcon, Snackbar, Alert} from "@mui/material";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import {  AccessTime, AddCircleOutline, ArrowDownward, ContentPaste, NotificationsNone, DeleteForeverOutlined } from "@mui/icons-material";
import DataGrid from "../../components/DataGrid";
import {  ContractStatusKeys, ModeOfSigningKeys, formatDate, generateLocalStorageKey, nl2br } from "../../_helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import CreateContractDrawer from "./Components/CreateContractDrawer";
import { cancelContract, deleteContracts, getAllContracts, sendReminder, setProlong } from "../../apis/contractApis";
import { LabelColumn } from "../../components/DataColumns";
import { LoadingButton } from "@mui/lab";
import ConfirmBox from './../../components/ConfirmBox';

const ContractOverview = () => {

  const classes = useStyles();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { t } = useTranslation();
  const [contract, setContract] = useState({
    loading:false,
    data:[]
  });
  const [toast, setToast] = React.useState({open:false, message:null, type:""})
  const [openGenerateContact, setOpenGenerateContact] = useState(false);
  const [isValidContractAction, setIsValidContractAction] = useState(false)
  const [isValidDeleteContractAction, setIsValidDeleteContractAction] = useState(false)
  const { selected , all:allBuildings} = useSelector(state => state.buildings)
  const [selectedRows, setSelectedRows] = useState([])
  const [prolongDays, setProlongDays] = useState()

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpenInMobile, setIsMenuOpenInMobile] = useState(null);
  const [isOpenConfirmBox, setisOpenConfirmBox] = useState({
    open:false,
    for:undefined
  })
  
 
  const [loading, setLoading] = useState({
    reminder: false,
    prolong: false,
    withdraw: false,
    delete: false
  })


  const handleAnchorElOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setIsMenuOpenInMobile(event.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setIsMenuOpenInMobile(null);
  }

  const open = Boolean(anchorEl);


  const columns = [
    {
      name: "contractId",
      options: {
          display: "excluded",
          filter: false,
              print: false,
              download: false,
          },
      },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
    },
    {
      name: "projectName",
      label: t("general.projectName"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.projectName)} label={request.projectName} />
        }
      }
    },
    {
      name: "objectNumber",
      label: t("general.objectNumber"),
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "buyer",
      label: t("general.buyer"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.buyer)} label={request.buyer} />
        }
      }
    },
    {
      name: "seller",
      label: t("general.seller"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.seller)} label={request.seller} />
        }
      }
    },
    {
      name: "salesRepresentative",
      label: t("general.responsible"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
            return <LabelColumn tooltipLabel={nl2br(request.salesRepresentative)} label={request.salesRepresentative} />
        }
      }
    },
    {
      name: "creationDate",
      label: t("general.creationDate"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
         return <LabelColumn tooltipLabel={nl2br(request.creationDate)} label={request.creationDate} />
        }
      }
    },
    {
      name: "expiryDate",
      label: t("general.expiryDate"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.expiryDate)} label={request.expiryDate} />
        }
      }
    },
    {
      name: "modeOfSigning",
      label: t("general.modeOfSigning"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.modeOfSigning)} label={request.modeOfSigning} />
        }
      }
    },
    {
      name: "scriveId",
      label: t("scrive.scriveId"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request) return "";
          return <LabelColumn tooltipLabel={nl2br(request.scriveId)} label={request.scriveId} />
        }
      }
    },
    {
      name: "scriveStatus",
      label: t("scrive.scriveStatus"),
      options: {
        filter: true,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request?.scriveStatus) return "";
          const value = t(`scrive.documentStatus.${request.scriveStatus}`)
          return <LabelColumn tooltipLabel={nl2br(value)} label={value} />
        }
      }
    },
    {
      name: "status",
      label: t("general.status"),
      options: {
        filter: false,
        sort: true,
        maxWidth: 180,
        customBodyRenderLite: (rowIndex) => {
          const request = contract.data[rowIndex];
          if (!request?.status?.toString()) return "";
          const value = t(`contract.status.${request.status}`)
          return <LabelColumn tooltipLabel={nl2br(value)} label={value} />
        }
      }
    },
  ];

  const handleGenerateContractDrawer = () => {
    setOpenGenerateContact(!openGenerateContact)
  }

  
  const handleProlong = async () => {
    try {
      setLoading({ ...loading, prolong: true })
      await Promise.all(selectedRows?.map((item)=>  setProlong(item?.contractId, prolongDays?.toString())))
   
      setToast({ open: true, message: t("contract.details.timeLine.prolong.success"), type: "success" })
      setAnchorEl(null);
      setIsMenuOpenInMobile(null);

    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, prolong: false })

    }
  }


  const handleReminder = async () => {
    try {
      setLoading({ ...loading, reminder: true })
      await Promise.all(selectedRows?.map((item)=> sendReminder(item?.contractId)))
      
      setToast({ open: true, message: t("contract.details.timeLine.reminder.success"), type: "success" })
      setIsMenuOpenInMobile(null);

    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, reminder: false })

    }
  }

  const fetchContracts = async (loading = true)=>{
    try{
      if(selected.projectId){
      setContract({data:[] , loading})
      const response=  await getAllContracts([selected.projectId])
      const data = response?.data?.map((item)=>{
        const building = allBuildings.find((building) => building?.buildingId?.toLowerCase() === item?.buildingId?.toLowerCase())
        if(!building){
          return item
        }
        return {...item, projectNo:building.projectNo,projectName :building.projectName,  objectNumber : building.buildingNoIntern,creationDate:item.creationDate ?formatDate(new Date(item.creationDate), true):"",  expiryDate : item.expiryDate ?formatDate(new Date(item.expiryDate), true):"" ,modeOfSigning :t("contract.signatureType."+item.signatureType),scriveId: item.scriveDocumentId,scriveStatus:item.scriveDocumentStatus,  status: item.status  }
      }) || []
       setContract({data:data, loading:false})
      }
    }catch(e){
      console.log(e)
      setContract({data:[], loading:false})
    }
  }

  const handleWithdraw = async () => {
    try {
      setLoading({ ...loading, withdraw: true })
      setisOpenConfirmBox({open:false,for:undefined})
      await Promise.all(selectedRows?.map((item)=> cancelContract(item?.contractId)))
      await fetchContracts();
      setToast({ open: true, message: t("contract.details.timeLine.withdraw.success"), type: "success" })
      setIsMenuOpenInMobile(null);
    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, withdraw: false })
    }
  }


  const handleCloseSnackbar = ()=>{
    setToast({open:false, message:null, type:"success"})
  }

  const handleDelete = async ()=>{
    try {
      setLoading({ ...loading, delete: true })
      setisOpenConfirmBox({open:false,for:undefined})

      await deleteContracts({data:selectedRows?.map((item)=> item?.contractId)})
      setIsMenuOpenInMobile(null);
      await fetchContracts()
      setToast({ open: true, message: t("contract.delete.success"), type: "success" })
    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, delete: false })
    }
  }
  
  const options = {
    rowsPerPage: rowsPerPage,
    pagination: true,
    rowsPerPageOptions: [5, 10, 20, 50, 75, 100],
    customToolbar: () => {
      return (
        <> 
          <IconButton
            onClick={handleGenerateContractDrawer}
            className={classes.meldingenTableButton}
            size="large"
            >
            <AddCircleOutline />
          </IconButton>  
            <Stack direction="row" justifyContent='space-between'>
        <>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleMobileMenuOpen} sx={{ p: 0 }}>
              <ContentPaste />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={isMenuOpenInMobile}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(isMenuOpenInMobile)}
              onClose={handleMobileMenuClose}
            >
          
              <>
                <MenuItem divider 
                onClick={() => {
                  handleReminder()
                }}
                disabled={!isValidContractAction}
                >
                  <ListItemIcon><NotificationsNone fontSize="small" /></ListItemIcon>
                  <Typography >{t("contract.details.timeLine.sendReminder")}</Typography>
                </MenuItem>
                <MenuItem divider disabled={!isValidContractAction} onClick={handleAnchorElOpen}>
                  <ListItemIcon><AccessTime fontSize="small" /></ListItemIcon>
                  <Typography>{t("contract.details.timeLine.prolong")}</Typography>
                </MenuItem>
                <MenuItem divider disabled={!isValidContractAction} onClick={() => {
                setisOpenConfirmBox({ open: true, for: "withdraw"})
              }}>
                <ListItemIcon><ArrowDownward fontSize="small" /></ListItemIcon>
                <Typography>{t("contract.details.timeLine.withdraw")}</Typography>
              </MenuItem>
              <MenuItem divider disabled={!isValidDeleteContractAction}  onClick={() => {
                setisOpenConfirmBox({ open: true, for: "delete"})
              }}>
                  <ListItemIcon><DeleteForeverOutlined fontSize="small" /></ListItemIcon>
                  <Typography>{t("general.delete")}</Typography>
                </MenuItem>
              </>
            </Menu>            
                {/* render confirm box DOM - start */}
                <ConfirmBox open={isOpenConfirmBox.open && isOpenConfirmBox.for === "withdraw"} title={t("general.warning")} description={t("contract.details.timeLine.withdraw.warning.description")} okText={t("general.confirm")} cancelText={t("general.cancel")} onOk={handleWithdraw}
                  onCancel={() => setisOpenConfirmBox({ open: false, for: undefined })} />
                {/* render confirm box DOM - end*/}
          </Box>
        </>
       
      </Stack>
            
        
      <Popover
        sx={{ mt: 1 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleAnchorElClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box maxWidth={275} p={1.5}>
          <Typography variant="caption">{t("contract.details.timeLine.prolong.popover.title")}</Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label={t("general.days")}
            type="number"
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e)=>{
              const val = e.target.value
               if(!val){
                setProlongDays("")
               }
               const numberValue = Number(val)
               if(numberValue <= 99 && numberValue>0){
                setProlongDays(numberValue)
               }
             }}
              InputProps={{
              inputProps: { 
                  max: 99, min: 1
              }
             }}
             value={prolongDays?.toString()}
          />
          <Stack direction="row" columnGap={1} mt={2} justifyContent="center">
            <Button variant="outlined" onClick={handleAnchorElClose}>{t("general.cancel")}</Button>
            <LoadingButton loading={loading.prolong} variant="contained" onClick={handleProlong}>{t("general.confirm")}</LoadingButton>
          </Stack>
        </Box>
      </Popover>
      
            </>
      );
    },
   
    onTableChange: (action, tableState) => {
      const newRowsPerPage = tableState.rowsPerPage;
      switch (action) {
        case "changeRowsPerPage": {
          setRowsPerPage(newRowsPerPage);
          break;
        }
        default: {
          break;
        }
      }
    },
    onRowClick: (rowData, {rowIndex}) => {
      console.log({rowData})
      const contractId = rowData[0];
      var item = contract.data.find((contract)=>contract.contractId === contractId)
      if (item) {
          history.push("/contract/"+ item.contractId);
      }
  },
  }



  useEffect(()=>{
    fetchContracts()
  },[selected.projectId])
  return (
    <>
      <Container maxWidth={false} className={classes.mainContainer}>
      <DataGrid
        title={t("contract.overview.title")}
        data={contract.data}
        key={contract.data}
        columns={columns}
        options={options}
        localColumnOrdersIdentifier={generateLocalStorageKey("Contract_OverviewList_ColumnOrder")}
        localColumnSortIdentifier={generateLocalStorageKey("Contract_OverviewList_ColumnSort")}
        rowsPerPageIdentifier={generateLocalStorageKey("Contract_OverviewList_RowsPerPage")}
        columnsVisibilityIdentifier={generateLocalStorageKey("Contract_OverviewList_ColumnsVisibility")}
        loading={contract.loading}
        fontSize={12}
        onRowSelect={(rowsSelected) => {
          const items = rowsSelected?.list?.map((index)=>contract?.data?.[index]) || []

          const isValidContractAction = items?.every((item)=> ContractStatusKeys.WaitingForSign === item?.status && !(item?.signatureType === ModeOfSigningKeys.PrintAndSign) 
          )
          const isDeleteAllowed = items?.every((item)=> [ContractStatusKeys.New , ContractStatusKeys.InProgress].includes(item?.status))

          setIsValidContractAction(Boolean(isValidContractAction) && items?.length >0 )
          setIsValidDeleteContractAction(Boolean(isDeleteAllowed) && items?.length >0 )

          
          if(isValidContractAction || isDeleteAllowed){
            setSelectedRows(items)
          }
        }}
      />
    <CreateContractDrawer openGenerateContact={openGenerateContact} handleGenerateContractDrawer={handleGenerateContractDrawer}/>
    </Container>

    <ConfirmBox open={isOpenConfirmBox.open && isOpenConfirmBox.for === "delete"} title={t("general.warning")} description={t("contract.delete.warning.description")} okText={t("general.confirm")} cancelText={t("general.cancel")} onOk={handleDelete}
            onCancel={() => setisOpenConfirmBox({ open: false, for: undefined })} />


    <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={toast.type || "success"}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  )
}


const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    overflow: "auto",
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerTitleSubContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  headerTitle: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "nowrap",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  greenText: {
    color: theme.palette.success.main,
  },
}));

export { ContractOverview }
