import React, { useCallback, useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  alpha,
  Grid,
  Grow,
  Hidden,
  Icon,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  AccountCircle,
  Archive,
  VpnKey,
  ArchiveRounded,
  ArrowDropDown,
  ArrowRight,
  CheckCircle,
  Clear,
  Close,
  CloudUpload,
  DeleteOutline,
  Description,
  Help,
  MoreHoriz,
  PriorityHigh,
  Search,
  Settings,
  Unarchive,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material"
import clsx from "clsx";
import {
  dossierViewLayoutLabels,
  formatDate,
  getNameInitials,
  groupBy,
  toBase64,
  validateFile,
} from "../../../_helpers";
import {  DatePicker } from "@mui/x-date-pickers"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ViewSwitcher from "./ViewSwitcher";
import { getDossiersDetailPathByModule } from "../../../_helpers/redirectHelper";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import { apps } from "../../../_constants";
import AntSwitch from "../../../components/AntSwitch";
import AddOrUpdateDossier from "../AddOrUpdateDossier";
import _find from "lodash/find";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { webApiUrl } = window.appConfig;

export default function DossierSidebar(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(0); //null to collapsed all projects
  const [openFilter, setOpenFilter] = useState(false);
  const [dossierMenuAnchor, setDossierMenuAnchor] = useState(null);
  const [selectedDossierMenu, setSelectedDossierMenu] = useState(null);
  const [selectedProjectMenu, setSelectedProjectMenu] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [settingAnchor, setSettingAnchor] = useState(null);
  const [projectMenuAnchor, setProjectMenuAnchor] = useState(null);
  const searchBoxRef = useRef();
  const { all, selected, rights } = useSelector((state) => state.buildings);
  const app = useSelector((state) => state.app);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [allProjects] = useState(
    Object.values(groupBy(all, "projectId")).map((p) => p[0])
  );
  const theme = useTheme();
  const matchOnlyXs = useMediaQuery(theme.breakpoints.only("xs"));
  const fileInputRef = useRef();

  const history = useHistory();
  const { viewType, selectedDataId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const buildingIdParam = params.get("buildingId");
  const dossierIdParam = params.get("dossierId") ?? selectedDataId;
  const [selectedBuildingForDossier, setSelectedBuildingForDossier] =
    useState(null);
  const [selectedRefElement, setSelectedRefElement] = useState({
    current: null,
  });
  const [scrollContainerRef, setScrollContainerRef] = useState({
    current: null,
  });
  const selectedRefElementNode = useCallback(
    (node) => node !== null && setSelectedRefElement({ current: node }),
    []
  );
  const scrollContainerRefNode = useCallback(
    (node) => node !== null && setScrollContainerRef({ current: node }),
    []
  );
  const dispatch = useDispatch();
  const {
    updateLoading,
    isDeadLineUpdating,
    selectedDossier,
    selectedLoading,
    dossiers,
    getBuildingLoading,
    buildingList,
    drawingDossier,
    loading,
    isDossiersLoading,
    availableRoles,
    dossierProjects,
    isUpdatePinnedDossierProjects,
  } = useSelector((state) => state.dossier);
  const isDrawingDossier = selectedDossier && selectedDossier.isSystem;
  const canEdit =
    rights["dossier.generalInformation.write"] ||
    rights["selected.object.write"];
  const [deadlineConfirmationDialog, setDeadlineConfirmationDialog] = useState({
    open: false,
    date: null,
  });
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const deadline = props.selectedDossierBuilding.buildingId
    ? props.selectedDossierBuilding.deadline
    : props.selectedDossier && props.selectedDossier.deadline;
  const [allDossiers, setAllDossiers] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [objectList, setObjectList] = useState(0);
  const [expand, setExpand] = useState("");
  const [updatingDossierId, setUpdatingDossierId] = useState();
  const [dossierData, setDossierData] = useState({
    dossierId: "",
    buildingId: "",
  });
  const [isUpdateDossier, setIsUpdateDossier] = useState(false);

  useEffect(() => {
    getPinnedDossierProjectsList();
  }, []);

  useEffect(() => {
    if (isUpdatePinnedDossierProjects) {
      getPinnedDossierProjectsList();
    }
  }, [isUpdatePinnedDossierProjects]);

  useEffect(() => {
    if ((props.isBuyer || app !== apps.aftercare) && selected) {
      getDossiers(selected.projectId);
    } else if (selectedDataId) {
      getDossiers(selectedDataId);
    }
  }, [app, selected, viewType]);

  useEffect(() => {
    if (selectedDossier && selectedDossier.buildingInfoList) {
      const data = selectedDossier.buildingInfoList
        .filter((b) => all.find((a) => a.buildingId === b.buildingId))
        .map((b) => {
          const { buildingNoExtern } =
            all.find((a) => a.buildingId === b.buildingId) || {};
          return { ...b, buildingNoExtern };
        });
      setObjectList(data.length);
    }
  }, [all, selectedDossier]);

  useEffect(() => {
    if (viewType === "dossier") {
      const data =
        dossiers.openOrClosedDossiers && dossiers.openOrClosedDossiers.length
          ? Object.assign([], dossiers.openOrClosedDossiers)
          : [];
      if (drawingDossier) data.unshift(drawingDossier);
      setAllDossiers(data);
    } else {
      setBuildingData(buildingList);
    }
  }, [viewType, dossiers, buildingList, drawingDossier, all]);

  useEffect(() => {
    if (
      !isDossiersLoading &&
      scrollContainerRef &&
      scrollContainerRef.current &&
      selectedRefElement &&
      selectedRefElement.current
    ) {
      const node = findDOMNode(selectedRefElement.current);
      node.scrollIntoView({ block: "center", inline: "center" });
    }
  }, [scrollContainerRef, selectedRefElement, isDossiersLoading]);

  useEffect(() => {
    if (openFilter && searchBoxRef && searchBoxRef.current)
      searchBoxRef.current.focus();
  }, [openFilter, searchBoxRef]);

  useEffect(() => {
    if (buildingIdParam || dossierIdParam) {
      setExpanded(selectedDataId);
    }
    setSelectedBuildingForDossier(buildingIdParam);
  }, [buildingIdParam, selectedDataId, dossierIdParam]);

  useEffect(() => {
    dispatch(dossiersActions.setDossierProjectData(allProjects));
  }, [allProjects]);

  useEffect(() => {
    handleFilter(searchQuery);
  }, [dossierProjects, searchQuery]);

  const handleDossierDeadlineUpdate = (deadlineDate, isUpdateBuildings) => {
    dispatch(
      dossiersActions.dossierDeadlineUpdate({
        dossierId: props.selectedDossier.id,
        deadlineDate,
        isUpdateBuildings,
      })
    );
  };
  const openDossierMenu = (e, dossier, id) => {
    e.stopPropagation();
    setDossierMenuAnchor(e.currentTarget);
    id && (dossier.buildingId = id);
    setSelectedDossierMenu(dossier);
  };

  const openProjectPopover = (e, project) => {
    e.stopPropagation();
    setProjectMenuAnchor(e.currentTarget);
    setSelectedProjectMenu(project);
  };

  const handleFilter = (value) => {
    let data;
    if (app === apps.aftercare && !props.isBuyer) {
      data = dossierProjects.filter((d) => {
        return value
          ? d.projectName.toLowerCase().includes(value.toLowerCase())
          : true;
      });
      setFilteredProjects(data);
    } else {
      if (viewType === "dossier") {
        data = Object.assign([], dossiers.openOrClosedDossiers).filter((d) => {
          return value
            ? d.name.toLowerCase().includes(value.toLowerCase())
            : true;
        });
        drawingDossier && data.unshift(drawingDossier);
        setAllDossiers(data);
      } else {
        const filteredBuildings = buildingList
          .filter((b) => all.find((a) => a.buildingId === b.buildingId))
          .map((b) => {
            const building =
              all.find((a) => a.buildingId === b.buildingId) || {};
            return { ...b, ...building };
          });
        data = filteredBuildings.filter((d) => {
          return value
            ? d.address.toLowerCase().includes(value.toLowerCase())
            : true;
        });
        setBuildingData(data);
      }
    }
  };

  function handleSearchDossiers({ target: { value } }) {
    setSearchQuery(value);
    handleFilter(value);
  }

  const resetFilters = () => {
    setOpenFilter((a) => !a);
    setSearchQuery(null);
  };

  useEffect(() => {
    if (dossierData.dossierId) {
      dispatch(
        dossiersActions.getSelectedDossierInfo(
          dossierData.dossierId,
          dossierData.buildingId,
          viewType
        )
      );
    } else {
      dispatch(dossiersActions.removeSelectedDossier());
    }
  }, [dossierData]);

  const redirectHandler = (projectId, buildingId, dossierId) => {
    history.push(
      getDossiersDetailPathByModule({
        dossierId,
        buildingId,
        viewType,
        projectId,
        projectNo: selected.projectNo,
        app,
        isBuyer: props.isBuyer,
      })
    );
  };

  const pinMenuHandler = (projectId, pin) => {
    dispatch(dossiersActions.setPinUnpinDossierProject(projectId, pin));
    setProjectMenuAnchor(null);
    let project = _find(dossierProjects, { projectId: projectId });
    if (!project) return;
    project.isPinned = pin;
    dispatch(dossiersActions.setDossierProjectDataWithPinnedInfo(project));
  };

  const settingsClickHandler = (e, buildingId, dossierId) => {
    setSettingAnchor(e.currentTarget);
    setDossierData({ buildingId, dossierId });
    props.settingModal(true);
  };
  const handleOpenDossier = (buildingId, dossierId) => {
    setDossierData({ buildingId, dossierId });
    redirectHandler(
      app === apps.aftercare && !props.isBuyer ? expanded : selected.projectId,
      buildingId,
      dossierId
    );
  };
  const handleDeleteDraft = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(dossiersActions.deleteDraftDossier(id));
  };
  const toggleBuildingDossierContainer = (id) => {
    setSelectedBuildingForDossier((p) => (id === p ? null : id));
  };

  const selectProjectHandler = (projectId) => {
    setExpanded((a) => {
      if (a === projectId) return null;
      getDossiers(projectId);
      return projectId;
    });
    if (app === apps.aftercare && !props.isBuyer && !props.isDashboard)
      history.push(`/nazorg/${viewType}`);
  };

  const getDossiers = (projectId) => {
    if (viewType === "dossier") {
      dispatch(dossiersActions.getAllDossiersByProject(projectId));
    } else {
      dispatch(dossiersActions.getBuildingWithDossiers(projectId));
    }
    dispatch(dossiersActions.getRoles(projectId));
  };

  const getPinnedDossierProjectsList = () => {
    if (app === apps.aftercare && !props.isBuyer) {
      dispatch(dossiersActions.getPinnedProjects());
    }
  };

  const goToCardDashboard = (e, projectId) => {
    e.preventDefault();
    e.stopPropagation();
    getDossiers(projectId);
    history.push(`/nazorg/${viewType}/${projectId}/dashboard`);
    setExpanded(0);
  };
  const handleUploadBackgroundImage = async ({ target: { files } }) => {
    if (files.length) {
      let file = files[0];
      if (validateFile({file:file, allowImage:true}) === true) {
        let currenturl = await toBase64(file);
        file.url = currenturl;
        const backgroundImage = {
          content: file.url.split(",")[1],
          name: file.name,
        };
        props.updateDossier("backgroundImage", backgroundImage);
      }
    }
  };

  const handleOpenUpdateDossier = (id) => {
    setIsUpdateDossier(true);
    setUpdatingDossierId(id);
  };

  const renderDossierData = (dossiers, concept) => {
    switch (viewType) {
      case "dossier": {
        return (
          <>
            {!!dossiers &&
              dossiers.map((dossier) => (
                <div key={dossier.id} className={classes.dossierItem}>
                  <Box
                    ref={
                      dossier.id === dossierIdParam
                        ? selectedRefElementNode
                        : null
                    }
                    className={clsx(
                      classes.popoverContainerGrid,
                      dossier.id === dossierIdParam && classes.selectedDossier
                    )}
                    onClick={(e) => {
                      concept === t("dossier.status.0")
                        ? handleOpenUpdateDossier(dossier.id)
                        : handleOpenDossier(null, dossier.id);
                    }}
                  >
                    <Typography className={classes.popOverBoxText}>
                      {dossier.name}
                      {dossier.deadline && (
                        <Typography
                          className={clsx(
                            classes.dossierDeadlineDate,
                            new Date(dossier.deadline) < new Date() &&
                              dossier.id !== dossierIdParam &&
                              classes.dossierDeadlineTooLate,
                            dossier.id === dossierIdParam &&
                              classes.selectedDossierDeadline
                          )}
                        >
                          {formatDate(new Date(dossier.deadline))}
                        </Typography>
                      )}
                    </Typography>
                    <div className={classes.dossierActionsContainer}>
                      {concept === t("dossier.status.0") &&
                      rights["dossier.draftList.write"] ? (
                        <Icon
                          onClick={(e) => handleDeleteDraft(e, dossier.id)}
                          className={classes.actionButton}
                        >
                          <DeleteOutline />
                        </Icon>
                      ) : (
                        <>
                          <div className={classes.iconContainer}>
                            {dossier.status === 2 && (
                              <Tooltip title={t("dossier.status.2")}>
                                <Icon className={classes.actionButton}>
                                  <CheckCircle
                                    className={classes.dossierCompletedIcon}
                                  />
                                </Icon>
                              </Tooltip>
                            )}
                          </div>
                          <div className={classes.iconContainer}>
                            {dossier.hasUpdates && (
                              <Tooltip
                                title={t("dossier.sidebar.priority.high")}
                              >
                                <Icon className={classes.actionButton}>
                                  <PriorityHigh
                                    className={classes.dangerColors}
                                  />
                                </Icon>
                              </Tooltip>
                            )}
                          </div>
                          {!props.isBuyer && (
                            <div className={classes.iconContainer}>
                              <Icon className={classes.actionButton}>
                                {dossier.isExternal ? (
                                  <Tooltip
                                    title={t("dossier.sidebar.visibleToBuyers")}
                                  >
                                    <VisibilityOutlined />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={t(
                                      "dossier.sidebar.invisibleToBuyers"
                                    )}
                                  >
                                    <VisibilityOffOutlined />
                                  </Tooltip>
                                )}
                              </Icon>
                            </div>
                          )}
                          {!props.isBuyer && (
                            <div className={classes.iconContainer}>
                              <IconButton
                                onClick={(e) =>
                                  openDossierMenu(e, { ...dossier })
                                }
                                className={classes.actionButton}
                                size="large"
                              >
                                <MoreHoriz />
                              </IconButton>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                  <Divider className={classes.dossierDivider} />
                </div>
              ))}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </div>
            )}
            {!loading && (!dossiers || !dossiers.length) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography className={classes.notFoundMessage}>
                  {" "}
                  {t("datatable.label.body.nomatch")}
                </Typography>
              </div>
            )}
          </>
        );
      }
      case "building": {
        return (
          <>
            {dossiers.map((building, j) => {
              const completedCount = building.dossierList.filter(
                (p) => p.status === 2
              ).length;
              const hasUpdatesCount = building.dossierList.filter(
                (p) => p.hasUpdates
              ).length;
              if (building) {
                const isExpanded =
                  selectedBuildingForDossier === building.buildingId;
                return (
                  <>
                    <div
                      key={building.buildingId}
                      className={classes.dossierItem}
                    >
                      <Tooltip
                        title={
                          isExpanded
                            ? t("general.collapse")
                            : t("general.expand")
                        }
                      >
                        <IconButton
                          className={clsx(
                            classes.expandButton,
                            classes.centerButton
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleBuildingDossierContainer(building.buildingId);
                          }}
                          size="large"
                        >
                          {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                        </IconButton>
                      </Tooltip>
                      <Box
                        ref={
                          selectedBuildingForDossier === building.buildingId &&
                          !dossierIdParam
                            ? selectedRefElementNode
                            : null
                        }
                        className={clsx(
                          classes.popoverContainerGrid,
                          isExpanded && classes.selectedDossier
                        )}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleBuildingDossierContainer(building.buildingId);
                        }}
                      >
                        <Typography className={classes.objectAddressInfo}>
                          {!!building.buildingNoExtern && (
                            <span
                              className={
                                building.addressModel && classes.rightBorder
                              }
                            >
                              {building.buildingNoExtern}
                            </span>
                          )}
                          <span>
                            {!!building.addressModel &&
                              building.addressModel.street}
                          </span>
                        </Typography>
                        <Typography className={classes.houseNoText}>
                          <span
                            className={
                              building.addressModel && classes.borderLeft
                            }
                          >
                            {!!building.addressModel &&
                              `${building.addressModel.houseNo} ${
                                building.addressModel.houseNoAddition || ""
                              }`}
                          </span>
                        </Typography>
                        {!!building.buildingBuyerRenterId && (
                          <IconButton
                            disabled
                            className={classes.userIconForBuilding}
                            size="large"
                          >
                            <AccountCircle />
                          </IconButton>
                        )}

                        {(hasUpdatesCount || completedCount) > 0 && (
                          <div className={classes.objectInfoChip}>
                            {hasUpdatesCount > 0 && (
                              <div className={classes.objectContainer}>
                                <Typography className={classes.objectNumber}>
                                  {hasUpdatesCount}
                                </Typography>
                                <Tooltip
                                  title={t("dossier.sidebar.priority.high")}
                                >
                                  <PriorityHigh
                                    className={classes.dangerColor}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            {completedCount > 0 && (
                              <div className={classes.objectContainer}>
                                <Typography className={classes.objectNumber}>
                                  {completedCount}
                                </Typography>
                                <Tooltip title={t("dossier.status.2")}>
                                  <CheckCircle
                                    className={classes.completedIcon}
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        )}
                      </Box>
                      <Divider className={classes.dossierDivider} />
                    </div>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      {building.dossierList.map((dossier, j) => (
                        <div key={dossier.id} className={classes.dossierItem}>
                          <Box
                            ref={
                              dossier.id === dossierIdParam
                                ? selectedRefElementNode
                                : null
                            }
                            className={clsx(
                              classes.popoverContainerGrid,
                              dossier.id === dossierIdParam &&
                                buildingIdParam === building.buildingId &&
                                classes.selectedChildDossier
                            )}
                            onClick={(e) =>
                              handleOpenDossier(building.buildingId, dossier.id)
                            }
                          >
                            <Typography className={classes.popOverBoxText}>
                              {dossier.name}
                              {dossier.deadline && (
                                <Typography
                                  className={clsx(
                                    classes.dossierDeadlineDate,
                                    new Date(dossier.deadline) < new Date() &&
                                      dossier.id !== dossierIdParam &&
                                      classes.dossierDeadlineTooLate,
                                    dossier.id === dossierIdParam &&
                                      buildingIdParam === building.buildingId &&
                                      classes.selectedDossierDeadline
                                  )}
                                >
                                  {formatDate(new Date(dossier.deadline))}
                                </Typography>
                              )}
                            </Typography>
                            <div className={classes.dossierActionsContainer}>
                              <div className={classes.iconContainer}>
                                {dossier.status === 2 && (
                                  <Tooltip title={t("dossier.status.2")}>
                                    <Icon className={classes.actionButton}>
                                      <CheckCircle
                                        className={classes.dossierCompletedIcon}
                                      />
                                    </Icon>
                                  </Tooltip>
                                )}
                              </div>
                              <div className={classes.iconContainer}>
                                {dossier.hasUpdates && (
                                  <Tooltip
                                    title={t("dossier.sidebar.priority.high")}
                                  >
                                    <Icon className={classes.actionButton}>
                                      <PriorityHigh
                                        className={classes.dangerColors}
                                      />
                                    </Icon>
                                  </Tooltip>
                                )}
                              </div>
                              <div className={classes.iconContainer}>
                                <Icon className={classes.actionButton}>
                                  {dossier.isExternal ? (
                                    <Tooltip
                                      title={t(
                                        "dossier.sidebar.visibleToBuyers"
                                      )}
                                    >
                                      <VisibilityOutlined />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={t(
                                        "dossier.sidebar.invisibleToBuyers"
                                      )}
                                    >
                                      <VisibilityOffOutlined />
                                    </Tooltip>
                                  )}
                                </Icon>
                              </div>
                              {!props.isBuyer && (
                                <div className={classes.iconContainer}>
                                  <IconButton
                                    onClick={(e) =>
                                      openDossierMenu(
                                        e,
                                        { ...dossier },
                                        building.buildingId
                                      )
                                    }
                                    className={classes.actionButton}
                                    size="large"
                                  >
                                    <MoreHoriz />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </Box>
                          <Divider className={classes.dossierDivider} />
                        </div>
                      ))}
                      {!building.dossierList.length && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography className={classes.notFoundMessage}>
                            {" "}
                            {t("dossier.notFound")}
                          </Typography>
                        </div>
                      )}
                    </Collapse>
                  </>
                );
              }
              return null;
            })}
            {getBuildingLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </div>
            )}
            {!getBuildingLoading && !dossiers.length && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography className={classes.notFoundMessage}>
                  {" "}
                  {t("datatable.label.body.nomatch")}
                </Typography>
              </div>
            )}
          </>
        );
      }
      default:
        return null;
    }
  };

  const renderProjects = (filteredProjects) => {
    return (
      <div>
        {isDossiersLoading ? (
          <div
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : (
          filteredProjects &&
          filteredProjects.map((project, i) => {
            return (
              <>
                <Card
                  key={`project-${project.projectId}`}
                  className={classes.cardContainer}
                >
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <div className={classes.cardAvatarContainer}>
                        <Tooltip
                          title={
                            expanded === project.projectId
                              ? t("general.collapse")
                              : t("general.expand")
                          }
                        >
                          <IconButton
                            className={classes.expandButton}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              selectProjectHandler(project.projectId);
                            }}
                            size="large"
                          >
                            {expanded === project.projectId ? (
                              <ArrowDropDown />
                            ) : (
                              <ArrowRight />
                            )}
                          </IconButton>
                        </Tooltip>
                        <div
                          className={classes.cardAvatar}
                          style={{
                            backgroundImage: `url(${webApiUrl}api/home/ProjectLogo/${project.projectId})`,
                          }}
                        ></div>
                      </div>
                    }
                    action={
                      <>
                        {/* <div className={classes.dossierActionsContainer}> */}
                        {/*{project.isPinned && <IconButton className={classes.actionButton}>*/}
                        {/*    <Icon className='material-icons-outlined'>push_pin</Icon>*/}
                        {/*</IconButton>}*/}
                        {/*<IconButton className={classes.actionButton}>*/}
                        {/*    <MoreHoriz />*/}
                        {/*</IconButton>*/}
                        {/* </div> */}

                        <IconButton
                          onClick={(e) => openProjectPopover(e, project)}
                          className={classes.projectActionButton}
                          size="large"
                        >
                          <MoreHoriz />
                        </IconButton>
                      </>
                    }
                    title={
                      <>
                        <Typography
                          className={clsx(
                            classes.projectTitle,
                            (expanded === project.projectId ||
                              project.projectId === selectedDataId) &&
                              classes.projectTitleSelected
                          )}
                          noWrap
                          onClick={(e) =>
                            goToCardDashboard(e, project.projectId)
                          }
                        >
                          {project.projectName}
                        </Typography>

                        {project.isPinned === true && (
                          <Icon className={classes.pinIcon}>push_pin</Icon>
                        )}
                      </>
                    }
                    disableTypography={true}
                    titleTypographyProps={{
                      title: project.projectName,
                      noWrap: true,
                    }}
                  />
                </Card>
                <Collapse
                  className={classes.collapseContainer}
                  in={project.projectId === expanded}
                  timeout="auto"
                  unmountOnExit
                >
                  {renderDossierData(
                    viewType === "dossier" ? allDossiers : buildingData
                  )}
                </Collapse>
              </>
            );
          })
        )}

        {!isDossiersLoading &&
          (!filteredProjects || !filteredProjects.length) && (
            <Typography className={classes.notFoundMessage}>
              {t("datatable.label.body.nomatch")}
            </Typography>
          )}
      </div>
    );
  };

  // for BG module
  const renderDossierSections = () => {
    const draftAndArchived = [];
    if (rights["dossier.draftList.read"])
      draftAndArchived.push(t("dossier.status.0"));
    if (rights["dossier.archiveList.read"])
      draftAndArchived.push(t("dossier.general.archive"));
    return (
      <div>
        {loading ? (
          <div
            style={{ display: "flex", justifyContent: "center", padding: 10 }}
          >
            <CircularProgress color="inherit" size={20} />
          </div>
        ) : (
          <>
            {renderDossierData(
              viewType === "dossier" ? allDossiers : buildingData
            )}
            {viewType === "dossier" &&
              draftAndArchived.map((name, i) => {
                const projectName = getNameInitials(name);
                return (
                  <>
                    <Card key={name} className={classes.cardContainer}>
                      <CardHeader
                        className={classes.cardHeader}
                        avatar={
                          <div className={classes.cardAvatarContainer}>
                            <Tooltip
                              title={
                                expand === name
                                  ? t("general.collapse")
                                  : t("general.expand")
                              }
                            >
                              <IconButton
                                className={classes.expandButton}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setExpand((a) => (a === name ? "" : name));
                                }}
                                size="large"
                              >
                                {expand === name ? (
                                  <ArrowDropDown />
                                ) : (
                                  <ArrowRight />
                                )}
                              </IconButton>
                            </Tooltip>
                            <Avatar
                              className={clsx(
                                classes.cardAvatar,
                                classes.cardAvatarStyle
                              )}
                              alt={projectName}
                            >
                              {" "}
                              {name === t("dossier.general.archive") ? (
                                <ArchiveRounded />
                              ) : (
                                <Help />
                              )}{" "}
                            </Avatar>
                          </div>
                        }
                        title={
                          <Typography
                            className={clsx(
                              classes.projectTitle,
                              expand === name && classes.projectTitleSelected
                            )}
                            noWrap
                          >
                            {name}
                          </Typography>
                        }
                        titleTypographyProps={{
                          title: name,
                          noWrap: true,
                        }}
                      />
                    </Card>
                    <Collapse
                      className={classes.collapseContainer}
                      in={name === expand}
                      timeout="auto"
                      unmountOnExit
                    >
                      {dossiers &&
                        renderDossierData(
                          name === t("dossier.status.0")
                            ? dossiers.draftDossiers
                            : dossiers.archiveDossiers,
                          name
                        )}
                    </Collapse>
                  </>
                );
              })}
          </>
        )}
      </div>
    );
  };
  const searchPlaceHolder =
    app === apps.aftercare && !props.isBuyer
      ? t("dossier.serchbarPlaceholder.projectLabel")
      : viewType === "dossier"
      ? t("dossier.serchbarPlaceholder.dossierLabel")
      : t("dossier.serchbarPlaceholder.objectLabel");

  const onSettingsModalClose = () => {
    setDossierMenuAnchor(null);
    props.settingModal(false);
    if (
      dossierData.dossierId === dossierIdParam &&
      dossierData.buildingId === buildingIdParam
    )
      return;
    setDossierData({ dossierId: dossierIdParam, buildingId: buildingIdParam });
  };

  const onProjectModalClose = () => {
    setProjectMenuAnchor(null);
  };

  return (
    <>
      <input ref={fileInputRef} className={classes.fileInput} type="file" accept={"image/x-png,image/gif,image/jpeg"} onChange={handleUploadBackgroundImage} />
      <div className={classes.container}>
        <Hidden mdDown={!openFilter}>
          <AppBar position="sticky">
            <Toolbar className={classes.toolbar}>
              {
                <Slide direction="right" mountOnEnter unmountOnExit in={!openFilter}>
                  <div className={classes.dossierTitle}>
                    <Typography variant="h6" className={classes.title}>
                      {t(dossierViewLayoutLabels[viewType])}
                    </Typography>

                    <Hidden mdDown>
                      <div>
                        {!props.isBuyer && (rights["dossier.canSwitchView"] || apps.aftercare === app) && <ViewSwitcher />}
                        <Tooltip title={t("general.search") + "..."}>
                          <IconButton className={classes.iconButton}  color="primary" onClick={() => setOpenFilter((a) => !a)} size="large">
                            <Search />
                          </IconButton>
                        </Tooltip>
                        {!loading && rights["dossier.canCreateDossier"] && (
                          <AddOrUpdateDossier
                            roles={availableRoles}
                            selectedBuilding={selected}
                            updatingDossierId={updatingDossierId}
                            isUpdateDossier={isUpdateDossier}
                            onCancelUpdating={() => {
                              setUpdatingDossierId(null)
                              setIsUpdateDossier(false)
                            }}
                            buildings={props.buildings}
                          />
                        )}
                      </div>
                    </Hidden>
                  </div>
                </Slide>
              }
              {openFilter && (
                <Slide direction="right" mountOnEnter unmountOnExit in={openFilter}>
                  <div className={classes.filterContainer}>
                    <TextField autoComplete="off" inputRef={searchBoxRef} fullWidth value={searchQuery} onChange={handleSearchDossiers} variant="standard" placeholder={searchPlaceHolder} id="dossier-name" className={classes.filterTextField} />
                    <IconButton className={classes.iconButton} color="primary" onClick={resetFilters} size="large">
                      <Close />
                    </IconButton>
                  </div>
                </Slide>
              )}
            </Toolbar>
          </AppBar>
        </Hidden>
        <div className={classes.menuContainer} ref={scrollContainerRefNode}>
          {app === apps.aftercare && !props.isBuyer ? renderProjects(filteredProjects) : renderDossierSections()}
          {selectedDossierMenu && (
            <Popover
              className={classes.filterOptions}
              id={"dossier-menu-popover"}
              open={Boolean(dossierMenuAnchor)}
              anchorEl={dossierMenuAnchor}
              onClose={onSettingsModalClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {!selectedDossierMenu.isProject && (
                <MenuItem className={classes.menuItem} onClick={(e) => settingsClickHandler(e, selectedDossierMenu.buildingId, selectedDossierMenu.id, false)}>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <Typography variant="inherit">{t("dossier.general.settings")}</Typography>
                </MenuItem>
              )}
            </Popover>
          )}

          {selectedProjectMenu && (
            <>
              <Popover
                className={classes.filterOptions}
                id={"project-menu-popover"}
                open={Boolean(projectMenuAnchor)}
                anchorEl={projectMenuAnchor}
                onClose={onProjectModalClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <MenuItem className={classes.menuItem} onClick={(e) => pinMenuHandler(selectedProjectMenu.projectId, !selectedProjectMenu.isPinned)}>
                  <ListItemIcon>
                    <Icon className={classes.pinIcon}>push_pin</Icon>
                  </ListItemIcon>
                  <Typography variant="inherit">{!selectedProjectMenu.isPinned ? t("dossier.general.pin") : t("dossier.general.unpin")}</Typography>
                </MenuItem>
              </Popover>
            </>
          )}
          {(props.selectedDossier || selectedLoading) && selectedDossierMenu && (
            <Popover
              className={classes.settingDialog}
              id={"dossier-menu-popover"}
              open={Boolean(settingAnchor)}
              TransitionComponent={matchOnlyXs ? Transition : Grow}
              anchorEl={settingAnchor}
              onClose={() => setSettingAnchor(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <DialogContent className={classes.settingTitle}>
                <DialogContentText id="alert-dialog-description" className={classes.dialogContextText}>
                  <Description style={{ marginRight: 8 }} />
                  {t("dossier.setting.header")}
                </DialogContentText>
                <IconButton className={classes.closeDialogButton} onClick={() => setSettingAnchor(null)} size="large">
                  <Close />
                </IconButton>
              </DialogContent>
              <DialogContent className={classes.settingCardsContainer}>
                {selectedLoading ? (
                  <div className={classes.loader}>
                    <CircularProgress color="inherit" size={30} />
                  </div>
                ) : (
                  <Grid container spacing={2}>
                    {!isDrawingDossier && (
                      <Grid item xs={4}>
                        <Card className={classes.settingCard}>
                          <Typography color="primary" className={classes.cardContent}>
                            {updateLoading && props.dossierUpdateType === "extern" ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              <AntSwitch
                                color="primary"
                                disabled={!canEdit}
                                checked={props.selectedDossier.isExternal === true || props.isReadOnly}
                                onChange={(e) => {
                                  props.updateDossier("extern", e.target.checked.toString())
                                }}
                              />
                            )}
                          </Typography>
                          <Typography className={classes.cardTitle}>{t("dossier.setting.buyersDossier")}</Typography>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <Card className={classes.settingCard}>
                        <Typography color="primary" className={classes.cardContent}>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              fileInputRef.current.click()
                            }}
                            className={classes.uploadButton}
                            startIcon={<CloudUpload />}
                            component="span"
                            disabled={props.uploadingBackground || props.isReadOnly}
                          >
                            {props.uploadingBackground ? <CircularProgress color="primary" size={24} /> : t("dossier.general.uploadImageButton.title")}
                          </Button>
                        </Typography>
                        <Typography className={classes.cardTitle}>{t("dossier.upload.backgroundImage")}</Typography>
                      </Card>
                    </Grid>
                    {!isDrawingDossier && (
                      <>
                        <Grid item xs={4}>
                          <Card className={classes.settingCard} onClick={(updateLoading && props.dossierUpdateType === "rights") || props.isReadOnly ? null : props.openEditObjects}>
                            <Typography color="primary" className={classes.cardContent}>
                              {objectList}/{props.buildings.length}
                            </Typography>
                            <Typography className={classes.cardTitle}>{t("general.objects")}</Typography>
                          </Card>
                        </Grid>

                        <Grid item xs={4}>
                          <Card className={classes.settingCard} onClick={(updateLoading && props.dossierUpdateType === "rights") || props.isReadOnly ? null : props.openDossierRights}>
                            <Typography color="primary" className={classes.cardContent}>
                              <VpnKey color={"primary"} />
                            </Typography>
                            <Typography className={classes.cardTitle}>{t("dossier.rights")}</Typography>
                          </Card>
                        </Grid>
                        <Grid item xs={4}>
                          <Card className={classes.settingCard}>
                            <Typography color="primary" className={classes.cardContent}>
                              {props.selectedDossier.status === 1 ? t("dossier.status.1") : t("dossier.status.2")}
                            </Typography>
                            <Typography className={classes.cardTitle}>{t("general.status")}</Typography>
                          </Card>
                        </Grid>
                        <Grid item xs={4}>
                          <Card
                            className={classes.settingCard}
                            disabled={updateLoading && props.dossierUpdateType === "archive"}
                            onClick={() => {
                              canEdit && props.updateDossier("archive", (!props.selectedDossier.isArchived).toString())
                              setDossierMenuAnchor(null)
                              setSettingAnchor(null)
                            }}
                          >
                            <Typography color="primary" className={classes.cardContent}>
                              {props.selectedDossier.isArchived ? <Unarchive color={"primary"} /> : <Archive color={"primary"} />}
                            </Typography>
                            <Typography className={classes.cardTitle}>
                              {updateLoading && props.dossierUpdateType === "archive" ? <CircularProgress color="inherit" size={24} /> : t(props.selectedDossier.isArchived ? "dossier.setting.unarchive" : "dossier.setting.archive")}
                            </Typography>
                          </Card>
                        </Grid>

                        <Grid item xs={12}>
                          <Card className={classes.dossierDeadLineCard}>
                            <Typography className={clsx(classes.cardTitle, classes.deadlineCardTitle)}>{t("dossier.general.deadline.title")}:</Typography>
                            <Dialog open={deadlineConfirmationDialog.open}>
                              <DialogTitle>{t("general.confirm")}</DialogTitle>
                              <DialogContent>
                                <DialogContentText> {t("dossier.deadline.confirmation.message")} </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    setDeadlineConfirmationDialog({
                                      date: null,
                                      open: false,
                                    })
                                    handleDossierDeadlineUpdate(deadlineConfirmationDialog.date || "", false)
                                  }}
                                >
                                  {t("button.action.no")}
                                </Button>
                                <Button
                                  color="primary"
                                  autoFocus
                                  onClick={() => {
                                    setDeadlineConfirmationDialog({
                                      date: null,
                                      open: false,
                                    })
                                    handleDossierDeadlineUpdate(deadlineConfirmationDialog.date || "", true)
                                  }}
                                >
                                  {t("button.action.yes")}
                                </Button>
                              </DialogActions>
                            </Dialog>
                            <DatePicker
                              fullWidth
                              variant="outlined"
                              format="dd-MM-yyyy"
                              margin="dense"
                              id="date-time-picker"
                              label={t("dossier.general.deadline.title")}
                              name="startDateTime"
                              value={deadline ? new Date(deadline) : null}
                              onChange={(date) => {
                                if (props.selectedDossierBuilding.buildingId || !props.selectedDossier.buildingInfoList.length) props.updateDossier("deadline", date.toJSON())
                                else
                                  setDeadlineConfirmationDialog({
                                    open: true,
                                    date: date.toJSON(),
                                  })
                                setOpenDatePicker(false)
                              }}
                              open={openDatePicker}
                              autoOk
                              slotProps={{
                                openPickerButton: { edge: "start", size: "large" },
                                textField: {
                                  variant: "standard",
                                  size: "large",
                                  onClick: () => {
                                    setOpenDatePicker(true)
                                  },
                                },
                              }}
                              slots={{
                                openPickerButton: () => deadline ? (
                                  <IconButton
                                    disabled={props.isReadOnly || (updateLoading && props.dossierUpdateType === "deadline") || isDeadLineUpdating}
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      if (props.selectedDossierBuilding.buildingId || !props.selectedDossier.buildingInfoList.length) props.updateDossier("deadline", null)
                                      else
                                        setDeadlineConfirmationDialog({
                                          open: true,
                                          date: null,
                                        })
                                    }}
                                    size="large"
                                  >
                                    {(updateLoading && props.dossierUpdateType === "deadline") || isDeadLineUpdating ? <CircularProgress size={20} /> : <Clear />}
                                  </IconButton>
                                ): null,
                              }}
                              disabled={props.isReadOnly || (updateLoading && props.dossierUpdateType === "deadline") || isDeadLineUpdating}
                              clearable
                            />
                          </Card>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </DialogContent>
            </Popover>
          )}
        </div>
      </div>
      <Hidden mdUp>
        <div className={classes.floatingActions}>
          {!props.isBuyer && (rights["dossier.canSwitchView"] || apps.aftercare === app) && <ViewSwitcher />}
          <Tooltip title={t("general.search") + "..."}>
            <Button className={classes.floatingActionIconButton} variant="contained" color="primary" onClick={() => setOpenFilter((a) => !a)}>
              <Search />
            </Button>
          </Tooltip>
        </div>
      </Hidden>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  fileInput: {
    display: "none",
  },
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    scrollBehavior: "smooth",
  },
  floatingActions: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    bottom: 10,
    right: 16,
    zIndex: theme.zIndex.speedDial,
    ["@media (orientation: portrait)"]: {
      bottom: 64,
    },
  },
  floatingActionIconButton: {
    margin: 4,
    borderRadius: 25,
    maxWidth: 50,
    maxHeight: 50,
    minWidth: 50,
    minHeight: 50,
    padding: theme.spacing(1),
  },
  subMenuIconContainer: {
    minWidth: 25,
    minHeight: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconContainer: {
    minWidth: 24,
  },
  subMenuIcon: {
    fontSize: 18,
  },
  closeButton: {
    cursor: "pointer",
  },
  title: {
    fontSize: 14,
    flexGrow: 1,
    color: theme.palette.grey[800],
    fontWeight: "bold",
    paddingLeft: theme.spacing(1),
  },
  selectedDossierDeadline: {
    color: `${theme.palette.common.white} !important`,
  },
  dossierDeadlineTooLate: {
    color: `${theme.palette.error.main} !important`,
  },
  selectedDossier: {
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    "& svg": {
      color: `${theme.palette.common.white} !important`,
    },
    "& .MuiIcon-root": {
      color: `${theme.palette.common.white} !important`,
    },
    "& + hr": {
      backgroundColor: theme.palette.common.white,
    },
  },
  selectedChildDossier: {
    background: `${theme.palette.grey[300]} !important`,
    "& + hr": {
      backgroundColor: theme.palette.common.white,
    },
  },
  popoverContainerGrid: {
    height: 46,
    display: "flex",
    transition: "0.5s all",
    padding: theme.spacing(1, 0.5),
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    marginTop: 5,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    "&:hover": {
      background: alpha(theme.palette.primary.main, 0.2),
      "& + hr": {
        backgroundColor: theme.palette.common.white,
      },
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    "& .MuiIcon-root": {
      width: 20,
      height: 20,
    },
    "&:after": {
      display: "block",
      position: "absolute",
      content: " ",
      borderBottom: "1px solid #d2d7da",
      width: "70%",
      transform: "translateX(-50%)",
      bottom: -15,
      left: "50%",
    },
  },
  activeSubMenu: {
    background: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`,
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 48px)",
    overflowY: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  toolbar: {
    backgroundColor: theme.palette.common.white,
    minHeight: 0,
    padding: theme.spacing(0),
  },
  cardContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
    "& > div": {
      padding: theme.spacing(1, 0.3, 1, 0.3),
    },
    position: "sticky",
    top: 0,
    "&:nth-last-child(1)": {
      marginBottom: 6,
    },
    zIndex: 1,
  },
  cardHeader: {
    "& > .MuiCardHeader-action": {
      margin: 0,
      alignSelf: "center",
    },
    "& > .MuiCardHeader-avatar": {
      marginRight: theme.spacing(0.5),
    },
    "& > .MuiCardHeader-content": {
      width: "100%",
      overflow: "hidden",
      display: "flex",
    },
  },
  cardAvatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  expandButton: {
    padding: theme.spacing(0),
  },
  centerButton: {
    position: "absolute",
    top: "50%",
    left: -10,
    transform: "translate(-50%, -50%)",
  },
  cardAvatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: theme.palette.grey[100],
    borderRadius: 50,
  },
  cardAvatarStyle: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  actionButton: {
    display: "flex",
    width: "100% !important",
    "& svg": {
      height: 20,
      width: 20,
      minWidth: 24,
    },
  },
  projectActionButton: {
    display: "flex",
    width: "100% !important",
    padding: 0,
    "& svg": {
      height: 20,
      width: 20,
      minWidth: 24,
    },
  },
  collapseContainer: {
    padding: `${theme.spacing(0, 0, 0, 4)} !important`,
  },
  buildingListContainer: {
    padding: `${theme.spacing(0, 0, 0, 3)} !important`,
  },
  rightBorder: {
    borderRight: `1px solid`,
    paddingRight: theme.spacing(0.6),
    marginRight: theme.spacing(0.6),
  },
  objectAddressInfo: {
    paddingLeft: theme.spacing(1.25),
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    whiteSpace: "nowrap",
  },
  houseNoText: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0),
    fontSize: 14,
  },
  borderLeft: {
    paddingLeft: theme.spacing(0.6),
    marginLeft: theme.spacing(0.6),
    borderLeft: `1px solid`,
  },
  popOverBoxText: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1.25),
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    whiteSpace: "nowrap",
  },
  dossierItem: {
    position: "relative",
    "&:nth-last-child(1)": {
      marginBottom: theme.spacing(2),
    },
  },
  buildingItem: {
    position: "relative",
    "&:nth-last-child(1)": {
      marginBottom: theme.spacing(2),
    },
  },
  dossierDivider: {
    width: "calc(100% - 10px)",
    marginLeft: 10,
  },
  cardContent: {
    fontWeight: "bold",
    margin: theme.spacing(1),
    height: 21,
  },
  dossierActionsContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: 68,
    justifyContent: "flex-end",
    "& svg": {
      color: `${theme.palette.grey[500]}`,
    },
    "& .MuiIcon-root": {
      color: `${theme.palette.grey[500]}`,
    },
  },
  dossierDeadlineDate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 12,
    whiteSpace: "nowrap",
    color: theme.palette.grey[500],
  },
  objectInfoChip: {
    minWidth: 73,
    height: 26,
    backgroundColor: theme.palette.common.white,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1),
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userIconForBuilding: {
    padding: theme.spacing(0.25),
  },
  completedIcon: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.success.main} !important`,
    },
    height: `12px !important`,
    width: `12px !important`,
    fontSize: `12px !important`,
  },
  dossierCompletedIcon: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.success.main} !important`,
    },
    height: `20px !important`,
    width: `20px !important`,
    fontSize: `20px !important`,
  },
  dangerColor: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.error.main} !important`,
    },
    height: `12px !important`,
    width: `12px !important`,
    fontSize: `12px !important`,
  },
  dangerColors: {
    "&.MuiSvgIcon-root": {
      color: `${theme.palette.error.main}`,
    },
    height: `20px !important`,
    width: `20px !important`,
    fontSize: `12px !important`,
  },
  objectNumber: {
    color: `${theme.palette.common.black} !important`,
    fontSize: `10px !important`,
  },
  objectContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(0.4),
  },
  overdueIcon: {
    marginLeft: 3,
  },
  projectTitleSelected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 700,
  },
  projectTitle: {
    fontSize: 12,
    cursor: "pointer",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingLeft: theme.spacing(2),
    minHeight: 48,
  },
  dossierTitle: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 48,
  },
  filterTextField: {
    "& .MuiInput-underline:before": {
      borderBottom: "0 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "0 !important",
    },
    "& input": {
      textOverflow: "ellipsis",
    },
  },
  iconButton: {
    padding: theme.spacing(0.5),
  },
  filterOptions: {
    "& svg": {
      color: `${theme.palette.grey[500]} !important`,
    },
    "& .MuiIcon-root": {
      color: `${theme.palette.grey[500]} !important`,
    },
    "& .MuiPopover-paper": {
      color: `${theme.palette.grey[500]} !important`,
      minWidth: 160,
    },
  },
  menuItem: {
    "& .MuiListItemIcon-root": {
      minWidth: 34,
    },
  },
  notFoundMessage: {
    textAlign: "center",
    padding: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  dialogContextText: {
    flexGrow: 1,
    color: theme.palette.common.white,
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
  },
  settingDialog: {
    "& .MuiPopover-paper": {
      width: 355,
      [theme.breakpoints.only("xs")]: {
        top: "auto !important",
        bottom: "0px !important",
        left: "0px !important",
        maxWidth: "100% !important",
        width: "100%",
        boxShadow: theme.shadows[24],
      },
    },
  },
  closeDialogButton: {
    color: theme.palette.common.white,
    padding: theme.spacing(0),
  },
  settingTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1),
    paddingTop: `${theme.spacing(1)} !important`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[4],
  },
  dossierDeadLineCard: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  settingCard: {
    cursor: "pointer",
    width: "100%",
    height: 94,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  loader: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  settingCardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
  },
  cardTitle: {
    fontSize: 12,
    textAlign: "center",
  },
  deadlineCardTitle: {
    marginRight: theme.spacing(2),
  },
  uploadButton: {
    fontSize: 8,
    borderRadius: 50,
    padding: theme.spacing(0.25, 1.25),
  },
  pinIcon: {
    fontSize: "1.25rem",
  },
}));
