import { Divider, alpha, Hidden, Typography, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RepairRequests from '../../components/RepairRequests';
import BasicDetails from '../../components/BasicDetails'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { surveyActions } from '../../../../_actions';
import { groupBy, statusColors, useInternetStatusDetector } from '../../../../_helpers';
import { CircularProgress } from '@mui/material';
import Remarks from '../../components/Remarks';
import { Grid } from '@mui/material';
import { Paper } from '@mui/material';
import BottomDrawer from '../../components/BottomDrawer';
import Close from '@mui/icons-material/Close';
import { ModeCommentOutlined } from '@mui/icons-material';
import clsx from "clsx";

const data = [
  { title: 'survey.defectsList', key: 'gebrekenlijst', description: '50', Icon: 'construction', },
  { title: 'survey.externalNotes', key: 'opmerkingen', description: '', Icon: ModeCommentOutlined },
];

export default function PreDeliveriesDetail({ canGoBack, openRepairRequestMap, setOpenRepairRequestMap, setDeliveryDetail, setId, handleGoBack, inspectionId, isRepairRequest, updateInspection, isSurveyUpdating, isUpdatingStatus, canShowBackButton, startPreDelivery, previewHandler, deliveryDetail, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  //Redux State
  const { selected, all } = useSelector(state => state.buildings);
  const app = useSelector(state => state.app);
  const { selectedInspection, inspectionLoading, repairRequests, loadingRepairRequests } = useSelector(state => state.surveying);
  const [bottomDrawer, setBottomDrawer] = useState({ open: false, key: '' });
  const requestsStatus = repairRequests && repairRequests.length && groupBy(repairRequests, "status");
  const [requests, setRequests] = useState(repairRequests);
  const isOnline = useInternetStatusDetector();
  useEffect(() => {
    setRequests(repairRequests.map(p => {
      const building = all.find(b => b.buildingId === p.buildingId);
      if (building) p.buildingNoExtern = building.buildingNoExtern;
      return p;
    }));
  }, [repairRequests]);
  useEffect(() => {
    if (selected && !openRepairRequestMap) {
      dispatch(surveyActions.getSurveyDetailAction(params.inspectionId, false));
      dispatch(surveyActions.getRepairRequests(params.inspectionId));
      setId(params.inspectionId)
    }
    return () => {
      setId(null)
    }
  }, [canGoBack, selected]);

  useEffect(() => {
    setDeliveryDetail(true);
    return () => {
      setDeliveryDetail(false);
    };
  }, [])


  const bottomDrawerOpenHandler = (key) => {
    setBottomDrawer({ open: true, key })
  }
  return (
    <div className={clsx(classes.layoutContainer, (inspectionLoading) && classes.layoutContainerHeight)}>
      {inspectionLoading ?
        <div className={classes.layoutLoading}>
          <CircularProgress size={20} />
        </div>
        : <>
          <Hidden smUp>
          <BottomDrawer open={bottomDrawer.open && bottomDrawer.key !== 'gebrekenlijst'} close={() => setBottomDrawer({ open: false })}>
              <div>
                <div className={clsx(classes.drawerHeader, classes.stickyHeader)}>
                  <Typography className={classes.surveyMomentCardTitle}>{t(`${'survey.externalNotes'}`)}</Typography>
                  <Close className={classes.drawerIcon} onClick={() => setBottomDrawer({ open: false })} />
                </div>
                <Divider />
                <Grid item xs={12}>
                  <Remarks selectedInspection={selectedInspection} preDeliveriesDetail deliveriesDetail />
                </Grid>
              </div>
            </BottomDrawer>
          </Hidden>
          <BasicDetails
            showPdfIcon
            isSecondSignature
            hideSignatureFromPDF
            showMeldingImage={isOnline}
            onlyOfflinePDFView
            hidePDFShare
            data={selectedInspection} />
          <Hidden smDown>
            <div className={classes.layoutSubContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Remarks selectedInspection={selectedInspection} preDeliveriesDetail deliveriesDetail />
                </Grid>
              </Grid>
            </div>
          </Hidden>
          <Hidden smUp>
            {data.map((p) => {
              return (
                <div className={classes.layoutSubContainer}>
                  <Paper className={classes.cardPaper} elevation={0} onClick={() => bottomDrawerOpenHandler(p.key)}>
                    <div className={classes.cardLayout}>
                      <Typography className={classes.cardTitle}>{t(p.title)}</Typography>
                      <Typography className={classes.cardSubTitle}>{
                        p.key === "gebrekenlijst" ? <>
                          <div className={classes.statusContainer}>
                            <div className={classes.status}>
                              <Typography className={classes.cardStatusTitle}>{t("general.total")}</Typography>
                              <div className={classes.cardStatusSquare}>
                                <Typography className={classes.cardStatusNumber}>{repairRequests && repairRequests.length}</Typography>
                              </div>
                            </div>
                            {Object.keys(requestsStatus).map(key => (
                              <div key={key} className={classes.status}>
                                <Typography className={classes.cardStatusTitle}>{t(key)}</Typography>
                                <div className={classes.cardStatusSquare} style={{ backgroundColor: statusColors[key] }}>
                                  <Typography className={classes.cardStatusNumber}>{requestsStatus[key].length}</Typography>
                                </div>
                              </div>
                            ))}
                          </div>
                        </> : ''
                      }</Typography>
                    </div>
                    <div className={classes.square}>
                      {typeof p.Icon !== "string" ? <p.Icon className={classes.bugIcon} /> : <Icon fontSize="default" className={classes.bugIcon}>{p.Icon}</Icon>}

                    </div>
                  </Paper>
                </div>
              )
            })}
          </Hidden>
          <div className={classes.layoutSubContainer}>
            <RepairRequests
              setOpenRepairRequestMap={setOpenRepairRequestMap}
              selectedInspection={selectedInspection}
              bottomDrawer={bottomDrawer}
              bottomDrawerClose={() => setBottomDrawer({ open: false })}
              keyPath={'inspectionId'}
              loading={loadingRepairRequests}
              inspection
              data={requests}
              selectedApp={app}
              title={'survey.defectsList'}
              buttonText={'survey.defects'} />
          </div>
        </>}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  drawerHeader: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 7
  },
  surveyMomentCardTitle: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    fontWeight: 'bold',
    color: theme.palette.common.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  drawerIcon: {
    fill: theme.palette.grey[300],
    cursor: 'pointer'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.common.white,
    zIndex: 1,
  },
  square: {
    height: 60,
    width: 60,
    background: alpha(theme.palette.primary.main, 0.05),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50
  },
  bugIcon: {
    fontSize: 40,
    fill: theme.palette.grey[500],
    color: theme.palette.grey[500],
  },
  cardTitle: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    marginBottom: 7
  },
  cardSubTitle: {
    fontSize: 18,
    color: theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardLayout: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardPaper: {
    display: 'flex',
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  layoutLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  layoutContainer: {
    padding: theme.spacing(0, 2, 2, 2),
    height: 'auto',
  },
  layoutContainerHeight: {
    height: '100% !important'
  },
  layoutSubContainer: {
    marginTop: theme.spacing(1),
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  statusContainer: {
    display: "flex"
  },
  status: {
    marginRight: 5
  },
  cardStatusTitle: {
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    fontSize: 10,
  },
  cardStatusNumber: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 15,
  },
  cardStatusSquare: {
    width: 30,
    height: 25,
    background: "#006ED8",
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))
