
import React, { useEffect, useState } from 'react'

import { ContractDetails } from './Components/ContractDetails';
import { ViewContract } from './Components/ViewContract';
import { getContractDetails } from '../../apis/contractApis';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Alert, CircularProgress, Container, Snackbar, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';


const Contract = () => {
 const [isPreviewEnabled, setIsPreviewEnabled] = useState(false)
 const [contractDetails, setContractDetails] = useState()
 const [isContractDetailsLoading, setIsContractDetailsLoading] = useState(false);
 const { contractId } = useParams();
 const [toast, setToast] = React.useState({open:false, message:null, type:""})
 const classes = useStyles();



 const fetchContractDetails = async (loading = false)=>{
  try{
    setIsContractDetailsLoading(loading)
    const response = await getContractDetails(contractId)
    setContractDetails(response.data)
  }catch(e){
    console.log(e)
  }finally{
    setIsContractDetailsLoading(false)
  }
  
  }
  useEffect(() => {
    fetchContractDetails(true)
  }, [])

  const handleCloseSnackbar = ()=>{
    setToast({open:false, message:null, type:"success"})
  }

  
  if(isContractDetailsLoading){
    return <Stack justifyContent='center' alignItems='center' height="100%" width="100%">  <CircularProgress /></Stack>
  }


  return (
    <>
    <Container  maxWidth={false} className={classes.mainContainer}>
   {!isPreviewEnabled && <ContractDetails contractDetails={contractDetails} fetchContractDetails={fetchContractDetails} setIsPreviewEnabled={setIsPreviewEnabled} setToast={setToast}/>}
   {isPreviewEnabled && <ViewContract contractDetails={contractDetails} setIsPreviewEnabled={setIsPreviewEnabled} setToast={setToast} fetchContractDetails={fetchContractDetails}/>}

   <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={toast.type || "success"}
        >
          {toast.message}
        </Alert>
      </Snackbar>
      </Container>
    </>
  )
}

export { Contract }



const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));
