import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Container } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDataTableTextLabels } from "../../_helpers";
import SelectResponsiblePerson from "../Survey/components/SelectResponsiblePerson";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { surveyActions } from "../../_actions";
import { useHistory } from "react-router-dom";
export function EmailLinkLists(props) {
  const classes = useStyles();
  const { applicationManagerItems, getApplicationLoading } = useSelector(
    (state) => state.surveying
  );
  const { wbEmailLinkingInfo = [] } = applicationManagerItems || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [oldEmailId, setOldEmailId] = useState(null);
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLinkEmail = ({ loginId, ...data }) => {
    dispatch(
      surveyActions.linkEmail(selectedEmailId, loginId, oldEmailId, data)
    );
    setSelectedEmailId(null);
    setOldEmailId(null);
  };

  useEffect(() => {
    dispatch(surveyActions.getApplicationManagerActionItem());
  }, [history.location.key]);

  const columns = [
    {
      name: "loginId",
      options: {
        display: "excluded",
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: "email",
      label: t("general.email"),
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "loginPersonName",
      label: t("settings.table.column.personName"),
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "loginEmail",
      label: t("settings.table.column.personEmail"),
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: "edit",
      label: t("general.action"),
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (_, { rowData, rowIndex, ...rest }) => {
          return (
            <Button
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setSelectedEmailId(rowData[1]);
                setOldEmailId(rowData[0]);
              }}
              disabled={organizationLoading && selectedEmailId === rowData[1]}
              variant="outlined"
              color="primary"
              size="small"
            >
              {organizationLoading ? (
                <CircularProgress size={20} />
              ) : (
                t("general.edit")
              )}
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    draggableColumns: { enabled: true },
    print: true,
    download: true,
    pagination: true,
    textLabels: getDataTableTextLabels(t, getApplicationLoading),
    customToolbarSelect: (selectedRows) => <></>,
  };

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <MUIDataTable
        title={t("settings.emailList.title")}
        data={getApplicationLoading ? [] : wbEmailLinkingInfo}
        className={classes.dataTable}
        columns={columns}
        options={options}
      />
      <SelectResponsiblePerson
        anchorEl={anchorEl}
        customTitle={"Login Person"}
        customButtonTitle={"Link"}
        customOplosser
        hideExtraInfo
        onSubmit={(data) => handleLinkEmail(data)}
        loading={organizationLoading}
        setLoading={(val) => setOrganizationLoading(val)}
        onClose={(data) => {
          setAnchorEl(null);
          setSelectedEmailId(null);
        }}
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: theme.spacing(0),
    background: theme.palette.common.white,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(1, 0.5, 1, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
}));
