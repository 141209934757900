import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, DialogContentText, Slide, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  DialogActions,
  Button,
  Tab,
  Tabs,
  AppBar,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import SignaturePad from 'react-signature-canvas';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { getSurveyReport } from '../../../apis/surveyingApis';
import { CircularProgress } from '@mui/material';
import { commonActions, surveyActions } from '../../../_actions';
import { Alert } from '@mui/material';
import { usePDFReport } from './PDFReport';
import { pdf } from '@react-pdf/renderer';
import { formatDate, SurveyType, toBase64 } from '../../../_helpers';
import { getBrowserInfo } from '../../../components/DataGridV2/utils';

const { iOSSafari } = getBrowserInfo()

const DocumentViewer = React.memo(({ classes, document, ...props }) => {
  return (
    <DocViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true
        },
        pdfVerticalScrollByDefault: true
      }}
      documents={document}
      className={classes.myDoc}
      pluginRenderers={DocViewerRenderers}
      style={{ width: '100%' }} />
  )
}, (p, c) => {
  return p.document === c.document;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export default function PdfPreview({
  isDownloadedSurvey,
  setIsOpenSignature = () => {},
  signModal = false,
  isSecondSignature = false,
  id,
  setPdfPreview = () => {},
  setId = () => {},
  signatureModal = false,
  close = () => {},
  offlinePDFOnly,
  hideSignatureFromPDF,
  showMeldingImage,
  ...props
}) {
    let sigPad = [...Array(3)];
    sigPad[0] = useRef({});
    sigPad[1] = useRef({});
    sigPad[2] = useRef({});
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [signsUrls, setSignsUrls] = useState({
    executor: '',
    buyerRenter1: '',
    buyerRenter2: ''
  });
  const [previewSignUrls, setPreviewSignUrls] = useState({
    executor: '',
    buyerRenter1: '',
    buyerRenter2: ''
  });
  const [backdrop, setBackdrop] = useState(false);
  const [canStoreOfflineReport, setCanStoreOfflineReport] = useState(false);
  const classes = useStyles({ activeTab });
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const { selected } = useSelector((state) => state.buildings);
  const { availableSurveyModules } = useSelector((state) => state.availableSurveyModules);
  const { selectedInspection, isSurveyUpdating, isSurveyUpdateError, repairRequests, loadingRepairRequests } = useSelector((state) => state.surveying);
  const [signPadsData, setSignPadsData] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [submitError, setSubmitError] = useState({
    open: false,
    message: t('email.validation.error')
  });
  const pdfReport = usePDFReport(isSecondSignature, hideSignatureFromPDF, showMeldingImage);
  useEffect(() => {
    return () => {
      setId(null);
      setPdfPreview(false);
    };
  }, []);
  useEffect(() => {
    (async () => {
      if (canStoreOfflineReport && !isSurveyUpdating) {
        setCanStoreOfflineReport(false);
        const pdfFile = await toBase64(await pdf(pdfReport).toBlob());
        const name =
          (selectedInspection.surveyType === SurveyType.Delivery ? (isSecondSignature ? 'PvO_met_tweede_handtekening_' : 'PvO_') : t('survey.preDelivery') + '_') +
          formatDate(new Date()) +
          '_' +
          new Date().getTime() +
          '.pdf';
        const data = {
          ...selectedInspection,
          offlineReport: {
            name,
            content: pdfFile.split(',')[1]
          }
        };
        dispatch(surveyActions.updateSurvey(data, selectedInspection.surveyType, isSecondSignature, 0));
      }
    })();
  }, [canStoreOfflineReport, isSurveyUpdating, selectedInspection]);

  useEffect(() => {
    if (!isSurveyUpdating && !isSurveyUpdateError && isSubmited) {
      close();
      setBackdrop(true);
      setIsSubmited(false);

      const canAvailableSSModule = availableSurveyModules.find((p) => p.moduleType === SurveyType.SecondSignature);
      if (!isSecondSignature && !canAvailableSSModule) {
        dispatch(commonActions.availableSurveyModules(selected.projectId));
      }
    } else if (!isSurveyUpdating && isSurveyUpdateError && isSubmited) {
      setIsSubmited(false);
      setSubmitError((p) => ({ ...p, open: true }));
    }
  }, [isSurveyUpdating, isSurveyUpdateError]);

  useEffect(() => {
    if (selectedInspection && selectedInspection.surveyId === params.inspectionId) {
      const data = [];
      const buyerRenter1 = selectedInspection.buyerRenter1;
      const buyerRenter2 = selectedInspection.buyerRenter2;
      const executor = selectedInspection.executedBy;
      !isSecondSignature &&
        executor &&
        data.push({
          name: executor,
          type: 'executor',
          label: t('survey.sign.signatureOnBehalfOfCompany'),
          id: 0
        });
      buyerRenter1 &&
        data.push({
          name: buyerRenter1,
          type: 'buyerRenter1',
          label: t('survey.sign.buyerSignature1'),
          id: !isSecondSignature && executor ? 1 : 0
        });
      buyerRenter2 &&
        data.push({
          name: buyerRenter2,
          type: 'buyerRenter2',
          label: t('survey.sign.buyerSignature2'),
          id: !isSecondSignature && executor ? 2 : 1
        });
      const sign = data.reduce((prev, c) => {
        return { ...prev, [c.type]: c };
      }, {});
      setSignsUrls(sign);
      setPreviewSignUrls(
        data.reduce((prev, c) => {
          return { ...prev, [c.type]: '' };
        }, {})
      );
      setSignPadsData(data.sort((p, c) => p.id - c.id));
    }
  }, [selectedInspection]);

  useEffect(() => {
    setPdfPreview(true);
    setId(params.inspectionId);
    if (params && params.inspectionId && selectedInspection) {
      setLoading(true);
      if (window.navigator.onLine && !offlinePDFOnly) {
        getSurveyReport(params.inspectionId, isSecondSignature)
          .then(async ({ data, ...res }) => {
            const uri = await window.URL.createObjectURL(data);
            const doc = { uri, fileType: data.type };
            setDocument([doc]);
            setLoading(false);
          })
          .catch((er) => {
            setLoading(false);
          });
      } else {
        loadOfflineReport().then(x => {
          if (x !== true) loadOfflineReport(); //Give it another try when fails.
        });
      }
    }
  }, [selectedInspection, repairRequests]);

  useEffect(() => {
    if (signPadsData[activeTab] && previewSignUrls[signPadsData[activeTab].type] !== '') {
      sigPad[activeTab].fromDataURL(previewSignUrls[signPadsData[activeTab].type]);
    }
  }, [activeTab]);

  const loadOfflineReport = () => {
    return pdf(pdfReport)
      .toBlob()
      .then(async (blob) => {
        const url = await window.URL.createObjectURL(blob);
        setDocument([{ name: 'Opname_Preview.pdf', uri: url, fileType: 'application/pdf' }]);
        setLoading(false);
        return true;
      })
      .catch((er) => {
        return false;
      });
  };

  const handleBackDropClose = () => {
    setBackdrop(false);
    close();
    history.replace(props.match.url.split('/preview')[0]);
  };

  const completeSignature = async () => {
    let data = Object.keys(signsUrls).reduce(
      (prevSigns, key) => ({
        ...prevSigns,
        [key + (isSecondSignature ? 'Second' : '') + 'Signature']: {
          name: `${(signPadsData.find((p) => p.type === key) || {}).name || ''}.png`,
          content: signsUrls && typeof signsUrls[key] === 'string' ? signsUrls[key].split(',')[1] : ''
        }
      }),
      {
        ...selectedInspection,
        buildingId: selectedInspection.buildingId,
        projectId: selectedInspection.projectId,
        surveyType: selectedInspection.surveyType,
        sync: 0,
        carriedOutBy: '',
        surveyId: params.inspectionId,
        status: 2,
        date: new Date(),
        buyerRenter1SecondSignature: {
          name: '',
          content: ''
        },
        buyerRenter2SecondSignature: {
          name: '',
          content: ''
        }
      }
    );
    if (isDownloadedSurvey) {
      setCanStoreOfflineReport(true);
      data = {
        ...data,
        secondSignatureDate: isSecondSignature ? new Date() : null
      };
    }

    setIsSubmited(true);
    dispatch(surveyActions.updateSurvey(data, selectedInspection.surveyType, isSecondSignature, 0));
  };

  const handleCompleteMoments = () => {
    setIsOpenSignature(true);
    handleBackDropClose();
  };

  const clear = async (type) => {
    await sigPad[activeTab].clear();
    captureSign(type, true);
  };

  const captureSign = (type, clear) => {
    const pad = sigPad[activeTab];
    setSignsUrls((p) => {
      return {
        ...p,
        [type]: clear ? null : sigPad[activeTab].getTrimmedCanvas().toDataURL('image/png')
      };
    });
    setPreviewSignUrls((p) => ({
      ...p,
      [type]: clear ? null : pad.getCanvas().toDataURL('image/png')
    }));
  };

  const signModalHandler = () => {
    completeSignature();
  };

  const nextHandleChange = (event, newValue) => {
    // captureSign(signPadsData[activeTab].type);
    setActiveTab(activeTab + 1);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    // captureSign(signPadsData[newValue])
  };

  const previousHandleChange = (event, newValue) => {
    setActiveTab(activeTab - 1);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.primary.main,
      '& .MuiTypography-root': {
        color: theme.palette.common.white,
        fontSize: 16,
        fontWeight: 'normal'
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.common.white
      }
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const ReportDocumentViewer = useCallback(() => {
    return <DocumentViewer classes={classes} document={document} />;
  }, [document]);

  const checkIfUserHasSigned = (userSign) => {
    return typeof userSign === 'string' && userSign.includes('data:image/png;base64');
  };

  const checkForSigns = () => {
    return isSecondSignature
      ? !(checkIfUserHasSigned(signsUrls.buyerRenter1) || checkIfUserHasSigned(signsUrls.buyerRenter2))
      : !(
          (checkIfUserHasSigned(signsUrls.executor) && checkIfUserHasSigned(signsUrls.buyerRenter1)) ||
          (checkIfUserHasSigned(signsUrls.executor) && checkIfUserHasSigned(signsUrls.buyerRenter2))
        );
  };

  return (
    <div className={classes.fullHeight}>
      <Snackbar
        style={{ top: 120 }}
        TransitionComponent={(props) => <Slide {...props} direction='left' />}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={submitError.open}
        onClose={() => setSubmitError((p) => ({ ...p, open: false }))}
        autoHideDuration={3000}
        key={'file-size-validation'}
      >
        <Alert elevation={6} variant='filled' severity='error'>
          {t(submitError.message)}
        </Alert>
      </Snackbar>
      {!signModal ? (
        <>
          {loading ? (
            <div className={classes.layoutLoading}>
              <CircularProgress size={20} />
            </div>
          ) : (
            <ReportDocumentViewer />
          )}
        </>
      ) : (
        <>
          <div className={classes.signatureDialog}>
            <DialogContent className={classes.signDialogContent}>
              <AppBar position='static' color='primary' className={classes.signatureAppBar}>
                <Tabs
                  value={activeTab}
                  onChange={handleChange}
                  indicatorColor='primary'
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons
                  aria-label='scrollable auto tabs example'
                  allowScrollButtonsMobile>
                  {signPadsData.map((p) => (
                    <Tab key={p.label} label={p.label} {...a11yProps(p.id)} />
                  ))}
                </Tabs>
              </AppBar>
              {signPadsData.map((p) => (
                <TabPanel key={p.label} className={classes.signaturePanel} value={activeTab} index={p.id}>
                  <Typography className={classes.signTitle}>{t(p.name)}</Typography>
                  <div className={classes.sigMainContainer}>
                    <div className={classes.sigContainer}>
                      <SignaturePad
                        onEnd={() => captureSign(p.type)}
                        clearOnResize={false}
                        canvasProps={{ className: classes.signPad }}
                        ref={(ref) => {
                          sigPad[activeTab] = ref;
                        }}
                      />
                    </div>
                    <div className={classes.signaturePadButton}>
                      <Button className={classes.buttons} onClick={() => clear(p.type)}>
                        {t('survey.clear')}
                      </Button>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </DialogContent>
            <DialogActions className={classes.signButton}>
              {activeTab > 0 && activeTab !== signPadsData.length && (
                <Button variant='contained' className={classes.actionButton} onClick={previousHandleChange} color='primary'>
                  {t('general.button.previous')}
                </Button>
              )}
              {activeTab < signPadsData.length && activeTab !== signPadsData.length - 1 && (
                <Button variant='contained' className={classes.actionButton} onClick={nextHandleChange} color='primary'>
                  {t('general.button.next')}
                </Button>
              )}
              {activeTab === signPadsData.length - 1 && (
                <Button
                  disabled={checkForSigns() || isSurveyUpdating || loadingRepairRequests}
                  variant='contained'
                  className={classes.actionButton}
                  onClick={signModalHandler}
                  color='primary'
                >
                  {t('general.sign')}
                </Button>
              )}
            </DialogActions>
          </div>
        </>
      )}

      <Dialog onClose={close} fullScreen={fullScreen} className={classes.signatureDialog} aria-labelledby='customized-dialog-title' maxWidth={'xl'} open={signatureModal}>
        <DialogTitle id='customized-dialog-title' onClose={close}>
          {t('general.signature')}
        </DialogTitle>
        <DialogContent className={classes.signDialogContent}>
          <AppBar position='static' color='primary' className={classes.signatureAppBar}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons
              aria-label='scrollable auto tabs example'
              allowScrollButtonsMobile>
              {signPadsData.map((p) => (
                <Tab key={p.label} label={p.label} {...a11yProps(p.id)} />
              ))}
            </Tabs>
          </AppBar>
          {signPadsData.map((p) => (
            <TabPanel key={p.label} className={classes.signaturePanel} value={activeTab} index={p.id}>
              <Typography className={classes.signTitle}>{t(p.name)}</Typography>
              <div className={classes.sigMainContainer}>
                <div className={classes.sigContainer}>
                  <SignaturePad
                    onEnd={() => captureSign(p.type)}
                    clearOnResize={false}
                    canvasProps={{ className: classes.signPad }}
                    ref={(ref) => {
                      sigPad[activeTab] = ref;
                    }}
                  />
                </div>
                <div className={classes.signaturePadButton}>
                  <Button className={classes.buttons} onClick={() => clear(p.type)}>
                    {t('survey.clear')}
                  </Button>
                </div>
              </div>
            </TabPanel>
          ))}
        </DialogContent>
        <DialogActions className={classes.signButton}>
          {activeTab > 0 && activeTab !== signPadsData.length && (
            <Button variant='contained' className={classes.actionButton} onClick={previousHandleChange} color='primary'>
              {t('general.button.previous')}
            </Button>
          )}

          {activeTab < signPadsData.length && activeTab !== signPadsData.length - 1 && (
            <Button variant='contained' className={classes.actionButton} onClick={nextHandleChange} color='primary'>
              {t('general.button.next')}
            </Button>
          )}

          {activeTab === signPadsData.length - 1 && (
            <Button
              disabled={checkForSigns() || isSurveyUpdating || loadingRepairRequests}
              variant='contained'
              className={classes.actionButton}
              onClick={signModalHandler}
              color='primary'
            >
              {isSurveyUpdating ? <CircularProgress size={20} /> : t('general.sign')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog className={classes.backdrop} open={backdrop} onClose={handleBackDropClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' className={classes.dialogContextText}>
            {t('survey.sendReportToEmailConfirmation')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' className={classes.dialogButtonPrimary} onClick={handleCompleteMoments}>
            {t('button.action.yes')}
          </Button>
          <Button className={classes.dialogButtonSecondary} onClick={handleBackDropClose}>
            {t('button.action.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0
    }
  },
  dialogContextText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  myDoc: {
    background: '#fff !important',
    '& #pdf-download': {
      display: iOSSafari ? "none" : "flex",
    }
  },
  layoutLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  signTitle: {
    height: 'auto !important',
    color: theme.palette.grey[500],
    fontSize: 14,
    marginBottom: 8
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3)
    },
    minWidth: 100,
    padding: theme.spacing(0.875, 1.25)
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3)
    },
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grey[500]
    }
  },
  backdrop: {
    '& .MuiPaper-root': {
      borderRadius: 10
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      '& p': {
        color: theme.palette.grey[700],
        textAlign: 'center'
      }
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',

      '& .MuiButtonBase-root': {
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1, 1, 2, 1)
        },
        margin: '10px 17px 25px 17px'
      }
    }
  },
  signaturePadButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  sigImage: {
    backgroundSize: '200px 50px',
    width: '200px',
    height: '50px',
    backgroundColor: 'white'
  },
  sigMainContainer: {
    width: '100%',
    height: '100%'
  },
  sigContainer: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 7
  },
  signDialogContent: {
    padding: 0,
    position: 'relative',
    height: '100%',
    [theme.breakpoints.only('sm')]: {
      height: 'calc(100% - 74px)',
      paddingTop: '0px !important'
    }
  },
  signaturePanel: {
    width: '100%',
    position: 'absolute',
    height: 'calc(100% - 100px)'
  },
  actionButton: {
    maxWidth: '116px',
    maxHeight: '42px',
    minWidth: '116px',
    minHeight: '42px',
    fontSize: 14,
    fontWeight: 'normal'
  },
  signButton: {
    display: 'flex',
    justifyContent: ({ value }) => (value < 1 ? 'flex-end' : 'space-between'),
    padding: theme.spacing(2)
  },
  signPad: {
    width: '100%',
    height: '100%'
  },
  signatureAppBar: {
    boxShadow: 'none',
    background: 'none',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& .MuiTabs-flexContainer': {
      padding: theme.spacing(0.5, 0)
    },
    '& .MuiTab-root': {
      textTransform: 'inherit',
      fontSize: 12,
      [theme.breakpoints.only('sm')]: {
        fontSize: 16
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 14
      },
      color: theme.palette.grey[800],
      fontWeight: 'normal'
    },
    '& .Mui-selected': {
      fontWeight: 'bold'
    }
  },
  signatureDialog: {
    height: '100%',
    '& .MuiBox-root': {
      height: '100%',
      '& .MuiTypography-root': {
        height: '100%'
      }
    },
    '& .MuiDialog-paper': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        minWidth: '100%',
        maxHeight: '100%',
        minHeight: '100%'
      },
      maxWidth: '80%',
      minWidth: '80%',
      maxHeight: '80%',
      minHeight: '80%',
      borderRadius: 10
    }
  },
  fullHeight: {
    height: '100%',
    '& iframe': {
      height: '100%',
      width: '100%'
    }
  }
}));
