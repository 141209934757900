import React from 'react'
import { Grid, Typography, Card, CardContent, Paper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import MUIDataTable from 'mui-datatables';
import { nl2br } from '../../../_helpers';
import { URLS } from '../../../apis/urls';


export default function CustomBottomDrawerChildrenRenderer({ onPreview = () => 0, title = null, type, onClose = () => 0, data, ...props }) {
    const classes = useStyles();
    const { t } = useTranslation();
    return <>
        <Grid container style={{ position: 'relative' }}>
            {title && <Grid xs={12} className={classes.stickyHeader}>
                <div className={classes.drawerHeader}>
                    <Typography className={classes.surveyMomentCardTitle}>{type === 'repairrequests' ? title : t(title)}</Typography>
                    <Close className={classes.drawerIcon} onClick={onClose} />
                </div>
                <Divider />
            </Grid>}
            <Grid xs={12} className={classes.drawerContainer}>
                {type === 'assignmentTextInfo' && (
                    <div>
                        <Card className={classes.cardContainer}>
                            <CardContent className={classes.cardContentContainer}>
                                <Typography className={classes.assignmentTitle} gutterBottom>
                                    {data && data.title}
                                </Typography>
                                <Paper className={classes.papers}>
                                    <Typography className={classes.assignmentDetailContent} gutterBottom>
                                        {data && nl2br(data.text)}
                                    </Typography>
                                </Paper>
                            </CardContent>
                            <CardContent className={classes.cardContentContainer}>
                                <Typography className={clsx(classes.assignmentTitle, classes.explanationTitle)} gutterBottom>
                                    {t('general.explanation')}
                                </Typography>
                                <Paper className={classes.papers}>
                                    <Typography className={classes.assignmentDetailContent} gutterBottom>
                                        {data && nl2br(data.remark)}
                                    </Typography>
                                </Paper>
                            </CardContent>
                        </Card>
                    </div>
                )}
                {type === 'evidenceInfo' && (
                    <div>
                        <Grid item xs={12} className={classes.filesContainer}>
                            <Grid container className={classes.gridContainer}>
                                {
                                    data.attachments && data.attachments.map((p, i) => (
                                        <Grid key={`image-${i}`} className={classes.attachmentItemContainer} item onClick={() => onPreview([{attachmentId:p}])}>
                                            <Grid item>
                                                <Grid
                                                    className={classes.thumnailContainer}
                                                    container xs={12} justifyContent="center">
                                                    <div className={classes.thumbnail}
                                                        style={{ backgroundImage: `url(${URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL}${p})` }} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid xs={12}>
                                <CardContent className={classes.cardContentContainer}>
                                    <Typography className={clsx(classes.assignmentTitle, classes.explanationTitle)} gutterBottom>
                                        {t('general.remarks')}
                                    </Typography>
                                    <Paper className={classes.papers}>
                                        <Typography className={classes.assignmentDetailContent} gutterBottom>
                                            {data && nl2br(data.remark)}
                                        </Typography>
                                    </Paper>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {type === 'repairrequests' && (
                    <MUIDataTable
                        className={classes.repairRequestDataTable}
                        data={data.repairRequests}
                        columns={data.columns}
                        options={{
                            ...data.options,
                            elevation: 0
                        }} />
                )}
            </Grid>
        </Grid>
    </>;
}

const useStyles = makeStyles((theme) => ({
    repairRequestDataTable: {
        '& .MuiTableCell-root': {
            '& .datatables-noprint': {
                [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }
            },
        }
    },
    drawerContainer: {
        padding: theme.spacing(1, 0)
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        background: theme.palette.common.white,
        zIndex: 1,
    },
    drawerHeader: {
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        // borderBottom: `1px solid ${theme.palette.grey[300]}`,
        alignItems: 'center',
        // marginBottom: 10,
        paddingBottom: 7
    },
    surveyMomentCardTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.common.black,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.only('xs')]: {
            fontSize: 14
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16
        },
    },
    drawerIcon: {
        fill: theme.palette.grey[300],
        cursor: 'pointer'
    },
    paperSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 22,
    },
    surveyMomentCardSubTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.common.black,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginBottom: 4
    },
    cardContainer: {
        width: '100%',
        height: 'fit-content',
        boxShadow: 'none'
    },
    assignmentTitle: {
        fontSize: 12,
        // marginTop: 12,
        // marginBottom: 12,
        color: theme.palette.grey[800],
        fontWeight: 'bold',
        marginLeft: 7,
    },
    papers: {
        minWidth: 131,
        flexGrow: 1,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[200],
        borderRadius: 6,
        boxShadow: 'none',
        margin: theme.spacing(0, 0.6)
    },
    assignmentDetailContent: {
        fontSize: 12,
        color: theme.palette.grey[600],
    },
    cardContentContainer: {
        padding: theme.spacing(2, 0, 0, 0)
    },
    explanationTitle: {
        marginTop: 0,
    },
    filesContainer: {
        overflow: 'auto',
        padding: theme.spacing(1.25),
    },
    gridContainer: {
        justifyContent: 'space-around',
        gridTemplateColumns: 'repeat(auto-fill, 53px)',
        display: 'grid',
        gap: 10
    },
    attachmentItemContainer: {
        position: 'relative',
        background: theme.palette.common.white,
        boxShadow: '3px 3px 4px #eee',
        height: 54,
        overflow: 'hidden',
        borderRadius: 5,
    },
    thumbnail: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
        display: 'block',

    },
    thumnailContainer: {
        width: 53,
        height: 54,
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-evenly',

    },
}));