import React from 'react';
import { Typography, Grid } from '@mui/material';

const RowDetails = ({ title, value }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <Typography variant="caption" color="text.secondary" component="p" sx={{ wordWrap: 'break-word' }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {!React.isValidElement(value) ? (
          <Typography variant="caption" color="text.primary" component="p" sx={{ wordWrap: 'break-word' }}>
            {value?.toString()}
          </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

export default RowDetails;
