import React, { useState } from "react";
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CloudDownload, PictureAsPdf, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { URLS } from '../../apis/urls';
import DocumentViewer from '../../components/DocumentViewer';
import { getMimeTypefromString } from '../../_helpers';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(1, 0),
    '& div': {
      minWidth: 40,
      color: theme.palette.grey[700]
    },
    '& span': {
      color: theme.palette.grey[700],
      fontSize: 12,
    }
  },
}))(MenuItem);

const WorkOrderPDF = ({ resolverId, ...props }) => {
  const [state, setState] = useState({
    reportPopOverEl: null,
    previewDocument: [],
    isOpenDocViewer: false
  });
  const classes = useStyles();
  const { t } = useTranslation();

  return <>
    <IconButton
      onClick={(e) => setState({ reportPopOverEl: e.currentTarget })}
      className={classes.pdfButton}
      variant="outlined"
      size="large">
      <PictureAsPdf color={"error"} />
    </IconButton>

    {!!state.reportPopOverEl && <Menu
      id="simple-menu"
      className={classes.menuContent}
      anchorEl={state.reportPopOverEl}
      open={!!state.reportPopOverEl}
      onClose={() => setState({ reportPopOverEl: null })}
      keepMounted
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disableRestoreFocus
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuList className={classes.menuListStyle}>
        <StyledMenuItem component={'a'}
          href={URLS.GET_WORK_ORDER_REPORT_PDF + resolverId}
          download
        >
          <ListItemIcon>
            <CloudDownload className={classes.menuIcon} color={'primary'} />
          </ListItemIcon>
          <Typography variant="inherit">{t('general.download')}</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          setState({
            previewDocument: [{
              uri: URLS.GET_WORK_ORDER_REPORT_PDF + resolverId,
              fileType: getMimeTypefromString(".pdf"),
              name: ""
            }],
            isOpenDocViewer: true
          });
        }}>
          <ListItemIcon>
            <Visibility
              className={classes.menuIcon}
              color={'primary'} />
          </ListItemIcon>
          <Typography variant="inherit">{t('general.show')}</Typography>
        </StyledMenuItem>
      </MenuList>
    </Menu>}
    <DocumentViewer
      radius={classes.docRadius}
      isSurveying
      open={state.isOpenDocViewer}
      onClose={() => {
        setState({
          previewDocument: [],
          isOpenDocViewer: false
        })
      }}
      documents={state.previewDocument || []} />
  </>;
}


const useStyles = makeStyles(theme => ({
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    borderRadius: 10,
    width: 167,
  },
  menuIcon: {
    marginRight: 10,
  },
  menuContent: {
    '& .MuiPopover-paper': {
      borderRadius: 10,
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
    },
    '& ul': {
      padding: theme.spacing(0),
    },
    '& ul > ul': {
      padding: theme.spacing(0.5, 2),
    },
  },
  pdfButton: {
    padding: theme.spacing(.2),
    outline: 'none !important'
  },
}));


export default WorkOrderPDF;