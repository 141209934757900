import { fileUploadConfig } from './fileUploadConfig';
import i18n from "../i18n";

const t = (key, params) => i18n.t(key, params);

export function validateFile({file, allowImage = false , allowPdf = false}) {
    const allowedFormats =  [...(allowImage ? fileUploadConfig.allowedImageFormats : [] ) ,...(allowPdf ? fileUploadConfig.allowedPdfFormats : []) , ...(allowPdf || allowImage ? [] : fileUploadConfig.allowedMimeTypes) ]
  
    if (!allowedFormats.find(x => x.mime === file.type.toLowerCase())) {
        alert(t("general.fileTypeNotSupported"));
        return false;
    }
    else if (file.size > fileUploadConfig.maxFileSizeInMB * 1024 * 1024) {
        alert(t("general.filesLargerThanMBNotAllowed", {maxFileSizeInMB : fileUploadConfig.maxFileSizeInMB}));
        return false;
    }
    else {
        return true;
    }
};

export function assignmentFileValidation(oldFiles,newFiles) {
    const files = Object.assign([], oldFiles).concat(newFiles)
    const maxFileSizeInMB = 10;
    const maxPDFSizeInMB = 20;
    const allowedFormats = fileUploadConfig.allowedImageFormats.concat({ extension: ".pdf", mime: "application/pdf" });
    const isValid = files.every(p => allowedFormats.find(f => f.mime === p.type));

    if (!isValid) {
        return { valid: false, message: t("general.fileTypeNotSupported") };
    }
    else if (files.some(file => file.type === "application/pdf" && file.size > (maxPDFSizeInMB * 1024 * 1024))) {
        return { valid: false, message: t("general.filesLargerThanMBNotAllowed", {maxFileSizeInMB : maxFileSizeInMB}) };
    }
    else if (files.some(file => file.type !== "application/pdf" && file.size > (maxFileSizeInMB * 1024 * 1024))) {
        return { valid: false, message: t("general.filesLargerThanMBNotAllowed", {maxFileSizeInMB : maxFileSizeInMB}) };
    }
    else {
        return { valid: true };
    }
}