export const formatInitials = (initials) => {
    if (!initials) {
        return "";
    }
    // Remove spaces from the input
    initials = initials.replace(/\s+/g, '');
    let result = '';
    let exception = '';
    if (initials.toLowerCase().startsWith('ph.') ||
        initials.toLowerCase().startsWith('chr.') ||
        initials.toLowerCase().startsWith('th.')) {
        const dotIndex = initials.indexOf('.');
        exception = initials.substring(0, dotIndex + 1);
        exception = exception.charAt(0).toUpperCase() + exception.slice(1).toLowerCase();
        // Remove the exception from the initials and recalculate the length
        initials = initials.substring(dotIndex + 1);
    }

    // Convert the remaining initials to uppercase and add periods
    for (let i = 0; i < initials.length; i++) {
        if (initials[i] !== '.') {
            result += initials[i].toUpperCase() + '.';
        }
    }
    result = exception + result;
    return result;
}