import { ButtonBase, Hidden, alpha, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { surveyActions } from '../../../_actions';
import { isReadOnly } from '../../../_helpers/enumsHelper';
import { useCanLockedInspection } from '../../../_helpers';
import TextFieldDialogPopup from './TextFieldDialogPopup';
import { NumericFormat } from 'react-number-format';


export default function MeterReading({ isSecondSignature, meterReading, deliveriesDetail, data, ...props }) {
  const dataKeys = [
    {
      label: 'survey.meterReadings.electricity1.label',
      key: 'meterReading_Electric1'
    },
    {
      label: 'survey.meterReadings.electricity2.label',
      key: 'meterReading_Electric2'
    },
    {
      label: 'survey.meterReadings.electricityReturn1',
      key: 'meterReading_ElectricReturn1'
    },
    {
      label: 'survey.meterReadings.electricityReturn2',
      key: 'meterReading_ElectricReturn2'
    },
    {
      label: 'survey.meterReadings.gasHeat',
      key: 'meterReading_GasHeat'
    },
    ...(data?.cityHeating ? [
      {
        label: 'survey.meterReadings.cityHeating',
        key: 'meterReading_CityHeating'
      }
    ] : []),
    {
      label: 'survey.meterReadings.water',
      key: 'meterReading_Water'
    },
  ]

  const classes = useStyles({ meterReading })
  const { t } = useTranslation();
  const theme = useTheme();
  const matchWidthDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const [readOnly, setReadOnly] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [meterkast, setMeterkast] = useState({
    meterReading_Electric1: null, meterReading_Electric2: null, meterReading_ElectricReturn1: null,
    meterReading_ElectricReturn2: null, meterReading_GasHeat: null, meterReading_Water: null, meterReading_CityHeating: null
  })
  const { isSurveyUpdating, isSurveyUpdateError } = useSelector(state => state.surveying)
  const dispatch = useDispatch()
  const canLockedInspection = useCanLockedInspection();
  const [openTextFieldDialog, setOpenTextFieldDialog] = useState(false);
  const [dataForTextFieldToEdit, setDataForTextFieldToEdit] = useState({});
  
  useEffect(() => {
    if (!isSurveyUpdating) {
      setOpenTextFieldDialog(false)
    }
  }, [isSurveyUpdating])

  useEffect(() => {
    if (data && !isSurveyUpdating) {
      setMeterkast(Object.keys(meterkast).reduce((p, key) => {
        if (focusedField === key || meterkast[key]) {
          return p;
        }
        return {
          ...p,
          [key]: data[key]
        };
      }, meterkast));
      setReadOnly(isReadOnly(isSecondSignature ? data.secondSignatureDate : data.status) || canLockedInspection || !data.hasRights)
    }
  }, [data, canLockedInspection]);

  useEffect(() => {
    if (!isSurveyUpdating && !isSurveyUpdateError && isUpdate) {
      setIsUpdate(false)
    }
  }, [isSurveyUpdating, isSurveyUpdateError])

  if (!data) return null;

  const handleClickOpen = (e, data) => {
    e.stopPropagation();
    if (!readOnly) {
      setOpenTextFieldDialog(true);
      setDataForTextFieldToEdit(data)
    }
  };
  const handleClose = () => {
    setOpenTextFieldDialog(false);
    setDataForTextFieldToEdit({});
  }

  const handleTextFieldUpdate = (key, meterkastData) => {
    const value = {
      ...data,
      ...meterkastData
    }
    setIsUpdate(key);
    dispatch(surveyActions.updateSurvey(value, value.surveyType));
    setFocusedField(null)
  }

  const handleUpdate = (event, textFieldValue) => {
    let value = { ...meterkast, [dataForTextFieldToEdit.key]: textFieldValue }
    setMeterkast(value);
    handleTextFieldUpdate(dataForTextFieldToEdit.key, value)
  }

  return (
    <Paper className={classes.detailMainContainer}>
      <div className={classes.detailContainer}>
        <Hidden mdDown>
          <Typography className={classes.detailHeader}> {t('survey.meterReadings')}</Typography>
        </Hidden>
        <form noValidate autoComplete="off">
          <Grid container spacing={matchWidthDownMd ? 2 : 3}>
            {dataKeys.map((p, index) => {
              return (
                <Grid item key={p.label} className={classes.detailGridContainer} xs={deliveriesDetail ? 12 : 12} sm={deliveriesDetail ? 12 : 4} md={deliveriesDetail ? 6 : 4} lg={deliveriesDetail ? 6 : 2} xl={deliveriesDetail ? 6 : 2}  >
                  <Paper className={classes.detailPaper}>
                    <div className={classes.detailInnerPapper}>
                              <ButtonBase
                                  disabled={readOnly || (isSurveyUpdating && isUpdate === p.key)}
                                  onClick={(e) => handleClickOpen(e, p)}>
                                  <div>
                                      <div className={classes.titleContainer}>
                                          <Typography className={classes.detailText}>
                                              {t(`${p.label}`)}
                                          </Typography>
                                      </div>
                                      <Typography variant="subtitle1">
                                        {p.key === "meterReading_CityHeating" ? <NumericFormat 
                                          displayType="text"
                                          decimalScale={3}
                                          fixedDecimalScale={true}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          valueIsNumericString ={false}
                                          value={meterkast[p.key]}
                                        /> : meterkast[p.key]}
                                      </Typography>
                                  </div>
                              </ButtonBase>
                    </div>
                  </Paper>
                </Grid>
              )
            })}
          </Grid>
        </form>


        <TextFieldDialogPopup
          open={openTextFieldDialog}
          dialogTitle={t('survey.editMeterReadings')}
          textFieldtitle={t(`${dataForTextFieldToEdit.label}`)}
          textFieldKey={dataForTextFieldToEdit.key}
          isUpdating={isSurveyUpdating ? (isSurveyUpdating && isUpdate === dataForTextFieldToEdit.key) : false}
          type="number"
          inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', shrink: true }}
          value={meterkast[dataForTextFieldToEdit.key]}
          handleClose={handleClose}
          handleUpdate={handleUpdate}
        />
      </div>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  meterReadingText: {
    '& input': {
      height: '0.9em'
    },
    fontSize: 12,
    color: theme.palette.grey[800],
    padding: theme.spacing(0.3, 1, 1, 1),
    '& .MuiInput-underline:before': {
      border: 'none'
    },
    '& .MuiInputBase-input:focus': {
      fontWeight: 'bold',
      borderBottom: `1px solid ${(alpha(theme.palette.primary.main, 0.05))}`,
      textTransform: 'capitalize',
      color: theme.palette.grey[700],
    },

  },
  titleContainer: {
    borderBottom: `1px solid ${(alpha(theme.palette.primary.main, 0.05))}`,
    padding: theme.spacing(1, 1, 0.3, 1),
    display: "flex",
    alignItems: "center"
  },
  detailText: {
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
      padding: theme.spacing(1)
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
      padding: theme.spacing(1)
    },
    fontSize: 12,
    color: theme.palette.grey[700],
    fontWeight: 'bold',

  },
  detailSubText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
      padding: theme.spacing(1)
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
      padding: theme.spacing(1)
    },
    fontSize: 12,
    color: theme.palette.grey[800],
    padding: theme.spacing(0.3, 1, 1, 1)
  },
  detailGridContainer: {
    [theme.breakpoints.only('sm')]: {
      padding: `${theme.spacing(1, 1.6)}!important`
    },
  },
  detailPaper: {
    [theme.breakpoints.down('md')]: {
      height: 80,
    },
    height: 55,
    background: alpha(theme.palette.primary.main, 0.05),
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
  },
  detailInnerPapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1
  },
  detailContainer: {
    padding: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: ({ meterReading }) => (meterReading) ? 'auto' : '100%',
    },
  },
  detailMainContainer: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none'
    },
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    height: '100%',
  },
  detailHeader: {
    fontSize: 16,
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
}));