import React, { useEffect, useState } from "react";
import {
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Close, FilterList, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { authHeader } from "../../_helpers";
import { Autocomplete } from '@mui/material';

const { webApiUrl } = window.appConfig;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
}));

export default function SelectOrganisationPopover(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    projectId,
    productServices,
    disabled,
    setSelectOrgAnchorEl,
    onSelect,
    setLoading,
  } = props;
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchResolversResult, setSearchResolversResult] = useState([]);
  const [selectOrgMethod, setSelectOrgMethod] = useState(0);
  const [selectOrgProductService, setSelectOrgProductService] = useState(null);
  const [showEditFilterAnchor, setShowEditFilterAnchor] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const GetResolversList = () => {
    if (!isMounted) {
      setLoading && setLoading(true);
      setIsMounted(true);
    }
    const url =
      webApiUrl +
      "api/Organisation/GetOrganisations/" +
      encodeURI(projectId) +
      "?" +
      "methodName=" +
      selectOrgMethod +
      (!!selectOrgProductService && selectOrgProductService.id.trim() !== ""
        ? "&productServiceId=" + selectOrgProductService.id
        : "") +
      (!!searchTerm ? "&searchText=" + encodeURI(searchTerm) : "");

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        setSearchResolversResult(findResponse);
        setLoading && setLoading(false);
      })
      .catch((err) => {
        setLoading && setLoading(false);
      });
  };

  useEffect(() => {
    GetResolversList();
  }, [selectOrgMethod, selectOrgProductService, searchTerm]);

  useEffect(() => {
    let search = "";
    const blockSpecialKeys = (e) => {
      if (search === "") {
        let REgex = new RegExp(/^[a-z0-9]$/i);
        e.preventDefault();

        if (!REgex.test(e.key)) {
          return false;
        }
        setSearchTerm((pre) => {
          search = search + e.key;
          return pre === null ? e.key : pre + e.key;
        });
      } else {
        e.preventDefault();
        setSearchTerm((pre) => (pre === null ? e.key : pre + e.key));
      }
    };
    window.addEventListener("keypress", blockSpecialKeys);
    return () => {
      window.removeEventListener("keypress", blockSpecialKeys);
    };
  }, []);

  return (
    <div style={{ padding: "16px" }}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row">
            <Grid item className={classes.grow}>
              {searchTerm == null ? (
                <Typography variant="h6">{selectOrgMethod >= 0 && t("general.resolver")}</Typography>
              ) : (
                <Input
                  onFocus={(event) => event.stopPropagation()}
                  autoFocus
                  type="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  endAdornment={
                    searchTerm !== "" && (
                      <InputAdornment position="end">
                        <IconButton color="inherit" size="small" onClick={() => setSearchTerm(null)}>
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              )}
            </Grid>
            <Grid item>
              <IconButton color="inherit" aria-label="search" component="span" style={{ marginTop: -8, marginBottom: -8 }} onClick={(e) => setSearchTerm(searchTerm == null ? "" : null)} size="large">
                <Search />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-describedby={"filter-list"} color="inherit" aria-label="filter-resolvers" component="span" style={{ marginTop: -8, marginBottom: -8 }} onClick={(e) => setShowEditFilterAnchor(!showEditFilterAnchor)} size="large">
                <FilterList />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="inherit" component="span" edge="end" style={{ marginTop: -8, marginBottom: -8 }} onClick={() => setSelectOrgAnchorEl(null)} size="large">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Collapse in={showEditFilterAnchor}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  disabled={disabled}
                  size="small"
                  //style={{ marginTop: -10, marginBottom: -10 }}
                >
                  <InputLabel id="change-method-select-label">{t("organization.search.method.label")}</InputLabel>
                  <Select labelId="change-method-select-label" id="change-method-select" value={selectOrgMethod} onChange={(e) => setSelectOrgMethod(e.target.value)} label={t("organization.search.method.label")}>
                    {[0, 1, 2].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {t("organization.search.method." + item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="product-service-method-select-addworkorder"
                  fullWidth
                  disabled={disabled}
                  options={productServices && productServices}
                  value={selectOrgProductService}
                  onChange={(e, v) => setSelectOrgProductService(v)}
                  getOptionLabel={(option) => option.code + " - " + option.description}
                  renderInput={(params) => <TextField {...params} label={t("repairRequest.productOrService")} variant="outlined" margin="dense" size="small"/>}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item>
          <MenuList style={{ margin: "0 -16px -12px" }}>
            {!!searchResolversResult &&
              searchResolversResult.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onSelect(item)
                    setSelectOrgAnchorEl(null)
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
          </MenuList>
        </Grid>
      </Grid>
    </div>
  )
}
