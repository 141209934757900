import React from 'react'
import { Box, Typography, Stack, InputAdornment, OutlinedInput, FormControl } from '@mui/material';
import ParticipantsList from './ParticipantsList';
import { useTranslation } from 'react-i18next';

const ChatCreationPreviewBody = ({ handleSelectParticipant, data, selectedParticipants, inputRef, isErrorInTextField, repairRequestNumber, groupName, setIsErrorInTextField, setGroupName }) => {
  const { t } = useTranslation();
  const tabs = [t("chat.buyerRenterOrPropertyManagers"), t("general.employees"), t("chat.add.subcontractors")]

  return (
    <>
      <Box p={1}>
        <FormControl error={isErrorInTextField} fullWidth >
          <OutlinedInput
            id="outlined-adornment-name-of-chat"
            startAdornment={<InputAdornment position="end">{repairRequestNumber}&nbsp;</InputAdornment>}
            aria-describedby="outlined-name-of-chat-helper-text"
            inputProps={{
              'aria-label': 'name-of-chat',
              maxLength: 40,
              ref: inputRef
            }}
            size='small'
            value={groupName}
            onChange={(e) => {
              setIsErrorInTextField(!e.target.value.trim())
              setGroupName(e.target.value)
            }}
            fullWidth
            placeholder={t('chat.add.enterNameOfChat')}
          />
        </FormControl>
      </Box>
      <Stack bgcolor={'background.paper'} px={1} flexGrow={1} overflow={"auto"}>
        <Typography px={1} py={0.5} variant='subtitle1'>{t("chat.add.selectedMembers", { count: selectedParticipants?.length || 0 })}</Typography>
        {tabs?.map((tab, i) => {
          const usersData = i === 0 ? data.buyerRenterOrPropertyManagers : i === 1 ? data.employees : data.allSubContractors
          const userList = usersData.filter((item) => selectedParticipants.includes(item.loginId))
          if (userList.length <= 0) {
            return <></>
          }
          return <>
            <Typography px={1} variant='caption' color={'text.secondary'}>{tab}</Typography>
            <ParticipantsList handleSelectParticipant={handleSelectParticipant} list={userList} />
          </>
        })}
      </Stack>
    </>
  )
}

export default ChatCreationPreviewBody