import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { buildings, dashboardCount, app, availableSurveyModules, meldingenFilter, currentVersionDate, workOrderCounts } from './common.reducer';
import { alert } from './alert.reducer';
import { dossier } from './dossiers.reducer';
import { surveys as surveying } from './survey.reducer';
import { syncs } from './sync.reducer';
import { routerReducer } from 'react-router-redux';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    buildings,
    dashboardCount,
    app,
    availableSurveyModules,
    dossier,
    routing: routerReducer,
    meldingenFilter,
    currentVersionDate,
    surveying,
    syncs,
    workOrderCounts
});

export default rootReducer;