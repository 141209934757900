export const getErrorTextContent = ({errors,t})=>{
  if(!errors){
    return ""
  }

  let fileContent = ""
  let sheetNames = Object.keys(errors);

  sheetNames?.forEach((sheetName)=>{
    const value  = errors[sheetName]
    const rowErrors = Object.keys(value).filter((key)=>key!== "sheetErrors")

    fileContent += `
  
  -----------------------------------------------------------------------------------------------------------
  ${t("settings.import.errorFile.fileName.title")} ${sheetName}
  -----------------------------------------------------------------------------------------------------------
    ${value?.sheetErrors && value?.sheetErrors?.length > 0 ? `
    ${t("settings.import.errorFile.fileName.subTitle")}
      ${value?.sheetErrors?.map((err)=>t(err)).join("\n      ")}
    ` : ""}
    ${rowErrors.length > 0 ?`
    ${t("settings.import.errorFile.rowError")}
    ---------`:""}
    ${rowErrors.map((rowNumber)=> `
      ${t("general.rowNumber")} ${rowNumber}:
        ${value[rowNumber]?.map((errorItem)=> !errorItem?.cellReference ? `Error: ${t(errorItem?.error)}` : "").join("")}
        ${value[rowNumber]?.map((errorItem)=> errorItem?.cellReference ? `
        ${t("general.rowNumber")} ${errorItem?.rowNo}, ${t("general.columnNumber")} ${errorItem?.columnNo}, ${t("general.cellReference")} ${errorItem?.cellReference}:
          ${t("general.error")}: ${t(errorItem?.error?.split(":")?.[0], {columnName:errorItem?.fieldName, maxLength:errorItem?.error?.split(":")?.[1],buildingType:errorItem?.error?.split(":")?.[1] })}` : "").join("")}
    `).join("")}
    `
  })
  return fileContent

// Sample response
//  `
  
// -----------------------------------------------------------------------------------------------------------
// Excel File name: first.excell
// -----------------------------------------------------------------------------------------------------------
//   Excell file Error: 
//     file name is wrong

//   Row error:
//   ---------
//     Row number 4:
//       Error: row should be unique
//         Row number 3, column number 4:
//           Error: filed must be from the drop down
//         Row number 3, column number 4:
//           Error: filed must be from the drop down
//     Row number 10:
//       Error: row should be unique
  
//   `
}
