import React, { useEffect, useState } from "react";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDataTableTextLabels, formatDate } from "../../_helpers";
import { surveyActions } from "../../_actions";
import { useHistory } from "react-router-dom";

export function WbProjects(props) {
  const classes = useStyles();
  const {
    applicationManagerItems,
    getApplicationLoading,
    confirmProjectLinkLoading,
    projectsForLinking = [],
  } = useSelector((state) => state.surveying);
  const { wbProjectsLinkingInfo = [] } = applicationManagerItems || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedConfirmProjectId, setSelectedConfirmProjectId] =
    useState(null);

  useEffect(() => {
    if (!confirmProjectLinkLoading && selectedConfirmProjectId)
      setSelectedConfirmProjectId(null);
  }, [confirmProjectLinkLoading]);

  useEffect(() => {
    dispatch(surveyActions.getApplicationManagerActionItem());
    dispatch(surveyActions.getProjectsForLinkingAction());
  }, [history.location.key]);

  const handleConfirmLinking = (projectId, wbProjectId) => {
    setSelectedConfirmProjectId(projectId);
    dispatch(surveyActions.confirmProject(projectId, wbProjectId));
  };

  const handleUpdateProject = ({ target: { value } }, wbProjectId) => {
    const selectedProject = projectsForLinking.find(
      (p) => p.projectId === value
    );
    dispatch(surveyActions.updateLinkProject(selectedProject, wbProjectId));
  };

  const columns = [
    {
      name: "wbProjectId",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "projectId",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "wbProjectName",
      label: t("wkb.wbProjectName"),
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "planNumber",
      label: t("wkb.wbProject.planNumber"),
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "projectName",
      label: t("settings.table.column.project.name"),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (projectName, { rowData }) => {
          const project =
            wbProjectsLinkingInfo.find((p) => p.wbProjectId === rowData[0]) ||
            {};
          if (projectName && project.linked) return projectName;
          return (
            <TextField
              id="standard-select-project"
              select
              fullWidth
              value={rowData[1]}
              onChange={(e) => handleUpdateProject(e, rowData[0])}
            >
              {projectsForLinking &&
                projectsForLinking.map((option, i) => (
                  <MenuItem key={option.projectId} value={option.projectId}>
                    {" "}
                    {option.projectName}{" "}
                  </MenuItem>
                ))}
            </TextField>
          );
        },
      },
    },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "stopped",
      label: t("general.status"),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (stopped, { rowData }) =>
          t(stopped ? "Stopped" : "Active"),
      },
    },
    {
      name: "archivedAt",
      label: t("general.archivedOn"),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (archivedAt, { rowData }) =>
          archivedAt ? formatDate(new Date(archivedAt)) : "",
      },
    },
    {
      name: "wbProjectManager",
      label: t("wkb.wbResponsible"),
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "confirm",
      label: t("general.action"),
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (_, { rowData }) => {
          const project =
            wbProjectsLinkingInfo.find((p) => p.wbProjectId === rowData[0]) ||
            {};
          return project.linked ? (
            t("general.linked")
          ) : (
            <Button
              disabled={
                (confirmProjectLinkLoading &&
                  selectedConfirmProjectId === rowData[1]) ||
                !project.projectId
              }
              onClick={() => handleConfirmLinking(rowData[1], rowData[0])}
              variant="outlined"
              color="primary"
              size="small"
            >
              {confirmProjectLinkLoading &&
              selectedConfirmProjectId === rowData[1] ? (
                <CircularProgress size={20} />
              ) : (
                t("general.confirm")
              )}
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    draggableColumns: { enabled: true },
    print: true,
    download: true,
    pagination: true,
    textLabels: getDataTableTextLabels(t, getApplicationLoading),
    customToolbarSelect: (selectedRows) => <></>,
  };

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <MUIDataTable
        title={t("settings.wbProjects.title")}
        data={getApplicationLoading ? [] : wbProjectsLinkingInfo}
        className={classes.dataTable}
        columns={columns}
        options={options}
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: theme.spacing(0),
    background: theme.palette.common.white,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(1, 0.5, 1, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
}));
