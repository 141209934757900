import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { withTheme } from "@mui/styles";
import { authHeader, getDataTableTextLabels } from "../../_helpers";
import { withTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { surveyActions } from "../../_actions";
import { themeBreakpointDown } from "../../_helpers/breakpointHelper";
// import CreateSurveyDialog from "./CreateSurveyDialog";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dialogHeaderTitle: {
    fontSize: "14px !important",
    color: theme.palette.common.white,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  closeIcon: {
    fill: theme.palette.common.white,
    fontSize: 24,
  },

  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  dataSyncDialog: {
    "& .MuiPaper-rounded": {
      borderRadius: 10,
      minWidth: "384px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "270px",
      },
    },
  },
  dataSyncDialogContainer: {
    padding: theme.spacing(2),
  },
  recordButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "25px 0 10px 0",
  },
  recordButton: {
    padding: theme.spacing(0.8, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.8, 5),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0.8, 3),
    },
  },
  checkboxTitle: {
    marginBottom: 0,
    "& .MuiTypography-root": {
      fontSize: 12,
      fontWeight: "bold",
      color: theme.palette.common.black,
    },
  },
});

class Page extends React.Component {
  state = {
    buildings: [],
    columnsVisibility: [],
    rowsPerPage: 50,
    recordModal: false,
    date: new Date(),
    carriedOutBy: "",
    surveyType: "",
    dateSet: false,
    isLoading: true,
    getMessageCountsLoading: true,
    messageCountPerBuilding: [],
  };

  handleClick = () => {
    this.setState({ recordModal: !this.state.recordModal });
  };

  handleChange = (field, value) => {
    this.setState((p) => ({ ...p, [field]: value }));
  };

  componentDidMount() {
    const { selected } = this.props;

    var rowsPerPage = parseInt(localStorage.getItem("Objects_RowsPerPage"));
    if (!!rowsPerPage && rowsPerPage > 0 && rowsPerPage !== this.state.rowsPerPage) {
      this.setRowsPerPage(rowsPerPage);
    }

    if (selected) {
      this.UpdateBuildings();
      this.getMessageCountPerBuilding();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.props;
    const { messageCountPerBuilding } = this.state;
    if (!prevProps.selected || prevProps.selected.projectId !== selected.projectId || this.props.location.key !== prevProps.location.key) {
      this.setState({ buildings: [], isLoading: true });
      this.UpdateBuildings();
      this.getMessageCountPerBuilding();
    }
    if (this.state.buildings && prevState.messageCountPerBuilding !== this.state.messageCountPerBuilding) {
      const buildings = this.state.buildings;

      buildings.forEach((building) => {
        building.messageCount = 0;
        if (messageCountPerBuilding) {
          var obj = messageCountPerBuilding.find((x) => x.buildingId.toUpperCase() === building.buildingId.toUpperCase());
          if (obj) building.messageCount = obj.count;
        }
      });

      this.setState({ buildings });
    }
  }

  getMessageCountPerBuilding = () => {
    const { selected } = this.props;
    if (selected) {
      this.setState({ getMessageCountsLoading: true });
      const url = `${webApiUrl}api/Home/GetCountOfNewMessagesPerBuilding/${selected.projectId}`;
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            messageCountPerBuilding: findResponse,
            getMessageCountsLoading: false,
          });
        })
        .catch((er) => {
          console.log(er);
          this.setState({ getMessageCountsLoading: false });
        });
    }
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });

    localStorage.setItem("Objects_RowsPerPage", rowsPerPage);
  };

  isColumnVisible = (columnName) => {
    const { theme } = this.props;
    const { columnsVisibility } = this.state;
    const isSmallScreen = themeBreakpointDown(window, theme, "sm");

    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  UpdateBuildings() {
    const { selected, buildings } = this.props;

    if (selected) {
      this.setState({
        buildings: buildings ? buildings.filter((x) => x.projectId === selected.projectId) : [],
        isLoading: false,
      });
    }
  }

  recordSaveHandler = () => {
    const { dispatch, selected } = this.props;
    const { date, carriedOutBy, surveyType, dateSet } = this.state;
    const obj = dateSet ? { date } : { date: new Date() };
    dispatch(
      surveyActions.addSurvey({
        ...obj,
        surveyType: +surveyType,
        buildingId: selected.buildingId,
        projectId: selected.projectId,
        status: 0,
        carriedOutBy,
        executorSignature: {
          name: "",
          content: "",
        },
        buyerRenter1Signature: {
          name: "",
          content: "",
        },
        buyerRenter2Signature: {
          name: "",
          content: "",
        },
      })
    );
    this.setState({ recordModal: false });
  };

  render() {
    const { t, classes, theme } = this.props;
    const { buildings, columnsVisibility, rowsPerPage } = this.state;
    const isExtraSmallScreen = themeBreakpointDown(window, theme, "xs");

    const columns = [
      {
        name: "buildingId",
        options: {
          display: "excluded",
          filter: false,
          print: false,
          download: false,
        },
      },
      {
        name: "buildingNoIntern",
        label: t("general.buildingNumber"),
        options: {
          filter: false,
          sort: true,
          customBodyRender: (v) => (
            <Button style={{ padding: 0 }} component={Link} to={"/object/" + v} color="primary">
              {v}
            </Button>
          ),
        },
      },
      {
        name: "propertyType",
        label: t("general.homeType"),
        options: {
          display: this.isColumnVisible("propertyType"),
          filter: true,
          filterType: "multiselect",
          sort: true,
        },
      },
      {
        name: "buildingType",
        label: t("general.buildingType"),
        options: {
          display: this.isColumnVisible("buildingType"),
          filter: true,
          sort: true,
        },
      },
      {
        name: "constructionFlow",
        label: t("general.constructionFlow.label"),
        options: {
          display: this.isColumnVisible("constructionFlow"),
          filter: true,
          sort: true,
        },
      },
      {
        name: "buyerRenterName",
        label: t("general.buyerRenterName"),
        options: {
          display: this.isColumnVisible("buyerRenterName"),
          filter: true,
          sort: true,
        },
      },
      {
        name: "address",
        label: t("layout.navbar.address"),
        options: {
          display: this.isColumnVisible("address"),
          filter: true,
          sort: true,
        },
      },
      {
        name: "status",
        label: t("general.status"),
        options: {
          display: this.isColumnVisible("status"),
          filter: true,
          sort: true,
        },
      },
      {
        name: "messageCount",
        label: t("chat.messages"),
        options: {
          display: this.isColumnVisible("messageCount"),
          filter: false,
          sort: true,
        },
      },
    ];

    const options = {
      sortOrder: { name: "buildingNoIntern", direction: "asc" },
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: rowsPerPage,
      download: !isExtraSmallScreen,
      print: !isExtraSmallScreen,
      // customToolbar: () => {
      //     const canCreate = availableSurveyModules && !!availableSurveyModules.length && availableSurveyModules.some(p => p.canCreate);
      //     return (
      //         canCreate && <>
      //             <Tooltip title={t('survey.create.label')}>
      //                 <IconButton disabled={!canCreate} style={{ order: -1 }} aria-label="more"
      //                     aria-controls="long-menu"
      //                     aria-haspopup="true" onClick={this.handleClick}>
      //                     <AddCircleOutlineOutlined />
      //                 </IconButton>
      //             </Tooltip>
      //         </>
      //     );
      // },
      //selectableRows: 'none',
      //onRowClick: rowData => {
      //    var requestId = rowData[0];
      //    if (requestId) {
      //        history.push('/objecten/dashboard/' + rowData[0]);
      //    }
      //},
      onViewColumnsChange: (changedColumn, action) => {
        if (action == "add") {
          var listToUpdate = columnsVisibility.slice();
          var column = listToUpdate.find((x) => x.name === changedColumn);
          if (column) {
            column.visible = true;
          } else {
            listToUpdate.push({ name: changedColumn, visible: true });
          }
          this.setState({ columnsVisibility: listToUpdate });
        } else if (action == "remove") {
          var listToUpdate = columnsVisibility.slice();
          var column = listToUpdate.find((x) => x.name === changedColumn);
          if (column) {
            column.visible = false;
          } else {
            listToUpdate.push({ name: changedColumn, visible: false });
          }
          this.setState({ columnsVisibility: listToUpdate });
        }
      },
      textLabels: getDataTableTextLabels(t, this.state.isLoading || this.state.getMessageCountsLoading),
      onChangeRowsPerPage: (rowsPerPage) => {
        this.setRowsPerPage(rowsPerPage);
      },
    };

    return (
      <Container maxWidth={false} className={classes.mainContainer}>
        {/* <CreateSurveyDialog
                    classes={classes}
                    handleChange={this.handleChange}
                    onClose={this.handleClick}
                    state={this.state}
                    onSave={this.recordSaveHandler}
                /> */}
        <MUIDataTable className={classes.dataTable} title={t("general.objects")} data={buildings} columns={columns} options={options} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, availableSurveyModules = [] } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  return {
    user,
    selected,
    buildings: all,
    availableSurveyModules,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as ObjectsPage };
