import React, { useState, useEffect, useCallback } from "react";
import { Button, TextField, FormControlLabel, Box, Typography, Grid, RadioGroup, Radio, FormControl, IconButton, FormLabel, Stack, Autocomplete, createFilterOptions, CircularProgress, FormHelperText } from "@mui/material";
import { InfoSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { getPersonMiddleNames } from "../../apis/profileApis";
import { addLoginRequest, validateLoginRequestEmail, validateLoginRequestObjectAddress } from "../../apis/loginRequestApi";
import { formatInitials } from "./utils";
import { debounce, history } from "../../_helpers";
import { deNormalizePhoneNumber, isValidEmail } from "../Profile/utils";
import "../../LoginPage/login.css";

const filter = createFilterOptions();

const GenderRadioButtonGroup = ({ value, onChange, label, name, error, helperText = "", t }) => {
    return (
        <FormControl margin="dense" error={error}>
            <FormLabel required>
                {label} :
            </FormLabel>
            <RadioGroup
                row
                value={value}
                onChange={onChange}
                name={name}
            >
                <FormControlLabel value={0} control={<Radio />} label={t("general.male")} />
                <FormControlLabel value={1} control={<Radio />} label={t("general.female")} />
            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

const LoginRequest = () => {
    const { webApiUrl } = window.appConfig;
    const { t } = useTranslation();
    const [middleNameOptions, setMiddleNameOptions] = useState([]);
    const [loading, setLoading] = useState({
        address: false,
        requestBtn: false
    });
    const [isSingleBuyer, setIsSingleBuyer] = useState(true)
    const formInitialValues = {
        person1: {
            lastName: '',
            middleName: '',
            initials: '',
            firstName: '',
            email: '',
            mobile: '',
            gender: ''
        },
        address: {
            houseNo: '',
            houseNoAddition: '',
            postcode: '',
            fullAddress: '',
        },
        message: '',
    }
    const [formData, setFormData] = useState(formInitialValues);
    const [errors, setErrors] = useState({})
    const { person1, person2, message, address } = formData

    useEffect(() => {
        (async () => {
            const response = await getPersonMiddleNames();
            setMiddleNameOptions(response?.data?.map(singleMiddleName => singleMiddleName.name))
        })()
    }, [])


    useEffect(() => {
        setFormData({
            ...formData,
            ...(isSingleBuyer ? {} : {
                person2: {
                    lastName: '',
                    middleName: '',
                    initials: '',
                    firstName: '',
                    email: '',
                    mobile: '',
                    gender: ''
                },
            }),
        })
    }, [isSingleBuyer])

    const validateBuyerForm = () => {
        let errors = {};
        if (!person1?.lastName?.trim()) {
            errors = {
                ...errors, person1: {
                    ...errors.person1,
                    lastName: t("general.field.error")
                }
            };
        }
        if (!person1?.firstName?.trim()) {
            errors = {
                ...errors, person1: {
                    ...errors.person1,
                    firstName: t("general.field.error")
                }
            };
        }
        if (!person1?.mobile?.trim() || deNormalizePhoneNumber(person1?.mobile).replace(/\D/g, '').length !== 10) {
            errors = {
                ...errors, person1: {
                    ...errors.person1,
                    mobile: t("general.field.error")
                }
            };
        }
        if (!person1?.email || !isValidEmail(person1?.email)) {
            errors = {
                ...errors, person1: {
                    ...errors.person1,
                    email: t("general.field.error")
                }
            };
        }
        if (!person1?.gender?.toString()) {
            errors = {
                ...errors, person1: {
                    ...errors.person1,
                    gender: t("general.field.error")
                }
            };
        }
        if (!isSingleBuyer) {
            if (!person2?.lastName?.trim()) {
                errors = {
                    ...errors, person2: {
                        ...errors.person2,
                        lastName: t("general.field.error")
                    }
                };
            }
            if (!person2?.firstName?.trim()) {
                errors = {
                    ...errors, person2: {
                        ...errors.person2,
                        firstName: t("general.field.error")
                    }
                };
            }
            if (person2?.email?.trim() && !isValidEmail(person1?.email)) {
                errors = {
                    ...errors, person2: {
                        ...errors.person2,
                        email: t("general.field.error")
                    }
                };
            }
            if (!person2?.mobile?.trim() || deNormalizePhoneNumber(person2?.mobile).replace(/\D/g, '').length !== 10) {
                errors = {
                    ...errors, person2: {
                        ...errors.person2,
                        mobile: t("general.field.error")
                    }
                };
            }
            if (!person2?.gender?.toString()) {
                errors = {
                    ...errors, person2: {
                        ...errors.person2,
                        gender: t("general.field.error")
                    }
                };
            }
        }
        if (!address?.postcode?.trim()) {
            errors = {
                ...errors, address: {
                    ...errors.address,
                    postcode: t("general.field.error")
                }
            };
        }
        if (!address?.houseNo?.trim()) {
            errors = {
                ...errors, address: {
                    ...errors.address,
                    houseNo: t("general.field.error")
                }
            };
        }
        if (!address?.fullAddress?.trim()) {
            errors = {
                ...errors, address: {
                    ...errors.fullAddress,
                    fullAddress: t("general.field.error")
                }
            };
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const fetchAddress = useCallback(
        debounce(async (postcode, houseNo, houseNoAddition) => {
            try {
                const response = await validateLoginRequestObjectAddress({
                    postcode,
                    houseNo,
                    ...(houseNoAddition ? { houseNoAddition } : {})
                });

                if (response.status === 200) {
                    setFormData((prev) => ({
                        ...prev,
                        address: {
                            ...prev.address,
                            fullAddress: response?.data?.addressText || '',
                        },
                    }));
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        address: {
                            ...prevErrors.address,
                            fullAddress: "",
                        }
                    }));

                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        address: {
                            ...prevErrors.address,
                            fullAddress: t("loginRequest.address.invalid"),
                        }
                    }));
                }
                setLoading({
                    ...loading,
                    address: false,
                });
            } catch (error) {
                console.error("Error fetching addresses:", error);
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    address: {
                        ...prevErrors.address,
                        fullAddress: t("loginRequest.address.invalid"),
                    }
                }));
                setFormData((prev) => ({
                    ...prev,
                    address: {
                        ...prev.address,
                        fullAddress: '',
                    },
                }));
                setLoading({
                    ...loading,
                    address: false,
                });
            }
        }, 1000),
        []
    );

    const handleChange = async (e) => {
        let { name, value } = e.target;
        const nameParts = name.split('.');
        const key = nameParts[0];
        const nestedKey = nameParts.slice(1).join('.');
        if (nestedKey === "firstName" || nestedKey === "lastName") {
            value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        } else if (nestedKey === "gender") {
            value = parseInt(value)
        }
        setFormData((prevState) => {
            const newState = {
                ...prevState,
                [key]: nestedKey ? { ...prevState[key], [nestedKey]: value } : value,
            };
            const { postcode, houseNo, houseNoAddition, } = newState.address;
            if ((name === "address.postcode" || name === "address.houseNo" || name === "address.houseNoAddition") && postcode && houseNo) {
                setLoading({
                    ...loading,
                    address: true
                });
                fetchAddress(postcode, houseNo, houseNoAddition);
            }
            return newState;
        });
        if (value) {
            clearError(name);
        }
    }
    const resetForm = () => {
        setErrors({})
        setFormData(formInitialValues);
        setIsSingleBuyer(true)
    };

    const validateField = (name, value) => {
        const requiredFieldNames = ["person1.firstName", "person1.lastName", "person1.email", "person1.mobile", "person2.firstName", "person2.lastName", "person2.mobile", "address.postcode", "address.houseNo", "address.fullAddress"]
        if (requiredFieldNames?.includes(name) && !value) {
            return t("general.field.error");
        }
        if (name === "person1.email") {
            return !isValidEmail(value) ? t("general.field.error") : ""
        }
        if ((name === "person1.mobile" || name === "person2.mobile") && deNormalizePhoneNumber(value).length !== 10) {
            return t("general.field.error");
        }
        return ""
    };

    const handleBlur = (e) => {
        let error = '';
        const { name, value } = e.target;
        const nameParts = name.split('.');
        const key = nameParts[0];
        const nestedKey = nameParts.slice(1).join('.');
        error = validateField(name, value);
        const isEmailField = name === "person1.email" || name === "person2.email";
        const isInvalidEmail = (name === "person1.email" && !isValidEmail(value)) || (name === "person2.email" && value && !isValidEmail(value));
        if (isEmailField) {
            if (isInvalidEmail) {
                error = t("general.field.error");
            } else if (value) {
                const checkEmailValidation = async () => {
                    try {
                        const response = await validateLoginRequestEmail({ email: value });
                        if (response.status === 200) {
                            setErrors((prevErrors) => ({
                                ...prevErrors,
                                [key]: { ...prevErrors[key], email: '' },
                            }));
                        } else {
                            setErrors((prevErrors) => ({
                                ...prevErrors,
                                [key]: {
                                    ...prevErrors[key],
                                    email: t("loginRequest.emailExists.error"),
                                }
                            }));
                        }
                    } catch (error) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            [key]: {
                                ...prevErrors[key],
                                email: t("loginRequest.emailExists.error"),
                            }
                        }));
                    }
                };
                checkEmailValidation();
            }
        }
        setErrors((prevErrors) => {
            if (nestedKey) {
                return {
                    ...prevErrors,
                    [key]: {
                        ...prevErrors[key],
                        [nestedKey]: error
                    }
                };
            } else {
                return {
                    ...prevErrors,
                    [name]: error
                };
            }
        });
        if (nestedKey === "initials") {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    [key]: {
                        ...prevState[key],
                        [nestedKey]: formatInitials(value).slice(0, 15)
                    }
                };
            });
        }
    };

    const handleSubmit = async () => {
        if (validateBuyerForm()) {
            try {
                setLoading({
                    ...loading,
                    requestBtn: true
                })
                const response = await addLoginRequest(formData);
                if (response.status === 200) {
                    history.push({
                        pathname: "/login-aanvraag/success",
                        state: { isFormSubmitted: true }
                    });
                } else {
                    setLoading({
                        ...loading,
                        requestBtn: false
                    })
                }
            } catch (error) {
                console.error("Error adding login request:", error);
                setLoading({
                    ...loading,
                    requestBtn: false
                })
            }
            resetForm();
        }
    };

    const handleBuyerChange = (event) => {
        resetForm()
        setIsSingleBuyer(event.target.value === "singleBuyer")
    };

    const isRequestBtnDisabled =
        [person1?.lastName, person1?.firstName, person1?.email, deNormalizePhoneNumber(person1?.mobile),
        address?.postcode, address?.houseNo, address?.fullAddress, person1?.gender]
            .concat(!isSingleBuyer ? [person2?.lastName, person2?.firstName, deNormalizePhoneNumber(person2?.mobile), person2?.gender] : [])
            .some(field => field?.length === 0);

    const clearError = (name) => {
        const nameParts = name.split('.');
        const key = nameParts[0];
        const nestedKey = nameParts.slice(1).join('.');
        setErrors((prevErrors) => {
            if (nestedKey) {
                return {
                    ...prevErrors,
                    [key]: {
                        ...prevErrors[key],
                        [nestedKey]: undefined
                    }
                };
            } else {
                const { [key]: _, ...rest } = prevErrors;
                return rest;
            }
        });
    };

    return (
        <Box
            height="100vh"
            overflow='auto'
            sx={{
                backgroundImage: "url(" + webApiUrl + "api/Config/WebBackground)",
            }}
            className="login-grid"
        >
            <Grid
                my={{ xs: 3, md: 6 }}
                mx="auto"
                container
                p={3}
                maxWidth={720}
                width='100%'
                justifyContent="center"
                bgcolor="common.white"
            >
                <Stack width={"100%"} justifyContent={'center'} alignItems={'center'} my={2} spacing={0.5}>
                    <Box
                        component="img"
                        src={webApiUrl + "api/Config/WebLogo"}
                        alt="JPDS"
                        maxWidth={200}
                        maxHeight={60}
                    />
                    <Typography variant="h6" align="center">
                        {t("loginRequest.requestALogin")}
                    </Typography>
                </Stack>
                <form noValidate>
                    <Stack>
                        <FormControl margin="dense">
                            <FormLabel id="radio-buttons-group-label"> {t("loginRequest.selectNoOfBuyers")}:</FormLabel>
                            <RadioGroup
                                aria-labelledby="controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={isSingleBuyer ? "singleBuyer" : "doubleBuyer"}
                                onChange={handleBuyerChange}
                            >
                                <FormControlLabel value="singleBuyer" control={<Radio />} label={t("loginRequest.buyerOnly")} />
                                <FormControlLabel value="doubleBuyer" control={<Radio />} label={t("loginRequest.buyerWithPartner")} />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    {!isSingleBuyer && (<Typography variant="body1" sx={{ mb: { xs: 1.5 } }} fontWeight={'500'}>{t('loginRequest.personOneData')} :</Typography>)}
                    <Grid container spacing={1.5} >
                        <Grid item xs={12} sm={6}   >
                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                label={t("general.lastName")}
                                type="text"
                                size='small'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{ maxLength: 40 }}
                                name="person1.lastName"
                                value={person1?.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                margin="dense"
                                error={errors?.person1?.lastName}
                                helperText={errors?.person1?.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <FormControl size="small" fullWidth>
                                <Autocomplete
                                    freeSolo
                                    id="person1-middleName"
                                    options={middleNameOptions}
                                    value={person1?.middleName}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue.includes(params.inputValue)) {
                                            filtered.push(params.inputValue);
                                        }
                                        return filtered;
                                    }}
                                    inputValue={person1?.middleName}
                                    onInputChange={(e, newInputValue) => {
                                        setFormData(prevData => ({
                                            ...prevData,
                                            person1: {
                                                ...prevData.person1,
                                                middleName: newInputValue
                                            }
                                        }));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("general.namePrefixes")}
                                            variant="outlined"
                                            size="small"
                                            margin="dense"
                                            inputProps={{
                                                ...params.inputProps,
                                                maxLength: 15
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}    >
                            <TextField
                                fullWidth
                                size='small'
                                label={t("general.initials")}
                                type="text"
                                name="person1.initials"
                                value={person1?.initials}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="dense"
                                autoComplete="off"
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                label={t("general.firstName")}
                                autoComplete="off"
                                size='small'
                                type="text"
                                name="person1.firstName"
                                value={person1?.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                margin="dense"
                                inputProps={{ maxLength: 40 }}
                                error={errors?.person1?.firstName}
                                helperText={errors?.person1?.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                autoComplete="off"
                                label={t("general.emailPrivate")}
                                type="text"
                                size='small'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{ maxLength: 200 }}
                                name="person1.email"
                                value={person1?.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                margin="dense"
                                error={errors?.person1?.email}
                                helperText={errors?.person1?.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}   >
                            <TextField
                                required
                                fullWidth
                                label={t("general.mobileNumber")}
                                size='small'
                                type="text"
                                autoComplete="off"
                                name="person1.mobile"
                                value={deNormalizePhoneNumber(person1?.mobile)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                margin="dense"
                                inputProps={{ maxLength: 10 }}
                                error={errors?.person1?.mobile}
                                helperText={errors?.person1?.mobile}
                            />
                        </Grid>
                    </Grid>
                    <GenderRadioButtonGroup
                        value={person1?.gender}
                        onChange={handleChange}
                        label={t("general.gender")}
                        name="person1.gender"
                        error={errors?.person1?.gender}
                        helperText={errors?.person1?.gender}
                        t={t}
                    />
                    {!isSingleBuyer && (
                        <>
                            <Typography variant="body1" fontWeight={'500'} sx={{ mb: { xs: 1.5 } }}>{t('loginRequest.personTwoData')} :</Typography>
                            <Grid container spacing={1}   >
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t("general.lastName")}
                                        type="text"
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{ maxLength: 40 }}
                                        name="person2.lastName"
                                        value={person2?.lastName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="dense"
                                        autoComplete="off"
                                        error={errors?.person2?.lastName}
                                        helperText={errors?.person2?.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl size="small" fullWidth>
                                        <Autocomplete
                                            freeSolo
                                            id="person2-middleName"
                                            options={middleNameOptions}
                                            value={person2?.middleName}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                                if (params.inputValue.includes(params.inputValue)) {
                                                    filtered.push(params.inputValue);
                                                }
                                                return filtered;
                                            }}
                                            inputValue={person2?.middleName}
                                            onInputChange={(e, newInputValue) => {
                                                setFormData(prevData => ({
                                                    ...prevData,
                                                    person2: {
                                                        ...prevData.person2,
                                                        middleName: newInputValue
                                                    }
                                                }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("general.namePrefixes")}
                                                    variant="outlined"
                                                    size="small"
                                                    margin="dense"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        maxLength: 15
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label={t("general.initials")}
                                        type="text"
                                        name="person2.initials"
                                        value={person2?.initials}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="dense"
                                        autoComplete="off"
                                        inputProps={{ maxLength: 15 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t("general.firstName")}
                                        size='small'
                                        type="text"
                                        name="person2.firstName"
                                        value={person2?.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        margin="dense"
                                        autoComplete="off"
                                        inputProps={{ maxLength: 40 }}
                                        error={errors?.person2?.firstName}
                                        helperText={errors?.person2?.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label={t("general.emailPrivate")}
                                        autoComplete="off"
                                        size='small'
                                        type="text"
                                        name="person2.email"
                                        value={person2?.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="dense"
                                        inputProps={{ maxLength: 200 }}
                                        error={errors?.person2?.email}
                                        helperText={errors?.person2?.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label={t("general.mobileNumber")}
                                        size='small'
                                        type="text"
                                        name="person2.mobile"
                                        value={deNormalizePhoneNumber(person2?.mobile)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{ maxLength: 10 }}
                                        margin="dense"
                                        autoComplete="off"
                                        error={errors?.person2?.mobile}
                                        helperText={errors?.person2?.mobile}
                                    />
                                </Grid>
                            </Grid>
                            {!isSingleBuyer && (
                                <GenderRadioButtonGroup
                                    value={person2?.gender}
                                    onChange={handleChange}
                                    label={t("general.gender")}
                                    name="person2.gender"
                                    error={errors?.person2?.gender}
                                    helperText={errors?.person2?.gender}
                                    t={t}
                                />
                            )}
                        </>
                    )}
                    <Stack direction="row" alignItems="center" sx={{ mb: { xs: 1.5, sm: 0 } }} >
                        <Box>
                            <IconButton edge="start">
                                <InfoSharp color="primary" />
                            </IconButton>
                        </Box>
                        <Typography color="primary" variant="body2">
                            {t("loginRequest.enterObjectAddress")}
                        </Typography>
                    </Stack>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                size='small'
                                label={t("general.address.postcode")}
                                type="text"
                                name="address.postcode"
                                value={address?.postcode}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                margin="dense"
                                autoComplete="off"
                                inputProps={{ maxLength: 8 }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors?.address?.postcode}
                                helperText={errors?.address?.postcode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                fullWidth
                                label={t("general.address.houseNumber")}
                                type="text"
                                name="address.houseNo"
                                value={address?.houseNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                                size="small"
                                margin="dense"
                                inputProps={{ maxLength: 12 }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                error={errors?.address?.houseNo}
                                helperText={errors?.address?.houseNo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                size='small'
                                label={t("general.address.numberAddition")}
                                type="text"
                                name="address.houseNoAddition"
                                value={address?.houseNoAddition}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="dense"
                                inputProps={{ maxLength: 10 }}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t("general.address")}
                                type="text"
                                name="address.fullAddress"
                                value={address?.fullAddress}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                margin="dense"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: loading.address ? <CircularProgress size={20} /> : null,
                                }}
                                disabled
                                error={errors?.address?.fullAddress}
                                helperText={errors?.address?.fullAddress}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={`${t("general.message")} (0/200)`}
                                size='small'
                                type="text"
                                name="message"
                                value={message}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ maxLength: 200 }}
                                multiline
                                maxRows={3}
                                rows={3}
                                margin="dense"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1.5} mt={1}>
                        <Grid item sm={6} xs={6}>
                            <Button fullWidth
                                onClick={() => history.push('/login')}
                                variant="outlined">
                                {t('general.cancel')}
                            </Button>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isRequestBtnDisabled}
                                loading={loading.requestBtn}
                            >
                                {t("general.request")}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Box>
    );
};

export { LoginRequest };
