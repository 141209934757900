import {useSelector} from "react-redux";
import {apps, userAccountTypeConstants} from "../_constants";

export const useRedirectionPath = (surveyModuleType) => {
    const app = useSelector(state => state.app);
    const { selected } = useSelector(state => state.buildings);
    const { availableSurveyModules } = useSelector(state => state.availableSurveyModules);
    const { user } = useSelector(state => state.authentication);
    const isBuyer = user && user.type === userAccountTypeConstants.buyer;

    if (selected) {
        let url = isBuyer ? '/' : app === 3 ? `${selected ? '/dashboard' : ''}` : `${selected ? '/werk/' + selected.projectNo : ''}`;
        const isExist = availableSurveyModules && availableSurveyModules.length && availableSurveyModules.find(p => p.moduleType === surveyModuleType);
        if (!isExist) {
            return url;
        }
    }
    return null;
}


export const getPathForDossierDetail = (view, buildingId, dossierId) => {
    return (buildingId || dossierId) ? `?${buildingId ? `buildingId=${buildingId}&` : ''}${dossierId ? `dossierId=${dossierId}` : ''}` : '';
}

export const getDossiersDetailPathByModule = ({projectId, buildingId, dossierId, isBuyer, projectNo, viewType, app}) => {
    if (app === apps.aftercare) {
        return `/nazorg/${viewType}/${projectId}${getPathForDossierDetail(viewType, buildingId, dossierId)}`;
    } else if (app === apps.constructionQuality) {
        return `/werk/${projectNo}/kwaliteitsborging/dossiers/${viewType}/${projectId}${getPathForDossierDetail(viewType, buildingId, dossierId)}`;
    } else if (isBuyer) {
        return `/${viewType}/${projectId}${getPathForDossierDetail(viewType, buildingId, dossierId)}`;
    } else {
        return `/werk/${projectNo}/${viewType}/${projectId}${getPathForDossierDetail(viewType, buildingId, dossierId)}`;
    }
}
