import React from "react";
import {
  Button,
  Hidden,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Description, Home, SwapHoriz } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { history } from "../../../_helpers";
import { getPathForDossierDetail } from "../../../_helpers/redirectHelper";
import { apps } from "../../../_constants";
import { useSelector } from "react-redux";

const availableLayouts = [
  { label: "layout.menuitem.dossiers", icon: Description, viewType: "dossier" },
  { label: "general.objects", icon: Home, viewType: "building" },
];

export default function ViewSwitcher() {
  const { t } = useTranslation();
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const { selected } = useSelector((state) => state.buildings);
  const [switcherAnchorEl, setSwitcherAnchorEl] = useState();
  const { viewType, selectedDataId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const buildingIdParam = params.get("buildingId");
  const dossierIdParam = params.get("dossierId");
  const isDashboard = window.location.pathname.includes("dashboard");
  const theme = useTheme();
  const matchesWidthDownSm = useMediaQuery(theme.breakpoints.down("md"));

  const handleChangeViewType = (view) => {
    let url;
    if (apps.aftercare === app) {
      url = `/nazorg/${view}`;
    } else {
      url = `/werk/${selected.projectNo}/${view}`;
    }
    if (selectedDataId) {
      if (isDashboard) url += `/${selectedDataId}/dashboard`;
      else
        url += `/${selectedDataId}${getPathForDossierDetail(
          view,
          buildingIdParam,
          dossierIdParam
        )}`;
    }
    history.push(url);
    setSwitcherAnchorEl(null);
  };

  const handleToggleLayoutView = (e) => {
    setSwitcherAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Hidden mdDown>
        <Tooltip title={t("dossier.general.switcher")}>
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={handleToggleLayoutView}
            size="large"
          >
            <SwapHoriz />
          </IconButton>
        </Tooltip>
      </Hidden>
      <Hidden mdUp>
        <Tooltip title={t("dossier.general.switcher")}>
          <Button
            variant="contained"
            color="primary"
            className={classes.speedDialIconButton}
            onClick={handleToggleLayoutView}
          >
            <SwapHoriz />
          </Button>
        </Tooltip>
      </Hidden>
      <Popover
        id={"dossier-menu-popover"}
        open={Boolean(switcherAnchorEl)}
        anchorEl={switcherAnchorEl}
        onClose={() => setSwitcherAnchorEl(null)}
        anchorOrigin={
          matchesWidthDownSm
            ? { vertical: "top", horizontal: "center" }
            : { vertical: "bottom", horizontal: "left" }
        }
        transformOrigin={
          matchesWidthDownSm
            ? { vertical: "bottom", horizontal: "center" }
            : { vertical: "top", horizontal: "left" }
        }
      >
        {availableLayouts.map((option) => (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeViewType(option.viewType)}
            selected={viewType === option.viewType}
          >
            <ListItemIcon>{option.icon && <option.icon />}</ListItemIcon>
            <Typography variant="inherit">{t(option.label)}</Typography>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
  speedDialIconButton: {
    margin: 4,
    borderRadius: 25,
    maxWidth: 50,
    maxHeight: 50,
    minWidth: 50,
    minHeight: 50,
    padding: theme.spacing(1),
  },
  menuItem: {
    "& .MuiListItemIcon-root": {
      minWidth: 34,
    },
  },
}));
