import React from 'react'
import { Box, List, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ParticipantsListItem from './ParticipantsListItem';

const ParticipantsList = ({ handleSelectParticipant, list, isCheckBoxVisible , selectedParticipants}) => {

  const { t } = useTranslation();
  
  return <Box>
    {list?.length <= 0 && <Typography p={2}>{t("general.noLoginFound")}</Typography>}
    {list?.map((userInfo) => {
      return <List disablePadding dense>
        <ParticipantsListItem handleSelectParticipant={handleSelectParticipant} userInfo={userInfo} isCheckBoxVisible={isCheckBoxVisible} selectedParticipants={selectedParticipants}/>
      </List>
    })}
  </Box>
}

export default ParticipantsList