import React, { useEffect, useState } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { AddCircleOutlineOutlined, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogContent,
    Grid,
    Button,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Box,
    FormLabel,
    ListItemText,
    Divider,
} from '@mui/material';
import Dropzone from '../../../../components/Dropzone'
import { formatFileSize, toBase64 } from '../../../../_helpers'
import Loader from '../../../../components/Loader'
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    '@global': {
        '.MuiButtonBase-root:focus': {
            outline: 0,
        },
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    dialogHeaderTitle: {
        fontSize: '14px !important',
        color: theme.palette.common.white
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
    },
    closeIcon: {
        fill: theme.palette.common.white,
        fontSize: 24,
    },
    dialog: {
        '& .MuiPaper-rounded': {
            borderRadius: 10,
            minWidth: '384px',
            [theme.breakpoints.down('sm')]: {
                minWidth: '270px',
            }
        }
    },
    dialogContainer: {
        padding: theme.spacing(2)
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '15px 0 10px 0',
        columnGap: 10

    },
}));



export default function ImportCufXml({ canOpen, onClose = () => { }, ...props }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const { webApiUrl } = window.appConfig;
    const [projectData, setProjectData] = useState([]);

    const [xmlFile, setXmlFile] = useState(null);
    const [calculationMethod, setCalculationMethod] = useState(10);
    const [monitoringCodeColumn, setMonitoringCodeColumn] = useState(10);
    const [project, setProject] = useState(null);
    const [category, setCategory] = useState(null);
    const [header, setHeader] = useState(null);
    const [optionNo, setOptionNo] = useState('');
    const [description, setDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (canOpen) {
            setOpen(canOpen);
        }
    }, [canOpen]);


    useEffect(() => {
        const url = webApiUrl + 'api/ImportExport/GetProjectsCategoriesHeaders';
        axios.get(url)
            .then(response => {
                setProjectData(response.data)
            })
            .catch(error => {
            });
    }, [])

    const closeHandler = (refresh) => {
        setOpen(p => !p);
        if (open) {
            setXmlFile(null);
            setProject(null);
            setCalculationMethod(10);
            setMonitoringCodeColumn(10);
            setOptionNo('');
            setDescription('');
            setCategory(null);
            setHeader(null);
        }
        onClose(refresh);
    };


    const handleFileChange = async (files) => {
        const file = files[0];
        if (file.name.toLowerCase().endsWith('.xml')) {
            setXmlFile({ content: (await toBase64(file)).split(',')[1], name: file.name, size: file.size });
            const indexOfFirstSpace = file.name.indexOf(' ');
            if (indexOfFirstSpace > 0) {
                let optNo = file.name.substr(0, indexOfFirstSpace);
                setOptionNo(optNo.length > 15 ? optNo.substr(0, 15) : optNo);
                let desc = file.name.substr(indexOfFirstSpace + 1);
                desc = desc.substr(0, desc.length - 4);
                if (desc.startsWith('- '))
                    desc = desc.substr(2);
                desc = desc.trim();
                setDescription(desc.length > 80 ? desc.substr(0, 80) : desc);
            }
        }
        else {
            alert(t('general.fileTypeNotSupported'));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        axios.post(
            webApiUrl + 'api/ImportExport/ImportCufXml',
            {
                file: xmlFile,
                calculationMethod,
                monitoringCodeColumn,
                projectId: project.projectId,
                optionNo,
                description,
                categoryId: category,
                headerId: header,
            })
            .then(x => {
                if (x.data.message?.trim() !== '') {
                    alert(x.data.message.replace('\r\n', '\n').replace('\r', '\n'));
                }
                closeHandler(true);
            })
            .catch(error => {
                alert(t('general.api.error'))
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const DialogTitle = (props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {open ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            closeHandler();
                        }}
                        size="large">
                        <Close className={classes.closeIcon} />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    };

    return <>
        <IconButton
            style={{ order: -1 }}
            onClick={() => setOpen(p => ({ ...p, modal: true }))}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            size="large">
            <AddCircleOutlineOutlined />
        </IconButton>
        {!!open ?
            <Dialog onClose={() => { closeHandler(); }} aria-labelledby="customized-dialog-title" className={classes.dialog} open={open}>
                <DialogTitle classes={classes} id="customized-dialog-title" onClose={() => { closeHandler(); }}>
                    <Typography className={classes.dialogHeaderTitle}>{t('settings.importCufXml.dialog.title')}</Typography>
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContainer}>
                    <Grid item xs={12}>
                        {
                            submitting && <Loader backdrop />
                        }
                        <form onSubmit={handleSubmit}>
                            <FormLabel >{t('settings.importCufXml.standardXmlFile')}</FormLabel>
                            <div style={{ maxWidth: 100 }}>
                                <Dropzone onFilesAdded={handleFileChange} multiple={false} accept=".xml" />
                            </div>
                            {
                                !!xmlFile && <ListItemText primary={xmlFile.name} secondary={formatFileSize(xmlFile.size)} />
                            }

                            <FormControl fullWidth margin="normal" size="small">
                                <InputLabel htmlFor="calculationMethod">{t('settings.importCufXml.calculationMethod.title')}</InputLabel>
                                <Select
                                    label="CalculationMethod"
                                    id="calculationMethod"
                                    value={calculationMethod}
                                    onChange={e => setCalculationMethod(e.target.value)}
                                >
                                    {
                                        [10, 20].map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {t("settings.importCufXml.calculationMethod." + item)}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="normal" size="small">
                                <InputLabel htmlFor="monitoringCodeColumn">{t('settings.importCufXml.monitoringCodeColumn.title')}</InputLabel>
                                <Select
                                    label="monitoringCodeColumn"
                                    id="monitoringCodeColumn"
                                    value={monitoringCodeColumn}
                                    onChange={e => setMonitoringCodeColumn(e.target.value)}
                                >
                                    {
                                        [10, 20, 30, 40, 50, 90].map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {t("settings.importCufXml.monitoringCodeColumn." + item)}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            <Box mt={3}>
                                <Typography variant="h6" gutterBottom>{t('settings.importCufXml.standardOption')}</Typography>
                                <Divider />

                                <FormControl fullWidth margin="normal" size="small">
                                    <InputLabel htmlFor="project">{t('general.project')}</InputLabel>
                                    <Select
                                        label="project"
                                        id="project"
                                        value={project?.projectId}
                                        required
                                        onChange={e => { setProject(projectData.find(x => x.projectId === e.target.value)); setCategory(null); setHeader(null); }}
                                    >
                                        {
                                            projectData.map((item, index) => (
                                                <MenuItem key={index} value={item.projectId}>
                                                    {item.projectNo + ' ' + item.projectName}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <TextField
                                    label={t('buyersGuide.standardOptions.optionNumber')}
                                    fullWidth
                                    required
                                    size="small"
                                    margin="normal"
                                    value={optionNo}
                                    inputProps={{ maxLength: 15 }}
                                    onChange={e => setOptionNo(e.target.value)}
                                />

                                <TextField
                                    label={t('general.description')}
                                    fullWidth
                                    required
                                    size="small"
                                    margin="normal"
                                    value={description}
                                    inputProps={{ maxLength: 80 }}
                                    onChange={e => setDescription(e.target.value)}
                                />

                                <FormControl fullWidth margin="normal" size="small">
                                    <InputLabel htmlFor="category">{t('general.category')}</InputLabel>
                                    <Select
                                        label="category"
                                        id="category"
                                        value={category}
                                        required
                                        onChange={e => setCategory(e.target.value)}
                                    >
                                        {
                                            project && project.categories && project.categories.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth margin="normal" size="small">
                                    <InputLabel htmlFor="header">{t('buyersGuide.standardOptions.header')}</InputLabel>
                                    <Select
                                        label="header"
                                        id="header"
                                        value={header}
                                        required
                                        onChange={e => setHeader(e.target.value)}
                                    >
                                        {
                                            project && project.headers && project.headers.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>

                            <div className={classes.buttonsContainer}>
                                <Button onClick={() => { closeHandler(); }} variant="contained" color="inherit" >
                                    {t('general.cancel')}
                                </Button>
                                <Button type="submit" variant="contained" disabled={!xmlFile || !!submitting}>
                                    {t('general.task.execute')}
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </DialogContent>
            </Dialog> : null}
    </>;
}
