export const surveyConstants = {
    GET_APPLICATION_MANAGER_LOADING: 'GET_APPLICATION_MANAGER_LOADING',
    GET_APPLICATION_MANAGER_ACTION_ITEMS_SUCCESS: 'GET_APPLICATION_MANAGER_ACTION_ITEMS_SUCCESS',
    GET_APPLICATION_MANAGER_ACTION_ITEMS_ERROR: 'GET_APPLICATION_MANAGER_ACTION_ITEMS_ERROR',
    MAP_BUILDING_TO_OBJECT_SUCCESS: 'MAP_BUILDING_TO_OBJECT_SUCCESS',
    MAP_BUILDING_TO_OBJECT_ERROR: 'MAP_BUILDING_TO_OBJECT_ERROR',
    GET_SETTLEMENT_SUCCESS: 'GET_SETTLEMENT_SUCCESS',
    GET_SETTLEMENT_ERROR: 'GET_SETTLEMENT_ERROR',
    GET_SURVEYS_MOMENTS_SUCCESS: 'GET_SURVEYS_MOMENTS_SUCCESS',
    GET_SURVEYS_MOMENTS_LOADING: 'GET_SURVEYS_MOMENTS_LOADING',
    GET_SURVEYS_MOMENTS_ERROR: 'GET_SURVEYS_MOMENTS_ERROR',
    INTERNAL_COMPLETE_SURVEYING_MOMENTS_SUCCESS: 'INTERNAL_COMPLETE_SURVEYING_MOMENTS_SUCCESS',
    INTERNAL_COMPLETE_SURVEYING_MOMENTS_ERROR: 'INTERNAL_COMPLETE_SURVEYING_MOMENTS_ERROR',
    GET_MOMENT_SUCCESS: 'GET_MOMENT_SUCCESS',
    GET_MOMENT_LOADING: 'GET_MOMENT_LOADING',
    GET_MOMENT_ERROR: 'GET_MOMENT_ERROR',
    GET_ASSIGNMENT_SUCCESS: 'GET_ASSIGNMENT_SUCCESS',
    GET_ASSIGNMENT_LOADING: 'GET_ASSIGNMENT_LOADING',
    GET_ASSIGNMENT_ERROR: 'GET_ASSIGNMENT_ERROR',
    REMOVE_ASSIGNMENT_ATTACHMENT_SUCCESS: 'REMOVE_ASSIGNMENT_ATTACHMENT_SUCCESS',
    REMOVE_ASSIGNMENT_ATTACHMENT_LOADING: 'REMOVE_ASSIGNMENT_ATTACHMENT_LOADING',
    REMOVE_ASSIGNMENT_ATTACHMENT_ERROR: 'REMOVE_ASSIGNMENT_ATTACHMENT_ERROR',
    GET_OBJECTS_LIST_SUCCESS: 'GET_OBJECTS_LIST_SUCCESS',
    GET_OBJECTS_LIST_ERROR: 'GET_OBJECTS_LIST_ERROR',
    GET_OBJECTS_LIST_LOADING: 'GET_OBJECTS_LIST_LOADING',
    ADD_ASSIGNMENT_ATTACHMENT_SUCCESS: 'ADD_ASSIGNMENT_ATTACHMENT_SUCCESS',
    ADD_ASSIGNMENT_ATTACHMENT_ERROR: 'ADD_ASSIGNMENT_ATTACHMENT_ERROR',
    ADD_ASSIGNMENT_ATTACHMENT_LOADING: 'ADD_ASSIGNMENT_ATTACHMENT_LOADING',
    INTERNAL_SURVEYING_MOMENTS_ERROR: 'INTERNAL_SURVEYING_MOMENTS_ERROR',
    INTERNAL_SURVEYING_MOMENTS_LOADING: 'INTERNAL_SURVEYING_MOMENTS_LOADING',
    INTERNAL_SURVEYING_MOMENTS_SUCCESS: 'INTERNAL_SURVEYING_MOMENTS_SUCCESS',
    COMPLETE_SURVEYING_MOMENTS_ERROR: 'COMPLETE_SURVEYING_MOMENTS_ERROR',
    COMPLETE_SURVEYING_MOMENTS_LOADING: 'COMPLETE_SURVEYING_MOMENTS_LOADING',
    COMPLETE_SURVEYING_MOMENTS_SUCCESS: 'COMPLETE_SURVEYING_MOMENTS_SUCCESS',
    UPDATE_RESPONSIBLE_PERSON_SUCCESS: 'UPDATE_RESPONSIBLE_PERSON_SUCCESS',
    UPDATE_RESPONSIBLE_PERSON_ASSIGNMENT_SUCCESS: 'UPDATE_RESPONSIBLE_PERSON_ASSIGNMENT_SUCCESS',
    UPDATE_RESPONSIBLE_PERSON_ERROR: 'UPDATE_RESPONSIBLE_PERSON_ERROR',
    UPDATE_RESPONSIBLE_PERSON_LOADING: 'UPDATE_RESPONSIBLE_PERSON_LOADING',
    UPDATE_PERSON_FOR_MOMENT_LOADING: 'UPDATE_PERSON_FOR_MOMENT_LOADING',
    UPDATE_PERSON_FOR_MOMENT_SUCCESS: 'UPDATE_PERSON_FOR_MOMENT_SUCCESS',
    UPDATE_PERSON_FOR_MOMENT_ERROR: 'UPDATE_PERSON_FOR_MOMENT_ERROR',
    UPDATE_ASSIGNMENT_INFO_LOADING: 'UPDATE_ASSIGNMENT_INFO_LOADING',
    UPDATE_ASSIGNMENT_INFO_SUCCESS: 'UPDATE_ASSIGNMENT_INFO_SUCCESS',
    UPDATE_ASSIGNMENT_INFO_ERROR: 'UPDATE_ASSIGNMENT_INFO_ERROR',
    GET_ALL_OBJECTS_BY_PROJECTID_LOADING: 'GET_ALL_OBJECTS_BY_PROJECTID_LOADING',
    GET_ALL_OBJECTS_BY_PROJECTID_SUCCESS: 'GET_ALL_OBJECTS_BY_PROJECTID_SUCCESS',
    GET_ALL_OBJECTS_BY_PROJECTID_ERROR: 'GET_ALL_OBJECTS_BY_PROJECTID_ERROR',
    SYNC_UPDATE_LOADING: 'SYNC_UPDATE_LOADING',
    SYNC_UPDATE_SUCCESS: 'SYNC_UPDATE_SUCCESS',
    SYNC_UPDATE_ERROR: 'SYNC_UPDATE_ERROR',
    GET_INSPECTIONS_SUCCESS: 'GET_INSPECTIONS_SUCCESS',
    GET_INSPECTIONS_LOADING: 'GET_INSPECTIONS_LOADING',
    GET_INSPECTIONS_ERROR: 'GET_INSPECTIONS_ERROR',
    GET_INSPECTION_DETAIL_SUCCESS: 'GET_INSPECTION_DETAIL_SUCCESS',
    GET_INSPECTION_DETAIL_LOADING: 'GET_INSPECTION_DETAIL_LOADING',
    GET_INSPECTION_DETAIL_ERROR: 'GET_INSPECTION_DETAIL_ERROR',
    GET_REPAIR_REQUEST_LOADING: 'GET_REPAIR_REQUEST_LOADING',
    GET_REPAIR_REQUEST_SUCCESS: 'GET_REPAIR_REQUEST_SUCCESSR',
    GET_REPAIR_REQUEST_ERROR: 'GET_REPAIR_REQUEST_ERROR',
    UPDATE_SURVEY_LOADING: 'UPDATE_SURVEY_LOADING',
    UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
    UPDATE_SURVEY_ERROR: 'UPDATE_SURVEY_ERROR',
    ADD_SURVEY_LOADING: 'ADD_SURVEY_LOADING',
    ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS',
    ADD_SURVEY_ERROR: 'ADD_SURVEY_ERROR',
    SENDING_EMAILS_LOADING: 'SENDING_EMAILS_LOADING',
    SENDING_EMAILS_SUCCESS: 'SENDING_EMAILS_SUCCESS',
    SENDING_EMAILS_ERROR: 'SENDING_EMAILS_ERROR',
    UPDATE_MOMENT_LAST_VIEW: 'UPDATE_MOMENT_LAST_VIEW',
    INTERNAL_COMPLETE_ASSIGNMENT_LOADING: 'INTERNAL_COMPLETE_ASSIGNMENT_LOADING',
    INTERNAL_COMPLETE_ASSIGNMENT_SUCCESS: 'INTERNAL_COMPLETE_ASSIGNMENT_SUCCESS',
    INTERNAL_COMPLETE_ASSIGNMENT_ERROR: 'INTERNAL_COMPLETE_ASSIGNMENT_ERROR',
    COMPLETE_ASSIGNMENT_LOADING: 'COMPLETE_ASSIGNMENT_LOADING',
    COMPLETE_ASSIGNMENT_SUCCESS: 'COMPLETE_ASSIGNMENT_SUCCESS',
    COMPLETE_ASSIGNMENT_ERROR: 'COMPLETE_ASSIGNMENT_ERROR',
    LINK_EMAIL_LOADING: "LINK_EMAIL_LOADING",
    LINK_EMAIL_SUCCESS: "LINK_EMAIL_SUCCESS",
    LINK_EMAIL_ERROR: "LINK_EMAIL_ERROR",
    CONFIRM_PROJECT_LOADING: "CONFIRM_PROJECT_LOADING",
    CONFIRM_PROJECT_SUCCESS: "CONFIRM_PROJECT_SUCCESS",
    CONFIRM_PROJECT_ERROR: "CONFIRM_PROJECT_ERROR",
    RESET_ASSIGNMENT_STATUS_LOADING: "RESET_ASSIGNMENT_STATUS_LOADING",
    RESET_ASSIGNMENT_STATUS_SUCCESS: "RESET_ASSIGNMENT_STATUS_SUCCESS",
    RESET_ASSIGNMENT_STATUS_ERROR: "RESET_ASSIGNMENT_STATUS_ERROR",
    UPDATE_LINK_PROJECT: "UPDATE_LINK_PROJECT",
    GET_PROJECTS_FOR_LINKING_LOADING: "GET_PROJECTS_FOR_LINKING_LOADING",
    GET_PROJECTS_FOR_LINKING_SUCCESS: "GET_PROJECTS_FOR_LINKING_SUCCESS",
    GET_PROJECTS_FOR_LINKING_ERROR: "GET_PROJECTS_FOR_LINKING_ERROR",
    GET_WB_PROJECT_INFO_LOADING: "GET_WB_PROJECT_INFO_LOADING",
    GET_WB_PROJECT_INFO_SUCCESS: "GET_WB_PROJECT_INFO_SUCCESS",
    GET_WB_PROJECT_INFO_ERROR: "GET_WB_PROJECT_INFO_ERROR",
    UPDATE_ASSIGNMENT_SORT_ORDER: "UPDATE_ASSIGNMENT_SORT_ORDER",
    GET_INSPECTION_PIN_IMAGES_SUCCESS: "GET_INSPECTION_PIN_IMAGES_SUCCESS",
    GET_PDF_REPORT_DETAILS_LOADING: "GET_PDF_REPORT_DETAILS_LOADING",
    GET_PDF_REPORT_DETAIL_SUCCESS: "GET_PDF_REPORT_DETAIL_SUCCESS",
    GET_PDF_REPORT_DETAIL_ERROR: "GET_PDF_REPORT_DETAIL_ERROR",
    CLEAR_PDF_REPORT_DETAILS_DATA: "CLEAR_PDF_REPORT_DETAILS_DATA",
    START_SIGNATURE_LOADING: "START_SIGNATURE_LOADING",
    START_SIGNATURE_SUCCESS: "START_SIGNATURE_SUCCESS",
    START_SIGNATURE_ERROR: "START_SIGNATURE_ERROR",
    STORE_IMAGES: "STORE_IMAGES",
    ADD_OR_UPDATE_LOCATION_NAME_SUCCESS: "ADD_OR_UPDATE_LOCATION_NAME_SUCCESS",
    ADD_OR_UPDATE_LOCATION_NAME_ERROR: "ADD_OR_UPDATE_LOCATION_NAME_ERROR",
    ADD_OR_UPDATE_LOCATION_NAME_LOADING: "ADD_OR_UPDATE_LOCATION_NAME_LOADING",
    GET_DRAWING_LOCATION_SUCCESS: " GET_DRAWING_LOCATION_NAME_SUCCESS",
    GET_DRAWING_LOCATION_ERROR: " GET_DRAWING_LOCATION_ERROR",
    GET_DRAWING_LOCATION_LOADING: " GET_DRAWING_LOCATION_LOADING",
    DELETE_DRAWING_LOCATION_SUCCESS: "DELETE_DRAWING_LOCATION_SUCCESS",
    DELETE_DRAWING_LOCATION_ERROR: "DELETE_DRAWING_LOCATION_ERROR",
    DELETE_DRAWING_LOCATION_LOADING: "DELETE_DRAWING_LOCATION_LOADING",
    ADD_UPDATE_REWORK_LOADING: "ADD_UPDATE_REWORK_LOADING",
    ADD_UPDATE_REWORK_SUCCESS: "ADD_UPDATE_REWORK_SUCCESS",
    ADD_UPDATE_REWORK_ERROR: "ADD_UPDATE_REWORK_ERROR",
    COMPLETE_RR_LOADING: "COMPLETE_RR_LOADING",
    COMPLETE_RR_SUCCESS: "COMPLETE_RR_SUCCESS",
    COMPLETE_RR_ERROR: "COMPLETE_RR_ERROR",
    DELETE_RR_LOADING: "DELETE_RR_LOADING",
    DELETE_RR_SUCCESS: "DELETE_RR_SUCCESS",
    DELETE_RR_ERROR: "DELETE_RR_ERROR",
    COMPLETE_MULTI_RR_SUCCESS: "COMPLETE_MULTI_RR_SUCCESS",
    UPDATE_ASSIGNMENTS_LOCK_IDS: "UPDATE_ASSIGNMENTS_LOCK_IDS",
    UPDATE_ASSIGNMENT_LOCK: "UPDATE_ASSIGNMENT_LOCK",
    GET_ASSIGNMENTS_LOADING: "GET_ASSIGNMENTS_LOADING",
    GET_ASSIGNMENTS_SUCCESS: "GET_ASSIGNMENTS_SUCCESS",
    GET_ASSIGNMENTS_ERROR: "GET_ASSIGNMENTS_ERROR",
    STORE_SURVEY_RECORDS_COUNTS: "STORE_SURVEY_RECORDS_COUNTS",
    UPDATE_EXECUTOR_FOR_SURVEY_LOADING: "UPDATE_EXECUTOR_FOR_SURVEY_LOADING",
    UPDATE_EXECUTOR_FOR_SURVEY_SUCCESS: "UPDATE_EXECUTOR_FOR_SURVEY_SUCCESS",
    UPDATE_EXECUTOR_FOR_SURVEY_ERROR: "UPDATE_EXECUTOR_FOR_SURVEY_ERROR",
};