import { Alert, AppBar, Box, Button, Grid, IconButton, Snackbar, Stack, Toolbar, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import clsx from "clsx";
import { ArrowBack,} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import MethodOfFinance from './MethodOfFinance';
import Proxy from './Proxy';
import { useSelector } from 'react-redux';
import General from "./General"
import Address from "./Address"
import { getFinanceForBuilding, updateFinance } from '../../../apis/contractApis';


const ContractDetails = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation()
  const { selected } = useSelector(state => state.buildings)
  const [contract, setContract] = useState()
  const [toast, setToast] = React.useState({open:false, message:null, type:""})

  const [financeCheckBoxs, setFinanceCheckBoxs] = useState({
    financeOwnFunds: false,
    financeMorgageBank: false,
    financeMorgagePrivate: false
  })

  const [proxyCheckBoxValue, setProxyCheckBoxValue] = useState(null);

  useEffect(() => {
    (async () => {
      const result = await getFinanceForBuilding(selected?.buildingId)
      const contractData = result.data
      setContract(contractData)
    })()
  }, [])

  useEffect(() => {
      setFinanceCheckBoxs({
          financeOwnFunds: contract?.financeOwnFunds,
          financeMorgageBank: contract?.financeMorgageBank,
          financeMorgagePrivate: contract?.financeMorgagePrivate,
      });
      setProxyCheckBoxValue(contract?.powerOfAttorney === true ? "Ja" : (contract?.powerOfAttorney === false ? "Nee" : null));
  }, [contract])


    const updateDetails = async (data) => {
        try {
            await updateFinance(data)

            const result = await getFinanceForBuilding(selected?.buildingId)
            const contractData = result.data
            setContract(contractData)
            setToast({ open: true, message: t("contract.save.success"), type: "success" })
        } catch (e) {
            console.log({ e })
            setToast({ open: true, message: t("error.header"), type: "error" })
        }
    }

  const handleSaveButtonClick = () => {
      console.log("called")
      updateDetails({
          id: contract?.id,
          financeOwnFunds: financeCheckBoxs?.financeOwnFunds,
          financeMorgageBank: financeCheckBoxs?.financeMorgageBank,
          financeMorgagePrivate: financeCheckBoxs?.financeMorgagePrivate,
          powerOfAttorney: financeCheckBoxs?.financeOwnFunds ? (proxyCheckBoxValue === "Ja" ? true : proxyCheckBoxValue === "Nee" ? false : null) : null
      });
  }

  
  const handleCloseSnackbar = ()=>{
    setToast({open:false, message:null, type:"success"})
  }

  console.log("currentr checkbox values", financeCheckBoxs, proxyCheckBoxValue)

  return (
    <>
      <AppBar position="sticky" color="inherit" className={classes.appBar} >
        <Toolbar variant="dense">
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
              <IconButton
                edge="start"
                aria-label="GoBack"
                color="inherit"
                // onClick={() => history.replace("/contract")}
                size="large"
              >
                <ArrowBack />
              </IconButton>
              <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                {t("contract.details.title")}
              </Typography>
            </Stack>
            <Box>
              <Button size='small' variant="contained" onClick={handleSaveButtonClick}>{t("general.save")}</Button>
            </Box>

          </Stack>
        </Toolbar>
      </AppBar>

      <Box mb={4}>
        <Box py={2}>
          <Grid container spacing={0}  >
            <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
              <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>

                <General />

                <Address contract={contract} />
              </Box>
            </Grid>


            <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
              <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
                <MethodOfFinance setFinanceCheckBoxs={setFinanceCheckBoxs} financeCheckBoxs={financeCheckBoxs} />
              </Box>
            </Grid>

            {
              financeCheckBoxs?.financeOwnFunds && <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
                <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
                  <Proxy proxyCheckBoxValue={proxyCheckBoxValue} setProxyCheckBoxValue={setProxyCheckBoxValue} />
                </Box>
              </Grid>
            }

            {/* <LotSize contractDetails={contractDetails} loadingKey={loadingKey} updateContractDetails={updateContractDetails} isDisabledEditing={isDisabledEditing}/> 
  
              <Address contractDetails={contractDetails}/> 
  
              
              <Template contractDetails={contractDetails} updateContractDetails={updateContractDetails} loadingKey={loadingKey} isDisabledEditing={isDisabledEditing}/> */}


          </Grid>

        </Box>
      </Box>

      <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={toast.type || "success"}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export { ContractDetails }

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 999,
    padding: "10px 0px",

  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
    height: "100%",
  },
  detailContainer: {
    borderRadius: "4px",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    fontSize: "1.4em",
  },
}));
