import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

function LotSize({contractDetails , loadingKey, updateContractDetails, isDisabledEditing}) {
  const [communalOwnershipShare, setCommunalOwnershipShare] = useState(contractDetails?.communalOwnershipShare)

  const {t} = useTranslation()



  const handleChange = (value) => {
    if(value.endsWith("/0")){
      return
    }
    const regex = /^\d+\/?\d*$/g
    if( value===""|| regex.test(value)){
      setCommunalOwnershipShare(value);
    }
  };

  return (
    <div><Typography mb={1.5} mt={2} variant='subtitle1'>{t("contract.details.lotSize.title")}</Typography>
          
    <Stack rowGap={2}>

     <NumericFormat
        label={t("contract.details.lotSize.plotSize")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.plotSize}
          variant="outlined"
          align="center"
        />


        {loadingKey === "communalownershipshare" ?    <Loader /> : <TextField
        fullWidth
        label={t("contract.details.lotSize.communalOwnershipShare")}
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 50 }}
        value={communalOwnershipShare?.toString() ||""}
        onChange={(e)=>handleChange(e.target.value)}
        onBlur={(e)=>{
            updateContractDetails("communalownershipshare" , e.target.value ,"communalOwnershipShare" )
        }}
        disabled={isDisabledEditing}
       
      />}


    </Stack> </div>
  )
}

export default LotSize