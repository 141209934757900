import React from "react";
import { Avatar, Checkbox, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { URLS } from "../../../apis/urls";
import clsx from "clsx";
import { formatDate, useInternetStatusDetector} from "../../../_helpers";
import { LocationOnOutlined, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../../components/CustomRowStyles";
import { MultiLabelColumn } from "../../../components/DataColumns";
import OfflineFileWrapper from "../../../components/OfflineFileWrapper";

export default function RepairRequestCustomRow(props) {
  const { onRowClick, canShowCheckBox, setRowsSelected, rowsSelected, columns, data, identifier, currentRow, dataIndex } = props;
  const classes = { ...useRowStyles(), ...useStyles() };
  const { t } = useTranslation();
  const isOnline = useInternetStatusDetector();

  const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
  const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};
  if (!selectedRow || !columns.length) return "";
  const completedWorkorders = selectedRow.resolvers ? selectedRow.resolvers.filter(a => a.status === 4).length : 0;
  let imageURL = "";
  let attachmentId;
  const attachments = selectedRow.attachments;
  if (selectedRow.sync === 0 && attachments && attachments.length) {
    imageURL = `${window.URL.createObjectURL(new Blob([attachments[0]]))}`;
  } else if (selectedRow.sync !== 0 && attachments && attachments.length) {
    attachmentId = attachments[0].attachmentId;
    imageURL = `${URLS.GET_ATTACHMENT_THUMBNAIL}${attachments[0].attachmentId}`;
  }

    const rowClickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onRowClick(selectedRow);
    }
  const tooltipTitle = selectedRow.sync !== 0 && !isOnline ? t("repairRequest.disabled.edit") : "";

  return (
    <React.Fragment >
      <Tooltip title={tooltipTitle}>
        <TableRow onClick={rowClickHandler} className={clsx(classes.customGridRow)}>
          <TableCell>
            <div className={classes.customSmallDeviceRowContainer}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                  <OfflineFileWrapper url={imageURL} id={attachmentId}>
                    {url => <Avatar
                      src={url}
                      className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')}>
                      {selectedRow.buildingNoExtern ? isNaN(selectedRow.buildingNoExtern) ? selectedRow.buildingNoExtern[0] : selectedRow.buildingNoExtern : ""}
                    </Avatar>}
                  </OfflineFileWrapper>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <div className={clsx(classes.detailContainer, classes.descriptionContainer)} style={{ maxWidth: "100%" }}>
                  <div>
                    <Typography className={clsx(classes.requestTitle, classes.grow)}>
                      {`${selectedRow.number} - ${selectedRow.desc}`}
                    </Typography>
                  </div>
                  <div>
                    <Typography noWrap style={{ display: "flex" }}>
                      {selectedRow.hasUpdate && (
                        <>
                          <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                            <PriorityHigh color="secondary" fontSize="small" size="small" />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      )}
                      {selectedRow.overdue ? (
                        <>
                          <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                            <Schedule fontSize="small" size="small" color="error" style={{ marginRight: 5 }} />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : selectedRow.is48HoursReminder ? (
                        <>
                          <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                            <Schedule fontSize="small" size="small" className={classes.warning} style={{ marginRight: 5 }} />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : ("")}
                      {canShowCheckBox && <Checkbox size="small" style={{ padding: 0 }} className={classes.customCheckbox} checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                        const list = Object.assign([], rowsSelected.list);
                        const isExist = list.findIndex(p => p === dataIndex);
                        const rowsData = Object.assign([], rowsSelected.data);
                        if (isExist >= 0) {
                          list.splice(isExist, 1)
                          rowsData.splice(isExist, 1)
                        } else {
                          rowsData.push({ [identifier]: selectedRow[identifier] })
                          list.push(dataIndex);
                        };

                        setRowsSelected({ ...rowsSelected, data: rowsData, list });
                      }} color='primary' onClick={(e) => e.stopPropagation()} />}
                    </Typography>
                  </div>
                </div>
                <div className={clsx(classes.detailContainer, classes.descriptionContainer)} style={{ maxWidth: "100%" }}>
                  <div>
                    <Typography className={classes.requestTypeLabel}>
                      {selectedRow.resolvers ? `${completedWorkorders}/${selectedRow.resolvers.length}` : "0/0"}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className={classes.requestStatusLabel}
                      style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                      {t(selectedRow.status)}
                    </Typography>
                  </div>
                </div>
                <div className={clsx(classes.detailContainer, classes.descriptionContainer)} style={{ maxWidth: "100%" }}>
                  <div className={classes.requestContainer}>
                    <div>
                      <Typography className={classes.requestBuildingLabel}>
                        <div className={classes.locationIconContainer}>
                          <LocationOnOutlined className={classes.locationIcon} />
                        </div>
                        <span>
                          {selectedRow.location}
                        </span>
                      </Typography>
                    </div>
                    <div className={classes.labelSpacing}>
                      <MultiLabelColumn data={selectedRow.textResolvers.map(name => ({ name }))} />
                    </div>
                  </div>
                  <div>
                    <Typography className={classes.requestBuildingLabel} >
                      {formatDate(new Date(selectedRow.date))}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </Tooltip>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  labelSpacing: {
    marginLeft: 5
  },
  requestContainer: {
    display: 'flex'
  },
  descriptionContainer: {
    flexDirection: "row"
  },
  offlineIconContainer: {
    position: "absolute",
    right: 0,
    top: 0
  },
  offlineIcon: {
    fontSize: 14,
    fill: theme.palette.common.white
  },
  avatarThumbnail: {
    background: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 6,
    boxShadow: "0px 0px 3px #eee"
  },
  imageBadgeContainer1: {
    bottom: 0,
    right: 0,
    borderRadius: 0,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    fontSize: 10,
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  overflowEllipse: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  checkboxColumn: {
    display: 'flex',
    padding: theme.spacing(.6)
  },
}));
