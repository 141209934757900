import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Divider, Button, IconButton, Popover, Grid, Badge, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { commonActions } from "../_actions";
import { House } from '@mui/icons-material';
import { apps, internalLayoutViewConstants, userAccountTypeConstants } from '../_constants';
import { authHeader, history } from '../_helpers';
import { useTranslation } from "react-i18next";

const { webApiUrl } = window.appConfig;

const StyledBadge = withStyles(theme => ({
    badge: {
        right: theme.spacing(-0.25),
        padding: theme.spacing(0, 0.5),
        minWidth: theme.spacing(2),
        height: theme.spacing(2),
    },
}))(Badge);

const SelectBuildingInternal = (props) => {
    const { t } = useTranslation();
    const app = useSelector(state => state.app);
    const { all, selected, buyerGuidesBuildings } = useSelector(state => state.buildings);
    const { user } = useSelector(state => state.authentication);
    const { messageCountPerProject } = useSelector(state => state.dashboardCount);
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);
    const [countOfNewMessagesPerBuilding, seCountOfNewMessagesPerBuilding] = useState([]);

    useEffect(() => {
        if (anchorEl) {
            GetCountOfNewMessagesPerBuilding();
        }
    }, [anchorEl]);

    useEffect(() => {
        if (!anchorEl && timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
    }, [timeoutId, anchorEl]);

    const handleChange = (buildingId) => {
        const isBuyer = user.type === userAccountTypeConstants.buyer;
        var selectedItem = all.filter(x => x.buildingId === buildingId)[0];
        dispatch(commonActions.selectBuilding(selectedItem));

        if (!isBuyer && props.layoutView !== internalLayoutViewConstants.Building && app === apps.huisinfo) {
            history.push('/object/' + selectedItem.buildingNoIntern)
        }
        handleClose();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getMessagesCount = buildingId => {
        if (countOfNewMessagesPerBuilding) {
            var obj = countOfNewMessagesPerBuilding.find(x => x.buildingId.toUpperCase() === buildingId.toUpperCase());
            if (obj)
                return obj.count;
        }
        return 0;
    }

    const GetCountOfNewMessagesPerBuilding = () => {
        if (app === apps.huisinfo) {
            const url = `${webApiUrl}api/Home/GetCountOfNewMessagesPerBuilding/${selected.projectId}`;
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(url, requestOptions)
                .then(Response => Response.json())
                .then(findResponse => {
                    seCountOfNewMessagesPerBuilding(findResponse);
                }).catch(er => {
                    console.log(er)
                }).finally(() => {
                    timer();
                });
        }
    }

    const timer = () => {
        const id = setTimeout(() => {
            GetCountOfNewMessagesPerBuilding();
        }, 10000);
        setTimeoutId(id);
    }

    let buildingsData = [];
    if (buyerGuidesBuildings && selected) {
        buildingsData = all.filter(x => x.projectId === selected.projectId).sort((a, b) => a.buildingNoExtern - b.buildingNoExtern);
    }

    const totalCount = selected && messageCountPerProject
        ?
        (messageCountPerProject.find(x => x.projectId.toUpperCase() === selected.projectId.toUpperCase()) || {}).count || 0
        :
        0;


    return buildingsData.length > 1 ? (
      <React.Fragment>
        <IconButton color="inherit" aria-describedby={anchorEl ? "building-popover" : undefined} onClick={handleClick} size="large">
          <Badge badgeContent={totalCount} color="secondary">
            <House />
          </Badge>
        </IconButton>
        <Popover
          id={anchorEl ? "building-popover" : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center" style={{ maxWidth: 300, margin: "8px", maxHeight: "calc(100vh - 80px)" }}>
              <Typography component="div" variant="subtitle2" color="textSecondary" style={{ fontWeight: "bold", width: "100%", padding: "0 16px", lineHeight: "24px" }}>
                {t("general.objects")}
              </Typography>
              {buildingsData.map((building, index) => (
                <Grid item key={index} style={app === apps.aftercare ? { width: "100%", justifyContent: "center", display: "flex", flexDirection: "column" } : {}}>
                  <Button key={index} onClick={() => handleChange(building.buildingId)} style={{ minWidth: "auto", color: "rgba(0, 0, 0, 0.87)" }}>
                    <StyledBadge color="secondary" badgeContent={getMessagesCount(building.buildingId)}>
                      {app === apps.aftercare ? building.address : building.buildingNoExtern}
                    </StyledBadge>
                  </Button>
                  {app === apps.aftercare && <Divider />}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Popover>
      </React.Fragment>
    ) : null
}

export default SelectBuildingInternal;