import { syncsConstants } from "../_constants";

let dailySyncTime = localStorage.getItem("dailySyncTime");
if (!dailySyncTime) {
    dailySyncTime = new Date();
    dailySyncTime.setHours(11);
    dailySyncTime.setMinutes(0);
    dailySyncTime.setSeconds(0);
} else {
    dailySyncTime = new Date(dailySyncTime);
}
const localLogs = JSON.parse(localStorage.getItem('logs'));
const logs = localLogs ? localLogs.filter(p => {
    return new Date().getDate() === new Date(p.createdAt).getDate()
}) : []
const localLogs1 = JSON.parse(localStorage.getItem('syncLogs'));
const syncLogs = localLogs1 ? localLogs1.filter(p => {
    return new Date().getDate() === new Date(p.createdAt).getDate()
}) : [];

const initialState = {
    dailySync: dailySyncTime,
    logs: logs,
    syncLogs: syncLogs,
    getUserObjects: false,
    hasUpdate: undefined,
    canOpenReleaseModal:false
}
export function syncs(state = initialState, action) {
    switch (action.type) {
        case syncsConstants.SYNC_UP_SUCCESS: {
            return {
                ...state,
                canOpenReleaseModal:action.canOpenReleaseModal
            }
        }
        case syncsConstants.RELEASE_LOCK_LOADING: {
            return {
                ...state,
                releaseLockLoading:true
            }
        }
        case syncsConstants.RELEASE_LOCK_SUCCESS: {
            return {
                ...state,
                releaseLockLoading:false
            }
        }
        case syncsConstants.UPDATE_LOG: {
            const logs = Object.assign([], state.logs);
            const logIndex = logs && logs.findIndex(log => log.id === action.log.id)
            if (logIndex >= 0) {
                logs[logIndex] = action.log;
            } else
                logs.push(action.log);
            localStorage.setItem('logs', JSON.stringify(logs));
            return {
                ...state,
                logs,
                hasUpdate: action.hasUpdate,
                getUserObjects: action.getUserObjects
            }
        }
        case syncsConstants.UPDATE_LOG_1: {
            const syncLogs = Object.assign([], state.syncLogs);
            const logIndex = syncLogs && syncLogs.findIndex(log => log.id === action.log.id)
            if (logIndex >= 0) {
                syncLogs[logIndex] = action.log;
            } else
                syncLogs.push(action.log);
            localStorage.setItem('syncLogs', JSON.stringify(syncLogs));
            return {
                ...state,
                syncLogs,
                hasUpdate: action.hasUpdate,
                getUserObjects: action.getUserObjects
            }
        }
        case syncsConstants.GET_USER_OBJECTS_LOADING: {
            return {
                ...state,
                loadingBuildings: true,
                error: null
            }
        }
        case syncsConstants.GET_USER_OBJECTS_SUCCESS: {
            return {
                ...state,
                loadingBuildings: false,
                error: null
            }
        }
        case syncsConstants.GET_USER_OBJECTS_ERROR: {
            return {
                ...state,
                loadingBuildings: false,
                error: action.error
            }
        }
        default:
            return state;
    }
}