import React, { useState, useEffect } from "react";
import { Chip, CircularProgress, Dialog, DialogTitle, DialogContent, Button, Tooltip, Typography, Grid, IconButton, FormControlLabel, Popover, alpha, Switch, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CalendarToday, Cancel, FilterList, ScheduleOutlined } from "@mui/icons-material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import SelectCustom from "../../../components/Select";
import { groupBy, isValidDate } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    disabledSwitch: {
        color: `${theme.palette.primary.light} !important`,
    },
    dialogContainer: {
        "& .MuiDialogTitle-root": {
            background: theme.palette.primary.main,
        },
    },
    dialogTitle: {
        color: theme.palette.common.white,
    },
    dialogCancelButton: {
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
    },
    dialogSubContainer: {
        background: theme.palette.grey[100],
        padding: theme.spacing(0, 3),
        height: 40,
        display: "flex",
        alignItems: "center",
    },
    dialogAllSwitch: {
        "& .MuiTypography-root": {
            color: theme.palette.common.black,
            paddingRight: 10,
        },
    },
    progressIcon: {
        color: theme.palette.common.white,
    },
    ctmPopoverIcon: {
        fill: theme.palette.grey[700],
        fontSize: 28,
        cursor: "pointer",
    },
    ctmPopoverLabel: {
        fontSize: 16,
        color: theme.palette.grey[700],
        fontWeight: "bold",
        paddingRight: theme.spacing(1.2),
    },
    ctmPopoverLabelRest: {
        fontSize: 16,
        color: alpha(theme.palette.primary.main, 0.8),
        fontWeight: "bold",
    },
    ctmPopover: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    ctmHeaderTex: {
        display: "flex",
    },
    ctmPopoverMainContainer: {
        "& .MuiPopover-paper": {
            width: "100%",
            boxShadow: theme.shadows[1],
            background: theme.palette.common.white,
            borderRadius: 6,
            padding: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                maxWidth: 400,
            },
        },
    },
    filterChip: {
        margin: theme.spacing(0, 0.25),
    },
}));

SelectObjectsAppointmentModal.defaultProps = {
    buildings: [],
};
export default function SelectObjectsAppointmentModal(props) {
    const { open, buildings, selectedObjects, onSave, onClose, isReadOnly } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectedObjectsDraft, setSelectedObjectsDraft] = useState(selectedObjects || []);
    const { updateLoading } = useSelector((state) => state.dossier);
    const [isUpdated, setIsUpdated] = useState(false);
    const [filterPopover, setFilterPopover] = useState(false);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [filters, setFilters] = useState({ propertyType: [], constructionFlow: [] });

    useEffect(() => {
        setSelectedObjectsDraft(selectedObjects);
    }, [selectedObjects]);

    useEffect(() => {
        if (updateLoading) setIsUpdated(true);
        if (!updateLoading && isUpdated) onClose();
    }, [updateLoading]);

    const openFilter = Boolean(filterPopover);

    const handleFilterChange = (filterName, data) => {
        let filterData = [];
        data.map((d) => {
            if (data.filter((x) => x.value === d.value).length === 1) filterData.push(d);
            return d;
        });
        setFilters({ ...filters, [filterName]: filterData });
    };

    const handleRemoveFilter = (key, item) => {
        if (filters[key]) {
            setFilters({ ...filters, [key]: filters[key].filter((x) => x.value !== item.value) });
        }
    };

    const selectFilterOptions = (key) => {
        const dataToGroup = buildings.filter((x) => !!x[key]);
        const groupedArray = groupBy(dataToGroup, key);
        const filteredArray = [];
        Object.keys(groupedArray).forEach((p) => filteredArray.push({ value: p, label: p }));
        return filteredArray;
    };

    const filterPopoverData = [
        { name: "propertyType", title: t("general.type"), option: selectFilterOptions("propertyType") },
        { name: "constructionFlow", title: t("general.constructionFlow.label"), option: selectFilterOptions("constructionFlow") },
    ];

    const clearFilters = () => {
        setFilters({ propertyType: [], constructionFlow: [] });
    };

    const handleCloseObjects = () => {
        onClose();
    };

    const handleSaveObjects = () => {
        onSave(selectedObjectsDraft);
    };

    const handleSetSelectedObjectsDraft = (objects) => {
        objects = objects.filter((x) => x.hasBuyerLogin || !!selectedObjects.find(y => y.buildingId === x.buildingId));
        setSelectedObjectsDraft(objects);
    };

    const selectAllObjects = () => {
        const objects = [...selectedObjectsDraft, ...buildings.filter(x => !selectedObjectsDraft.find(y => y.buildingId === x.buildingId))];
        handleSetSelectedObjectsDraft(objects);
    };

    const handleChangeSelectAllObjects = ({ target: { checked } }) => {
        if (checked === true) {
            selectAllObjects();
        } else {
            handleSetSelectedObjectsDraft(buildings.filter(x => !x.hasBuyerLogin && !!selectedObjects.find(y => y.buildingId === x.buildingId)));
        }
    };

    const filterPopoverHandler = (e) => {
        setFilterButtonRef(e.currentTarget);
        setFilterPopover(!filterPopover);
    };

    function renderSelectBuilding(building) {
        const selectedObject = selectedObjectsDraft.find((x) => x.buildingId === building.buildingId);
        const switchHandler = () => {
            if (!!selectedObject) {
                const objects = selectedObjectsDraft.filter((x) => x.buildingId !== building.buildingId).slice();
                handleSetSelectedObjectsDraft(objects);
            } else {
                let objects = selectedObjectsDraft.slice();
                objects.push(building);
                handleSetSelectedObjectsDraft(objects);
            }
        };

        const dateChangeHandler = (key, value) => {
            if (!!selectedObject) {
                let objects = selectedObjectsDraft.slice().map(x => {
                    if (x.buildingId === selectedObject.buildingId) {
                        x[key] = value;
                    }
                    return x;
                });
                handleSetSelectedObjectsDraft(objects)
            }
        };

        return (
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <FormControlLabel
                        sx={{ ml: 0, mb: -.5, mt: 1.5 }}
                        value={building.buildingId}
                        disabled={isReadOnly || !building.hasBuyerLogin}
                        control={<Switch disabled={isReadOnly} color="primary" checked={!!selectedObject} classes={{ disabled: !!selectedObject && classes.disabledSwitch }} onChange={() => switchHandler()} />}
                        label={building.buildingNoExtern}
                        labelPlacement="start"
                    />
                </Grid>
                {
                    !!selectedObject &&
                    <>
                        <Grid item>
                            <DatePicker
                                label={t("general.startDate")}
                                disableToolbar
                                size="small"
                                margin="dense"
                                sx={{ width: { xs: 96, sm: 142 } }}
                                minDate={new Date()}
                                variant="inline"
                                format="dd-MM-yyyy"
                                id="start-date-picker"
                                name="startDateTime"
                                required
                                value={selectedObject?.startDateTime}
                                className={classes.customDatePicker}
                                onChange={(date) => dateChangeHandler("startDateTime", date)}
                                autoOk
                                slotProps={{
                                    openPickerButton: { edge: "start", size: "large" },
                                    textField: {
                                        variant: "standard",
                                        size: "large",
                                    },
                                }}
                                slots={{
                                    openPickerButton: IconButton,
                                    openPickerIcon: CalendarToday,
                                }}
                                disabled={isReadOnly || !building.hasBuyerLogin || !selectedObject}
                            />
                        </Grid>
                        <Grid item>
                            <TimePicker
                                label={t("general.startTime")}
                                size="small"
                                margin="dense"
                                sx={{ width: { xs: 72, sm: 96 } }}
                                ampm={false}
                                minutesStep={15}
                                views={["hours", "minutes"]}
                                id="start-time-picker"
                                okLabel={t("general.ok")}
                                cancelLabel={t("general.cancel")}
                                name="startDateTime"
                                required
                                value={selectedObject?.startDateTime}
                                onChange={(time) => dateChangeHandler("startDateTime", time)}
                                slotProps={{
                                    openPickerButton: { edge: "start", size: "large" },
                                    textField: {
                                        variant: "standard",
                                        size: "large",
                                    },
                                }}
                                slots={{
                                    openPickerButton: IconButton,
                                    openPickerIcon: ScheduleOutlined,
                                }}
                                disabled={isReadOnly || !building.hasBuyerLogin || !selectedObject}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        );
    }

    return (
        open === true && (
            <Dialog open={open} onClose={updateLoading ? () => { } : handleCloseObjects} aria-labelledby="form-dialog-title" maxWidth="xs" scroll="paper" className={classes.dialogContainer}>
                <DialogTitle id="dialog-objects-title" className={classes.dialogTitle}>
                    <Grid container spacing={1}>
                        <Grid item className={classes.grow}>
                            <Box sx={{ mr: -2 }}>{t("general.objects.select")}</Box>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button disabled={updateLoading} variant="outlined" className={classes.dialogCancelButton} onClick={handleCloseObjects}>
                                        {t("general.cancel")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button disabled={updateLoading || isReadOnly || selectedObjectsDraft.filter(x => !isValidDate(x.startDateTime) || (x.startDateTime < new Date())).length > 0} variant="outlined" onClick={handleSaveObjects} className={classes.dialogCancelButton}>
                                        {updateLoading ? <CircularProgress size={20} className={classes.progressIcon} /> : t("general.save")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Grid container alignItems="center" className={classes.dialogSubContainer}>
                    <Grid item>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            disabled={isReadOnly}
                            className={classes.dialogAllSwitch}
                            value="all"
                            control={
                                <Switch
                                    color="primary"
                                    classes={{ disabled: selectedObjectsDraft && selectedObjectsDraft.length === buildings.filter((x) => x.hasBuyerLogin).length && classes.disabledSwitch }}
                                    disabled={isReadOnly}
                                    checked={selectedObjectsDraft && selectedObjectsDraft.length === buildings.filter((x) => x.hasBuyerLogin || !!selectedObjects.find(y => y.buildingId === x.buildingId)).length}
                                    onChange={handleChangeSelectAllObjects}
                                />
                            }
                            label={t("general.all")}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item className={classes.grow}></Grid>
                    {filterPopoverData.filter((x) => x.option && x.option.length).length && (
                        <Grid item>
                            <Tooltip title={t("general.filter")}>
                                <IconButton size="small" aria-label="Filter" onClick={filterPopoverHandler}>
                                    <FilterList />
                                </IconButton>
                            </Tooltip>
                            <Popover
                                id="toolbar-filter"
                                open={openFilter}
                                anchorEl={filterButtonRef}
                                onClose={filterPopoverHandler}
                                classes={{ root: classes.ctmPopoverMainContainer }}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                            >
                                <div className={classes.ctmPopover}>
                                    <div className={classes.ctmHeaderTex}>
                                        <Typography className={classes.ctmPopoverLabel}>{t("datatable.label.filter.title")}</Typography>
                                        <Typography onClick={() => clearFilters()} className={classes.ctmPopoverLabelRest}>
                                            {t("datatable.label.filter.reset")}
                                        </Typography>
                                    </div>
                                    <Cancel className={classes.ctmPopoverIcon} onClick={filterPopoverHandler} />
                                </div>
                                <hr />
                                <Grid container spacing={2}>
                                    {filterPopoverData.map((p, index) => {
                                        return (
                                            p.option.length && (
                                                <Grid item xs={12}>
                                                    <SelectCustom
                                                        label={p.title}
                                                        searchKey={p.name}
                                                        selectProps={{
                                                            multiple: true,
                                                            value: filters[p.name],
                                                            onChange: (e, newValue) => {
                                                                handleFilterChange(p.name, newValue);
                                                            },
                                                        }}
                                                        selectedList={filters[p.name]}
                                                        idKey={"value"}
                                                        labelKey={"label"}
                                                        options={p.option}
                                                    />
                                                </Grid>
                                            )
                                        );
                                    })}
                                </Grid>
                            </Popover>
                        </Grid>
                    )}
                </Grid>
                <DialogContent>
                    <Grid container spacing={1}>
                        {Object.keys(filters).map((key) => {
                            return filters[key].map((item) => <Chip className={classes.filterChip} key={key + item.value} label={item.value} onDelete={() => handleRemoveFilter(key, item)} />);
                        })}
                    </Grid>
                    <Grid container spacing={1}>
                        {buildings
                            .sort((a, b) => {
                                if (a.buildingNoExtern < b.buildingNoExtern) return -1;
                                if (a.buildingNoExtern > b.buildingNoExtern) return 1;
                                return 0;
                            })
                            .map((building, index) => {
                                var keys = Object.keys(filters);
                                var isFiltered = true;
                                for (var i = 0; i < keys.length; i++) {
                                    if (isFiltered && filters[keys[i]] && filters[keys[i]].length) {
                                        isFiltered = !!filters[keys[i]].find((x) => x.value === building[keys[i]]);
                                    }
                                }
                                return (
                                    isFiltered && (
                                        <Grid item xs="12">
                                            <React.Fragment key={index}>{renderSelectBuilding(building)}</React.Fragment>
                                        </Grid>
                                    )
                                );
                            })}
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    );
}
