import { groupBy } from "../../_helpers"

export const convertGetAvailableParticipantsForChatCreationResponse = (data , t) => {
  const output = {
    buyerRenterOrPropertyManagers: [],
    employees: [],
    involvedSubContractors: {},
    otherSubContractors: {},
    allSubContractors: []
  }

  const allInvolvePartData = []
  const allOtherData = []
  
  data.forEach((item) => {
    if (item.isBuyerRenter || item.isPropertyManager) {
      output.buyerRenterOrPropertyManagers.push({ loginId: item.loginId, title: item.name, subTitle: item.isBuyerRenter ? t("general.buyerRenter") : t("general.propertyManager"), organisationId: item.organisationId, organisationName: item.organisationName  ,personId:item.personId})
      return;
    }

    if (item.isManager || item.isEmployee) {
      output.employees.push({ loginId: item.loginId, title: item.name, subTitle: item.function, organisationId: item.organisationId, organisationName: item.organisationName,personId:item.personId })
      return;
    }

    if (item.isInvolvedParty) {
      allInvolvePartData.push({ loginId: item.loginId, title: item.name, subTitle: item.function, organisationId: item.organisationId, organisationName: item.organisationName,personId:item.personId })
      return;
    }
    allOtherData.push({ loginId: item.loginId, title: item.name, subTitle: item.function, organisationId: item.organisationId, organisationName: item.organisationName ,personId:item.personId})
    return;
  })

  output.involvedSubContractors = groupBy(allInvolvePartData, "organisationId")
  output.otherSubContractors = groupBy(allOtherData, "organisationId")
  output.allSubContractors = allInvolvePartData.concat(allOtherData)

  return output

}
