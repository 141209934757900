import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export default function ReleaseLockConfirmModal({
  open,
  onClose,
  moduleTitle,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckbox = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  return (
    <>
      {open && (
        <Dialog
          className={classes.backdrop}
          open={open}
          onClose={() => onClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div className={classes.dialogMainContainer}>
              <div className={classes.dialogIcon}>
                <Warning className={classes.dialogWarningIcon} />
              </div>
              <div>
                <Typography
                  id="alert-dialog-title"
                  className={classes.dialogContextText}
                >
                  <b>{t("sync.releaseDownloadWarning.title")}</b>
                </Typography>
                <Typography
                  id="alert-dialog-description-1"
                  className={classes.dialogContextText}
                >
                  {t(`sync.releaseDownloadWarning.message`, {
                    moduleType: _.lowerCase(moduleTitle),
                  })}
                </Typography>
                <Typography
                  id="alert-dialog-description-2"
                  className={classes.dialogContextText}
                  dangerouslySetInnerHTML={{
                    __html: t(`sync.releaseDownloadWarning.message2`, {
                      moduleType: _.lowerCase(moduleTitle),
                    }),
                  }}
                ></Typography>
              </div>
            </div>
            <div>
              <FormControlLabel
                className={classes.customLabel}
                control={
                  <Checkbox
                    checked={isCheckboxChecked}
                    color="primary"
                    onChange={handleCheckbox}
                    name="filter"
                  />
                }
                label={t("general.agree")}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!isCheckboxChecked}
              className={classes.dialogButtonPrimary}
              onClick={() => onClose(true)}
            >
              {t("general.ok")}
            </Button>
            <Button
              className={classes.dialogButtonSecondary}
              onClick={() => {
                setIsCheckboxChecked(false);
                onClose(false);
              }}
            >
              {t("general.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ReleaseLockConfirmModal.defaultProps = {
  open: false,
  onClose: () => {},
};

const useStyles = makeStyles((theme) => ({
  dialogButtonPrimary: {
    minWidth: 100,
    background: "#f44336",
    color: theme.palette.common.white,
    padding: theme.spacing(0.875, 1.25),
    "&:hover": {
      background: "#f44336",
    },
    "&:disabled": {
      background: theme.palette.grey[300],
      color: theme.palette.grey[500],
    },
  },
  dialogButtonSecondary: {
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  dialogIcon: {
    display: "flex",
    justifyContent: "center",
    background: "#f4433624",
    alignItems: "center",
    borderRadius: "50px",
    height: 50,
    width: 50,
    minHeight: 50,
    minWidth: 50,
    maxHeight: 50,
    maxWidth: 50,
  },
  dialogWarningIcon: {
    fill: "#f44336",
    fontSize: 22,
  },
  dialogMainContainer: {
    display: "flex",
    columnGap: 20,
  },
  customLabel: {
    marginBottom: "0px !important",
  },
  backdrop: {
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "left",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      padding: 0,

      "& .MuiButtonBase-root": {
        margin: "0px 17px 25px 17px",
      },
    },
  },
  dialogRoot: {
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiPaper-root.MuiDialog-paper": {
      borderRadius: 10,
      [theme.breakpoints.up("sm")]: {
        height: "80%",
      },
    },
  },
  dialogContextText: {
    marginBottom: "10px",
  },
}));
