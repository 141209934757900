import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

const Price = ({contractDetails}) => {

  const {t} = useTranslation()
  
  return (
    <div><Typography mb={1.5} variant='subtitle1'>{t("general.price")}</Typography>
    <Typography variant='caption'>{t("contract.details.price.totalContractCostTitle")}</Typography>
    <Stack columnGap={1.5} direction="row" my={1.5}>

        <NumericFormat
        label={t("contract.details.price.totalContractCostExclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.contractPriceExclTax}
          variant="outlined"
          align="center"
        />
        <NumericFormat
        label={t("contract.details.price.totalContractCostInclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.contractPriceInclTax}
          variant="outlined"
          align="center"
        />
    </Stack>
    <Typography variant='caption'>{t("contract.details.price.totalLandCostTitle")}</Typography>
    <Stack columnGap={1.5} direction="row" my={1.5} >
    
              <NumericFormat
        label={t("contract.details.price.totalLandCostExclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.landPriceExclTax}
          variant="outlined"
          align="center"
        />
    
      <NumericFormat
        label={t("contract.details.price.totalLandCostInclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.landPriceInclTax}
          variant="outlined"
          align="center"
        />
      
    </Stack>

    <Typography variant='caption'>{t("contract.details.price.totalCostTitle")}</Typography>
    <Stack columnGap={1.5} direction="row" my={1.5} >
   
      <NumericFormat 
        label={t("contract.details.price.totalCostExclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.totalExclTax}
          variant="outlined"
          align="center"
        />
    
      <NumericFormat
        label={t("contract.details.price.totalCostInclude")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.totalInclTax}
          variant="outlined"
          align="center"
        />
    </Stack>

    <Typography variant='caption'>{t("contract.details.price.dueValueAddedTax")}</Typography>
    <Stack columnGap={1.5} direction="row" mt={1.5}>
     
      <NumericFormat
        label={t("contract.details.price.dueValueAddedTax")}
        InputLabelProps={{
          shrink: true,
        }}
          size="small"
          disabled
          fullWidth
          customInput={TextField}
          fixedDecimalScale={true}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          value={contractDetails?.dueValueAddedTax}
          variant="outlined"
          align="center"
        />
    </Stack></div>
  )
}

export default Price