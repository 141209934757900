import { IconButton, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CloseOutlined, } from '@mui/icons-material';
import React, { useRef, useState } from 'react'
import moment from "moment";
import { AgendaAppointmentParticipantStatus } from "../../../_helpers";
import CustomEvent from "./CustomEvent";
import { useTranslation } from 'react-i18next';

export default function CustomEventPopup({ target, showMoreEvents }) {
  const classes = useStyles();
  const ref = useRef();
  const [modalState, setModalState] = useState(false);
  const { t } = useTranslation();

  const popoverModalHandler = (e) => {
    setModalState(!modalState);
  }
  const { events, date } = showMoreEvents || { events: [] };
  return <>
    <Popover
      open={modalState}
      anchorEl={ref.current}
      onClose={popoverModalHandler}
      classes={{ paper: classes.customPopover }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className={classes.popoverMainContainer}>
        <IconButton
          className={classes.showMoreCloseIcon}
          onClick={popoverModalHandler}
          size="large">
          <CloseOutlined />
        </IconButton>
        <div className={classes.showMoreHeaderContainer}>
          <Typography className={classes.showMoreHeaderDay}>{moment(date).format('dddd')}</Typography>
          <Typography className={classes.showMoreHeaderDate}>{moment(date).format('DD')}</Typography>
        </div>
        {events.map(e => {
          let className = classes.availableSlot;
          if (!e.published) className = classes.draftSlot
          else if (!e.participants) className = classes.availableSlot;
          else if (e.participants.some(p => p.status === AgendaAppointmentParticipantStatus.Confirmed)) className = classes.eventSuccess;
          else if (e.participants.some(p => p.status === AgendaAppointmentParticipantStatus.Cancelled)) className = classes.eventCancel;
          return <div className={className}>
            <CustomEvent view="week" event={e} />
          </div>
        })}
      </div>
    </Popover>
    <span className="ml-2" role="presentation" ref={ref} onClick={() => popoverModalHandler()}>{target} {t("general.more")}</span>
  </>;
}

const useStyles = makeStyles((theme) => ({
  eventSuccess: {
    background: theme.palette.green.light,
    border: `1px solid ${theme.palette.green.dark}`,
    padding: theme.spacing(0.5),
    borderRadius: 4,
    margin: theme.spacing(1, 0)
  },
  eventCancel: {
    backgroundColor: theme.palette.red.light,
    border: `1px solid ${theme.palette.red.dark}`,
    padding: theme.spacing(0.5),
    borderRadius: 4,
    margin: theme.spacing(1, 0)
  },
  availableSlot: {
    backgroundColor: theme.palette.indigo.light,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0.5),
    borderRadius: 4,
    margin: theme.spacing(1, 0)
  },
  draftSlot: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[900]}`,
    padding: theme.spacing(0.5),
    borderRadius: 4,
    margin: theme.spacing(1, 0)
  },
  showMoreEventTitle: {
    fontSize: 13,
    color: theme.palette.grey[900]
  },
  showMoreCloseIcon: {
    fill: theme.palette.grey[600],
    width: 18,
    float: 'right'
  },
  showMoreHeaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  showMoreHeaderDay: {
    fontSize: 13,
    textTransform: 'uppercase',
    color: theme.palette.grey[600]
  },
  showMoreHeaderDate: {
    fontSize: 22,
    color: theme.palette.grey[900]
  },
  customPopover: {
    width: 237,
    height: 'fit-content',
    borderRadius: 10
  },
  popoverMainContainer: {
    padding: theme.spacing(2)
  },
}))
