let lst = [
    {
        value: '😁',
        content: '😁',
        keys: []
    },
    {
        value: '😉',
        content: '😉',
        keys: []
    },
    {
        value: '😂',
        content: '😂',
        keys: []
    },
    {
        value: '😆',
        content: '😆',
        keys: []
    },
    {
        value: '🙃',
        content: '🙃',
        keys: []
    },
    {
        value: '🙂',
        content: '🙂',
        keys: []
    },
    {
        value: '😇',
        content: '😇',
        keys: []
    },
    {
        value: '🤔',
        content: '🤔',
        keys: []
    },
    {
        value: '😬',
        content: '😬',
        keys: []
    },
    {
        value: '❤️',
        content: '❤️',
        keys: []
    },
    {
        value: '😎',
        content: '😎',
        keys: []
    },
    {
        value: '😷',
        content: '😷',
        keys: []
    },
    {
        value: '😢',
        content: '😢',
        keys: []
    },
    {
        value: '😟',
        content: '😟',
        keys: []
    },
    {
        value: '🥺',
        content: '🥺',
        keys: []
    },
    {
        value: '🙈',
        content: '🙈',
        keys: []
    },
    {
        value: '🙊',
        content: '🙊',
        keys: []
    },
    {
        value: '🔑',
        content: '🔑',
        keys: []
    },
    {
        value: '🛁',
        content: '🛁',
        keys: []
    },
    {
        value: '🚪',
        content: '🚪',
        keys: []
    },
    {
        value: '🚽',
        content: '🚽',
        keys: []
    },
    {
        value: '🏠',
        content: '🏠',
        keys: []
    },
    {
        value: '🏗',
        content: '🏗',
        keys: []
    },
    {
        value: '👷',
        content: '👷',
        keys: []
    },
    {
        value: '🧱',
        content: '🧱',
        keys: []
    }
];
export const emojis = lst;