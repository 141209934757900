import React, { useEffect, useState } from 'react'
import { Alert, Box, Typography, Stack, Button } from '@mui/material'
import { grey } from '@mui/material/colors'
import { authHeader, formatDate, formatTime, nl2br } from '../../_helpers'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import i18n from '../../i18n'
import moment from 'moment'

const { webApiUrl } = window.appConfig;

const Activities = ({ generateWorkOrderPageLink = () => "#", repairRequestId, resolverId, hideWorkOrderLink = false }) => {
  const { t } = useTranslation();
  let prevDate = null;
  const [allActivities, setAllactivities] = useState([])

  useEffect(() => {
    let url = `${webApiUrl}api/RepairRequest/GetActivities/${encodeURI(repairRequestId)}`
    if (resolverId) {
      url = url + `?resolverId=${encodeURI(resolverId)}`
    }
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((jsonResponse) => {
        if (jsonResponse?.length > 0) {
          setAllactivities(jsonResponse)
        }
      })
      .catch((er) => {
        console.log({ er })
      });
  }, [repairRequestId, resolverId])
  return (
    <Box p={{ xs: 2.5, md: 3 }} borderRadius={1} width="100%" minHeight="350px" height="calc(35vh + 65px)" overflow="auto" sx={{
      backgroundImage: 'url(/content/images/background/brickwall-bg.png)',
    }}>
      {allActivities && allActivities.map((singleStatus) => {
        const { title, workOrderNo, severity, dateTime, mergeFields, resolverId } = singleStatus || {}
        const titleTranslationObject = {}
        for (let index = 0; index < mergeFields.length; index++) {
          const mergeField = mergeFields[index];
          const key = mergeField.id.toLowerCase()
          titleTranslationObject[key] = key === "datetime" ? moment(mergeField.name).locale(i18n.language.split("-")[0]).format('LLLL') : mergeField.name
        }

        const newDateTime = new Date(dateTime);
        const showDate = !prevDate || prevDate.toDateString() !== newDateTime.toDateString();
        prevDate = newDateTime;
        return (
          <Stack p={1.5} direction="column" justifyContent="center">
            {showDate && (
              <Stack direction="row" mb={2} justifyContent="center"   >
                <Typography px={1.25} py={0.25} fontSize="smaller" borderRadius={6.25} bgcolor={grey[300]} >{formatDate(newDateTime)}</Typography>
              </Stack>)}
            <Stack alignSelf="center" >
              <Alert
                severity={severity || "info"}>
                <Typography sx={{ wordBreak: "break-word" }} >
                  {
                    workOrderNo && resolverId && !hideWorkOrderLink && (
                      <>
                        <Button sx={{ p: 0, minWidth: "auto", transform: "none" }} component={Link} to={generateWorkOrderPageLink(resolverId)} color="primary">
                          {workOrderNo}
                        </Button>
                        &nbsp;-&nbsp;
                      </>
                    )
                  }
                  {nl2br(t(title, titleTranslationObject))}
                </Typography>
                <Stack direction="row-reverse" >
                  <Typography variant="caption">{formatTime(newDateTime)}</Typography>
                </Stack>
              </Alert>
            </Stack>
          </Stack>)
      })}
    </Box >
  )
}
export default Activities