import { getLocalStorageItem } from "../../_helpers";

export const isFilterValid = (filterModal) => {
  if (!filterModal) {
    return true
  }
  if (filterModal?.items?.length === 0) {
    return true
  }
  let isvalid = false

  for (let index = 0; index < filterModal?.items?.length; index++) {
    const element = filterModal?.items[index];
    if (["isEmpty", "isNotEmpty"].includes(element.operator)) {
      isvalid = true
      continue
    }
    const isValueString = !!element?.value && (typeof element.value) == 'string';
    isvalid = Boolean(element.field && element.operator && ![null, undefined].includes(element.value) && (!isValueString || element?.value?.trim()))
    if (!isvalid) {
      break;
    }
  }
  return isvalid
}

export const getBrowserInfo = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  return { iOS, iOSSafari, webkit };
}

const removeUnWantedFilterItem = (filterModalParams)=>{

  const filterModal = filterModalParams?  JSON.parse(JSON.stringify(filterModalParams)) :undefined
  if (!filterModal || filterModal?.items?.length === 0) {
    return filterModal
  }
  let items = []

  for (let index = 0; index < filterModal?.items?.length; index++) {
    const element = filterModal?.items[index];
    if (["isEmpty", "isNotEmpty"].includes(element.operator)) { 
      items.push(element)
      continue
    }
    const isValueString = !!element?.value && (typeof element.value) == 'string';
    const isvalid = Boolean(element.field && element.operator && ![null, undefined].includes(element.value) && (!isValueString || element?.value?.trim()))
    if (isvalid) {
      items.push(element)
    }
  }

  filterModal.items = items
return filterModal
}

export function isFilterModalSame  (oldFilterModal, newFilterModal){
  return JSON.stringify(removeUnWantedFilterItem(oldFilterModal)) == JSON.stringify(removeUnWantedFilterItem(newFilterModal))
}


export const getOperator = (type) => {
  switch (type) {
    case "string":
      return "equals"
    case "singleSelect":
      return "is"
    case "number":
      return "="
    case "date":
      return "is"
    default:
      break;
  }
}

export const applyClientSideCellSort = ({ apiRef, sortModel }) => {
  const { field, sort } = sortModel;
  const newSortModel = [{ field, sort }]
  apiRef.current.setSortModel(newSortModel);
}

export const applyClientSideCellFilter = ({apiRef, data, filterKey})=>{
  const filterModel = getLocalStorageItem(filterKey, undefined)
  if (Array.isArray(data) && data.length > 0) {
    const { fieldId, value, operator, type = "string" } = data[0];
    const newFilterModel = {
      items: [
        ...(filterModel?.items || []), 
        {
          field: fieldId,
          operator: operator || getOperator(type),
          value: value
        },
      ],
    };
    apiRef.current.setFilterModel(newFilterModel);
  } else {
    apiRef.current.setFilterModel({ items: [] });
  }
}