import { Alert, Box, Container, Divider, Snackbar, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {useEffect,useMemo,useState} from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProfileHeader from './component/ProfileHeader';
import ProfileBody from './component/ProfileBody';
import OrganizationProfileBody from './component/OrganizationProfileBody';
import { useParams } from 'react-router-dom';
import { getOrganisationProfile, getUserProfile, updateOrganisationProfile, updateUserProfile } from '../../apis/profileApis';
import Loader from '../../components/Loader';
import { deNormalizePhoneNumber, isValidEmail, isValidWebsite } from './utils';

const { webApiUrl } = window.appConfig;

function Profile() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.authentication)

  const { profileType } = useParams();
  const { t } = useTranslation();

  const [profile, setProfile] = useState({ loading: false, data: undefined, initialValue:undefined })
  const [errors, setErrors] = useState({})

  const [toast, setToast] = React.useState({open:false, message:null, type:""})

  const updateProfileData = (obj) => {
    setProfile({ ...profile, data: { ...profile.data, ...obj } })
  }
  const [organisationDetails, setOrganisationDetails] = useState({ loading: false, data: undefined, initialValue:undefined })

  const updateOrganisationData = (obj) => {
    setOrganisationDetails({ ...organisationDetails, data: { ...organisationDetails.data, ...obj } })
  }
  const clearError = (key) => {
    const allErrors = { ...errors }

    delete allErrors[key];
    setErrors({ ...allErrors })
  }


  const validateUserProfile = () => {
    let errors = {}
    if (!profile.data?.lastName) {
      errors = { ...errors, lastName: t("general.field.error") }
    }
    if (!profile.data?.initials) {
      errors = { ...errors, initials: t("general.field.error") }
    }
    if (profile.data?.telephone && deNormalizePhoneNumber(profile.data?.telephone).length !== 10) {
      errors = { ...errors, telephone: t("general.field.error") }
    }
    if (profile.data?.mobile &&  deNormalizePhoneNumber(profile.data?.mobile).length !== 10) {
      errors = { ...errors, mobile: t("general.field.error") }
    }
    setErrors({ ...errors })
    return Object.keys(errors).length <= 0
  }

  const validateOrganisationDetails = () => {
    let errors = {}
    if (!organisationDetails.data?.searchName) {
      errors = { ...errors, searchName: t("general.field.error") }
    }
    if (!organisationDetails.data?.name) {
      errors = { ...errors, name: t("general.field.error") }
    }
    if (organisationDetails.data?.telephone &&  deNormalizePhoneNumber(organisationDetails.data?.telephone).length !== 10) {
      errors = { ...errors, telephone: t("general.field.error") }
    }
    if (organisationDetails.data?.email && !isValidEmail(organisationDetails.data?.email)) {
      errors = { ...errors, email: t("general.field.error") }
    }
    if(organisationDetails.data?.website && !isValidWebsite(organisationDetails.data?.website)){
      errors = { ...errors, website: t("general.field.error") }
    }
    setErrors({ ...errors })
    return Object.keys(errors).length <= 0
  }
  
  const handleSave = async () => {
    console.log({ profile: profile.data })
    try {
      if (profileType === "user") {
        const isValid = validateUserProfile()
        if (isValid) {
          setProfile({ ...profile, loading: true })
         
          const res = await updateUserProfile(profile.data)
          setToast({ open: true, message: t("user.profile.update.success"), type: "success" })
         
          setProfile({loading:false, data:res?.data?.result , initialValue: res?.data?.result})
        }

      } else if (profileType === "organisation") {
        const isValid = validateOrganisationDetails()
        if (isValid) {
          setOrganisationDetails({ ...organisationDetails, loading: true })
          const res = await updateOrganisationProfile(organisationDetails.data)
          setToast({ open: true, message: t("organisation.profile.update.success"), type: "success" })
          setOrganisationDetails({ loading: false, data: res?.data?.result ,initialValue: res?.data?.result})
        }

      }

    } catch (e) {
      setProfile({ ...profile, data:profile.initialValue, loading: false })
      setOrganisationDetails({ ...organisationDetails, data:organisationDetails.initialValue,loading: false })
      setToast({ open: true, message: t("error.header"), type: "error" })
      console.log(e)
    }
  }


  const resetData = ()=>{
    setProfile({...profile, data: profile.initialValue})
    setOrganisationDetails({...organisationDetails, data: organisationDetails.initialValue})
  }

  useEffect(() => {
    (async () => {
      if (profileType === "user") {
        setProfile({ loading: true, data: undefined, initialValue:undefined })
        const res = await getUserProfile()
      
        setProfile({ loading: false, data: res.data, initialValue:res.data })
      } else if (profileType === "organisation") {
        setOrganisationDetails({ loading: true, data: undefined , initialValue:undefined })
        const res = await getOrganisationProfile()
        setOrganisationDetails({ loading: false, data: res.data,initialValue:res.data })
      }

    })()
  }, [profileType])

  const headerData = useMemo(() => {
    if (profileType === "user") {
      return {
        name: profile?.data?.name,
        email: profile?.data?.loginEmail,
        imgUrl: webApiUrl + "api/home/GetPersonPhoto/" + user.personId
      }
    } else if (profileType === "organisation") {
      return {
        name: organisationDetails?.data?.name,
        email: organisationDetails?.data?.email,
        imgUrl:  webApiUrl +  "api/organisation/GetOrganisationLogo/" + user?.organisationId
      }
    }
  }, [profileType, profile?.data, organisationDetails?.data ,user.personId, user?.organisationId])

  useEffect(() => {
  setErrors({})
  }, [profileType])
  

  if (profile.loading || organisationDetails.loading) {
    return <Loader />
  }


  const handleCloseSnackbar = ()=>{
    setToast({open:false, message:null, type:"success"})
  }


  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <Stack mb={4} >
        <ProfileHeader data={headerData} handleSave={handleSave} isOrganization={profileType === "organisation"} resetData={resetData}/>
        <Stack alignItems="center">
          <Divider sx={{ mt:{xs:0.5,md:2}, width: { xs: "100%", md: "70%" } }} />
        </Stack>

        <Box px={{ xs: 2, md: 0 }} mx="auto" width={{ xs: '100%', md: "40%" }}>
          {
            profileType === "organisation" && <OrganizationProfileBody organisationData={organisationDetails.data} updateOrganisationData={updateOrganisationData} errors={errors} clearError={clearError} />
          }
          {
            profileType === "user" && <ProfileBody profileData={profile.data} updateProfileData={updateProfileData} errors={errors} clearError={clearError} />
          }
        </Box>
      </Stack>


      <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={toast.type || "success"}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Profile

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
        width: "100%",
        overflow: "auto",
        padding: 0,
     
    },
}));