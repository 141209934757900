import {
    Collapse,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { CloudDownload, ExpandLess, ExpandMore, FormatSize, Image, MoreVert, Print, Settings } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {fontSizeConstant} from "../_constants/grid.constants";

export default function DataGridSetting({ muiTableRef, hideImageSizePicker = false, canListType = false, imageSize = 20, fontSize = 12, onChangeImageSize = () => 0, onChangeFontSize = () => 0, onChangeGridViewType, ...props }) {
    const muiTheme = useTheme();
    const matchTouchDevices = useMediaQuery(muiTheme.breakpoints.down('md'));
    const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"))

    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();
    const [canExpandSizeContainer, setCanExpandSizeContainer] = useState(false);
    const [canExpandFontSizeContainer, setCanExpandFontSizeContainer] = useState(false);
    const [canExpandGridStyleContainer, setCanExpandGridStyleContainer] = useState(false);
    
    const handleClick = () => {
        setCanExpandSizeContainer(!canExpandSizeContainer);
    };
    const handleFontClick = () => {
        setCanExpandFontSizeContainer(!canExpandFontSizeContainer);
    };

    const gridStyleToggler = () => {
        setCanExpandGridStyleContainer(!canExpandGridStyleContainer);
    };

    useEffect(() => {
        [{ dataId: "data-id-custom-csv-download-for-datagrid", id: "custom-csv-download-for-datagrid" }, { dataId: "data-id-custom-print-for-datagrid", id: "custom-print-for-datagrid" }].map(p => {
            const printElement = document.getElementById(p.id)
            if (printElement) {
                printElement.setAttribute("id", p.id);
                printElement.style.display = matchWidthDownSm ? "none" : "inline-flex";
            }
        });
    }, [matchWidthDownSm]);

    return <>
        {<Tooltip title="">
            <IconButton
                aria-label="setting"
                aria-controls="setting-menu"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="large">
                {!matchWidthDownSm ? <Settings /> : <MoreVert />}
            </IconButton>
        </Tooltip>}
        <Popover
            id="mouse-over-popover"
            style={{ overflow: 'hidden' }}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            open={!!anchorEl}
            disableRestoreFocus
            classes={{ paper: classes.paper }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div>
                <MenuList className={classes.menuListStyle}>

                    {matchTouchDevices && [
                        <StyledMenuItem for={"download-moments-lists"} component={"label"}>
                            <ListItemIcon className={canExpandGridStyleContainer && classes.settingIcon}>
                                <Settings />
                            </ListItemIcon>
                            <div className={classes.mainContainer}>
                                <div onClick={gridStyleToggler} className={classes.collapseContainer}>
                                    <Typography variant="inherit" noWrap>
                                        {t("general.chooseView")}
                                    </Typography>
                                    <div className={classes.iconContainer}>
                                        {canExpandGridStyleContainer ? <ExpandLess /> : <ExpandMore />}
                                    </div>
                                </div>
                                <Collapse in={canExpandGridStyleContainer} timeout="auto" unmountOnExit>
                                    <ListItem className={classes.removeContainerSpacing}>
                                        <List component="div">
                                            <RadioGroup aria-label="grid-style-picker" name="grid-style-picker" value={canListType.toString()}
                                                onChange={({ target: { value } }) => {
                                                    onChangeGridViewType(value === "true")
                                                }}>
                                                <FormControlLabel className={classes.removeSpacing} value={"false"} control={<Radio color="primary" className={classes.radioButton} />}
                                                    label={t("general.table.gridDisplay.label")} />
                                                <FormControlLabel className={classes.removeSpacing} value={"true"} control={<Radio color="primary" className={classes.radioButton} />}
                                                    label={t("general.table.listView.label")} />
                                            </RadioGroup>
                                        </List>
                                    </ListItem>
                                </Collapse>
                            </div>
                        </StyledMenuItem>

                    ]}

                    {
                        (!canListType || !matchTouchDevices) && !hideImageSizePicker && <>
                            <StyledMenuItem for={"download-moments-lists"} component={"label"}>
                                <ListItemIcon className={classes.settingIcon}>
                                    {<Image />}
                                </ListItemIcon>
                                <div>
                                    <div onClick={handleClick} className={classes.collapseContainer}>
                                        <Typography variant="inherit" noWrap>
                                            {t("general.table.chooseImageSize")}
                                        </Typography>
                                        <div className={classes.iconContainer}>
                                            {canExpandSizeContainer ? <ExpandLess /> : <ExpandMore />}
                                        </div>
                                    </div>
                                    <Collapse in={canExpandSizeContainer} timeout="auto" unmountOnExit>
                                        <ListItem className={classes.imageOptionsContainer}>
                                            <List component="div">
                                                <RadioGroup aria-label="image-size-picker" name="image-size-picker" value={imageSize}
                                                    onChange={({ target: { value } }) => onChangeImageSize(value)}>
                                                    <FormControlLabel className={classes.removeSpacing} value={20} control={<Radio color="primary" className={classes.radioButton} />}
                                                        label={t("dataGrid.pictureSize.1")} />
                                                    <FormControlLabel className={classes.removeSpacing} value={40} control={<Radio color="primary" className={classes.radioButton} />}
                                                        label={t("dataGrid.pictureSize.2")} />
                                                    <FormControlLabel className={classes.removeSpacing} value={60} control={<Radio color="primary" className={classes.radioButton} />}
                                                        label={t("dataGrid.pictureSize.3")} />
                                                </RadioGroup>
                                            </List>
                                        </ListItem>
                                    </Collapse>
                                </div>
                            </StyledMenuItem>
                            {/* gridFontSize */}
                            <StyledMenuItem for={"download-moments-lists"} component={"label"}>
                                <ListItemIcon className={classes.settingIcon}>
                                    {<FormatSize />}
                                </ListItemIcon>
                                <div>
                                    <div onClick={handleFontClick} className={classes.collapseContainer}>
                                        <Typography variant="inherit" noWrap>
                                            {t("print.fontsize")}
                                        </Typography>
                                        <div className={classes.iconContainer} style={{ marginLeft: 110 }}>
                                            {canExpandFontSizeContainer ? <ExpandLess /> : <ExpandMore />}
                                        </div>
                                    </div>
                                    <Collapse in={canExpandFontSizeContainer} timeout="auto" unmountOnExit>
                                        <ListItem className={classes.imageOptionsContainer}>
                                            <List component="div">
                                                <RadioGroup aria-label="image-size-picker" name="image-size-picker" value={fontSize}
                                                    onChange={({ target: { value } }) => onChangeFontSize(value)}>
                                                    {fontSizeConstant.map(item => (<FormControlLabel key={item}
                                                        className={classes.removeSpacing}
                                                        value={item}
                                                        control={<Radio color="primary" className={classes.radioButton} />}
                                                        label={item} />))}
                                                </RadioGroup>
                                            </List>
                                        </ListItem>
                                    </Collapse>
                                </div>
                            </StyledMenuItem>
                        </>
                    }

                    {matchWidthDownSm &&
                        <>
                            <StyledMenuItem for={"custom-csv-download-for-datagrid"} component={"label"}>
                                <ListItemIcon>
                                    <CloudDownload fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {t('general.download')}
                                </Typography>
                            </StyledMenuItem>
                            <StyledMenuItem for={"custom-print-for-datagrid"} component={"label"}>
                                <ListItemIcon>
                                    <Print fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {t('general.print')}
                                </Typography>
                            </StyledMenuItem>
                        </>
                    }
                </MenuList>
            </div>
        </Popover>
    </>;
}

const StyledMenuItem = withStyles((theme) => ({
    root: {
        whiteSpace: 'pre-wrap',
        padding: theme.spacing(1, 0),
        '& div': {
            minWidth: 30,
            color: theme.palette.grey[700]
        },
        '& span': {
            color: theme.palette.grey[700]
        }
    },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
    paper: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: 7,
    },
    CustomCheckbox: {
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 2px 2px',
        borderRadius: '6px',
        padding: theme.spacing(0.6, 1.2),
    },
    iconHeader: {
        marginRight: theme.spacing(-4.5)
    },
    noWrapText: {
        whiteSpace: "nowrap"
    },
    iconContainer: {
        marginLeft: 32,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    imageOptionsContainer: {
        padding: theme.spacing(0)
    },
    menuListStyle: {
        padding: theme.spacing(1.875, 1.25),
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: 10,
        maxWidth: 325,
        width: 'fit-content',
        minWidth: 200
    },
    collapseContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    settingIcon: {
        alignSelf: 'flex-start'
    },
    removeContainerSpacing: {
        padding: '0px !important'
    },
    mainContainer: {
        width: '100%'
    },
    removeSpacing: { marginLeft: 0, marginBottom: 8 },
    radioButton: {
        padding: theme.spacing(0)
    }
}));
