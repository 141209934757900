import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Container,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  AppBar,
  IconButton,
  Toolbar,
  Slide,
  ListItemAvatar,
  TextField,
  Collapse,
  Button,
  ListItemSecondaryAction,
  Input,
  InputAdornment,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemIcon,
  Tooltip,
  Icon,
} from "@mui/material"
import { withStyles, withTheme } from "@mui/styles"
import { Add, Search, ArrowBack, ExpandLess, ExpandMore, AttachFile, ChevronRight, Close, CloudDownload, Menu, FormatQuote, Clear } from "@mui/icons-material"
import { history, validateFile, formatFileSize, nl2br, formatDate, authHeader } from "../../_helpers";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import SelectedOptionCard from "./SelectedOptionCard";
import { themeBreakpointUp } from "../../_helpers/breakpointHelper";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  appBar: {},
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  slideRight: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  fullWidth: {
    width: "100%",
  },
  categoryList: {
    height: "calc(100% - 48px)",
    overflow: "auto",
    width: "100%",
  },
  menuIcon: {
    justifyContent: "center",
  },
  optionsContainer: {
    position: "absolute",
    zIndex: 1,
    height: "100%",
    right: 0,
  },
  optionsTitle: {
    overflow: "hidden",
    "& p": {
      lineHeight: 1.2,
    },
  },
  options: {
    overflow: "auto",
    flexGrow: 1,
    padding: theme.spacing(3, 3, 0),
    height: "calc(100% - 48px)",
    "&.requested": {
      height: "calc(100% - 96px)",
      padding: theme.spacing(0),
      marginBottom: theme.spacing(6),
    },
  },
  fixedBottomAppBar: {
    top: "auto",
    bottom: 0,
  },
  descriptionText: {
    margin: theme.spacing(2, 3),
  },
  expansionPanel: {
    width: "100%",
    "& .MuiAccordionDetailsSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionDetailsSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  optionsCategory: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    cursor: "inherit !important",
    "& .MuiIconButton-root": {
      color: "inherit",
    },
  },
  categoryColumn: {
    flexBasis: "33.33%",
  },
  headerColumn: {
    flexBasis: "50%",
    flexGrow: 1,
  },
  optionsDetails: {
    display: "block",
    padding: 0,
  },
  optionsHeading: {
    backgroundColor: theme.palette.grey[100],
    cursor: "inherit !important",
  },
  expansionPanelDetails: {
    padding: theme.spacing(1, 3),
  },
  stickyAppBar: {
    background: "none",
    boxShadow: "none",
  },
  inputBox: {
    color: "inherit",
    maxWidth: "100%",
    margin: "-3px 0 -5px",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& svg": {
      color: "inherit",
    },
  },
})

class Page extends React.Component {
  state = {
    quotations: [],
    selectedQuote: null,
    openLeftMenu: true,
    quoteMenuExpanded: true,
    checkedConfirmation: false,
    quotationFile: null,
    drawingFile: null,
    totalQuotationFileSizeError: false,
    filesToUpload: [],
    uploading: false,
    cancelQuoteDialogOpen: false,
    cancelQuoteReason: "",
    cancelQuoteReasonError: false,
    cancelQuoteInPrgoress: false,
    selectRequestedOptions: false,
    categories: [],
    options: [],
    searchTerm: "",
    filteredOptions: [],
  };

  componentDidMount() {
    this.UpdateQuotations();
    this.UpdateCategories();

    var intervalId = setInterval(this.timer, 10000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || !this.props.selected || prevProps.selected.buildingId.toUpperCase() !== this.props.selected.buildingId.toUpperCase()) {
      this.UpdateQuotations();
      this.UpdateCategories();
    }
    if (this.state.selectedQuote) {
      if (!prevState.selectedQuote || prevState.selectedQuote.quoteId !== this.state.selectedQuote.quoteId) {
        this.UpdateDocuments();
      }
      if (!this.state.quotations.find((x) => x.quoteId === this.state.selectedQuote.quoteId)) {
        this.setState({
          selectedQuote: null,
          checkedConfirmation: false,
          openLeftMenu: false,
          filesToUpload: [],
          uploading: false,
        });
      }
    }
    if (prevState.searchTerm !== this.state.searchTerm) {
      this.setState({
        filteredOptions: this.getFilteredItems(this.state.options),
      });
    }
  }

  timer = () => {
    this.UpdateQuotations(true);
    this.UpdateCategories(true);
  };

  UpdateQuotations(refresh = false) {
    const { selected } = this.props;
    if (selected) {
      if (refresh === false) {
        this.setState({
          quotations: [],
          selectedQuote: null,
          checkedConfirmation: false,
          quotationFile: null,
          drawingFile: null,
          totalQuotationFileSizeError: false,
          filesToUpload: [],
          uploading: false,
          cancelQuoteDialogOpen: false,
          cancelQuoteReason: "",
          cancelQuoteReasonError: false,
          cancelQuoteInPrgoress: false,
        });
      }

      const url = webApiUrl + "api/shopping/GetQuotations/" + encodeURI(selected.buildingId);
      if (this.quotationsAbortController && this.quotationsAbortController.signal.aborted !== true) {
        this.quotationsAbortController.abort();
      }

      this.quotationsAbortController = new window.AbortController();

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.quotationsAbortController.signal,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({ quotations: findResponse });
        })
        .catch(console.log);
    }
  }

  UpdateDocuments() {
    const { selectedQuote } = this.state;
    if (selectedQuote) {
      this.setState({
        quotationFile: null,
        drawingFile: null,
        totalQuotationFileSizeError: false,
      });

      const url = webApiUrl + "api/shopping/GetQuotationDocuments/" + encodeURI(selectedQuote.quoteId);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            quotationFile: findResponse.quotationFile,
            drawingFile: findResponse.drawingFile,
            totalQuotationFileSizeError: findResponse.fileSizeError,
          });
        });
    }
  }

  UpdateCategories(refresh = false) {
    const { selected } = this.props;
    if (selected) {
      const url = webApiUrl + "api/shopping/GetMyRequestedOptions/" + encodeURI(selected.buildingId);
      if (this.categoriesAbortController && this.categoriesAbortController.signal.aborted !== true) {
        this.categoriesAbortController.abort();
      }

      this.categoriesAbortController = new window.AbortController();

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.categoriesAbortController.signal,
      };

      if (refresh === false) {
        this.setState({
          categories: [],
          options: [],
          searchTerm: "",
        });
      }
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            categories: findResponse.categories,
            options: findResponse.options,
            filteredOptions: this.getFilteredItems(findResponse.options),
          });
        });
    }
  }

  handleClickOrder = (event) => {
    const { t } = this.props;
    const { selectedQuote, checkedConfirmation, filesToUpload } = this.state;
    if (checkedConfirmation === true && selectedQuote) {
      if (selectedQuote.isDigitalSigning === true) {
        const url = webApiUrl + "api/shopping/OrderAndSendQuotationForDigitalSigning/" + encodeURI(selectedQuote.quoteId);

        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((findResponse) => {
            history.push("/order-unsigned");
          })
          .catch((error) => {
            alert(t("general.api.error"));
          });
      } else {
        if (filesToUpload.length > 0) {
          this.setState({ uploading: true });

          const formData = new FormData();

          for (var i = 0; i < filesToUpload.length; i++) {
            formData.append("files", filesToUpload[i]);
          }

          fetch(webApiUrl + "api/shopping/OrderQuotationAndUploadSignedDocuments/" + encodeURI(selectedQuote.quoteId), {
            method: "POST",
            headers: authHeader(),
            body: formData,
          })
            .then((Response) => Response.json())
            .then((res) => {
              history.push("/order-complete");
            })
            .catch((e) => {
              this.setState({ uploading: false });
              alert(t("general.api.error"));
            });
        }
      }
    } else {
      event.preventDefault();
    }
  };

  handleSubmitCancelQuote = (event) => {
    const { cancelQuoteReason, selectedQuote } = this.state;
    const { selected, t } = this.props;

    if (selected && selectedQuote && cancelQuoteReason && cancelQuoteReason.trim() !== "") {
      this.setState({ cancelQuoteInPrgoress: true });
      const message = cancelQuoteReason;
      const url = webApiUrl + "api/shopping/CancelQuotation/" + encodeURI(selectedQuote.quoteId);
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify(message),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          this.UpdateQuotations();
        })
        .catch((e) => {
          alert(t("general.api.error"));
          this.setState({ cancelQuoteInPrgoress: false });
        });
    } else {
      this.setState({ cancelQuoteReasonError: true });
    }
  };

  handleCancelQuoteDialogToggle = (event) => {
    this.setState({
      cancelQuoteDialogOpen: !this.state.cancelQuoteDialogOpen,
      cancelQuoteReason: "",
      cancelQuoteReasonError: false,
      cancelQuoteInPrgoress: false,
    });
  };

  changeCancelReason = (event) => {
    this.setState({ cancelQuoteReason: event.target.value });
  };

  toggleOpenLeftMenu = () => {
    this.setState({ openLeftMenu: !this.state.openLeftMenu });
  };

  handleSelectQuotation = (selectedQuote) => {
    if (!this.state.selectedQuote || selectedQuote.quoteId !== this.state.selectedQuote.quoteId) {
      this.setState({
        selectedQuote,
        //Search #FUTURE_FIX in this page to fix related issue
        //#FUTURE_FIX temporarily making checkedConfirmation to true... later make it false when unhide the block.
        checkedConfirmation: true,
        openLeftMenu: false,
        filesToUpload: [],
        uploading: false,
        selectRequestedOptions: false,
      });
    } else {
      this.setState({ openLeftMenu: false });
    }
  };

  handleSelectRequestedOptions = () => {
    this.setState({
      selectedQuote: null,
      checkedConfirmation: false,
      openLeftMenu: false,
      filesToUpload: [],
      uploading: false,
      selectRequestedOptions: true,
    });
  };

  handleChangeConfirmation = () => {
    this.setState({
      checkedConfirmation: !this.state.checkedConfirmation,
    });
  };

  handleSelectFiles = (e) => {
    const files = Array.from(e.target.files);
    let filesToUpload = this.state.filesToUpload.slice();
    for (var i = 0; i < files.length; i++) {
      if (validateFile({file:files[i]}) === true) {
        filesToUpload.push(files[i]);
      }
    }
    this.setState({ filesToUpload });
  };

  handleRemoveFile = (index) => {
    let filesToUpload = this.state.filesToUpload.slice();
    filesToUpload.splice(index, 1);
    this.setState({ filesToUpload });
  };

  getTotalText() {
    var total = 0;
    if (this.state.selectedQuote) {
      total = this.state.selectedQuote.options.reduce((prev, next) => prev + next.salesPriceInclVAT * next.quantity, 0);
    }

    return (
      <React.Fragment>
        &euro;{" "}
        {total.toLocaleString("nl-NL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </React.Fragment>
    );
  }

  getTotalTextRequestedOptions() {
    return (
      <React.Fragment>
        &euro;{" "}
        {this.state.options
          .reduce((prev, next) => prev + next.salesPriceInclVAT * next.quantity, 0)
          .toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
      </React.Fragment>
    );
  }

  getFilteredItems(options) {
    var text = this.state.searchTerm.toUpperCase().split(" ");
    return options.filter(function (item) {
      return text.every(function (el) {
        var content = item.optionNo + " " + item.description + " " + item.commercialDescription;
        return content.toUpperCase().indexOf(el) > -1;
      });
    });
  }

  containsCategory(catId) {
    return this.state.filteredOptions.filter((x) => x.optionCategoryProjectId === catId).length > 0;
  }

  containsHeader(catId, headerId) {
    return this.state.filteredOptions.filter((x) => x.optionCategoryProjectId === catId && x.optionHeaderProjectId === headerId).length > 0;
  }

  handleChangeSearch = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  clearSearchText = () => {
    this.setState({
      searchTerm: "",
    });
  };

  renderOptionsInBasket(selectedQuote) {
    const { t } = this.props;
    let headerText = "";
    return (
      <React.Fragment>
        {selectedQuote.options.map((option, indexOption) => {
          let currentHeader = option.category + " - " + option.header;
          if (headerText !== currentHeader) {
            headerText = currentHeader;
          } else {
            currentHeader = "";
          }
          return (
            <React.Fragment key={indexOption}>
              {currentHeader !== "" && (
                <Grid item xs={12}>
                  <Typography variant="body2">{currentHeader}</Typography>
                </Grid>
              )}
              <Grid container item xs={12} alignItems="flex-start">
                <Grid item xs={2} md={1}>
                  <Typography variant="body2">
                    <NumericFormat displayType="text" decimalScale={option.unitDecimalPlaces} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," value={option.quantity} suffix="&nbsp; " />
                    {option.eenheid}
                  </Typography>
                </Grid>
                <Grid item xs={7} md={9}>
                  <Typography variant="body2" component="div">
                    {option.optionNo + " - " + option.description}
                  </Typography>
                  <Typography variant="body2" component="div" color="textSecondary">
                    {nl2br(option.commercialDescription)}
                  </Typography>
                  <Typography variant="body2" component="div" color="textSecondary">
                    <FormatQuote fontSize="small" />
                    {option.additionalDescription}
                  </Typography>
                </Grid>
                <Grid item container xs={3} md={2} alignItems="center" justifyContent="flex-end">
                  <Grid item xs={12} sm md={6}>
                    <Typography variant="body2" component="div" align="right">
                      {!option.salesPriceEstimated && !option.salesPriceToBeDetermined ? (
                        <NumericFormat prefix="&euro;&nbsp;" displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," value={option.quantity * option.salesPriceInclVAT} />
                      ) : (
                        option.salesPriceInclVAT_Text
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  renderDocument(file) {
    const { classes } = this.props;
    return (
      file && (
        <Grid container item xs={12} alignItems="center" justifyContent="flex-end">
          <Typography className={classes.grow} noWrap>
            {file.description}
          </Typography>
          <Grid item>
            <Typography>{file.datum && formatDate(new Date(file.dateTime))}</Typography>
          </Grid>
          <Grid item>
            <IconButton href={webApiUrl + "api/home/GetAttachment/" + encodeURI(file.id)} download>
              <CloudDownload />
            </IconButton>
          </Grid>
        </Grid>
      )
    );
  }

  render() {
    const { user, t, classes, theme, selected } = this.props
    const {
      selectedQuote,
      openLeftMenu,
      quoteMenuExpanded,
      quotations,
      quotationFile,
      drawingFile,
      totalQuotationFileSizeError,
      filesToUpload,
      uploading,
      cancelQuoteDialogOpen,
      cancelQuoteReason,
      cancelQuoteReasonError,
      cancelQuoteInPrgoress,
      selectRequestedOptions,
    } = this.state;
    const matches = themeBreakpointUp(window, theme, "md")

    return (
      <Container className={classes.mainContainer}>
        <Grid container className={classes.container}>
          <Slide direction="right" appear={!matches} in={openLeftMenu || matches} mountOnEnter unmountOnExit>
            <Grid item xs={12} md={4} container direction="column" className={classes.slideRight}>
              <AppBar position="static" color="inherit" className={classes.appBar}>
                <Toolbar variant="dense">
                  {!matches && (
                    <IconButton aria-label="GoBack" edge="start" color="inherit" onClick={this.toggleOpenLeftMenu}>
                      <ArrowBack />
                    </IconButton>
                  )}
                  <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                    {t("layout.menuitem.housingwishes.requested")}
                  </Typography>
                  <IconButton aria-label="Search" edge="end" color="inherit">
                    <Search />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <List className={classes.categoryList}>
                <ListItem title={t("general.inProcess")} button selected={selectRequestedOptions} onClick={this.handleSelectRequestedOptions}>
                  <ListItemText primary={t("general.inProcess")} primaryTypographyProps={{ noWrap: true }} />
                  <ChevronRight color="action" />
                </ListItem>
                <Divider component="li" />
                <ListItem
                  title={t("buyersGuide.quotations")}
                  button
                  //selected={selectedQuote}
                  onClick={() => {
                    this.setState({ quoteMenuExpanded: !quoteMenuExpanded })
                  }}
                >
                  <ListItemText primary={t("buyersGuide.quotations")} primaryTypographyProps={{ noWrap: true }} />
                  {quoteMenuExpanded ? <ExpandLess className={classes.impChatListIcon} color="action" /> : <ExpandMore className={classes.impChatListIcon} color="action" />}
                </ListItem>
                <Collapse in={quoteMenuExpanded} timeout="auto" unmountOnExit>
                  {quotations.length > 0 ? (
                    <React.Fragment>
                      {quotations.map((quote, index) => (
                        <React.Fragment key={index}>
                          <Divider component="li" />
                          <ListItem
                            title={t("buyersGuide.quotation") + " - " + quote.quoteNo}
                            button
                            //className={classes.nested}
                            selected={selectedQuote && selectedQuote.quoteId.toUpperCase() === quote.quoteId.toUpperCase()}
                            onClick={() => this.handleSelectQuotation(quote)}
                          >
                            <ListItemIcon className={classes.menuIcon}>
                              <Tooltip title={t("general.sign")}>
                                <Icon className="fas fa-file-signature" />
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={t("buyersGuide.quotation") + " - " + quote.quoteNo} primaryTypographyProps={{ noWrap: true }} />
                            <ChevronRight color="action" />
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <ListItem>
                      <ListItemText secondary={t("buyersGuide.quotations.noQuotations")} />
                    </ListItem>
                  )}
                </Collapse>
              </List>
            </Grid>
          </Slide>

          <Grid item xs={12} md={8} container direction="column" justifyContent="center" className={classes.optionsContainer}>
            <AppBar position="static" color="inherit" className={classes.appBar}>
              <Toolbar variant="dense">
                {!matches && (
                  <IconButton aria-label="OpenMenu" edge="start" color="inherit" onClick={this.toggleOpenLeftMenu}>
                    <Menu />
                  </IconButton>
                )}
                {selectedQuote ? (
                  <React.Fragment>
                    <div className={clsx(classes.grow, classes.optionsTitle)}>
                      <Typography className={classes.bold} noWrap>
                        {t("buyersGuide.quotation") + " - " + selectedQuote.quoteNo}
                      </Typography>
                    </div>
                    {
                      <IconButton aria-label={t("datatable.label.toolbar.search")} edge="end" color="inherit">
                        <Search />
                      </IconButton>
                    }
                  </React.Fragment>
                ) : selectRequestedOptions === true ? (
                  <React.Fragment>
                    <Typography className={classes.bold}>{t("buyersGuide.requestedOptions")}</Typography>
                    <div className={classes.headerColumn} align="right">
                      <Input
                        onFocus={(event) => event.stopPropagation()}
                        className={classes.inputBox}
                        type="search"
                        value={this.state.searchTerm}
                        onChange={this.handleChangeSearch}
                        endAdornment={
                          <InputAdornment position="end">
                            {this.state.searchTerm !== "" ? (
                              <IconButton color="inherit" size="small" onClick={this.clearSearchText}>
                                <Close />
                              </IconButton>
                            ) : (
                              <Search />
                            )}
                          </InputAdornment>
                        }
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Toolbar>
            </AppBar>
            <Grid container direction="column" className={selectRequestedOptions === true ? clsx(classes.options, "requested") : classes.options}>
              {selectedQuote ? (
                <Grid item container xs={12} direction="row" alignContent="flex-start">
                  <Grid item xs={12} container spacing={2}>
                    {this.renderOptionsInBasket(selectedQuote)}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.bold}>
                          {t("layout.menuitem.documents")}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        {selectedQuote.isDigitalSigning !== true && this.renderDocument(quotationFile)}
                        {this.renderDocument(drawingFile)}
                      </Grid>
                    </Grid>
                    {selectedQuote.isDigitalSigning !== true && (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textSecondary">
                            {t("buyersGuide.quotation.offlineSigning.processInfo")}
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid container item xs={12} alignItems="center">
                            <Typography variant="body2" className={clsx(classes.bold, classes.grow)}>
                              {t("buyersGuide.quotation.offlineSigning.uploadSignedQuote")}
                            </Typography>
                            <input accept="*" style={{ display: "none" }} id="icon-button-file" type="file" multiple disabled={!selected || user.viewOnly === true} onChange={this.handleSelectFiles} />
                            <label htmlFor="icon-button-file" style={{ margin: 0 }}>
                              {uploading ? (
                                <CircularProgress color="inherit" size={24} />
                              ) : (
                                <IconButton color="inherit" aria-label="upload" component="span" disabled={!selected}>
                                  <Add />
                                </IconButton>
                              )}
                            </label>
                          </Grid>
                          <Grid container item xs={12}>
                            {
                              <List dense className={classes.grow}>
                                {filesToUpload.map((file, index) => (
                                  <ListItem key={index}>
                                    <ListItemAvatar>
                                      <Avatar>
                                        <AttachFile />
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={file.name} secondary={formatFileSize(file.size)} />
                                    <ListItemSecondaryAction>
                                      <IconButton edge="end" aria-label="delete" onClick={() => this.handleRemoveFile(index)}>
                                        <Clear />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                ))}
                              </List>
                            }
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs>
                        <Typography variant="body2" className={classes.bold}>
                          {t("general.total")}
                        </Typography>
                        <br />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.bold}>
                          {this.getTotalText()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <Typography variant="body2" component="div" color="textSecondary" paragraph>
                        {selectedQuote.isDigitalSigning !== true ? t("buyersGuide.quotation.offlineSigning.confirmOptionsInfo") : t("buyersGuide.quotation.digitalSigning.confirmOptionsInfo")}
                      </Typography>
                      {selectedQuote.isDigitalSigning === true && (
                        <>
                          <Typography
                            variant="body2"
                            component="div"
                            color="textSecondary"
                            paragraph={totalQuotationFileSizeError === true}
                            dangerouslySetInnerHTML={{
                              __html: t("buyersGuide.quotation.digitalSigning.processInfo"),
                            }}
                          />
                          {totalQuotationFileSizeError === true && (
                            <Typography variant="body2" color="error">
                              {t("buyersGuide.quotation.digitalSigning.fileSizeErrorInfo")}
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                    {
                      //Search #FUTURE_FIX in this page to fix related issue
                      //#FUTURE_FIX temporarily commented.
                      //<Grid item xs={12}>
                      //    <FormControlLabel
                      //        control={
                      //            <Checkbox
                      //                checked={this.state.checkedConfirmation}
                      //                onChange={this.handleChangeConfirmation}
                      //                value="checkedB"
                      //                color="primary"
                      //                size="small"
                      //                disabled={user.viewOnly === true}
                      //            />
                      //        }
                      //        label={
                      //            <Typography variant="body2">{t('buyersGuide.quotation.termsAndConditions')}</Typography>
                      //        }
                      //    />
                      //</Grid>
                    }
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={this.handleClickOrder}
                        disabled={user.viewOnly === true || (selectedQuote.isDigitalSigning === true && (!quotationFile || totalQuotationFileSizeError === true))}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {selectedQuote.isDigitalSigning !== true ? t("buyersGuide.quotation.offlineSigning.order") : t("buyersGuide.quotations.digitalSigning")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={this.handleCancelQuoteDialogToggle}
                        disabled={user.viewOnly === true || (selectedQuote.isDigitalSigning === true && (!quotationFile || totalQuotationFileSizeError === true))}
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {t("buyersGuide.quotation.cancelQuotation")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      &nbsp;
                    </Grid>
                  </Grid>
                </Grid>
              ) : selectRequestedOptions === true ? (
                <React.Fragment>
                  <div>
                    <Typography variant="body2" color="textSecondary" className={classes.descriptionText}>
                      {t("buyersGuide.requestedOptions.toBeReviewedInfo")}
                    </Typography>
                    {this.state.categories
                      .filter((x) => this.containsCategory(x.optionCategoryProjectId))
                      .map((category, indexCat) => (
                        <Accordion key={indexCat} className={classes.expansionPanel} expanded={true}>
                          <AccordionSummary className={classes.optionsCategory}>
                            <Typography className={classes.bold}>{category.category}</Typography>
                          </AccordionSummary>
                          <AccordionDetails className={classes.optionsDetails}>
                            {category.headers
                              .filter((x) => this.containsHeader(category.optionCategoryProjectId, x.optionHeaderProjectId))
                              .map((header, indexHeader) => (
                                <Accordion key={indexHeader} ref={header.ref} className={classes.expansionPanel} expanded={true}>
                                  <AccordionSummary className={classes.optionsHeading}>
                                    <Typography className={classes.bold}>{header.header}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className={classes.expansionPanelDetails}>
                                    <div className={classes.fullWidth}>
                                      {this.state.filteredOptions
                                        .filter((x) => x.optionCategoryProjectId === category.optionCategoryProjectId && x.optionHeaderProjectId === header.optionHeaderProjectId)
                                        .map((option, indexOption) => (
                                          <SelectedOptionCard key={indexOption} option={option} />
                                        ))}
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                  {
                    <AppBar position="absolute" className={classes.fixedBottomAppBar}>
                      <Toolbar variant="dense">
                        <Typography className={clsx(classes.headerColumn, classes.bold)}>{t("buyersGuide.options.totalAmount")}</Typography>
                        <Typography className={classes.bold}>{this.getTotalTextRequestedOptions()}</Typography>
                      </Toolbar>
                    </AppBar>
                  }
                </React.Fragment>
              ) : (
                <Grid item container xs={12} spacing={2} alignContent="center" alignItems="center" justifyContent="center">
                  <Grid item>
                    <img src={webApiUrl + "api/Config/WebLogo"} width="300" alt="JPDS" />
                    <Typography variant="h4" color="textSecondary" align="center">
                      {t("buyersGuide.quotation.chooseYourQuotationHere")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {selectedQuote && (
          <Dialog fullWidth maxWidth="sm" open={cancelQuoteDialogOpen} onClose={this.handleCancelQuoteDialogToggle} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t("buyersGuide.quotation.cancelQuotation") + "-" + selectedQuote.quoteNo}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("buyersGuide.quotation.cancellationReason.label")}</DialogContentText>
              <TextField
                error={cancelQuoteReasonError}
                autoFocus
                margin="dense"
                id="message"
                label={t("general.message")}
                required
                multiline
                maxRows="5"
                fullWidth
                value={cancelQuoteReason}
                onChange={this.changeCancelReason}
                disabled={cancelQuoteInPrgoress === true || user.viewOnly === true}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancelQuoteDialogToggle} color="default" disabled={cancelQuoteInPrgoress === true || user.viewOnly === true}>
                {t("general.cancel")}
              </Button>
              <Button onClick={this.handleSubmitCancelQuote} color="primary" disabled={cancelQuoteInPrgoress === true || user.viewOnly === true}>
                {t("general.send")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    )
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected } = buildings;
  return {
    user,
    selected,
    buildings,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme((withTranslation()(withStyles(styles)(Page)))))
export { connectedPage as RequestedPage };
