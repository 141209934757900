import { URLS } from "./urls";
import Axios from "axios";
import {
  getAssignment,
  getAssignments,
  getEmailsForRepairRequestIDB,
  getFile,
  getMoment,
  getOrganizationsByProjectFromIDB,
  getOrganizationsFromIDB,
  getPinImages,
  getProductsServicesIDB,
  getRecipientsEmailsIDB,
  getRepairRequestCarryOutAsTypeListIDB,
  getRepairRequestCauseListFromIDB,
  getRepairRequestCauserListFromIDB,
  getRepairRequestDetailsIDB,
  getRepairRequestLocationFromIDB,
  getRepairRequestNatureListIDB,
  getRepairRequestsFromIDB,
  getRepairRequestTypeListFromIDB,
  getSettlementTermsFromIDB,
  getSurveyDetails,
  getSurveys as getSurveysApi,
  getSurveysFromDB,
  getUserLockId,
  getWBProjectInfoIDB,
  updateResponsiblePersonForAssignmentsOffline
} from "../_helpers";
import { syncsConstants } from "../_constants";


export const updateExecutorForSurvey = (surveyIds, loginId, isSecondSignature = false) => Axios.post(`${URLS.UPDATE_EXECUTOR_FOR_SURVEY}?loginId=${loginId}&isSecondSignature=${isSecondSignature}`, surveyIds);
export const getAvailableExecutorsForSurvey = (surveyIds, isSecondSignature = false) => Axios.post(`${URLS.GET_AVAILABLE_EXECUTORS_FOR_SURVEY}?isSecondSignature=${!!isSecondSignature}`, surveyIds);
export const getProjectsForLinking = () => Axios.get(URLS.GET_PROJECTS_FOR_LINKING);
export const getAssignmentsByProjectId = (projectId) => {
  const isOnline = window.navigator.onLine;
  if (!isOnline)
    return getAssignments(projectId).then(data => ({ data }));
  return Axios.get(URLS.GET_ASSIGNMENTS_BY_PROJECT_ID + projectId);
};
export const resetAssignmentStatus = (assignmentIds) => Axios.post(URLS.RESET_ASSIGNMENT_STATUS, assignmentIds);

export const getOrganizations = async (projectIds, app) => {
  const projectId = Array.isArray(projectIds) ? projectIds[0] : projectIds;
  if (!Array.isArray(projectIds)) projectIds = [projectIds];
  if (window.navigator.onLine)
    return Axios.post(URLS.GET_ORGANIZATIONS, projectIds);
  else
    return await getOrganizationsFromIDB({ app, projectId })
}

export const getOrganizationsByProject = async (projectId) => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_ORGANIZATIONS_BY_PROJECT + projectId);
  else
    return await getOrganizationsByProjectFromIDB({ projectId }).then(data => ({ data }));
}



export const addOrUpdateDrawingLocationNameForProject = (data) => Axios.post(URLS.ADD_OR_UPDATE_DRAWING_LOCATION_NAME, data);
export const getDrawingLocationNamesForProject = () => Axios.get(URLS.GET_DRAWING_LOCATION_NAME);
export const deleteDrawingLocationNameForProject = () => Axios.delete(URLS.DELETE_DRAWING_LOCATION_NAME);
export const getApplicationManagerActionItems = () => Axios.get(URLS.APPLICATION_MANAGER_ACTION_ITEMS);
export const confirmProjectLinking = (projectId, wbProjectId) => Axios.post(`${URLS.CONFIRM_PROJECT_LINKING}?wbProjectId=${wbProjectId}&projectId=${projectId}`);
export const linkEmailToLogin = (email, loginId, oldLoginId) => Axios.post(`${URLS.LINK_EMAIL_TO_LOGIN}?email=${email}&loginId=${loginId}${oldLoginId ? `&oldLoginId=${oldLoginId}` : ''}`);
export const mapBuildingToWBObject = (data) => Axios.post(`${URLS.MAP_BUILDING_TO_OBJECT}${data.objectId}${data.buildingId ? `?buildingId=${data.buildingId}` : ''}`);
export const completeSurveyingMoments = (data) => Axios.post(URLS.COMPLETE_SURVEYING_MOMENTS, data.map(p => p.surveyingMomentId));
export const internalCompleteSurveyingMoments = (data) => Axios.post(`${URLS.INTERNAL_COMPLETE_SURVEYING_MOMENTS}`, data.map(p => p.surveyingMomentId));
// export const getAssignmentMessages = (assignmentId, lastDateTime) => Axios.post(URLS.GET_ASSIGNMENT_MESSAGES + `/${assignmentId}?lastDateTime=${lastDateTime}`);
export const updateResponsiblePerson = (data) => Axios.post(URLS.UPDATE_RESPONSIBLE_PERSON, data);
export const updateResponsiblePersonForAssignments = async (data, selected, isMulti, app, selectedMoment, orgId) => {
  if (window.navigator.onLine) {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const lockInfo = await getUserLockId({ unitId: data.ids[0] || "", userId: user.id || "" });

    return Axios.post(URLS.UPDATE_RESPONSIBLE_PERSON_FOR_ASSIGNMENTS, data, lockInfo && {
      headers: {
        [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
      }
    })
  }
  return updateResponsiblePersonForAssignmentsOffline(data, selected, isMulti, app, selectedMoment, orgId);
};
export const uploadAssignmentFiles = (data) => Axios.post(URLS.UPLOAD_ASSIGNMENT_FILES, data);
// export const uploadAssignmentFiles = (data) => Axios.post(URLS.UPLOAD_ASSIGNMENT_FILES, {data});
export const getObjectsByProjectId = (projectId) => Axios.get(URLS.GET_ALL_OBJECTS, { data: { projectId } });
export const getProjects = () => Axios.get(URLS.GET_ALL_PROJECTS);
// export const addRepairRequest = (data) => Axios.post(URLS.ADD_REPAIR_REQUEST, { data });
export const getObjectsListByProjectId = (projectId) => Axios.get(URLS.GET_OBJECTS_LIST_BY_PROJECT_ID + projectId);
// export const getOrganizations = ({ projectId, methodName, productServiceId }) => Axios.get(URLS.GET_ORGANIZATIONS + projectId);

export const getRepairRequestsApi = (surveyId, isOnline) => {
  if (!isOnline)
    return getRepairRequestsFromIDB({ surveyId }).then(data => ({ data }));
  return Axios.get(URLS.GET_REPAIR_REQUESTS + surveyId);
}
export const getRepairRequestDetails = (requestId) => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_DETAILS + requestId);
  else
    return getRepairRequestDetailsIDB(requestId).then(data => ({ data }));
}
export const getRepairRequestsAttachments = (repairRequestId, attachmentId) => Axios.get(`${URLS.GET_REPAIR_REQUEST_ATTACHMENTS}${repairRequestId}/${attachmentId}`, { responseType: 'arraybuffer' });
export const getRepairRequestLocations = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_LOCATIONS)
  return getRepairRequestLocationFromIDB().then(data => ({ data }));
}
export const getProjectLogoForReport = (projectId) => {
  if (window.navigator.onLine)
    return Axios.get(`${URLS.GET_PROJECT_LOGO_FOR_REPORT}${projectId}?forReport=true`, { responseType: 'arraybuffer' });
  return getFile(projectId + 'forReport').then(data => ({ data: data ? data.file : null }));
}

export const getRepairRequestTypeList = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_TYPE_LIST)
  return getRepairRequestTypeListFromIDB().then(data => ({ data }));
};

export const getPinLocationImage = (id) => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_PIN_LOCATION_IMAGE + id)
  return getPinImages(id);
};

export const getRepairRequestCauseList = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_CAUSE_LIST)
  return getRepairRequestCauseListFromIDB().then(data => ({ data }));
};

export const getRepairRequestCauserList = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_CAUSER_LIST)
  return getRepairRequestCauserListFromIDB().then(data => ({ data }));
};

export const getRepairRequestNatureList = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_NATURE_LIST)
  return getRepairRequestNatureListIDB().then(data => ({ data }));
};

export const getRepairRequestCarryOutAsTypeList = () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_REPAIR_REQUEST_CARRY_OUT_TYPE_LIST)
  return getRepairRequestCarryOutAsTypeListIDB().then(data => ({ data }));
};


export const addSurveyRepairRequest = async (data) => {
  let body = {};
  for (let key of data.keys()) body[key === 'files' ? 'attachments' : key] = data[key === 'files' ? 'getAll' : 'get'](key);
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: body.assignmentId || body.surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.ADD_SURVEY_REPAIR_REQUEST, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
};
export const getSurveyReport = (surveyId, isSecondSignature) => Axios.get((isSecondSignature ? URLS.GET_SURVEY_REPORT_FOR_SECOND_SIGNATURE : URLS.GET_SURVEY_REPORT) + surveyId, { responseType: 'blob' });
export const completeSignature = async ({ status, ...data }) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: data.surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.COMPLETE_SIGNATURE + data.surveyId, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}
export const updateSurveyApi = async (data) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: data.surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.UPDATE_SURVEY, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
};

export const startSecondSignature = async (surveyId) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: surveyId || "", userId: user.id || "" });
  return Axios.get(URLS.START_SECOND_SIGNATURE + surveyId, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
};

export const getRecipientsEmails = async (surveyId) => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_RECIPIENTS_EMAILS + surveyId)
  return getRecipientsEmailsIDB({ surveyId }).then(data => ({ data }))
};
export const getEmailsForRepairRequest = async (requestId) => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_EMAILS_FOR_REPAIR_REQUEST + requestId)
  return getEmailsForRepairRequestIDB({ requestId }).then(data => ({ data }))
};
export const sendRecipientsEmails = async (surveyId, data) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.SEND_RECIPIENTS_EMAILS + surveyId, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  })
};
export const sendRecipientsEmailsSecondSignature = async (surveyId, data) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.SEND_RECIPIENTS_EMAILS_FOR_SECOND_SIGNATURE + surveyId, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}

export const getProductsServices = async () => {
  const isOnline = window.navigator.onLine
  if (!isOnline)
    return getProductsServicesIDB().then(data => ({ data }))
  return Axios.get(URLS.GET_PRODUCTS_SERVICES)
};

export const getExistingImagesFromRepairRequest = (projectId) => Axios.get(`${URLS.EXISTING_IMAGES_FROM_REPAIR_REQUEST}${projectId}`);
export const updateSurveyMomentLastView = (surveyingMomentId, assignmentId, data) => Axios.post(`${URLS.UPDATE_MOMENT_LAST_VIEW}${surveyingMomentId}?lastViewDate=${encodeURIComponent(new Date().toISOString())}${assignmentId ? `&assignmentId=${assignmentId}` : ''}`);
export const deleteAssignmentImage = async (assignmentId, attachmentId) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: assignmentId, userId: user.id || "" });
  return Axios.delete(`${URLS.DELETE_ASSIGNMENT_IMAGE}${assignmentId}/${attachmentId}`, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
};
export const internalCompleteAssignments = (data) => Axios.post(`${URLS.INTERNAL_COMPLETE_ASSIGNMENTS}`, data);
export const completeAssignments = async (data) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: data[0], userId: user.id || "" });
  return Axios.post(`${URLS.COMPLETE_ASSIGNMENTS}`, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}
export const getAvailableSurveyModules = (projectId, buildingId, getAvailableSurveyModulesController) => Axios.get(`${URLS.GET_AVAILABLE_SURVEY_MODULES}${projectId}${buildingId ? `?buildingId=${buildingId}` : ''}`, getAvailableSurveyModulesController && { cancelToken: getAvailableSurveyModulesController.token });
export const getAttachment = (attachmentId) => Axios.get(`${URLS.GET_ATTACHMENT}${attachmentId}`);
export const getAssignmentAttachment = (attachmentId) => Axios.get(`${URLS.GET_ASSIGNMENT_ATTACHMENT}${attachmentId}`, { responseType: 'arraybuffer' });
export const getAssignmentAttachmentThumbnail = (attachmentId) => Axios.get(`${URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL}${attachmentId}`);
export const getWBProjectInfo = async (projectId) => {
  if (window.navigator.onLine)
    return Axios.get(`${URLS.GET_WB_PROJECT_INFO}${projectId}`)
  return getWBProjectInfoIDB(projectId).then(data => ({ data }))
};

export const getAllSurveys = async (projectId, isOnline, userId) => {
  if (!isOnline) {
    return getSurveysFromDB(projectId, userId);
  } else
    return Axios.get(`${URLS.GET_SURVEYS_MOMENTS}${projectId}`);
  // return Axios.get(`${URLS.GET_SURVEYS_MOMENTS}`, { data: { projectId } });
}
export const getSurveyMomentInfo = async (data, isOnline) => {
  if (!isOnline) {
    return getMoment({ surveyingMomentId: data.surveyMomentId, objectId: data.wbObjectId });
  } else
    return Axios.get(`${URLS.GET_SURVEY_MOMENT_INFO}${data.surveyMomentId}${data.wbObjectId ? `/${data.wbObjectId}` : ""}`)
  // return Axios.get(`${URLS.GET_SURVEY_MOMENT_INFO}`, { data })
};
export const getAssignmentInfo = async (id, isOnline) => {
  if (!isOnline) {
    return getAssignment(id);
  } else
    return Axios.get(`${URLS.GET_ASSIGNMENT_DETAILS}${id}`)
  // return Axios.get(URLS.GET_ASSIGNMENT_DETAILS, { data: { assignmentId: id } })
};


export const getSurveys = async ({ surveyType: type, ...data } = {}) => {
  if (!window.navigator.onLine)
    return getSurveysApi({ surveyType: type, isSecondSignature: data.isSecondSignature ? 1 : 0 })
  return Axios.post(URLS.GET_INSPECTIONS, { type, ...data });
};


export const getSurveyDetailApi = async (surveyId, isOnline, isSecondSignature = false) => {
  if (!isOnline)
    return getSurveyDetails({ surveyId, isSecondSignature: +isSecondSignature })
  return Axios.get(`${URLS.GET_INSPECTION}${surveyId}?isSecondSignature=${isSecondSignature}`);
}

export const updateAssignmentInfo = async ({ assignmentId, ...data }, senderName) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: assignmentId, userId: user.id || "" });
  return Axios.post(`${URLS.UPDATE_ASSIGNMENT_INFO}${assignmentId}`, Object.keys(data).map(id => ({
    id,
    name: data[id]
  })), lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  })
};
export const createRepairRequest = async (data) => {
  let body = {};
  for (let key of data.keys()) body[key === 'files' ? 'attachments' : key] = data[key === 'files' ? 'getAll' : 'get'](key);
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: body.assignmentId || body.surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.CREATE_REPAIR_REQUEST, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}
export const getSettlementTerms = async () => {
  if (window.navigator.onLine)
    return Axios.get(URLS.GET_SETTLEMENT_TERM)
  return getSettlementTermsFromIDB().then(data => ({ data }))
}
export const updateRepairRequest = (requestId, data) => Axios.post(URLS.UPDATE_REPAIR_REQUEST + requestId, data);
export const addRepairRequestResolver = (requestId, data) => Axios.post(URLS.ADD_REPAIR_REQUEST_RESOLVER + requestId, data);
export const addUpdateRework = async (data, surveyId) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.ADD_UPDATE_REWORK, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}
export const markCompleteRR = async (data, surveyId) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: surveyId || "", userId: user.id || "" });
  return Axios.post(URLS.MARK_COMPLETE_RR, data, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
}
export const deleteRepairRequest = async (requestId, selectedInspection) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const lockInfo = await getUserLockId({ unitId: selectedInspection.surveyId || "", userId: user.id || "" });

  return Axios.delete(URLS.DELETE_RR + requestId, lockInfo && {
    headers: {
      [syncsConstants.OFFLINE_HEADER_ID_IDENTIFIER]: lockInfo.lockId,
    }
  });
};
export const getRRDashboardCounts = (app, data) => Axios.post(`${URLS.GET_REPAIR_REQUEST_DASHBOARD_COUNTS}?app=${app}`, data);
export const canRequestExistCache = async (request) => {
  return new Promise((resolve) => {
    if (window.caches) {
      window.caches.open("pwa-app-cache").then(cache => {
        cache.match(request).then(res => {
          if (res) resolve(res)
          else resolve(null)
        }).catch(() => resolve(null))
      }).catch(() => resolve(null));
    } else {
      resolve(null);
    }
  });
}


//lOCK & RELEASE UNITS
export const lockUnits = (data) => Axios.post(URLS.LOCK_UNITS, data);
export const releaseUnits = (data) => Axios.post(URLS.RELEASE_UNITS_LOCKS, data);
