import { Box, ListItemIcon, Menu, MenuItem, Divider, Typography } from "@mui/material";

const CellMenu = ({ cellSubMenuOptions, row, anchorEl, handleMenuClose, position }) => {

  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: position?.y,
        horizontal: position?.x,
      }}
    >
      {cellSubMenuOptions?.options?.map(({ label, icon, onClick: OnItemClick, isDividerAfter = false }, i) => {
        return <Box><MenuItem sx={{ minWidth: "248px" }} onClick={() => { OnItemClick(row, cellSubMenuOptions?.extraData); handleMenuClose(); }}>
          {icon ? (<> <ListItemIcon>{icon}</ListItemIcon>{label}</>) : (<Typography ml={"36px"}>{label}</Typography>)}
        </MenuItem>
        {isDividerAfter && <Divider />}
        </Box>
      })}
    </Menu>
  );
};

export default CellMenu