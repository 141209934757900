import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
  Snackbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import { base64ToFile } from "../../../_helpers";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Camera({ closeDrawer, open, close, ...props }) {
  const [imageURL, setImageURL] = useState("");
  const [camNotFound, setCamNotFound] = useState(false);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  let videoEle = React.useRef();
  let canvasEle = React.useRef();
  let imageEle = React.useRef();

  const startCamera = async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (typeof navigator.mediaDevices.getUserMedia === "undefined") {
      navigator.getUserMedia({
        audio: false,
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            // height: '100%',
            // width: '100%',
            border: "1px solid blue",
            // width: { min: 270, max: 270 },
            // height: { min: 480, max: 480 },
            facingMode: "environment",
            // aspectRatio: 9/16,
          },
        })
        .then((stream) => {
          videoEle.current.srcObject = stream;
        })
        .catch((err) => {
          setCamNotFound(true);
        });
    }

    // try {
    //     const stream = await navigator.mediaDevices.getUserMedia({
    //         video: true
    //     });

    //     videoEle.current.srcObject = stream;

    // } catch (err) {
    //     console.log(err);
    // }
  };

  useEffect(() => {
    navigator &&
      navigator.permissions &&
      navigator.permissions.query({ name: "camera" }).then((res) => {
        if (res.state !== "granted") setIsSnackbar(true);
      });
    if (open) {
      startCamera();
    }
  }, [open]);

  const stopCam = () => {
    const stream = videoEle.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
  };

  const takeSelfie = async () => {
    // Get the exact size of the video element.
    const width = videoEle.current.videoWidth;
    const height = videoEle.current.videoHeight;

    // get the context object of hidden canvas
    const ctx = canvasEle.current.getContext("2d");

    // Set the canvas to the same dimensions as the video.
    canvasEle.current.width = width;
    canvasEle.current.height = height;

    // Draw the current frame from the video on the canvas.
    ctx.drawImage(videoEle.current, 0, 0, width, height);

    // Get an image dataURL from the canvas.
    const imageDataURL = canvasEle.current.toDataURL("image/png");
    const file = await base64ToFile(
      imageDataURL,
      `Camera-${new Date().getTime()}.png`,
      "image/png"
    );
    // Set the dataURL as source of an image element, showing the captured photo.
    stopCam();
    setImageURL(imageDataURL);
    setImageFile(file);
  };

  const backHandler = () => {
    if (imageURL) {
      !imageURL && stopCam();
      setImageURL("");
      setImageFile("");
      close();
    }
    close();
  };

  const backToCam = () => {
    setImageURL("");
    setImageFile("");
    startCamera();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={backHandler}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={backHandler}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isSnackbar && (
        <Snackbar
          style={{ top: 120 }}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isSnackbar}
          onClose={() => {
            setIsSnackbar(false);
            close();
          }}
          autoHideDuration={3000}
          key={"file-size-validation"}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {t("camera.block.permission")}
          </Alert>
        </Snackbar>
      )}
      {!camNotFound ? (
        <div className={classes.selfie} style={{ height: "100%" }}>
          {imageURL === "" && (
            <div className={classes.cam}>
              <div className={classes.videoContainer}>
                <video
                  resizeMode="none"
                  className={classes.videoPlayer}
                  autoPlay={true}
                  ref={videoEle}
                ></video>
              </div>
              <div>
                <button className={classes.captureBtn} onClick={takeSelfie}>
                  {/* <i class="fa fa-camera" aria-hidden="true"></i> */}
                  {/* <CameraIcon /> */}
                  <img src={"/Content/icon/Ellipse_13.svg"} alt="" />
                </button>
              </div>
            </div>
          )}
          <canvas ref={canvasEle} style={{ display: "none" }}></canvas>
          {imageURL !== "" && (
            <div className={classes.preview}>
              <img
                className={classes.previewImg}
                src={imageURL}
                ref={imageEle}
              />

              <div className={classes.btnContainer}>
                {/* <a href={imageURL} download="selfie.png"
                        className={classes.downloadBtn}>
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </a> */}
                <Button variant="outlined" color="primary" onClick={backToCam}>
                  ReTake
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    // stopCam()
                    setImageURL("");
                    setImageFile("");
                    closeDrawer(imageFile);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.selfieNotFound}>
          <span>{t("camera.notfound.error")}</span>
        </div>
      )}
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  selfie: {
    display: "flex",
    justifyContent: "center",
    height: "100 %",
  },
  selfieNotFound: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  videoContainer: {
    boxSizing: "border-box",
    height: "100%",
    overflow: "hidden",
    // border: '1px solid blue',
    width: "100%",
    "& video": {
      height: "100%",
    },
  },
  videoPlayer: {
    display: "block",
    marginTop: 56,
    position: "fixed",
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
  },
  cam: {
    position: "relative",
    // border: '1px solid yellow',
  },
  preview: {
    position: "relative",
    marginTop: 56,
    // border: '5px solid yellow',
    height: "calc(100% - 60px)",
    width: "100%",
  },
  previewImg: {
    // display: 'block',
    height: "auto",
    width: "100%",
    // border: '1px solid red',
    // objectFit: 'cover'
  },
  captureBtn: {
    position: "absolute",
    bottom: 9,
    display: "block",
    left: "50%",
    transform: "translateX(-50%)",
    background: "none",
    color: "#fff",
    border: "none",
    fontSize: 40,
  },

  btnContainer: {
    display: "flex",
    justifyContent: "space-around",
    position: "absolute",
    bottom: 16,
    left: 0,
    width: "100%",
  },
  backBtn: {
    background: "none",
    color: theme.palette.primary.main,
    border: "none",
    fontSize: 40,
  },
  downloadBtn: {
    background: "none",
    color: theme.palette.primary.main,
    border: "none",
    fontSize: 40,
    marginLeft: 25,
  },
}));
