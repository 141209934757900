import React, { useEffect, useState } from 'react'
import {
    CircularProgress,
    Container,
    Dialog,
    alpha,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import RepairRequestForm from '../Survey/RepairRequestForm';
import { dossiersActions } from '../../_actions/dossiers.actions';
import { useTranslation } from 'react-i18next';
import { generateLocalStorageKey, getLocalStorageItem, setLocalStorageItem } from '../../_helpers';
import { Close } from '@mui/icons-material';
import DataGrid from '../../components/DataGrid';
import { LabelColumn } from '../../components/DataColumns';
import { userAccountRoleConstants, userAccountTypeConstants } from '../../_constants';

const checkBuildingForRole = (role, building) => building.roles && building.roles.includes(role);

export default function AddRepairRequest({ canProjectLevel, isOpenObjectModal, setIsOpenObjectModal, building, repairRequestCreateCallBack, handleCloseNewRepairRequestModal,...props }) {
    const { drawingFiles, drawingFilesLoading, updateLoading } = useSelector(state => state.dossier);
    const { user } = useSelector(state => state.authentication);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({ pages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState(null);
    const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
    const dispatch = useDispatch();
    const { all = [], loading } = useSelector(state => state.buildings);
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedObject, setSelectedObject] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [canListType, setCanListType] = useState(false);
    const [buildings, setBuildings] = useState([]);
    const [isBuyerOrPropertyManager, setIsBuyerOrPropertyManager] = useState(false);
    const [defaultFilter, setDefaultFilter] = useState([...Array(7)].map(a => []));
    const isBuyer = user && user.type === userAccountTypeConstants.buyer;
    const [sortOrder, setSortOrder] = useState({ name: "buildingNoIntern", direction: "asc" })
    const [columnsVisibility, setColumnsVisibility] = useState([
        { name: "buildingId", visible: "excluded" },
        { name: "projectName", visible: true },
        { name: "buildingNoIntern", visible: true },
        { name: "addressText", visible: true },
        { name: "addressModel.postcode", visible: true },
        { name: "addressModel.place", visible: true },
        { name: "buyerRenterName", visible: true },
    ]);

    useEffect(() => {
        const filter = getLocalStorageItem(`AfterCare_Add_Building_Filters`);
        if (filter) setDefaultFilter(filter);
        const localColumnsVisibility = getLocalStorageItem("AfterCare_Add_Building_ColumnsVisibility");
        if (localColumnsVisibility) setColumnsVisibility(localColumnsVisibility);
        else setLocalStorageItem("AfterCare_Add_Building_ColumnsVisibility", columnsVisibility);

        if (!!building) {
            handleSaveObject(building);
        }
    }, []);

    useEffect(() => {
        const filteredBuildings = all.filter(building => {
            const isSiteManagerBuilding = checkBuildingForRole(userAccountRoleConstants.siteManager, building) && !building.isAfterCareStarted;
            const isAftercareEmployeeBuilding = checkBuildingForRole(userAccountRoleConstants.aftercareEmployee, building) && building.isAfterCareStarted;
            const isPropertyManagerBuilding = checkBuildingForRole(userAccountRoleConstants.propertyManager, building);
            return isSiteManagerBuilding || isAftercareEmployeeBuilding || isPropertyManagerBuilding;
        });
        setBuildings(
            filteredBuildings.map(a => ({
                ...a,
                addressText: a.addressModel ? `${a.addressModel.street || ""} ${a.addressModel.houseNo || ""} ${a.addressModel.houseNoAddition || ""}` : "",
            }))
        );
    }, [all]);

    useEffect(() => {
        if (!drawingFilesLoading && isLoading) {
            if (drawingFiles && drawingFiles.length) {
                setSelectedDrawingFiles({
                    ...drawingFiles[0],
                    groupBy: drawingFiles[0].isInternal ? 'Internal' : 'External'
                });
            } else {
                setSelectedDrawingFiles({});
            }
            setTimeout(() => {
                setIsLoading(false);
                setOpenRepairRequestMap(true);
                handleCloseObjectModal();
            }, 100)
        }
    }, [isLoading, drawingFilesLoading, drawingFiles]);

    const handleCloseObjectModal = () => {
        setIsOpenObjectModal(false);
    }

    const handleSaveObject = (data) => {
        setIsLoading(true);
        setSelectedObject(data)
        setSelected(data)
        setIsBuyerOrPropertyManager(isBuyer || (data.roles && data.roles.length === 1 && data.roles.includes(userAccountRoleConstants.propertyManager)));
        dispatch(dossiersActions.getDrawingFiles(data.projectId, data.buildingId));
    };

    const isColumnVisible = (columnName) => {
        const isSmallScreen = true;
        var column = columnsVisibility.find((x) => x.name === columnName);
        if (column) {
            return column.visible;
        } else if (isSmallScreen) {
            return false;
        } else {
            return true;
        }
    };

    const columns = [
        {
            name: "buildingId",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },

        {
            name: "projectName",
            label: t("general.projectName"),
            options: {
                display: isColumnVisible("projectName"),
                filter: true,
                maxWidth: 120,
                print: false,
                sort: true,
                filterList: defaultFilter ? defaultFilter[1] : [],
                download: false,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData) return "";
                    return <LabelColumn tooltipLabel={rowData.projectName} label={rowData.projectName} />
                },
            },
        },
        {
            name: "buildingNoIntern",
            label: t("general.buildingNumber"),
            options: {
                maxWidth: 120,
                display: isColumnVisible("buildingNoIntern"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[2] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData) return "";
                    return <LabelColumn tooltipLabel={rowData.buildingNoIntern} label={rowData.buildingNoIntern} />
                },
            },
        },
        {
            name: "addressText",
            label: t("general.objectAddress"),
            options: {
                display: isColumnVisible("addressText"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[3] : [],
                sort: true,
                maxWidth: 110,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData) return "";
                    return <LabelColumn tooltipLabel={rowData.addressText} label={rowData.addressText} />
                },
            },
        },
        {
            name: "addressModel.postcode",
            label: t("general.address.postcode"),
            options: {
                display: isColumnVisible("addressModel.postcode"),
                maxWidth: 90,
                filter: true,
                filterList: defaultFilter ? defaultFilter[4] : [],
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData || !rowData.addressModel) return "";
                    return <LabelColumn tooltipLabel={rowData.addressModel.postcode} label={rowData.addressModel.postcode} />
                },
            },
        },
        {
            name: "addressModel.place",
            label: t("general.address.city"),
            options: {
                display: isColumnVisible("addressModel.place"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[5] : [],
                maxWidth: 100,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData || !rowData.addressModel) return "";
                    return <LabelColumn tooltipLabel={rowData.addressModel.place} label={rowData.addressModel.place} />
                },
            },
        },
        {
            name: "buyerRenterName",
            label: t("general.buyer"),
            options: {
                display: isColumnVisible("buyerRenterName"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[6] : [],
                sort: true,
                maxWidth: 100,
                customBodyRenderLite: (rowIndex) => {
                    const rowData = buildings && buildings[rowIndex];
                    if (!rowData) return "";
                    return <LabelColumn tooltipLabel={rowData.buyerRenterName} label={rowData.buyerRenterName} />
                },
            },
        },
    ];

    const options = {
        pagination: true,
        rowsPerPageOptions: [5, 10, 20, 50, 75, 100],
        download: false,
        print: false,
        selectableRows: "none",
        enableNestedDataAccess: ".",
        sortOrder,
        onColumnSortChange: (name, direction) => {
            setSortOrder({ name, direction });
        },
        onViewColumnsChange: (changedColumn, action) => {
            const listToUpdate = Object.assign([], columnsVisibility);
            const column = listToUpdate.find((x) => x.name === changedColumn);
            if (action === "add") {
                if (column) {
                    column.visible = true;
                } else {
                    listToUpdate.push({ name: changedColumn, visible: true });
                }
            } else if (action === "remove") {
                if (column) {
                    column.visible = false;
                } else {
                    listToUpdate.push({ name: changedColumn, visible: false });
                }
            }
            setLocalStorageItem("AfterCare_Add_Building_ColumnsVisibility", listToUpdate);
            setColumnsVisibility(listToUpdate);
        },
        onFilterChange: (identifier, list) => {
            setLocalStorageItem(`AfterCare_Add_Building_Filters`, list);
            setDefaultFilter(list)
        },
        customToolbar: () => {
            return <>
                <IconButton
                    disabled={updateLoading || isLoading}
                    aria-label="more"
                    aria-controls="long-menu"
                    onClick={handleCloseObjectModal}
                    size="large">
                    <Close />
                </IconButton>
            </>;
        },
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            console.log(currentRowsSelected, allRowsSelected, rowsSelected)
        },
        onRowClick: (rowData, rowMeta) => {
            if (!loading) {
                const buildingId = rowData[0];
                const buildingData = buildings && buildings.find((x) => x.buildingId === buildingId);
                if (!!buildingData)
                    handleSaveObject(buildingData);
            }
        }
    }

    return (
        <Container>
            <Dialog
                fullScreen={isMobile}
                classes={{ paper: classes.dialog, paperFullScreen: classes.paperFullScreen }}
                open={!!isOpenObjectModal}
                onClose={updateLoading || isLoading ? () => { } : handleCloseObjectModal}
                aria-labelledby="form-dialog-title"
                maxWidth='lg'
                scroll="paper"
            >
                <Container maxWidth={false} className={classes.mainContainer}>
                    {isOpenObjectModal && <DataGrid
                        canListType={canListType}
                        
                        fixed
                        options={options}
                        identifier="buildingId"
                        localColumnOrdersIdentifier={generateLocalStorageKey("AfterCare_Add_Building_ColumnOrder")}
                        columns={columns}
                        data={loading ? [] : buildings}
                        title={t("general.object.select")}
                        rowsPerPageIdentifier={generateLocalStorageKey("AfterCare_Add_Building_RowsPerPage")}
                        columnsVisibilityIdentifier={generateLocalStorageKey("AfterCare_Add_Building_ColumnsVisibility")}
                        loading={loading}
                    />}

                </Container>
                {(updateLoading || isLoading) && <div className={classes.loaderContainer}>
                    <CircularProgress size={30} />
                </div>}
            </Dialog >
            <RepairRequestForm
                isBuyerOrPropertyManager={isBuyerOrPropertyManager}
                setSelectedDrawingFiles={setSelectedDrawingFiles}
                setCurrentPage={setCurrentPage}
                buildingId={selectedObject && selectedObject.buildingId}
                projectId={selected && selected.projectId}
                selectedProjectId={(selected && selected.projectId) || (selectedObject && selectedObject.projectId)}
                selectedDrawingFiles={selectedDrawingFiles}
                currentPage={currentPage}
                redirectToMomentDetail={console.log}
                onPreview={console.log}
                setID={(id) => console.log(id)}
                canSelectSortManual
                // setIsRepairRequest={(data) => setIsRepairRequest(data)}
                openRepairRequestMap={openRepairRequestMap}
                onClose={(reload) => {
                    reload && repairRequestCreateCallBack();
                    setOpenRepairRequestMap(false)
                    setCurrentPage(1)
                    handleCloseNewRepairRequestModal  && handleCloseNewRepairRequestModal()
                }}
            />
        </Container>
    )
}


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    loaderContainer: {
        display: "flex",
        position: "absolute",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: alpha(theme.palette.common.black, 0.20),
        zIndex: theme.zIndex.drawer
    },
    dialog: {
        width: "60vw",
        height: "100%",
        overflow: "hidden"
    },
    mainContainer: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        height: "100%"
    },
    paperFullScreen: {
        width: "100% !important"
    },
}));