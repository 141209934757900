
import React  from 'react'
import { ContractDetails } from './Components/ContractDetails';
import {  Container, } from '@mui/material';
import { makeStyles } from '@mui/styles';

const BuyerContract = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth={false} className={classes.mainContainer}>
        <ContractDetails />
      </Container>
    </>
  )
}

export { BuyerContract }



const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));
