import { AppBar, Box, Button,Grid, IconButton,  Stack,  Toolbar, Typography, useTheme } from '@mui/material'
import React, { useMemo, useState } from 'react'
import clsx from "clsx";
import {  ArrowBack,  Visibility } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router';
import { updateContractKeyValue,} from '../../../apis/contractApis';
import Template from './Template';
import Address from './Address';
import General from './General';
import Price from './Price';
import Guarantee from './Guarantee';
import StakeHolders from './StakeHolders';
import IndividualContractDetail from './IndividualContractDetail';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import FilterMenu from '../../DossiersDeadline/components/FilterMenu';
import { useTranslation } from 'react-i18next';
import TimeLine from './TimeLine';
import { ContractStatusKeys, ModeOfSigningKeys } from '../../../_helpers';
import TimelineActionButton from './TimelineActionButton';
import LotSize from './LotSize';
import MethodOfFinance from '../../BuyerContract/Components/MethodOfFinance';
import Proxy from '../../BuyerContract/Components/Proxy';


const ContractDetails = ({ contractDetails, fetchContractDetails , setIsPreviewEnabled, setToast}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { contractId } = useParams();
  const {t  } = useTranslation()


  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDetailsHidden , setIsDetailsHidden] = useState(false)
  const [loadingKey, setLoadingKey] = useState("")
  const [mainFilterData, setMainFilterData] = useState({
    startDate: null,
    endDate: null,
    status: 0,
    is48hoursReminder: false,
    isOverdue: false,
    hasUpdates: false,
  });

  const handleFilterData = (filters) => {
    setMainFilterData(filters);
  };


  const isOrganisation = useMemo(()=> Boolean(contractDetails?.buyerOrganisationId), [contractDetails?.buyerOrganisationId])
  const isTimelineShow = ![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status) && !(contractDetails?.signatureType === ModeOfSigningKeys.PrintAndSign) 

  
  const updateContractDetails = async(key, value, responseKey, forceUpdate = false)=>{
    try{
      if( (value !== (contractDetails?.[responseKey]?.toString() ||"")) || forceUpdate) {
        setLoadingKey(key)
       await updateContractKeyValue([ {
        id: key,
        name: value
      }] , contractId)
      
      }
    }catch(e){
      console.log({e})
    }

    await fetchContractDetails()
    setLoadingKey("")
  }

  
  const isPreviewButtonDisplay = useMemo(()=>{
    return Boolean(contractDetails?.buyer1?.email && (contractDetails?.buyer2 ? contractDetails?.buyer2?.email : true) && contractDetails?.templateId && contractDetails?.seller1?.email && (contractDetails?.seller2 ? contractDetails?.seller2?.email : true))
  
    
  },[contractDetails])

  const isDisabledEditing = useMemo(()=>{
    return ![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status) || ![ModeOfSigningKeys.DigitalSign,ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType)
  },[contractDetails?.status])
  return (
    <>
      <AppBar position="sticky" color="inherit" className={classes.appBar} >
        <FilterMenu
          open={isFilterOpen}
          mainFilterData={mainFilterData}
          onClickAway={() => setIsFilterOpen(false)}
          onClickListItem={() => setIsFilterOpen(false)}
          handleFilterData={handleFilterData}
        />
        <Toolbar variant="dense">
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
              <IconButton
                edge="start"
                aria-label="GoBack"
                color="inherit"
                onClick={() => history.replace("/contract")}
                size="large"
              >
                <ArrowBack />
              </IconButton>
              <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                {t("contract.details.title")}
              </Typography>
            </Stack>
            {!isDisabledEditing && <Box>
                          <Button size='small' disabled={!isPreviewButtonDisplay} variant="contained" startIcon={<Visibility color='white' />} onClick={() => setIsPreviewEnabled(true)}>{contractDetails?.status === 0 ? t("contract.overview.generateContract") : t("contract.overview.openDraft")}</Button>
            </Box>}
            {isTimelineShow && <TimelineActionButton contractDetails={contractDetails} setIsPreviewEnabled={setIsPreviewEnabled} setToast={setToast} fetchContractDetails={fetchContractDetails}/>}
          </Stack>
        </Toolbar>
      </AppBar>
      <Box mb={4}>
    
        <Box py={2}>
       {!isDetailsHidden &&  <Grid container spacing={0}  >
          <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
            <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              
            <General contractDetails={contractDetails} updateContractDetails={updateContractDetails} loadingKey={loadingKey} isDisabledEditing={isDisabledEditing}/>

            <LotSize contractDetails={contractDetails} loadingKey={loadingKey} updateContractDetails={updateContractDetails} isDisabledEditing={isDisabledEditing}/> 

            <Address contractDetails={contractDetails}/> 

            
            <Template contractDetails={contractDetails} updateContractDetails={updateContractDetails} loadingKey={loadingKey} isDisabledEditing={isDisabledEditing}/>
            </Box>
          </Grid>


          <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
            <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              <Price contractDetails={contractDetails}/>
            </Box>

            <Box sx={{ px: 2.5, py: 1.5, mt: 2.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              <Guarantee contractDetails={contractDetails} updateContractDetails={updateContractDetails} loadingKey={loadingKey} isDisabledEditing={isDisabledEditing}/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
            <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              <StakeHolders  contractDetails={contractDetails} isOrganisation={isOrganisation } updateContractDetails={updateContractDetails}  loadingKey={loadingKey}  isDisabledEditing={isDisabledEditing}/>
            </Box>

            <Box sx={{ px: 2.5, py: 1.5 }} mt={2.5} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              <MethodOfFinance updateContractDetails={updateContractDetails} financeCheckBoxs={contractDetails} disabled={isDisabledEditing} />
            </Box>
            {
                contractDetails?.financeOwnFunds &&
                <Box sx={{ px: 2.5, py: 1.5 }} mt={2.5} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
                    <Proxy proxyCheckBoxValue={contractDetails?.powerOfAttorney === true ? "Ja" : (contractDetails?.powerOfAttorney === false ? "Nee" : null)} setProxyCheckBoxValue={v => { updateContractDetails('powerOfAttorney', v === "Ja" ? 'true' : (v === "Nee" ? 'false' : null))}} disabled={isDisabledEditing} />
                </Box>
            }
          </Grid>
          <Grid item xs={12} md={6} lg={3} px={2.5} py={1.5}>
            <Box sx={{ px: 2.5, py: 1.5 }} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={1}>
              <IndividualContractDetail  contractDetails={contractDetails} updateContractDetails={updateContractDetails} loadingKey={loadingKey} isDisabledEditing={isDisabledEditing}/>
            </Box>
          </Grid>
        </Grid>}


        </Box>
    </Box>
    {isTimelineShow && <TimeLine contractDetails={contractDetails}/>}
    </>
  )
}

export { ContractDetails }

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 999,
    padding:"10px 0px",

  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
    height: "100%",
  },
  detailContainer: {
    borderRadius: "4px",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    fontSize: "1.4em",
  },
}));
