import React from "react";
import { connect } from "react-redux";
import {
  Badge,
  Container,
  Typography,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {
  Chat,
  Schedule,
  PriorityHigh,
  AddCircleOutlineOutlined,
  MapOutlined,
  Image,
} from "@mui/icons-material";
import { authHeader, getLocalStorageItem, history, nl2br, setLocalStorageItem, generateLocalStorageKey, formatDate } from "../../_helpers";
import { withTranslation } from "react-i18next";
import { commonActions } from "../../_actions/common.actions";
import AddRepairRequest from "./AddRepairRequest";
import { URLS } from "../../apis/urls";
import DataGrid from "../../components/DataGrid";
import {
  ImageColumn,
  LabelColumn,
  LinkColumn,
  PriorityIconColumn,
  MultiLabelColumn,
} from "../../components/DataColumns";
import RepairRequestCustomRow from "./RepairRequestCustomRow";
import DataGridSetting from "../../components/DataGridSetting";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
  },
  bold: {
    fontWeight: "bold",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  tooltipImageContainer: {
    "& > img": {
      width: "100%",
    },
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        "& button": {
          [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(1.2, 0.5)} !important`,
          },
        },
        "& div:nth-child(1)": {
          flexGrow: 1,
        },
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        alignItems: "center",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
        display: "flex",
        flexGrow: 1,
        justifyContent: "end",
      },
    },
    "& .MuiTableCell-root": {
      "& .MuiButton-root > span div ": {
        textAlign: "start",
      },
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  rotate45Clockwise: {
    transform: "rotate(45deg)",
  },
  rotate45AntiClockwise: {
    transform: "rotate(-45deg)",
  },
  root: {
    background: theme.palette.common.white,
    height: "100%",
    overflow: "auto",
    position: "relative",
  },

  mapIcon: {
    fill: theme.palette.primary.main,
  },
  iconContainer: {
    [theme.breakpoints.up("md")]: {
      minWidth: 24,
    },
  },
  iconsColumnBody: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible'
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
  },
  mapIconContainer: {
    padding: theme.spacing(0.2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.4, 1.4, 1.4, 0),
    },
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 7,
  },
  CustomCheckbox: {
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 2px",
    borderRadius: "6px",
    padding: theme.spacing(0.6, 1.2),
  },
  iconHeader: {
    marginRight: theme.spacing(-4.5),
  },
  noWrapText: {
    whiteSpace: "nowrap",
  },
});

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSize: 20,
      fontSize: 12,
      requests: [],
      columnsVisibility: [
        { name: "isRequiredHandling", visible: true },
        { name: "number", visible: true },
        { name: "date", visible: true },
        { name: "buildingNoIntern", visible: true },
        { name: "location", visible: true },
        { name: "desc", visible: true },
        { name: "textResolvers", visible: true },
        { name: "targetCompletionDate", visible: true },
        { name: "carryOutAsType", visible: true },
        { name: "status", visible: true },
        { name: "drawingPinLocation", visible: true },
        { name: "unreadMessagesCount", visible: true },
        { name: "overdue", visible: "excluded" },
      ],
      isLoading: true,
      checked: true,
      filterType: '',
      canListType: false
    };
    this.getRepairRequestsForPrintAndDownload = this.getRepairRequestsForPrintAndDownload.bind(this);
  }

  componentDidMount() {
    const { selected, location, t } = this.props;
    let { columnsVisibility } = this.state;
    if (location.state && location.state.filter) {
      let filter = [...Array(19)].map((p) => []);
      switch (location.state.filter) {
        case "newRepairRequests": {
          filter = Object.assign(filter, { [16]: [t("repairRequest.status.new")] });
          break;
        }
        case "inProgressRepairRequests": {
          filter = Object.assign(filter, { [16]: [t("general.inProcess")] });
          break;
        }
        case "overdueRepairRequests": {
          filter = Object.assign(filter, {
            [2]: [t("general.overdue")],
          });
          break;
        }
        default: {
          break;
        }
      }
      this.setState({ filterType: location.state });
      const state = { ...this.props.history.location.state };
      delete state.filter;
      history.replace({ ...this.props.history.location, state });
      this.props.dispatch(commonActions.meldingenTableFilter(filter));
      setLocalStorageItem(`CQRepairRequests_Columns_Filters`, filter);
    } else {
      const filter = getLocalStorageItem(`CQRepairRequests_Columns_Filters`);
      filter && this.props.dispatch(commonActions.meldingenTableFilter(filter));
    }

    const localColumnsVisibility = getLocalStorageItem("CQRepairRequests_ColumnsVisibility");
    if (localColumnsVisibility) columnsVisibility = localColumnsVisibility;
    else setLocalStorageItem("CQRepairRequests_ColumnsVisibility", columnsVisibility);

    if (selected) {
      this.UpdateRepairRequests();
    }

    var intervalId = setInterval(this.timer, 10000);
    let canListType = getLocalStorageItem("CQRepairRequests_Grid_Styles");
    canListType = canListType === null ? this.props.isMobileOrTab : canListType;
    const size = getLocalStorageItem("CQRepairRequests_List_Image_Size");
    const fontSizeLocal = getLocalStorageItem("CQRepairRequests_Grid_Font_Size");
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId, columnsVisibility, imageSize: +size || this.state.imageSize, fontSize: +fontSizeLocal || this.state.fontSize, canListType });
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    this.props.dispatch(commonActions.meldingenTableFilter(null));
    clearInterval(this.state.intervalId);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let canListType = getLocalStorageItem("CQRepairRequests_Grid_Styles");
    canListType = canListType === null ? nextProps.isMobileOrTab : canListType;
    this.setState({ canListType });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.props;
    if (
      !prevProps.selected ||
      prevProps.selected.projectId !== selected.projectId ||
      this.props.location.key !== prevProps.location.key
    ) {
      this.UpdateRepairRequests();
    }
  }

  timer = () => {
    //this.UpdateBuildings();
  };

  listToMapRedirectHandler = (_,selectedRequest) => {
    history.push({
      pathname: `/werk/${this.props.selected.projectNo}/${this.props.app === 3
        ? "kwaliteitsborging/meldingen/map"
        : "meldingen/map"
        }`,
      state: {
        selectedRequest,
        requests: this.state.requests,
        buildingId: selectedRequest.buildingId,
      },
    });
  };

  repairRequestCreateCallBack = () => {
    this.UpdateRepairRequests();
  };

  isColumnVisible = (columnName) => {
    const { columnsVisibility } = this.state;
    const isSmallScreen = true; //isWidthDown('sm', width);
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  UpdateRepairRequests() {
    const { t, selected, buildings } = this.props;
    if (selected) {
      this.setState({ isLoading: true, requests: [] });
      const url =
        webApiUrl +
        "api/RepairRequest/GetRepairRequestsByProject/" +
        encodeURI(selected.projectId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          findResponse.forEach((request, index) => {
            request.textResolvers = [];
            request.textResolversNames = [];
      
            if (
              request.completed !== true &&
              request.resolvers &&
              request.resolvers.length > 0
            ) {
              request.textResolvers = request.resolvers.map((x) => x);
              request.textResolversNames = request.resolvers.map((x) => x.name);
              
            }
            var building = buildings.find(
              (x) => x.buildingId === request.buildingId
            );
            // request.buildingNoIntern = building && building.buildingNoIntern;
            // request.buildingNoExtern = building && building.buildingNoExtern;
            const projectBuilding = buildings.find((x) => x.projectId === request.projectId);
            request.buildingNoIntern = building ? building.buildingNoIntern : projectBuilding.projectNo + "-P";
            request.buildingNoExtern = building ? building.buildingNoExtern : projectBuilding.projectNo + "-P";
            request.priorityText =
              request.priority >= 0 && request.priority <= 2
                ? t("repairRequest.priority." + request.priority)
                : "";
          });
          this.setState({ requests: findResponse, isLoading: false });
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }

  handleResizeImage(value) {
    this.setState({ imageSize: +value });
    setLocalStorageItem("CQRepairRequests_List_Image_Size", +value);
  }
  handleResizeFont(value) {
    this.setState({ fontSize: +value });
    setLocalStorageItem("CQRepairRequests_Grid_Font_Size", +value);
  }

  handleChangeGridStyle(val) {
    this.setState({ canListType: val })
    setLocalStorageItem("CQRepairRequests_Grid_Styles", val);
  }

  async getRepairRequestsForPrintAndDownload(tableRef) {
    const { displayData, data: tableData, columns } = tableRef.current.state;
    const data = displayData.map(a => tableData.find(p => p.index === a.dataIndex));
    return data.map(a => {
      const request = this.state.requests.find((x) => x.requestId === a.data[0]);
      return columns.reduce((prev, c, i) => {
        const selectedCellData = a.data[i];
        const obj = { ...prev };
        if (c.name === "isRequiredHandling") {
          const array = [];
          request.isRequiredHandling && array.push({ icon: "PriorityHigh" });
          if (request.overdue) array.push({ icon: "Schedule", color: "error" })
          else if (request.is48HoursReminder) array.push({ icon: "Schedule", color: "warning" })
          obj[c.name] = array;
        }
        else if (c.name.toLowerCase().includes("date")) obj[c.name] = formatDate(new Date(selectedCellData))
        else if (c.name === "textResolvers") obj[c.name] = selectedCellData ? [...new Set(selectedCellData.map((a) => a.name))] : [];
        else obj[c.name] = selectedCellData;
        return obj
      }, {})
    });
  }

  render() {
    const { t, classes,  selected, defaultFilter } = this.props;
    const { requests, columnsVisibility, imageSize, fontSize } = this.state;
    const columns = [
      {
        name: "requestId",
        options: {
          display: "excluded",
          filter: false,
          print: false,
          download: false,
        },
      },
      {
        name: "isRequiredHandling",
        label: " ",
        options: {
          maxWidth: 70,
          display: this.isColumnVisible("isRequiredHandling"),
          filter: true,
          print: true,
          download: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div>
                <PriorityHigh color="secondary" size="small" />
                <Schedule fontSize="small" />
              </div>
            );
          },
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <Typography noWrap className={classes.iconsColumnBody}>
                <div className={classes.iconContainer}>
                  {request.isRequiredHandling === true && <Tooltip
                    title={<Typography>{t("general.actionRequired")}</Typography>}
                  >
                    <PriorityHigh color="secondary" size="small" />
                  </Tooltip>
                  }
                </div>
                <div className={classes.iconContainer}>
                  {request.overdue ? (
                    <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                      <Schedule fontSize="small" color="error" />
                    </Tooltip>
                  ) : request.is48HoursReminder ? (
                    <Tooltip
                      title={<Typography>{t("general.48hours")}</Typography>}
                    >
                      <Schedule fontSize="small" className={classes.warning} />
                    </Tooltip>
                  ) : ""}
                </div>
              </Typography>
            );
          },
          filterList: defaultFilter ? defaultFilter[1] : [],
          filterType: "custom",
          filterOptions: {
            names: [t("general.attention.label")],
            label: t("general.attention.label"),
            logic: (value, filters, row) => {
              if (filters.length && filters.includes(t("general.attention.label"))) return value;
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <div className={classes.CustomCheckbox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!filterList.find(
                            (p) => p[0] === column.filterOptions.label
                          )
                        }
                        color="primary"
                        onChange={(event) => {
                          if (event.nativeEvent.target.checked)
                            filterList[index][0] = column.filterOptions.label;
                          else filterList[index] = [];
                          onChange(filterList[index], index, column);
                        }}
                      />
                    }
                    label={column.filterOptions.label}
                  />
                </div>
              );
            },
          },
        },
      },
      {
        name: "overdue",
        label: " ",
        options: {
          display: "excluded",
          filter: true,
          filterList: defaultFilter ? defaultFilter[2] : [],
          print: false,
          download: false,
          filterType: "custom",
          filterOptions: {
            names: [t("general.overdue")],
            // logic: (value, filters, row) => {
            //     if (filters.length && filters.includes(t('general.overdue')) && value) return false;
            //     return true;
            // },
            label: t("general.overdue"),
            logic: (value, filters, row) => {
              if (
                filters.length &&
                filters.includes(
                  t("general.overdue")
                )
              )
                return value;
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <div className={classes.CustomCheckbox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!filterList.find(
                            (p) => p[0] === column.filterOptions.label
                          )
                        }
                        color="primary"
                        onChange={(event) => {
                          if (event.nativeEvent.target.checked)
                            filterList[index][0] = column.filterOptions.label;
                          else filterList[index] = [];
                          onChange(filterList[index], index, column);
                        }}
                      />
                    }
                    label={column.filterOptions.label}
                  />
                </div>
              );
            },
          },
        },
      },
      {
        name: "firstFileId",
        label: t("general.image"),
        options: {
          display: this.isColumnVisible("firstFileId"),
          filter: false,
          maxWidth: 60,
          sort: false,
          print: true,
          download: false,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            const imageId = request && request.firstFileId 
            return (
              <ImageColumn
                size={imageSize}
                src={imageId ? URLS.GET_ATTACHMENT_THUMBNAIL + imageId : null}
              />
            );
          },
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ textAlign: "center" }}>
                <Image />
              </div>
            );
          },
        },
      },
      {
        name: "number",
        label: t("general.number"),
        options: {
          maxWidth: 80,
          display: this.isColumnVisible("number"),
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <LinkColumn
                className={classes.noWrapText}
                label={request.number}
                to={`/werk/${selected.projectNo}/kwaliteitsborging/${request.requestId}`}
              />
            );
          },
        },
      },
      {
        name: "date",
        label: t("general.date"),
        options: {
          maxWidth: 80,
          display: this.isColumnVisible("date"),
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn date={request.date} />
          },
        },
      },
      {
        name: "buildingNoIntern",
        label: t("general.buildingNumber"),
        options: {
          maxWidth: 120,
          display: this.isColumnVisible("buildingNoIntern"),
          filter: true,
          filterType: "multiselect",
          filterList: defaultFilter ? defaultFilter[6] : [],
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.buildingNoIntern} tooltipLabel={request.buildingNoIntern} />
          },
        },
      },
      {
        name: "addressText",
        label: t("general.objectAddress"),
        options: {
          display: this.isColumnVisible("addressText"),
          filter: false,
          sort: true,
          maxWidth: 110,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.addressText} tooltipLabel={request.addressText} />
          },
        },
      },
      {
        name: "addressPostcode",
        label: t("general.address.postcode"),
        options: {
          display: this.isColumnVisible("addressPostcode"),
          maxWidth: 90,
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.addressPostcode} tooltipLabel={request.addressPostcode} />
          },
        },
      },
      {
        name: "addressPlace",
        label: t("general.address.city"),
        options: {
          display: this.isColumnVisible("addressPlace"),
          filter: false,
          maxWidth: 100,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.addressPlace} tooltipLabel={request.addressPlace} />
          },
        },
      },
      {
        name: "location",
        label: t("general.location"),
        options: {
          display: this.isColumnVisible("location"),
          maxWidth: 150,
          filter: true,
          filterList: defaultFilter ? defaultFilter[10] : [],
          filterType: "multiselect",
          sort: true,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <LabelColumn
                label={request.location}
                Icon={MapOutlined}
                clickable
                tooltipLabel={request.location}
                showIcon={request.drawingPinLocation}
                onClick={(e) => this.listToMapRedirectHandler(e, request)}
              />
            );
          },
        },
      },
      {
        name: "desc",
        label: t("general.description"),
        options: {
          display: this.isColumnVisible("desc"),
          filter: false,
          sort: true,
          maxWidth: 180,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <LabelColumn
                label={request.desc}
                tooltipLabel={nl2br(request.detailDesc)}
              />
            );
          },
        },
      },
      {
        name: "textResolvers",
        label: t("general.resolvers"),
        options: {
          display: this.isColumnVisible("textResolvers"),
          maxWidth: 100,
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <MultiLabelColumn data={request.textResolvers} />
            )
          }
        },
      },
      {
        name: "textResolversNames",
        label: t("general.resolvers"),
        options: {
          display: "excluded",
          maxWidth: 100,
          filter: true,
          filterList: defaultFilter ? defaultFilter[13] : [],
          filterType: "multiselect",
          sort: true,
        },
      },
      {
        name: "targetCompletionDate",
        label: t("repairRequest.targetCompletionDate.shortLabel"),
        options: {
          maxWidth: 110,
          display: this.isColumnVisible("targetCompletionDate"),
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn date={request.targetCompletionDate} />
          },
        },
      },
      {
        name: "carryOutAsType",
        label: t("general.type"),
        options: {
          display: this.isColumnVisible("carryOutAsType"),
          filter: true,
          maxWidth: 100,
          filterList: defaultFilter ? defaultFilter[15] : [],
          filterType: "multiselect",
          sort: true,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.carryOutAsType} tooltipLabel={request.carryOutAsType} />
          },
        },
      },
      {
        name: "status",
        label: t("general.status"),
        options: {
          display: this.isColumnVisible("status"),
          filter: true,
          maxWidth: 105,
          filterList: defaultFilter ? defaultFilter[16] : [],
          filterType: "multiselect",
          sort: true,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return (
              <LabelColumn
                color={
                  request.completed
                    ? "#2DA800"
                    : request.overdue
                      ? "#f00"
                      : "black"
                }
                label={request.status}
                tooltipLabel={request.status}
              />
            );
          },
        },
      },

      {
        name: "priorityText",
        label: t("repairRequest.priority"),
        options: {
          display: "excluded",
          filter: true,
          filterList: defaultFilter ? defaultFilter[17] : [],
        },
      },
      {
        name: "priority",
        label: t("repairRequest.priority.short"),
        options: {
          maxWidth: 50,
          display: this.isColumnVisible("priority"),
          filter: false,
          sort: true,
          customBodyRenderLite: (rowIndex) => {
            const request = requests[rowIndex];
            if (!request) return "";
            return <PriorityIconColumn value={request.priority} />
          },
        },
      },
      {
          name: "unreadMessagesCount",
          label: t("chat.messages"),
          options: {
              display: this.isColumnVisible("unreadMessagesCount"),
              filter: false,
              download: false,
              maxWidth: 60,
              customHeadLabelRender: (columnMeta) => {
                  return <div className={classes.iconHeader}>
                      <Chat fontSize="small" />
                  </div>
              },
              customBodyRenderLite: (rowIndex) => {
                  const request = requests[rowIndex];
                  if (!request?.unreadMessagesCount) return ""
                  return (
                      <Badge badgeContent={request.unreadMessagesCount} color="secondary">
                          <Chat fontSize="small" />
                      </Badge>
                  )
              },
          },
      },
      {
        name: "buyerRenterName",
        label: t("general.buyer"),
        options: {
          display: this.isColumnVisible("buyerRenterName"),
          filter: false,
          sort: true,
          maxWidth: 140,
          customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.buyerRenterName} tooltipLabel={request.buyerRenterName} />
          },
        },
      },
    ];

    const options = {
      download: true,
      print: true,
      sortingList: [
        { label: "general.description", key: "desc", type: "string" },
        { label: "general.location", key: "location", type: "string" },
        { label: "repairRequest.targetCompletionDate.shortLabel", key: "targetCompletionDate", type: "date" },
        { label: "general.type", key: "carryOutAsType", type: "string" },
        { label: "general.status", key: "status", type: "string" },
      ],
      onFilterChange: (_, list) => {
        this.props.dispatch(commonActions.meldingenTableFilter(list));
        setLocalStorageItem(`CQRepairRequests_Columns_Filters`, list);
      },
      customToolbarSelect: (selectedRows) => <></>,
      onViewColumnsChange: (changedColumn, action) => {
        if (action == "add") {
          var listToUpdate = columnsVisibility;

          var column = listToUpdate.find((x) => x.name === changedColumn);
          if (column) {
            column.visible = true;
          } else {
            listToUpdate.push({ name: changedColumn, visible: true });
          }
          this.setState({ columnsVisibility: listToUpdate });
          setLocalStorageItem("CQRepairRequests_ColumnsVisibility", listToUpdate);
        } else if (action == "remove") {
          var listToUpdate = columnsVisibility;

          var column = listToUpdate.find((x) => x.name === changedColumn);
          if (column) {
            column.visible = false;
          } else {
            listToUpdate.push({ name: changedColumn, visible: false });
          }
          this.setState({ columnsVisibility: listToUpdate });
          setLocalStorageItem("CQRepairRequests_ColumnsVisibility", listToUpdate);
        }
      },
      customToolbar: () => {
        return <>
          <Tooltip title={t('repairRequest.createRepairRequest')}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              onClick={() => this.setState({ isOpenObjectModal: true })}
              size="large">
              <AddCircleOutlineOutlined />
            </IconButton>
          </Tooltip>
          <DataGridSetting
            canListType={this.state.canListType}
            imageSize={imageSize}
            fontSize={fontSize}
            onChangeImageSize={(val) => this.handleResizeImage(val)}
            onChangeFontSize={(val) => this.handleResizeFont(val)}
            onChangeGridViewType={(val) => this.handleChangeGridStyle(val)}
          />
        </>;
      },
    };

    return (
      <Container maxWidth={false} className={classes.mainContainer}>
        <DataGrid
          defaultSort={{ name: "number", direction: "desc" }}
          canListType={this.state.canListType}
          CustomRowRender={this.props.isMobileOrTab && !!this.state.canListType ?
              (rest) =>
                  <RepairRequestCustomRow
                      {...rest}
                  />
              :
              null
          }
          options={options}
          identifier="requestId"
          localColumnOrdersIdentifier={generateLocalStorageKey("CQRepairRequests_ColumnOrder")}
          localColumnSortIdentifier={generateLocalStorageKey("CQRepairRequests_ColumnSort")}
          columns={columns}
          data={requests}
          title={t("general.repairRequests")}
          rowsPerPageIdentifier={generateLocalStorageKey("CQRepairRequests_RowsPerPage")}
          columnsVisibilityIdentifier={generateLocalStorageKey("CQRepairRequests_ColumnsVisibility")}
          loading={this.state.isLoading}
          canGoBack={!!this.state.filterType}
          getData={this.getRepairRequestsForPrintAndDownload}
          fontSize={fontSize}
        />
        <AddRepairRequest
          repairRequestCreateCallBack={this.repairRequestCreateCallBack}
          isOpenObjectModal={this.state.isOpenObjectModal}
          setIsOpenObjectModal={(value) => this.setState({ isOpenObjectModal: value })} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, meldingenFilter, app } =
    state;
  const { user } = authentication;
  const { selected, all } = buildings;
  return {
    user,
    app,
    selected,
    buildings: all,
    defaultFilter: meldingenFilter,
  };
}

function WithMediaQuery({ ...rest }) {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  return <ConnectedPage {...rest} isMobileOrTab={mediumScreen} />;
}
const ConnectedPage = connect(mapStateToProps)((withTranslation()(withStyles(styles)(Page))));
export { WithMediaQuery as ConstructionQualityPage };