import {
  alpha,
  Hidden,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ArrowBack, CalendarTodayOutlined, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import clsx from "clsx";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PreviewCalenderContext } from "../CreateSlot/CreateSlot";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 13,
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function PreviewPageToolbar({ onView, date, onNavigate }) {
  const { t } = useTranslation();
  const { activeStep, steps, onBack, handleFilters } = useContext(PreviewCalenderContext);
  const [activeTab, setActiveTab] = useState(0);
  const [view, setView] = useState('day');
  const theme = useTheme();
  const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles()

  useEffect(() => {
    if (matchWidthDownXs) {
      tabsHandleChange('', 0)
    } else {
      tabsHandleChange('', activeTab)
    }
  }, [matchWidthDownXs])
  const tabsHandleChange = (event, newValue) => {
    if (newValue === 0) {
      onView("day");
      setView('day')
    } else if (newValue === 1) {
      onView("week");
      setView('week')
    } else if (newValue === 2) {
      onView("month");
      setView('month')
    } else if (newValue === 3) {
      onView("agenda");
      setView('agenda')
    }
    setActiveTab(newValue);
  };

  const goToPreviousDate = () => {
    let newDate;
    if (view === "month") {
      newDate = moment(date).add(-1, 'month').startOf('month')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('month').format('YYYY-MM-DD')
      })
    } else if (view === "week") {
      newDate = moment(date).add(-1, 'week').startOf('week')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('week').format('YYYY-MM-DD')
      })
    } else {
      newDate = moment(date).add(-1, 'day').startOf('day')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('day').format('YYYY-MM-DD')
      })
    }
    onNavigate("prev", newDate.toDate());
  };

  const goToNextDate = () => {
    let newDate;
    if (view === "month") {
      newDate = moment(date).add(1, 'month').startOf('month');
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('month').format('YYYY-MM-DD')
      })
    } else if (view === "week") {
      newDate = moment(date).add(1, 'week').startOf('week');
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('week').format('YYYY-MM-DD')
      })
    } else {
      newDate = moment(date).add(1, 'day').startOf('day')
      handleFilters({
        startDate: newDate.format('YYYY-MM-DD'),
        endDate: newDate.clone().endOf('day').format('YYYY-MM-DD')
      })
    }
    onNavigate("next", newDate);
  };

  const startOfWeek = moment(date).startOf('week').format("DD MMMM YYYY");
  const endOfWeek = moment(date).endOf('week').format("DD MMMM YYYY");
  const monthLabel = moment(date).format("MMMM");

  const slotDatePickerHandler = (changedDate) => {
    handleFilters({
      startDate: moment(changedDate).format('YYYY-MM-DD'),
      endDate: moment(changedDate).clone().endOf('day').format('YYYY-MM-DD')
    })
    onNavigate("next", changedDate);
  }
  return (
    <>
      <div className={classes.toolbarContainer}>
        <div className={classes.previewToolbarContainer}>
          <div className={clsx(classes.toolbarMonth, classes.toolbarMainContainer)}>
            <IconButton onClick={onBack} className={classes.arrowBackContainer} size="large">
              <ArrowBack className={classes.backIcon} />
            </IconButton>
          </div>
          <div className={clsx(classes.toolbarMonth, classes.toolbarMainContainer)}>
            {activeTab !== 3 && (
              <div className={classes.toolbar_btn_group}>
                <div className={classes.squareButton} onClick={goToPreviousDate}>
                  <ChevronLeft className={classes.chevronIcon} />
                </div>
                <div className={classes.squareButton} onClick={goToNextDate}>
                  <ChevronRight className={classes.chevronIcon} />
                </div>
              </div>
            )}
            {(activeTab === 0 || activeTab === 3) && (
              <>
                {activeTab === 0 ? (
                  <>
                    <DatePicker
                      id="date-picker"
                      value={date ? new Date(date) : null}
                      onChange={(date) => slotDatePickerHandler(new Date(date))}
                      autoOk
                      ampm={false}
                      slotProps={{
                        openPickerIcon: { className: classes.toolbarCalendarIcon },
                        openPickerButton: { edge: "end", size: "large" },
                        textField: {
                          variant: "standard",
                          size: "0",
                          style: { width: "0", marginRight: "48px" },
                        },
                      }}
                      slots={{
                        openPickerButton: IconButton,
                        openPickerIcon: CalendarTodayOutlined,
                      }}
                    />
                  </>
                ) : (
                  <IconButton size="large">
                    <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                  </IconButton>
                )}
              </>
            )}
            {activeTab === 0 && (
              <Typography className={classes.toolbarDay}>
                {moment(date).format("dddd")}
                <span className={classes.toolbarFullDate}>{moment(date).format("D MMMM YYYY")}</span>
              </Typography>
            )}
            {activeTab === 3 && (
              <Typography className={classes.toolbarDay}>
                {moment(date).format("dddd")}
                <span className={classes.toolbarFullDate}>{moment(date).format("D MMMM YYYY")}</span>
              </Typography>
            )}
            {activeTab === 1 && (
              <>
                <IconButton size="large">
                  <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                </IconButton>
                <Typography className={classes.toolbarFullDate}>
                  {startOfWeek} - {endOfWeek}
                </Typography>
              </>
            )}
            {activeTab === 2 && (
              <>
                <IconButton size="large">
                  <CalendarTodayOutlined className={classes.toolbarCalendarIcon} />
                </IconButton>
                <Typography className={classes.toolbarCalendarMonthText}>{monthLabel}</Typography>
              </>
            )}
          </div>
        </div>
        <div className={classes.stepperHiddenTabletContainer}>
          <Stepper activeStep={activeStep} classes={{ root: classes.customStepper }}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </div>
        <div className={clsx(classes.toolbarTabsContainer, classes.toolbarMainContainer)}>
          <StyledTabs value={activeTab} onChange={tabsHandleChange} aria-label="styledTabs">
            <StyledTab label={t("agenda.toolbar.tab.day")} />
            <StyledTab label={t("agenda.toolbar.tab.week")} />
            <StyledTab label={t("agenda.toolbar.tab.months")} />
            <StyledTab label={t("agenda.toolbar.tab.list")} />
          </StyledTabs>
        </div>
        <Hidden smUp>
          <div className={clsx(classes.backIconContainer)}>
            <IconButton size="large">
              <ArrowBack className={classes.backIcon} />
            </IconButton>
          </div>
        </Hidden>
        <div className={classes.stepperHiddenLargeContainer}>
          <div className={classes.stepperSmallDeviceContainer}>
            <Stepper activeStep={activeStep} classes={{ root: classes.customStepper }}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = {}
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </div>
        </div>
      </div>
    </>
  )
}

export default PreviewPageToolbar;

const useStyles = makeStyles((theme) => ({
  toolbarDay: {
    fontSize: 17,
    color: theme.palette.grey[500],
  },
  toolbarFullDate: {
    fontSize: 17,
    color: theme.palette.grey[900],
    fontWeight: 500,
    paddingLeft: theme.spacing(0.5)

  },
  backIconContainer: {
    [theme.breakpoints.only("xs")]: {
      position: 'absolute',
      left: 0
    },
  },
  stepperSmallDeviceContainer: {
    width: "100%",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  stepperHiddenLargeContainer: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'contents'
    }
  },
  stepperHiddenTabletContainer: {
    display: 'contents',
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    },
  },

  previewToolbarContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto'
    },
  },
  toolbarMainContainer: {
    [theme.breakpoints.only("xs")]: {
      display: 'none !important'
    },
  },
  toolbarTabsMainContainer: {
    [theme.breakpoints.only("xs")]: {
      display: 'none'
    },
  },
  toolbarCalendarIcon: {
    width: 18,
    fill: theme.palette.primary.main,
  },
  toolbarCalendarMonthText: {
    fontSize: 17,
    fontWeight: 500,
    color: theme.palette.grey[900],
    alignSelf: 'center',
  },
  toolbar_btn_group: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only("sm")]: {
      marginLeft: theme.spacing(0),
    },
  },
  squareButton: {
    height: 30,
    width: 30,
    minHeight: 30,
    minWidth: 30,
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    margin: theme.spacing(0, 0.5),
    cursor: 'pointer'
  },
  chevronIcon: {
    fill: theme.palette.common.white,
    fontSize: 18
  },
  backIcon: {
    fontSize: 24,
    fill: theme.palette.grey[600]
  },
  customStepper: {
    width: '35%',
    padding: theme.spacing(1.3),
    [theme.breakpoints.only("xs")]: {
      width: '90%',
    },
    [theme.breakpoints.between("sm", 'lg')]: {
      width: "60%"
    }
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    borderBottom: `1px solid ${alpha(theme.palette.grey[600], 0.17)}`,
  },
  toolbarMonth: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      justifyContent: 'flex-end'
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(1.5, 0.7)
      },
    },
  },
  toolbarTabsContainer: {
    borderRadius: 50,
    height: 36,
    background: alpha(theme.palette.primary.main, 0.05),
    "& .Mui-selected": {
      background: theme.palette.primary.main,
      borderRadius: 50,
      margin: theme.spacing(0.9, 1.2),
      fontWeight: '500 !important',
      color: `${theme.palette.common.white} !important`
    },
    "& .MuiTab-root": {
      minHeight: 22,
      padding: 0,
      minWidth: 55,
      fontWeight: 'normal',
      fontSize: 11,
      color: theme.palette.grey[600]
    },

  },
  arrowBackContainer: {
    [theme.breakpoints.only("sm")]: {
      padding: `${theme.spacing(1.5, 0)} !important`
    },
  },
}))
