import {
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button, Grid, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import {
  Close,
  Cancel,
  MapOutlined,
  CheckCircle,
  MoreVert,
  Image,
} from "@mui/icons-material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  formatDate,
  generateLocalStorageKey,
  getLocalStorageItem,
  setLocalStorageItem,
  useCanLockedInspection,
  useInternetStatusDetector,
} from "../../../../_helpers";
import BottomDrawer from "../../components/BottomDrawer";
import clsx from "clsx";
import { alpha } from "@mui/material/styles";
import { surveyActions } from "../../../../_actions";
import RepairRequestInfoModal from "../../components/RepairRequestInfoModal";
import { apps, userAccountTypeConstants } from "../../../../_constants";
import { URLS } from "../../../../apis/urls";
import { RepairRequestDetailModal } from "../../components/RepairRequestDetailModal";
import DataGrid from "../../../../components/DataGrid";
import RepairRequestCustomRow from "../../components/RepairRequestCustomRow";
import {
  ImageColumn,
  LabelColumn,
  MultiLabelColumn,
} from "../../../../components/DataColumns";
import DataGridSetting from "../../../../components/DataGridSetting";
import OfflineFileWrapper from "../../../../components/OfflineFileWrapper";

export default function RepairRequests({
  setOpenRepairRequestMap,
  selectedInspection,
  bottomDrawer,
  bottomDrawerClose,
  loading,
  title,
  keyPath,
  buttonText,
  inspection,
  assignmentDetail,
  wbObjectId,
  data,
  ...props
}) {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const classes = useStyles({ readOnly });
  const [openTooltip, setOpenTooltip] = useState({ element: null, image: "" });
  const [defaultFilter, setDefaultFilter] = useState(null);

  //Redux State
  const app = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.authentication);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { selected } = useSelector((state) => state.buildings);
  const { isCompleteRRLoading, isUpdateReworkLoading, deleteRRLoading } =
    useSelector((state) => state.surveying);

  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [infoType, setInfoType] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsSelected, setRowsSelected] = useState({ data: [], list: [] });
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const canLockedInspection = useCanLockedInspection();
  const isOnline = useInternetStatusDetector();
  const [canListType, setCanListType] = useState(false);
  const [imageSize, setImageSize] = useState(20);
  const [fontSize, setFontSize] = useState(12);
  const matchWidthDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "requestId", visible: "excluded" },
    { name: "location", visible: true },
    { name: "attachments", visible: true },
    { name: "number", visible: true },
    { name: "date", visible: true },
    { name: "desc", visible: true },
    { name: "textResolvers", visible: true },
    { name: "targetCompletionDate", visible: true },
    { name: "carryOutAsType", visible: true },
    { name: "status", visible: true },
    { name: "buyerField", visible: true },
  ]);

  useEffect(() => {
    const columnsVisibilityLocal = getLocalStorageItem(
      "RepairRequest_ColumnsVisibility"
    );
    let gridStyle = getLocalStorageItem("Detail_RepairRequest_Grid_Style");
    gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
    const size = getLocalStorageItem("RepairRequest_Lists_Image_Size");
    setCanListType(gridStyle);
    setImageSize((p) => +size || p);
    const fontSizeLocal = getLocalStorageItem("RepairRequest_Lists_Font_Size");
    setFontSize(+fontSizeLocal || fontSize);
    if (!columnsVisibilityLocal) {
      setLocalStorageItem("RepairRequest_ColumnsVisibility", columnsVisibility);
    } else {
      setColumnsVisibility(columnsVisibilityLocal);
    }
  }, [matchWidthDownSm]);

  useEffect(() => {
    if (user) {
      const localFilters = getLocalStorageItem("RepairRequest_Grid_Filters");
      if (localFilters) setDefaultFilter(localFilters);
    }
  }, [user]);

  useEffect(() => {
    if (selectedRequestId && !deleteRRLoading) {
      setSelectedRequestId(null);
    }
  }, [selectedRequestId, deleteRRLoading]);

  useEffect(() => {
    if (selectedInspection) {
      setReadOnly(
        selectedInspection.secondSignatureDate ||
          canLockedInspection ||
          !selectedInspection.hasRights
      );
    }
  }, [selectedInspection && selectedInspection.status, canLockedInspection]);

  useEffect(() => {
    if (data) {
      setRequests(
        data.map((request) => {
          request.textResolvers = [];
          if (request.resolvers && request.resolvers.length)
            request.textResolvers = request.resolvers.map((x) => x.name);
          return request;
        })
      );
      setRowsSelected((p) => ({
        ...p,
        data: p.data.map((a) => data.find((c) => c.requestId === a.requestId)),
      }));
    }
  }, [data, isCompleteRRLoading, isUpdateReworkLoading, deleteRRLoading]);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTooltipClose = (event) => {
    event.stopPropagation();
    setOpenTooltip({ element: null, image: null });
  };

  const infoModalTogglerHandler = (type, request) => {
    if (rowsSelected.data.length > 1) {
      dispatch(surveyActions.markCompleteRRActions(rowsSelected.data));
    } else {
      !isOpenInfoModal && setAnchorEl(null);
      isOpenInfoModal && setIsOpenDetail(false);
      setInfoType(isOpenInfoModal ? null : type);
      setIsOpenInfoModal((p) => !p);
      request =
        request ||
        (rowsSelected.data[0] &&
          requests.find((a) => a.requestId === rowsSelected.data[0].requestId));
      setSelectedRequest(request);
    }
  };

  const detailDialogOpenHandler = (request) => {
    setIsOpenDetail(!isOpenDetail);
    setSelectedRequest(isOpenDetail ? null : request);
    !isOpenDetail && setRowsSelected((p) => ({ ...p, data: [], list: [] }));
  };
  const detailDialogCloseHandler = () => {
    setSelectedRequest(null);
    setIsOpenDetail(false);
  };

  const inspectionDetailColumns = inspection
    ? [
        {
          name: "location",
          label: t("general.location"),
          options: {
            display: isColumnVisible("location"),
            filter: true,
            filterList: defaultFilter ? defaultFilter[3] : [],
            filterType: "multiselect",
            maxWidth: 100,
            customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return <LabelColumn label={request.location} />;
            },
            setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          },
        },
      ]
    : [];

  const columns = [
    {
      name: "requestId",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
        maxWidth: 100,
      },
    },
    {
      name: "attachments",
      label: t("general.image"),
      options: {
        display: isColumnVisible("attachments"),
        filter: false,
        sort: false,
        print: false,
        download: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          let imageURL = "";
          let attachmentId;
          if (
            request &&
            request.sync === 0 &&
            request.attachments &&
            request.attachments.length
          ) {
            imageURL = `${window.URL.createObjectURL(
              new Blob([request.attachments[0]])
            )}`;
          } else if (
            request &&
            request.sync !== 0 &&
            request.attachments &&
            request.attachments.length
          ) {
            if (inspection) {
              attachmentId = request.attachments[0].attachmentId;
              imageURL = `${URLS.GET_ATTACHMENT_THUMBNAIL}${request.attachments[0].attachmentId}`;
            } else imageURL = `${request.attachments[0].attachmentUrl}`;
          }
          return (
            <OfflineFileWrapper id={attachmentId} url={imageURL}>
              {(url) => <ImageColumn src={url} size={imageSize} />}
            </OfflineFileWrapper>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Image />
            </div>
          );
        },
      },
    },
    {
      name: "number",
      label: t("general.number"),
      options: {
        display: isColumnVisible("number"),
        maxWidth: 100,
        filter: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                detailDialogOpenHandler(request);
              }}
              className={classes.colorPrimary}
            >
              {t(request.number)}
            </Button>
          );
        },
      },
    },
    ...inspectionDetailColumns,
    {
      name: "date",
      label: t("general.date"),
      options: {
        display: isColumnVisible("date"),
        maxWidth: 100,
        filter: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn date={new Date(request.date)} />;
        },
      },
    },
    {
      name: "desc",
      label: t("general.description"),
      options: {
        display: isColumnVisible("desc"),
        filter: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn label={request.desc} tooltipLabel={request.desc} />
          );
        },
      },
    },
    {
      name: "textResolvers",
      label: t("general.resolvers"),
      options: {
        display: isColumnVisible("textResolvers"),
        maxWidth: 150,
        filter: true,
        filterList: defaultFilter ? defaultFilter[6] : [],
        filterType: "multiselect",
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <MultiLabelColumn
              data={request.textResolvers.map((name) => ({ name }))}
            />
          );
        },
      },
    },
    {
      name: "targetCompletionDate",
      label: t("repairRequest.targetCompletionDate.shortLabel"),
      options: {
        display: isColumnVisible("targetCompletionDate"),
        maxWidth: 100,
        filter: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn
              label={
                !request.targetCompletionDate
                  ? "--"
                  : formatDate(new Date(request.targetCompletionDate))
              }
            />
          );
        },
      },
    },
    {
      name: "status",
      label: t("general.status"),
      options: {
        display: isColumnVisible("status"),
        maxWidth: 100,
        filter: true,
        filterList: defaultFilter ? defaultFilter[8] : [],
        filterType: "multiselect",
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <>
              <span
                className={clsx(
                  request.status === "Afgehandeld" && classes.colorSuccess,
                  classes.content
                )}
              >
                {request.status}
              </span>
              <div className={classes.spacer}> {request.status}</div>
              <span>&nbsp;</span>
            </>
          );
        },
      },
    },
    {
      name: "buyerField",
      label: t("repairRequest.buyerStatus"),
      options: {
        display: isColumnVisible("buyerField"),
        sort: false,
        maxWidth: 100,
        print: false,
        filter: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          if (request.completedByBuyer)
            return (
              <Tooltip title={request.status}>
                <CheckCircle className={classes.colorSuccess} />
              </Tooltip>
            );
          else if (request.isRework)
            return (
              <Tooltip title={request.status}>
                <Cancel color={"error"} />
              </Tooltip>
            );
          return "";
        },
      },
    },
  ];

  const redirectToMap = () => {
    history.push({
      pathname:
        user.type !== userAccountTypeConstants.buyer && app !== apps.aftercare
          ? `/werk/${selected.projectNo}/${
              app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"
            }`
          : `/meldingen/map`,
      state: {
        selectedRequest: null,
        buildingId: selectedInspection && selectedInspection.buildingId,
        requests: requests,
      },
    });
  };

  const handleChangeGridStyle = (val) => {
    setCanListType(val);
    setLocalStorageItem("Detail_RepairRequest_Grid_Style", val);
  };

  const handleResizeImage = (value) => {
    setImageSize(+value);
    setLocalStorageItem("RepairRequest_Lists_Image_Size", +value);
  };

  const handleResizeFont = (value) => {
    setFontSize(+value);
    setLocalStorageItem("RepairRequest_Lists_Font_Size", +value);
  };

  function onFilterChange(_, list) {
    setDefaultFilter(list);
    setLocalStorageItem("RepairRequest_Grid_Filters", list);
  }

  const handleRowClick = (selectedRow) => {
    if (selectedRow && isOnline && selectedRow.sync !== 0) {
      switch (app) {
        case apps.constructionQuality: {
          history.push(
            `/werk/${selectedInspection.projectNo}/kwaliteitsborging/opname/melding/${selectedRow.requestId}`
          );
          break;
        }
        case apps.aftercare: {
          history.push(`/nazorg/opname/melding/${selectedRow.requestId}`);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const options = {
    filterType: false,
    selectableRows:
      readOnly ||
      (selectedInspection && !selectedInspection.isSecondSignatureInitiated)
        ? "none"
        : "multiple",
    print: false,
    search: true,
    viewColumns: true,
    filter: true,
    download: false,
    pagination: false,
    selectableRowsHeader:
      !readOnly &&
      selectedInspection &&
      selectedInspection.isSecondSignatureInitiated,
    selectToolbarPlacement: "replace",
    selectableRowsOnClick:
      !readOnly &&
      selectedInspection &&
      selectedInspection.isSecondSignatureInitiated,
    onFilterChange,
    onRowClick: (rowData, rowMeta) => {
      const requestId = rowData[0];
      const selectedRow =
        requests && requests.find((x) => x.requestId === requestId);
      if (!!selectedRow) {
        handleRowClick(selectedRow);
      }
    },
    sortingList: [
      { label: "general.description", key: "desc", type: "string" },
      { label: "general.location", key: "location", type: "string" },
      {
        label: "repairRequest.targetCompletionDate.shortLabel",
        key: "targetCompletionDate",
        type: "date",
      },
      { label: "general.status", key: "status", type: "string" },
    ],
    customToolbarSelect: (selectedRows) => (
      <>
        <IconButton
          disabled={
            (selectedInspection &&
              !selectedInspection.isSecondSignatureInitiated) ||
            canLockedInspection
          }
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.moreButton}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenMenu(e);
          }}
          size="large"
        >
          <MoreVert />
        </IconButton>
      </>
    ),
    customToolbar: () => {
      return (
        <>
          {
            <DataGridSetting
              canListType={canListType}
              imageSize={imageSize}
              onChangeImageSize={(val) => handleResizeImage(val)}
              fontSize={fontSize}
              onChangeFontSize={(val) => handleResizeFont(val)}
              onChangeGridViewType={(val) => handleChangeGridStyle(val)}
            />
          }
        </>
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = Object.assign([], columnsVisibility);

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("RepairRequest_ColumnsVisibility", listToUpdate);
      } else if (action == "remove") {
        var listToUpdate = Object.assign([], columnsVisibility);

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("RepairRequest_ColumnsVisibility", listToUpdate);
      }
    },
  };

  const canShowMapIcon =
    requests && requests.length && requests.some((p) => p.drawingPinLocation);
  const canApproveReject = rowsSelected.data.every(({ requestId }) => {
    const request = requests.find((p) => p.requestId === requestId);
    return (
      request &&
      (!request.resolvers ||
        (request.resolvers &&
          request.resolvers.every(
            (r) =>
              !r.workOrderNumber ||
              (r.workOrderNumber && (r.status === 3 || r.status === 4))
          )))
    );
  });

  return (
    <>
      <Hidden smUp>
        <BottomDrawer
          open={bottomDrawer.open && bottomDrawer.key === "gebrekenlijst"}
          close={bottomDrawerClose}
        >
          <div>
            {title && (
              <Grid xs={12} className={classes.stickyHeader}>
                <div className={classes.drawerHeader}>
                  <Typography
                    className={clsx(
                      classes.surveyMomentCardTitle,
                      classes.headerIconContainer
                    )}
                  >
                    {t(`${title}`)}
                    {!!canShowMapIcon &&
                      drawingFiles &&
                      !!drawingFiles.length && (
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={() => redirectToMap()}
                          size="large"
                        >
                          <MapOutlined className={classes.mapIcon} />
                        </IconButton>
                      )}
                  </Typography>
                  <Close
                    className={classes.drawerIcon}
                    onClick={bottomDrawerClose}
                  />
                </div>
                <Divider />
              </Grid>
            )}

            <DataGrid
              canListType={canListType}
              CustomRowRender={
                matchWidthDownSm && !!canListType
                  ? (rest) => (
                      <RepairRequestCustomRow
                        onRowClick={handleRowClick}
                        {...props}
                        {...rest}
                        canShowCheckBox={
                          !readOnly &&
                          selectedInspection &&
                          selectedInspection.isSecondSignatureInitiated
                        }
                      />
                    )
                  : null
              }
              options={options}
              identifier="requestId"
              onRowSelect={(data) => setRowsSelected(data)}
              localColumnOrdersIdentifier={generateLocalStorageKey(
                "Signature_Meldingen_Table_Order"
              )}
              columns={columns}
              localColumnSortIdentifier={generateLocalStorageKey(
                "Signature_Meldingen_Table_Sort"
              )}
              columnsVisibilityIdentifier={generateLocalStorageKey(
                "RepairRequest_ColumnsVisibility"
              )}
              data={requests}
              loading={loading}
              canGoBack={false}
              fontSize={fontSize}
            />
          </div>
        </BottomDrawer>
      </Hidden>
      <Hidden smDown>
        <DataGrid
          canListType={canListType}
          CustomRowRender={
            matchWidthDownSm && !!canListType
              ? (rest) => (
                  <RepairRequestCustomRow
                    onRowClick={handleRowClick}
                    {...props}
                    {...rest}
                    canShowCheckBox={
                      !readOnly &&
                      selectedInspection &&
                      selectedInspection.isSecondSignatureInitiated
                    }
                  />
                )
              : null
          }
          options={options}
          identifier="requestId"
          onRowSelect={(data) => setRowsSelected(data)}
          localColumnOrdersIdentifier={generateLocalStorageKey(
            "Signature_Meldingen_Table_Order"
          )}
          localColumnSortIdentifier={generateLocalStorageKey(
            "Signature_Meldingen_Table_Sort"
          )}
          columns={columns}
          columnsVisibilityIdentifier={generateLocalStorageKey(
            "RepairRequest_ColumnsVisibility"
          )}
          data={requests}
          title={title}
          className={classes.dataTable}
          IconComponent={() =>
            !!canShowMapIcon &&
            drawingFiles &&
            !!drawingFiles.length && (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => redirectToMap()}
                size="large"
              >
                <MapOutlined className={classes.mapIcon} />
              </IconButton>
            )
          }
          loading={loading}
          canGoBack={false}
          fontSize={fontSize}
        />
      </Hidden>
      <Popover
        open={openTooltip.element && openTooltip.image}
        anchorEl={openTooltip.element}
        onClose={handleTooltipClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <div className={classes.tooltipImageContainer}>
          <img src={openTooltip.image} alt="" />
        </div>
      </Popover>
      {selectedRequest && isOpenDetail && (
        <RepairRequestDetailModal
          selectedInspection={selectedInspection}
          readOnly={
            readOnly ||
            (selectedInspection &&
              !selectedInspection.isSecondSignatureInitiated)
          }
          open={isOpenDetail}
          handleRequest={infoModalTogglerHandler}
          handleClose={detailDialogCloseHandler}
          requestId={selectedRequest.requestId}
        />
      )}
      {isOpenInfoModal && (
        <RepairRequestInfoModal
          canDetailView={null}
          selectedRequest={selectedRequest}
          handleClose={infoModalTogglerHandler}
          open={isOpenInfoModal}
          infoType={infoType}
        />
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        classes={{ root: classes.menuRootContainer }}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={
            (rowsSelected && rowsSelected.list.length > 1) ||
            rowsSelected.data.some(
              ({ completed, isRework }) => !completed && isRework
            ) ||
            !canApproveReject
          }
          onClick={() => infoModalTogglerHandler("reject")}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <Cancel color="error" size="small" />
          </ListItemIcon>
          <Typography>{t("general.button.reject")}</Typography>
        </MenuItem>
        <MenuItem
          disabled={
            rowsSelected.data.some(
              ({ completedByBuyer }) => completedByBuyer
            ) || !canApproveReject
          }
          onClick={() => infoModalTogglerHandler("approve")}
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            <CheckCircle size="small" className={classes.colorSuccess} />
          </ListItemIcon>
          <Typography>{t("general.approve")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  customCheckbox: {
    [theme.breakpoints.down("md")]: {
      "& .MuiSvgIcon-root": {
        height: 14,
      },
    },
  },
  drawerHeader: {
    display: "flex",
    padding: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 7,
  },
  surveyMomentCardTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.common.black,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  drawerIcon: {
    fill: theme.palette.grey[300],
    cursor: "pointer",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 101,
  },
  meldingenTableButton: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
    "& .MuiButton-label": {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
        lineHeight: "0px !important",
      },
    },
  },
  meldingenTableDeleteButton: {
    marginRight: 10,
  },
  customMainHeader: {
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      fontSize: 14,
      padding: 0,
      margin: 0,
      whiteSpace: "nowrap ",
    },
  },
  papers: {
    minWidth: 100,
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("sm")]: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      "&:nth-last-child(1)": {
        borderRight: `none`,
      },
    },
  },
  paperSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 22,
  },
  cardTitle: {
    fontSize: 12,
    color: theme.palette.grey[500],
    whiteSpace: "wrap",
  },
  removePadding: {
    padding: `${theme.spacing(0)} !important`,
  },
  paperContainer: {
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      rowGap: 16,
      gap: 0,
      ["@media screen and (min-width:351px)"]: {
        "& > $papers:nth-child(n)": {
          borderLeft: `2px solid ${theme.palette.grey[300]}`,
          paddingLeft: 10,
        },
        "& > $papers:nth-child(1),$papers:nth-child(4)": {
          border: "none",
        },
      },
      ["@media screen and (max-width:351px)"]: {
        "& > $papers:nth-child(2n)": {
          borderLeft: `2px solid ${theme.palette.grey[300]}`,
          paddingLeft: 10,
        },
      },
    },
  },
  expandableRow: {
    background: theme.palette.common.white,
    boxShadow: "0.6px 1px 3px #ccc",
    display: "table-row",
    position: "relative",
    top: 0,
    borderRadius: 4,
    "& .MuiTableCell-root": {
      "&:first-child": {
        borderBottomLeftRadius: 4,
      },
      "&:last-child": {
        borderBottomRightRadius: 4,
      },
    },
  },
  wrapMomentTitle: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    wordBreak: "break-all" /* number of lines to show */,
    display: "-webkit-box",
    boxOrient: "vertical",
  },
  wrapMomentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  minCellWidth: {
    minWidth: 100,
  },
  customCell: {
    position: "relative",
  },
  customMomentRow: {
    borderRadius: 4,
    "& td:nth-child(1)": {
      [theme.breakpoints.down("md")]: {
        width: "5%",
      },
    },
    "& td:nth-child(-n+6)": {
      [theme.breakpoints.only("xs")]: {
        width: "12%",
      },
    },
    "& td:nth-child(-n+7)": {
      [theme.breakpoints.only("sm")]: {
        width: "10%",
      },
    },
    "& .MuiTableCell-root": {
      "&:first-child": {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : 4),
      },
      "&:last-child": {
        borderTopRightRadius: 4,
        borderBottomRightRadius: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : 4),
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "10px !important",
        padding: theme.spacing(1, 0.4),
      },
      fontSize: "14px !important",
    },
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[100],
    },
    [theme.breakpoints.down("md")]: {
      "&:hover": {
        background: theme.palette.common.white,
      },
      background: theme.palette.common.white,
      boxShadow: ({ isDrawerOpen }) =>
        isDrawerOpen ? "2px 7px 6px #ccc" : "2px 2px 6px #ccc",
    },
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
      "& .MuiButtonBase-root": {
        padding: theme.spacing(1.2),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(0.3, 1.2),
        },
        "&:nth-last-child()": {
          paddingRight: 0,
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: ({ readOnly }) => (readOnly ? "none" : "flex"),
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      padding: theme.spacing(0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: theme.spacing(0),
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 1px",
      marginTop: "auto",
      padding: theme.spacing(0.5, 1.5),
      "& caption": {
        display: "none",
      },
    },
    "& .MuiTableCell-root": {
      [theme.breakpoints.between("xs", "md")]: {
        borderBottom: "transparent",
        paddingBottom: 9,
      },
      "& .datatables-noprint": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      },
      color: theme.palette.grey[700],
      fontWeight: "normal",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 0.5, 1, 0.5),
      },
      padding: theme.spacing(0.7, 0.5, 0.7, 0.5),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
          "& .MuiSvgIcon-root": {
            [theme.breakpoints.down("md")]: {
              fontSize: 14,
            },
          },
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        "& .MuiButtonBase-root": {
          "& .MuiButton-label": {
            color: theme.palette.grey[700],
            fontWeight: "bold",
          },
        },
      },
    },
  },
  cardSubTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.common.black,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginBottom: 4,
  },
  meldingenButton: {
    padding: theme.spacing(0.2),
    borderRadius: 7,
    marginRight: 5,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
    "&:focus": {
      outline: "none !important",
    },
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: 17,
      },
    },
  },
  mainTitle: {
    fontSize: 16,
    color: theme.palette.grey[700],
    fontWeight: "bold",
    marginBottom: 0,
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18,
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  tooltipImageContainer: {
    padding: theme.spacing(1.25),
    "& > img": {
      maxWidth: "200px",
      maxHeight: "200px",
    },
  },
  thumbnailImage: {
    marginLeft: theme.spacing(0),
    width: 35,
    height: 35,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
  },
  colorSuccess: {
    padding: theme.spacing(0.2),
    color: theme.palette.success.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${alpha(theme.palette.success.main, 0.7)} !important`,
    },
  },
  moreButton: {
    padding: theme.spacing(0.3),
    marginRight: 8,
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  menuRootContainer: {
    zIndex: theme.zIndex.appBar,
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    height: 0,
    overflow: "hidden",
  },
}));
