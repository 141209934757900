import React, { useEffect, useState } from 'react'
import { Checkbox, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next';
import CreateDrawingLocationName from './components/CreateDrawingLocationName';
import { getDataTableTextLabels } from '../../../_helpers';
import axios from 'axios';

export default function DrawingLocationsNames() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordIndex, setSelectedRecordIndex] = useState(null);
  const [drawingLocationsNames, setDrawingLocationsNames] = useState([])
  const { webApiUrl } = window.appConfig;
  const [loading, setLoading] = useState({ addDrawingLocationNameLoading: false, getDrawingLocation: false, deleteDrawingLocation: false })

  useEffect(() => {
    setLoading(p => ({ ...p, getDrawingLocation: true }))
    const url = webApiUrl + 'api/Dossier/GetDrawingLocationNames';
    axios.get(url)
      .then(response => {
        setDrawingLocationsNames(response.data)
        setLoading(p => ({ ...p, getDrawingLocation: false }))
      })
      .catch(error => {
        setLoading(p => ({ ...p, getDrawingLocation: false }))
      });
  }, [])

  const drawingLocationSaveHandler = (data) => {
    const canSendId = selectedRecord ? { id: selectedRecord.id } : {};
    setLoading(p => ({ ...p, addDrawingLocationNameLoading: true }))
    const url = webApiUrl + 'api/Dossier/AddOrUpdateDrawingLocationName';
    axios.post(url, { ...data, ...canSendId })
      .then(({ data: { viewModel } }) => {
        const records = Object.assign([], drawingLocationsNames);
        const updatedData = selectedRecord ? Object.assign(records, { [selectedRecordIndex]: viewModel }) : [...records, viewModel];
        setDrawingLocationsNames(updatedData)
      })
      .catch(error => {
        setLoading(p => ({ ...p, addDrawingLocationNameLoading: false }))
      });
  }

  const columns = [
    {
      name: 'name',
      label: t('general.name'),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (v, { rowData }) => {
          return (
            <>
              <Typography>{v.toString()}</Typography>
            </>
          )
        }
      }
    },
    {
      name: 'forProject',
      label: t('layout.navbar.project'),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (v, { rowData }) => {
          return (
            <>
              <Checkbox checked={v} color="primary" />
            </>
          )
        }
      }
    },
    {
      name: 'forBuilding',
      label: t('general.object'),
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (v, { rowData }) => {
          return (
            <>
              <Checkbox checked={v} color="primary" />
            </>
          )
        }
      }
    },
  ];

  const options = {
    filterType: 'dropdown',
    draggableColumns: { enabled: true },
    print: true,
    download: true,
    pagination: true,
    onRowsDelete: async ({ data }) => {
      setLoading(p => ({ ...p, deleteDrawingLocation: true }))
      const url = webApiUrl + 'api/Dossier/DeleteDrawingLocationName/';
      for (let i = 0; i < data.length; i++) {
        const currentData = drawingLocationsNames[data[i].dataIndex];
        try {
          await axios.delete(url + currentData.id);
          setDrawingLocationsNames(p => p.filter(l => l.id !== currentData.id));
        } catch (er) {
          console.log(er);
        }
      }
      setLoading(p => ({ ...p, deleteDrawingLocation: false }));
    },
    textLabels: getDataTableTextLabels(t, loading.getDrawingLocation),
    onRowClick: (rowData, { dataIndex }) => {
      const data = drawingLocationsNames[dataIndex]
      setSelectedRecord(data);
      setSelectedRecordIndex(dataIndex);
    },
    customToolbar: () => {
      return (
        <CreateDrawingLocationName
          state={selectedRecord}
          canOpen={selectedRecord}
          onSave={(data) => {
            if (data)
              drawingLocationSaveHandler(data);
            setSelectedRecord(null)
            setSelectedRecordIndex(null)
          }} />
      )
    }
  };

  return (
    <Container maxWidth={false} elevation={0} className={classes.mainContainer}>
      <MUIDataTable
        title={t("drawing.dossier.library.title")}
        data={drawingLocationsNames ? drawingLocationsNames : []}
        className={classes.dataTable}
        columns={columns}
        options={options}
      />
    </Container>
  )
}


const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(0),
    background: theme.palette.common.white
  },
  dataTable: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > .MuiPaper-root': {
      flex: '0 1 auto',
    },
    '& > .MuiToolbar-root.MuiToolbar-regular': {
      display: 'flex',
      '& > div': {
        padding: 0,
        textAlign: 'right',
        flex: '1 0 auto',
        '& .MuiTypography-root': {
          textAlign: 'left'
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(1, 0.5, 1, 0),
      '&.MuiTableCell-paddingCheckbox': {
        paddingLeft: theme.spacing(0.5),
        '& > div': {
          justifyContent: 'center'
        },
        '& .MuiCheckbox-root': {
          padding: theme.spacing(0.25)
        }
      },
      '&.MuiTableCell-head': {
        fontWeight: 'bold'
      }
    },
  },
}))