// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export default function register() {
  if ((process.env.NODE_ENV === 'production') && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin || window.location.pathname.startsWith('/api/')) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }
      const swUrl = `${process.env.PUBLIC_URL}/worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);
      } else {
        // Is not local host. Just register service worker
        registerValidSW(swUrl);
      }
  }
}


function cacheFiles() {
    fetch('asset-manifest.json')
        .then(response => {
            return response.json()
        })
        .then(assets => {
            console.log(assets, "assets");
            // We will cache initial page and the main.js
            // We could also cache assets like CSS and images
            const urlsToCache = [
                '/',
                '/index.html',
                '/api/Config/WebLogo',
                '/api/Config/WebBackground',
                '/config.js',
                '/api/Config/manifest.json',
                '/Content/Images/Flags/en.svg',
                '/Content/Images/Flags/de.svg',
                '/Content/Images/Flags/fr.svg',
                '/Content/Images/Flags/nl-NL.svg',
                '/Content/Images/Background/report-img-placeholder.png',
                '/Content/Translations/en.json',
                '/Content/Translations/de.json',
                '/Content/Translations/fr.json',
                '/Content/Translations/nl-NL.json',
                '/Content/Background/contract-template.png',
                '/Content/Background/House-900x604.svg',
                '/Content/fonts/Roboto-Regular.ttf',
                '/Content/fonts/Roboto-Bold.ttf',
                '/Content/fonts/Roboto-Medium.ttf',
                '/Content/fonts/Roboto-Light.ttf',
                '/Content/icon/complatePin.png',
                '/Content/icon/Ellipse_13.svg',
                '/Content/icon/inprogressPin.png',
                '/Content/icon/ms_excel.svg',
                '/Content/icon/newPin.png',
                '/Content/icon/overduePin.png',
                '/Content/icon/pinLocation.png',
                '/Content/icon/warningPinLocation.png',
                '/Content/icon/santa-dance-christmas-music.gif',
                '/Content/Group_411.png',
                '/Content/Group_533.png',
                '/Content/Lines.png',
                'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js',
                ...Object.values(assets.files).filter(x => !x.toLowerCase().endsWith('.map')),
            ].map(url => fetch(url));
            Promise.all(urlsToCache).then(x => {
                window.serviceWorkerStatus = "ready";
            })
        }).catch(e => {
            console.log('caching-failed', e);
            cacheFiles();
        });
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log('New content is available; please refresh.');
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
          }
          };
        };


      navigator.serviceWorker.ready.then((registration) => {
          registration.active.postMessage("Test message sent immediately after creation",);
          cacheFiles();
          setInterval(() => {
              registration.active.postMessage('test');
          }, 20000);
      });
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.ready.then(registration => {
      return registration.unregister();
    });
  }
}
