import { forwardRef, useState } from 'react'
import { Typography, styled, Box, Button, Divider, ListItem, Dialog, AppBar, IconButton, Slide, Toolbar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close as CloseIcon, Help as HelpIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


const Transition = forwardRef(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[600],
  listStyleType: "disc",
  display: "list-item",
}));

const ImportFileHeader = () => {
  const { t } = useTranslation();
  const [openHelpModal, setOpenHelpModal] = useState(false);

  const handleClickOpenHelpModal = () => {
    setOpenHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setOpenHelpModal(false);
  };

  return (
    <Box display="flex" justifyContent="space-between" mx={3} my={4}>
      <Typography variant='subtitle1'>{t("settings.import.heading")}</Typography>
      <HelpIcon sx={{ color: grey[500] }} onClick={handleClickOpenHelpModal} />


      {/* Dialog for help start */}
      <Dialog
        open={openHelpModal}
        onClose={handleCloseHelpModal}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'sticky' }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" >
              {t("settings.import.instructions.title")}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseHelpModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box overflow="scroll">
          <Box p={2.5}>
            {
              [...Array(10)].map((_, index) => {
                return <StyledListItem key={index}>{t(`settings.import.instruction.${index}`)}</StyledListItem>
              })
            }
          </Box>
          <Divider />

          <Box px={2} my={3}>
            <Button variant='outlined' color='primary' fullWidth onClick={handleCloseHelpModal}>
              {t("general.cancel")}
            </Button>
          </Box>
        </Box>
      </Dialog>
      {/* Dialog for help end */}
    </Box>
  )
}

export default ImportFileHeader