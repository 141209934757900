import React from "react";
import OTPInput from "otp-input-react";
import { Box, Stack, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { nl2br } from "../_helpers"

export const OtpInput = ({ otp, handleOtpChange, resetPasswordFailureCount }) => {
    const { t } = useTranslation();
    return (
        <Box width={'100%'}>
            <OTPInput value={otp} onChange={(e) => { handleOtpChange(e.toString()) }} autoFocus OTPLength={6} otpType="number" secure inputStyles={{ margin: 0, borderRadius: 5, borderColor: blue[700], borderWidth: "1px" }} style={{ width: "100%", justifyContent: "space-around" }} />
            {resetPasswordFailureCount > 0 && <Box width={"100%"} display={'flex'} justifyContent={'start'} mt={0.5}>
                <Typography variant="body2" py={1} px={1.5} color={"error"}>{nl2br(t("login.forgotpassword.wrongOtp", { remainingAttempts: (3 - resetPasswordFailureCount) }))}</Typography>
            </Box>}
        </Box>
    );
};
