import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
 

const ViewAsBuyerError = () => {

  const { t } = useTranslation();

  const handleCloseTab = () => {
    window.close()
  };


  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Card variant='outlined' sx={{ maxWidth: '300px', p: 3, borderRadius: 5, boxShadow: 0.1}}>
        <Stack justifyContent="center" alignItems="center" rowGap={3}>
          <Box p={1} borderRadius="50%" bgcolor="primary.main"><PriorityHighIcon fontSize='large' sx={{ color:  grey[50] }} /></Box>
          <Box><Typography textAlign="center" variant='subtitle2'> {t("viwAsBuyer.error.message")}
 </Typography></Box>
          <Button variant='contained' sx={{  bgcolor:"primary.main"}} fullWidth  onClick={handleCloseTab}>{t("general.back")}</Button>
        </Stack>
      </Card>
    </Stack>
  )
}

export default ViewAsBuyerError