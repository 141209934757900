import React, { useEffect, useState } from 'react'
import { Container, Grid, IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom/';
import DataGrid from '../../../components/DataGrid'
import {
  formatDate,
  generateLocalStorageKey,
  getLocalStorageItem,
  setLocalStorageItem
} from '../../../_helpers'
import { rowsPerPageConstant } from '../../../_constants/grid.constants'
import { useTranslation } from 'react-i18next'
import { DatePicker } from "@mui/x-date-pickers"
import clsx from "clsx";
import { AddCircleOutline, DateRange } from '@mui/icons-material'
import { LabelColumn } from '../../../components/DataColumns'
import moment from 'moment'
import { useSelector } from 'react-redux';
import { getSlotsGenerations, getCategories } from '../../../apis/agendaApis';

function GenerationList() {
  const history = useHistory();
  const { selected } = useSelector(state => state.buildings)
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortData, setSortData] = useState({ name: "endDateTime", direction: "desc" });
  const [generationList, setGenerationList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "startDateTime", visible: true },
    { name: "endDateTime", visible: true },
    { name: "title", visible: true },
    { name: "categoryId", visible: true },
    { name: "description", visible: false },
    { name: "chatMessage", visible: true },
    { name: "objects", visible: true },
    { name: "status", visible: true },
  ]);
  const [defaultFilter, setDefaultFilter] = useState([...Array(8)].map(() => []));

  const getGenerationList = async () => {
      setLoading(true);
      const list = await getSlotsGenerations(selected.projectId)

      if (list && list.data && list.data.length > 0) {
          setGenerationList(list.data.map(x => ({  ...x, status: x.completionDate ? t("general.published") : t("general.draft"), objects:  x.completionDate ?`${x?.confirmedAppointmentsCount}/${x?.buildingIds?.length}` : x?.buildingIds?.length})));
      } else {
          setGenerationList([])
      }
      setLoading(false)
  }


  const getAgendaCategories = async () => {
    const categories = await getCategories();
    setCategories(categories?.data);
  }

  useEffect(() => {
    getGenerationList()
    getAgendaCategories()
  }, []);

  useEffect(() => {
    const localColumnsVisibility = getLocalStorageItem("Agenda_GenerationList_ColumnsVisibility");
    if (localColumnsVisibility) setColumnsVisibility(localColumnsVisibility);
    else setLocalStorageItem("Agenda_GenerationList_ColumnsVisibility", columnsVisibility);

    let rowsPerPageLocal = getLocalStorageItem("Agenda_GenerationList_RowsPerPage");
    if (!rowsPerPageLocal) setLocalStorageItem("Agenda_GenerationList_RowsPerPage", rowsPerPage);
    else setRowsPerPage(rowsPerPageLocal);

    let filterDataLocal = getLocalStorageItem("Agenda_GenerationList_Columns_Filters");
    if (!filterDataLocal) setLocalStorageItem("Agenda_GenerationList_Columns_Filters", defaultFilter);
    else {
      setDefaultFilter(filterDataLocal);
    }
  }, [])

  const isColumnVisible = (columnName) => {
    const column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else return true;
  };
  

  const columns = [
    {
      name: "generationId",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "title",
      label: t("general.subject"),
      options: {
        display: isColumnVisible('title'),
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "startDateTime",
      label: t("general.startDate"),
      options: {
        display: isColumnVisible('startDateTime'),
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return "";
            const dateTimeString = formatDate(new Date(list.startDateTime), true);
            return <LabelColumn tooltipLabel={dateTimeString} label={dateTimeString} />
        },
        filterType: "custom",
        filterOptions: {
          logic: (value, filters, row) => {
                var endDate = row[3];
              if (filters.length && !filters.find(a => {
                return moment(a).isSameOrBefore(endDate, 'day')
              })) return true;
              return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index] && filterList[index].length ? filterList[index][0] || null : null;
              const valueEnd = filterList[index+1] && filterList[index+1].length ? filterList[index+1][0] || null : null;
            return (
              <>
                <div className={clsx(classes.datePickerContainer, !value && classes.shrinkDateLabel)}>
                  <DatePicker
                    variant="standard"
                    format="dd-MM-yyyy"
                    id="start-date-picker"
                    label={t("general.start")}
                    className={classes.customDatePicker}
                    name="startDateTime"
                    value={value ? new Date(value) : value}
                    maxDate={valueEnd?new Date(valueEnd):valueEnd}
                    onChange={(date) => {
                      onChange([new Date(date).toJSON()], index, column)
                    }}
                    autoOk
                    slotProps={{
                      openPickerIcon: { className: classes.inputFieldIcon },
                      openPickerButton: { edge: "start", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "large",
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: DateRange,
                    }}
                  />
                </div>
              </>
            )
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a}>{formatDate(new Date(a))}</span>)
          }
        }
      },
    },
    {
      name: "endDateTime",
      label: t("general.endDate"),
      options: {
        display: isColumnVisible('endDateTime'),
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return "";
            const dateString = formatDate(new Date(list.endDateTime), true);
            return <LabelColumn tooltipLabel={dateString} label={dateString} />
        },
        filterType: "custom",
        filterOptions: {
          logic: (value, filters, row) => {
                var startDate = row[2];
              if (filters.length && !filters.find(a => {
                return moment(a).isSameOrAfter(startDate, 'day')
              })) return true;
              return false;
          },
          display: (filterList, onChange, index, column) => {
              const value = filterList[index] && filterList[index].length ? filterList[index][0] || null : null;
              const valueStart = filterList[index-1] && filterList[index-1].length ? filterList[index-1][0] || null : null;
            return (
              <>
                <div className={clsx(classes.datePickerContainer, !value && classes.shrinkDateLabel)}>
                  <DatePicker
                    disableToolbar
                    id="end-date-picker"
                    label={t("general.end")}
                    format="dd-MM-yyyy"
                    name="startDateTime"
                    value={value ? new Date(value) : value}
                    minDate={valueStart?new Date(valueStart):valueStart}
                    onChange={(date) => {
                      onChange([new Date(date).toJSON()], index, column)
                    }}
                    autoOk
                    ampm={false}
                    slotProps={{
                      openPickerIcon: { className: classes.inputFieldIcon },
                      openPickerButton: { edge: "end", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "large",
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: DateRange,
                    }}
                  />
                </div>
              </>
            )
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a}>{formatDate(new Date(a))}</span>)
          }
        }
      },
    },
    {
      name: "categoryId",
      label: t("general.category"),
      options: {
        display: isColumnVisible('categoryId'),
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return "";
            const category = categories && categories.length > 0 ? categories.find(c => c.id === list.categoryId) : '';
            return <LabelColumn tooltipLabel={category.title} label={category.title} />
        }
      },
    },
    {
      name: "description",
      label: t("general.description"),
      options: {
        display: isColumnVisible('description'),
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return "";
            return <LabelColumn tooltipLabel={list.description} label={list.description} />
        },
      },
    },
    {
      name: "chatMessage",
      label: t("general.message"),
      options: {
        display: isColumnVisible('chatMessage'),
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return "";
            return <LabelColumn tooltipLabel={list.chatMessage} label={list.chatMessage} />
        },
      },
    },
    {
      name: "objects",
      label: t("general.objects"),
      options: {
        display: isColumnVisible('objects'),
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const list = generationList[rowIndex];
            if (!list) return ""; 
            return <LabelColumn label={list.objects} />
      },
      },
    },
    {
      name: "status",
      label: t("general.status"),
      options: {
        display: isColumnVisible('status'),
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false
      },
    },
  ]

 


  const options = {
    rowsPerPage,
    pagination: true,
    rowsPerPageOptions: rowsPerPageConstant,
    serverSide: false,
    download: false,
    print: false,
    sortOrder: sortData,
    selectableRows: "none",
    customToolbar: () => {
      return (
        <IconButton
          onClick={handleAddButtonClick}
          className={classes.meldingenTableButton}
          size="large"
        >
          <AddCircleOutline />
        </IconButton>
      );
    },
    onFilterChange: (identifier, list, type, index) => {
      setDefaultFilter(list)
      setLocalStorageItem(`Agenda_GenerationList_Columns_Filters`, list);
    },
    onTableChange: (action, tableState) => {
      const newRowsPerPage = tableState.rowsPerPage;
      const sortDataLocal = Object.keys(tableState.sortOrder).length ? tableState.sortOrder : null;

      switch (action) {
        case "changeRowsPerPage": {
          setLocalStorageItem('Agenda_GenerationList_RowsPerPage', newRowsPerPage);
          setRowsPerPage(newRowsPerPage)
          break;
        }
        case "sort": {
          setLocalStorageItem('Agenda_SortOrder', sortDataLocal);
          setSortData(sortDataLocal);
          break;
        }
        default: {
          break;
        }
      }
    },
    onViewColumnsChange: (changedColumn, action) => {
      const listToUpdate = Object.assign([], columnsVisibility);
      let column = listToUpdate.find((x) => x.name === changedColumn);
      if (action == "add") {
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("Agenda_GenerationList_ColumnsVisibility", listToUpdate);
      } else if (action == "remove") {
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("Agenda_GenerationList_ColumnsVisibility", listToUpdate);
      }
    },
    onCellClick: (cellData, { colIndex, dataIndex }, _metaData) => {
      const columnName = columns[colIndex].name;
      const generationId = generationList[dataIndex]?.generationId

      if(columnName === "objects" && generationList[dataIndex]?.completionDate) {
        history.push(`/agenda/tijdsloten/${generationId}`)
      } else{
      history.push({ pathname: "/agenda/maak", state: { generationId } })
      }
    },
  }

  const handleAddButtonClick = () => {
    history.push('/agenda/maak')
  }


  return (
    <Container maxWidth={false} className={clsx(classes.mainContainer, classes.activeStepMainContainer)}>
      <Grid item xs={12}>
          <DataGrid
          title={t("agenda.timeSlots")}
          options={options}
          identifier="appointmentId"
          localColumnOrdersIdentifier={generateLocalStorageKey("Agenda_GenerationList_ColumnOrder")}
          localColumnSortIdentifier={generateLocalStorageKey("Agenda_GenerationList_ColumnSort")}
          columns={columns}
          data={generationList}
          rowsPerPageIdentifier={generateLocalStorageKey("Agenda_GenerationList_RowsPerPage")}
          columnsVisibilityIdentifier={generateLocalStorageKey("Agenda_GenerationList_ColumnsVisibility")}
          loading={loading}
          getData={true}
          fontSize={12}
        />  
      </Grid>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    overflow: "auto",
  },
  activeStepMainContainer: {
    padding: theme.spacing(2),
  },
  datePickerContainer: {
    display: "flex",
    "& > div": {
      padding: `${theme.spacing(0)} !important`,
      boxShadow: "none !important",
      width: "100%",
    },
    "& > div > div": {
      height: 35,
      "& input": {
        textOverflow: "ellipsis",
      },
      "& .MuiInputAdornment-root": {
        padding: `${theme.spacing(0)} !important`,
        height: 0
      },
    },
  },
}))

GenerationList.defaultProps = {
  length: 30,
}

GenerationList.range = (start, { length = GenerationList.defaultProps.length, localizer }) => {
  let end = localizer.add(start, length, 'day')
  return { start, end }
}

GenerationList.title = (start, { length = GenerationList.defaultProps.length, localizer }) => {
  let end = localizer.add(start, length, 'day')
  return localizer.format({ start, end }, 'agendaHeaderFormat')
}
export default GenerationList
