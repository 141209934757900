import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

export function SettingsLayout(props) {
    const classes = useStyles();
    return (
        <Container maxWidth={false} className={classes.mainContainer}>
            {props.children}
        </Container >
    )
}

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    bold: {
        fontWeight: "bold"
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: 0
    },
}));