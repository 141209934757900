import React from "react";
import { Drawer } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

export default function BottomDrawer({ customOplosser, open, close, toggler, className, ...props }) {
  const classes = useStyles();

  return (
    <Drawer
      className={clsx(
        classes.drawerContainer,
        className,
        customOplosser && classes.drawerContainerRestyle
      )}
      anchor={"bottom"}
      style={{ zIndex: 1250 }}
      open={open}
      onClose={close}>
      {props.children}
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    "& .MuiDrawer-paperAnchorBottom": {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      maxHeight: "70%",
    },
  },
}));
