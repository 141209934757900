export const apps = {
    huisinfo: 0,
    aftercare: 1,
    constructionQuality: 3,
    resolverModule: 4,
    settingModule: 5,
};

let info = [
    {
        appId: apps.huisinfo,
        nameKey: 'app.name.huisinfo',
        isDifferentNameForBuyer: true,
        icon: process.env.PUBLIC_URL + '/Content/Images/Logos/Apps/country-house.png',
        path: '/home'
    },
    {
        appId: apps.aftercare,
        nameKey: 'app.name.aftercare',
        isDifferentNameForBuyer: false,
        icon: process.env.PUBLIC_URL + '/Content/Images/Logos/Apps/reparation-tools.png',
        path: '/dashboard',
        buyerPath: '/nazorg',
    },
    {
        appId: apps.constructionQuality,
        nameKey: 'app.name.constructionquality',
        isDifferentNameForBuyer: false,
        icon: process.env.PUBLIC_URL + '/Content/Images/Logos/Apps/reparation-tools.png',
        path: '/dashboard'
    },
    {
        appId: apps.resolverModule,
        nameKey: 'app.name.resolver',
        isDifferentNameForBuyer: false,
        icon: process.env.PUBLIC_URL + '/Content/Images/Logos/Apps/reparation-tools.png',
        path: '/werkbonnen'
    },
    {
        appId: apps.settingModule,
        nameKey: 'app.name.settingModule',
        isDifferentNameForBuyer: false,
        icon: process.env.PUBLIC_URL + '/Content/Images/Logos/Apps/settings.png',
        path: '/settings/WB-Werken'
    }
];

export function getLinkToHome(app) {
    var appinfo = info.find(x => x.appId === app);
    if (appinfo)
        return appinfo.path;

    return '/';
}
export const appsInfo = info;
