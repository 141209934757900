import React, { useState } from "react";
import {
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    Grid,
    Icon,
    IconButton,
    Tooltip,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { getNameInitials } from "../../_helpers";
import { Business, Email, ExpandLess, ExpandMore, Home, Phone, Smartphone, Visibility } from "@mui/icons-material";

const { webApiUrl } = window.appConfig;

const useStyles = makeStyles(theme => ({
	contactCard: {
		'& .MuiCardHeader-avatar': {
			'& .MuiBadge-badge': {
				'& .MuiAvatar-root': {
					height: theme.spacing(2),
					width: theme.spacing(2),
					backgroundColor: theme.palette.success.main
				}
			}
		},
		'& .MuiCardHeader-content': {
			overflow: 'hidden'
		},
		'& .MuiCardHeader-action': {
			alignSelf: 'center',
			margin: theme.spacing(-.5, -1, -.5, 0)
		},
		'& .MuiCardContent-root': {
			paddingTop: 0
		}
	},
	infoGridRow: {
		'& > div': {
			padding: theme.spacing(0.5, 2),
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover
		},
		'& .MuiInputLabel-outlined': {
			whiteSpace: 'nowrap',
			maxWidth: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},
}));

export default function DossierContactCard(props) {
	const { object, isVisible, isOrg, subTitle } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);
	const userInitials = getNameInitials(object.name);
	const photoUrl = !isOrg
		?
		webApiUrl + "api/home/GetPersonPhoto/" + object.loginId
		:
		webApiUrl + "api/organisation/GetOrganisationLogo/" + object.organisatonId;
	const relationNameInitials = isOrg ? getNameInitials(object.relationName) : '';
	return (
        <Card className={classes.contactCard} elevation={props.elevation || 1}>
			<CardHeader
				avatar={
					<Badge
						overlap="circular"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}>
						<Avatar alt={userInitials} src={photoUrl}>{userInitials}</Avatar>
					</Badge>}
				action={
					<>
						{
							isVisible &&
							<Tooltip title={t('dossier.visibleToBuyer')}>
								<Visibility fontSize="default" color="action" />
							</Tooltip>
						}
						<Tooltip title={expanded === true ? t('general.collapse') : t('general.expand')}>
							<IconButton aria-label="settings" onClick={() => setExpanded(!expanded)} size="large">
								{
									expanded === true ?
										<ExpandLess />
										:
										<ExpandMore />
								}
							</IconButton>
						</Tooltip>
					</>
				}
				title={object.name}
				titleTypographyProps={{ title: object.name, noWrap: true }}
				subheader={expanded && isOrg ? "" : subTitle}
				subheaderTypographyProps={{ title: expanded && isOrg ? "" : subTitle, noWrap: true }}
			/>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				{
					!isOrg ?
						<CardContent>
							<Grid container>
								{
									object.telephonePrivate &&
									<>
										<Grid item xs={12}>
											<Grid container className={classes.infoGridRow}>
												<Grid item xs={12}>
													<Home />&nbsp;<Button color="primary"
														href={'tel:' + object.telephonePrivate}>{object.telephonePrivate}&nbsp;
														<Phone /></Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</>
								}
								{
									object.mobile &&
									<>
										<Grid item xs={12}>
											<Grid container className={classes.infoGridRow}>
												<Grid item xs={12}>
													<Smartphone />&nbsp;<Button color="primary"
														href={'tel:' + object.mobile}>{object.mobile}&nbsp;
														<Phone /></Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</>
								}
								{
									object.telephoneWork &&
									<>
										<Grid item xs={12}>
											<Grid container className={classes.infoGridRow}>
												<Grid item xs={12}>
													<Business />&nbsp;<Button color="primary"
														href={'tel:' + object.telephoneWork}>{object.telephoneWork}&nbsp;
														<Phone /></Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</>
								}
								{
									object.emailPrivate &&
									<>
										<Grid item xs={12}>
											<Grid container className={classes.infoGridRow}>
												<Grid item xs={12}>
													<Home />&nbsp;<Button color="primary" sx={{ textTransform: 'none' }}
														href={'mailto:' + object.emailPrivate}>{object.emailPrivate}&nbsp;
														<Email /></Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</>
								}
								{
									object.emailWork &&
									<>
										<Grid item xs={12}>
											<Grid container className={classes.infoGridRow}>
												<Grid item xs={12}>
													<Business />&nbsp;<Button color="primary" sx={{ textTransform: 'none' }}
														href={'mailto:' + object.emailWork}>{object.emailWork}&nbsp;
														<Email /></Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Divider />
										</Grid>
									</>
								}
							</Grid>
						</CardContent>
						:
						<>
							<CardContent>
								<Grid container>
									{
										object.telephone &&

										<>
											<Grid item xs={12}>
												<Grid container className={classes.infoGridRow}>
													<Grid item xs={12}>
														<Business />&nbsp;<Button color="primary"
															href={'tel:' + object.telephone}>{object.telephone}&nbsp;
															<Phone /></Button>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Divider />
											</Grid>
										</>
									}
									{
										object.email &&
										<>
											<Grid item xs={12}>
												<Grid container className={classes.infoGridRow}>
													<Grid item xs={12}>
														<Business />&nbsp;<Button color="primary" sx={{ textTransform: 'none' }}
															href={'mailto:' + object.email}>{object.email}&nbsp;
															<Email /></Button>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Divider />
											</Grid>
										</>
									}
								</Grid>
							</CardContent>
							{
								object.relationName &&
								<>
									<CardHeader
										avatar={<Avatar alt={relationNameInitials}
											src={webApiUrl + "api/home/GetPersonPhoto/" + object.relationPersonId}>{relationNameInitials}</Avatar>}
										action={
											object.relationPersonSex === 0 ?
												<Tooltip title={t('general.male')}>
													<Icon color="action" className="fas fa-male" />
												</Tooltip>
												:
												<Tooltip title={t('general.female')}>
													<Icon color="action" className="fas fa-female" />
												</Tooltip>
										}
										title={object.relationName}
										titleTypographyProps={{ title: object.name, noWrap: true }}
										subheader={object.relationFunctionName}
										subheaderTypographyProps={{ title: subTitle, noWrap: true }}
									/>
									<CardContent>
										<Grid container>
											{
												object.relationTelephone &&
												<>
													<Grid item xs={12}>
														<Grid container className={classes.infoGridRow}>
															<Grid item xs={12}>
																<Business />&nbsp;<Button color="primary"
																	href={'tel:' + object.relationTelephone}>{object.relationTelephone}&nbsp;
																	<Phone /></Button>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<Divider />
													</Grid>
												</>
											}
											{
												object.relationMobile &&
												<>
													<Grid item xs={12}>
														<Grid container className={classes.infoGridRow}>
															<Grid item xs={12}>
																<Smartphone />&nbsp;<Button color="primary"
																	href={'tel:' + object.relationMobile}>{object.relationMobile}&nbsp;
																	<Phone /></Button>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<Divider />
													</Grid>
												</>
											}
											{
												object.relationEmail &&
												<>
													<Grid item xs={12}>
														<Grid container className={classes.infoGridRow}>
															<Grid item xs={12}>
																<Business />&nbsp;<Button color="primary" sx={{ textTransform: 'none' }}
																	href={'mailto:' + object.relationEmail}>{object.relationEmail}&nbsp;
																	<Email /></Button>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<Divider />
													</Grid>
												</>
											}
										</Grid>
									</CardContent>
								</>
							}
						</>
				}
			</Collapse>
		</Card>
    );
}
