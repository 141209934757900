import React from "react";
import { connect } from "react-redux";
import { Container, CssBaseline, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import './layout.css';
import { commonActions } from "../_actions";
import { withTranslation } from "react-i18next";
import { getUser, history } from "../_helpers";
import { userAccountTypeConstants, internalLayoutViewConstants, apps } from "../_constants";
import { dossiersActions } from "../_actions/dossiers.actions";
import MenuBar from "./MenuBar";
import HeaderInternal from './HeaderInternal'
import clsx from 'clsx'
import { buyerAndAfterCareRoutes, layoutInternalRoutes } from "../routes";
import { matchPath } from "react-router-dom";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    mainElement: {
        height: "calc(100% - 64px)",
        position: "absolute",
        overflowX: "hidden",
        overflowY: "auto",
        width: "100%",
        paddingLeft: 72,
        ['@media (orientation: portrait)']: {
            paddingBottom: 56,
            paddingLeft: 0
        },
        [theme.breakpoints.down('md')]: {
            height: "calc(100% - 64px)",
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 56,
            paddingLeft: 0,
            height: "calc(100% - 56px)",
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            height: "calc(100% - 48px)"
        }
    },
    LayoutContainer: {
        height: '100%',
        overflow: 'auto'
    },
    errorContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
    },
    card: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        boxShadow: theme.shadows[5],
        borderRadius: 4
    }
})

class Layout extends React.Component {
    state = {
        isOnline: false
    };

    componentDidMount() {
        const { dispatch, user, app, selected } = this.props;
        if (user && user.id && app !== null) {
            dispatch(commonActions.getBuildings(app));
        }
        if (selected) {
            if (user.type === userAccountTypeConstants.buyer) {
                this.props.dispatch(dossiersActions.getDossiersByBuildingId(selected.buildingId, selected.projectId));
            } else if (app !== apps.aftercare) {
                this.props.dispatch(dossiersActions.getAllDossiersByProject(selected.projectId));
            }
            this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
            this.UpdateDashboardCount()
        }
        window.addEventListener("online", () => {
            this.setState({ isOnline: window.navigator.onLine });
            if (selected)
                this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
        });
        window.addEventListener("offline", () => {
            this.setState({ isOnline: window.navigator.onLine });
            if (selected)
                this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
        });
        //Change document title here to customize based on user
        //document.title = "Custom title here";
        // store intervalId in the state so it can be accessed later:
        this.setState({ isOnline: window.navigator.onLine });
        if (selected)
            this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearTimeout(this.state.intervalId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { dashboardCount, dispatch, user, app, selected } = this.props;
        if (user && user.id && app !== null && (!prevProps.user || prevProps.user.id !== user.id || prevProps.app !== app)) {
            dispatch(commonActions.getBuildings(app));
        }
        if (!prevProps.selected || (this.props.selected && prevProps.selected.buildingId !== this.props.selected.buildingId)) {
            if (user.type === userAccountTypeConstants.buyer && selected) {
                this.props.dispatch(dossiersActions.getDossiersByBuildingId(selected.buildingId, selected.projectId));
            } else if (selected && app !== apps.aftercare)
                this.props.dispatch(dossiersActions.getAllDossiersByProject(selected.projectId));
            if (selected)
                this.props.dispatch(commonActions.availableSurveyModules(selected.projectId));
            clearTimeout(this.state.intervalId);
            this.UpdateDashboardCount()
        }
        if (prevProps.dashboardCount.isLoading !== dashboardCount.isLoading && prevProps.dashboardCount.retryAction !== dashboardCount.retryAction && !dashboardCount.isLoading && dashboardCount.retryAction) {
            this.timer()
        }
    }

    timer = () => {
        clearTimeout(this.state.intervalId);
        const intervalId = setTimeout(() => {
            this.UpdateDashboardCount();
            this.CheckLoginStatus();
        }, 10000);
        this.setState({ intervalId });
    }


    CheckLoginStatus() {
        var localUser = getUser();
        if (!localUser) {
            history.push('/login');
        }
    }

    UpdateDashboardCount() {
        const { selected, user, app, dispatch } = this.props;
        if (!!selected && !!user && app != null) {
            dispatch(commonActions.getDashboardCount(app, selected));
        }
    }

    onScroll = () => {
        window.scrollTo(0, 1)
    }

    render() {
        const { t, selected, user, classes, dashboardCount, app } = this.props;
        let layoutView = internalLayoutViewConstants.Project;
        let totalUnreadChats = 0, totalUnreadChatMessages = 0, totalCountSavedMessages = 0;
        const dashboardCountObj = { totalUnreadChats, totalUnreadChatMessages, totalCountSavedMessages };
        const isBuyer = user && user.type === userAccountTypeConstants.buyer;
        const isAfterCareModule = isBuyer || app === apps.aftercare;
        const currentRoute = (isAfterCareModule ? buyerAndAfterCareRoutes : layoutInternalRoutes).find(route => matchPath(this.props.history.location.pathname, route));
        return (
            user ?
                <div id="layout-root" className="layout-root" style={{
                    backgroundImage:
                        (
                            (isBuyer || app !== apps.aftercare) && selected
                                ?
                                'url(' + webApiUrl + 'api/home/ProjectBackground/' + selected.projectId + '), '
                                :
                                ''
                        )
                        + 'url(' + webApiUrl + 'api/Config/WebBackground)'
                }}>
                    <CssBaseline />
                    <HeaderInternal user={user} selectedBuilding={selected} app={app} layoutView={layoutView} dashboardCount={dashboardCount}
                    />
                    <main className={classes.mainElement} onScroll={this.onScroll}>
                        <MenuBar user={user} layoutView={layoutView} selectedBuilding={selected} app={app}
                            history={history} dashboardCount={dashboardCountObj} />
                        {!this.state.isOnline && currentRoute && !currentRoute.accessInOffline ?
                            <Container className={classes.errorContainer}>
                                <Typography className={clsx(classes.bold, classes.card)} noWrap>
                                    {t('sync.network.error')}
                                </Typography>
                            </Container> : <div className={classes.LayoutContainer}>{this.props.children}</div>
                        }
                    </main>
                </div>
                :
                this.props.children
        );
    }
}

function mapStateToProps(state) {
    const { alert, buildings, dashboardCount, app } = state;
    const { selected } = buildings;
    return {
        alert,
        selected,
        dashboardCount,
        app
    };
}

const connectedLayout = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Layout)));
export { connectedLayout as Layout }
