import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = ({ backdrop, ...rest }) => {
    const styles = backdrop && {
        position: 'absolute',
        top: 0, left: 0,
        zIndex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)'
    };
  return (
      <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} style={styles} {...rest}> <CircularProgress /></Box>
  )
}

export default Loader