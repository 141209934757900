import { Card, CardContent, IconButton, Paper, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { nl2br } from '../../../_helpers';


export default function LinkAssignmentModal({ id, open, anchorEl, onClose, data, type, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Popover
        classes={{
          paper: classes.assignmentPopover,
          root: classes.popoverRoot,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        // disableRestoreFocus
        anchorReference={"none"}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
        onClose={onClose}
      >
        <div >
          <Card className={classes.cardContainer}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title}>
                {t(type === 'linkedAssignment' ? "wkb.assignment.mainAssignment" : "wkb.assignment.followUpAssignments")}:
              </Typography>
              <IconButton
                className={classes.closeIcon}
                aria-label="settings"
                onClick={onClose}
                size="large">
                <Close className={classes.closeSVGIcon} />
              </IconButton>
            </div>
            {type === 'linkedAssignment' ?
              <CardContent >
                <Typography className={clsx(classes.assignmentTitle, classes.explanationTitle, classes.assignmentHoverTitle)} gutterBottom>
                  {t(data.controlPointDesc)}
                </Typography>
                <Paper className={classes.papers}>
                  <Typography className={classes.assignmentDetailContent} gutterBottom>
                    {nl2br(data.text)}
                  </Typography>
                </Paper>
              </CardContent>
              :
              <>
                {data && data.map((p) => {
                  return (
                    <CardContent>
                      <Typography className={clsx(classes.assignmentTitle, classes.explanationTitle, classes.assignmentHoverTitle)} gutterBottom>
                        {t(p.controlPointDesc)}
                      </Typography>
                      <Paper className={classes.papers}>
                        <Typography className={classes.assignmentDetailContent} gutterBottom>
                          {nl2br(p.text)}
                        </Typography>
                      </Paper>
                    </CardContent>
                  )
                })}
              </>
            }
          </Card>
        </div>
      </Popover>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  closeIcon: {
    padding: theme.spacing(0.55)
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.grey[800],
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14,
    },
  },
  titleContainer: {
    padding: 10,
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 0,
  },
  closeSVGIcon: {
    fontSize: 17,
    fontWeight: 'bold',
    color: theme.palette.grey[800]
  },
  assignmentPopover: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    width: 700,
    maxWidth: '80%',
    borderRadius: 7,
  },
  popoverRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  assignmentHoverTitle: {
    marginLeft: 0,
  },
  explanationTitle: {
    marginTop: 0,
  },
  cardContainer: {
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.24)0px 3px 8px',
    borderRadius: 4,
    height: 'fit-content',
  },
  papers: {
    minWidth: 150,
    flexGrow: 1,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[200],
    borderRadius: 6,
    boxShadow: 'none',
  },
  assignmentCardContainer: {
    marginBottom: 10
  },
  assignmentTitle: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    width: '100%',
    // minWidth:60,
    maxWidth: '100%',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 12,
    },
    // marginTop: 12,
    // marginBottom: 12,
    color: theme.palette.grey[800],
    fontWeight: 'bold',
  },
  assignmentDetailContent: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
}))