import React from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    border: "0.5px solid " + grey[300],
    fontSize: 18,
    borderRadius: 4,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 24,
      height: 24,
      fontSize: 18,
      padding:"13px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  },
}));

const FilterButton = ({ children, isSelected = false, onClick }) => {
  const classes = useStyles();
  return (
    <Box
      bgcolor={isSelected ? "primary.main" : "inherit"}
      onClick={onClick}
      className={classes.box}
    >
      {children}
    </Box>
  );
};

export default FilterButton;
