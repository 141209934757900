export function getStorageImagesForStandardOption(standardOptionId) {
    var images = JSON.parse(sessionStorage.getItem('SO_' + standardOptionId));
    return !!images ? images : [];
}

export function setStorageImagesForStandardOption(standardOptionId, listImages) {
    sessionStorage.setItem('SO_' + standardOptionId, JSON.stringify(listImages));
}

export function getStorageImagesForIndividualOption(individualOptionId) {
    var images = JSON.parse(sessionStorage.getItem('IO_' + individualOptionId));
    return !!images ? images : [];
}

export function setStorageImagesForIndividualOption(individualOptionId, listImages) {
    sessionStorage.setItem('IO_' + individualOptionId, JSON.stringify(listImages));
}

export function setLocalStorageItem(key, values) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) localStorage.setItem(`${key}_${user.id}`, JSON.stringify(values));
    else localStorage.setItem(key, JSON.stringify(values));
};

export function getLocalStorageItem(key , fallBackValue) {
    try{
        const user = JSON.parse(localStorage.getItem("user"));
        if (user)
            return JSON.parse(localStorage.getItem(`${key}_${user.id}`)) || fallBackValue;
        return JSON.parse(localStorage.getItem(key)) || fallBackValue;
    }catch(e){
        console.log(e)
    }

    return fallBackValue
  
};

export function generateLocalStorageKey(key) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user)
        return `${key}_${user.id}`;
    return key;
};