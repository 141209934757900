import React from "react";
import { Avatar, Checkbox, Hidden, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { formatDate, formatTime, getSurveyTooltipKey, SurveyType } from "../../../_helpers";
import { GetApp, LocationOnOutlined, Lock, PriorityHigh, Schedule, Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../../components/CustomRowStyles";
import { useSelector } from "react-redux";
import { apps } from "../../../_constants";

export default function SurveysListCustomRow(props) {
    const { onRowClick, moduleType, gridDataTitle, setRowsSelected, rowsSelected, columns, data = [], identifier, currentRow, dataIndex } = props;
  const app = useSelector(state => state.app);
  const { t } = useTranslation();
  const { user } = useSelector(state => state.authentication);
  const { assignmentLockIds = [] } = useSelector(state => state.surveying);
  const classes = { ...useRowStyles(), ...useStyles() };
  const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
  const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};
  if (!selectedRow || !columns.length) return "";
  const { isCracked, lockId } = selectedRow;
  const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);
  const canShowAdditionalInfo = app === apps.aftercare && (selectedRow.surveyType === SurveyType.PreDelivery || selectedRow.surveyType === SurveyType.Delivery) && !selectedRow.isSecondSignature

  return (
    <React.Fragment >
        <TableRow onClick={(e) => {
          e.stopPropagation();
          onRowClick(selectedRow)
        }} className={clsx(classes.customGridRow)}>
          <TableCell>
            <div className={classes.customSmallDeviceRowContainer}>
              <div className={classes.avatarIconContainer}>
                <div className={classes.thumbnail}>
                  <Avatar
                    className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception', canShowAdditionalInfo&&'additionalInfo')}>
                    {isNaN(selectedRow.buildingNoExtern) ? selectedRow.buildingNoExtern[0] : selectedRow.buildingNoExtern}
                  </Avatar>
                  <div className={classes.offlineIconContainer}>
                    {lockInfo && lockInfo.userId === user.id && lockId && <Tooltip title={t(getSurveyTooltipKey(), { moduleType })}>
                      <GetApp color='primary' className={classes.offlineIcon} />
                    </Tooltip>}
                    {isCracked && <Tooltip title={t(getSurveyTooltipKey("locked"), { moduleType })}>
                      <Warning color='primary' className={classes.offlineIcon} />
                    </Tooltip>}
                    {!lockInfo && lockId && <Tooltip title={t(getSurveyTooltipKey("broken"), { moduleType })}>
                      <Lock color='primary' className={classes.offlineIcon} />
                    </Tooltip>}
                  </div>
                </div>
              </div>
              <div className={classes.columnContainer}>
                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                  <div>
                    <Typography className={clsx(classes.requestTitle, classes.grow)}>
                      {gridDataTitle}
                    </Typography>
                  </div>
                  <div>
                    <Typography noWrap style={{ display: "flex" }}>
                      {selectedRow.hasUpdate && (
                        <>
                          <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                            <PriorityHigh color="secondary" fontSize="small" size="small" />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      )}
                      {selectedRow.overdue ? (
                        <>
                          <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                            <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : selectedRow.is48HoursReminder ? (
                        <>
                          <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                            <Schedule fontSize="small" size="small" className={clsx(classes.warning, classes.marginRight_5)} />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : ("")}
                      <Checkbox size="small" className={clsx(classes.customCheckbox, classes.noPaddingContainer)} checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                        const list = Object.assign([], rowsSelected.list);
                        const isExist = list.findIndex(p => p === dataIndex);
                        const rowsData = Object.assign([], rowsSelected.data);
                        if (isExist >= 0) {
                          list.splice(isExist, 1)
                          rowsData.splice(isExist, 1)
                        } else {
                          rowsData.push({ [identifier]: selectedRow[identifier] })
                          list.push(dataIndex);
                        };

                        setRowsSelected({ ...rowsSelected, data: rowsData, list });
                      }} color='primary' onClick={(e) => e.stopPropagation()} />
                    </Typography>
                  </div>
                </div>
                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                  {canShowAdditionalInfo && <div>
                    <Typography className={classes.requestTypeLabel}>
                      {`${selectedRow.executorCompanyName || ""} ${selectedRow.executorCompanyName && selectedRow.executedBy ? "-" : ""}  ${selectedRow.executedBy || ""}`}
                    </Typography>
                  </div>}
                  <div className={canShowAdditionalInfo && classes.margin_10}>
                    <Typography className={classes.requestTypeLabel}>
                      {`${selectedRow.repairRequestsCount} ${(selectedRow.repairRequestsCount === 1 ? t("general.repairRequest") : selectedRow.repairRequestsCount === 0  ? t("general.repairRequests").toLowerCase() : t("general.repairRequests"))}`}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      className={classes.requestStatusLabel}
                      style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                      {t(selectedRow.internalStatusPlainText)}
                    </Typography>
                  </div>
                </div>
                          {
                              canShowAdditionalInfo &&
                              <Hidden smUp>
                                  <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                      <div>
                                          <Typography className={classes.requestBuildingLabel}>
                                              <span>{selectedRow.address}</span>
                                          </Typography>
                                      </div>
                                  </div>
                              </Hidden>
                          }

                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                  <div>
                    <Typography className={classes.requestBuildingLabel}>
                      <div className={classes.locationIconContainer}>
                        <LocationOnOutlined className={classes.locationIcon} />
                      </div>
                      <span>
                        {selectedRow.buildingNoIntern}
                      </span>
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.requestBuildingLabel} >
                      {formatDate(new Date(selectedRow.date))} {formatTime(new Date(selectedRow.date), true)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </TableCell>
        </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  descriptionContainer: {
    flexDirection: "row"
  },
  offlineIconContainer: {
    position: "absolute",
    right: 0,
    top: 0
  },
  offlineIcon: {
    fontSize: 14,
    fill: theme.palette.common.white
  },
  avatarThumbnail: {
    background: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 6,
    boxShadow: "0px 0px 3px #eee",
    height:40,
      width:40,
      '&.additionalInfo': {
          height: 75,
          width: 75,
      }
  },
  imageBadgeContainer1: {
    bottom: 0,
    right: 0,
    borderRadius: 0,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 6,
    fontSize: 10,
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  overflowEllipse: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  thumbnail: {
    position: 'relative'
  },
  avatarIconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  customGridRow: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    cursor: "pointer"
  },
  margin_10: {
    marginLeft: 10,
    marginRight: "auto",
  }
}));
