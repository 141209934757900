import React from 'react'
import {  CameraAlt, Cancel, Save } from '@mui/icons-material';
import { Avatar, Box, Button, ButtonBase, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getNameInitials } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import {  grey } from '@mui/material/colors';

const { webApiUrl } = window.appConfig;

const ProfileHeader = ({ data, handleSave, isOrganization = false ,resetData}) => {
  const { user } = useSelector((state) => state.authentication)
  const userInitials = getNameInitials(user.name);

  const { t } = useTranslation();

  const handleProfileImage = (event) => {
    const profileImgFile = event.target.files[0];
  }

  const avatarWidthSx = {
    width: { xs: isOrganization ? 136 : 68, md: isOrganization ? 320 : 160 }
  }

  const avatarHeightSx = {
    height: { xs: 68, md: 160 }
  }

  const avatarBorderRadiusSx = {
    borderRadius: isOrganization ? 0 : '50%'
  }

  return (
    <>
      <Box
        component="img"
        src={`${webApiUrl}api/Config/WebBackground`}
        alt="house-image"
        loading="lazy"
        maxHeight={240}
        width="100%"
        sx={{ objectFit: 'cover' }}
      />
      <Stack justifyContent="center" alignItems='center'>
        <Box width={{ xs: "100%", md: "70%" }} mt={{ xs: -3, md: -4 }} display="flex" alignItems="center" justifyContent="space-between" px={2}>
          <Stack alignItems="center" direction="row">
            <Box>
              {/* NOTE: Ignore comment on this file because that will be used in future when we all0ow user to change photo */}
              {/* <input accept="image/*" id="upload-avatar-pic" type="file" hidden onChange={handleProfileImage}
              /> */}
              <label htmlFor="upload-avatar-pic">
                <ButtonBase component="span" sx={{ position: 'relative', ...avatarWidthSx, ...avatarBorderRadiusSx }}>
                  <Stack
                    sx={{
                      ...avatarWidthSx,
                      ...avatarHeightSx,
                      // position: 'absolute',
                      ...avatarBorderRadiusSx,
                      // zIndex: 100000,
                      justifyContent: 'center', alignItems: 'center',
                      // opacity: 0, backdropFilter: 'blur(2px)', ':hover': { opacity: 1 }
                    }}
                  >
                    <CameraAlt sx={{ color: 'white' }} fontSize="large" />
                  </Stack>
                  <Avatar
                    variant={isOrganization ? 'square' : 'circular'}
                    // src={webApiUrl + "api/home/GetPersonPhoto/" + user.personId}
                    src={data?.imgUrl}
                    sx={{
                      border: isOrganization ? 0 : "4px solid white",
                      ...avatarWidthSx, ...avatarHeightSx,
                      //TODO: use box shado of mui
                      boxShadow: 3,
                      "&" :{
                        "img":{
                          objectFit: isOrganization ? "fill": 'cover'
                        }
                      }
                    }}
                  >
                    {userInitials}
                  </Avatar>
                </ButtonBase>
              </label>
            </Box>
            <Box ml={2} mt={{ xs: 3, md: 2 }}>
              <Typography variant='h6' fontWeight={"600"}>{data?.name}</Typography>
              <Typography color={grey["600"]}>{data?.email}</Typography>
            </Box>
          </Stack>

          <Stack direction="row" columnGap={1} display={{ xs: "flex", md: "none" }}>
            <Box><Cancel /></Box>
            <Box><Save /></Box>
          </Stack>
          <Stack direction="row" columnGap={2} display={{ xs: "none", md: 'flex' }}>
            <Button variant='outlined' onClick={resetData}>{t("general.cancel")}</Button>
            <Button variant='contained' onClick={handleSave}>{t("general.save")}</Button>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default ProfileHeader