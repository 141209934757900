import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  isCompleteAssignment,
  isReadOnly,
  isCompletedSurvey,
  SurveyType,
} from "./enumsHelper";

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const useInternetStatusDetector = () => {
  const [status, setStatus] = useState(window.navigator.onLine);
  useEffect(() => {
    window.addEventListener("online", () => setStatus(window.navigator.onLine));
    window.addEventListener("offline", () =>
      setStatus(window.navigator.onLine)
    );
    return () => {
      window.removeEventListener("offline", () => 0);
      window.removeEventListener("online", () => 0);
    };
  }, []);
  return status;
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [ref, value]);
  return ref.current;
}

export const useCanDeleteFolder = (folderId, buildingId) => {
  const [state, setState] = useState(true);
  const { selectedDossier, selectedDossierBuilding } = useSelector(
    (state) => state.dossier
  );
  useEffect(() => {
    if (
      ((buildingId && selectedDossierBuilding) ||
        (!buildingId && selectedDossier && selectedDossier.isSystem)) &&
      folderId
    ) {
      const selected = buildingId ? selectedDossierBuilding : selectedDossier;
      const canDelete = [
        `internal${buildingId ? "Object" : ""}Files`,
        `external${buildingId ? "Object" : ""}Files`,
      ].reduce((p, key) => {
        const canFindData =
          !selected[key] ||
          Object.keys(selected[key]).reduce((prev, k) => {
            const canFind = selected[key][k].find(
              (p) => p.drawingLocationId === folderId
            );
            return !canFind && !!prev;
          }, true);
        return !!canFindData && !!p;
      }, true);
      setState(canDelete);
    }
  }, [folderId, selectedDossier, buildingId, selectedDossierBuilding]);

  return state;
};

export const useCanCompleteSecondSignature = () => {
  const { selectedInspection } = useSelector((state) => state.surveying);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (
      selectedInspection &&
      selectedInspection.isSecondSignatureInitiated &&
      selectedInspection.secondSignatureDate
    )
      setState(true);
    else setState(false);
  }, [selectedInspection]);

  return state;
};

export const useCanAssignmentIsReadOnly = () => {
  const [state, setState] = useState(false);
  const { selectedAssignment, assignmentLockIds, wbProjectInfo } = useSelector(
    (state) => state.surveying
  );
  const isOnline = useInternetStatusDetector();
  useEffect(() => {
    if (selectedAssignment) {
      const lockInfo =
        assignmentLockIds &&
        assignmentLockIds.find((a) => a.lockId === selectedAssignment.lockId);
      if (
        !selectedAssignment.surveyingMomentDetails.isBuildingMapped ||
        !wbProjectInfo ||
        (wbProjectInfo && wbProjectInfo.archivedAt) ||
        isCompleteAssignment(selectedAssignment.internalStatus) ||
        (!lockInfo && selectedAssignment.lockId)
      ) {
        setState(true);
      } else if (!lockInfo && !isOnline) {
        setState(true);
      } else {
        setState(false);
      }
    }
  }, [selectedAssignment, isOnline, assignmentLockIds, wbProjectInfo]);
  return state;
};


export const useCanLockedInspection = () => {
  const { inspections, selectedInspection, assignmentLockIds } = useSelector(
    (state) => state.surveying
  );
  const [state, setState] = useState(false);
  const isOnline = useInternetStatusDetector();

  useEffect(() => {
    if (!selectedInspection) {
      setState(true);
    } else if (selectedInspection) {
      const lockInfo =
        assignmentLockIds &&
        assignmentLockIds.find(
          (a) =>
            a.lockId === selectedInspection.lockId &&
            a.isSecondSignature === +selectedInspection.isSecondSignature
        );
      const canLocked = !lockInfo && selectedInspection.lockId;
      setState(canLocked || (!lockInfo && !isOnline));
    } else setState(false);
  }, [inspections, selectedInspection, isOnline, assignmentLockIds]);
  return state;
};
export const useCanLockedInspections = (rowsSelected) => {
  const { inspections, assignmentLockIds } = useSelector(
    (state) => state.surveying
  );
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!rowsSelected) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const canLocked =
        !!rowsSelected.data.length &&
        rowsSelected.data.some((s) => {
          const survey = inspections.find((p) => p.surveyId === s.surveyId);
          if (survey) {
            const lockInfo =
              assignmentLockIds &&
              assignmentLockIds.find(
                (a) =>
                  a.lockId === survey.lockId &&
                  a.isSecondSignature === +survey.isSecondSignature
              );
            return !lockInfo && survey.lockId;
          }
          return false;
        });
      setState(canLocked);
    } else setState(false);
  }, [assignmentLockIds, inspections, rowsSelected]);

  return state;
};

export const useIsSurveyCompleted = (rowsSelected) => {
  const { inspections } = useSelector((state) => state.surveying);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!rowsSelected) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const isCompleted =
        !!rowsSelected.data.length &&
        rowsSelected.data.some((s) => {
          const survey = inspections.find((p) => p.surveyId === s.surveyId);
          if (survey) {
            if (survey.isSecondSignature)
              return (
                survey.secondSignatureDate && survey.isSecondSignatureInitiated
              );
            return survey.status === 4 || survey.status === 3;
          }
          return false;
        });
      setState(isCompleted);
    } else setState(false);
  }, [inspections, rowsSelected]);

  return state;
};

export const useCanChangeExecutorForSurveys = (rowsSelected, type) => {
  const { inspections } = useSelector((state) => state.surveying);
  const [state, setState] = useState(false);
  const isOnline = useInternetStatusDetector();

  useEffect(() => {
    if (!rowsSelected || type === SurveyType.Inspection || !isOnline) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const projectId = (
        inspections.find((p) => p.surveyId === rowsSelected.data[0].surveyId) ||
        {}
      ).projectId;
      const canChange = rowsSelected.data.every((s) => {
        const survey = inspections.find((p) => p.surveyId === s.surveyId);
        if (survey) {
          if (survey.isSecondSignature)
            return (
              !survey.secondSignatureDate &&
              !survey.lockId &&
              projectId === survey.projectId
            );
          return (
            !isCompletedSurvey(survey.status) &&
            !survey.lockId &&
            projectId === survey.projectId
          );
        }
        return false;
      });
      setState(canChange);
    } else setState(false);
  }, [inspections, isOnline, rowsSelected, type]);

  return state;
};

export const useCanSyncDownInspections = (rowsSelected) => {
  const { inspections, assignmentLockIds } = useSelector(
    (state) => state.surveying
  );
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!rowsSelected) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const canLocked =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const survey = inspections.find((p) => p.surveyId === s.surveyId);
          if (survey) {
            return (
              !survey.lockId &&
              survey.hasRights &&
              (survey.isSecondSignature
                ? !survey.isSecondSignatureInitiated ||
                  !survey.secondSignatureDate
                : survey.status === 0 || survey.status === 1)
            );
          }
          return false;
        });
      setState(canLocked);
    } else setState(false);
  }, [inspections, assignmentLockIds, rowsSelected]);

  return state;
};

export const useCanSyncUpInspections = (rowsSelected) => {
  const { user } = useSelector((state) => state.authentication);
  const { inspections, assignmentLockIds } = useSelector(
    (state) => state.surveying
  );
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!rowsSelected) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const canSyncUp =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const survey = inspections.find((p) => p.surveyId === s.surveyId);
          if (survey) {
            const lockInfo =
              assignmentLockIds &&
              assignmentLockIds.find(
                (a) =>
                  a.lockId === survey.lockId &&
                  a.isSecondSignature === +survey.isSecondSignature
              );
            return lockInfo && lockInfo.userId === user.id;
          }
          return false;
        });
      setState(canSyncUp);
    } else setState(false);
  }, [inspections, assignmentLockIds, rowsSelected, user.id]);

  return state;
};

export const useCanReleaseInspections = (rowsSelected) => {
  const { inspections } = useSelector((state) => state.surveying);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (!rowsSelected) {
      setState(false);
    } else if (rowsSelected && rowsSelected.data.length) {
      const canLocked =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const survey = inspections.find((p) => p.surveyId === s.surveyId);
          if (survey) return survey.lockId;
          return false;
        });
      setState(canLocked);
    } else setState(false);
  }, [inspections, rowsSelected]);

  return state;
};

export const useSelectedAssignmentInfo = (rowsSelected) => {
  const { user } = useSelector((state) => state.authentication);
  const {
    assignments,
    wbProjectInfo,
    assignmentLockIds,
    isInternalCompleteAssignmentLoading,
    resetAssignmentLoading,
    isCompleteAssignmentLoading,
  } = useSelector((state) => state.surveying);
  const [state, setState] = useState({});

  useEffect(() => {
    if (!rowsSelected) {
      setState({});
    } else if (
      rowsSelected &&
      rowsSelected.data.length &&
      !resetAssignmentLoading &&
      !isInternalCompleteAssignmentLoading &&
      !isCompleteAssignmentLoading
    ) {
      const firstAssignment = assignments.find(
        (assignment) =>
          assignment.assignmentId === rowsSelected.data[0].assignmentId
      );
      const canSyncUp =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            const lockInfo =
              assignmentLockIds &&
              assignmentLockIds.find((a) => a.lockId === assignment.lockId);
            return lockInfo && lockInfo.userId === user.id;
          }
          return false;
        });

      const canSyncDown =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            return (
              !assignment.lockId &&
              (assignment.internalStatus === 0 ||
                assignment.internalStatus === 1)
            );
          }
          return false;
        });

      const isAnyLocked =
        !!rowsSelected.data.length &&
        rowsSelected.data.some((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            const lockInfo =
              assignmentLockIds &&
              assignmentLockIds.find((a) => a.lockId === assignment.lockId);
            return !lockInfo && assignment.lockId;
          }
          return false;
        });

      const canRelease =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            return assignment.lockId;
          }
          return false;
        });

      const canResetStatus =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );

          if (assignment) {
            return (
              assignment.internalStatus === 1 &&
              wbProjectInfo &&
              !wbProjectInfo.archivedAt &&
              assignment.surveyingMomentDetails.surveyingMomentId ===
                firstAssignment.surveyingMomentDetails.surveyingMomentId
            );
          }
          return false;
        });

      const isNotAnyInternalComplete =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            return (
              !isCompleteAssignment(assignment.internalStatus) &&
              wbProjectInfo &&
              !wbProjectInfo.archivedAt &&
              assignment.surveyingMomentDetails.surveyingMomentId ===
                firstAssignment.surveyingMomentDetails.surveyingMomentId
            );
          }
          return false;
        });

      const isAnyCompletedAssignment =
        !!rowsSelected.data.length &&
        rowsSelected.data.some((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            return (
              assignment.internalStatus >= 1 &&
              assignment.surveyingMomentDetails.surveyingMomentId ===
                firstAssignment.surveyingMomentDetails.surveyingMomentId
            );
          }
          return false;
        });

      const isAllAnswerSelected =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            return assignment.answer;
          }
          return false;
        });

      const canSendToWB =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const assignment = assignments.find(
            (assignment) => assignment.assignmentId === s.assignmentId
          );
          if (assignment) {
            const lockInfo =
              assignmentLockIds &&
              assignmentLockIds.find((a) => a.lockId === assignment.lockId);
            return (
              assignment.canSendToWB &&
              !isReadOnly(assignment.internalStatus) &&
              wbProjectInfo &&
              !wbProjectInfo.archivedAt &&
              assignment.surveyingMomentDetails.surveyingMomentId ===
                firstAssignment.surveyingMomentDetails.surveyingMomentId &&
              !lockInfo
            );
          }
          return false;
        });

      setState({
        canSyncUp,
        canSyncDown,
        canRelease,
        canResetStatus,
        canSendToWB,
        isAnyCompletedAssignment,
        isNotAnyInternalComplete,
        isAllAnswerSelected,
        isAnyLocked,
      });
    } else setState({});
  }, [
    assignments,
    wbProjectInfo,
    rowsSelected,
    resetAssignmentLoading,
    isInternalCompleteAssignmentLoading,
    isCompleteAssignmentLoading,
    assignmentLockIds,
    user.id,
  ]);
  return state;
};

export const useCanResetStatus = (rowsSelected) => {
  const {
    selectedMoment,
    resetAssignmentLoading,
    isInternalCompleteAssignmentLoading,
    isCompleteAssignmentLoading,
  } = useSelector((state) => state.surveying);
  const [state, setState] = useState(false);

  useEffect(() => {
    if (
      rowsSelected &&
      rowsSelected.data &&
      rowsSelected.data.length &&
      !resetAssignmentLoading &&
      !isInternalCompleteAssignmentLoading &&
      !isCompleteAssignmentLoading &&
      selectedMoment.projectStatus !== "stopped"
    ) {
      const canResetStatus =
        !!rowsSelected.data.length &&
        rowsSelected.data.every((s) => {
          const controlPoint = selectedMoment.controlPoints.find((p) =>
            p.assignments.find((assignment) => assignment.assignmentId === s)
          );
          if (controlPoint) {
            const index = controlPoint.assignments.findIndex(
              (assignment) => assignment.assignmentId === s
            );
            if (controlPoint && index >= 0)
              return controlPoint.assignments[index].internalStatus === 1;
          }
          return false;
        });

      setState(canResetStatus);
    } else setState(false);
  }, [
    selectedMoment,
    rowsSelected,
    resetAssignmentLoading,
    isInternalCompleteAssignmentLoading,
    isCompleteAssignmentLoading,
  ]);

  return state;
};

export const useCanViewedToWBAttachments = () => {
  const { selectedMoment, selectedAssignment } = useSelector(
    (state) => state.surveying
  );
  const [state, setState] = useState(false);

  useEffect(() => {
    if (selectedAssignment && selectedAssignment.assignmentAttachments) {
      const canViewedToWB = selectedAssignment.assignmentAttachments.some(
        (s) => s.sentToWb === true
      );
      setState(canViewedToWB);
    } else setState(false);
  }, [selectedMoment, selectedAssignment]);

  return state;
};

export const sortAlphaNumerically = (a = "", b = "", type) => {
  if (!a) a = "";
  if (!b) b = "";
  return (type === "asc" ? a : b)
    .toString()
    .localeCompare((type === "asc" ? b : a).toString(), "en", {
      numeric: true,
    });
};

