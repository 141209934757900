import React, { useEffect } from 'react'
import { Dialog } from '@mui/material'

const Christmas = (props) => {
    const { user } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        function checkLocalStorage() {
            if (user) {
                var localStorageValue = localStorage.getItem(`christmas_${user.id}_played`);
                setOpen(!localStorageValue)
            }
        }
        checkLocalStorage()
        const interval = setInterval(() => checkLocalStorage(), 1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    function handleClose() {
        setOpen(false);
        if (user) {
            localStorage.setItem(`christmas_${user.id}_played`, true);
        }
    }


    return (
        <Dialog onClose={handleClose} open={open} maxWidth={720}>
            <div style={{ maxWidth: "100%", width: 720 }}>
                <div style={{ padding: '71.33% 0 0 0', position: 'relative' }}>
                    <iframe src="https://player.vimeo.com/video/896813281?autoplay=1&loop=1&autopause=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="Vrolijk kerstfeest namens JPDS Huisinfo"></iframe>
                </div>
            </div>
        </Dialog>
    )
}

export default Christmas