import { Typography } from "@mui/material";

const EllipsisTypography = ({
  children,
  lineClamp = 1,
  ...rest
}) => {
  return (
    <Typography
      {...rest}
      sx={{
        wordBreak: "break-word",
        display: "-webkit-box",
        WebkitLineClamp: lineClamp,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...rest.sx
      }}
    >
      {children}
    </Typography>
  );
};

export default EllipsisTypography;
