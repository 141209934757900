export const syncsConstants = {
    GET_USER_OBJECTS_ERROR:'GET_USER_OBJECTS_ERROR',
    GET_USER_OBJECTS_SUCCESS:'GET_USER_OBJECTS_SUCCESS',
    GET_DAILY_SYNC_TIME:'GET_DAILY_SYNC_TIME',
    GET_USER_OBJECTS_LOADING:'GET_USER_OBJECTS_LOADING',
    UPDATE_LOG:'UPDATE_LOG',
    UPDATE_LOG_1:'UPDATE_LOG_1',
    SYNC_UP_SUCCESS:'SYNC_UP_SUCCESS',
    RELEASE_LOCK_LOADING:'RELEASE_LOCK_LOADING',
    RELEASE_LOCK_SUCCESS: 'RELEASE_LOCK_SUCCESS',
    OFFLINE_HEADER_ID_IDENTIFIER:"offline_lock_id"
}