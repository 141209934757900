import { useEffect, useState } from 'react'
import { getFile } from "../_helpers";

export default function OfflineFileWrapper({ id, url, ...props }) {
  const [file, setFile] = useState(url);
  useEffect(() => {

    // if (!window.navigator.onLine && id) {
    //   getFile(id).then(data => {
    //     data && setFile(window.URL.createObjectURL(new Blob([data.file])))
    //   }
    //   );
    // } else setFile(url);

    if (!window.navigator.onLine && id) {
      getFile(id).then(async (data) => {
        if (data) {
          let filebase64 = data.file
          setFile(filebase64)
        }
      }
      );
    } else setFile(url);
  }, [id, window.navigator.onLine]);
  return typeof props.children === 'function' ? props.children(file) : props.children;
}
