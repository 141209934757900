import { AccessTime, ArrowDownward, ContentPaste, InsertDriveFile, NotificationsNone } from "@mui/icons-material"
import { Box, Button, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { useState } from "react"
import { cancelContract, sendReminder, setProlong } from "../../../apis/contractApis"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import ConfirmBox from "../../../components/ConfirmBox"
import { sleep } from "../../../_helpers/sleep"

const TimelineActionButton = ({ setToast, setIsPreviewEnabled, contractDetails,fetchContractDetails }) => {
  const contractId = contractDetails?.contractId
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpenInMobile, setIsMenuOpenInMobile] = useState(null);
  const [isOpenConfirmBox, setisOpenConfirmBox] = useState({
    open:false,
    for:undefined
  })

  const [loading, setLoading] = useState({
    reminder: false,
    prolong: false,
    withdraw: false
  })
  const { t } = useTranslation()
  const [prolongDays, setProlongDays] = useState()


  const handleAnchorElOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setIsMenuOpenInMobile(event.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setIsMenuOpenInMobile(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'prolong-poup' : undefined;

  const handleReminder = async () => {
    try {
      setLoading({ ...loading, reminder: true })
      await sendReminder(contractId);
      setToast({ open: true, message: t("contract.details.timeLine.reminder.success"), type: "success" })
      setIsMenuOpenInMobile(null);

    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, reminder: false })

    }
  }

  const handleProlong = async () => {
    try {
      setLoading({ ...loading, prolong: true })
      await setProlong(contractId, prolongDays?.toString());
      setToast({ open: true, message: t("contract.details.timeLine.prolong.success"), type: "success" })
      setAnchorEl(null);
      setIsMenuOpenInMobile(null);

    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, prolong: false })

    }
  }


  const handleWithdraw = async () => {
    try {
      setLoading({ ...loading, withdraw: true })
      setisOpenConfirmBox({open:false,for:undefined})
      await cancelContract(contractId);
      await sleep(5000)
      await fetchContractDetails()
      setToast({ open: true, message: t("contract.details.timeLine.withdraw.success"), type: "success" })
      setIsMenuOpenInMobile(null);
    } catch (e) {
      console.log(e)
      setToast({ open: true, message: t("error.header"), type: "error" })
    } finally {
      setLoading({ ...loading, withdraw: false })
    }
  }

  return (
    <>

      {/* popover - when we click on prolong option in desktop - start */}
      <Popover
        id={id}
        sx={{ mt: 1 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleAnchorElClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box maxWidth={275} p={1.5}>
          <Typography variant="caption">{t("contract.details.timeLine.prolong.popover.title")}</Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            label={t("general.days")}
            type="number"
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e)=>{
              const val = e.target.value
               if(!val){
                setProlongDays("")
               }
               const numberValue = Number(val)
               if(numberValue <= 99 && numberValue>0){
                setProlongDays(numberValue)
               }
             }}
              InputProps={{
              inputProps: { 
                  max: 99, min: 1
              }
             }}
             value={prolongDays?.toString()}
          />
          <Stack direction="row" columnGap={1} mt={2} justifyContent="center">
            <Button variant="outlined" onClick={handleAnchorElClose}>{t("general.cancel")}</Button>
            <LoadingButton loading={loading.prolong} variant="contained" onClick={handleProlong}>{t("general.confirm")}</LoadingButton>
          </Stack>
        </Box>
      </Popover>
      {/* popover - when we click on prolong option in desktop - end */}

      <Stack direction="row" justifyContent='space-between'>
        {/* display menu for mobile screen -  start */}
        <Hidden mdUp>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleMobileMenuOpen} sx={{ p: 0 }}>
              {/* FIXME: Update the icon */}
              <ContentPaste />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={isMenuOpenInMobile}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(isMenuOpenInMobile)}
              onClose={handleMobileMenuClose}
            >
              <MenuItem divider onClick={() => {
                handleMobileMenuClose()
                setIsPreviewEnabled(true)
              }}>
                <ListItemIcon><InsertDriveFile fontSize="small" /></ListItemIcon>
                <Typography>{t("contract.details.timeLine.viewContract")}</Typography>
              </MenuItem>
              {contractDetails?.status === 2 && <> <MenuItem divider onClick={() => {
                setisOpenConfirmBox({ open: true, for: "withdraw"})
              }}>
                <ListItemIcon><ArrowDownward fontSize="small" /></ListItemIcon>
                <Typography>{t("contract.details.timeLine.withdraw")}</Typography>
              </MenuItem>
                <MenuItem divider onClick={() => {
                  handleReminder()
                }}>
                  <ListItemIcon><NotificationsNone fontSize="small" /></ListItemIcon>
                  <Typography>{t("contract.details.timeLine.sendReminder")}</Typography>
                </MenuItem>
                <MenuItem divider onClick={handleAnchorElOpen}>
                  <ListItemIcon><AccessTime fontSize="small" /></ListItemIcon>
                  <Typography>{t("contract.details.timeLine.prolong")}</Typography>
                </MenuItem>
              </>}
            </Menu>
          </Box>
        </Hidden>
        {/* display menu for mobile screen-  end */}

        {/* display buttons for desktop -  start */}
        <Hidden mdDown>
          <Stack direction="row" columnGap={2.5}>
            <Box color={grey[900]}>
              <Tooltip title={t("contract.details.timeLine.viewContract")} arrow>
                <Button variant='outlined' size='small' startIcon={<InsertDriveFile />} color='inherit' onClick={() => setIsPreviewEnabled(true)}>{t("contract.details.timeLine.viewContract")}</Button>
              </Tooltip>
            </Box>
            {contractDetails?.status === 2 && <>
              <Tooltip title={t("contract.details.timeLine.withdraw")} arrow>
                <LoadingButton loading={loading.withdraw} variant='outlined' size='small' startIcon={<ArrowDownward />} color='error' onClick={() => setisOpenConfirmBox({ open: true, for: "withdraw" })}>{t("contract.details.timeLine.withdraw")}</LoadingButton></Tooltip>
              <Tooltip title={t("contract.details.timeLine.sendReminder")} arrow>
                <LoadingButton loading={loading.reminder} variant='outlined' size='small' startIcon={<NotificationsNone />} color='primary' onClick={handleReminder}>{t("contract.details.timeLine.sendReminder")}</LoadingButton></Tooltip>
              <Box color={grey[900]}>
                <Tooltip title={t("contract.details.timeLine.prolong")} arrow>
                  <Button aria-describedby={id} variant='outlined' size='small' startIcon={<AccessTime />} color='inherit' onClick={handleAnchorElOpen}>{t("contract.details.timeLine.prolong")}</Button>
                </Tooltip>
              </Box>
            </>}
          </Stack>
        </Hidden>
        {/* display buttons for desktop -  end */}

        {/* render confirm box DOM only for status = 2 - start */}
        {
          contractDetails?.status === 2 && <ConfirmBox open={isOpenConfirmBox.open} title={t("general.warning")} description={t("contract.details.timeLine.withdraw.warning.description")} okText={t("general.confirm")} cancelText={t("general.cancel")} onOk={handleWithdraw}
            onCancel={() => setisOpenConfirmBox({ open: false, for: undefined })} />
        }
        {/* render confirm box DOM only for status = 2 - end*/}
      </Stack>
    </>
  )
}

export default TimelineActionButton