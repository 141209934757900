import React from "react";
import { Router, Route, Redirect, matchPath, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { ThemeProvider } from "@mui/styles";
import * as Sentry from "@sentry/react"
import { history, getCommonArray, authHeader } from "./_helpers";
import { alertActions, userActions, commonActions } from "./_actions";
import { Layout, LayoutInternal, PageNotFound } from "./_layout";
import { CommonDashboard, WorkOrderStatusUpdatePage } from "./Pages";
import { LoginPage, ForgotPage, ResetPage } from "./LoginPage";
import { LoginRequest } from "./Pages/LoginRequest";
import { userAccountTypeConstants, apps } from "./_constants";
import AutoReload from "./components/AutoReload";
import Christmas from "./components/Christmas";
import { buyerAndAfterCareRoutes, layoutInternalRoutes } from "./routes";
import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { nlNL, enUS, frFR, deDE } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { de, enGB, nl, fr } from 'date-fns/locale';
import { green, indigo, red } from "@mui/material/colors";
import FallBackPage from "./components/FallBackPage"
import { registerLicense } from "@syncfusion/ej2-base";

import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/material.css";
import ViewAsBuyerError from "./ViewAsBuyerError";

import { LicenseInfo } from '@mui/x-license-pro';
import { withTranslation } from "react-i18next";
import { LoginRequestSuccess } from "./Pages/LoginRequest/LoginRequestSuccess";
const { webApiUrl } = window.appConfig;
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);




LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY);


const theme = createTheme({
  palette: {
    // primary: {
    //   main: "#3F51B5",
    // },
    // secondary: {
    //   main: "#f50057",
    // },
    indigo: {
      light: indigo[50],
    },
    green: {
      light: green[500],
      dark: green[900],
    },
    red: {
      light: red[50],
      dark: red[900],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiDataTable: {
      styleOverrides: {
        paper: {
          "@media print": {
            height: "auto !important",
            overflow: "auto !important",
          },
        },
      },
    },
  },
})

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  "@global": {
    "*": {
      scrollbarColor: "rgba(0,0,0,.2) hsla(0,0%,100%,.1)",
      scrollbarWidth: "thin",
    },
    "::-webkit-scrollbar": {
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
    },
    "::-webkit-scrollbar-track": {
      background: "hsla(0,0%,100%,.1)",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgba(0,0,0,.2)",
      borderRadius: theme.spacing(1),
      "&:hover": {
        background: theme.palette.action.active,
      },
    },
    "& .MuiTablePagination-displayedRows": {
      marginBottom: theme.spacing(0),
    },
    "& .MuiTablePagination-selectLabel": {
      marginBottom: theme.spacing(0),
    },
  },
})(() => null);

class App extends React.Component {
  state = { pageTitle: "" };

  constructor(props) {
    super(props);

    const { dispatch, user, app } = this.props;
    var matchResult = matchPath(history.location.pathname, "/viewasbuyer/:loginId");

    const matchWorkOrderPath = matchPath(history.location.pathname, "/werk/:projectNo/werkbon/:resolverId");
    if (matchWorkOrderPath) {
      const { resolverId } = matchWorkOrderPath.params;
      const searchParams = new URLSearchParams(window.location.search);
      const isRedirectToAfterCare = searchParams.get("nazorg");

      if (user) {
        let defaultApp = user && user.availableApps[0];
        if (user.availableApps.includes(apps.resolverModule)) defaultApp = apps.resolverModule;
        else if (isRedirectToAfterCare && user.availableApps.includes(apps.aftercare)) defaultApp = apps.aftercare;
        else if (app === null) defaultApp = user && user.availableApps[0];

        dispatch(commonActions.selectApp(defaultApp));

        if (isRedirectToAfterCare && user.availableApps.includes(apps.aftercare)) history.replace(`/nazorg/werkbon/${resolverId}`);
      }
    }

    if (matchResult) {
      dispatch(userActions.getViewAsUser({
        loginId: matchResult.params.loginId, 
        onSuccess: () => {
          history.push("/");
        },
        
      }));
    } else {
      dispatch(userActions.getLoggedInUser());
    }
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return 10000;
      },
      retryCondition: (requestOptions) => {
        return isNetworkOrIdempotentRequestError(requestOptions) && requestOptions.config.method.toLowerCase() === "get";
      },
    });
    this.updatePageTitle();
    this.getServerDetail();
  }

  getServerDetail() {
    axios
      .get(webApiUrl)
      .then((res) => {
        this.props.dispatch(commonActions.getCurrentVersionDate(res.headers["last-modified"]));
      })
      .catch(console.log);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!prevProps.user && this.props.user) || (prevProps.user && !this.props.user) || (prevProps.user && this.props.user && prevProps.user.type !== this.props.user.type)) {
      this.updatePageTitle();
    }
    if (this.props.user) {
      axios.defaults.headers = authHeader();
    }
    if (this.props.user && this.props.user.viewAsFailed === true) {
      alert(this.props.t("ViewAsFailed"));
      window.close();
    }
  }

  updatePageTitle() {
    if (this.state.pageTitle && this.state.pageTitle.trim() !== "") {
      document.title = this.state.pageTitle;
    } else {
      var title = localStorage.getItem("pageTitle");
      document.title = title ? title : "";
    }

    const url = webApiUrl + "api/config/GetPageTitle";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(url, requestOptions)
      .then((Response) => Response.text())
      .then((findResponse) => {
        document.title = findResponse;
        localStorage.setItem("pageTitle", findResponse);
        this.setState({
          pageTitle: findResponse,
        });
      })
      .catch((er) => {
        // console.log(er,'err')
      });
  }

  CheckAccessToSelectedAppsAndSwitch(...apps) {
    const userApps = getCommonArray(this.props.user.availableApps, apps);
    if (userApps.length > 0) {
      if (!apps.includes(this.props.app)) {
        this.props.dispatch(commonActions.selectApp(userApps[0]));
      }
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { user, loggingIn, app, viewAsFailed, i18n } = this.props;
    const { language } = i18n;

    let adapterLocale = nl
    let localeText = nlNL.components.MuiLocalizationProvider.defaultProps.localeText
    if (language === "en") {
      adapterLocale = enGB
      // enGB is not present for localText so we need to use enUs for other titles
      localeText = enUS.components.MuiLocalizationProvider.defaultProps.localeText
    } else if (language === "fr") {
      adapterLocale = fr
      localeText = frFR.components.MuiLocalizationProvider.defaultProps.localeText
    } else if (language === "de") {
      adapterLocale = de
      localeText = deDE.components.MuiLocalizationProvider.defaultProps.localeText
    }

    return viewAsFailed ? <ViewAsBuyerError /> : (
      <React.Suspense fallback={null}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Sentry.ErrorBoundary fallback={(rest) => <FallBackPage {...rest} />}>
              <LocalizationProvider localeText={localeText} dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <Router history={history}>
                  <React.Fragment>
                    <GlobalCss />
                    <AutoReload url="/index.html" tryDelay={10 * 60 * 1000} />
                                    {
                                        user && user.type !== userAccountTypeConstants.buyer && Date.now() <= new Date("2024-01-15") && <Christmas user={user} />
                                    }
                    {user && !matchPath(history.location.pathname, "/werkbon/:workOrderId") ? (
                      <React.Fragment>
                        <Route exact path="/" component={({ ...props }) => <CommonDashboard {...props} />} />
                        {history.location.pathname !== "/" &&
                          (user.type === userAccountTypeConstants.buyer || app === apps.aftercare ? (
                            <Layout user={user} history={history}>
                              <Switch>
                                {buyerAndAfterCareRoutes.map(({ path, Component, permissions, forbiddenUserTypes, exact }) => {
                                  if (forbiddenUserTypes && forbiddenUserTypes.includes(user.type)) return null
                                  return <Route key={path.toString()} exact={exact} path={path} render={(props) => this.CheckAccessToSelectedAppsAndSwitch(...permissions) && <Component {...props} />} />
                                })}
                                <Route component={PageNotFound} />
                              </Switch>
                            </Layout>
                          ) : (
                            <LayoutInternal user={user} history={history}>
                              <Switch>
                                {layoutInternalRoutes.map(({ path, forbiddenUserTypes, Component, permissions, exact }) => {
                                  if (!user.isSuperAdmin && !user.availableApps.includes(5) && path === "/settings/import") {
                                    return null
                                  }
                                  if (forbiddenUserTypes && forbiddenUserTypes.includes(user.type)) return null
                                  return <Route key={path.toString()} exact={exact} path={path} render={(props) => this.CheckAccessToSelectedAppsAndSwitch(...permissions) && <Component {...props} app={app} />} />
                                })}
                                <Route component={PageNotFound} />
                              </Switch>
                            </LayoutInternal>
                          ))}
                      </React.Fragment>
                    ) : (
                      loggingIn !== true &&
                      history.location.pathname !== "/login" &&
                      history.location.pathname !== "/login-aanvraag" &&
                      history.location.pathname !== "/login-aanvraag/success" &&
                      history.location.pathname !== "/forgot" &&
                      history.location.pathname !== "/reset" &&
                      !matchPath(history.location.pathname, "/werkbon/:workOrderId") && (
                        <Redirect
                          to={{
                            pathname: "/login",
                            state: { from: history.location },
                          }}
                        />
                      )
                    )}
                    <Route path="/login" component={LoginPage} />
                    <Route path="/login-aanvraag" component={LoginRequest} exact /> 
                    <Route path="/login-aanvraag/success" component={LoginRequestSuccess} exact />
                    <Route path="/forgot" component={ForgotPage} />
                    <Route path="/reset" component={ResetPage} />
                    <Route path="/werkbon/:resolverId" component={WorkOrderStatusUpdatePage} />
                  </React.Fragment>
                </Router>
              </LocalizationProvider>
            </Sentry.ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Suspense>
    )
  }
}

function mapStateToProps(state, s) {
  const { alert, authentication, app } = state;
  const { user, loggingIn, viewAsFailed } = authentication;
  return {
    alert,
    user,
    loggingIn,
    app,
    viewAsFailed
  };
}

const connectedPage = connect(mapStateToProps)(withTranslation()((App)));
export { connectedPage as App };

