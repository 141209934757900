import React from "react";
import { connect } from "react-redux";
import {
    Container,
    Grid,
    Typography, Accordion, AccordionSummary, AppBar, Toolbar, Table, TableBody, TableRow, TableCell, AccordionDetails} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ArrowDropDown } from "@mui/icons-material"
import { userAccountTypeConstants } from "../../_constants"
import { withTranslation } from 'react-i18next';
import { authHeader } from "../../_helpers";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    appBar:{
            },
    grow: {
        flexGrow: 1
    },
    bold: {
        fontWeight: "bold"
    },
    mainContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(5, 0, 6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0)
        }
    },
    expansionPanel: {
        width: '100%',
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 48,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0'
        }
    },
    documentHeading: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        '& .MuiAccordionSummary-content': {
            display: 'block',
            overflow: 'hidden',
        },
        '& .MuiSvgIcon-root': {
            color: "white"
        }
    },
    qaHeaderDetails: {
        display: 'block',
        padding: 0
    },
    qaQuestion: {
        backgroundColor: theme.palette.grey[100],
    },
    expansionPanelDetails: {
        padding: theme.spacing(1, 3)
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    }
});


class Page extends React.Component {
    state = {
        involvedParties: []
    };

    componentDidMount() {
        this.UpdateInvolvedPartiesInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.selected || !this.props.selected || prevProps.selected.projectId.toUpperCase() !== this.props.selected.projectId.toUpperCase()) {
            this.UpdateInvolvedPartiesInfo();
        }
    }

    UpdateInvolvedPartiesInfo() {
        const { selected } = this.props;
        if (selected) {
            const url = webApiUrl + 'api/home/GetInvolvedParties/' + encodeURI(selected.projectId);
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(url, requestOptions)
                .then(Response => Response.json())
                .then(findResponse => {
                    this.setState({ involvedParties: findResponse });
                });
        }
    }

    render() {
        const { t, classes, user } = this.props;
        const { involvedParties } = this.state;
        const isBuyer = user.type === userAccountTypeConstants.buyer;
        return (
            <Container className={classes.mainContainer}>
                <Grid container>
                    <Grid item container xs={12} className={classes.container}>
                        <AppBar position="sticky" color='inherit' className={classes.appBar}>
                            <Toolbar variant="dense">
                                <Typography className={classes.bold}>{t('layout.menuitem.projectinformation.involvedparties')}</Typography>
                            </Toolbar>
                        </AppBar>
                        <div style={{ width: '100%' }}>
                            {
                                involvedParties.map((party, indexHeader) => (
                                    <Accordion key={indexHeader} className={classes.expansionPanel} defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDown  />}
                                            aria-controls={'panel-cat-' + indexHeader + '-content'}
                                            id={'panel-cat-' + indexHeader + '-header'} className={classes.documentHeading}
                                        >
                                            <Typography  className={classes.bold} noWrap>{party.organisationNamePart}</Typography>
                                            <Typography component='p'  variant="caption" noWrap>{(isBuyer === false ? '(' + party.productCode + ') ' : '') + party.productDescription}</Typography>
                                            
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.documentHeaderDetails}>
                                            <div>
                                                {
                                                    <Table size="small">
                                                        <TableBody>
                                                            <TableRow >
                                                                <TableCell component="th" scope="row">
                                                                    {t('buyersGuide.involvedParties.mailingAddress') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationPostAdress_StreeNumberAddition}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {t('general.address.postcode') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationPostAdress_Postcode}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {t('general.address.city') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationPostAdress_Place}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {t('general.telephone') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationTelephone}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {t('general.website') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationWebsite}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell component="th" scope="row">
                                                                    {t('general.email.label') + ':'}
                                                                </TableCell>
                                                                <TableCell>{party.organisationEmail}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </div>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, buildings } = state;
    const { user } = authentication;
    const { selected } = buildings;
    return {
        user,
        selected
    };
}

const connectedPage = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Page)));
export { connectedPage as InvolvedPartiesPage };