import { AlertTitle, Alert, Typography } from '@mui/material'
import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

const CustomAlert = ({ title = "", description = "", type = "success", endButtonProps }) => {
  return (
    <Alert severity={type} sx={{m:1}}>
      <AlertTitle>
        {title}
      </AlertTitle>
      <Typography mb={1} variant='body2'>
        {description}
      </Typography>
     {endButtonProps && <LoadingButton 
        endIcon={ endButtonProps?.endIcon && <ArrowDownwardIcon />}
        variant='outlined' color={type} 
        size='small'
        onClick={()=> typeof endButtonProps?.onClick === "function" ?endButtonProps?.onClick():"" }
        loading={endButtonProps?.loading}
        >
          {endButtonProps?.buttonText}
      </LoadingButton>}
    </Alert>
  )
}

export default CustomAlert