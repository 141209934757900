import React, { useContext, useEffect, useState } from 'react'
import { Container, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DataGrid from '../../../components/DataGrid'
import SelectCustom from '../../../components/Select'
import {
  formatDate,
  generateLocalStorageKey,
  getLocalStorageItem,
  setLocalStorageItem
} from '../../../_helpers'
import { rowsPerPageConstant } from '../../../_constants/grid.constants'
import { useTranslation } from 'react-i18next'
import { DatePicker } from "@mui/x-date-pickers"
import clsx from "clsx";
import { DateRange } from '@mui/icons-material'
import { LabelColumn } from '../../../components/DataColumns'
import { CalendarContext } from "../Agenda";
import moment from 'moment'

const { webApiUrl } = window.appConfig;

function AgendaListView({ events, activeStep, ...props }) {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [sortData, setSortData] = useState({ name: "start", direction: "asc" });
  const classes = useStyles({ activeStep });
  const { loading, handleFilters, filters, isBuyer } = useContext(CalendarContext);

  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "start", visible: true },
    { name: "end", visible: true },
    { name: "title", visible: true },
    { name: "name", visible: true },
    { name: "categoryTitle", visible: true },
    { name: "projectName", visible: false },
    { name: "object", visible: true },
    { name: "location", visible: true },
  ]);
  const [defaultFilter, setDefaultFilter] = useState([...Array(8)].map(() => []));

  useEffect(() => {
    if (!filters) return;
    const localColumnsVisibility = getLocalStorageItem("Agenda_ColumnsVisibility");
    if (localColumnsVisibility) setColumnsVisibility(localColumnsVisibility);
    else setLocalStorageItem("Agenda_ColumnsVisibility", columnsVisibility);

    let rowsPerPageLocal = getLocalStorageItem("Agenda_RowsPerPage");
    if (!rowsPerPageLocal) setLocalStorageItem("Agenda_RowsPerPage", rowsPerPage);
    else setRowsPerPage(rowsPerPageLocal);

    let filterDataLocal = getLocalStorageItem("Agenda_Columns_Filters");
    if (!filterDataLocal) setLocalStorageItem("Agenda_Columns_Filters", defaultFilter);
    else {
      const startDate = moment(filterDataLocal[1][0]).format('YYYY-MM-DD')
      const endDate = filterDataLocal[2][0] ? moment(filterDataLocal[2][0]).format('YYYY-MM-DD') : moment(startDate).add(999, 'years').format('YYYY-MM-DD')
      setDefaultFilter(filterDataLocal);
      handleFilters({ startDate, endDate });
    }
  }, [])

  const isColumnVisible = (columnName) => {
    const column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else return true;
  };

  const selectFilterOptions = (arr, key) => {
    const filteredArray = [{ value: '', label: t('general.all') }]
    return filteredArray.concat([...new Set(arr.map(p => p[key]))].map(value => ({ value, label: value })));
  }

  const columns = [
    {
      name: "appointmentId",
      options: {
        display: "excluded",
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: "start",
      label: t("general.start"),
      options: {
        display: isColumnVisible('start'),
        filterList: defaultFilter ? defaultFilter[1] : [],
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            const dateString = formatDate(event.start, true);
            return <LabelColumn tooltipLabel={dateString} label={dateString} />
        },
        filterType: "custom",
        filterOptions: {
          logic: (value, filters, row) => {
            if (activeStep) {
              if (filters.length && !filters.find(a => {
                return moment(a).isSameOrBefore(value, 'day')
              })) return true;
              return false;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index] && filterList[index].length ? filterList[index][0] || null : null;
            return (
              <>
                <div className={clsx(classes.datePickerContainer, !value && classes.shrinkDateLabel)}>
                  <DatePicker
                    variant="standard"
                    format="dd-MM-yyyy"
                    id="start-date-picker"
                    label={t("general.start")}
                    className={classes.customDatePicker}
                    name="startDateTime"
                    value={value ? new Date(value) : value}
                    onChange={(date) => {
                      onChange([new Date(date).toJSON()], index, column)
                    }}
                    autoOk
                    slotProps={{
                      openPickerIcon: { className: classes.inputFieldIcon },
                      openPickerButton: { edge: "start", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "large",
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: DateRange,
                    }}
                  />
                </div>
              </>
            )
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a}>{formatDate(new Date(a))}</span>)
          }
        }
      },
    },
    {
      name: "end",
      label: t("general.end"),
      options: {
        display: isColumnVisible('end'),
        filterList: defaultFilter ? defaultFilter[2] : [],
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            const dateString = formatDate(event.end, true);
            return <LabelColumn tooltipLabel={dateString} label={dateString} />
        },
        filterType: "custom",
        filterOptions: {
          logic: (value, filters, row) => {
            if (activeStep) {
              if (filters.length && !filters.find(a => {
                return moment(a).isSameOrAfter(value, 'day')
              })) return true;
              return false;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index] && filterList[index].length ? filterList[index][0] || null : null;
            return (
              <>
                <div className={clsx(classes.datePickerContainer, !value && classes.shrinkDateLabel)}>
                  <DatePicker
                    disableToolbar
                    id="end-date-picker"
                    label={t("general.end")}
                    format="dd-MM-yyyy"
                    name="startDateTime"
                    value={value ? new Date(value) : value}
                    onChange={(date) => {
                      onChange([new Date(date).toJSON()], index, column)
                    }}
                    autoOk
                    ampm={false}
                    slotProps={{
                      openPickerIcon: { className: classes.inputFieldIcon },
                      openPickerButton: { edge: "end", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "large",
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: DateRange,
                    }}
                  />
                </div>
              </>
            )
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a}>{formatDate(new Date(a))}</span>)
          }
        }
      },
    },
    {
      name: "title",
      label: t("general.subject"),
      options: {
        display: isColumnVisible('title'),
        filterList: defaultFilter ? defaultFilter[3] : [],
        filter: true,
        sort: false,
        maxWidth: 70,
        filterType: 'custom',
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            return <LabelColumn tooltipLabel={event.title} label={event.title} />
        },
        filterOptions: {
          logic: (value, filters, row) => {
            if (filters.length && !filters.find(a => a.value === value) && value) return true;
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index].length ? filterList[index][0] : null;
            return (
              <SelectCustom
                label={t("general.subject")}
                searchKey="value"
                selectProps={{
                  value,
                  onChange: (e, newValue) => {
                    onChange(newValue ? [newValue] : [], index, column);
                  },
                }}
                idKey={"value"}
                labelKey={"label"}
                options={selectFilterOptions(events, 'title')}
              />
            );
          },
        },
        customFilterListOptions: {
          render: (record) => {
            return record.map(({ label }) => <span key={label}>{label}</span>)
          }
        }
      },
    },
    {
      name: "creatorName",
      label: t("agenda.participants"),
      options: {
        display: handleFilters ? isColumnVisible('creatorName') : "excluded",
        filterList: defaultFilter ? defaultFilter[4] : [],
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
          const event = events[rowIndex];
          if (!event) return "";
          const participants = event.participants || [];
          const personId = isBuyer ? event.creatorPersonId : participants.length ? participants[0].id : null;
          const personName = isBuyer ? event.creatorName : participants.length ? participants.map(p => p.name).join(', ') : null;
          return <LabelColumn tooltipLabel={personName} label={personName} showIcon={!!personId} isAvatar={!!personId}
            avatarUrl={webApiUrl + "api/home/GetPersonPhoto/" + personId} />
        },
      },
    },
    {
      name: "categoryTitle",
      label: t("general.category"),
      options: {
        display: isColumnVisible('categoryTitle'),
        filterList: defaultFilter ? defaultFilter[5] : [],
        filter: true,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            return <LabelColumn tooltipLabel={event.categoryTitle} label={event.categoryTitle} />
        },
        filterType: 'custom',
        filterOptions: {
          logic: (value, filters, row) => {
            if (value && filters.length && !filters.find(a => a.value === value)) return true;
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index].length ? filterList[index][0] : null;
            return (
              <SelectCustom
                label={t("general.category")}
                searchKey="value"
                selectProps={{
                  value,
                  onChange: (e, newValue) => {
                    onChange(newValue ? [newValue] : [], index, column);
                  },
                }}
                idKey={"value"}
                labelKey={"label"}
                options={selectFilterOptions(events, 'categoryTitle')}
              />
            );
          },
        },
        customFilterListOptions: {
          render: (record) => {
            return record.map(({ label }) => <span key={label}>{label}</span>)
          }
        }
      },
    },
    {
      name: "projectName",
      label: t("general.projectName"),
      options: {
        display: isColumnVisible('projectName'),
        filterList: defaultFilter ? defaultFilter[6] : [],
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            return <LabelColumn tooltipLabel={event.projectName} label={event.projectName} />
        },
      },
    },
    {
      name: "object",
      label: t("general.objects"),
      options: {
        display: handleFilters ? isColumnVisible('object') : "excluded",
        filterList: defaultFilter ? defaultFilter[7] : [],
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event) return "";
            return <LabelColumn tooltipLabel={event.object} label={event.object} />
        },
      },
    },
    {
      name: "address",
      label: t("general.location"),
      options: {
        display: isColumnVisible('address'),
        filterList: defaultFilter ? defaultFilter[8] : [],
        filter: false,
        sort: false,
        print: true,
        maxWidth: 70,
        download: false,
        customBodyRenderLite: (rowIndex) => {
            const event = events[rowIndex];
            if (!event || !event.address) return "";
            const address = typeof event.address === 'string' ?
                event.address :
                `${event.address.street} ${event.address.houseNo} ${event.address.houseNoAddition || ''} ${event.address.postcode} ${event.address.place}`;
            return <LabelColumn tooltipLabel={address} label={address} />
        },
      },
    },
  ]

  const options = {
    rowsPerPage,
    pagination: true,
    rowsPerPageOptions: rowsPerPageConstant,
    serverSide: false,
    download: false,
    print: false,
    sortOrder: sortData,
    onFilterChange: (identifier, list, type, index) => {
      if (handleFilters && !activeStep) {
        const startDate = moment(list[1][0]).format('YYYY-MM-DD')
        const endDate = list[2][0] ? moment(list[2][0]).format('YYYY-MM-DD') : moment(startDate).add(999, 'years').format('YYYY-MM-DD')
        switch (identifier) {
          case "start": {
            handleFilters({ startDate, endDate })
            break;
          }
          case "end": {
            handleFilters({ startDate, endDate })
            break;
          }
          default: {
            break;
          }
        }
        if (type === 'reset')
          handleFilters({ startDate, endDate })
      }
      setDefaultFilter(list)
      setLocalStorageItem(`Agenda_Columns_Filters`, list);
    },
    onTableChange: (action, tableState) => {
      const newRowsPerPage = tableState.rowsPerPage;
      const sortDataLocal = Object.keys(tableState.sortOrder).length ? tableState.sortOrder : null;

      switch (action) {
        case "changeRowsPerPage": {
          setLocalStorageItem('Agenda_RowsPerPage', newRowsPerPage);
          setRowsPerPage(newRowsPerPage)
          break;
        }
        case "sort": {
          setLocalStorageItem('Agenda_SortOrder', sortDataLocal);
          setSortData(sortDataLocal);
          break;
        }
        default: {
          break;
        }
      }
    },
    onViewColumnsChange: (changedColumn, action) => {
      const listToUpdate = Object.assign([], columnsVisibility);
      let column = listToUpdate.find((x) => x.name === changedColumn);
      if (action == "add") {
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("Agenda_ColumnsVisibility", listToUpdate);
      } else if (action == "remove") {
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("Agenda_ColumnsVisibility", listToUpdate);
      }
    },

  }
  return (
    <Container maxWidth={false} className={clsx(classes.mainContainer, activeStep && classes.activeStepMainContainer)}>
      <DataGrid
        options={options}
        identifier="appointmentId"
        localColumnOrdersIdentifier={generateLocalStorageKey("Agenda_ColumnOrder")}
        localColumnSortIdentifier={generateLocalStorageKey("Agenda_ColumnSort")}
        columns={columns}
        data={events}
        rowsPerPageIdentifier={generateLocalStorageKey("Agenda_RowsPerPage")}
        columnsVisibilityIdentifier={generateLocalStorageKey("Agenda_ColumnsVisibility")}
        loading={loading}
        getData={true}
        fontSize={12}
      />
    </Container>
  )
}

const useStyles = makeStyles(theme => ({

  avatarIcon: {
    padding: theme.spacing(0.2),
    fontSize: 18
  },
  mainContainer: {
    overflow: "auto",
  },
  activeStepMainContainer: {
    padding: theme.spacing(2),
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "auto",
    whiteSpace: "nowrap",
    maxWidth: "100%",
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  datePickerContainer: {
    display: "flex",
    "& > div": {
      padding: `${theme.spacing(0)} !important`,
      boxShadow: "none !important",
      width: "100%",
    },
    "& > div > div": {
      height: 35,
      "& input": {
        textOverflow: "ellipsis",
      },
      "& .MuiInputAdornment-root": {
        padding: `${theme.spacing(0)} !important`,
        height: 0
      },
    },
  },
}))

AgendaListView.defaultProps = {
  length: 30,
}

AgendaListView.range = (start, { length = AgendaListView.defaultProps.length, localizer }) => {
  let end = localizer.add(start, length, 'day')
  return { start, end }
}

AgendaListView.title = (start, { length = AgendaListView.defaultProps.length, localizer }) => {
  let end = localizer.add(start, length, 'day')
  return localizer.format({ start, end }, 'agendaHeaderFormat')
}
export default AgendaListView
