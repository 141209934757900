import axios from "axios";
import { URLS } from "./urls";
export const getUserProfile = () => axios.get(URLS.USER_PROFILE);
export const getOrganisationProfile = () => axios.get(URLS.ORGANISATION_PROFILE);
export const getPersonTitleFor = () => axios.get(URLS.GET_PERSON_TITLE_FOR);
export const getPersonMiddleNames = () => axios.get(URLS.GET_PERSON_MIDDLE_NAMES);
export const getPersonTitleBehind = () => axios.get(URLS.GET_PERSON_TITLE_BEHIND);
export const getBanks = () => axios.get(URLS.GET_BANKS);
export const getIndustries = () => axios.get(URLS.GET_INDUSTRIES);
export const updateUserProfile = (data) => axios.patch(URLS.USER_PROFILE, data);
export const updateOrganisationProfile = (data) => axios.patch(URLS.ORGANISATION_PROFILE, data);