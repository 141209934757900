import { Stack, TextField, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react'
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';

const General = ({contractDetails , updateContractDetails, loadingKey, isDisabledEditing}) => {
  const { all: allBuildings } = useSelector(state => state.buildings)
  const [objectRegistrationNumber, setObjectRegistrationNumber] = useState(contractDetails?.plotRegistrationNo)
  const theme = useTheme();



  const {buildingId} = contractDetails||{}

  const buildingInfo = useMemo(()=> allBuildings?.find((building)=>building.buildingId?.toLowerCase() === buildingId?.toLowerCase()), [buildingId])

  const {t} = useTranslation()

  return (
    <div><Typography mb={1.5} variant='subtitle1'>{t("general.general")}</Typography>
    <Stack rowGap={2}>
      <TextField
        fullWidth
        label={t("general.projectName")}
        type="text"
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={buildingInfo?.projectName}
      />
      <TextField
        fullWidth
        size='small'
        label={t("general.objectNumber")}
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={buildingInfo?.buildingNoIntern}
      />
      <TextField
        fullWidth
        label={t("general.building.type")}
        size='small'
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={buildingInfo?.buildingType}
      />
      <TextField
        fullWidth
        label={t("general.type")}
        size='small'
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={buildingInfo?.propertyType}
      />
    
     {loadingKey === "plotRegNo" ?    <Loader /> :  <TextField
        fullWidth
        label={t("contract.details.general.objectRegistrationNumber")}
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <EditIcon sx={{color: theme.palette.grey[500]}}/>,
        }}
        value={objectRegistrationNumber}
        onChange={(e)=>setObjectRegistrationNumber(e.target.value)}
        onBlur={(e)=>{
            updateContractDetails("plotRegNo" , e.target.value ,"plotRegistrationNo" )
        }}
        disabled={isDisabledEditing}
       
      />
}
    </Stack></div>
  )
}

export default General