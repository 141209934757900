import React, { useEffect, useRef, useState } from 'react'
import { AppBar, Tab, Tabs, Box, Typography, Stack, IconButton, CircularProgress, Toolbar, Tooltip } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ArrowBack, ArrowForward, Check, Close, Groups, Handyman, Home } from '@mui/icons-material';
import ParticipantsList from './ParticipantsList';
import { getAvailableParticipantsForChatCreation } from '../../apis/aftercareApis';
import { convertGetAvailableParticipantsForChatCreationResponse } from './util';
import InvolvePartyList from './InvolvePartyList';
import ChatCreationPreviewBody from './ChatCreationPreviewBody';
import { useTranslation } from 'react-i18next';

const TabPanel = ({ children, value, index, icon, label, actionItem }) => {
  const theme = useTheme()
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-createNewChat-${index}`} aria-labelledby={`simple-tab-createNewChat-${index}`}>
      {value === index && (
        <>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {
              icon && label && <Typography component="h2" variant="h6" padding={theme.spacing(2)}>
                {icon}&nbsp;&nbsp;
                {label}
              </Typography>
            }
            {actionItem}
          </Box>
          {children}
        </>
      )}
    </div>
  );
}

const Loader = () => (
  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flex={1}>
    <CircularProgress size={30} />
  </Box>
);

const Header = ({ handleActiveTab, activeTab, selectedParticipants, handleCloseChat, t, setIsPreviewOpen }) => (<>
  <Tabs value={activeTab} indicatorColor="secondary" onChange={handleActiveTab}>
    {[{ title: t('chat.buyerRenterOrPropertyManagers'), Icon: Home }, { title: t('general.employees'), Icon: Groups }, { title: t('chat.add.subcontractors'), Icon: Handyman }].map((x) =>
      <Tooltip title={x.title} arrow>
        <Tab sx={{ minWidth: "64px" }} label={<x.Icon fontSize="medium" sx={{ color: 'common.white' }} />} />
      </Tooltip>
    )}
  </Tabs>
  <Stack direction="row">
    {(selectedParticipants?.length !== 0) && <IconButton
      aria-label="ArrowRight"
      size="large"
      onClick={() => setIsPreviewOpen(true)}
      color='inherit'
    >
      <ArrowForward fontSize="medium" />
    </IconButton>}

    <IconButton
      aria-label="Close"
      size="large"
      onClick={handleCloseChat}
      color='inherit'
    >
      <Close fontSize="medium" />
    </IconButton>
  </Stack>
</>)

const PreviewHeader = ({ setIsPreviewOpen, groupName, handleInitiateChat, setIsErrorInTextField, inputRef, handleCloseChat, t }) => (<>
  <Stack direction="row" justifyContent={'space-between'} width={"100%"}>
    <Stack direction="row" alignItems="center">
      <IconButton
        aria-label="ArrowBack"
        size="large"
        onClick={() => setIsPreviewOpen(false)}
        color='inherit'
      >
        <ArrowBack fontSize="medium" />
      </IconButton>
      <Typography>{t("chat.add.createNewChat")}</Typography>
    </Stack>
    <Stack direction="row">
      <IconButton
        aria-label="Check"
        size="large"
        onClick={() => {
          if (groupName.trim().length > 0) {
            handleInitiateChat(groupName)
          } else {
            setIsErrorInTextField(true)
            inputRef.current.focus()
          }
        }}
        color='inherit'
      >
        <Check fontSize="medium" />
      </IconButton>
      <IconButton
        aria-label="close"
        size="large"
        onClick={handleCloseChat}
        color='inherit'
      >
        <Close fontSize="medium" />
      </IconButton>
    </Stack>
  </Stack>
</>
)

const CreateNewChat = ({ repairRequestId, repairRequestNumber, selectedParticipants, handleCloseChat, handleSelectParticipant, handleInitiateChat }) => {
  const inputRef = useRef();

  const [activeTab, setActiveTab] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const [isErrorInTextField, setIsErrorInTextField] = useState(false)
  const [groupName, setGroupName] = useState("")

  useEffect(() => {
    setLoading(true);
    if (repairRequestId) {
      getAvailableParticipantsForChatCreation(repairRequestId).then((res) => {
        setData(convertGetAvailableParticipantsForChatCreationResponse(res.data, t))
        setLoading(false);
      })
        .catch((er) => {
          setLoading(false);
        })
    }
  }, [])

  const handleActiveTab = (event, newTab) => {
    setActiveTab(newTab);
  };

  if (loading) {
    return <Loader />;
  }
  return <>
    <Stack height={"100%"}>
      <Box borderBottom={1} borderColor="divider" pt={1} >
        <AppBar position="static" >
          <Toolbar sx={{ display: "flex", padding: "0px !important", justifyContent: "space-between" }} variant="dense">

            {!isPreviewOpen && <Header handleActiveTab={handleActiveTab} activeTab={activeTab} selectedParticipants={selectedParticipants} handleCloseChat={handleCloseChat} handleSelectParticipant={handleSelectParticipant} handleInitiateChat={handleInitiateChat} t={t} setIsPreviewOpen={setIsPreviewOpen} />}

            {isPreviewOpen && <PreviewHeader setIsPreviewOpen={setIsPreviewOpen} groupName={groupName} handleInitiateChat={handleInitiateChat} setIsErrorInTextField={setIsErrorInTextField} inputRef={inputRef} handleCloseChat={handleCloseChat} t={t} />}

          </Toolbar>
        </AppBar>
      </Box>

      <Box flexGrow={1} py={1} sx={{ bgcolor: 'background.paper', overflow: "auto" }}>
        {!isPreviewOpen && <>
          <TabPanel
            value={activeTab}
            index={0}
          >
            <ParticipantsList handleSelectParticipant={handleSelectParticipant} list={data?.buyerRenterOrPropertyManagers} selectedParticipants={selectedParticipants} isCheckBoxVisible={true} />
          </TabPanel>

          <TabPanel
            value={activeTab}
            index={1}
          >
            <ParticipantsList handleSelectParticipant={handleSelectParticipant} list={data?.employees} selectedParticipants={selectedParticipants} isCheckBoxVisible={true} />
          </TabPanel>

          <TabPanel
            value={activeTab}
            index={2}
          >
            <InvolvePartyList handleSelectParticipant={handleSelectParticipant} data={data} selectedParticipants={selectedParticipants} />
          </TabPanel>
        </>}
        {isPreviewOpen && <ChatCreationPreviewBody handleSelectParticipant={handleSelectParticipant} data={data} selectedParticipants={selectedParticipants} inputRef={inputRef} isErrorInTextField={isErrorInTextField} repairRequestNumber={repairRequestNumber} groupName={groupName} setIsErrorInTextField={setIsErrorInTextField} setGroupName={setGroupName} />}
      </Box>
    </Stack>
  </>
}

export default CreateNewChat
