import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

function Address({contract}) {

  const {t} = useTranslation()

  return (
    <div><Typography mb={1.5} mt={2} variant='subtitle1'>{t("general.address")}</Typography>
          
    <Stack rowGap={2}>
      <TextField
        fullWidth
        label={t("general.address.street")}
        size='small'
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={contract?.objectAddress?.street}
      />
      <Stack direction='row' columnGap={1.5}>
        <TextField
          fullWidth
          size='small'
          label={t("general.number")}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        disabled
        value={contract?.objectAddress?.houseNo}
        />
        <TextField
          fullWidth
          size='small'
          label={t("general.address.numberAddition")}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        disabled
        value={contract?.objectAddress?.houseNoAddition}
        />
      </Stack>
      <Stack direction='row' columnGap={1.5}>
        <TextField
          fullWidth
          size='small'
          label={t("general.address.postcode")}
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        disabled
        value={contract?.objectAddress?.postcode}
          
        />
        <TextField
          fullWidth
          size='small'
          label={t("general.address.city")}
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
        disabled
        value={contract?.objectAddress?.place}
        />
      </Stack>
      <TextField
        fullWidth
        size='small'
        label={t("general.country")}
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        value={contract?.objectAddress?.country}
      />
    </Stack> </div>
  )
}

export default Address