import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  Input,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  MenuList,
  TextField,
  ListItem,
  ListItemText,
  List,
  Radio,
  Typography,
  IconButton,
  Popover,
  Grid,
  Button,
  Checkbox,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close, Search, FilterList } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import clsx from "clsx";
import { useMediaQuery } from "@mui/material";
import BottomDrawer from "./BottomDrawer";
import {
  getOrganizations,
  getProductsServices,
} from "../../../apis/surveyingApis";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
export default function SelectOrganizations({
  projectId,
  customTitle,
  oplossers,
  customButtonTitle,
  selectOrg = false,
  hideExtraInfo = false,
  onSubmit = () => 0,
  setLoading,
  canCreateRepairRequest,
  loading,
  customOplosser,
  anchorEl,
  onClose,
  isCanSendToWB = false,
  closeModal = () => {},
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
  transformOrigin = { vertical: "top", horizontal: "right" },
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [organizationsData, setAllOrganizationsData] = useState([]);
  const [filterSearchTerm, setFilterSearchTerm] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectOrgMethod, setSelectOrgMethod] = useState(0);
  const [selectOrgProductService, setSelectOrgProductService] = useState(null);
  const [showEditFilterAnchor, setShowEditFilterAnchor] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [collapseContainer, setCollapseContainer] = useState([]);
  const [productServices, setProductServices] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const { selected } = useSelector((state) => state.buildings);
  const { isUpdatingPerson } = useSelector((state) => state.surveying);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [isShowSearchBox, setIsShowSearchBox] = useState(false);
  const [state, setState] = useState({
    orgIds: [],
    relationIds: [],
    canSendToWB: false,
    workOrderText: "",
    sendWorkOrder: false,
    item: "",
    person: "",
    repairRequestRight: false,
    workOrderRight: false,
    createWorkOrder: false,
    notify: false,
  });

  useEffect(() => {
    if (!isUpdatingPerson && isUpdating) {
      closeModal();
      onClose();
      setIsUpdating(false);
    }
  }, [isUpdatingPerson]);

  useEffect(() => {
    if (!!anchorEl && organizationsData && organizationsData.length) {
      filterHandler(selectOrgMethod);
    }
  }, [anchorEl, organizationsData]);

  useEffect(() => {
    if (!!anchorEl) {
      setLoading(true);
      getOrganizations(projectId, app.toString())
        .then((res) => {
          setAllOrganizationsData(res.data);
          setLoading(false);
          setState(
            oplossers || {
              orgIds: [],
              relationIds: [],
              canSendToWB: false,
              workOrderText: "",
              sendWorkOrder: false,
              item: "",
              person: "",
              repairRequestRight: false,
              workOrderRight: false,
              createWorkOrder: false,
              notify: false,
            }
          );
        })
        .catch((er) => {
          setLoading(false);
        });

      getProductsServices()
        .then((res) => {
          setProductServices(res.data);
        })
        .catch(console.log);
    }
  }, [anchorEl]);

  const filterHandler = (method, searchQuery = "", product) => {
    const SearchDissolver = organizationsData.filter((res) => {
      const isExist =
        res.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (method !== "all"
          ? !method
            ? res.projectIds && res.projectIds.includes(projectId)
            : res.isOwnOrganisation == method
          : true) &&
        (product ? res.productServiceIds.includes(product.id) : true);
      return isExist;
    });
    method !== selectOrgMethod && setSelectOrgMethod(method);
    product !== selectOrgProductService && setSelectOrgProductService(product);
    searchQuery !== searchTerm && setSearchTerm(searchQuery);
    setFilterSearchTerm(SearchDissolver);
  };

  const handleChangePerson = (relationId, orgId) => {
    const orgIndex = state.relationIds.findIndex((a) => a.orgId === orgId);
    setState((p) => ({
      ...p,
      orgIds: p.orgIds.includes(orgId) ? p.orgIds : [...p.orgIds, orgId],
      relationIds: Object.assign([...p.relationIds], {
        [orgIndex >= 0 ? orgIndex : p.relationIds.length]: {
          relationId,
          orgId,
          createWorkOrder: false,
          notify: false,
        },
      }),
    }));
  };
  const handleChangeOrganization = (orgId) => {
    const canInclude = state.orgIds.includes(orgId);
    setState((p) => ({
      ...p,
      orgIds: canInclude
        ? p.orgIds.filter((p) => p !== orgId)
        : [...p.orgIds, orgId],
      relationIds: canInclude
        ? p.relationIds.filter((r) => r.orgId !== orgId)
        : p.relationIds,
    }));
  };

  const childRenderer = () => (
    <>
      <Grid item className={classes.stickyHeader}>
        <Grid container direction="row">
          <Grid item className={classes.grow}>
            {!isShowSearchBox ? (
              <Typography className={classes.title} variant="h6">
                {t(customTitle || "general.resolver")}
              </Typography>
            ) : (
              <Input
                onFocus={(event) => event.stopPropagation()}
                className={classes.InputStyle}
                autoFocus
                value={searchTerm}
                onChange={(e) => filterHandler(selectOrgMethod, e.target.value, selectOrgProductService)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => {
                        filterHandler(selectOrgMethod, "", selectOrgProductService)
                        setIsShowSearchBox(false)
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          </Grid>
          {!isShowSearchBox && (
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="search"
                component="span"
                style={{ marginTop: -8, marginBottom: -8 }}
                onClick={(e) => {
                  setIsShowSearchBox((a) => !a)
                  filterHandler(selectOrgMethod, "", selectOrgProductService)
                }}
                size="large"
              >
                <Search className={classes.icons} />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            <IconButton aria-describedby={"filter-list"} color="inherit" aria-label="filter-resolvers" component="span" style={{ marginTop: -8, marginBottom: -8 }} onClick={(e) => setShowEditFilterAnchor(!showEditFilterAnchor)} size="large">
              <FilterList className={classes.icons} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              color="inherit"
              component="span"
              edge="end"
              style={{ marginTop: -8, marginBottom: -8 }}
              onClick={() => {
                onClose({
                  relationIds: state.relationIds,
                  canSendToWB: state.canSendToWB,
                })
              }}
              size="large"
            >
              <Close className={classes.icons} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.oploserContain}>
        <Grid container direction="column">
          <Grid item>
            <Collapse in={showEditFilterAnchor}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel id="change-method-select-label">{t("organization.search.method.label")}</InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      labelId="change-method-select-label"
                      id="change-method-select"
                      value={selectOrgMethod}
                      onChange={(e) => filterHandler(e.target.value, searchTerm || "", selectOrgProductService)}
                      label={t("organization.search.method.label")}
                    >
                      {[0, 1, "all"].map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {t("organization.search.method." + index)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                  size="small"
                    id="product-service-method-select-addworkorder"
                    fullWidth
                    options={productServices}
                    value={selectOrgProductService}
                    onChange={(e, v) => filterHandler(selectOrgMethod, searchTerm || "", v)}
                    getOptionLabel={(option) => option.code + " - " + option.description}
                    renderInput={(params) => <TextField {...params} classes={{ root: classes.customTextField }} placeholder={t("repairRequest.productOrService")} variant="outlined" margin="dense" />}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid item>
            <MenuList style={{ margin: "0 -16px -12px" }}>
              {!!filterSearchTerm &&
                filterSearchTerm.map((item, index) => (
                  <React.Fragment key={item.id + index}>
                    <ListItem
                      button
                      component={(!item.relations || !item.relations.length) && "label"}
                      for={(!item.relations || !item.relations.length) && item.id}
                      onClick={() => {
                        setCollapseContainer((p) => (p.includes(item.id) ? p.filter((a) => a !== item.id) : [...p, item.id]))
                      }}
                      className={classes.removePaddingTopBottom}
                    >
                      <ListItemText primary={item.name} className={classes.collapseListStyle} />
                      <Checkbox
                        id={item.id}
                        className={classes.customRadioButton}
                        color="primary"
                        checked={state.orgIds.includes(item.id)}
                        onChange={() => {
                          handleChangeOrganization(item.id)
                          setCollapseContainer((p) => (p.includes(item.id) ? p.filter((a) => a !== item.id) : [...p, item.id]))
                        }}
                        value={item.id}
                        name="org"
                        inputProps={{ "aria-label": item.name }}
                      />
                    </ListItem>
                    {item.relations && !!item.relations.length && (
                      <Collapse in={collapseContainer.includes(item.id)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.innerbg}>
                          {item.relations
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((person) => {
                              const canFindIndex = state.relationIds.findIndex((a) => a.relationId === person.id)
                              return (
                                <React.Fragment key={`${item.id}-${person.id}`}>
                                  <ListItem
                                    className={classes.innerOption}
                                    onClick={() => {
                                      if (isCollapse !== person.id) {
                                        setCollapse(person.id)
                                      } else {
                                        setCollapse(false)
                                      }
                                    }}
                                    button
                                  >
                                    <ListItemText secondary={person.name} className={classes.radioTypo} />
                                    <Radio
                                      id={person.id}
                                      className={classes.customRadioButton}
                                      color="primary"
                                      checked={canFindIndex >= 0}
                                      onChange={() => handleChangePerson(person.id, item.id)}
                                      value={person.id}
                                      name="person"
                                      inputProps={{ "aria-label": person.name }}
                                    />
                                  </ListItem>
                                  <Collapse in={isCollapse === person.id} timeout="auto" unmountOnExit>
                                    {canFindIndex >= 0 && (
                                      <>
                                        <div className={classes.checkboxContainer}>
                                          <Checkbox
                                            checked={canFindIndex >= 0 && state.relationIds[canFindIndex].createWorkOrder}
                                            onChange={({ target: { checked } }) => {
                                              setState((p) => ({
                                                ...p,
                                                relationIds: Object.assign(p.relationIds, {
                                                  [canFindIndex]: {
                                                    ...p.relationIds[canFindIndex],
                                                    createWorkOrder: checked,
                                                  },
                                                }),
                                              }))
                                            }}
                                            className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                            color="primary"
                                          />
                                          &nbsp;
                                          <Typography className={classes.typo}>{t("workOrders.createWorkOrderImmediately")}</Typography>
                                        </div>
                                        {state.relationIds[canFindIndex].createWorkOrder && (
                                          <div className={classes.checkboxContainer}>
                                            <Checkbox
                                              checked={canFindIndex >= 0 && state.relationIds[canFindIndex].notify}
                                              onChange={({ target: { checked } }) => {
                                                setState((p) => ({
                                                  ...p,
                                                  relationIds: Object.assign(p.relationIds, {
                                                    [canFindIndex]: {
                                                      ...p.relationIds[canFindIndex],
                                                      notify: checked,
                                                    },
                                                  }),
                                                }))
                                              }}
                                              className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                              color="primary"
                                            />
                                            &nbsp;
                                            <Typography className={classes.typo}>{t("general.notifyImmediately")}</Typography>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Collapse>
                                </React.Fragment>
                              )
                            })}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                ))}
              <div className={classes.OpslaanStyle}>
                <Button
                  disabled={isUpdatingPerson}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSubmit(state, organizationsData)
                    setIsUpdating(true)
                  }}
                  className={classes.OpslaanButtonStyle}
                >
                  {isUpdatingPerson ? <CircularProgress size={20} /> : t(customButtonTitle || "general.save")}
                </Button>
              </div>
            </MenuList>
          </Grid>
        </Grid>
      </div>
    </>
  )

  return (
    !loading && (
      <>
        {!isSmallDevice ? (
          <Popover
            open={!!anchorEl}
            disableRestoreFocus
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id={"relations-list"}
            anchorEl={anchorEl}
            classes={{ paper: classes.roundedPaper }}
            onClose={!isUpdatingPerson ? onClose : () => 0}
          >
            {childRenderer()}
          </Popover>
        ) : (
          <BottomDrawer
            className={classes.bottomDrawerStyle}
            open={!!anchorEl}
            customOplosser={customOplosser}
            close={!isUpdatingPerson ? onClose : () => 0}
          >
            {childRenderer()}
          </BottomDrawer>
        )}
      </>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  bottomDrawerStyle: {
    zIndex: `${theme.zIndex.modal} !important`,
  },
  oploserContain: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  OpslaanStyle: {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 12,
  },
  customTextField: {
    "& .MuiInputBase-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& input::placeholder": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  OpslaanButtonStyle: {
    fontSize: 14,
    marginTop: "10px",
    "&.Mui-disabled": {
      background: theme.palette.grey[200],
      color: theme.palette.grey[400],
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 2,
    padding: theme.spacing(1.3, 2, 0, 2),
  },
  grow: {
    flexGrow: 1,
    width: 0,
    display: "flex",
    alignItems: "center",
  },
  InputStyle: {
    width: "100%",
  },
  checkboxRoot: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  lightCheckBox: {
    padding: theme.spacing(0),
    "& .MuiSvgIcon-root": {
      fill: theme.palette.grey[400],
    },
  },
  roundedPaper: {
    borderRadius: 10,
    width: 303,
    height: 303,
  },
  removePaddingTopBottom: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0.5),
  },
  checkboxContainer: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(0.625),
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  TextBoxContainer: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(0.625),
    display: "flex",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
  },
  innerOption: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(1.2),
  },
  innerbg: {
    background: theme.palette.grey[100],
  },
  typo: {
    color: theme.palette.grey[800],
    fontSize: 12,
    marginLeft: 7,
  },
  typoTextBox: {
    color: theme.palette.grey[800],
    fontSize: 12,
  },
  title: {
    color: theme.palette.grey[700],
    fontWeight: "bold",
    fontSize: 16,
  },
  collapseListStyle: {
    "& .MuiTypography-root": {
      color: theme.palette.grey[700],
      fontSize: 14,
    },
  },
  collapseButton: {
    "&:focus": {
      outline: "none",
    },
  },
  radioTypo: {
    "& .MuiTypography-root": {
      color: theme.palette.grey[700],
      fontSize: 12,
    },
  },
  customRadioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: 17,
    },
  },
  icons: {
    fill: theme.palette.grey[700],
  },
}));
