import React, { useEffect, useRef, useState } from "react";
import TuiImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { alpha, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  base64ToFile,
  changeFileNameExtension,
  toBase64,
  compressImage,
} from "../_helpers";
import { Close, Done } from "@mui/icons-material";
import clsx from "clsx";

export default function ImageEditor({
  file,
  onClose = () => {},
  onGetImage = () => {},
  ...props
}) {
  const rootEl = useRef(null);
  const [imageEditorInst, setImageEditorInst] = useState(null);
  const classes = useStyles();
  
  useEffect(() => {
    (async () => {
        const options = {
            includeUI: {
                menu: ["crop", "flip", "rotate", "draw", "shape", "icon", "text"],
                initMenu: "draw",
                uiSize: { width: "100%", height: "100%" },
                menuBarPosition: "bottom",
                loadImage: {
                    path: await compressImage(await toBase64(file)),
                    name: file.name,
                },
            },
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70,
            },
            cssMaxWidth: window.innerWidth,
            cssMaxHeight: window.innerHeight - 134,
            usageStatistics: false,
        };

      let inst = new TuiImageEditor(rootEl.current, { ...options });
      inst.ui._actions.draw.stopDrawingMode();
      inst.ui._actions.draw.setColor("#FF4040");
      inst.ui.draw.color = "#FF4040";
      setImageEditorInst(inst);
    })();
  }, []);

  return (
    <div className={classes.root}>
      <div ref={rootEl} />
      <div className={classes.customButtonContainer}>
        {imageEditorInst && (
          <IconButton
            onClick={async () => {
              // Quality set to 0.95 so that the filesize do not increase a lot and we do not compromise much with Quality of image.
              let getFile = await base64ToFile(
                imageEditorInst.toDataURL({ format: "jpeg", quality: 0.95 }),
                changeFileNameExtension(file.name, "jpeg"),
                file.type
              );
              if (getFile) {
                onGetImage(getFile);
                const canvas = imageEditorInst._graphics._canvas;
                imageEditorInst.destroy();
                canvas.dispose();
              }
            }}
            className={classes.doneBtn}
            color={"primary"}
            // startIcon={}
            variant="outlined"
            size="large"
          >
            <Done className={classes.fillSaveIcon} />
            {/* <Typography>{t('Done')}</Typography> */}
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            const canvas = imageEditorInst._graphics._canvas;
            imageEditorInst.destroy();
            canvas.dispose();
            onClose();
          }}
          className={clsx(classes.doneBtn, classes.closeButton)}
          color={"secondary"}
          variant="outlined"
          size="large"
        >
          <Close className={classes.fillCloseIcon} />
        </IconButton>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    "& .tui-image-editor-header": {
      display: "none !important",
    },
    "& .tui-image-editor-item": {
      padding: `${theme.spacing(1.2)} !important`,
      margin: `4px !important`,
      minWidth: "fit-content",
      background: `${alpha(theme.palette.grey[100], 0.1)}`,
      borderRadius: `50px !important`,
      overflow: "hidden",
    },
    "& .tui-image-editor-controls": {
      display: "flex !important",
      alignItems: "center !important",
      overflow: "auto !important",
    },
    "& .tui-image-editor-help-menu": {
      display: "flex",
      alignItems: "center",
      maxWidth: "calc(100% - 120px)",
      height: "fit-content !important",
      background: "transparent !important",
      overflow: "hidden",
      overflowX: "auto",
      left: "3px !important",
      "-webkit-transform": "none !important",
      transform: "none !important",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
      "& .tui-image-editor-item:nth-child(4)": {
        display: "none !important",
      },
      "& .tui-image-editor-item:nth-child(5)": {
        display: "none !important",
      },
      "& .tui-image-editor-item:nth-child(9)": {
        display: "none !important",
      },
    },
  },
  fillCloseIcon: {
    fill: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  fillSaveIcon: {
    fill: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  doneBtn: {
    margin: theme.spacing(0, 0.3),
    height: 44,
    width: 44,
    borderRadius: 50,
    // [theme.breakpoints.down("xs")]: {
    //     borderRadius: 0,
    // },
    padding: `${theme.spacing(1.2)} !important`,
    background: alpha(theme.palette.grey[100], 0.1),
  },
  closeButton: {
    background: alpha(theme.palette.secondary.main, 0.5),
  },
  customButtonContainer: {
    position: "absolute",
    zIndex: 10,
    top: 12,
    right: 8,
  },
}));
