import {
  Avatar,
  Checkbox,
  CircularProgress,
  alpha,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AddCircleOutlineOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  FileCopyOutlined
} from '@mui/icons-material';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { AgendaAppointmentParticipantStatus, formatStartEndDateTime, getNameInitials, history } from "../../../_helpers";
import clsx from "clsx";
import { CalendarContext } from "../Agenda";
import { PreviewCalenderContext } from "../CreateSlot/CreateSlot";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import SelectSingleObjectModal from '../../../components/SelectSingleObjectModal';
import { getBuildingsForSlot } from '../../../apis/agendaApis';


const { webApiUrl } = window.appConfig;
export default function CustomEvent({ view, event }) {
  const { t } = useTranslation();
  const classes = useStyles({ view });
  const ref = useRef();
  let {
    deleteAgendaSlots,
    cancelAgendaAppointment,
    confirmAgendaAppointment,
    isBuyer,
    selected,
    updateMeetingLink,
  } = useContext(CalendarContext);
    let previewCalContext = useContext(PreviewCalenderContext);
    if (!deleteAgendaSlots) {
        deleteAgendaSlots = previewCalContext.deleteAgendaSlots;
    }
  const { all } = useSelector(state => state.buildings)
  const [modalState, setModalState] = useState(false);
  const [deleteEventDetails, setDeleteEventDetails] = useState({});
  const [meetingLink, setMeetingLink] = useState(event.onlineMeetingLink);
  const [addLink, setAddLink] = useState();
  const [eventRemoveModal, setEventRemoveModal] = useState(false);
  const [buyersModal, setBuyersModal] = useState(false);
  const [loaders, setLoaders] = useState({});
  const [openObjectSelector, setOpenObjectSelector] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const [availableBuildings, setAvailableBuildings] = useState([]);
  const eventDeleteHandler = async () => {
    setLoaders({ deletingEvent: true });
    if (event.participants && event.participants.some(p => p.status === AgendaAppointmentParticipantStatus.Confirmed)) {
      try {
        await cancelAgendaAppointment({ appointmentId: event.appointmentId, ...deleteEventDetails });
        deleteEventCloseHandler();
      } catch (e) {
      } finally {
        setLoaders({ deletingEvent: false });
      }
    } else {
      try {
        await deleteAgendaSlots({ slotId: event.appointmentId, ...deleteEventDetails });
        deleteEventCloseHandler()
      } catch (e) {
      } finally {
        setLoaders({ deletingEvent: false });
      }
    }
  }

  const eventConfirmHandler = async () => {
    setLoaders({ confirmingEvent: true });
    try {
      await confirmAgendaAppointment({ slotId: event.appointmentId, buildingId: isBuyer ? selected.buildingId : selectedBuilding.buildingId });
      buyersModalHandler();
    } catch (e) {
    } finally {
      setLoaders({ confirmingEvent: false });
    }
  }

  const toggleEventDetailModalHandler = () => {
    setModalState(!modalState);
  }

  const deleteEventHandler = () => {
    setEventRemoveModal(true)
  }
  const deleteEventCloseHandler = () => {
    setEventRemoveModal(false)
  }

  const buyersModalHandler = () => {
    setBuyersModal(!buyersModal)
  }

  const deleteEventDetailsChangeHandler = ({ target: { name, value, type, checked } }) => {
    setDeleteEventDetails({ ...deleteEventDetails, [name]: type === 'checkbox' ? checked : value });
  };

  const linkChangeHandler = (event) => {
    setMeetingLink(event.target.value)
  }
  const addLinkCloseHandler = (event) => {
    setAddLink(false);
    setMeetingLink('');
  }

  const editEventHandler = () => {
    history.push({ pathname: "/agenda/maak", state: { generationId: event.appointmentGenerationId } })
  }

  const updateLinkHandler = async () => {
    setLoaders({ updatingLink: true });
    try {
      await updateMeetingLink(event.appointmentId, meetingLink);
    } catch (e) {
    } finally {
      setLoaders({ updatingLink: false });
    }
  }
  const renderLinkHandler = (event) => {
    if (addLink)
      return <div className={classes.popoverInfoLink}>
        <TextField id="txt-meeting-link"
          label={null}
          variant="outlined"
          value={meetingLink}
          onChange={linkChangeHandler}
          classes={{ root: classes.linkMessageTextFields }}
          InputProps={{
            className: classes.customLinkInputMessage,
          }}
        />
        <div className={classes.popoverInfoIconContainer}>
          {loaders.updatingLink ? <CircularProgress color='inherit' size={22} /> :
            <IconButton size="small" className={classes.popoverInfoIcon} onClick={updateLinkHandler}>
              <CheckOutlined /></IconButton>}
          <IconButton size="small" className={classes.popoverInfoIcon} onClick={addLinkCloseHandler}>
            <CloseOutlined />
          </IconButton>
        </div>
      </div>;
    if (event.onlineMeetingLink)
      return <div className={classes.popoverInfoLink}>
        <TextField id="txt-meeting-link"
          label={null}
          variant="outlined"
          value={event.onlineMeetingLink}
          classes={{ root: classes.linkMessageTextFields }}
          InputProps={{
            className: classes.customLinkInputMessage,
          }}
        />
        <div className={classes.popoverInfoIconContainer}>
          <IconButton size="small" className={classes.popoverInfoIcon} onClick={() => {
            navigator.clipboard.writeText(event.onlineMeetingLink)
          }}><FileCopyOutlined /></IconButton>
          {!isBuyer &&
            <IconButton size="small" className={classes.popoverInfoIcon} onClick={() => {
              setMeetingLink(event.onlineMeetingLink);
              setAddLink(true);
            }}><EditOutlined /></IconButton>}
        </div>
      </div>;
    return <>
      {!isBuyer && <Button className={classes.popoverInfoAddLink} onClick={() => setAddLink(true)}>
        <AddCircleOutlineOutlined className={classes.popoverAddIcon} />{t('agenda.detailModal.addLink')}</Button>}
    </>
  }

  const handleAddObjects = async () => {
    setOpenObjectSelector(true);
    const res = await getBuildingsForSlot(event.appointmentId);
    setAvailableBuildings(res.data);
  }

  const handleBuildingSelect = (buildingId) => {
    buyersModalHandler();
    const selectedItem = all.filter(x => x.buildingId === buildingId)[0];
    setSelectedBuilding(selectedItem);
    
  }
  const isShortMeeting = moment(event.end).diff(event.start, 'minutes') < 45;
  const participants = event.participants || [];
  const { personId, name } = isBuyer ? { personId: event.creatorPersonId, name: event.creatorName } : participants.length ? participants[0] : {};
  const isEventCancelled = participants.some(p => p.status === AgendaAppointmentParticipantStatus.Cancelled)
  const isEventConfirmed = participants.some(p => p.status === AgendaAppointmentParticipantStatus.Confirmed)
  const buildingId = participants.length ? participants[0].buildingId : null;
  const building = all.find(p => (!buildingId || p.buildingId === buildingId) && p.projectId === event.projectId);
  const userInitials = getNameInitials(name);
  const showMoreDetails = view === 'day' || view === 'week';
  const address = event.address ? typeof event.address === 'string' ? event.address : `${event.address.street} ${event.address.houseNo} ${event.address.houseNoAddition || ''} ${event.address.postcode} ${event.address.place}` : '';  
  
  return (
    <>
      <Popover
        id={'event-' + event.id}
        open={modalState}
        anchorEl={ref.current}
        onClose={toggleEventDetailModalHandler}
        classes={{ paper: classes.customPopover }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.popoverMainContainer}>
          <div className={classes.popoverNavMainContainer}>
            <div className={classes.popoverNavContainer}>
              <Typography className={classes.popoverHeaderTitle}>
                {event.title}
                {isEventCancelled && <Typography component="span" className={classes.eventCancel}>{t('general.cancelled')}</Typography>}</Typography>
              <Typography
                className={classes.popoverSubTitle}>{formatStartEndDateTime(event)} {t('general.time.hrs')}</Typography>
            </div>
            <div className={classes.popoverNavIconContainer}>
              {!isBuyer &&
                <>
                  {event.published === false &&
                    <EditOutlined className={classes.popoverHeaderIcon} onClick={editEventHandler} />}
                  {(!isEventCancelled) &&
                    <DeleteOutline className={classes.popoverHeaderIcon} onClick={deleteEventHandler} />
                  }
                </>
              }
              <CloseOutlined className={classes.popoverHeaderIcon} onClick={toggleEventDetailModalHandler} />
            </div>
          </div>
          <hr className={classes.popoverBottomBorder} />
          <div className={classes.popoverInformationContainer}>
            <Grid container spacing={1}>
              {building && <><Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('general.projectName')}</Typography>
              </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.popoverInfoDescription}>{building.projectName}</Typography>
                </Grid></>}
              {participants.length > 0 && <><Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('agenda.detailModal.buyersName')}</Typography>
              </Grid>
                <Grid item xs={7}>
                  {participants.map((x) => {
                    return (
                      <Typography className={classes.popoverInfoDescription}>{x.name}</Typography>
                    )
                  })}
                </Grid></>}
              <Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('agenda.detailModal.modeOfMeeting')}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography
                  className={classes.popoverInfoDescription}>{t(`agenda.meetingMode.${event.appointmentLocation}`)}</Typography>
              </Grid>
              {building && !!buildingId && <><Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('general.object')}</Typography>
              </Grid>
                <Grid item xs={7}>
                  <Typography
                    className={classes.popoverInfoDescription}>{isBuyer ? building.buildingNoExtern : building.buildingNoIntern}</Typography>
                </Grid></>}
              {isEventConfirmed && event.appointmentLocation === 1 && <><Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('agenda.detailModal.link')}</Typography>
              </Grid>
                <Grid item xs={7}>
                  {renderLinkHandler(event)}
                </Grid></>}
            </Grid>
          </div>
          <hr className={classes.popoverBottomBorder} />
          <Typography className={classes.popoverInfoTitle}>{t('agenda.detailModal.additionalText')}</Typography>
          <Typography className={classes.popoverInfoDescription}>{event.addressAdditionalDesc}</Typography>
          {event.appointmentLocation === 0 &&
            <>
              <hr className={classes.popoverBottomBorder} />
              <Typography className={classes.popoverInfoTitle}>{t('general.location')}</Typography>
              {address &&
                <Typography
                  className={classes.popoverInfoDescription}>{address}</Typography>}
            </>
          }
          {!isBuyer && meetingLink !== '' && !isEventConfirmed && !isEventCancelled &&
            <>
              <hr className={classes.popoverBottomBorder} />
              <Grid container spacing={1}>
              <Grid item xs={5}>
                <Typography className={classes.popoverInfoTitle}>{t('agenda.detailModal.confirmForBuyer')}</Typography>
              </Grid>
              <Grid item xs={5}>  
              <Button className={classes.popoverInfoAddLink} onClick={() => handleAddObjects(event.appointmentId)}>
              <AddCircleOutlineOutlined className={classes.popoverAddIcon} />{t('general.objects.add')}</Button>
            </Grid>
            </Grid>
            </>
          }
          {isBuyer &&
            <>
              <hr className={classes.popoverBottomBorder} />
              <Typography className={classes.popoverInfoTitle}>{t('general.buyersGuide')}</Typography>
              <div className={classes.buyerInfoLabelContainer}>
                <Avatar alt={userInitials} src={webApiUrl + "api/home/GetPersonPhoto/" + event.creatorPersonId} />
                <Typography className={classes.popoverInfoDescription}>{event.creatorName}</Typography>
              </div>
            </>
          }
        </div>
      </Popover>
      {openObjectSelector && 
        <SelectSingleObjectModal 
        open={openObjectSelector}
        buildings={all.filter(building => availableBuildings.includes(building.buildingId))}
        onSave={handleBuildingSelect}
        onClose={() => setOpenObjectSelector(false)}
        />
      }
      <Modal
        open={buyersModal}
        onClose={buyersModalHandler}
        aria-labelledby="modal-buyer-title"
        aria-describedby="modal-buyer-description"
      >
        <div className={classes.deleteEventModalPaper}>
          <Typography
            className={classes.buyersLabel}>{t("agenda.confirmMeetingModal.label", { name: isBuyer ? event.creatorName : selectedBuilding.buyerRenterName, dateTime: `${formatStartEndDateTime(event)} ${t('general.time.hrs')}` })}</Typography>
          <div className={classes.buyerBtnContainer}>
            <Button isLoading={loaders.confirmingEvent} className={classes.eventRemoveModalBtn}
              onClick={eventConfirmHandler}>{t("button.action.yes")}</Button>
            <Button className={classes.eventRemoveModalBtnClose}
              onClick={buyersModalHandler}>{t("button.action.no")}</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={eventRemoveModal}
        onClose={deleteEventCloseHandler}
        aria-labelledby="event-remove-modal-title"
        aria-describedby="event-remove-modal-description"
      >
        <div className={classes.deleteEventModalPaper}>
          <div className={classes.deleteModalContainer}>
            <div className={classes.deleteEventIcon}>
              <DeleteIcon className={classes.deleteIcon} />
            </div>
            <Typography className={classes.deleteHeaderIcon}>{t("agenda.deleteModal.headerTitle")}</Typography>
          </div>
          <hr />
          <div className={classes.eventRemoveModalSubContainer}>
            <Typography className={classes.modalSubTitle}>{t("agenda.cancelModal.headerSubTitle")}</Typography>

            {event.published && isEventConfirmed
              && <TextField id="cancellation-reason"
                label={null}
                name="cancellationReason"
                multiline
                value={deleteEventDetails.cancellationReason}
                onChange={deleteEventDetailsChangeHandler}
                variant="outlined"
                placeholder={t("general.message")}
                classes={{ root: classes.messageTextFields }}
                InputProps={{
                  className: classes.customInputMessage,
                }}
              />}

            { event.appointmentType === 1 &&
            <div className={classes.checkBoxContainer}>
              {isEventConfirmed && <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteEventDetails.keepSlotOpen}
                    onChange={deleteEventDetailsChangeHandler}
                    classes={{
                      root: classes.modalCustomCheckBox,
                      checked: classes.modalCustomCheckBoxChecked
                    }}
                    name="keepSlotOpen"
                    color="primary"
                  />
                }
                label={<Typography variant="h6"
                  className={classes.customCheckBoxLabel}>{t("agenda.deleteCancelModal.checkbox.label.keepAvailableForOthers")}</Typography>}
              />}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteEventDetails.allFutureSlots}
                    onChange={deleteEventDetailsChangeHandler}
                    classes={{
                      root: classes.modalCustomCheckBox,
                      checked: classes.modalCustomCheckBoxChecked
                    }}
                    name="allFutureSlots"
                    color="primary"
                  />
                }
                label={<Typography variant="h6"
                  className={classes.customCheckBoxLabel}>{t("agenda.deleteCancelModal.checkbox.label.forAllFuture")}</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteEventDetails.allPastSlots}
                    onChange={deleteEventDetailsChangeHandler}
                    name="allPastSlots"
                    classes={{
                      root: classes.modalCustomCheckBox,
                      checked: classes.modalCustomCheckBoxChecked
                    }}
                    color="primary"
                  />
                }
                label={<Typography variant="h6"
                  className={classes.customCheckBoxLabel}>{t("agenda.deleteCancelModal.checkbox.label.forAllPast")}</Typography>}
              />
            </div>}
          </div>
          <hr />
          <div className={classes.eventRemoveBtnContainer}>
            <Button className={classes.eventRemoveModalBtn} isLoading={loaders.deletingEvent}
              onClick={eventDeleteHandler}>{t("button.action.yes")}</Button>
            <Button className={classes.eventRemoveModalBtnClose}
              onClick={deleteEventCloseHandler}>{t("button.action.no")}</Button>
          </div>
        </div>
      </Modal>
      <div className={classes.eventContainer} id={'event-' + event.id} ref={ref} aria-describedby={'event-' + event.id}
        onClick={isBuyer && !isEventConfirmed && !isEventCancelled ? buyersModalHandler : toggleEventDetailModalHandler}>
        <div className={classes.eventTextContainer}>
          <div className={classes.eventTextSubContainer}>
            {view === "day" && !isShortMeeting && personId &&
              <div className={classes.avatarContainer}>
                <Avatar className={classes.eventAvatar} alt={userInitials}
                  src={webApiUrl + "api/home/GetPersonPhoto/" + personId} />
              </div>
            }
            <div>
              {isEventCancelled && view === 'week' && <Typography className={classes.eventCancelWeekView}>{t('general.cancelled')}</Typography>}
              <div className={classes.eventLabelContainer}>
                <Typography
                  className={clsx(classes.eventTitle, view === 'day' && classes.eventTitleBold, classes.eventTitleMonthView, view === 'week' && classes.eventTitleWrap)}>
                  {isEventConfirmed && buildingId && building && <span>{building.buildingNoIntern}:</span>}
                  {view === "month" ? moment(event.start).format('HH:mm') : ''} {event.title}{!showMoreDetails ? `, ${address}` : ''}</Typography>
                {isEventCancelled && !(view === 'week') && <Typography className={classes.eventCancel}>{t('general.cancelled')}</Typography>}
              </div>

              {showMoreDetails && !isShortMeeting &&
                <>
                  {event.address && <Typography
                    className={classes.eventDate}>{address}</Typography>}
                  <Typography className={classes.eventDate}>
                    {event.participants && event.participants.map(p => p.name).join(', ')}
                  </Typography>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  buyersLabel: {
    fontSize: 16,
    padding: theme.spacing(3, 0),
    textAlign: 'center',
    color: theme.palette.grey[600],
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  buyerInfoLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    paddingTop: theme.spacing(0.5),
    "& .MuiAvatar-root": {
      height: 30,
      width: 30,
      marginRight: theme.spacing(1)
    },
  },
  popoverAddIcon: {
    fontSize: 22,
    fill: theme.palette.primary.main,
    paddingRight: theme.spacing(0.5)
  },
  popoverInfoAddLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    cursor: 'pointer'
  },

  popoverInfoLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    background: alpha(theme.palette.primary.main, 0.05),
    borderRadius: 4,
    padding: theme.spacing(0.4)
  },
  popoverInfoIconContainer: {
    display: 'flex',
    columnGap: theme.spacing(0.5)
  },
  popoverInfoIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  customMessageLabel: {
    color: theme.palette.grey[400],
    fontSize: 18,
    fontWeight: 'normal'
  },
  customInputMessage: {
    minHeight: 84,
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: 8,
    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      fontSize: 12,
      minHeight: 70
    },
    [theme.breakpoints.only("sm",)]: {
      fontSize: 14,
      minHeight: 70
    },
  },
  messageTextFields: {
    maxWidth: 650,
    width: '100%',
    minHeight: 84,
    height: '100%',
    borderRadius: 8,
    marginBottom: 16,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      minHeight: 70
    },
    '& label.Mui-focused': {
      color: alpha(theme.palette.grey[600], 0.19),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: alpha(theme.palette.grey[600], 0.19),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: alpha(theme.palette.grey[600], 0.19),
      },
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.grey[600], 0.19),
      },
      '&.Mui-focused fieldset': {
        borderColor: alpha(theme.palette.grey[600], 0.19),
      },
    },
  },
  customLinkInputMessage: {
    minHeight: 20,
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: 8,
    fontSize: 12,
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0.5, 1)
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 12,
      minHeight: 70
    },
    [theme.breakpoints.only("sm",)]: {
      fontSize: 14,
      minHeight: 70
    },
  },
  linkMessageTextFields: {
    width: 650,
    minHeight: 20,
    height: '100%',
    borderRadius: 8,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      minHeight: 70
    },
    '& label.Mui-focused': {
      color: alpha(theme.palette.grey[600], 0.19),
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: alpha(theme.palette.grey[600], 0.19),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none'
      },
      '&:hover fieldset': {
        borderColor: alpha(theme.palette.grey[600], 0.19),
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      },
    },
  },
  modalCustomCheckBox: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    "& .MuiSvgIcon-root": {
      width: 25,
      height: 25,
      borderRadius: 20,
      [theme.breakpoints.only("xs")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.only("sm",)]: {
        width: 22,
        height: 22,
      },
    },
  },
  modalCustomCheckBoxChecked: {
    color: theme.palette.primary.main,
    borderRadius: 4
  },
  eventRemoveModalSubContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  modalSubTitle: {
    fontSize: 16,
    color: theme.palette.grey[600],
    fontWeight: 'normal',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      textAlign: 'center'
    },
    [theme.breakpoints.only("sm")]: {
      textAlign: 'center',
      fontSize: 16
    }
  },
  customCheckBoxLabel: {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: 'normal',
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm",)]: {
      fontSize: 16,
    },
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  eventRemoveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: theme.spacing(2)
  },
  buyerBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: theme.spacing(2)
  },
  deleteHeaderIcon: {
    fontSize: 18,
    color: theme.palette.grey[900],
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("sm",)]: {
      fontSize: 20,
    },
  },
  deleteIcon: {
    fill: theme.palette.red.dark,
    width: 25
  },
  deleteModalContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  deleteEventIcon: {
    height: 53,
    width: 53,
    background: theme.palette.red.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px'
  },
  eventRemoveModalBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: theme.spacing(0.9, 1.2),
    width: 100,
    height: 38,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
      height: 35,
      padding: theme.spacing(1.3),
      width: 90,
      fontSize: 14,
    },
  },
  eventRemoveModalBtnClose: {
    background: theme.palette.grey[400],
    color: theme.palette.common.white,
    fontSize: 14,
    padding: theme.spacing(0.9, 1.2),
    width: 100,
    height: 38,
    '&:hover': {
      background: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md',)]: {
      height: 35,
      padding: theme.spacing(1.3),
      width: 90,
      fontSize: 14,
    },
  },
  deleteEventModalPaper: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    width: "auto",
    maxWidth: 600,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    '&:focus-visible': {
      outline: 'none !important'
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      width: "80%",
      borderRadius: 12
    },
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(2),
      width: "70%",
      borderRadius: 12
    }
  },
  popoverBottomBorder: {
    margin: theme.spacing(1, 0)
  },
  popoverInfoDescription: {
    fontSize: 14,
    color: theme.palette.grey[900],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  popoverInfoTitle: {
    fontSize: 14,
    color: theme.palette.grey[500]
  },
  popoverSubTitle: {
    fontSize: 12,
    color: theme.palette.grey[500],
    margin: theme.spacing(0.5, 0)
  },
  popoverHeaderTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grey[900]
  },
  popoverHeaderIcon: {
    fill: theme.palette.grey[600],
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  popoverNavMainContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  popoverInformationContainer: {
    display: 'flex',
  },
  customPopover: {
    width: 430,
    height: 'fit-content'
  },
  popoverMainContainer: {
    padding: theme.spacing(2)
  },
  eventAvatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  },
  eventTextContainer: {
    overflow: 'hidden',
    width: "100%"
  },
  eventTextSubContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  avatarContainer: {
    float: 'left'
  },
  eventTitleBold: {
    fontWeight: 'bold',
  },
  eventTitle: {
    color: theme.palette.grey[900],
    fontSize: 14,
    fontWeight: 'regular',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  eventTitleMonthView: {
    fontSize: 12,
    lineHeight: 1,
  },
  eventTitleWrap: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word'
  },
  eventDate: {
    color: theme.palette.grey[600],
    fontSize: 13,
    fontWeight: 500
  },
  eventContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    padding: theme.spacing(0, 1.2)
  },
  eventCancel: {
    fontSize: 12,
    color: theme.palette.red.dark,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1.8)
  },
  eventCancelWeekView: {
    fontSize: 12,
    color: theme.palette.red.dark,
    fontWeight: 'bold',
  },
  eventLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
