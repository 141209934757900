import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme, makeStyles, withStyles } from "@mui/styles";
import { IconButton, Typography, Dialog, Switch, FormGroup, DialogContent, Grid, Badge, List, ListItem, useMediaQuery, Container, Icon, Box, Popover, Tooltip, SvgIcon,} from "@mui/material";
import {
  Home,
  House,
  Chat,
  Dashboard,
  HomeWork,
  MoreHoriz,
  Build,
  Description,
  EventNote,
  PriorityHigh,
  Close,
  InfoOutlined,
  ListAlt,
  DescriptionOutlined,
  HomeWorkOutlined,
  EmailSharp,
  AssignmentTurnedIn,
  BusinessCenter,
  Work,
  LibraryAdd,
  AccessTime,
  FormatListBulleted,
  LoginSharp
} from "@mui/icons-material";
import { SurveyType, groupBy, useInternetStatusDetector } from "../_helpers";
import { useTranslation } from "react-i18next";
import { userAccountTypeConstants, apps, getLinkToHome, internalLayoutViewConstants, userAccountRoleConstants } from "../_constants";
import clsx from "clsx";
import { useRef } from "react";
import { useSelector } from "react-redux";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import SubMenu from "./SubMenu";
import HandshakeIcon from '@mui/icons-material/Handshake';

const InventorySvg = () => (
  <SvgIcon fontSize="medium">
    <path id="Path_148" data-name="Path 148" d="M5,5H7V8H17V5h2v5h2V5a2.006,2.006,0,0,0-2-2H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2h6V19H5Zm7-2a1,1,0,1,1-1,1A1,1,0,0,1,12,3Z" />
    <path id="Path_149" data-name="Path 149" d="M21,11.5,15.51,17,12.5,14,11,15.5,15.51,20l6.99-7Z" />
  </SvgIcon>
);

const Wkb = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_607" data-name="Group 607" transform="translate(-2521 -3495)">
      <path id="Path_633" data-name="Path 633" d="M2.083,0H22.917A2.083,2.083,0,0,1,25,2.083V22.917A2.083,2.083,0,0,1,22.917,25H2.083A2.083,2.083,0,0,1,0,22.917V2.083A2.083,2.083,0,0,1,2.083,0Z" transform="translate(2521 3495)" fill="#fff" />
      <path
        id="Path_634"
        data-name="Path 634"
        d="M0-6.442H1.961l.708,3.78L3.7-6.442H5.663L6.7-2.667,7.41-6.442H9.365L7.889.31H5.86L4.685-3.941,3.515.31H1.486Zm9.923,0h1.9v3.486l1.389-1.625H15.5l-1.74,1.714L15.6.31H13.5l-.983-1.961-.7.69V.31h-1.9Zm6.25,0h1.878V-4.1a1.837,1.837,0,0,1,.633-.442,2.019,2.019,0,0,1,.783-.147,1.891,1.891,0,0,1,1.466.643,2.661,2.661,0,0,1,.58,1.845,3.518,3.518,0,0,1-.265,1.412,1.986,1.986,0,0,1-.733.912,1.879,1.879,0,0,1-1.039.3,1.908,1.908,0,0,1-.9-.212,2.36,2.36,0,0,1-.667-.622V.31H16.168ZM18.032-2.15a1.427,1.427,0,0,0,.235.914.742.742,0,0,0,.6.283.694.694,0,0,0,.56-.279,1.5,1.5,0,0,0,.226-.937,1.34,1.34,0,0,0-.222-.852.671.671,0,0,0-.537-.272.774.774,0,0,0-.619.283A1.305,1.305,0,0,0,18.032-2.15Z"
        transform="translate(2523.004 3510.537)"
        fill="#3f51b5"
      />
    </g>
  </SvgIcon>
);

const surveyData = [
  {
    moduleType: 2,
    label: "survey.inspections",
    Img: "grading",
    route: "inspecties",
  },
  {
    moduleType: 5,
    label: "survey.checklists",
    Img: "fact_check",
    route: "",
  },
  {
    moduleType: 4,
    label: "wkb.surveyingMoments",
    Img: Wkb,
    route: "toezichtsmomenten",
  },
  {
    moduleType: 4,
    label: "wkb.assignments.label",
    Img: Wkb,
    route: "opdrachten",
  },
  {
    moduleType: 0,
    label: "survey.preDelivery",
    Img: InventorySvg,
    route: "voorschouwingen",
  },
  {
    moduleType: 1,
    label: "survey.deliveries",
    Img: "assignment_turned_in",
    route: "opleveringen",
  },
  {
    moduleType: 3,
    label: "survey.secondSignature",
    Img: "draw",
    route: "tweede-handtekening",
  },
];

const projectInformation = [
  {
    label: "layout.menuitem.projectinformation.generalprojectinfo",
    Icon: ListAlt,
    route: "algemene-projectinformatie",
  },
  {
    label: "layout.menuitem.projectinformation.involvedparties",
    Icon: ListAlt,
    route: "betrokken-partijen",
  },
  {
    label: "layout.menuitem.projectinformation.faq",
    Icon: ListAlt,
    route: "veel-gestelde-vragen",
  },
];

const housingwishes = [
  {
    label: "layout.menuitem.housingwishes.standardoptionslist",
    Icon: ListAlt,
    route: "keuzelijst",
  },
  {
    label: "layout.menuitem.housingwishes.requested",
    Icon: ListAlt,
    route: "aangevraagd",
  },
  {
    label: "layout.menuitem.housingwishes.finaloptions",
    Icon: ListAlt,
    route: "mijndefinitieveopties",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: 72,
    height: "100%",
    paddingTop: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    overflowY: "auto",
    overflowX: "hidden",
    position: "absolute !important",
    left: 0,
    zIndex: 1101,
    [theme.breakpoints.down("sm")]: {
      height: 56,
      width: "100%",
      overflowY: "hidden",
      overflowX: "auto",
      bottom: 0,
    },
    "@media (orientation: portrait)": {
      height: 56,
      width: "100%",
      overflowY: "hidden",
      overflowX: "auto",
      bottom: 0,
    },
  },
  settingIcon: {
    fontSize: 30,
  },
  emojiContainer: {
    "& svg": {
      fill: theme.palette.grey[700],
    },
    "& svg > g > path:first-child": {
      fill: theme.palette.grey[700],
    },
    "& svg > g > path:nth-child(2)": {
      fill: theme.palette.common.white,
    },
    "& .material-icons": {
      color: theme.palette.grey[700],
    },
    "& .MuiTypography-root": {
      fontSize: "10px !important",
      color: theme.palette.grey[700],
    },
    "& $settingButton": {
      position: "static",
      width: "72px !important",
      right: 0,
      maxWidth: "100%",
      "&::before": {
        content: "none",
      },
    },
  },
  settingButton: {
    "@media screen and (min-width:600px)": {
      "@media (orientation: portrait)": {
        right: 0,
        bottom: 0,
        position: "relative",
        width: "72px !important",
        maxWidth: "72px !important",
        height: "56px !important",
        "&::before": {
          background: "none !important",
        },
      },
      position: "absolute",
      bottom: 10,
      height: "50px !important",
      width: "95% !important",
      maxWidth: 55,
      right: 9,
      overflow: "hidden",
      borderRadius: 9,
      "&::before": {
        background: theme.palette.common.white,
        content: '""',
        opacity: 0.1,
        position: "absolute",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      },
    },
  },
  button: {
    height: 56,
    width: 72,
    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "4px solid transparent",
      borderBottom: "4px solid transparent",
      float: "left",
    },
    "@media (orientation: portrait)": {
      borderLeft: "none",
      borderRight: "none",
      borderTop: "4px solid transparent",
      borderBottom: "4px solid transparent",
      float: "left",
    },
    "&.more": {
      float: "right",
      position: "absolute",
      bottom: 0,
      right: 0,
    },
    "&.Mui-selected": {
      borderLeftColor: "rgba(255,255,255,0.7)",
      borderBottomColor: "rgba(255,255,255,0.7)",
    },
    "&:hover": {
      color: "inherit",
    },
    "& .text": {
      fontSize: ".6rem",
      maxWidth: "100%",
    },
  },
  popOverBoxText: {
    textAlign: "center",
    color: theme.palette.primary.main,
    fontSize: 14,
    marginTop: 5,
  },
  popOverBoxIcon: {
    fontSize: 25,
    color: theme.palette.primary.main,
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2.5, 1.9),
    flexDirection: "column",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    borderRadius: 6,
    maxWidth: 95,
    height: 84,
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      maxWidth: "initial",
      // width: '120px',
      // maxWidth: '120px',
    },
  },
  popoverMainContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
    },
  },
  title: {
    fontSize: 12,
    color: theme.palette.grey[800],
    fontWeight: "bold",
  },
  popoverContainerGrid: {
    // display: 'flex',
    // justifyContent: 'center',
  },
  popoverBadge: {
    position: "absolute",
    right: 2,
    top: 7,
  },
  dataSyncDialog: {
    "& .MuiPaper-rounded": {
      borderRadius: 10,
      minWidth: "384px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "270px",
      },
    },
  },
  dataSyncDialogContainer: {
    padding: theme.spacing(2),
  },
  dataSyncContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  popoverStyle: {
    width: 248,
    height: "auto",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "calc(100% - 0px)",
      marginLeft: 0,
      left: "0 !important",
      boxShadow: "0px 0px 5px #888, 0px -13px 10px #88888866",
    },
  },
  popover: {
    pointerEvents: "none",
  },
  loadingModules: {
    width: "100%",
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colorFilter: {
    filter: "brightness(4.5)",
  },
  priorityHighIcon: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "50px",
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function MenuBar(props) {
  const { dashboardCount, user, app, history, selectedBuilding, layoutView } = props;
  const menuList = useRef();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const widthMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { loading, all, rights, buyerGuidesBuildings: buildings } = useSelector((state) => state.buildings);
  const { dossiers, drawingDossier } = useSelector((state) => state.dossier);
  const { getWorkOrderCountsLoading, workOrderCounts } = useSelector((state) => state.workOrderCounts);
  const { messageCount, messageCountPerProject, newLoginRequestsCount } = useSelector((state) => state.dashboardCount);
  const { availableSurveyModules, loadingModules } = useSelector((state) => state.availableSurveyModules);
  const { applicationManagerItems } = useSelector((state) => state.surveying);
  const showDossiers = rights["menu.canShowMenu"] || (dossiers.openOrClosedDossiers && dossiers.openOrClosedDossiers.length > 0);
  const showDossiersisBuyer = (buildings && buildings.length > 0) || (dossiers.openOrClosedDossiers && dossiers.openOrClosedDossiers.length > 0) || drawingDossier;
  const hasDossierUpdates = dossiers.openOrClosedDossiers && dossiers.openOrClosedDossiers.some((p) => p.hasUpdates);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [surveyAnchorEl, setSurveyAnchorEl] = React.useState(null);
  const [buyerMenu, setBuyerMenu] = React.useState(null);
  const [projectMenu, setProjectMenu] = React.useState(null);
  const [dataSyncModal, setDataSyncModal] = React.useState(false);
  const [selectedAftercarePath, setSelectedAftercarePath] = React.useState(null);
  const [selectedProjectPath, setSelectedProjectPath] = React.useState(null);
  const isBuyer = user.type === userAccountTypeConstants.buyer;
  const isBuyerGuide = buildings && buildings.length > 0;
  const surveyElRef = useRef();
  const isOnline = useInternetStatusDetector();
  const showContractItems = isBuyer ? !!selectedBuilding?.isContract : (buildings?.filter(x => x.projectId === selectedBuilding?.projectId && x.isContract).length > 0);
  const showBuyersGuideItems = isBuyer ? !selectedBuilding?.isContract : (buildings?.filter(x => x.projectId === selectedBuilding?.projectId && !x.isContract).length > 0);

  const [subMenuDetails, setSubMenuDetails] = React.useState(null);


  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [syncSwitch, setSyncSwitch] = React.useState({
    signingChecked: true,
    synchroniserenChecked: true,
  });
  const { wbEmailLinkingInfo = [], wbProjectsLinkingInfo = [] } = applicationManagerItems || {};

  const syncSwitchHandleChange = (event) => {
    setSyncSwitch({ ...syncSwitch, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const opnameRoutesHandler = ({ route }) => {
    let url = app === apps.aftercare ? `/nazorg/opname` : `${selectedBuilding ? "/werk/" + selectedBuilding.projectNo : ""}`;
    switch (app) {
      case apps.huisinfo:
        switch (layoutView) {
          case internalLayoutViewConstants.Project: {
            url = isBuyer ? `/opname` : `${selectedBuilding ? "/werk/" + selectedBuilding.projectNo : ""}/opname`;
            break;
          }
          default:
            break;
        }
        break;
      case apps.constructionQuality:
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            url = selectedBuilding ? "/werk/" + selectedBuilding.projectNo + "/kwaliteitsborging/opname" : "";
            break;
          default:
            break;
        }
        break;
      case apps.aftercare:
        url = `/nazorg/opname`;
        break;
      default:
        break;
    }
    return `${url}/${route}`;
  };

  useEffect(() => {
    const baseUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo : "";
    const isOpenOpnameModal = history.location.pathname === baseUrl + "/opname" && surveyElRef.current;
    setSurveyAnchorEl(isOpenOpnameModal);
    setBuyerMenu(isBuyer && isOpenOpnameModal);
    setProjectMenu(isBuyer && isOpenOpnameModal);
    return () => {
      setSurveyAnchorEl(null);
      setBuyerMenu(null);
      setProjectMenu(null);
      setSubMenuDetails(null);

    };
  }, [surveyElRef, selectedBuilding, history.location.pathname, isBuyer]);

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  const dataSyncModalHandler = () => {
    setDataSyncModal(!dataSyncModal);
    setAnchorEl(null);
  };

  const handleSubMenuClick = (open) => {
    setSubMenuDetails(null);
  }

  const handleSubMenuToggle = (key, title, subMenuItems) => {
    if (subMenuDetails?.key === key)
      setSubMenuDetails(null);
    else
      setSubMenuDetails({ key, title, subMenuItems });
  }

  const opnameModule =
    !loadingModules && availableSurveyModules && availableSurveyModules.length
      ? surveyData.map((p) => {
        const canShowModule =
          availableSurveyModules &&
          availableSurveyModules.find(({ moduleType }) => {
            const returnValue = moduleType === p.moduleType;
            switch (app) {
              case apps.aftercare: {
                if (isBuyer) return returnValue && moduleType === SurveyType.PreDelivery;
                else return returnValue && moduleType !== SurveyType.Inspection && moduleType !== SurveyType.WKB;
              }
              case apps.constructionQuality: {
                return returnValue && (moduleType === SurveyType.Inspection || moduleType === SurveyType.WKB);
              }
              default:
                return returnValue;
            }
          });

        return (
          !!canShowModule && (
            <ListItem
              key={`Menu-${canShowModule.moduleType}`}
              button
              onClick={() => {
                setAnchorEl(null);
              }}
              disableGutters
              component={Link}
              to={opnameRoutesHandler(p)}
              className={classes.button}
              selected={history.location.pathname.startsWith(opnameRoutesHandler(p))}
            >
              <Grid container direction="column" justifyContent="center" alignItems="center">
                {typeof p.Img !== "string" ? <p.Img /> : <Icon fontSize="medium">{p.Img}</Icon>}
                <Typography noWrap className="text">
                  {" "}
                  {t(p.label)}{" "}
                </Typography>
              </Grid>
            </ListItem>
          )
        );
      })
      : [];

  function getMenuItems() {
    switch (app) {
      case apps.huisinfo:
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            const baseUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo : "";

            if (!isOnline) return [];

            const renderMenu = rights["menu.canShowMenu"]
              ? [
                  <ListItem button disableGutters component={Link} to={baseUrl} className={classes.button} selected={history.location.pathname === baseUrl}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Dashboard fontSize="medium" />
                      <Typography noWrap className="text">
                        {t("general.dashboard")}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to={baseUrl + "/overzicht"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/overzicht")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <HomeWork fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("general.projects")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to={baseUrl + "/objecten"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/objecten")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <House fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("general.objects")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to={baseUrl + "/berichten"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/berichten")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Badge badgeContent={dashboardCount.totalUnreadChats} color="secondary">
                        <Chat fontSize="medium" />
                      </Badge>
                      <Typography noWrap className="text">
                        {" "}
                        {t("dashboard.messages.title")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  showBuyersGuideItems &&
                  <ListItem button disableGutters component={Link} to={baseUrl + "/standaard-opties"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/standaard-opties")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Build fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("buyersGuide.options")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                ]
              : [];
            if (isBuyerGuide){
              renderMenu.push(
                <ListItem button disableGutters
                  onClick={() => handleSubMenuToggle("agenda", t("agenda.title"), [
                    {
                      to: "/agenda",
                      title: t("agenda.title"),
                      icon: <AccessTime fill="white" fontSize="medium" />,
                    },
                    {
                      to: "/agenda/tijdsloten",
                      title: t("agenda.timeSlots"),
                      icon: <FormatListBulleted fill="white" fontSize="medium" />,
                    }
                  ])
                  }
                  className={classes.button} selected={history.location.pathname.startsWith("/agenda") || subMenuDetails?.key === "agenda"}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <AccessTime fill="white" fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("agenda.title")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>
              );
              if (showContractItems) {
                renderMenu.push(
                  <ListItem button disableGutters component={Link} to={"/contract"} className={classes.button} selected={history.location.pathname.startsWith("/contract")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <HandshakeIcon fontSize="medium" />
                      <Typography noWrap className="text">
                        {t("contract.title")}
                      </Typography>
                    </Grid>
                  </ListItem>
                );
              }
             }
            const buyersMenu = isBuyer
              ? [
                  <ListItem button disableGutters component={Link} to={linkToHome} className={classes.button} selected={history.location.pathname.startsWith("/home")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Home fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("layout.menuitem.home")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem
                    button
                    disableGutters
                    className={classes.button}
                    onClick={(e) => {
                      setProjectMenu(e.currentTarget);
                    }}
                    selected={history.location.pathname.startsWith(selectedAftercarePath) || projectMenu}
                  >
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <ListAlt fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("layout.menuitem.projectinformation")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to="/documenten" className={classes.button} selected={history.location.pathname.startsWith("/documenten")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <DescriptionOutlined fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("layout.menuitem.documents")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to={"/berichten"} className={classes.button} selected={history.location.pathname.startsWith("/berichten")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Badge badgeContent={messageCount} color="secondary">
                        <Chat fontSize="medium" />
                      </Badge>
                      <Typography noWrap className="text">
                        {" "}
                        {t("chat.messages")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  showContractItems &&
                  <ListItem button disableGutters component={Link} to={"/finance"} className={classes.button} selected={history.location.pathname.startsWith("/finance")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <HandshakeIcon fontSize="medium" />
                      <Typography noWrap className="text">
                        {t("contract.title")}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  showBuyersGuideItems &&
                  <ListItem
                    button
                    disableGutters
                    className={classes.button}
                    onClick={(e) => {
                      setBuyerMenu(e.currentTarget);
                    }}
                    selected={history.location.pathname.startsWith(selectedProjectPath) || buyerMenu}
                  >
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <HomeWorkOutlined fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("layout.menuitem.housingwishes")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                  <ListItem button disableGutters component={Link} to={"/agenda"} className={classes.button} selected={history.location.pathname === "/agenda"}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <AccessTime fill="white" fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("agenda.title")}{" "}
                      </Typography>
                    </Grid>
                  </ListItem>,
                   
                  
                ]
              : [];

            return [
              ...renderMenu,
              ...buyersMenu,
              isBuyer ? (
                showDossiersisBuyer && (
                  <ListItem button disableGutters component={Link} to={"/dossier"} className={classes.button} selected={history.location.pathname.startsWith("/dossier")}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Description fontSize="medium" />
                      <Typography noWrap className="text">
                        {" "}
                        {t("layout.menuitem.dossiers")}{" "}
                      </Typography>
                    </Grid>
                    {hasDossierUpdates && (
                      <Tooltip title={t("dossier.sidebar.priority.high")}>
                        <PriorityHigh color="secondary" className={classes.priorityHighIcon} />
                      </Tooltip>
                    )}
                  </ListItem>
                )
              ) : (
                <ListItem
                  button
                  disableGutters
                  component={Link}
                  to={baseUrl + "/dossier"}
                  className={classes.button}
                  selected={history.location.pathname.startsWith(baseUrl + "/dossier") || history.location.pathname.startsWith(baseUrl + "/building")}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Description fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("layout.menuitem.dossiers")}{" "}
                    </Typography>
                  </Grid>
                  {hasDossierUpdates && (
                    <Tooltip title={t("dossier.sidebar.priority.high")}>
                      <PriorityHigh color="secondary" className={classes.priorityHighIcon} />
                    </Tooltip>
                  )}
                </ListItem>
              ),
              showDossiers && !isBuyer && (
                <ListItem button disableGutters component={Link} to={baseUrl + "/deadline-dossier"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/deadline-dossier")}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <EventNote fontSize="medium" />
                    <Typography style={{ textAlign: "center" }} className="text">
                      {" "}
                      {t("dossier.general.deadline.title")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>
              ),
              // ...settingMenu,
            ];
          case internalLayoutViewConstants.Building:
            return selectedBuilding && isOnline
              ? [
                <ListItem button disableGutters component={Link} to={"/object/" + selectedBuilding.buildingNoIntern} className={classes.button} selected={history.location.pathname === "/object/" + selectedBuilding.buildingNoIntern}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Home fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.dashboard")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
                <ListItem
                  button
                  disableGutters
                  component={Link}
                  to={"/object/" + selectedBuilding.buildingNoIntern + "/berichten"}
                  className={classes.button}
                  selected={history.location.pathname.startsWith("/object/" + selectedBuilding.buildingNoIntern + "/berichten")}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Badge badgeContent={dashboardCount.totalUnreadChats} color="secondary">
                      <Chat fontSize="medium" />
                    </Badge>
                    <Typography noWrap className="text">
                      {" "}
                      {t("layout.navbar.messages")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
              ]
              : [];

          default:
            return [];
        }
      case apps.constructionQuality:
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            const isOnlySubContractor = all.every((a) => a.roles && a.roles.every((b) => b === userAccountRoleConstants.subContractorSupervisor || b === userAccountRoleConstants.subContractorEmployee));
            const baseUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo + "/kwaliteitsborging" : "";
            const werkBonnenUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo + "/werkbonnen" : "";
            const chatUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo + "/berichten" : "";

            if (!isOnline) return opnameModule;

            const defaultMenus = !isOnlySubContractor
              ? [
                <ListItem button disableGutters component={Link} to={chatUrl} className={classes.button} selected={history.location.pathname === chatUrl}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Badge badgeContent={dashboardCount.totalUnreadChats} color="secondary">
                      <Chat fontSize="medium" />
                    </Badge>
                    <Typography noWrap className="text">
                      {" "}
                      {t("dashboard.messages.title")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
                <ListItem button disableGutters component={Link} to={baseUrl} className={classes.button} selected={history.location.pathname === baseUrl}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <BusinessCenter fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.repairRequests")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,

                <ListItem button disableGutters component={Link} to={werkBonnenUrl} className={classes.button} selected={history.location.pathname === werkBonnenUrl}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Work fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.workOrders")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
              ]
              : [];

            return [
              <ListItem
                button
                disableGutters
                component={Link}
                to={isOnlySubContractor ? "/kwaliteitsborging" : linkToHome}
                className={classes.button}
                selected={history.location.pathname === (isOnlySubContractor ? "/kwaliteitsborging" : "/dashboard")}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Home fontSize="medium" />
                  <Typography noWrap className="text">
                    {" "}
                    {t("layout.menuitem.home")}{" "}
                  </Typography>
                </Grid>
              </ListItem>,
              ...defaultMenus,
              <ListItem
                button
                disableGutters
                component={Link}
                to={baseUrl + "/dossiers/dossier"}
                className={classes.button}
                selected={history.location.pathname.startsWith(baseUrl + "/dossiers/dossier") || history.location.pathname.startsWith(baseUrl + "/dossiers/building")}
              >
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Description fontSize="medium" />
                  <Typography noWrap className="text">
                    {" "}
                    {t("layout.menuitem.dossiers")}{" "}
                  </Typography>
                </Grid>
                {hasDossierUpdates && (
                  <Tooltip title={t("dossier.sidebar.priority.high")}>
                    <PriorityHigh color="error" className={classes.priorityHighIcon} />
                  </Tooltip>
                )}
              </ListItem>,
              ...opnameModule,
              // ...settingMenu,
            ];
          case internalLayoutViewConstants.Building:
            return selectedBuilding && isOnline
              ? [
                <ListItem
                  button
                  disableGutters
                  component={Link}
                  to={"/object/" + selectedBuilding.buildingNoIntern + "/kwaliteitsborging"}
                  className={classes.button}
                  selected={history.location.pathname === "/object/" + selectedBuilding.buildingNoIntern + "/kwaliteitsborging"}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <BusinessCenter fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.repairRequests")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
              ]
              : [];
          default:
            return [];
        }
      case apps.resolverModule:
        if (!isOnline) return [];
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            const baseUrl = selectedBuilding ? "/werk/" + selectedBuilding.projectNo : "";
            return [(!getWorkOrderCountsLoading && !workOrderCounts
              ?
                <ListItem button disableGutters component={Link} to={"/werkbonnen"} className={classes.button} selected={history.location.pathname === "/werkbonnen"}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Home fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("layout.menuitem.home")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>
              :
                <ListItem button disableGutters component={Link} to={baseUrl + "/werkbonnen"} className={classes.button} selected={history.location.pathname === baseUrl + "/werkbonnen"}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Home fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.workOrders")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>
            ),
                <ListItem button disableGutters component={Link} to={baseUrl + "/berichten"} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/berichten")}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Badge badgeContent={dashboardCount.totalUnreadChats} color="secondary">
                      <Chat fontSize="medium" />
                    </Badge>
                    <Typography noWrap className="text">
                      {" "}
                      {t("dashboard.messages.title")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>
              ];
          case internalLayoutViewConstants.Building:
            return selectedBuilding
              ? [
                <ListItem
                  button
                  disableGutters
                  component={Link}
                  to={"/object/" + selectedBuilding.buildingNoIntern + "/werkbonnen"}
                  className={classes.button}
                  selected={history.location.pathname === "/object/" + selectedBuilding.buildingNoIntern + "/kwaliteitsborging"}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Home fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.workOrders")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
              ]
              : [];
          default:
            return [];
        }
      case apps.aftercare: {
        const isOnlySubContractorOrEmptyRole = all.every((a) => !a.roles || a.roles.length === 0 || a.roles.every((b) => b === userAccountRoleConstants.subContractorSupervisor || b === userAccountRoleConstants.subContractorEmployee));
        const isAfterCareEmployeeOrSiteManager = all.some((a) => a.roles && (a.roles.includes(userAccountRoleConstants.aftercareEmployee) || a.roles.includes(userAccountRoleConstants.siteManager)));
        let projectsSoFar = [];

        if (!isOnline) {
          return opnameModule;
        }

        if (all) {
          projectsSoFar = Object.values(groupBy(all, "projectId"))
            .map((p) => {
              return p.find((b) => b.roles && b.roles.some((r) => r === userAccountRoleConstants.aftercareEmployee|| r === userAccountRoleConstants.siteManager ||  r === userAccountRoleConstants.propertyManager)) || p[0];
            })
            .flat();
        }
        
        const totalCount = messageCountPerProject
          ? messageCountPerProject
              .filter((x) =>
                projectsSoFar
                  .filter((p) => p.roles && p.roles.some((r) => r === userAccountRoleConstants.aftercareEmployee|| r === userAccountRoleConstants.siteManager ||  r === userAccountRoleConstants.propertyManager)) 
                  .map((y) => y.projectId)
                  .includes(x.projectId)
              )
              .reduce((prev, next) => prev + next.count, 0)
          : 0;

        const aftercareEmployeeOrSiteManagerMenu = isAfterCareEmployeeOrSiteManager
          ? [
            <ListItem button disableGutters component={Link} to={"/nazorg/werkbonnen"} className={classes.button} selected={history.location.pathname === "/nazorg/werkbonnen"}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Work fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("general.workOrders")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
          ]
          : [];

        const defaultMenus = isOnlySubContractorOrEmptyRole
          ? []
          : [
            <ListItem button disableGutters component={Link} to={"/nazorg/berichten"} className={classes.button} selected={history.location.pathname.startsWith("/nazorg/berichten")}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Badge badgeContent={totalCount} color="secondary">
                      <Chat fontSize="medium" />
                    </Badge>
                    <Typography noWrap className="text">
                      {" "}
                      {t("dashboard.messages.title")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
            <ListItem button disableGutters component={Link} to={"/nazorg/meldingen"} className={classes.button} selected={history.location.pathname === "/nazorg/meldingen"}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <BusinessCenter fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("general.repairRequests")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            ...aftercareEmployeeOrSiteManagerMenu,
          ];

        const appMenus = isBuyer
          ? [
            <ListItem button disableGutters component={Link} to={"/nazorg"} className={classes.button} selected={history.location.pathname === "/nazorg"}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Home fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.home")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            <ListItem
              button
              disableGutters
              className={classes.button}
              onClick={(e) => {
                setProjectMenu(e.currentTarget);
              }}
              selected={history.location.pathname.startsWith(selectedAftercarePath) || projectMenu}
            >
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <ListAlt fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.projectinformation")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            <ListItem button disableGutters component={Link} to={"/nazorg/berichten"} className={classes.button} selected={history.location.pathname.startsWith("/nazorg/berichten")}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Badge badgeContent={messageCount} color="secondary">
                  <Chat fontSize="medium" />
                </Badge>
                <Typography noWrap className="text">
                  {" "}
                  {t("dashboard.messages.title")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            ...opnameModule,
            <ListItem button disableGutters component={Link} to={"/mijndefinitieveopties"} className={classes.button} selected={history.location.pathname.startsWith("/mijndefinitieveopties")}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <HomeWorkOutlined fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.housingwishes.finaloptions")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            <ListItem button disableGutters component={Link} to={"/nazorg/dossier"} className={classes.button} selected={history.location.pathname.startsWith("/nazorg/dossier")}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Description fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.dossiers")}{" "}
                </Typography>
              </Grid>
              {hasDossierUpdates && (
                <Tooltip title={t("dossier.sidebar.priority.high")}>
                  <PriorityHigh color="error" className={classes.priorityHighIcon} />
                </Tooltip>
              )}
            </ListItem>,
          ]
          : [
            <ListItem button disableGutters component={Link} to={isOnlySubContractorOrEmptyRole ? "/home" : linkToHome} className={classes.button} selected={history.location.pathname === (isOnlySubContractorOrEmptyRole ? "/home" : "/dashboard")}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Home fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.home")}{" "}
                </Typography>
              </Grid>
            </ListItem>,
            ...defaultMenus,
            ...opnameModule,
            <ListItem button disableGutters component={Link} to={"/nazorg/dossier"} className={classes.button} selected={history.location.pathname.includes("/nazorg/dossier") || history.location.pathname.includes("/nazorg/building")}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Description fontSize="medium" />
                <Typography noWrap className="text">
                  {" "}
                  {t("layout.menuitem.dossiers")}{" "}
                </Typography>
              </Grid>
              {/*{hasDossierUpdates && (*/}
              {/*  <Tooltip*/}
              {/*    title={t("Er is een aanpassing gedaan in het dossier.")}*/}
              {/*  >*/}
              {/*    <PriorityHigh*/}
              {/*      color="error"*/}
              {/*      className={classes.priorityHighIcon}*/}
              {/*    />*/}
              {/*  </Tooltip>*/}
              {/*)}*/}
            </ListItem>,
            ...(isAfterCareEmployeeOrSiteManager ? [
              <ListItem button disableGutters component={Link} to={"/nazorg/inlogverzoeken"} className={classes.button} selected={history.location.pathname === "/nazorg/inlogverzoeken"}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Badge badgeContent={newLoginRequestsCount} color="secondary">
                    <LoginSharp fontSize="medium" />
                  </Badge>
                  <Typography noWrap className="text">
                    {t("layout.menuitem.loginRequests")}
                  </Typography>
                </Grid>
              </ListItem>
            ] : []),
          ];

        return appMenus;
      }
      case apps.settingModule: {
        if (!isOnline) return [];
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            const baseUrl = "/settings";
            return [
              <ListItem button disableGutters component={Link} to={linkToHome} className={classes.button} selected={history.location.pathname.startsWith(baseUrl + "/WB-Werken")}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <AssignmentTurnedIn fontSize="medium" />
                  <Typography noWrap className="text">
                    {" "}
                    {t("settings.wbProjects.title")}{" "}
                  </Typography>
                </Grid>
                {!!wbProjectsLinkingInfo.some((p) => p.linked === false) && (
                  <Tooltip title={t("dossier.sidebar.priority.high")}>
                    <PriorityHigh color="error" className={classes.priorityHighIcon} />
                  </Tooltip>
                )}
              </ListItem>,
              <ListItem button disableGutters component={Link} to={baseUrl + "/link-email"} className={classes.button} selected={history.location.pathname === baseUrl + "/link-email"}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <EmailSharp fontSize="medium" />
                  <Typography noWrap className="text">
                    {" "}
                    {t("settings.emailLink.title")}{" "}
                  </Typography>
                </Grid>
                {!!wbEmailLinkingInfo.some((p) => !p.loginId) && (
                  <Tooltip title={t("dossier.sidebar.priority.high")}>
                    <PriorityHigh color="error" className={classes.priorityHighIcon} />
                  </Tooltip>
                )}
              </ListItem>,
              <ListItem button disableGutters component={Link} to={baseUrl + "/bibliotheek"} className={classes.button} selected={history.location.pathname === baseUrl + "/bibliotheek"}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <LibraryAdd fontSize="medium" />
                  <Typography noWrap className="text">
                    {" "}
                    {t("drawing.dossier.library.title")}{" "}
                  </Typography>
                </Grid>
              </ListItem>,
              <ListItem button disableGutters component={Link} to={baseUrl + "/import"} className={classes.button} selected={history.location.pathname === baseUrl + "/import"}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <LibraryAdd fontSize="medium" />
                  <Typography noWrap className="text">
                    {t("settings.import.title")}
                  </Typography>
                </Grid>
              </ListItem>,
              <ListItem button disableGutters component={Link} to={baseUrl + "/cuf-xml"} className={classes.button} selected={history.location.pathname === baseUrl + "/cuf-xml"}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <LibraryAdd fontSize="medium" />
                  <Typography noWrap className="text">
                    {t("settings.importCufXml.title")}
                  </Typography>
                </Grid>
              </ListItem>
            ];
          case internalLayoutViewConstants.Building:
            return selectedBuilding
              ? [
                <ListItem
                  button
                  disableGutters
                  component={Link}
                  to={"/object/" + selectedBuilding.buildingNoIntern + "/kwaliteitsborging"}
                  className={classes.button}
                  selected={history.location.pathname === "/object/" + selectedBuilding.buildingNoIntern + "/kwaliteitsborging"}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <BusinessCenter fontSize="medium" />
                    <Typography noWrap className="text">
                      {" "}
                      {t("general.repairRequests")}{" "}
                    </Typography>
                  </Grid>
                </ListItem>,
              ]
              : [];
          default:
            return [];
        }
      }

      default:
        return [];
    }
  }

  const linkToHome = getLinkToHome(app);

  const menuItems = getMenuItems();

  const filterMenuItems = menuItems.filter((p) => !!p);
  const isPortrait = dimensions.height > dimensions.width;
  let noOfMenuItemsCanFit = filterMenuItems.length;

  if (isPortrait === true) {
    noOfMenuItemsCanFit = Math.floor(dimensions.width / 72);
  } else {
    noOfMenuItemsCanFit = Math.floor(dimensions.height / 65);
  }

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    dialogHeaderTitle: {
      fontSize: "14px !important",
      color: theme.palette.common.white,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    closeIcon: {
      fill: theme.palette.common.white,
      fontSize: 24,
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const open = Boolean(anchorEl);
  const surveyPopperOpen = Boolean(surveyAnchorEl || buyerMenu || projectMenu);
  const id = open ? "more-buttons-popover" : undefined;

  const projectInformationHandler = ({ route }) => {
    setSurveyAnchorEl(null);
    setSelectedAftercarePath(`/${route}`);
    history.push(`/${route}`);
  };

  const buyerMenuInformationHandler = ({ route }) => {
    setBuyerMenu(null);
    setSelectedProjectPath(`/${route}`);
    history.push(`/${route}`);
  };

  const synchroniserenHandler = () => {
    setDataSyncModal(false);
    let url = `${selectedBuilding ? "/werk/" + selectedBuilding.projectNo : ""}`;
    switch (app) {
      case apps.huisinfo:
        switch (layoutView) {
          case internalLayoutViewConstants.Project: {
            url = `${selectedBuilding ? "/werk/" + selectedBuilding.projectNo : ""}/opname`;
            break;
          }
          default:
            break;
        }
        break;
      case apps.constructionQuality:
        switch (layoutView) {
          case internalLayoutViewConstants.Project:
            url = selectedBuilding ? "/werk/" + selectedBuilding.projectNo + "/kwaliteitsborging/opname" : "";
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    setSurveyAnchorEl(null);
    history.push(`${url}/synchronize`);
  };
  return (
    !loading && (
      <>
        <Box ref={menuList} component={List} boxShadow={5} className={classes.root}>
          {filterMenuItems.map((item, index) => {
            return (noOfMenuItemsCanFit >= filterMenuItems.length || index < noOfMenuItemsCanFit - 1) && <React.Fragment key={index}>{item}</React.Fragment>;
          })}
          {noOfMenuItemsCanFit < filterMenuItems.length && (
            <React.Fragment>
              <ListItem aria-describedby={id} button disableGutters className={clsx(classes.button, "more")} onClick={handleMoreClick}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <MoreHoriz fontSize="default" />
                  <Typography noWrap className={clsx("text", classes.capitalize)}>
                    {" "}
                    {t("general.more")}{" "}
                  </Typography>
                </Grid>
              </ListItem>
              <Popover
                id={id}
                disableRestoreFocus
                open={open}
                anchorEl={anchorEl}
                PaperProps={{ style: { boxShadow: "none" } }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <div className={classes.emojiContainer} onClick={() => setAnchorEl(null)}>
                  {filterMenuItems.map((item, index) => index >= noOfMenuItemsCanFit - 1 && <React.Fragment key={index}>{item}</React.Fragment>)}
                </div>
              </Popover>
            </React.Fragment>
          )}
          <Popover
            id={open ? "opname-app" : undefined}
            open={surveyPopperOpen}
            anchorEl={surveyAnchorEl || buyerMenu || projectMenu}
            onClose={() => {
              setSurveyAnchorEl(null);
              setBuyerMenu(null);
              setProjectMenu(null);
            }}
            disableRestoreFocus
            PaperProps={{ className: classes.popoverStyle }}
            anchorOrigin={{
              vertical: noOfMenuItemsCanFit < filterMenuItems.length ? "bottom" : "top",
              horizontal: widthMdUp ? "right" : "center",
            }}
            transformOrigin={{
              vertical: widthMdUp ? "top" : "bottom",
              horizontal: widthMdUp ? "left" : "center",
            }}
          >
            <Container style={{ padding: 15 }}>
              <Box component="span" display={{ xs: "block", sm: "none" }} className={classes.popoverMainContainer}>
                <Typography className={classes.title}>{app === apps.aftercare ? "projectinformation" : "Opname app"}</Typography>
                <span onClick={() => setSurveyAnchorEl(null)}>
                  <Close />
                </span>
              </Box>
              <Grid container spacing={2}>
                {buyerMenu &&
                  housingwishes.map((p) => (
                    <Grid item xs={4} sm={6} md={6} lg={6} xl={6} className={classes.popoverContainerGrid}>
                      <Box className={classes.box} onClick={() => buyerMenuInformationHandler(p)}>
                        {p.Icon ? <p.Icon /> : <img src={p.icon} alt="icon" className={classes.popOverBoxIcon} />}

                        <Typography className={classes.popOverBoxText}>{t(p.label)}</Typography>
                      </Box>
                    </Grid>
                  ))}

                {app === apps.aftercare ? (
                  <>
                    {projectMenu &&
                      projectInformation.map((p) => (
                        <Grid item xs={4} sm={6} md={6} lg={6} xl={6} className={classes.popoverContainerGrid}>
                          <Box className={classes.box} onClick={() => projectInformationHandler(p)}>
                            {p.Icon ? <p.Icon /> : <img src={p.img} alt="icon" className={classes.popOverBoxIcon} />}
                            <Typography className={classes.popOverBoxText}>{t(p.label)}</Typography>
                          </Box>
                        </Grid>
                      ))}

                    {isBuyer && (
                      <Grid item xs={4} sm={6} md={6} lg={6} xl={6} className={classes.popoverContainerGrid}>
                        <Box className={classes.box} onClick={() => projectInformationHandler({ route: "mijn-woning" })}>
                          {/* <img
                        src={wkbcheck}
                        alt="icon"
                        className={classes.popOverBoxIcon}
                      /> */}
                          <Icon fontSize="default">rule</Icon>
                          <Typography className={classes.popOverBoxText}>{t("layout.menuitem.projectinformation.myhouse")}</Typography>
                        </Box>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {projectMenu &&
                      projectInformation.map((p) => (
                        <Grid item xs={4} sm={6} md={6} lg={6} xl={6} className={classes.popoverContainerGrid}>
                          <Box className={classes.box} onClick={() => projectInformationHandler(p)}>
                            {p.Icon ? <p.Icon /> : <img src={p.img} alt="icon" className={classes.popOverBoxIcon} />}
                            <Typography className={classes.popOverBoxText}>{t(p.label)}</Typography>
                          </Box>
                        </Grid>
                      ))}

                    {projectMenu && isBuyer && (
                      <Grid item xs={4} sm={6} md={6} lg={6} xl={6} className={classes.popoverContainerGrid}>
                        <Box className={classes.box} onClick={() => projectInformationHandler({ route: "mijn-woning" })}>
                          {/* <img
                        src={wkbcheck}
                        alt="icon"
                        className={classes.popOverBoxIcon}
                      /> */}
                          <Icon fontSize="default">rule</Icon>
                          <Typography className={classes.popOverBoxText}>{t("layout.menuitem.projectinformation.myhouse")}</Typography>
                        </Box>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Container>
          </Popover>
          <Dialog onClose={dataSyncModalHandler} aria-labelledby="customized-dialog-title" className={classes.dataSyncDialog} open={dataSyncModal}>
            <DialogTitle id="customized-dialog-title" onClose={dataSyncModalHandler}>
              <Typography className={classes.dialogHeaderTitle}> Project instellingen</Typography>
            </DialogTitle>
            <DialogContent dividers className={classes.dataSyncDialogContainer}>
              <FormGroup>
                <Typography component="div">
                  <Grid container className={classes.dataSyncContent} alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>Digitaal ondertekenen</Typography>
                    </Grid>
                    <Grid item>
                      <AntSwitch checked={syncSwitch.signingChecked} onChange={syncSwitchHandleChange} name="signingChecked" />
                    </Grid>
                  </Grid>
                </Typography>
              </FormGroup>
              <FormGroup>
                <Typography component="div">
                  <Grid container className={classes.dataSyncContent} alignItems="center" xs={12} spacing={1}>
                    <Grid item>
                      <Typography>
                        {t("survey.sync.label")} <InfoOutlined color="primary" cursor="pointer" onClick={synchroniserenHandler} />{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <AntSwitch checked={syncSwitch.synchroniserenChecked} onChange={syncSwitchHandleChange} name="synchroniserenChecked" />
                    </Grid>
                  </Grid>
                </Typography>
              </FormGroup>
            </DialogContent>
          </Dialog>
        </Box>
        {
          !!subMenuDetails && <SubMenu
            items={subMenuDetails.subMenuItems}
            isOpenSubMenu={!!subMenuDetails}
            handleSubMenuOpen={handleSubMenuClick}
            title={subMenuDetails.title}
            history={history}
          />
        }

      </>
    )
  );
}
