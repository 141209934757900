import Axios from "axios";
import { URLS } from "./urls";


export const getAllContracts = (data) => Axios.post(URLS.GET_ALL_CONTRACTS, data);
export const getAllTemplates = () => Axios.get(URLS.GET_ALL_TEMPLATES);
export const getTemplatePreview = (templateId) => Axios.get(URLS.GET_TEMPLATE_PREVIEW + templateId);
export const createContract = (data) => Axios.post(URLS.CREATE_CONTRACT , data);
export const getContractDetails = (contractId) => Axios.get(URLS.GET_CONTRACT_DETAILS + contractId);
export const updateContractKeyValue = (data, contractId) => Axios.post(URLS.UPDATE_CONTRACT_KEY_VALUE + contractId , data);
export const getContractDraftAndUpdateStatus = (contractId) => Axios.get(URLS.GET_CONTRACT_DRAFT_AMD_UPDATE_STATUS + contractId);
export const updateContractDraft = (data, contractId) => Axios.post(URLS.UPDATE_CONTRACT_DRAFT + contractId ,data);
export const sendForDigitalSigning = (contractId) => Axios.post(URLS.SEND_FOR_DIGITAL_SIGNING +"?contractId="+ contractId );
export const getScriveDocHistory = (contractId , language) => Axios.get(URLS.GET_SCRIVE_DOC_HOSTORY + contractId +"?lang="+language );
export const sendReminder = (contractId) => Axios.get(URLS.CONTRACT_SEND_REMINDER + contractId );
export const deleteContracts = (data) => Axios.delete(URLS.DELETE_CONTRACTS, data );
export const setProlong = (contractId, days) => Axios.get(URLS.SET_PROLONG + contractId + "?days="+ days);
export const cancelContract = (contractId) => Axios.get(URLS.CANCEL_CONTRACT + contractId );

// Finance api

export const getFinanceForBuilding = (buildingId) => Axios.get(URLS.GET_FINANCE_FOR_BUILDING + buildingId);
export const updateFinance = (data) => Axios.post(URLS.UPDATE_FINANCE, data);