import React, { useState, useEffect, useRef } from "react";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw, convertFromRaw, EditorState, Modifier } from "draft-js";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import { ThemeProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";

import { useTheme } from "@mui/styles";

export default function RichTextEditor(props) {
  const {
    assignmentDetail,
    readOnly,
    onChange,
    value,
    showToolbar,
    textToInsertAtCursor,
    richTextToInsertAtCursor,
    onCompleteTextInsert,
    applyMinStyles,
    ...rest
  } = props;
  const rteRef = useRef(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [currentValue, setCurrentValue] = useState("");
  const [injectEditorState, setInjectEditorState] = useState(false);
  const [replaceAtomic, setReplaceAtomic] = useState("");
  const [initialValue, setInitialValue] = useState(value);
  const theme = useTheme();
  const [localTheme, setLocalTheme] = useState(theme);

  const minHeightEditor = showToolbar === true ? 160 : 20;
  const editorContainerMaxHeight = applyMinStyles ? "240px" : "40vh";

  const local_theme_overrides = {
    overrides: {
      MUIRichTextEditor: {
        root: {
          //backgroundColor: "#ebebeb",
          //width: "90%"
        },
        container: {
          fontFamily: "inherit",
          margin: 0,
          //position: 'relative',
          '& [class*="Autocomplete-container"]': {
            //position: 'fixed'
            maxHeight: "calc(100% + 16px)",
            overflow: "auto",
          },
        },
        editor: {
          //backgroundColor: "#ebebeb",
          //padding: "20px",
          minHeight: 20,
          height: "inherit",
        },
        editorContainer: {
          margin: 0,
          minHeight: minHeightEditor,
          maxHeight: editorContainerMaxHeight,
          overflow: "auto",
          padding: 8,
          borderRadius: 4,
          "& *": {
            maxWidth: "100%",
          },
        },
        placeHolder: {
          position: "relative",
          marginBottom: -20,
        },
        toolbar: {
          //margin: '-8px 0 0',
          whiteSpace: "nowrap",
          overflow: "auto",
          display: showToolbar === true ? "" : "none",
          //borderTop: "1px solid gray",
          //backgroundColor: "#ebebeb"
          "& > button": {
            marginRight: theme.spacing(1),
          },
          "& > button:last-child": {
            marginRight: theme.spacing(0),
          },
        },
      },
    },
  };

  useEffect(() => {
    setLocalTheme(Object.assign({ ...theme }, local_theme_overrides));
  }, [showToolbar]);

  useEffect(() => {
    clearTimeout(window.chatTextTimeoutId);
    if (value !== currentValue || initialValue !== "") {
      let rawData = mdToDraftjs(value);
      //to update the default value provided initially. it was resseting to empty again and again due to atomic replace code.
      if (value !== initialValue && initialValue !== "") {
        rawData = mdToDraftjs(initialValue);
        setInitialValue("");
      }
      rteRef.current.focus();
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.createWithContent(convertFromRaw(rawData))
      );
      setEditorState(newEditorState);
      setInjectEditorState(true);
    }
  }, [value]);

  useEffect(() => {
    if (textToInsertAtCursor && textToInsertAtCursor.trim() !== "") {
      clearTimeout(window.chatTextTimeoutId);
      addContent(textToInsertAtCursor);
    }
  }, [textToInsertAtCursor]);

  useEffect(() => {
    if (richTextToInsertAtCursor && richTextToInsertAtCursor.trim() !== "") {
      clearTimeout(window.chatTextTimeoutId);
      addContent(richTextToInsertAtCursor, true);
    }
  }, [richTextToInsertAtCursor]);

  const onChangeData = (data) => {
    setEditorState(data);
    const currentContent = data.getCurrentContent();
    const content = draftjsToMd(convertToRaw(currentContent));
    if (content.includes("\n![](undefined)\n")) {
      if (replaceAtomic !== "") {
        const richText = replaceAtomic.slice();
        setReplaceAtomic("");
        onChange(content.replace("![](undefined)\n", richText));
      }
    } else {
      setCurrentValue(content);
      if (content !== value) onChange(content);
      setInjectEditorState(false);
    }
  };

  const onChangeText = function (data) {
    if (injectEditorState) {
      clearTimeout(window.chatTextTimeoutId);
      onChangeData(data);
    } else {
      clearTimeout(window.chatTextTimeoutId);
      window.chatTextTimeoutId = setTimeout(() => {
        clearTimeout(window.chatTextTimeoutId);
        onChangeData(data);
      }, 100);
    }
  };

  function addContent(value, isRichText = false) {
    if (isRichText === false) {
      const es = editorState ? editorState : EditorState.createEmpty();

      const selection = es.getSelection().set("hasFocus", true);
      const contentState = es.getCurrentContent();
      const ncs = Modifier.replaceText(contentState, selection, value);
      const final = EditorState.push(es, ncs, "insert-fragment");

      setEditorState(final);
    } else {
      const rawData = mdToDraftjs(value);
      const convertedRawData = convertFromRaw(rawData);
      if (editorState) {
        var contentState = editorState.getCurrentContent();
        var selectionState = editorState.getSelection();

        var afterRemoval = Modifier.removeRange(
          contentState,
          selectionState,
          "backward"
        );

        var targetSelection = afterRemoval.getSelectionAfter();
        var afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
        var insertionTarget = afterSplit.getSelectionAfter();

        var asAtomicBlock = Modifier.setBlockType(
          afterSplit,
          insertionTarget,
          "insert-fragment"
        );

        var withAtomicBlock = Modifier.replaceWithFragment(
          asAtomicBlock,
          insertionTarget,
          convertedRawData.getBlockMap()
        );

        var newContent = withAtomicBlock.merge({
          selectionBefore: selectionState,
          selectionAfter: withAtomicBlock
            .getSelectionAfter()
            .set("hasFocus", true),
        });

        var final = EditorState.push(
          editorState,
          newContent,
          "insert-fragment"
        );
        setEditorState(final);
      } else {
        const newEditorState = EditorState.createWithContent(convertedRawData);
        setEditorState(newEditorState);
      }
    }
    onCompleteTextInsert();
    setInjectEditorState(true);
    rteRef.current.focus();
  }

  const MarkDownCustomControl = (props) => {
    const { blockProps } = props;
    const { value } = blockProps; // Get the value provided in the TAutocompleteItem[]

    setReplaceAtomic(value);
    return <></>;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={localTheme}>
        <MUIRichTextEditor
          ref={rteRef}
          inheritFontSize
          toolbarButtonSize="small"
          readOnly={readOnly}
          controls={[
            "title",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "undo",
            "redo",
            "link",
            //"media",
            "numberList",
            "bulletList",
            "quote",
            "code",
            "clear",
          ]}
          customControls={[
            {
              name: "markdown",
              type: "atomic",
              atomicComponent: MarkDownCustomControl,
            },
          ]}
          //inlineToolbar={true}
          onChange={onChangeText}
          draftEditorProps={injectEditorState === true && { editorState }}
          {...rest}
        />
        {
          //<IconButton aria-label="Chat" onClick={addContent}>
          //    <TextFormat />
          //</IconButton>
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
