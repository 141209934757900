import { Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react'

export default function AntSwitch(props) {
  return (<MuiSwitch {...props} />)
}
const MuiSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 18,
    [theme.breakpoints.between('xs', 'md')]: {
      width: 30,
      height: 16,
    },
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(21px)',
      [theme.breakpoints.between('xs', 'md')]: {
        transform: 'translateX(14px)',
      },
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    [theme.breakpoints.between('xs', 'md')]: {
      width: 12,
      height: 12,
    },
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '50px',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);