import React from "react"
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery, Divider, Slide } from "@mui/material"
import { useTheme } from "@mui/styles"
import { makeStyles } from "@mui/styles"
import { Link } from "react-router-dom"
import clsx from "clsx" 

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1100
  },
  slideRight: {
    zIndex: 1100,
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    height: "auto",
    minHeight: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 20px 2px #0000001A",
    [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
      marginLeft: theme.spacing(0),
      width: "100%",
      maxWidth: "100%",
      minHeight: "auto",
      overflowY: "auto",
      borderRadius: theme.spacing(2, 2, 0, 0),
      bottom: 56,
      height: "auto",
    },
  },
  heading: {
    padding: theme.spacing(3),
    fontWeight: "bold",
    [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      alignItems: "center",
      textAlign: "center",
    },
    "& .MuiTypography-root": {
      margin: theme.spacing(2, 0, 1, 0),
    },
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    height: "auto",
    maxHeight: "calc(100vh - 170px)",
    overflowY: "auto",
    paddingTop: theme.spacing(0),
    "& :hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[200],
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
      maxHeight: "calc(100vh - 200px)",
    },
  },
  list: {
    height: theme.spacing(7),
    padding: theme.spacing(0, 2.5),
    alignItems: "center",
    color: theme.palette.text.secondary,
    textDecoration: "none !important",
    "& .MuiTypography-root": {
      fontWeight: theme.typography.fontWeightBold,
      [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
          fontWeight: 400,
          fontSize: theme.spacing(2),
      } 
    },
    "& .MuiSvgIcon-root": {
      fontWeight: theme.typography.fontWeightBold,
      height: theme.spacing(4),
      width: theme.spacing(4),
      [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
        fontWeight: 400,
        height: theme.spacing(4),
        width: theme.spacing(4),
      }
    },
    "& :hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[200],
    },
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[200],
     "& .MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
  },
  listItemText: {
    fontWeight: "bolder",
  },
  headerLine: {
    display: "none",
    width: 60,
    height: 2,
    backgroundColor: "grey",
    marginBottom: 12,
    color: theme.palette.grey[400],
    [`${theme.breakpoints.down("sm")} or (orientation: portrait)`]: {
        display: "block"
    }
  },
}))

export default function SubMenu({ isOpenSubMenu, items = [], handleSubMenuOpen, title, history }) {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  })
  const isPortrait = useMediaQuery("(orientation: portrait)", {
    noSsr: true,
  })

  const subMenuItem = () => {
    return items.map((item) => {
      return (
        <ListItem component={Link} to={item.to} className={clsx(classes.list, history.location.pathname === item.to && classes.selected)} onClick={() => handleSubMenuOpen(false)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} className={classes.listItemText} />
        </ListItem>
      )
    })
  }

  return (
    <div className={classes.outerContainer} onClick={() => handleSubMenuOpen(false)}>
      <Slide direction={isSmallScreen || isPortrait ? "up" : "right"} in={isOpenSubMenu} mountOnEnter unmountOnExit>
        <Grid container xs={12} sm={5} md={4} lg={3} direction="column" className={classes.slideRight}>
          <Grid item  className={classes.heading}>
            <div className={classes.headerLine}></div>
            <Typography variant={isSmallScreen || isPortrait ? "h6" : "h5"}>{title}</Typography>
          </Grid>
          <Divider />
          <Grid item>
            <List className={classes.lists}>
              {subMenuItem()}
            </List>
          </Grid>
        </Grid>
      </Slide>
    </div>
  )
}
