import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Toolbar, IconButton, Typography, Grid, Badge, MenuItem, Menu, Button, Divider, Avatar, useMediaQuery, Hidden, Tooltip } from "@mui/material";
import { AccountCircle, ExitToApp as LogOutIcon, Chat, Apps, HomeWorkOutlined, ArrowRight, HouseOutlined, Bookmarks, LockRounded as LockRoundedIcon, Close, ShoppingBasket, Info } from "@mui/icons-material";
import { history, getCommonArray, getNameInitials, getAppsBuildings, useInternetStatusDetector, formatDate } from "../_helpers";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { userAccountTypeConstants, apps, appsInfo, getLinkToHome, internalLayoutViewConstants } from "../_constants";
import clsx from "clsx";
import SelectProjectInternal from "./SelectProjectInternal";
import SelectBuildingInternal from "./SelectBuildingInternal";
import MessagesDetailsPopover from "./MessagesDetailsPopover";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { DialogContent } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import withStyles from "@mui/styles/withStyles";

const { webApiUrl } = window.appConfig;
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    bottom: 0,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  appBarWrapper: {
    height: 64,
    [theme.breakpoints.down("md")]: {
      height: 64,
    },
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      height: 52,
    },
    "& .MuiAppBar-positionFixed": {
      padding: "0 !important",
      "& .MuiToolbar-root": {
        borderBottomColor: theme.palette.primary.main,
        borderBottomStyle: "solid",
        borderBottomWidth: theme.spacing(0.5),
      },
    },
  },
  logoAnchor: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 216px)",
    },
  },
  logo: {
    maxHeight: 36,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  buttonAvatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    margin: theme.spacing(-0.5),
    color: "inherit",
    background: "transparent",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    "&:hover": {
      color: "inherit",
    },
  },
  projectButton: {
    maxWidth: "45%",
    textTransform: "none",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  projectInfoHeader: {
    overflow: "auto",
  },
  // headerContainer: {
  //   overflow: "hidden",
  // },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  accountMenu: {
    maxWidth: 300,
    width: 300,
  },
  userInfo: {
    padding: theme.spacing(0, 1, 1, 2),
    minWidth: 270,
  },
  rightSection: {
    display: "flex",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuButton: {
    margin: theme.spacing(0, 1, 0, -1),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  list: {
    width: 250,
  },
  projectAppbar: {
    top: 64,
  },
  projectToolbar: {
    minHeight: 30,
  },
  selectBox: {
    color: "inherit",
    "& svg": {
      color: "inherit",
    },
  },
  selectBuilding: {
    width: "100%",
    textAlign: "right",
  },
  syncIcon: {
    color: "inherit",
  },
  syncAnimated: {
    animation: "$rotation 2s infinite linear",
  },
  "@keyframes rotation": {
    from: { transform: "rotate(360deg)" },
    to: { transform: "rotate(-360deg)" },
  },
  dataSyncDialog: {
    "& .MuiPaper-rounded": {
      borderRadius: 10,
      minWidth: "384px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "270px",
      },
    },
  },
  dataSyncDialogContainer: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dataSyncContent: {
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    margin: 0,
    padding: theme.spacing(0),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  dialogHeaderTitle: {
    fontSize: "14px !important",
    color: theme.palette.common.white,
    flexGrow: 1,
  },
  closeButton: {
    color: theme.palette.grey[500],
    border: "0px !important",
    outline: "none !important",
  },
  closeIcon: {
    fill: theme.palette.common.white,
    fontSize: 24,
  },
  buttonLink: {
    padding: theme.spacing(1),
    border: "0px !important",
    outline: "none !important",
    "& > .MuiButton-label": {
      textDecoration: "underline",
    },
  },
  dialogContent: {
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.grey[700],
  },
  online: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.main
    },
  },
  offline: {
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.main
    },
  },
  onlineServiceWorkerNotReady:{
    "& .MuiBadge-badge": {
      backgroundColor:  theme.palette.warning.main,
      color: theme.palette.warning.main
    }
  },
  currentVersionDate: {
    fontSize: 12,
    marginLeft: 5,
  },
  versionInfo: {
    fontSize: 12,
  },
}));

export default function PrimaryAppBar(props) {
  const { dashboardCount, selectedBuilding, user, app, layoutView, recentUnreadMessages, recentSavedMessages } = props;
  const currentVersionDate = useSelector((state) => state.currentVersionDate);
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changePwdOpen, setChangePwdOpen] = React.useState(false);
  const { rights, selected } = useSelector((state) => state.buildings);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [dataSyncModal, setDataSyncModal] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const userApps = getCommonArray(
    user.availableApps,
    appsInfo.map((x) => x.appId)
  );
  const isBuyer = user.type === userAccountTypeConstants.buyer;
  const linkToHome = app === apps.aftercare && isBuyer ? "/nazorg" : getLinkToHome(app);
  const isSurveyModule = history.location.pathname.includes("/opname");
  const menuId = "primary-account-menu";
  const logo = webApiUrl + (selectedBuilding && (app !== apps.aftercare || user.type === userAccountTypeConstants.buyer) ? "api/home/ProjectLogo/" + selectedBuilding.projectId : "api/Config/WebLogo");
  const userInitials = getNameInitials(user.name);
  const isOnline = useInternetStatusDetector();
  const [isServiceWorkerReady, setIsServiceWorkerReady] = useState(false)
  const canShowProjectInfo = (app !== apps.aftercare || isBuyer) && app !== apps.settingModule;

  useEffect(() => {
    if(window?.serviceWorkerStatus === "ready"){
      setIsServiceWorkerReady(true)
    }
  }, [window?.serviceWorkerStatus])
  

  useEffect(() => {
    if (selected) {
      getAppsBuildings({
        app: app || "",
        projectId: selected.projectId || "",
        sync: 1,
      })
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, [app, selected]);

  const onChangePassword = () => {
    setChangePwdOpen(true);
  };

  const changePwdClose = (params) => {
    setChangePwdOpen(false);
  };

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  // function handleDrawerToggle() {
  //     setMenuOpen(!menuOpen);
  // }

  const dataSyncToggler = () => {
    setDataSyncModal(!dataSyncModal);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      disableRestoreFocus
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.accountMenu}
    >
      <div className={classes.userInfo}>
        <Grid container wrap="nowrap" spacing={1} alignItems="center" justifyContent="center">
          <Grid item>
            <Avatar alt={userInitials} src={webApiUrl + "api/home/GetPersonPhoto/" + user.personId}>
              {userInitials}
            </Avatar>
            {
              //<Avatar>
              //    <AccountCircle />
              //</Avatar>
            }
          </Grid>
          <Grid item xs>
            <Typography variant="body1" className={classes.grow}>
              {user.name}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      {canShowProjectInfo && !matches && (
        <>
          {selectedBuilding && (
            <React.Fragment>
              <MenuItem
                onClick={() => {
                  history.push(linkToHome);
                }}
              >
                <HomeWorkOutlined fontSize="medium" />
                &nbsp;
                <Typography noWrap>{selectedBuilding.projectName}</Typography>
              </MenuItem>
              {layoutView === internalLayoutViewConstants.Building && (
                <MenuItem
                  onClick={() => {
                    history.push("/object/" + selectedBuilding.buildingNoIntern);
                  }}
                >
                  <HouseOutlined fontSize="medium" />
                  &nbsp;
                  <Typography noWrap>{selectedBuilding.buildingNoIntern}</Typography>
                </MenuItem>
              )}
              {isBuyer && (
                <MenuItem
                  onClick={() => {
                    history.push(linkToHome);
                  }}
                >
                  <HouseOutlined fontSize="medium" />
                  &nbsp;
                  <Typography noWrap>{app === apps.aftercare ? selectedBuilding.address || selectedBuilding.buildingNoExtern : selectedBuilding.buildingNoExtern}</Typography>
                </MenuItem>
              )}
            </React.Fragment>
          )}
          <Divider />
        </>
      )}
      <LanguageSelector handleClose={() => {}} />
      <MenuItem
        onClick={() => {
                  history.push("/profile/user");
                  handleMenuClose();
        }}
      >
        <PersonIcon fontSize="medium" />
        &nbsp;
        <span>{t("layout.navbar.userProfile")}</span>
      </MenuItem>
          {
              !!user?.organisationId &&
              <MenuItem
                  onClick={() => {
                      history.push("/profile/organisation");
                      handleMenuClose();
                      }}
              >
                  <BusinessIcon fontSize="medium" />
                  &nbsp;
                  <span>{t("layout.navbar.organisationProfile")}</span>
              </MenuItem>
          }
      <MenuItem
        onClick={() => {
          onChangePassword();
        }}
      >
        <LockRoundedIcon fontSize="medium" />
        &nbsp;
        <span>{t("layout.navbar.changepassword")}</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push("/login");
        }}
      >
        <LogOutIcon />
        &nbsp;
        <span>{t("layout.navbar.logout")}</span>
      </MenuItem>
      <MenuItem disabled>
        <Info />
        &nbsp;
        <span className={classes.versionInfo} title={`Huisinfo v${process.env.REACT_APP_VERSION} (${formatDate(new Date(currentVersionDate))})`}>
          {`Huisinfo v${process.env.REACT_APP_VERSION} `}
          <span>{`(${formatDate(new Date(currentVersionDate))})`}</span>
        </span>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={clsx(classes.appBarWrapper, classes.grow)}>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Grid container alignItems="center" wrap="nowrap">
            {
              <IconButton edge="start" aria-label="Apps" color="inherit" disabled={userApps.filter((x) => x !== app && appsInfo.find((y) => y.appId === app)).length === 0} className={classes.button} component={Link} to="/" fontSize="medium">
                <Apps />
              </IconButton>
            }
                      {
                          !isBuyer && Date.now() <= new Date("2024-01-15") &&
                          <IconButton aria-label="Christmas" color="inherit" className={classes.button} onClick={() => { localStorage.removeItem(`christmas_${user.id}_played`) }} fontSize="medium">
                                  <img src={"/Content/icon/santa-dance-christmas-music.gif"} alt="X" style={{ width: 42, margin: theme.spacing(-1, 0) }} />
                          </IconButton>
                      }
            <div className={clsx(classes.grow, classes.projectInfoHeader)}>
              <Link to={linkToHome} className={classes.logoAnchor}>
                <img src={logo} alt="Huisinfo" className={classes.logo} />
              </Link>
              {canShowProjectInfo && matches && (
                <React.Fragment>
                  {selectedBuilding && (
                    <React.Fragment>
                      <Button color="primary" className={classes.projectButton} component={Link} to={linkToHome}>
                        <Typography variant="h6" noWrap>
                          {selectedBuilding.projectName}
                        </Typography>
                      </Button>
                      {layoutView === internalLayoutViewConstants.Building && (
                        <React.Fragment>
                          <ArrowRight />
                          <Button color="primary" className={classes.projectButton} component={Link} to={"/object/" + selectedBuilding.buildingNoIntern}>
                            <Typography variant="h6" noWrap>
                              {selectedBuilding.buildingNoIntern}
                            </Typography>
                          </Button>
                        </React.Fragment>
                      )}
                      {isBuyer && (
                        <React.Fragment>
                          <ArrowRight />
                          <Typography color="primary" variant="h6" noWrap>
                            {app === apps.aftercare ? selectedBuilding.address : selectedBuilding.buildingNoExtern}
                          </Typography>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            <div style={{ display: "flex" }}>
              {app === apps.huisinfo && isBuyer === true && (
                <React.Fragment>
                  <IconButton aria-label={t("chat.messages")} color="inherit" className={classes.button} component={Link} to="/berichten" size="large">
                    <Badge badgeContent={dashboardCount && dashboardCount.messageCount} color="secondary">
                      <Chat />
                    </Badge>
                  </IconButton>
                  <Hidden only="xs">
                    <IconButton
                      aria-label={t("layout.navbar.systemmessages")}
                      color="inherit"
                      className={classes.button}
                      component={Link}
                      to={{
                        pathname: "/berichten",
                        state: {
                          showImportantMessages: true,
                        },
                      }}
                      fontSize="medium"
                    >
                      <Badge badgeContent={dashboardCount && dashboardCount.systemMessageCount} color="primary">
                        <Bookmarks />
                      </Badge>
                    </IconButton>
                  </Hidden>
                  {isBuyer === true && !selected.isContract && (
                    <IconButton
                      aria-label="ShoppingCart"
                      color="inherit"
                      className={classes.button}
                      component={Link}
                      to={{
                        pathname: "/keuzelijst",
                        state: {
                          bottomCartExpanded: dashboardCount && dashboardCount.selectedOptionsCount > 0,
                        },
                      }}
                      size="large"
                    >
                      <Badge badgeContent={dashboardCount && dashboardCount.selectedOptionsCount} color="secondary">
                        <ShoppingBasket />
                      </Badge>
                    </IconButton>
                  )}
                </React.Fragment>
              )}
              {/* {
                                isSurveyModule && <Tooltip title={t((isSyncing || (isManualSync && canSyncManual)) ? 'Downloading' : 'Synchroniseren')}>
                                    <IconButton onClick={syncHandler} color='inherit' >
                                        <Sync className={clsx(classes.syncIcon, (isSyncing || (canSyncManual && isManualSync)) && classes.syncAnimated)} />
                                    </IconButton>
                                </Tooltip>
                            } */}
              {canShowProjectInfo && selectedBuilding && (layoutView === internalLayoutViewConstants.Project || layoutView === internalLayoutViewConstants.Building) && (
                <React.Fragment>
                  <SelectProjectInternal layoutView={layoutView} recentUnreadMessages={recentUnreadMessages} recentSavedMessages={recentSavedMessages} />
                  {(app === apps.huisinfo || isBuyer) && <SelectBuildingInternal layoutView={layoutView} />}
                </React.Fragment>
              )}
              {app === apps.huisinfo && rights["menu.canShowMenu"] && (
                <React.Fragment>
                  {selectedBuilding && (
                    <Hidden only={isSurveyModule && "xs"}>
                      <React.Fragment>
                        <MessagesDetailsPopover
                          api={"GetTopUnreadMessages"}
                          projectNo={selectedBuilding.projectNo}
                          projectName={selectedBuilding.projectName}
                          badgeContent={dashboardCount.totalUnreadChatMessages}
                          projectId={selectedBuilding.projectId}
                          badgeColor="secondary"
                        >
                          <Chat />
                        </MessagesDetailsPopover>
                      </React.Fragment>
                    </Hidden>
                  )}
                  {selectedBuilding && (
                    <Hidden only="xs">
                      <MessagesDetailsPopover
                        isSavedMessages={true}
                        api={"GetTopSavedMessages"}
                        projectNo={selectedBuilding.projectNo}
                        projectId={selectedBuilding.projectId}
                        projectName={selectedBuilding.projectName}
                        badgeContent={dashboardCount.totalCountSavedMessages}
                        badgeColor="primary"
                        invisible={!dashboardCount.totalCountSavedMessages}
                      >
                        <Bookmarks />
                      </MessagesDetailsPopover>
                    </Hidden>
                  )}
                </React.Fragment>
              )}
              <Tooltip title={t("layout.navbar.account")}>
                <IconButton edge="end" aria-label={user.name} aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit" size="large">
                  <StyledBadge
                    className={isOnline ? (!isServiceWorkerReady ? classes.onlineServiceWorkerNotReady :  classes.online): classes.offline}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar className={classes.buttonAvatar} alt={userInitials} src={webApiUrl + "api/home/GetPersonPhoto/" + user.personId}>
                      <AccountCircle fontSize="medium" />
                    </Avatar>
                  </StyledBadge>
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {changePwdOpen && <ChangePasswordModal onChangePasswordClose={changePwdClose} changePwdOpen={changePwdOpen} />}
      <Dialog onClose={dataSyncToggler} aria-labelledby="customized-dialog-title" className={classes.dataSyncDialog} open={dataSyncModal}>
        <DialogTitle className={classes.root}>
          <Typography className={classes.dialogHeaderTitle}>{""}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={dataSyncToggler} size="large">
            <Close className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dataSyncDialogContainer}>
          <Typography className={classes.dialogContent}>{t("layout.navbar.projectNotSync")}</Typography>
          <Typography className={classes.dialogContent}>
            <Button
              onClick={() => {
                setDataSyncModal(false);
                history.push(`${selectedBuilding ? "/werk/" + selectedBuilding.projectNo : ""}${app === 3 ? "/kwaliteitsborging" : ""}/opname/synchronize`);
              }}
              className={classes.buttonLink}
              color="primary"
              variant="outlined"
            >
              {t("layout.navbar.clickHere")}
            </Button>
            {t("layout.navbar.toContinue")}
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
