import React from "react";
import { connect } from "react-redux";
import { Container, Grid, Typography, AppBar, Toolbar, IconButton, MenuItem, Button, TextField, FormControlLabel, Checkbox, Divider, Popover, Menu, Snackbar, Tooltip, Hidden, Slide, Box, Portal, Stack } from "@mui/material";
import { withTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { ArrowBack, Clear, Build, Contacts, Assignment, Schedule, Share, FileCopyOutlined, LocationOn, PictureAsPdf, Draw } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { history, nl2br, formatDate, authHeader, validateFile, formatTime, getMimeTypefromString } from "../../_helpers";
import ContactCard from "./RepairRequestContactCard";
import { Alert } from "@mui/material";
import Dropzone from "../../components/Dropzone";
import WorkOrderPDF from "./WorkOrderPDF";
import { URLS } from "../../apis/urls";
import RepairRequestForm from "../Survey/RepairRequestForm";
import DocumentViewer from "../../components/DocumentViewer";
import { commonActions } from "../../_actions/common.actions";
import WorkOrderAppointment from "./WorkOrderAppointment";
import NotificationPanel from "./NotificationPanel";
import SignatureModal from "./SignatureModal";
import { copyToClipboard } from "../../_helpers/copyToClipboardHelper";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  appBar: {},
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    padding: theme.spacing(2, 3, 4),
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  block: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 3),
    "&.collapsed": {
      paddingBottom: theme.spacing(1),
    },
  },
  subHeader: {
    padding: theme.spacing(2),
    "& svg": {
      fontSize: 30,
    },
  },
  pdfButtonTitle: {
    display: "flex",
    alignItems: "center",
  },
  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  locationPreview: {
    maxWidth: "100%",
    // maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
    objectFit: "contain",
  },
  locationContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  imageGallery: {
    width: "100%",
    maxHeight: "15vw",
    height: "calc(100% + 4px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "28vw",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "56vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "85vw",
    },
  },
  dropzoneContainer: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    margin: "-2px auto -4px",
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  pdfTextWrapper: {
    position: "absolute",
    top: "8px",
    width: "100%",
    textAlign: 'center',
    fontSize: "24px",
    fontWeight: "bolder",
  },
  attachmentsWrapper: {
    position: "relative"
  },
  galleryImageTile: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
  },
  halfWidth: {
    width: "50%",
    verticalAlign: "top",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      width: 350,
      maxWidth: "100%",
    },
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&.big": {
      width: "100%",
      height: 0,
      padding: "50%",
      cursor: "pointer",
      //borderRadius: theme.spacing(1)
    },
  },
  thumbnailOrg: {
    width: "calc(100% - 16px)",
    margin: theme.spacing(-1, 0),
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    //overflow: 'hidden',
    background: "none",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0, 0, 0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      "& caption": {
        display: "none",
      },
      //marginBottom: theme.spacing(0.5)
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  formControlCheckList: {
    paddingLeft: theme.spacing(0.5),
    "& .MuiTypography-body1": {
      lineHeight: 1,
    },
  },
  
  
});

class Page extends React.Component {
  state = {
    attachmentIndex: null,
    workOrder: null,
    building: null,
    shareAnchorEl: null,
    openRepairRequestMap: false,
    document: null,
    filesSizeIsInValid: false,
    fileUploadErrorMessage: null,
    isSignatureModalOpen: false,
    anchorElHandleWorkOrder : null,
    snackbar: {
      open: false,
      message: "",
      severity: ""
    }
  };


  componentDidMount() {
    this.GetWorkOrderDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId.toUpperCase() !== this.props.selected.buildingId.toUpperCase()) {
      //this.GetWorkOrderDetails();
    }
    if ((!prevState.workOrder && !!this.state.workOrder) || (!!prevState.workOrder && !!this.state.workOrder && prevState.workOrder.resolverId !== this.state.workOrder.resolverId)) {
      if (this.props.selected && this.props.selected.buildingId.toUpperCase() !== this.state.workOrder.buildingId.toUpperCase()) {
        var selectedItem = this.props.allBuildings.find((x) => x.buildingId.toUpperCase() === this.state.workOrder.buildingId.toUpperCase());
        if (selectedItem && selectedItem.projectId !== this.props.selected.projectId) {
          this.props.dispatch(commonActions.selectBuilding(selectedItem));
        } else {
          this.setState({ building: selectedItem || this.props.selected });
        }
      } else {
        this.setState({ building: this.props.selected });
      }
    }
  }

  GetWorkOrderDetails = (update = false) => {
    const { resolverId } = this.props.match.params;
    if (resolverId) {
      const url = webApiUrl + "api/RepairRequest/GetWorkOrderDetails/" + encodeURI(resolverId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      if (update === false) {
        this.setState({
          workOrder: null,
        });
      }

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((workOrder) => {
          if (!!workOrder.resolverId) {
            workOrder.completed = workOrder.status === 3 || workOrder.status === 4;
            this.setState({
              workOrder,
              isTemporary: workOrder.status == 0 && !workOrder.workOrderNumber,
              completeOrRejectionText: workOrder.status === 3 ? workOrder.rejectionText : workOrder.status === 4 ? workOrder.solutionText : this.state.completeOrRejectionText
            });
            this.updateWorkOrderAsInformed();
          }
        });
    }
  }
  updateWorkOrderAsInformed() {
    const { t } = this.props;
    const { workOrder } = this.state;

    if (!!workOrder && workOrder.status === 0) {
      this.setState({ updating: "status" });

      const url = webApiUrl + `api/RepairRequest/UpdateResolverStatusAsInformed/` + encodeURI(workOrder.resolverId);
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ updating: null });
            this.GetWorkOrderDetails(true);
          } else {
            this.setState({ updating: null });
          }
        })
        .catch((e) => {
          this.setState({ updating: null });
          alert(t("general.api.error"));
        });
    }
  }

  initiateHandle = (e, handleType) => {
    if (["complete", "reject"].includes(handleType)) {
      const { workOrder, isTemporary, completeOrRejectionText } = this.state;
      if (workOrder && isTemporary !== true && workOrder.completed !== true) {
        if (completeOrRejectionText && completeOrRejectionText.trim() !== "") {
          this.setState({
            anchorElHandleWorkOrder: e.currentTarget,
            handleType,
          });
        } else {
          this.setState({ completeOrRejectionTextError: true });
        }
      }
    }
  };

  handleWorkOrder = () => {
    const { t, selected } = this.props;
    const { workOrder, isTemporary, handleType, completeOrRejectionText } = this.state;
    if (workOrder && isTemporary !== true && workOrder.completed !== true) {
      if (completeOrRejectionText && completeOrRejectionText.trim() !== "" && ["complete", "reject"].includes(handleType)) {
        this.setState({ handling: true });

        let notification = this.createModelForEmailNotify();
        let isComplete = false;
        let isCompleteRepairRequest = false;
        switch (handleType) {
          case "complete":
            isComplete = true;
            break;
          case "reject":
            isComplete = false;
            break;
          default:
            break;
        }

        const model = {
          resolverId: workOrder.resolverId,
          completeOrRejectionText,
          isComplete,
          continuedWorkOrder: false,
          isCompleteRepairRequest,
          notification,
        };

        const url = webApiUrl + `api/RepairRequest/UpdateWorkOrderStatus`;
        const requestOptions = {
          method: "POST",
          headers: authHeader("application/json"),
          body: JSON.stringify(model),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((success) => {
            if (success) {
              this.setState({ handling: false });
              history.push("/werk/" + selected.projectNo + "/werkbonnen");
            } else {
              this.setState({ handling: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ handling: false });
            alert(t("general.api.error"));
          });
      } else {
        this.setState({ showCreateWorkOrderErrors: true });
      }
    }
  };

  createModelForEmailNotify = () => {
    const { isNotify } = this.state;
    const model = {
      isNotify: isNotify === true,
    };

    return model;
  };

  handleSnackbarOpen = (message, severity = "success") => {
    this.setState({
      snackbar: {
        open: true,
        message: message,
        severity: severity
      }
    })
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbar: {
        open: false,
        message: "",
      }
    })
  }

  uploadAttachment = (files) => {
    let filesToUpload = [];
    const { workOrder } = this.state
    const length = workOrder.solutionImages.length + workOrder.solutionPdfs.length
    if (files.length + length > 20) {
      this.setState({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.noOfFilesLimit.error",
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (validateFile({ file: files[i], allowPdf: true, allowImage: true }) === true) {
        filesToUpload.push(files[i]);
      }
    }

    if (filesToUpload.length > 0) {
      const { t } = this.props;
      const { workOrder } = this.state;
      this.setState({
        uploading: true,
      });

      if (workOrder && workOrder.completed !== true) {
        const formData = new FormData();

        for (var i = 0; i < filesToUpload.length; i++) {
          formData.append("files", filesToUpload[i]);
        }

        const url = webApiUrl + `api/RepairRequest/UploadWorkOrderFiles/` + encodeURI(workOrder.resolverId);
        fetch(url, {
          method: "POST",
          headers: authHeader(),
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              this.setState({
                uploading: false,
              });
              this.GetWorkOrderDetails(true);
            } else {
              this.setState({ uploading: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ uploading: false });
            alert(t("general.api.error"));
          });
      }
    }
  };

  handleRemoveAttachFile(attachmentId) {
    const { t } = this.props;
    const { workOrder } = this.state;
    if (workOrder && workOrder.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestAttachment/${workOrder.repairRequestId}/${attachmentId}`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true) {
            this.GetWorkOrderDetails(true);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  handleFileModalOpen = (index, type = "image") => {
    const { workOrder } = this.state;
    const isImage = type === "image"
    const filesAttribute = isImage ? "repairRequestImages" : "repairRequestPdfs"

    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder[filesAttribute][index] ? workOrder[filesAttribute][index].attachmentId : ""),
          fileType: getMimeTypefromString(isImage ? ".jpg" : ".pdf"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };


  handleFileModalClose = () => {
    this.setState({ attachmentIndex: null, document: null });
  };


  handleFileModal2Open = (index, type = "image") => {
    const { workOrder } = this.state;
    const isImage = type === "image"
    const filesAttribute = isImage ? "solutionImages" : "solutionPdfs"
    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder[filesAttribute][index] ? workOrder[filesAttribute][index].attachmentId : ""),
          fileType: getMimeTypefromString(isImage ? ".jpg" : ".pdf"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };

  handleShare(e) {
    this.setState({
      shareAnchorEl: e.currentTarget,
    });
  }
  getCopyTextComponent({  clipBoardValue = "", toastValue = "" }) {
    const { t } = this.props;
    return <Tooltip title={t("general.copy")}>
  <IconButton
        aria-label="copy"
        component="span"
        size="small"
        edge="end"
        onClick={(e) => {
          copyToClipboard(clipBoardValue)
          this.handleSnackbarOpen(t("general.copiedText", { text: toastValue || clipBoardValue }))
        }
        }
      >
        <FileCopyOutlined />
      </IconButton>
    </Tooltip>
  }
  renderCopyText(key, title) {
    const { workOrder } = this.state;
    return    <Box sx={{ wordWrap: "break-word" }}>
        <Box sx={{float:"right",margin:'-4px -12px -8px 0px'}}>  {this.getCopyTextComponent({  clipBoardValue: workOrder[key], toastValue: title })}</Box>
        {nl2br(workOrder[key])}
      </Box>
 
  }

  blockGeneral() {
    const { t, classes } = this.props;
    const { workOrder, building } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={clsx(classes.subHeader, classes.pdfButtonTitle)}>
          <Build color="primary" /> &nbsp;
          <div className={classes.grow}>
            {t("general.workOrder")}
            &nbsp;
            {workOrder && (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + "-##")}
          </div>
          <WorkOrderPDF resolverId={workOrder && workOrder.resolverId} />
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.resolver") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.name && workOrder.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.repairRequest") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.repairRequestNo && workOrder.repairRequestNo}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.relation") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.relationName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.streetAndHouseNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address &&
                    (workOrder.address.street ? workOrder.address.street : "") + " " + (workOrder.address.houseNo ? workOrder.address.houseNo : "") + (workOrder.address.houseNoAddition ? " " + workOrder.address.houseNoAddition : "")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.address.postcodeAndCity") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address && (
                    <>
                      {workOrder.address.postcode ? workOrder.address.postcode + " " : ""}
                      &nbsp;
                      {workOrder.address.place ? workOrder.address.place : ""}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.buildingNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {building && building.buildingNoIntern}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.deliveryDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.deliveryDate && formatDate(new Date(workOrder.deliveryDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequests.secondSignatureDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.secondSignatureDate && formatDate(new Date(workOrder.secondSignatureDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.endOfMaintenancePeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.maintenanceEndDate && formatDate(new Date(workOrder.maintenanceEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.endOfWarrantyPeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.warrantyEndDate && formatDate(new Date(workOrder.warrantyEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  blockStatus() {
    const { t, classes } = this.props;
    const { workOrder, terms } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Schedule color="primary" /> &nbsp;
          {t("general.status")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.status") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.status >= 0 && t("resolver.status." + workOrder.status)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.completedOn") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.dateHandled && formatDate(new Date(workOrder.dateHandled))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder.isHandled === true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedBy") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledBy}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedOn") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledOn && formatDate(new Date(workOrder.handledOn)) + " " + formatTime(new Date(workOrder.handledOn))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockObjectInfo() {
    const { t, classes, location } = this.props;
    const { workOrder, isTemporary } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Build color="primary" /> &nbsp;
          {t("workOrders.workOrderInformation")}
        </Typography>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("general.description") + ":"}
            </Grid>
            <Grid item xs={6}>
            {this.renderCopyText("description", t("general.description"))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {workOrder && workOrder.previousWorkOrderDetails && (
          <>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.workOrderOld") + ": "}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.previousWorkOrderDetails.workOrderNumber}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.oldDateOfCompletion") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.previousWorkOrderDetails.handledDate && formatDate(new Date(workOrder.previousWorkOrderDetails.handledDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.solutionOld") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {nl2br(workOrder.previousWorkOrderDetails.solution)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.explanationOld") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {nl2br(workOrder.previousWorkOrderDetails.explantion)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.workOrderTextOld") + ":"}
                </Grid>
                <Grid item xs={6}>
                {this.renderCopyText("workOrderText", t("workOrders.workOrderText"))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("workOrders.workOrderText") + ":"}
            </Grid>
            <Grid item xs={6}>
              {nl2br(workOrder.workOrderText)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("general.explanation") + ":"}
            </Grid>
            <Grid item xs={6}>
            {this.renderCopyText("explanation", t("general.explanation"))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("general.type") + ":"}
            </Grid>
            <Grid item xs={6}>
              {workOrder.carryOutAsType}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("workOrders.repairRequestDate") + ":"}
            </Grid>
            <Grid item xs={6}>
              {workOrder.repairRequestDate && formatDate(new Date(workOrder.repairRequestDate))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("workOrders.dateNotified") + ":"}
            </Grid>
            <Grid item xs={6}>
              {workOrder.dateNotified && formatDate(new Date(workOrder.dateNotified))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("repairRequest.targetCompletionDate.label") + ":"}
            </Grid>
            <Grid item xs={6}>
              {workOrder.targetCompletionDate && formatDate(new Date(workOrder.targetCompletionDate))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={12}>
              {t("repairRequests.repairRequestAttachments") + ":"}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={12}>
              {((workOrder.repairRequestImages && workOrder.repairRequestImages.length > 0) || (workOrder.repairRequestPdfs && workOrder.repairRequestPdfs.length > 0)) && (
                <div className={classes.imageGallery}>

                  <Grid container spacing={1}>
                    {(workOrder.repairRequestImages || []).map((attachment, index) => (
                      <Grid key={index} item xs={6} sm={4} xl={3}>
                        <div
                          key={index}
                          class={clsx(classes.thumbnail, "big")}
                          onClick={() => this.handleFileModalOpen(index)}
                          style={{
                            backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${attachment.attachmentId})`,
                          }}
                        />
                      </Grid>
                    ))}

                    {(workOrder.repairRequestPdfs || []).map((attachment, index) => (
                      <Grid key={index} item xs={6} sm={4} xl={3}>

                        <div className={classes.attachmentsWrapper} onClick={() => this.handleFileModalOpen(index, "pdf")}>
                          <div
                            key={index}
                            class={clsx(classes.thumbnail, "big")}
                            style={{
                              backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${attachment.attachmentId})`,
                            }}
                          />
                          <Typography className={classes.pdfTextWrapper} ><PictureAsPdf color={"error"} /></Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {workOrder && workOrder.drawingPinLocation && (
          <>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.location") + ":"}
                </Grid>
                <Grid item xs={6} className={classes.locationContainer}>
                  <img
                    alt="map"
                    src={`${webApiUrl}api/Dossier/GetDossierPdfImage/${workOrder.drawingPinLocation.dossierFileId}?page=${workOrder.drawingPinLocation.pageNumber}`}
                    className={classes.locationPreview}
                    onClick={() => this.setState({ openRepairRequestMap: true })}
                  />
                  <LocationOn
                    color="primary"
                    className={classes.locationMarkPreview}
                    style={{
                      left: `calc(${workOrder.drawingPinLocation.xrel} * 100% - 12px)`,
                      top: `calc(${workOrder.drawingPinLocation.yrel} * 100% - 22px)`,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </div>
    );
  }

  blockWorkOrder() {
    const { t, classes } = this.props;
    const { workOrder, isTemporary, uploading, showCreateWorkOrderErrors } = this.state;
    const completionRejectionLabel = workOrder.status == 3 ? t("general.reasonOfRejection") : workOrder.status == 4 ? t("workOrders.solution") : t("workOrders.workPerformed");
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Assignment color="primary" /> &nbsp;
          {t("general.additionalInformation")}
        </Typography>
        <Grid container>
          {isTemporary !== true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <TextField
                      label={completionRejectionLabel}
                      value={this.state.completeOrRejectionText}
                      onChange={(e) =>
                        this.setState({
                          completeOrRejectionText: e.target.value,
                        })
                      }
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={10}
                      required
                      error={this.state.completeOrRejectionTextError === true && (!this.state.completeOrRejectionText || this.state.completeOrRejectionText.trim() === "")}
                      fullWidth
                      autoFocus
                      disabled={workOrder.completed === true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {(!!workOrder.workOrderNumber || (workOrder.solutionImages && workOrder.solutionImages.length > 0)) && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    {t("general.attachments") + ":"}
                  </Grid>
                </Grid>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <div className={classes.imageGallery}>
                      {workOrder.completed !== true && (
                        <div className={classes.dropzoneContainer}>
                          <Dropzone onFilesAdded={this.uploadAttachment} disabled={workOrder.completed === true} uploading={uploading} accept="image/*, application/pdf" />
                        </div>
                      )}
                      {workOrder.solutionImages &&
                        workOrder.solutionImages.length > 0 &&
                        workOrder.solutionImages.map((file, index) => (
                          <div className={classes.galleryImageTile}>
                            <div
                              onClick={() => this.handleFileModal2Open(index)}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                              }}
                            ></div>
                            {workOrder.completed !== true && (
                              <Tooltip title={t("general.remove")}>
                                <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                      {workOrder.solutionPdfs &&
                        workOrder.solutionPdfs.length > 0 &&
                        workOrder.solutionPdfs.map((file, index) => (
                          <div className={classes.galleryImageTile}>
                            <div
                              onClick={() => this.handleFileModal2Open(index, "pdf")}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                              }}
                            >
                              <Typography className={classes.pdfTextWrapper} ><PictureAsPdf color={"error"} /></Typography>
                            </div>

                            {workOrder.completed !== true && (
                              <Tooltip title={t("general.remove")}>
                                <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockSignatureInfo() {
    const { t, classes } = this.props;
    const {workOrder} = this.state
    return (
      <div className={classes.block}>
        <Grid container alignItems="center">
          <Grid item className={classes.grow}>
            <Typography
              component="h2"
              variant="h6"
              className={classes.subHeader}
            >
              <Draw color="primary" /> &nbsp;
              {t("workOrders.signatureStatus")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.infoGridRow}>
          <Grid item xs={12}> <Typography>{t("workOrders.signatureInfoSubtitle")}</Typography></Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("workOrders.signatoryName")+ ":"}
            </Grid>
            <Grid item xs={6}>
            {workOrder?.signatoryName}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container className={classes.infoGridRow} alignItems="center">
            <Grid item xs={6}>
              {t("workOrders.buyerSignature") + ":"}
            </Grid>
            <Grid item xs={6}>
              <Box width="100%" p={1} component="img" bgcolor="white"
                src={`data:image/png;base64,${workOrder?.signature?.content}`}
              />
            </Grid>
          </Grid>
           <Grid item xs={12}>
            <Divider />
          </Grid>
          {
            workOrder?.signedWithSatisfaction && <Grid container className={classes.infoGridRow} alignItems="center">
            <Grid item xs={12}>
            {t("workOrders.signedWithSatisfaction")}
            </Grid>
          </Grid>
          }
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container className={classes.infoGridRow}>
            <Grid item xs={6}>
              {t("workOrders.signingDateTime") + ":"}
            </Grid>
            <Grid item xs={6}>  
            {workOrder?.signatureDateTime ? formatDate(new Date(workOrder?.signatureDateTime),true) : "-"}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  blockContactInfo() {
    const { t, classes } = this.props;
    const { workOrder } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Contacts color="primary" /> &nbsp;
          {t("general.contactDetails")}
        </Typography>
        <Grid container spacing={1}>
          {workOrder && workOrder.contactInfo && (
            <>
              {workOrder.contactInfo.buyer && (
                <>
                  {workOrder.contactInfo.buyer.type === 0 && (
                    <>
                      <Grid item xs={12}>
                        <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p1} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer1")} />
                      </Grid>
                      {workOrder.contactInfo.buyer.p2 && (
                        <Grid item xs={12}>
                          <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p2} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer2")} />
                        </Grid>
                      )}
                    </>
                  )}
                  {workOrder.contactInfo.buyer.type === 1 && (
                    <Grid item xs={12}>
                      <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.org} isOrg pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyerOrganization")} />
                    </Grid>
                  )}
                </>
              )}
              {workOrder.contactInfo.client && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.client} isOrg pointOfContactType={1} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("repairRequest.client")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvE && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvE} isOrg pointOfContactType={2} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvE")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvEAdministrator && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvEAdministrator} isOrg pointOfContactType={3} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvEAdministrator")} />
                </Grid>
              )}
              {workOrder.contactInfo.propertyManagers &&
                workOrder.contactInfo.propertyManagers.map((propertyManager, index) => (
                  <Grid key={index} item xs={12}>
                    <ContactCard hidePointOfContactCheckbox={true} object={propertyManager} isOrg pointOfContactType={4} selectedPointOfContactType={index === 0 && workOrder.pointOfContact} subTitle={t("general.propertyManager")} />
                  </Grid>
                ))}
              {workOrder.contactInfo.employee && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.employee} isOrg pointOfContactType={5} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.employee")} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    );
  }
  toggleSignatureModal = () => {
    this.setState({ isSignatureModalOpen : !this.state.isSignatureModalOpen})
  }
  render() {
    const { t, classes } = this.props;
    const { workOrder, attachmentIndex, shareAnchorEl, isTemporary, isNotify, snackbar} = this.state;
    return (
      <Container maxWidth={false} className={classes.mainContainer} >
        <Grid container>
          <Grid item container xs={12} className={classes.container}>
            <AppBar position="sticky" color="inherit" className={classes.appBar}>
              <Toolbar variant="dense">
                <IconButton
                  color="inherit"
                  edge="start"
                  aria-label="go back"
                  component="span"
                  onClick={() => {
                    history.goBack();
                  }}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
                <Typography className={clsx(classes.bold, classes.grow)} noWrap>
                  <Hidden mdDown>{t("general.workOrder") + " "}</Hidden>{(workOrder ? (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + "-##") : "")}
                </Typography>
                {workOrder && !!workOrder.workOrderNumber && (
                  <>
                    <Tooltip title={t("general.share")}>
                      <IconButton
                        color="inherit"
                        aria-label="Share"
                        component="span"
                        onClick={(e) => {
                          this.handleShare(e);
                        }}
                        size="large"
                      >
                        <Share />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="share-menu"
                      anchorEl={shareAnchorEl}
                      keepMounted
                      open={Boolean(shareAnchorEl)}
                      onClose={() => {
                        this.setState({ shareAnchorEl: null });
                      }}
                    >
                      {workOrder.completed !== true && (
                        <MenuItem
                          onClick={() => {
                            let Unsubscribelink = `${window.location.origin}/werkbon/${this.props.match.params.resolverId}`;
                            navigator.clipboard.writeText(Unsubscribelink);
                            this.setState({shareAnchorEl: null})
                            this.handleSnackbarOpen(t("workOrders.task.directLinkCopied"))
                          }}
                        >
                          <FileCopyOutlined />
                          &nbsp;
                          <span>{t("workOrders.task.directLink")}</span>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          let Workorderlink = window.location.href;
                          navigator.clipboard.writeText(Workorderlink);
                          this.setState({ shareAnchorEl: null })
                          this.handleSnackbarOpen(t("workOrders.workOrderLinkCopied"))
                        }}
                      >
                        <FileCopyOutlined />
                        &nbsp;
                        <span>{t("workOrders.workOrderLink")}</span>
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {!!workOrder && isTemporary !== true && workOrder.completed !== true && (
                  <>
                    <Stack direction="row" spacing={1} overflow="auto">
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" sx={{ minWidth: "max-content" }} size="small" onClick={(e) => this.initiateHandle(e, "complete")}>
                        {t("general.button.complete")}
                      </Button>
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" sx={{ minWidth: "max-content" }} size="small" onClick={(e) => this.initiateHandle(e, "reject")}>
                        {t("general.button.reject")}
                      </Button>
                      {!(workOrder?.signature?.name && workOrder?.signature?.content?.length > 0) &&
                        <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" sx={{ minWidth: "max-content" }} size="small" onClick={() => { this.toggleSignatureModal() }}>
                          {t("general.sign")}
                        </Button>
                      }
                    </Stack>
                    {!!this.state.anchorElHandleWorkOrder && this.state.handleType && (
                      <Popover
                        open={true}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        id={"handleWorkOrderPopup"}
                        anchorEl={this.state.anchorElHandleWorkOrder}
                        onClose={() => {
                          this.setState({ anchorElHandleWorkOrder: null });
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <Typography variant="h6">{t("workOrders.handle." + this.state.handleType)}</Typography>
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isNotify === true}
                                    onChange={(e) =>
                                      this.setState({
                                        isNotify: e.target.checked === true,
                                      })
                                    }
                                    name="notify"
                                    color="primary"
                                  />
                                }
                                label={t("workOrders.notifyExecutorNow.label")}
                              />
                            </Grid>
                            <Grid item>
                              <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      this.setState({
                                        anchorElHandleWorkOrder: null,
                                      });
                                    }}
                                  >
                                    {t("general.cancel")}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button variant="outlined" disabled={workOrder.completed === true} onClick={() => this.handleWorkOrder()}>
                                    {t("workOrders.handle." + this.state.handleType)}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
            {workOrder && (
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="flex-start">
                  <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={2} >
                      <Grid item xs={12}>
                        {this.blockGeneral()}
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockObjectInfo()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden lgDown>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                      {
                          workOrder && !workOrder.completed &&
                          <Grid item xs={12}>
                            <WorkOrderAppointment workOrder={workOrder} getWorkOrderDetails={this.GetWorkOrderDetails} />
                          </Grid>
                        }
                        <Grid item xs={12}>
                          {this.blockWorkOrder()}
                        </Grid>
                        {
                          workOrder?.signature?.name && workOrder?.signature?.content?.length > 0 &&
                          <Grid item xs={12}>
                            {this.blockSignatureInfo()}
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {this.blockStatus()}
                      </Grid>
                      <Grid item xs={12}>
                      <div className={classes.block}>
                        <NotificationPanel hasChats={workOrder.hasChats} expandViewTitle={`${workOrder.workOrderNumber} - ${workOrder.description}`} repairRequestId={workOrder.repairRequestId} repairRequestNumber={workOrder.repairRequestNo} resolverId={workOrder.resolverId} canInitiateChat={workOrder?.canInitiateChat} hideAddChatButton  refreshParentState={()=>this.GetWorkOrderDetails(true)} />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockContactInfo()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden lgUp>
                    {
                      workOrder && !workOrder.completed &&
                      <Grid item xs={12} md={6}>
                            <WorkOrderAppointment workOrder={workOrder} getWorkOrderDetails={this.GetWorkOrderDetails} />
                      </Grid>
                    }
                    <Grid item xs={12} md={6}>
                      {this.blockWorkOrder()}
                    </Grid>
                    {
                      workOrder?.signature?.name && workOrder?.signature?.content?.length > 0 &&
                      <Grid item xs={12}>
                        {this.blockSignatureInfo()}
                      </Grid>
                    }
                  </Hidden>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {
          this.state.isSignatureModalOpen && (
            <SignatureModal handleSnackbarOpen ={this.handleSnackbarOpen} isSignatureModalOpen={this.state.isSignatureModalOpen} workOrder={this.state.workOrder} toggleSignatureModal={this.toggleSignatureModal} getWorkOrderDetails={this.GetWorkOrderDetails} />
          )
        }

        {<DocumentViewer radius={classes.docRadius} open={attachmentIndex != null && this.state.document} onClose={this.handleFileModalClose} documents={this.state.document} />}
        <RepairRequestForm
          isDrawingLocation
          isCreateRepairRequest={false}
          isReadOnly
          requestId={this.state.workOrder && this.state.workOrder.repairRequestId}
          openRepairRequestMap={this.state.openRepairRequestMap}
          onClose={(reload) => this.setState({ openRepairRequestMap: false })}
        />
        <Snackbar
          TransitionComponent={(props) => (
            <Slide {...props} direction="left" />
          )}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.filesSizeIsInValid}
          onClose={() =>
            this.setState((p) => ({ ...p, filesSizeIsInValid: false }))
          }
          autoHideDuration={3000}
          key={"file-size-validation"}
          style={{ top: "80px" }}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {t(this.state.fileUploadErrorMessage, { noOfFilesAllowed: 20 })}
          </Alert>
        </Snackbar>
        <Portal>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            key={snackbar.message}
          >
            <Alert
            onClose={this.handleSnackbarClose}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  const allBuildings = all;
  return {
    user,
    selected,
    allBuildings,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as ResolverWorkOrderDetailsPage };
