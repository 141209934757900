import React, { useCallback, useEffect, useState } from 'react'
import {
  ButtonBase,
  CircularProgress,
  alpha,
  Grid,
  Hidden,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { surveyActions } from '../../../_actions';
import { isReadOnly } from '../../../_helpers/enumsHelper';
import { userAccountTypeConstants } from '../../../_constants';
import { useCanLockedInspection, nl2br } from '../../../_helpers';
import TextFieldDialogPopup from './TextFieldDialogPopup';


const remarkKeys = [
  {
    title: 'survey.executor',
    key: 'executorComments'
  },
  {
    title: 'general.buyerRenter',
    key: 'buyerRenterComments'
  },
  {
    title: 'survey.closingStatement',
    key: 'reportFooter'
  },
];

export default function Remarks({ preDeliveriesDetail, selectedInspection, deliveriesDetail, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation();
  const [editRemark, setEditRemark] = useState({
    buyerRenterComments: '',
    executorComments: '',
    reportFooter: ''
  });
  const dispatch = useDispatch()
  const [readOnly, setReadOnly] = useState(false);
  const [canSaveData, setCanSaveData] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const { isSurveyUpdating, isSurveyUpdateError } = useSelector(state => state.surveying);
  const { user } = useSelector(state => state.authentication);
  const isBuyer = user && user.type === userAccountTypeConstants.buyer;
  const canLockedInspection = useCanLockedInspection();
  const theme = useTheme()
  const [openTextFieldDialog, setOpenTextFieldDialog] = useState(false);
  const [dataForTextFieldToEdit, setDataForTextFieldToEdit] = useState({});

  useEffect(() => {
    if (!isSurveyUpdating) {
      setOpenTextFieldDialog(false)
    }
  }, [isSurveyUpdating])

  useEffect(() => {
    if (selectedInspection) {
      setEditRemark(remarkKeys.reduce((prev, c) => ({ ...prev, [c.key]: selectedInspection[c.key] }), {}));
      setReadOnly(isReadOnly(selectedInspection.status) || canLockedInspection || !selectedInspection.hasRights)
    }
  }, [selectedInspection && selectedInspection.status, canLockedInspection])
  useEffect(() => {
    if (!isSurveyUpdating && !isSurveyUpdateError && isUpdate) {
      setEditRemark(p => ({ ...p }))
      setIsUpdate(false)
    }
  }, [isSurveyUpdating, isSurveyUpdateError])

  useEffect(() => {
    if (editRemark && selectedInspection && (
      editRemark.executorComments !== selectedInspection.executorComments ||
      editRemark.buyerRenterComments !== selectedInspection.buyerRenterComments ||
      editRemark.reportFooter !== selectedInspection.reportFooter
    )) window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
    else window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
  }, [editRemark]);

  const beforeUnloadListener = useCallback((event) => {
    event.preventDefault();
    setCanSaveData(false)
    event.returnValue = "Are you sure you want to exit?"; // only development use, not showing in front-end 
    return "Are you sure you want to exit?"; // only development use, not showing in front-end
  }, []);

  const handleClickOpenRemark = (e, data) => {
    e.preventDefault();
    if (!readOnly) {
      setOpenTextFieldDialog(true);
      setDataForTextFieldToEdit(data)
    }
  }

  const handleClose = () => {
    setOpenTextFieldDialog(false);
    setDataForTextFieldToEdit({});
  }

  const handleTextFieldUpdate = (key, valueOfKey) => {
    const value = {
      ...selectedInspection,
      [key]: valueOfKey,
    };
    setIsUpdate(key)
    dispatch(surveyActions.updateSurveyInfo(value, selectedInspection.surveyType, key));
  }

  const handleUpdate = (event, textFieldValue) => {
    const key = dataForTextFieldToEdit.key
    setEditRemark(e => ({ ...e, [key]: textFieldValue }))
    handleTextFieldUpdate(key, textFieldValue)
  }
  return selectedInspection ? <>
    <Paper className={classes.detailMainContainer}>
      <div className={classes.detailContainer}>
        <Hidden lgDown>
          <Typography
            className={classes.detailHeader}> {t('survey.externalNotes')}</Typography>
        </Hidden>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            {remarkKeys.map((p, i) => {
              if (p.key === "executorComments" && isBuyer) return "";
              const disabled = readOnly || (isSurveyUpdating && isUpdate === p.key);
              return (
                (preDeliveriesDetail && p.key === "reportFooter") ? null : <Grid
                  key={`remark-${i}`}
                  item
                  xs={isBuyer ? 12 : deliveriesDetail ? (remarkKeys.length - 1 === i) ? 12 : 12 : 4}
                  sm={isBuyer ? 12 : deliveriesDetail ? (remarkKeys.length - 1 === i) ? 12 : 6 : 4}
                  md={isBuyer ? 12 : deliveriesDetail ? (remarkKeys.length - 1 === i) ? 12 : 6 : 4}
                  lg={isBuyer ? 12 : deliveriesDetail ? (remarkKeys.length - 1 === i) ? preDeliveriesDetail ? 6 : 12 : preDeliveriesDetail ? 6 : 6 : 4}>
                      <Typography className={classes.detailSubHeader}> {t(`${p.title}`)}</Typography>
                      <ButtonBase
                          disabled={disabled}
                          onClick={(e) => handleClickOpenRemark(e, p)}
                          style={{ width: '100%' }}
                      >
                          <Tooltip title={nl2br(editRemark[p.key])}>
                              <div className={classes.textAreaContainer}>
                                  <TextField
                                      multiline
                                      className={classes.textArea}
                                      fullWidth
                                      disabled={disabled}
                                      variant="outlined"
                                      name='remarks'
                                      value={editRemark[p.key]}
                                  />
                                  {isSurveyUpdating && isUpdate === p.key ?
                                      <CircularProgress className={classes.loader} size={20} /> : null}
                              </div>
                          </Tooltip>
                      </ButtonBase>                                
                </Grid>
              )
            })}
          </Grid>
        </form>

        <TextFieldDialogPopup
          open={openTextFieldDialog}
          dialogTitle={t('survey.editExternalNotes')}
          textFieldtitle={t(`${dataForTextFieldToEdit.title}`)}
          textFieldKey={dataForTextFieldToEdit.key}
          isUpdating={isSurveyUpdating ? (isSurveyUpdating && isUpdate === dataForTextFieldToEdit.key) : false}
          type="text"
          multiline={true}
          value={editRemark && (editRemark[dataForTextFieldToEdit.key])}
          handleClose={handleClose}
          handleUpdate={handleUpdate}
        />
      </div>
    </Paper>
  </> : null;
}

const useStyles = makeStyles(theme => ({
  dialogHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  grow: {
    flexGrow: 1
  },
  headerStyle: {
    fontSize: 16
  },
  remarkTextInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%'
  },
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  remarkTextInfo: {
    padding: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 535,
      overflow: 'hidden',
      wordBreak: 'break-word'
    },
  },
  editRemarkText: {
    maxWidth: 444
  },
  detailSubText: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 3,
    wordBreak: "break-all", /* number of lines to show */
    display: "-webkit-box",
    boxOrient: "vertical",
  },
  textAreaContainer: {
    position: 'relative',
    width: '100%'
  },
  loader: {
    position: 'absolute',
    top: -25,
    right: 5
  },
  textArea: {
    '& .MuiInputBase-formControl': {
      padding: 0
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        cursor: 'inherit'
    },
    '&  .MuiOutlinedInput-input': {
      fontSize: 12,
      [theme.breakpoints.only('xs')]: {
        fontSize: 14,
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: 16,
      },
      boxShadow: 'none',
      padding: theme.spacing(1),
      display: 'flex',
      background: alpha(theme.palette.primary.main, 0.05),
      height: '70px !important',
      minHeight: '70px !important',
      maxHeight: '70px !important',
      border: `1px solid ${theme.palette.grey[200]}`,
      color: theme.palette.grey[700],
      cursor: 'inherit',
      overflow: 'auto !important',
    }
  },
  detailPaper: {
    boxShadow: 'none',
    padding: theme.spacing(1),
    display: 'flex',
    background: theme.palette.common.white,
    height: '70px !important',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  detailInnerPapper: {
    display: 'flex',
    flexGrow: 1
  },
  detailContainer: {
    overflow: 'hidden',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    }
  },
  detailMainContainer: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none'
    },
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    height: '100%',
  },
  detailHeader: {
    fontSize: 16,
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  detailSubHeader: {
    fontSize: 14,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
}))
