import React from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { commonActions } from "../../_actions";
import { history as History } from "../../_helpers";
import { getLinkToHome } from "../../_constants";
import { withRouter } from 'react-router-dom';
class Layout extends React.Component {
	state = {
		actions: [],
		plannings: [],
		messages: []
	};

	componentDidMount() {
		this.UpdateSelectedBuilding()
		var intervalId = setInterval(this.timer, 10000);
		// store intervalId in the state so it can be accessed later:
		this.setState({ intervalId: intervalId });
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}

	componentDidUpdate(prevProps) {
		const { selected, match, history } = this.props;
		const { projectNo } = match.params;
		if (!prevProps.selected && projectNo !== (selected && selected.projectId)) {
			this.UpdateSelectedBuilding();
		}
		if (!!prevProps.selected && prevProps.selected.buildingId !== selected.buildingId) {
			var url = match.url + '/';
			url = url.replace('/werk/' + prevProps.selected.projectNo + '/', '/werk/' + selected.projectNo + '/');
			url = url.substr(0, url.length - 1);
			if (!!history && !!history.location && history.location.pathname === url) {
				History.push(url, history.location.state);
			} else {
				History.push(url);
			}
		}
	}

	UpdateSelectedBuilding() {
		const { dispatch, selected, buildings, match, app } = this.props;
		const { projectNo } = match.params;
		if (selected && selected.projectNo && projectNo && selected.projectNo.toUpperCase() !== projectNo.toUpperCase()) {
			var selectedItems = buildings.filter(x => x.projectNo.toUpperCase() === projectNo.toUpperCase());
			if (selectedItems && selectedItems.length > 0) {
				dispatch(commonActions.selectBuilding(selectedItems[0]));
			} else {
				History.push(getLinkToHome(app))
			}
		}
	}


	render() {
		return this.props.children;
	}
}

function mapStateToProps(state) {
	const { authentication, buildings, app } = state;
	const { user } = authentication;
	const { selected, all } = buildings;
	return {
		user,
		selected: selected || {},
		buildings: all, app,
	};
}

const connectedLayout = connect(mapStateToProps)(withTranslation()(withRouter(Layout)));
export { connectedLayout as ProjectLayout };
