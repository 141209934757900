import React, { useEffect, useState } from 'react'
import {
    CircularProgress,
    Container,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
    InputLabel,
    Select,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DataGrid from '../../components/DataGrid';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, Cancel, GetApp, Image, Lock, PriorityHigh, Warning, Backup, LockOpen, History, AssignmentOutlined, Link, BeenhereOutlined, SendOutlined, ListAltOutlined, ErrorOutline, DescriptionOutlined } from '@mui/icons-material';
import { ImageColumn, LabelColumn, MultiLabelColumn } from '../../components/DataColumns';
import { formatDate, history, nl2br, useSelectedAssignmentInfo, useInternetStatusDetector, getAppsBuildings, setLocalStorageItem, getLocalStorageItem, generateLocalStorageKey, SurveyType } from '../../_helpers';
import { green, red } from '@mui/material/colors';
import { URLS } from '../../apis/urls';
import { AssignmentCustomRow } from './components/AssignmentCustomRow';
import { surveyActions, syncsActions } from '../../_actions';
import DataGridSetting from '../../components/DataGridSetting';
import withStyles from '@mui/styles/withStyles';
import SyncInfoModal from "./components/SyncInfoModal";
import ReleaseLockConfirmModal from "./components/ReleaseLockConfirmModal";
import SelectResponsiblePerson from "./components/SelectResponsiblePerson";
import SelectSingleObjectModal from "../../components/SelectSingleObjectModal";
import LinkAssignmentModal from "./components/LinkAssignmentModal";
import OfflineFileWrapper from "../../components/OfflineFileWrapper";
import { generatePath } from 'react-router-dom';

const extraInformationFilter = [
    { id: 0, label: "datatable.label.filter.all" },
    { id: 1, label: "survey.filter.download" },
    { id: 2, label: "survey.filter.notDownloaded" },
    { id: 3, label: "survey.filter.locked" },
];

const StyledMenuItem = withStyles((theme) => ({
    root: {
        whiteSpace: 'pre-wrap',
        padding: theme.spacing(1, 0),
        '& div': {
            minWidth: 40,
            color: theme.palette.grey[700]
        },
        '& span': {
            color: theme.palette.grey[700]
        }
    },
}))(MenuItem);

export default function AssignmentsGrid({ ...props }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const muiTheme = useTheme();
    const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down('md'));
    const [canListType, setCanListType] = useState(false);
    const dispatch = useDispatch();
    const [imageSize, setImageSize] = useState(20);
    const [defaultFilter, setDefaultFilter] = useState(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isObjectModal, setIsObjectModal] = useState(false);
    const [mappedState, setMappedState] = useState({ selectedBuildingId: null, selectedObjectId: null });
    const [linkAssignment, setLinkAssignment] = useState({ element: null, data: null });
    const openLinkModal = Boolean(linkAssignment.element);
    const OpenLinkModalId = openLinkModal ? 'simple-popover' : undefined;
    const [rowsSelected, setRowsSelected] = useState({ list: [], data: [] });
    const [openSyncInfoModal, setOpenSyncInfoModal] = useState(false);
    const [organizationLoading, setOrganizationLoading] = useState(false)
    const [canChangeResponsiblePerson, setCanChangeResponsiblePerson] = React.useState(null);
    const [openReleaseLockConfirmModal, setOpenReleaseLockConfirmModal] = useState(false);
    const [all, setAll] = useState([]);
    const isOnline = useInternetStatusDetector();
    const [columnsVisibility, setColumnsVisibility] = useState([
        { name: "surveyingMomentId", visible: "excluded" },
        { name: "objectId", visible: "excluded" },
        { name: "extraInformation", visible: true },
        { name: "assignmentAttachments", visible: true },
        { name: "number", visible: true },
        { name: "moment", visible: true },
        { name: "object", visible: true },
        { name: "controlPointDesc", visible: true },
        { name: "text", visible: true },
        { name: "startDate", visible: true },
        { name: "endDate", visible: true },
        { name: "assignments", visible: true },
        { name: "repairRequests", visible: true },
        { name: "responsiblePerson", visible: true },
        { name: "assignedPerson", visible: true },
        { name: "responsiblePersonPlainText", visible: "excluded" },
        { name: "responsiblePersonCompanyPlainText", visible: true },
        { name: "internalStatus", visible: true },
        { name: "wbStatusPlainText", visible: true },
        { name: "wbSurveyor", visible: true },
        { name: "progress", visible: true },
    ]);

    //Redux State
    const { user } = useSelector(state => state.authentication);
    const { selected } = useSelector(state => state.buildings);
    const { selectedAssignment, wbProjectInfo, assignmentLockIds = [], assignments, isInternalCompleteAssignmentLoading, resetAssignmentLoading, isCompleteAssignmentLoading, loadingAssignments } = useSelector(state => state.surveying);


    const {
        canSyncUp: canSyncUpAssignments, canSyncDown: canSyncDownAssignments, canRelease: canReleaseAssignments,
        canResetStatus: canResetAssignmentsStatus, canSendToWB: canSendToWBAction, isAnyCompletedAssignment: isCompletedAssignment,
        isNotAnyInternalComplete: isNotAnyInternalCompleted, isAllAnswerSelected: canAnswerIsSelected, isAnyLocked: canLockedAssignments
    } = useSelectedAssignmentInfo(rowsSelected);

    useEffect(() => {
        if (selectedAssignment) dispatch(surveyActions.clearAssignmentDetails());
        const columnsVisibilityLocal = getLocalStorageItem("Assignment_ColumnsVisibility");
        const localFilter = getLocalStorageItem(`Assignment_Columns_Filters`);
        if (!columnsVisibilityLocal) {
            setLocalStorageItem("Assignment_ColumnsVisibility", columnsVisibility);
        } else {
            setColumnsVisibility(columnsVisibilityLocal);
        }
        if (history.location.state && history.location.state.filter) {
            let filter = [...Array(20)].map(p => []);
            filter = Object.assign(filter, { [3]: [history.location.state.filter.moment] });
            filter = Object.assign(filter, { [4]: [history.location.state.filter.object] });
            setCanGoBack(!!history.location.state);
            const state = { ...history.location.state };
            delete state.filter;
            history.replace({ ...history.location, state });
            setDefaultFilter(filter);
            setLocalStorageItem(`Assignment_Columns_Filters`, filter);
        } else {
            setDefaultFilter(localFilter || columns.map((_, i) => []));
        }
        if (history.location.state && history.location.state.canGoBack) setCanGoBack(true);
        const size = getLocalStorageItem("assignmentsListsImageSize");
        setImageSize(p => size ? +size : p);
    }, []);

    useEffect(() => {
        let gridStyle = getLocalStorageItem("Assignment_Grid_Style");
        gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
        setCanListType(gridStyle);
    }, [matchWidthDownSm]);

    useEffect(() => {
        if (selected) {
            getAppsBuildings({ projectId: selected.projectId || "" }).then(async res => {
                setAll(res)
            }).catch((er) => {
                console.log(er)
            })
        }
    }, [selected]);

    useEffect(() => {
        if (selected && selected.projectId)
            dispatch(surveyActions.getAssignmentsList(selected.projectId))
        dispatch(surveyActions.getWBProjectInoAction(selected.projectId));
    }, [selected, history.location, isOnline]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInternalCompleteAssignment = (payload) => {
        dispatch(surveyActions.completeInternalAssignments(payload.map(a => a.assignmentId)));
    }

    const handleResetAssignmentStatus = (assignmentIds) => {
        dispatch(surveyActions.resetAssignmentStatusAction(assignmentIds.map(a => a.assignmentId)));
    }

    const handleCompleteAssignment = (payload) => {
        dispatch(surveyActions.assignmentsComplete(payload.map(a => a.assignmentId)));
    }

    const handleInfoSyncClose = () => {
        setOpenSyncInfoModal(false);
        handleClose();
        setRowsSelected(p => ({ data: [], list: [] }));
    }

    const openReleaseLockConfirmationModal = () => {
        setOpenReleaseLockConfirmModal(true);
    }

    const assignmentsSyncDownHandler = () => {
        setOpenSyncInfoModal(true);
        const assignmentsFlatData = assignments.map(a => ({
            ...a,
            surveyMomentId: a.surveyingMomentDetails.surveyingMomentId,
            objectId: a.surveyingMomentDetails.objectId,
            lockId: `${new Date().getTime()}-${a.assignmentId}`,
            controlPointDesc: a.text
        }));
        const selected = rowsSelected.data.map(({ assignmentId }) => assignmentsFlatData.find(a => a.assignmentId === assignmentId));
        dispatch(syncsActions.assignmentSyncDown(selected));
    }

    const assignmentsSyncUpHandler = () => {
        setOpenSyncInfoModal(true);
        const assignmentsFlatData = assignments.map(a => ({
            ...a,
            surveyMomentId: a.surveyingMomentDetails.surveyingMomentId,
            objectId: a.surveyingMomentDetails.objectId,
            controlPointDesc: a.text
        }));
        const selected = rowsSelected.data.map(({ assignmentId }) => assignmentsFlatData.find(a => a.assignmentId === assignmentId));
        dispatch(syncsActions.assignmentSyncUp(selected));
    };

    const releaseLockHandler = () => {
        setOpenSyncInfoModal(true);
        setOpenReleaseLockConfirmModal(false)
        const assignmentsFlatData = assignments.map(a => ({
            ...a,
            surveyMomentId: a.surveyingMomentDetails.surveyingMomentId,
            objectId: a.surveyingMomentDetails.objectId,
            controlPointDesc: a.text
        }));
        const selected = rowsSelected.data.map(({ assignmentId }) => assignmentsFlatData.find(a => a.assignmentId === assignmentId));
        const canClean = selected.some(p => {
            const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === p.lockId);
            return lockInfo && lockInfo.userId === user.id;
        });
        dispatch(syncsActions.releaseLock(selected, canClean, "Opdracht"));
    }

    const isOpenResponsibleDrawer = (e, name) => {
        setCanChangeResponsiblePerson(e.currentTarget)
    }

    const handleChangeResponsiblePersonForAssignment = ({ orgId, ...data }) => {
        data = {
            loginId: data.loginId,
            workorderRight: data.workOrderRight,
            repairRequestRight: data.repairRequestRight,
            ids: rowsSelected.data.map(p => p.assignmentId),
        }
        dispatch(surveyActions.updateAssignedPersonForAssignment(data, rowsSelected.data.map(p => p.assignmentId), true, orgId));
    };

    const handleChangeGridStyle = (val) => {
        setCanListType(val)
        setLocalStorageItem("Assignment_Grid_Style", val);
    }

    const followUpAssignmentsHandler = (event, data, type) => {
        event.stopPropagation()
        setLinkAssignment({ element: event.currentTarget, data, type })
    }

    const followUpAssignmentsHandlerClose = (event) => {
        setLinkAssignment({ element: null })
    };

    const objectModalListHandler = (event, selectedBuildingId, selectedObjectId) => {
        event.stopPropagation();
        setIsObjectModal(true);
        setMappedState({ selectedBuildingId, selectedObjectId });
    }

    const handleCloseObjects = () => {
        setIsObjectModal(false);
        setMappedState({ selectedBuildingId: null, selectedObjectId: null });
    };

    const handleSaveObjects = (buildingId) => {
        dispatch(surveyActions.mapBuildingObject({ objectId: mappedState.selectedObjectId, buildingId }))
        handleCloseObjects();
    }

    const getGeneratedPath = (params = {}) => {
        if (props.match.params.projectNo)
            return generatePath(props.match.path, { ...props.match.params, ...params })
        return `${props.match.url}/${params.projectNo}`;
    }

    const handleRowClick = selectedRow => {
        const { objectId, surveyingMomentId } = selectedRow.surveyingMomentDetails;
        history.push(`${getGeneratedPath({ assignmentId: selectedRow.assignmentId })}/opdracht/${selectedRow.assignmentId}${objectId ? `?toezichtmomentId=${surveyingMomentId}&objectId=${objectId}` : ''}`, { canGoBack, assignmentId: selectedRow.assignmentId, });
    }

    const getAssignmentsForPrintAndDownload = async (tableRef) => {
        const { displayData, data: tableData, columns } = tableRef.current.state;
        const data = displayData.map(a => tableData.find(p => p.index === a.dataIndex));
        return data.map(a => {
            const assignment = assignments.find(r => r.assignmentId === a.data[0]) || {}
            const { surveyingMomentDetails, isCracked, lockId } = assignment;
            const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);

            return columns.reduce((prev, c, i) => {
                const selectedCellData = a.data[i];
                const obj = { ...prev };
                switch (c.name) {
                    case "extraInformation": {
                        const array = [];
                        if (assignment.hasUpdate) array.push({ icon: "PriorityHigh", color: "secondary" })
                        if (surveyingMomentDetails.objectId && !surveyingMomentDetails.isBuildingMapped) array.push({ icon: "ErrorOutline", color: "error" })
                        if (lockInfo && lockInfo.userId === user.id && lockId) array.push({ icon: "GetApp", color: "primary" })
                        if (!lockInfo && lockId) array.push({ icon: "Lock", color: "primary" })
                        if (isCracked) array.push({ icon: "Warning", color: "primary" })
                        obj[c.name] = array;
                        break;
                    }
                    case "answer": {
                        let answer = {};
                        if ((assignment.answer && assignment.answerOptions)) {
                            answer = assignment.answerOptions.find(answer => answer.answerOption === assignment.answer) || {};
                        }
                        obj[c.name] = answer.answerOptionLabel || '';
                        break;
                    }
                    case "startDate": {
                        const startDate = assignment.startDate ? formatDate(new Date(assignment.startDate), false, '-') : '';
                        obj[c.name] = startDate;

                        break;
                    }
                    case "endDate": {
                        const endDate = assignment.endDate ? formatDate(new Date(assignment.endDate), false, '-') : '';
                        obj[c.name] = endDate;
                        break;
                    }
                    case "repairRequests": {
                        obj[c.name] = selectedCellData ? selectedCellData.length : 0;
                        break;
                    }
                    case "responsiblePerson": {
                        obj[c.name] = selectedCellData ? selectedCellData.relationName : '';
                        break;
                    }
                    case "assignedPerson": {
                        obj[c.name] = selectedCellData ? selectedCellData.relationName : '';
                        break;
                    }
                    case "internalStatus": {
                        obj[c.name] = t("wkb.assignment.internalStatus." + selectedCellData)
                        break;
                    }
                    case "wbReceivedDate": {
                        obj[c.name] = formatDate(new Date(selectedCellData))
                        break;
                    }
                    case "wbJudgmentDate": {
                        obj[c.name] = formatDate(new Date(selectedCellData))
                        break;
                    }
                    default: {
                        obj[c.name] = selectedCellData;
                        break;
                    }
                }
                return obj
            }, {})
        });
    }

    const isColumnVisible = (columnName) => {
        const isSmallScreen = true;
        var column = columnsVisibility.find((x) => x.name === columnName);
        if (column) {
            return column.visible;
        } else if (isSmallScreen) {
            return false;
        } else {
            return true;
        }
    };

    let columns = [
        {
            name: "assignmentId",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            },
        },
        {
            name: 'extraInformation',
            label: ' ',
            options: {
                filter: false,
                maxWidth: 170,
                download: false,
                display: isColumnVisible("extraInformation"),
                sort: true,
                setCellHeaderProps: () => ({ className: clsx(classes.customMainHeader) }),
                customHeadLabelRender: (columnMeta) => {
                    return <div noWrap style={{ display: 'flex', alignItems: 'center', overflow: 'visible' }}>
                        <div className={classes.iconContainer}>
                            <PriorityHigh color='secondary' className={classes.icon} />
                        </div>
                        <div className={classes.iconContainer}>
                            <ErrorOutline fontSize="small" color="error" />
                        </div>
                        <div className={classes.iconContainer}>
                            <GetApp color='primary' className={classes.tableIcon} />
                        </div>
                        <div className={classes.iconContainer}>
                            <Warning fontSize="small" color="error" className={classes.tableIcon} />
                        </div>
                    </div>
                },
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const { surveyingMomentDetails, hasUpdate, isCracked, lockId } = assignment;
                    const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);
                    return (
                        <Typography noWrap style={{ display: 'flex', alignItems: 'center', overflow: 'visible' }}>
                            <div className={classes.iconContainer}>
                                {hasUpdate && <Tooltip title={t("general.actionRequired")}>
                                    <PriorityHigh color='secondary' className={classes.icon} />
                                </Tooltip>
                                }
                            </div>
                            <div className={classes.iconContainer}>
                                {surveyingMomentDetails.objectId && !surveyingMomentDetails.isBuildingMapped &&
                                    <Tooltip title={t("wkb.objectIsNotLinked")}>
                                        <IconButton
                                            disabled={!wbProjectInfo || (wbProjectInfo && wbProjectInfo.archivedAt)}
                                            className={classes.iconButton}
                                            onClick={(e) => objectModalListHandler(e, surveyingMomentDetails.buildingId, surveyingMomentDetails.objectId)}
                                            size="large">
                                            <ErrorOutline className={classes.colorWarning} />
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            <div className={classes.iconContainer}>
                                {lockInfo && lockInfo.userId === user.id && lockId && <Tooltip title={t(`general.downloaded`, { moduleType: t(`wkb.assignment.label`) })}>
                                    <GetApp color='primary' className={classes.icon} />
                                </Tooltip>}
                                {!lockInfo && lockId && <Tooltip title={t(`general.locked`, { moduleType: t(`wkb.assignment.label`) })}>
                                    <Lock color='primary' className={classes.icon} />
                                </Tooltip>}
                            </div>
                            <div className={classes.iconContainer}>
                                {isCracked && <Tooltip title={t(`general.lockBroken`, { moduleType: t(`wkb.assignment.label`) })}>
                                    <Warning color='primary' className={classes.icon} />
                                </Tooltip>}
                            </div>
                        </Typography>
                    );
                },
            }
        },
        {
            name: "assignmentAttachments",
            label: t("general.image"),
            options: {
                display: isColumnVisible("assignmentAttachments"),
                filter: false,
                maxWidth: 60,
                sort: false,
                download: false,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment || !assignment.assignmentAttachments || !assignment.assignmentAttachments.length) return "";
                    const imageId = assignment.assignmentAttachments[0].id;
                    return <OfflineFileWrapper id={imageId} url={imageId ? URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL + imageId : assignment.assignmentAttachments[0]}>
                        {url => <ImageColumn size={imageSize} src={url} />}
                    </OfflineFileWrapper>

                },
                customHeadLabelRender: (columnMeta) => {
                    return <div style={{ textAlign: "center" }}><Image /></div>
                }
            },
        },
        {
            name: 'moment',
            label: t('wkb.surveyingMoment'),
            options: {
                display: isColumnVisible("moment"),
                filter: true,
                maxWidth: 200,
                sort: true,
                filterList: defaultFilter ? defaultFilter[3] : [],
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn label={assignment.moment} tooltipLabel={assignment.moment} />
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'object',
            label: t('general.object'),
            options: {
                display: isColumnVisible("object"),
                filter: true,
                maxWidth: 100,
                sort: true,
                filterList: defaultFilter ? defaultFilter[4] : [],
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn label={assignment.object} tooltipLabel={assignment.object} />
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'controlPointDesc',
            label: t('general.description'),
            options: {
                display: isColumnVisible("controlPointDesc"),
                filter: false,
                maxWidth: 160,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const { linkedAssignment, controlPointDesc } = assignment;
                    return (
                        <>
                            <LabelColumn
                                label={controlPointDesc}
                                tooltipLabel={controlPointDesc}
                                Icon={Link}
                                clickable
                                showIcon={linkedAssignment}
                                onClick={(e) => followUpAssignmentsHandler(e, linkedAssignment, 'linkedAssignment')}
                            />
                        </>
                    )
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'text',
            label: t('wkb.assignments.label'),
            options: {
                display: isColumnVisible("text"),
                filter: false,
                sort: true,
                maxWidth: 160,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn label={assignment.text} tooltipLabel={nl2br(assignment.text)} />
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'remark',
            label: t('general.remarks'),
            options: {
                display: isColumnVisible("remark"),
                filter: false,
                maxWidth: 160,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn label={assignment.remark} tooltipLabel={nl2br(assignment.remark)} />
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'answer',
            label: t('wkb.assignment.answer.label'),
            options: {
                display: isColumnVisible("answer"),
                filter: false,
                maxWidth: 190,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    let answer = {};
                    if ((assignment.answer && assignment.answerOptions)) {
                        answer = assignment.answerOptions.find(answer => answer.answerOption === assignment.answer) || {};
                    }
                    if (!answer.answerOptionLabel) return "";
                    return (
                        <>
                            <span className={clsx(classes.assignmentsCardButton, classes.content, classes[answer.answerOptionColor])} >{t(answer.answerOptionLabel)}</span>
                            <div className={classes.spacer}>{t(answer.answerOptionLabel)}</div>
                            <span>&nbsp;</span>
                        </>
                    )
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'startDate',
            label: t('general.startDate'),
            options: {
                display: isColumnVisible("startDate"),
                filter: false,
                maxWidth: 110,
                sort: true,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const startDate = assignment.startDate ? formatDate(new Date(assignment.startDate), false, '-') : '';
                    if (startDate) {
                        return <LabelColumn label={startDate} tooltipLabel={startDate} />
                    }
                }
            }
        },
        {
            name: 'endDate',
            label: t('general.endDate'),
            options: {
                display: isColumnVisible("endDate"),
                filter: false,
                maxWidth: 110,
                sort: true,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const endDate = assignment.endDate ? formatDate(new Date(assignment.endDate), false, '-') : '';
                    if (endDate) {
                        return <LabelColumn label={endDate} tooltipLabel={endDate} />
                    }
                }
            }
        },
        {
            name: 'repairRequests',
            label: t('general.repairRequests'),
            options: {
                display: isColumnVisible("repairRequests"),
                maxWidth: 100,
                filter: false,
                sort: true,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn label={assignment.repairRequests ? assignment.repairRequests.length : 0} />
                },
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
            }
        },
        {
            name: 'responsiblePerson',
            label: t('wkb.executive'),
            options: {
                display: isColumnVisible("responsiblePerson"),
                maxWidth: 150,
                filter: false,
                sort: true,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment || !assignment.responsiblePerson) return "";
                    return <MultiLabelColumn data={[{ ...assignment.responsiblePerson, name: assignment.responsiblePerson.relationName }]} />
                }
            }
        },
        {
            name: 'assignedPerson',
            label: t('wkb.assignment.delegate'),
            options: {
                display: isColumnVisible("assignedPerson"),
                filter: false,
                maxWidth: 150,
                sort: true,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment || !assignment.assignedPerson) return "";
                    return <MultiLabelColumn data={[{ ...assignment.assignedPerson, name: assignment.assignedPerson.relationName }]} />
                }
            }
        },
        {
            name: 'responsiblePersonPlainText',
            label: t('wkb.executive'),
            options: {
                display: 'excluded',
                filter: false,
                filterList: defaultFilter ? defaultFilter[14] : [],
                sort: false,
                print: false,
                download: false,
            }
        },
        {
            name: 'assignedPersonPlainText',
            label: t('wkb.assignment.delegate'),
            options: {
                display: 'excluded',
                filterList: defaultFilter ? defaultFilter[15] : [],
                filter: true,
                sort: false,
                print: false,
                download: false,
            }
        },
        {
            name: 'internalStatus',
            label: t('wkb.surveyingMoment.internalStatus'),
            options: {
                display: isColumnVisible("internalStatus"),
                filter: true,
                filterList: defaultFilter ? defaultFilter[16] : [],
                maxWidth: 160,
                sort: true,
                setCellHeaderProps: () => ({ className: classes.customMainHeader }),
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const { followUpAssignments, internalStatus } = assignment;
                    return (
                        <LabelColumn
                            label={t("wkb.assignment.internalStatus." + internalStatus)}
                            tooltipLabel={t("wkb.assignment.internalStatus." + internalStatus)}
                            Icon={followUpAssignments && !!followUpAssignments.length ? DescriptionOutlined : ''}
                            clickable
                            showIcon={followUpAssignments && !!followUpAssignments.length}
                            onClick={(e) => followUpAssignmentsHandler(e, followUpAssignments, 'followUpAssignments')}
                        />
                    )
                },
                filterOptions: {
                    names: [
                        t('wkb.assignment.internalStatus.0'),
                        t('wkb.assignment.internalStatus.1'),
                        t('wkb.assignment.internalStatus.2'),
                        t('wkb.assignment.internalStatus.3'),
                    ],
                    logic: (value, filters, row) => {
                        if (filters.length && filters.includes(t(`wkb.assignment.internalStatus.${value}`))) return false;
                        return true;
                    },
                },
            }
        },
        {
            name: "wbReceivedDate",
            label: t('wkb.wbReceivedDate'),
            options: {
                display: isColumnVisible("wbReceivedDate"),
                maxWidth: 110,
                filter: false,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn date={assignment.wbReceivedDate} />
                }
            },
        },
        {
            name: "wbStatus",
            label: t('wkb.wbJudgement'),
            options: {
                display: isColumnVisible("wbStatus"),
                filter: true,
                maxWidth: 150,
                filterList: defaultFilter ? defaultFilter[18] : [],
                print: false,
                download: false,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    const { wbStatus } = assignment;
                    return (
                        <>
                            <Tooltip title={wbStatus} arrow placement="right">
                                <span className={clsx(classes.wbStatusIcon, classes.content)}>
                                    {
                                        (wbStatus === "[WB akkoord]" || wbStatus === "Akkoord") ?
                                            <CheckCircle fontSize="small" className={classes.colorSuccess} /> :
                                            (wbStatus === "[WB niet akkoord]" || wbStatus === "Niet akkoord") ?
                                                <Cancel fontSize="small" color="error" /> :
                                                (!!wbStatus && wbStatus !== "") ?
                                                    <PriorityHigh fontSize="small" className={classes.warning} /> :
                                                    wbStatus
                                    }
                                    {wbStatus}
                                </span>
                            </Tooltip>
                            <div className={classes.spacer}>
                                {
                                    (wbStatus === "[WB akkoord]" || wbStatus === "Akkoord") ?
                                        <CheckCircle fontSize="small" className={classes.colorSuccess} /> :
                                        (wbStatus === "[WB niet akkoord]" || wbStatus === "Niet akkoord") ?
                                            <Cancel fontSize="small" color="error" /> :
                                            (!!wbStatus && wbStatus !== "") ?
                                                <PriorityHigh fontSize="small" className={classes.warning} /> :
                                                wbStatus
                                }
                                {wbStatus}
                            </div>
                            <span>&nbsp;</span>
                        </>
                    )
                }
            },
        },
        {
            name: "wbJudgmentDate",
            label: t('wkb.wbJudgementDate'),
            options: {
                display: isColumnVisible("wbJudgmentDate"),
                filter: false,
                maxWidth: 110,
                customBodyRenderLite: (rowIndex) => {
                    const assignment = assignments[rowIndex];
                    if (!assignment) return "";
                    return <LabelColumn date={assignment.wbJudgmentDate} />
                }
            },
        },
        {
            name: '',
            label: t('survey.extraInformation'),
            options: {
                display: "excluded",
                filter: true,
                sort: false,
                download: false,
                filterType: "custom",
                filterList: defaultFilter ? defaultFilter[20] : [],
                filterOptions: {
                    names: extraInformationFilter,
                    logic: (value, filters, row) => {
                        const appliedFilter = filters.length && filters[0];
                        const assignment = assignments && assignments.find(a => a.assignmentId === row[0]);
                        if (!assignment) return false;
                        const { lockId } = assignment;
                        const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);
                        //false - show row
                        //true - hide row
                        switch (appliedFilter.label) {
                            case extraInformationFilter[0].label: {
                                return false;
                            }
                            case extraInformationFilter[1].label: {
                                return !(lockInfo && lockId && lockInfo.userId === user.id);
                            }
                            case extraInformationFilter[2].label: {
                                return !!lockId;
                            }
                            case extraInformationFilter[3].label: {
                                return !(!lockInfo && lockId);
                            }
                            default: {
                                return false;
                            }
                        }
                    },
                    display: (filterList, onChange, index, column) => {
                        const value = filterList[index].length ? filterList[index][0].label : "";
                        return (
                            <>
                                <InputLabel id="extra-information-column-label">{t("survey.extraInformation")}</InputLabel>
                                <Select
                                    variant='standard'
                                    size='small'
                                    labelId="extra-information-column-label"
                                    id="extra-information-column"
                                    value={value}
                                    onChange={({ target: { value } }) => {
                                        onChange([extraInformationFilter.find(a => a.label === value)], index, column);
                                    }}
                                >
                                    {extraInformationFilter.map(a => (
                                        <MenuItem key={a.id} value={a.label} >{t(a.label)}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        );
                    },

                },
                customFilterListOptions: {
                    render: function (record) {
                        return record.map(a => <span key={a.label}>{t(a.label)}</span>)
                    }
                }
            }
        },
        // {
        //     name: 'responsiblePersonCompanyPlainText',
        //     label: t('general.company'),
        //     options: {
        //         display: isColumnVisible("responsiblePersonCompanyPlainText"),
        //         filter: true,
        //         download: true,
        //         maxWidth: 120,
        //         setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        //         customBodyRenderLite: (rowIndex) => {
        //             const assignment = assignments[rowIndex];
        //             if (!assignment) return "";
        //             return <LabelColumn label={assignment.responsiblePersonCompanyPlainText} tooltipLabel={assignment.responsiblePersonCompanyPlainText} />
        //         },
        //     }
        // },
    ];

    const options = {
        sortingList: [
            { label: "general.description", key: "controlPointDesc", type: "string" },
            { label: "wkb.assignments.label", key: "text", type: "string" },
            { label: "general.startDate", key: "startDate", type: "date" },
            { label: "general.endDate", key: "endDate", type: "date" },
            { label: "wkb.surveyingMoment.internalStatus", key: "status", type: "string" },
        ],
        download: true,
        print: true,
        onFilterChange: (_, list) => {
            setDefaultFilter(list);
            setLocalStorageItem(`Assignment_Columns_Filters`, list);
        },
        onRowClick: (rowData, rowMeta) => {
            const assignmentId = rowData[0];
            const selectedRow = assignments && assignments.find((x) => x.assignmentId === assignmentId);
            if (!!selectedRow) {
                handleRowClick(selectedRow);
            }
        },
        onViewColumnsChange: (changedColumn, action) => {
            if (action == "add") {
                var listToUpdate = Object.assign([], columnsVisibility);

                var column = listToUpdate.find((x) => x.name === changedColumn);
                if (column) {
                    column.visible = true;
                } else {
                    listToUpdate.push({ name: changedColumn, visible: true });
                }
                setColumnsVisibility(listToUpdate);
                setLocalStorageItem("Assignment_ColumnsVisibility", listToUpdate);
            } else if (action == "remove") {
                var listToUpdate = Object.assign([], columnsVisibility);

                var column = listToUpdate.find((x) => x.name === changedColumn);
                if (column) {
                    column.visible = false;
                } else {
                    listToUpdate.push({ name: changedColumn, visible: false });
                }
                setColumnsVisibility(listToUpdate);
                setLocalStorageItem("Assignment_ColumnsVisibility", listToUpdate);
            }
        },
        customToolbar: () => {
            return <>
                <Menu
                    id="simple-menu"
                    className={classes.menuContent}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={1}
                    getContentAnchorEl={null}
                    disableRestoreFocus
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    {...props}
                >
                    <MenuList className={classes.menuListStyle}>
                        <StyledMenuItem
                            disabled={
                                isInternalCompleteAssignmentLoading ||
                                !rowsSelected.list.length ||
                                !canAnswerIsSelected ||
                                !isNotAnyInternalCompleted ||
                                canLockedAssignments}
                            onClick={() => handleInternalCompleteAssignment(rowsSelected.data)}>
                            <ListItemIcon>
                                {isInternalCompleteAssignmentLoading ?
                                    <CircularProgress size={18} style={{ minWidth: 0 }} /> :
                                    <ListItemIcon style={{ minWidth: '40px' }}>
                                        <SendOutlined fontSize="small" />
                                    </ListItemIcon>
                                }
                            </ListItemIcon>
                            <Typography variant="inherit">{t('wkb.provisionallyCompleted')}</Typography>
                        </StyledMenuItem>

                        {(canResetAssignmentsStatus && !canLockedAssignments) && <StyledMenuItem
                            disabled={!canResetAssignmentsStatus || resetAssignmentLoading || canLockedAssignments}
                            onClick={() => handleResetAssignmentStatus(rowsSelected.data)}
                        >
                            <ListItemIcon>
                                {resetAssignmentLoading ?
                                    <CircularProgress size={18} style={{ minWidth: 0 }} />
                                    : <ListItemIcon style={{ minWidth: '40px' }}>
                                        <BeenhereOutlined fontSize="small" />
                                    </ListItemIcon>
                                }
                            </ListItemIcon>
                            <Typography variant="inherit">{t('wkb.resetAssignmentStatus')}</Typography>
                        </StyledMenuItem>}

                        <StyledMenuItem
                            disabled={
                                !isOnline ||
                                isCompleteAssignmentLoading ||
                                !canSendToWBAction ||
                                !rowsSelected.list.length ||
                                !canAnswerIsSelected ||
                                canLockedAssignments
                            } onClick={() => {
                                // setIsCompleted(true);
                                handleCompleteAssignment(rowsSelected.data)
                            }}>
                            <ListItemIcon>
                                {isCompleteAssignmentLoading ?
                                    <CircularProgress size={18} style={{ minWidth: 0 }} />
                                    : <ListItemIcon style={{ minWidth: '40px' }}>
                                        <BeenhereOutlined fontSize="small" />
                                    </ListItemIcon>
                                }
                            </ListItemIcon>
                            <Typography variant="inherit">{t('wkb.sendToWB')}</Typography>
                        </StyledMenuItem>
                        <StyledMenuItem
                            disabled={!rowsSelected.list.length
                                || organizationLoading
                                || isCompletedAssignment ||
                                !canSendToWBAction || !isOnline || canLockedAssignments}
                            onClick={(e) => isOpenResponsibleDrawer(e, 'VeranderVerantwoordelijkeOpdracht')}>
                            <ListItemIcon>
                                {organizationLoading ?
                                    <CircularProgress size={18} style={{ minWidth: 0 }} />
                                    :
                                    <ListAltOutlined fontSize="small" />
                                }
                            </ListItemIcon>
                            <Typography variant="inherit">{t('wkb.assignment.changeDelegator')}</Typography>
                        </StyledMenuItem>

                        {/* Pull & Push (Sync) */}
                        {!canSyncUpAssignments && <StyledMenuItem
                            onClick={assignmentsSyncDownHandler}
                            disabled={!rowsSelected.list.length || !isOnline || canLockedAssignments || !canSyncDownAssignments}>
                            <ListItemIcon>
                                <GetApp fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">{t('sync.offline')}</Typography>
                        </StyledMenuItem>}
                        {canSyncUpAssignments && <StyledMenuItem
                            onClick={assignmentsSyncUpHandler}
                            disabled={!rowsSelected.list.length || !isOnline || !canSyncUpAssignments || canLockedAssignments}>
                            <ListItemIcon>
                                {organizationLoading ?
                                    <CircularProgress size={18} style={{ minWidth: 0 }} />
                                    :
                                    <Backup fontSize="small" />
                                }
                            </ListItemIcon>
                            <Typography variant="inherit">{t('sync.up')}</Typography>
                        </StyledMenuItem>}
                        {canReleaseAssignments && <StyledMenuItem
                            disabled={!canReleaseAssignments || !isOnline}
                            onClick={openReleaseLockConfirmationModal}>
                            <ListItemIcon>
                                <LockOpen fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">{t('sync.release')}</Typography>
                        </StyledMenuItem>}
                        <StyledMenuItem onClick={() => setOpenSyncInfoModal(true)}>
                            <ListItemIcon>
                                <History fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">{t('sync.logs')}</Typography>
                        </StyledMenuItem>
                    </MenuList>
                </Menu >
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    disabled={!wbProjectInfo || (wbProjectInfo && wbProjectInfo.archivedAt)}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="large">
                    <AssignmentOutlined />
                </IconButton>
                {matchWidthDownSm && <DataGridSetting
                    canListType={canListType}
                    hideImageSizePicker
                    onChangeGridViewType={(val) => handleChangeGridStyle(val)}
                />}
            </>;
        },
    };

    return (
        <Container maxWidth={false} className={classes.mainContainer}>
            <DataGrid
                canListType={canListType}
                CustomRowRender={
                    matchWidthDownSm && !!canListType ?
                        (rest) => (
                            <AssignmentCustomRow
                                canGoBack={canGoBack}
                                objectModalListHandler={objectModalListHandler}
                                followUpAssignmentsHandler={followUpAssignmentsHandler}
                                onRowClick={handleRowClick}
                                {...rest} {...props} />
                        ) : null
                }
                options={options}
                onRowSelect={(data) => {
                    setRowsSelected(data)
                }}
                defaultSort={{ name: 'controlPointDesc', direction: 'asc' }}
                identifier="assignmentId"
                localColumnOrdersIdentifier={generateLocalStorageKey("Assignment_Table_List_Order")}
                localColumnSortIdentifier={generateLocalStorageKey("Assignment_Table_List_Sort")}
                columnsVisibilityIdentifier={generateLocalStorageKey("Assignment_ColumnsVisibility")}
                rowsPerPageIdentifier={generateLocalStorageKey("Assignment_RowsPerPage")}
                columns={columns}
                data={assignments}
                title={t("wkb.assignments.label")}
                loading={loadingAssignments}
                canGoBack={canGoBack}
                getData={getAssignmentsForPrintAndDownload}
                fontSize={12}
            />
            {openSyncInfoModal && <SyncInfoModal title={t("wkb.assignments.label")} selectedAssignments={rowsSelected.data} open={openSyncInfoModal}
                onClose={handleInfoSyncClose} />}
            {openReleaseLockConfirmModal && <ReleaseLockConfirmModal
                moduleTitle={t(`module.${SurveyType.WKB}`)}
                open={openReleaseLockConfirmModal}
                onClose={(release) => release ? releaseLockHandler() : setOpenReleaseLockConfirmModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            />}
            <LinkAssignmentModal id={OpenLinkModalId} open={openLinkModal} anchorEl={linkAssignment.element}
                onClose={followUpAssignmentsHandlerClose} data={linkAssignment.data} type={linkAssignment.type} />
            {isObjectModal &&
                <SelectSingleObjectModal open={isObjectModal}
                    buildings={all}
                    canProjectLevel
                    selectedBuilding={mappedState.selectedBuildingId}
                    onSave={handleSaveObjects}
                    onClose={handleCloseObjects} />
            }
            {canChangeResponsiblePerson && <SelectResponsiblePerson
                anchorEl={canChangeResponsiblePerson}
                canCreateRepairRequest
                customOplosser
                projectId={selected && selected.projectId}
                loading={organizationLoading}
                setLoading={(val) => setOrganizationLoading(val)}
                onSubmit={(data) => handleChangeResponsiblePersonForAssignment(data)}
                onClose={(data) => {
                    setCanChangeResponsiblePerson(null)
                }
                } />}
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    '@global': {
        '.MuiButtonBase-root:focus': {
            outline: 0,
        },
    },
    colorSuccess: {
        color: theme.palette.success.main
    },
    wbStatusIcon: {
        marginLeft: 4
    },
    grow: {
        flexGrow: 1,
        display: 'flex'
    },
    bold: {
        fontWeight: "bold"
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    progressStyle: {
        fontWeight: 'bold',
        fontSize: '12px',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        color: '#fff'
    },
    warning: {
        fill: '#ffc107'
    },
    pending: {
        fill: '#dc3545'
    },
    progressWrapper: {
        position: 'relative',
        color: theme.palette.grey[800],
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.only('sm')]: {
            width: '100%',
        }
    },
    customMainHeader: {
        fontSize: 12,
        '& .MuiButtonBase-root': {
            [theme.breakpoints.only('xs')]: {
                fontSize: 14,
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: 16,
            },
            fontSize: 14,
            padding: 0,
            margin: 0,
            whiteSpace: 'nowrap '
        },
    },
    headerTitle: {
        color: theme.palette.grey[900],
        fontWeight: 'bold',
        fontSize: 16,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
        },
    },
    customHeaderTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    tableIcon: {
        fontSize: 20,
        boxShadow: 'none',

    },
    messageCountIcon: {
        '& span': {
            [theme.breakpoints.down('md')]: {
                fontSize: 9,
                height: 14,
                minWidth: 14,
                padding: theme.spacing(0, 0.4)
            },
        }
    },
    iconMainContainer: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
        columnGap: 2,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'space-around'
        },
    },
    iconContainer: {
        [theme.breakpoints.only('sm')]: {
            minWidth: 20,
        },
        [theme.breakpoints.only('xs')]: {
            minWidth: 15,
        },
        '& svg': {
            fontSize: 16
        },
        minWidth: 32,
        display: 'flex',
        justifyContent: 'center'
    },
    menuContent: {
        '& .MuiPopover-paper': {
            borderRadius: 10,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important',
        },
        '& ul': {
            padding: theme.spacing(0),
        },
        '& ul > ul': {
            padding: theme.spacing(2),
        },
    },
    menuListStyle: {
        padding: theme.spacing(1.875, 1.25),
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: 10,
        maxWidth: 325,
        width: 'fit-content',
        minWidth: 200
    },
    dataTable: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        '& > .MuiPaper-root': {
            flex: '0 1 auto',
        },
        '& > .MuiToolbar-root.MuiToolbar-regular': {
            '& .MuiSvgIcon-root': {
                [theme.breakpoints.down('md')]: {
                    fontSize: 18,
                },
            },
            '& .MuiButtonBase-root': {
                padding: theme.spacing(1.2),
                '&:nth-last-child()': {
                    paddingRight: 0,
                }
            },
            display: 'flex',
            padding: theme.spacing(0, 2),
            '& .header svg': {
                fontSize: 30,
            },
            '& > div': {
                padding: theme.spacing(0),
                textAlign: 'right',
                flex: '1 0 auto',
                '& .MuiTypography-root': {
                    textAlign: 'left'
                }
            }
        },

        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '0 1px',
            marginTop: 'auto',
            padding: theme.spacing(.5, 1.5),
            '& caption': {
                display: 'none'
            }
        },
        '& .MuiTableCell-root': {
            position: 'relative',
            [theme.breakpoints.between('xs', 'md')]: {
                borderBottom: 'transparent',
                paddingBottom: 9
            },
            '& .datatables-noprint': {
                [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }
            },
            fontWeight: 'normal',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1.7, 0, 1.7, 0),
            },
            padding: theme.spacing(1.7, 0.5, 1.7, 0.5),
            '&.MuiTableCell-paddingCheckbox': {
                paddingLeft: theme.spacing(0.5),
                '& > div': {
                    justifyContent: 'center'
                },
                '& .MuiCheckbox-root': {
                    padding: theme.spacing(0.25),
                    '& .MuiSvgIcon-root': {
                        [theme.breakpoints.down('md')]: {
                            fontSize: 14
                        },
                    },
                }
            },
            '&.MuiTableCell-head': {
                fontWeight: 'bold',
                '& .MuiButtonBase-root': {
                    '& .MuiButton-label': {
                        fontWeight: 'bold'
                    }
                }
            },
        },
        '& .MuiTablePagination-actions': {
            '& > button': {
                padding: theme.spacing(0.2),
            }
        },
        '& .MuiTablePagination-toolbar': {
            minHeight: 'fit-content !important',
            paddingLeft: theme.spacing(0)
        },
        '& .MuiTableCell-footer': {
            padding: `${theme.spacing(0)} !important`,
        },
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: theme.spacing(0),
        background: theme.palette.background.default
    },
    scrollContainer: {
        height: '100%',
        padding: theme.spacing(0),
        overflow: 'auto'
    },
    momentLabelStyle: {
        [theme.breakpoints.up('sm')]: {
            wordBreak: "break-all",
        },
    },

    customTableCell: {
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('sm')]: {
            whiteSpace: 'nowrap'
        },
        fontSize: '14px '
    },
    customCheckbox: {
        [theme.breakpoints.down('md')]: {
            '& .MuiSvgIcon-root': {
                height: 14
            }
        },
    },
    customMomentRow: {
        position: 'relative',
        borderRadius: 4,
        '& td:nth-child(1)': {
            [theme.breakpoints.down('md')]: {
                width: '5%',
            },
        },
        '& td:nth-child(4)': {
            [theme.breakpoints.down('lg')]: {
                width: '21%',
                maxWidth: '21%',
                '& > $customTableCell': {
                    display: '-webkit-box',
                    '-webkit-line-clamp': 1,
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal'
                },
            },
        },
        '& td:nth-child(-n+6)': {
            [theme.breakpoints.only('xs')]: {
                width: '25%',
            },
        },
        '& td:nth-child(-n+7)': {
            [theme.breakpoints.only('sm')]: {
                width: '10%',
            },
        },
        '& .MuiTableCell-root': {
            padding: theme.spacing(.5, .4),
            '&:first-child': {
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
            },
            '&:last-child': {
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: 14,
                padding: theme.spacing(0, .4)
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: 16,
                padding: theme.spacing(0, .4)
            },
            fontSize: '14px'
        },
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.grey[100]
        },
        [theme.breakpoints.down('md')]: {
            '&:hover': {
                background: theme.palette.common.white,
            },
            background: theme.palette.common.white,
            boxShadow: '2px 2px 6px #ccc',
        }
    },
    removePadding: {
        padding: `${theme.spacing(0)} !important`,
        [theme.breakpoints.down('md')]: {
            border: 'none'
        },
    },
    customCell: {
        position: 'relative',
        height: 37,
        minHeight: 37,
        maxHeight: 37,
    },
    iconGridContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    paperStatus: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paperGridContainer: {
        rowGap: 5
    },
    titlePaperContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    papersWrap: {
        maxWidth: 'fit-content'
    },
    paperContainer: {
        display: 'flex',
        gap: 10,
        flexWrap: 'wrap',
        background: theme.palette.common.white,
        marginBottom: 10,
        boxShadow: ' rgba(0, 0, 0, 0.16) 0px 1px 4px',
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            '& > $papers:nth-child(2n)': {
                borderLeft: `2px solid ${theme.palette.grey[300]}`,
                paddingLeft: 10
            }
        }
    },
    paperChip: {
        height: 'fit-content',
        padding: theme.spacing(0.3, 0),
        color: theme.palette.common.white
    },
    chipProgress: {
        background: theme.palette.grey[600],
        color: theme.palette.common.white
    },
    chipClose: {
        background: `${red[500]} !important`,
        color: theme.palette.common.white
    },
    chipApproved: {
        background: `${green[500]} !important`,
        color: theme.palette.common.white
    },
    chipReOpen: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    papers: {
        flexGrow: 1,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        borderRadius: 0,
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 0.5),
        },
    },
    cardTitleBold: {
        fontWeight: 'bold'
    },
    cardTitle: {
        fontSize: 12,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
            lineBreak: 'anywhere',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineClamp: 1,
            wordBreak: "break-all", /* number of lines to show */
            display: "-webkit-box",
            boxOrient: "vertical",
            color: theme.palette.grey[800],
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
            lineBreak: 'anywhere',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineClamp: 1,
            wordBreak: "break-all", /* number of lines to show */
            display: "-webkit-box",
            boxOrient: "vertical",
            color: theme.palette.grey[800],
        },
        color: theme.palette.grey[500],
        whiteSpace: 'wrap'
    },
    iconButton: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
        padding: theme.spacing(.45, 0.5),
    },
    CustomIconButton: {
        height: 17,
        '& span:nth-child(1)': {
            [theme.breakpoints.down('md')]: {
                display: 'block',
                width: '13px'
            },
        },
    },
    assignmentsCardButton: {
        height: '100%',
        position: 'relative',
        width: '100%',
        fontSize: 14,
        [theme.breakpoints.only('xs')]: {
            fontSize: 14,
            marginTop: 4,
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: 16,
            marginTop: 4,
        },
    },
    groen: {
        color: theme.palette.success.main,
    },
    rood: {
        color: theme.palette.secondary.main,
    },
    oranje: {
        color: theme.palette.warning.main,
    },
    colorWarning: {
        color: theme.palette.error.main,
        fontSize: 16
    },
    content: {
        position: "absolute",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    spacer: {
        height: 0,
        overflow: "hidden"
    },
}));
