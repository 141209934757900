import React, { useEffect } from "react";
import { Button, Typography, Grid, useTheme, Stack, } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { history } from "../../_helpers";
import "../../LoginPage/login.css";

const LoginRequestSuccess = (props) => {
    const { webApiUrl } = window.appConfig;
    const theme = useTheme()
    const { t } = useTranslation();
    const locationState = props?.location?.state

    useEffect(() => {
        if (!locationState?.isFormSubmitted) {
            history.push("/login")
        }
    }, [locationState?.isFormSubmitted])

    const handleBackToLogin = () => {
        history.push('/login')
    }

    return (
        <Stack
            spacing={1}
            alignItems="center"
            justifyContent="center"
            className="login-grid"
            height="100vh"
            sx={{
                backgroundImage: "url(" + webApiUrl + "api/Config/WebBackground)",
            }}
        >
            <Grid
                container
                px={{ xs: 2, sm: 4 }}
                py={4}
                maxWidth={442}
                width='100%'
                bgcolor="common.white"
            >
                <Stack width={'100%'} alignItems={'center'} justifyContent={'center'} >
                    <CheckCircle sx={{ color: theme.palette.success.light, fontSize: '112px' }} />
                </Stack>
                <Stack my={2}>
                    <Typography
                        variant="h5"
                        textAlign="center"
                    >
                        {t('loginRequest.success.title')}
                    </Typography>
                </Stack>
                <Stack mb={2} >
                    <Typography
                        variant="caption"
                        textAlign={'center'}
                        color={'text.secondary'}>
                        {t('loginRequest.success.subtitle')}
                    </Typography>
                </Stack>
                <Button onClick={handleBackToLogin} fullWidth variant="contained">
                    {t('loginRequest.success.button')}
                </Button>
            </Grid>
        </Stack>
    )
}

export { LoginRequestSuccess }
