import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Stack,
  DialogTitle,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../../_constants";
import { getLocalStorageItem, setLocalStorageItem } from "../../../_helpers";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridV2 from "../../../components/DataGridV2";
import { LabelColumnV2 } from "../../../components/DataGridV2/DataColumnsV2";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";
import i18n from "../../../i18n";

const checkBuildingForRole = (role, building) =>
  building.roles && building.roles.includes(role);

const ObjectModal = ({
  isLoading,
  handleCloseObjectModal,
  isOpenObjectModal,
  handleClickedRowData,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [buildings, setBuildings] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isRowsDataLoading, setIsRowsDataLoading] = useState(false)
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_PAGINATION.AFTERCARE, undefined
  ))
  const { all = [], loading: buildingLoading } = useSelector((state) => state.buildings);
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_FILTER.AFTERCARE,
    sortKey: LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_SORT.AFTERCARE,
    i18n,
    t,
    apiRef,
    mode: "client"
  })

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_PAGINATION.AFTERCARE,
      newPaginationModel
    );
  }, [])

  const columns = [
    {
      field: "projectName",
      headerName: t("general.projectName"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            tooltipLabel={rowData.projectName}
            label={rowData.projectName}
            request={rowData}
            cellSubMenuOptions={{ options: getOptions({ field: "projectName", isQuickFilterDisplay: Boolean(rowData.projectName) }), extraData: { fieldName: "projectName" } }}
          />
        );
      },
    },
    {
      field: "buildingNoIntern",
      headerName: t("general.buildingNumber"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            tooltipLabel={rowData.buildingNoIntern}
            label={rowData.buildingNoIntern}
            request={rowData}
            cellSubMenuOptions={{ options: getOptions({ field: "buildingNoIntern", isQuickFilterDisplay: Boolean(rowData.buildingNoIntern) }), extraData: { fieldName: "buildingNoIntern" } }}
          />
        );
      },
    },
    {
      field: "addressText",
      headerName: t("general.objectAddress"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            tooltipLabel={rowData.addressText}
            label={rowData.addressText}
            request={rowData}
            cellSubMenuOptions={{ options: getOptions({ field: "addressText", isQuickFilterDisplay: Boolean(rowData.addressText) }), extraData: { fieldName: "addressText" } }}
          />
        );
      },
    },
    {
      field: "addressModel.postcode",
      headerName: t("general.address.postcode"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            label={rowData.addressModel.postcode}
            request={rowData}
            tooltipLabel={rowData.addressModel.postcode}
            cellSubMenuOptions={{ options: getOptions({ field: "addressModel.postcode", isQuickFilterDisplay: Boolean(rowData.addressText) }), extraData: { fieldName: "addressModel.postcode" } }}
          />
        );
      },
    },
    {
      field: "addressModel.place",
      headerName: t("general.address.city"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            tooltipLabel={rowData.addressModel.place}
            label={rowData.addressModel.place}
            request={rowData}
            cellSubMenuOptions={{ options: getOptions({ field: "addressModel.place", isQuickFilterDisplay: Boolean(rowData.addressModel.place) }), extraData: { fieldName: "addressModel.place" } }}
          />
        );
      },
    },
    {
      field: "buyerRenterName",
      headerName: t("general.buyer"),
      flex: 1,
      download: true,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <LabelColumnV2
            tooltipLabel={rowData.buyerRenterName}
            label={rowData.buyerRenterName}
            request={rowData}
            cellSubMenuOptions={{ options: getOptions({ field: "buyerRenterName", isQuickFilterDisplay: Boolean(rowData.buyerRenterName) }), extraData: { fieldName: "buyerRenterName" } }}
          />
        );
      },
    },
    {
      field: "buildingId",
      headerName: t("general.primaryKey"),
      download: false,
      print: false,
      filterable: false,
      sortable: false,
      hidden: true,
    },
  ];

  useEffect(() => {
    setIsRowsDataLoading(true);
    const filteredBuildings = all.filter((building) => {
      const isSiteManagerBuilding =
        checkBuildingForRole(userAccountRoleConstants.siteManager, building) &&
        !building.isAfterCareStarted;
      const isAftercareEmployeeBuilding =
        checkBuildingForRole(userAccountRoleConstants.aftercareEmployee, building) &&
        building.isAfterCareStarted;
      return isSiteManagerBuilding || isAftercareEmployeeBuilding;
    });

    setBuildings(
      filteredBuildings.map((a) => ({
        ...a,
        id: a.buildingId,
        addressText: a.addressModel
          ? `${a.addressModel.street || ""} ${a.addressModel.houseNo || ""} ${a.addressModel.houseNoAddition || ""}`
          : "",
      }))
    );
    setIsRowsDataLoading(false);
  }, [all, buildingLoading]);

  if (buildingLoading)
    return (
      <Stack height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={25} />
      </Stack>
    );

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      classes={{ paper: classes.dialog }}
      open={!!isOpenObjectModal}
      onClose={isLoading ? () => { } : handleCloseObjectModal}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        {t("general.object.select")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseObjectModal}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
      <Box className={classes.mainContainer}>
        {isOpenObjectModal && (
          <DataGridV2
            onRowClick={(params) => { handleClickedRowData(params) }}
            localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_SEARCH_TEXT.AFTERCARE}
            localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_FILTER.AFTERCARE}
            localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_SORT.AFTERCARE}
            localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_ORDER.AFTERCARE}
            localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_WIDTH.AFTERCARE}
            localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.OBJECT_MODAL_COLUMN_VISIBILITY.AFTERCARE}
            apiRef={apiRef}
            loading={buildingLoading || isRowsDataLoading || isLoading}
            rows={buildings}
            columns={columns}
            pagination
            {...(!!paginationModel && { paginationModel })}
            onPaginationModelChange={onPaginationModelChange}
            toolbarProps={{
              title: t("general.object.select"),
              selectedRows: rowSelectionModel
            }}
            filterMode="client"
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
          />
        )}
      </Box>
    </Dialog>

  );
};

export default ObjectModal;

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "100%",
    overflow: "hidden",
  },
  mainContainer: {
    backgroundColor: theme.palette.background.default,
    height: "calc(100% - 64px)",
  }
}));
