import { Avatar, Checkbox, ListItemButton, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material'
import { getNameInitials } from '../../_helpers';

const { webApiUrl } = window.appConfig;

const ParticipantsListItem = ({ userInfo, isCheckBoxVisible = false, handleSelectParticipant, selectedParticipants }) => {
    var srcUrl = webApiUrl + "api/home/GetPersonPhoto/" + userInfo.personId;
    return (
        <ListItemButton dense onClick={() => { if (isCheckBoxVisible) handleSelectParticipant(userInfo.loginId) }}>
            {isCheckBoxVisible && <>
                <ListItemIcon>
                    <Checkbox
                        edge="end"
                        disableRipple
                        checked={selectedParticipants?.includes(userInfo.loginId)}
                    />
                </ListItemIcon>
            </>}
            <ListItemAvatar>
                <Avatar src={srcUrl} alt={getNameInitials(userInfo.title)}>
                </Avatar>
            </ListItemAvatar>
            <ListItemText id={userInfo.loginId} primary={userInfo.title} secondary={userInfo.subTitle} />
        </ListItemButton>
    )
}

export default ParticipantsListItem