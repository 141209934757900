export const userAccountTypeConstants = {
    relation: 0,
    buyer: 1,
    employee: 2,
};

export const userAccountRoleConstants = {
    aftercareEmployee: "AftercareEmployee",
    propertyManager: "PropertyManager",
    siteManager: "SiteManager",
    subContractorSupervisor: "SubContractorSupervisor",
    subContractorEmployee: "SubContractorEmployee",
    buyersGuide:"BuyersGuide"
}