import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  CloudUpload,
  Delete,
  Edit,
  MoreVert,
  PriorityHigh,
} from "@mui/icons-material";
import axios from "axios";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { fileUploadConfig } from "../../../../_helpers/fileUploadConfig";
import AddDrawingLocation from "./AddDrawingLocation";
import Dropzone from "../../../../components/Dropzone";
import { formatDate, useCanDeleteFolder } from "../../../../_helpers";
import { useTranslation } from "react-i18next";
import { URLS } from "../../../../apis/urls";
import ImagesContainer from "../../../Survey/components/ImagesContainer";
import { useSelector } from "react-redux";

const { webApiUrl } = window.appConfig;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    disableRestoreFocus
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: "fit-content",
    color: theme.palette.grey[800],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
export default function DrawingLocationsTab(props) {
  const {
    canDrawingDossier,
    canDrop,
    isRootFolder,
    buildings,
    selected,
    selectedDossier,
    currentTab,
    activeTab,
    handleUpdateFiles,
    attachmentData,
    handleMoveExistingFiles,
    handleSelectFiles,
    isReadOnly,
    viewType,
    fileUploading,
    selectedFiles,
    activeFiles,
    handleGetAllImages,
    archivedFiles,
    removedFiles,
    buildingId,
    attachmentType,
    handlePreviewOfFiles,
    markFileAsViewed,
  } = props;

  const classes = useStyles();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordIndex, setSelectedRecordIndex] = useState(null);
  const [openAttachmentsMenu, setOpenAttachmentsMenu] = useState({
    open: false,
    Index: "",
  });
  const [drawingLocationsNames, setDrawingLocationsNames] = useState([]);
  const { t } = useTranslation();
  const canDeleteFolder = useCanDeleteFolder(
    openAttachmentsMenu.data && openAttachmentsMenu.data.id,
    props.buildingId
  );
  const fileInputRef = useRef();
  const [isOpenImageContainer, setIsOpenImageContainer] = useState(false);
  const [selectedDrawingLocation, setSelectedDrawingLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileAdding, setIsFileAdding] = useState(false);
  const [addRecordLoading, setAddRecordLoading] = useState(null);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const {
    dossier: { isUploading, isMoving },
  } = useSelector((state) => state);

  const fileListToArray = (list) => {
    const filesToArray = [];
    for (var i = 0; i < list.length; i++) filesToArray.push(list.item(i));
    return filesToArray;
  };

  useEffect(() => {
    if (!isMoving && selectedAttachment) {
      setOpenAttachmentsMenu((p) => ({ ...p, open: null, data: null }));
      setSelectedAttachment(null);
    }
  }, [isMoving]);
  useEffect(() => {
    if (!isUploading && isFileAdding) setIsFileAdding(null);
  }, [isFileAdding, isUploading]);

  const openAttachmentsMenuHandler = (event, Index, data, file) => {
    setOpenAttachmentsMenu((p) => ({
      ...p,
      open: event.currentTarget,
      Index,
      data,
    }));
    setSelectedAttachment(file);
  };

  useEffect(() => {
    if (selectedDossier) {
      setIsLoading(true);
      const url = `${webApiUrl}api/Dossier/GetDrawingLocationNamesForProject/${
        selectedDossier.projectId
      }${props.buildingId ? `?buildingId=${props.buildingId}` : ""}`;
      axios
        .get(url)
        .then((res) => {
          setDrawingLocationsNames(res.data);
          setIsLoading(false);
        })
        .catch((er) => {
          setIsLoading(false);
        });
    }
  }, [props.buildingId]);

  const recordSaveHandler = (data) => {
    const sendId = selectedRecord ? { id: selectedRecord.id } : {};
    selectedRecord && setAddRecordLoading(selectedRecord.id);
    axios
      .post(
        `${webApiUrl}api/Dossier/AddOrUpdateDrawingLocationNameForProject`,
        {
          name: data.inputValue || data.name,
          projectId: selectedDossier.projectId,
          buildingId: props.buildingId,
          ...sendId,
        }
      )
      .then(({ data: { viewModel } }) => {
        setAddRecordLoading(null);
        setDrawingLocationsNames((p) =>
          selectedRecord
            ? Object.assign([...p], { [selectedRecordIndex]: viewModel })
            : [...p, { ...viewModel }]
        );
      })
      .catch((er) => {
        setAddRecordLoading(null);
      });
  };

  const deleteDrawingLocationHandler = (Index) => {
    if (!canDeleteFolder) {
      handleUpdateFiles(
        { isDeleted: true },
        props.type === "internal",
        selectedAttachment
      );
      setSelectedAttachment(null);
    } else {
      axios
        .delete(
          `${webApiUrl}api/Dossier/DeleteDrawingLocationNameForProject/${openAttachmentsMenu.data.id}`
        )
        .then((res) => {
          const data = Object.assign([], drawingLocationsNames);
          data.splice(Index, 1);
          setDrawingLocationsNames(data);
          setOpenAttachmentsMenu((p) => ({ ...p, open: null, data: null }));
          setSelectedAttachment(null);
        })
        .catch(console.log);
    }
  };

  const handleItemClick = (item) => {
    if (currentTab === 1 || currentTab === 2) {
      setIsOpenImageContainer((p) => !p);
      setSelectedDrawingLocation(item);
    }
  };

  return isLoading ? (
    <div className={classes.loaderContainer}>
      {" "}
      <CircularProgress size={20} />
    </div>
  ) : (
    <>
      <Grid item xs={12} className={classes.floorFilesContainer}>
        <input
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          accept={"application/pdf"}
          onChange={({ target: { files } }) => {
            setIsFileAdding(openAttachmentsMenu.data.id);
            handleSelectFiles(fileListToArray(files), {
              type: props.type,
              isActive: true,
              drawingLocationId: openAttachmentsMenu.data.id,
            });
            setOpenAttachmentsMenu((p) => ({ ...p, open: null }));
          }}
        />
        <Grid container className={classes.floorGridContainer}>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={openAttachmentsMenu.open}
            keepMounted
            open={Boolean(openAttachmentsMenu.open)}
            onClose={() =>
              setOpenAttachmentsMenu((p) => ({ ...p, open: null }))
            }
          >
            {(selectedAttachment || canDeleteFolder || isMoving) && (
              <MenuItem
                disabled={isMoving || (!selectedAttachment && !canDeleteFolder)}
                onClick={() =>
                  deleteDrawingLocationHandler(openAttachmentsMenu.Index)
                }
              >
                {isMoving ? <CircularProgress size={20} /> : <Delete />}
                {t("general.delete")}
              </MenuItem>
            )}
            <MenuItem onClick={() => fileInputRef.current.click()}>
              <CloudUpload />
              {t("dossier.general.uploadImageButton.title")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedRecord(openAttachmentsMenu.data);
                setSelectedRecordIndex(openAttachmentsMenu.Index);
                setOpenAttachmentsMenu((p) => ({ ...p, open: null }));
              }}
            >
              <Edit />
              {t("drawing.dossier.rename.title")}
            </MenuItem>
          </StyledMenu>
          {drawingLocationsNames &&
            drawingLocationsNames.map((folder, i) => {
              const attachment =
                attachmentData &&
                !!attachmentData.length &&
                attachmentData.find((a) => a.drawingLocationId === folder.id);
              return (isRootFolder || attachment) && (
                <Grid
                  className={classes.floorThumnailContainer}
                  container
                  justifyContent="center"
                >
                  <div
                    onClick={() => handleItemClick(folder)}
                    className={clsx(
                      classes.dropzone,
                      classes.folderCustomDropStyle
                    )}
                  >
                    {currentTab === 0 && (
                      <IconButton
                        id="demo-customized-button"
                        aria-controls="demo-customized-menu"
                        aria-haspopup="true"
                        disabled={isUploading && isFileAdding === folder.id}
                        className={classes.moreIconStyle}
                        onClick={(e) =>
                          openAttachmentsMenuHandler(e, i, folder, attachment)
                        }
                        aria-label="settings"
                        size="large">
                        <MoreVert />
                      </IconButton>
                    )}
                    {attachment ? (
                      <Grid
                        onClick={(e) => {
                          currentTab === 0 &&
                            handleGetAllImages("preview", [attachment]);
                        }}
                      >
                        {isUploading && isFileAdding === folder.id ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Grid
                            onClick={() => {
                              if (currentTab === 0 && attachment.hasUpdates)
                                markFileAsViewed(
                                  attachment.fileId,
                                  buildingId,
                                  props.type,
                                  attachmentType,
                                  attachment.isGeneralFile
                                );
                            }}
                            onMouseEnter={() => {
                              if (currentTab === 0 && attachment.hasUpdates)
                                markFileAsViewed(
                                  attachment.fileId,
                                  buildingId,
                                  props.type,
                                  attachmentType,
                                  attachment.isGeneralFile
                                );
                            }}
                            className={classes.thumnailContainer}
                            container
                            xs={12}
                            justifyContent="center"
                          >
                            {(currentTab !== 0
                              ? (currentTab === 1
                                  ? archivedFiles
                                  : removedFiles
                                )
                                  .filter(
                                    (file) =>
                                      file.drawingLocationId === folder.id
                                  )
                                  .some((a) => a.hasUpdates)
                              : attachment.hasUpdates) && (
                              <Tooltip
                                title={t(
                                  "dossier.sidebar.priority.high"
                                )}
                              >
                                <PriorityHigh
                                  color="error"
                                  style={{
                                    position: "absolute",
                                    left: -4,
                                    top: 3,
                                  }}
                                />
                              </Tooltip>
                            )}
                            <div
                              className={classes.thumbnail}
                              style={{
                                backgroundImage: `url("${URLS.GET_UPLOADED_DOSSIER_FILE_THUMBNAIL}${attachment.fileId}")`,
                              }}
                            />
                            <Typography
                              variant="caption"
                              className={classes.caption}
                              noWrap
                            >
                              {attachment.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              className={classes.caption}
                              noWrap
                            >
                              {formatDate(
                                new Date(attachment.lastModifiedOn)
                              )}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      isRootFolder && (
                        <Grid item style={{ margin: "0 auto" }}>
                          <Grid
                            className={classes.thumnailContainer}
                            container
                            justifyContent="center"
                          >
                            {isUploading && isFileAdding === folder.id ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Dropzone
                                className={classes.dropzone}
                                canDrawingDossier={canDrawingDossier}
                                handlePreviewOfFiles={handlePreviewOfFiles}
                                accept={
                                  canDrawingDossier
                                    ? "application/pdf"
                                    : fileUploadConfig.allowedMimeTypes
                                        .map((f) => f.mime)
                                        .join()
                                }
                                selectedObjects={
                                  selectedDossier.buildingInfoList
                                }
                                buildings={buildings.filter(
                                  (x) => x.projectId === selected.projectId
                                )}
                                withUploadFileDialog={!!buildings.length}
                                handleSelectExistingImages={(data) =>
                                  handleMoveExistingFiles(data, folder.id)
                                }
                                onFilesAdded={(data) => {
                                  handleSelectFiles(data, {
                                    type: props.type,
                                    isActive: true,
                                    drawingLocationId: folder.id,
                                  });
                                  setIsFileAdding(folder.id);
                                }}
                                disabled={isReadOnly || canDrop}
                                uploading={fileUploading}
                              />
                            )}
                          </Grid>
                        </Grid>
                      )
                    )}
                  </div>
                  <Tooltip title={folder.name}>
                    <Typography variant="h6" className={classes.folderTitle}>
                      {addRecordLoading === folder.id ? (
                        <CircularProgress size={18} />
                      ) : (
                        folder.name
                      )}
                    </Typography>
                  </Tooltip>
                </Grid>
              );
            })}
          {isRootFolder && (
            <Grid item style={{ margin: "0 auto" }}>
              <AddDrawingLocation
                existingFloors={drawingLocationsNames}
                buildingId={props.buildingId}
                state={selectedRecord}
                canOpen={selectedRecord}
                onSave={(data) => {
                  if (data) recordSaveHandler(data);
                  setSelectedRecord(null);
                  setSelectedRecordIndex(null);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isOpenImageContainer && selectedDrawingLocation && (
        <ImagesContainer
          hideTitle
          onClose={() => {
            setIsOpenImageContainer((p) => !p);
            setSelectedDrawingLocation(null);
          }}
          handleView={(attachment) =>
            markFileAsViewed(
              attachment.fileId,
              buildingId,
              props.type,
              attachmentType,
              attachment.isGeneralFile
            )
          }
          showFileTitle
          onPreview={() =>
            handleGetAllImages(
              "preview",
              (currentTab === 1 ? archivedFiles : removedFiles).filter(
                (file) => file.drawingLocationId === selectedDrawingLocation.id
              )
            )
          }
          hideCheckBox
          open={isOpenImageContainer}
          data={(currentTab === 1 ? archivedFiles : removedFiles).filter(
            (file) => file.drawingLocationId === selectedDrawingLocation.id
          )}
          drawingPDFThumbnail
        />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: "none",
  },
  loaderContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  floorFilesContainer: {
    maxHeight: 410,
    minHeight: 177,
    overflow: "auto",
    padding: theme.spacing(1.25, 1.25, 3.25, 1.25),
  },
  thumbnail: {
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: 80,
    width: "100%",
    display: "block",
  },
  caption: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  thumnailContainer: {
    width: 135,
    height: 135,
    cursor: "pointer",
    minHeight: 120,
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  floorThumnailContainer: {
    width: 135,
    height: 135,
    cursor: "pointer",
    minHeight: 120,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  isLeft: {
    position: "absolute",
    top: -3,
    left: -3,
    padding: 0,
  },
  icons: {
    fill: theme.palette.common.white,
    width: "1.2em",
    height: "1.2em",
  },
  attachmentStatus: {
    maxWidth: "80%",
    position: "fixed",
    backgroundColor: theme.palette.common.white,
    boxShadow: "1px 1px 6px #eee",
    bottom: 10,
    padding: "10px",
    marginTop: "23px",
    right: "-100%",
    opacity: 0,
    minWidth: 300,
    transition: "1s all",
    zIndex: 2,
    ["@media screen and (max-width:821px)"]: {
      bottom: 66,
    },
  },
  modificationHeader: {
    fontSize: "1rem",
  },
  isRight: {
    position: "absolute",
    right: 0,
    top: -4,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    justifyContent: "space-around",
    gridTemplateColumns: "repeat(auto-fill, 135px)",
    display: "grid",
    gap: 10,
  },
  floorGridContainer: {
    justifyContent: "space-around",
    gridTemplateColumns: "repeat(auto-fill, 135px)",
    display: "grid",
    gap: 10,
    rowGap: 50,
  },
  dropzone: {
    padding: 0,
    height: "100%",
  },
  createIcon: {
    fontSize: 85,
    color: theme.palette.grey[500],
  },
  customDropStyle: {
    width: "100%",
    border: "2px dashed rgb(187, 186, 186)",
    display: " flex",
    fontSize: "16px",
    alignItems: "center",
    borderRadius: "5px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  folderCustomDropStyle: {
    width: "100%",
    position: "relative",
    display: " flex",
    fontSize: "16px",
    alignItems: "center",
    borderRadius: "5px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  moreIconStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
  },
  whiteCheckbox: {
    top: 2,
    left: 2,
  },
  folderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.grey[800],
    marginTop: 10,
    display: "-webkit-box",
    maxWidth: 135,
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
