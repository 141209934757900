import { commonActions } from "../../_actions";
import { apps, userAccountRoleConstants } from "../../_constants";

export const goToBuildingOverviewPage = (buildingId, buildings, dispatch, history) => {
  const selectedItem = buildings.find((x) => x.buildingId === buildingId);
  if (selectedItem) {
    dispatch(commonActions.selectBuilding(selectedItem));
    history.push("/object/" + selectedItem.buildingNoIntern);
  }
};

export const checkRoles = ({buildings , role , buildingId , projectId}) => {
  if (buildingId) {
    const building = buildings.all.find((building) => building.buildingId === buildingId)
    return building && building.roles && building.roles.includes(role)
  }
  else if (projectId) {
    const filteredBuildings = buildings.all.filter((building) => building.projectId === projectId)
    return buildings.length === filteredBuildings.filter(building => building.roles && building.roles.includes(role)).length
  }
};

export const checkHasAdminRightsForChat = ({app , buildings , buildingId , projectId})=>{
  let hasAdminRightsForChat = false;
    switch (app) {
      case apps.huisinfo:
        hasAdminRightsForChat = checkRoles( { buildings , buildingId , projectId , role: userAccountRoleConstants.buyersGuide})
        break;
      case apps.constructionQuality:
        hasAdminRightsForChat = checkRoles({role :userAccountRoleConstants.siteManager, buildings , buildingId , projectId})
        break;
      case apps.aftercare:
        hasAdminRightsForChat = checkRoles({role :userAccountRoleConstants.siteManager, buildings , buildingId , projectId}) || checkRoles({role:userAccountRoleConstants.aftercareEmployee , buildings , buildingId , projectId})
        break;
      default:
        break;
    }
    return hasAdminRightsForChat
}

export const  getTotalUnreadCount = (chats)=>{
  if (chats && chats.length > 0) {
    return chats.reduce((chatCount, chat) => {
     
      chatCount+= chat.unreadMessagesCount;
      return chatCount;
    }, 0);
  }
  return 0
}