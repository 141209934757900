import React, { Component } from "react";
import packageJson from '../../package.json';
import { Dialog } from "@mui/material";
import { authHeader } from "../_helpers";
import { unregister } from "../registerServiceWorker";
import { withTranslation } from 'react-i18next';

const { webApiUrl } = window.appConfig;

class AutoReload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeHasChanged: false,
            isOnline: window.navigator.onLine
        };
        this.fetchSource = this.fetchSource.bind(this);
    }

    componentDidMount() {
        const { tryDelay } = this.props;
        const un = localStorage.getItem('sw-un');
        if (!un) this.reloadApp();
        this.fetchSource();
        this.interval = setInterval(this.fetchSource, tryDelay);
        window.addEventListener("online", () => this.setState({isOnline:window.navigator.onLine}));
        window.addEventListener("offline", () => this.setState({isOnline:window.navigator.onLine}));
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener("offline", () => 0);
        window.removeEventListener("online", () => 0);
    }

    fetchSource() {
        const url = webApiUrl + 'api/config/CheckApiStatus';
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        this.setState({
            categories: [],
            options: [],
            searchTerm: ''
        });

        fetch(url, requestOptions)
            .then(Response => Response.json())
            .then(findResponse => {
                if (findResponse.version.toUpperCase() !== packageJson.version.toUpperCase())
                    this.setState({ codeHasChanged: true });
            })
            .catch(() => {
                //do nothing
            });
    }

    async reloadApp(e) {
        if (e) e.preventDefault();
        localStorage.setItem('sw-un', "true");
        await unregister();
        if (window.caches) {
            const keys = await window.caches.keys();
            for (let name of keys) {
                await caches.delete(name);
            }
        }
        window.location.reload(true);
    }
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <input type="hidden" name="version" value={packageJson.version} />
                {
                    // Added to remove from dev env...
                    !!this.state.codeHasChanged && process.env.NODE_ENV === 'production' && this.state.isOnline ?
                        <Dialog open>
                            <div style={{
                                padding: "1em",
                                backgroundColor: "bisque",
                                borderRadius: 5,
                                textAlign: "center",
                                margin: "auto"
                            }}>
                                <div>{t('general.newVersion.info')}</div>
                                <div>
                                    <a href="javascript:void(0);" onClick={this.reloadApp}>{t('general.newVersion.clickHereToReload')}</a>
                                </div>
                            </div>
                        </Dialog>
                        :
                        null
                }
            </React.Fragment>
        );
    }
}

AutoReload.defaultProps = {
    url: "/",
    tryDelay: 5 * 60 * 1000, // 5 minutes
    forceDelay: 1 * 60 * 60 * 1000, // 1 hour
};

export default (withTranslation()(AutoReload));
