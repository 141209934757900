import React, { useRef, useState } from 'react';
import {
    Typography,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, CreateNewFolder, Folder, LocationOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ExistingImageModal from './ExistingImageModal';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

const UploadFiles = ({ hideExistingImageModal = false, isFundering, title = '', isAssignmentFile, canDrawingDossier, existingImageModal, redirectRepairRequestHandler, isHideLocatie, isSurveying, open, onClose, buildings, handleSelectExistingImages, handleSubmit, handlePreviewOfFiles, selectedDossier, isSingle, ...props }) => {
    const [isExistingImageModal, setIsExistingImageModal] = useState(false);
    const [isBottmDrawer, setIsBottmDrawer] = useState(false);

    const { t } = useTranslation()
    const theme = useTheme();
    const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles({ isSurveying, matchWidthDownXs });
    const fileInputRef = useRef();
    const { images } = useSelector(state => state.dossier);

    const onFilesAdded = (evt) => {
        if (props.disabled) return
        const files = evt.target.files
        if (handleSubmit) {
            const array = fileListToArray(files)
            handleSubmit(array)
        }
        onClose();
    }

    const fileListToArray = (list) => {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }


    const openFileDialog = () => {
        fileInputRef.current.click();
    }

    return (
        <Dialog open={open} onClose={onClose} className={classes.dialogContainer}>
            {<ExistingImageModal
                closeUploadDialog={onClose}
                isAssignmentFile={isAssignmentFile}
                isFundering={isFundering}
                canDrawingDossier={canDrawingDossier}
                existingImageModal={existingImageModal}
                isSurveying={isSurveying} bottomDrawer={isBottmDrawer}
                bottomDrawerClose={() => setIsBottmDrawer(false)} images={isSurveying ? [] : images}
                onselect={handleSelectExistingImages}
                handlePreviewOfFiles={handlePreviewOfFiles} isSingle={isSingle} open={isExistingImageModal} handleClose={() => { setIsExistingImageModal(false); onClose() }} />}
            <DialogContent className={classes.container}>
                <Grid item xs={12} className={classes.dialogHeaderContainer}>
                    <Typography className={classes.grow}>
                        {title ? t(title) : null}
                    </Typography>
                    <IconButton className={classes.closeIcon} component="span" size="small" onClick={onClose} >
                        <Close />
                    </IconButton>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item container xs={12} spacing={2} className={classes.mainContent}>
                        {!isSurveying && <Grid item container xs={12} className={classes.grid}>
                            <Grid item xs={6} className={classes.content}>
                                <Typography variant={'h4'} className={classes.title}>{t(isSurveying ? 'general.disk' : 'dossier.general.uploadImageButton.title')}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.content}>
                                <Typography variant={'h4'} className={classes.title}>{t(isSurveying ? 'Huisinfo' : "dossier.file.upload.title.1")}</Typography>
                            </Grid>
                        </Grid>}
                        <Grid item container xs={12} className={classes.grid}>
                            <Grid item xs={(isSurveying && !isHideLocatie && !hideExistingImageModal) ? 4 : 6} className={classes.content}>
                                <div className={classes.itemContainer} onClick={openFileDialog}>
                                    {isSurveying && <Typography variant={'h4'} className={classes.title}>{t('general.disk')}</Typography>}
                                    <input
                                        ref={fileInputRef}
                                        className={classes.fileInput}
                                        type="file"
                                        accept={props.accept ? props.accept : "*"}
                                        multiple={!isSingle}
                                        onChange={onFilesAdded}
                                    />
                                    <IconButton
                                        component="span"
                                        size="small"
                                    >
                                        <CreateNewFolder className={classes.icons} />
                                    </IconButton>
                                </div>
                            </Grid>
                            {!hideExistingImageModal && <Grid item xs={(isSurveying && !isHideLocatie) ? 4 : 6} className={classes.content}>
                                <div className={classes.itemContainer} onClick={() => matchWidthDownXs && existingImageModal ? setIsBottmDrawer(true) : setIsExistingImageModal(true)}>
                                    {isSurveying && <Typography variant={'h4'} className={classes.title}>{t('Huisinfo')}</Typography>}
                                    <IconButton
                                        component="span"
                                        size="small"
                                    >
                                        <Folder className={classes.icons} />
                                    </IconButton>
                                </div>
                            </Grid>}
                            {isSurveying && !isHideLocatie && <Grid item xs={!hideExistingImageModal?4:6} className={classes.content}>
                                <div className={classes.itemContainer} onClick={redirectRepairRequestHandler}>
                                    {isSurveying && <Typography variant={'h4'} className={classes.title}>{t("general.location")}</Typography>}
                                    <IconButton
                                        component="span"
                                        size="small"
                                    >
                                        <LocationOn className={classes.icons} />
                                    </IconButton>
                                </div>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default UploadFiles;

const useStyles = makeStyles((theme) => ({
    dialogHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && `1px solid ${theme.palette.grey[100]}`,
        paddingBottom: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && 10,
        marginBottom: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && 10,
    },
    title: {
        textAlign: 'center',
        fontSize: ({ isSurveying }) => isSurveying ? 12 : 21,
        margin: ({ isSurveying }) => isSurveying ? '8px 0' : 0,
        color: ({ isSurveying }) => isSurveying ? theme.palette.common.black : theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: ({ isSurveying }) => isSurveying ? 10 : 12,
        }
    },
    icons: {
        width: ({ isSurveying }) => isSurveying ? '2em' : '3em',
        height: ({ isSurveying }) => isSurveying ? '2em' : '3em',
        fill: theme.palette.primary.main
    },
    dialogContainer: {
        '& .MuiDialog-container': {
            alignItems: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) ? 'flex-end' : 'center',
        },
        '& .MuiDialog-paper': {
            margin: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) ? 0 : 32,
            borderRadius: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && '14px 14px 0 0',
            width: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && '100%',
        },
    },
    container: {
        padding: theme.spacing(3.125),
        position: 'relative'
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0.5),
        },
    },
    itemContainer: {
        display: 'flex',
        flexDirection: ({ isSurveying }) => isSurveying ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ({ isSurveying }) => isSurveying ? theme.palette.grey[300] : theme.palette.common.white,
        padding: theme.spacing(1),
        borderRadius: ({ isSurveying }) => isSurveying ? 7 : 0,
        width: ({ isSurveying }) => isSurveying ? 115 : 'auto',
        cursor: 'pointer'
    },
    mainContent: {
        marginTop: 10,
        marginBottom: 10,
    },
    grid: {
        marginTop: 15,
        marginBottom: 15,
    },
    fileInput: {
        display: 'none'
    },
    grow: {
        flexGrow: 1,
        fontSize: 15,
        fontWeight: 'bold',
        color: theme.palette.grey[800]
    }
    // closeIcon: {
    //     position: 'absolute',
    //     right: 10,
    //     top: 10
    // }
}));
