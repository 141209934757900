import i18n from '../i18n';
import { surveyConstants } from '../_constants';
import { shortenSurveyingMomentTitle, sortAlphaNumerically } from '../_helpers';
import { internalAssignmentStatus, internalSurveyingMomentStatus, SurveyStatus } from '../_helpers/enumsHelper';

const initialState = {
    surveys: [],
    getSurveysLoading: false,
    getSurveysError: null,
    selectedMoment: null,
    getMomentError: null,
    getMomentLoading: false,
    selectedAssignment: null,
    getAssignmentError: null,
    getAssignmentLoading: false,
    attachmentLoading: false,
    attachmentError: false,
    objects: [],
    getObjectListError: null,
    getObjectListLoading: false,
    getSettingsDataLoading: false,
    getSettingsDataError: null,
    settingsData: [],
    inspections: [],
    isSyncing: false,
    selectedInspection: null,
    repairRequests: [],
    canSendMailSuccessfully: false,
    canSendMailError: null,
    isInternalCompleteAssignmentError: null,
    isInternalCompleteAssignmentLoading: false,
    isCompleteAssignmentError: null,
    isCompleteAssignmentLoading: false,
    applicationManagerItems: null,
    getApplicationError: null,
    getApplicationLoading: false,
    projectsForLinking: null,
    getProjectsLikingError: null,
    getProjectsLikingLoading: false,
    sortKey: 'endDate',
    sortType: 'asc',
    pinImagesForReport: null,
    reportLoading: false,
    repairRequestsReportImages: [],
    addDrawingLocationName: [],
    addDrawingLocationNameError: null,
    addDrawingLocationNameLoading: false,
    getDrawingLocationNameError: null,
    getDrawingLocationNameLoading: false,
    deleteDrawingLocationNameError: null,
    deleteDrawingLocationNameLoading: false,
    assignments: [],
    surveyModuleCounts: {}
};

const t = (key, params) => i18n.t(key, params);

export function surveys(state = initialState, action) {
    switch (action.type) {
        case surveyConstants.DELETE_RR_LOADING: {
            return {
                ...state,
                deleteRRLoading: true
            };
        }
        case surveyConstants.DELETE_RR_SUCCESS: {
            return {
                ...state,
                repairRequests: state.repairRequests.filter(p => p.requestId !== action.data.requestId),
                deleteRRLoading: false
            };
        }
        case surveyConstants.DELETE_RR_ERROR: {
            return {
                ...state,
                deleteRRLoading: false
            };
        }
        case surveyConstants.COMPLETE_RR_LOADING: {
            return {
                ...state,
                isCompleteRRLoading: true
            };
        }
        case surveyConstants.COMPLETE_RR_SUCCESS: {
            let repairRequests = Object.assign([], state.repairRequests);
            const findIndex = repairRequests.findIndex(p => p.requestId === action.data.requestId);
            if (findIndex >= 0) {
                repairRequests = Object.assign(repairRequests, {
                    [findIndex]: {
                        ...repairRequests[findIndex],
                        completed: true,
                        completedByBuyer: true,
                        status: repairRequests[findIndex].status === "Afgewezen" ? "Afgewezen" : "Afgehandeld",
                        isRework: true,
                    }
                });
            }
            return {
                ...state,
                repairRequests,
                isCompleteRRLoading: false
            };
        }
        case surveyConstants.COMPLETE_MULTI_RR_SUCCESS: {
            const repairRequests = state.repairRequests.map(p => {
                const isSelected = action.data.find(a => a.requestId === p.requestId);
                if (isSelected) {
                    return {
                        ...p,
                        isRework: false,
                        completed: true,
                        completedByBuyer: true,
                        status: p.status === "Afgewezen" ? "Afgewezen" : "Afgehandeld"
                    }
                }
                return p;
            });
            return {
                ...state,
                repairRequests,
                isCompleteRRLoading: false
            };
        }
        case surveyConstants.COMPLETE_RR_ERROR: {
            return {
                ...state,
                isCompleteRRLoading: false
            };
        }
        case surveyConstants.ADD_UPDATE_REWORK_LOADING: {
            return {
                ...state,
                isUpdateReworkLoading: true
            };
        }
        case surveyConstants.ADD_UPDATE_REWORK_SUCCESS: {
            let repairRequests = Object.assign([], state.repairRequests);
            const findIndex = repairRequests.findIndex(p => p.requestId === action.data.requestId);
            if (findIndex >= 0) {
                repairRequests = Object.assign(repairRequests, {
                    [findIndex]: {
                        ...repairRequests[findIndex],
                        completed: false,
                        completedByBuyer: false,
                        isRework: true,
                        status: "In behandeling",
                    }
                });
            }
            return {
                ...state,
                repairRequests,
                isUpdateReworkLoading: false
            };
        }
        case surveyConstants.ADD_UPDATE_REWORK_ERROR: {
            return {
                ...state,
                isUpdateReworkLoading: false
            };
        }
        case surveyConstants.STORE_IMAGES: {
            return {
                ...state,
                repairRequestsReportImages: action.data
            };
        }
        case surveyConstants.GET_APPLICATION_MANAGER_LOADING: {
            return {
                ...state,
                getApplicationLoading: true,
                getApplicationError: null,
            };
        }
        case surveyConstants.START_SIGNATURE_LOADING: {
            return {
                ...state,
                isSecondSignatureInitiatedLoading: true
            };
        }
        case surveyConstants.START_SIGNATURE_SUCCESS: {
            return {
                ...state,
                isSecondSignatureInitiatedLoading: false,
                selectedInspection: { ...state.selectedInspection, isSecondSignatureInitiated: true }
            };
        }
        case surveyConstants.START_SIGNATURE_ERROR: {
            return {
                isSecondSignatureInitiatedLoading: false,
                ...state,
            };
        }
        case surveyConstants.CLEAR_PDF_REPORT_DETAILS_DATA: {
            return {
                ...state,
                pinImagesForReport: null,
                selectedInspection: null,
                repairRequests: [],
            };
        }
        case surveyConstants.GET_INSPECTION_PIN_IMAGES_SUCCESS: {
            return {
                ...state,
                pinImagesForReport: action.images,
            };
        }
        case surveyConstants.GET_PDF_REPORT_DETAILS_LOADING: {
            return {
                ...state,
                reportLoading: true
            };
        }
        case surveyConstants.GET_PDF_REPORT_DETAIL_SUCCESS: {
            return {
                ...state,
                selectedInspection: action.data.inspectionDetail,
                repairRequests: action.data.repairRequests,
                reportLoading: false,
            };
        }
        case surveyConstants.GET_PDF_REPORT_DETAIL_ERROR: {
            return {
                ...state,
                reportLoading: false
            };
        }
        // case surveyConstants.UPDATE_ASSIGNMENT_SORT_ORDER: {
        //     if (!state.selectedMoment) return state;
        //     let data = state.selectedMoment.controlPoints;
        //     const { key, sortType } = action;
        //     if (key === "name")
        //         data = data && data.sort((a, b) => sortAlphaNumerically(a.description, b.description, sortType));
        //     else if (key === "status")
        //         data = data && data.sort((a, b) => sortAlphaNumerically(a.internalStatusPlainText, b.internalStatusPlainText, sortType));
        //     else if (key.includes('Date'))
        //         data = data && data.sort((a, b) => {
        //             if (sortType === 'desc')
        //                 return new Date(a[key + "PlainText"]) - new Date(b[key + "PlainText"]);
        //             return new Date(b[key + "PlainText"]) - new Date(a[key + "PlainText"]);
        //         });
        //     else data = data && data.sort((a, b) => sortAlphaNumerically(a.serialNumber, b.serialNumber, sortType));
        //     state.selectedMoment.controlPoints = data;
        //     return {
        //         ...state,
        //         sortKey: key,
        //         sortType
        //     };
        // }
        case surveyConstants.GET_APPLICATION_MANAGER_ACTION_ITEMS_SUCCESS: {
            return {
                ...state,
                applicationManagerItems: action.applicationItems.data,
                getApplicationError: null,
                getApplicationLoading: false,
            };
        }
        case surveyConstants.GET_APPLICATION_MANAGER_ACTION_ITEMS_ERROR: {
            return {
                ...state,
                applicationManagerItems: [],
                getApplicationError: action.error,
                getApplicationLoading: false,
            };
        }
        case surveyConstants.LINK_EMAIL_ERROR: {
            return {
                ...state,
                linkEmailError: action.error,
                isUpdatingPerson: false,
            };
        }
        case surveyConstants.LINK_EMAIL_LOADING: {
            return {
                ...state,
                linkEmailError: null,
                isUpdatingPerson: true,
            };
        }
        case surveyConstants.LINK_EMAIL_SUCCESS: {
            const index = state.applicationManagerItems.wbEmailLinkingInfo.findIndex(p => p.email === action.email);
            if (index >= 0) {
                state.applicationManagerItems.wbEmailLinkingInfo[index].loginPersonName = action.data.person;
                state.applicationManagerItems.wbEmailLinkingInfo[index].loginId = action.loginId;
            }
            return {
                ...state,
                linkEmailError: null,
                isUpdatingPerson: false,
            };
        }
        case surveyConstants.CONFIRM_PROJECT_ERROR: {
            return {
                ...state,
                confirmProjectLinkError: action.error,
                confirmProjectLinkLoading: false,
            };
        }
        case surveyConstants.CONFIRM_PROJECT_LOADING: {
            return {
                ...state,
                confirmProjectLinkError: null,
                confirmProjectLinkLoading: true,
            };
        }
        case surveyConstants.CONFIRM_PROJECT_SUCCESS: {
            const index = state.applicationManagerItems.wbProjectsLinkingInfo.findIndex(p => (p.wbProjectId === action.wbProjectId && action.projectId === p.projectId));
            if (index >= 0) {
                state.applicationManagerItems.wbProjectsLinkingInfo[index].linked = true;
            }
            return {
                ...state,
                projectsForLinking: state.projectsForLinking.filter(project => project.projectId !== action.projectId),
                confirmProjectLinkError: null,
                confirmProjectLinkLoading: false,
            };
        }
        case surveyConstants.UPDATE_LINK_PROJECT: {
            const index = state.applicationManagerItems.wbProjectsLinkingInfo.findIndex(p => p.wbProjectId === action.wbProjectId);
            if (index >= 0 && action.project) {
                state.applicationManagerItems.wbProjectsLinkingInfo[index].projectName = action.project.projectName;
                state.applicationManagerItems.wbProjectsLinkingInfo[index].projectNo = action.project.projectNo;
                state.applicationManagerItems.wbProjectsLinkingInfo[index].projectId = action.project.projectId;
            }
            return {
                ...state,
                confirmProjectLinkError: null,
                confirmProjectLinkLoading: false,
            };
        }
        case surveyConstants.MAP_BUILDING_TO_OBJECT_SUCCESS: {
            const findIndex = state.surveys.findIndex(a => a.objectId === action.objectId);
            const survey = state.surveys[findIndex];
            state.survey.isBuildingMapped = true;
            if (state.assignments && state.assignments.length) {
                state.assignments.forEach(p => {
                    if (p.suveryingMomentDetails.objectId === action.objectId) {
                        p = { ...p, isBuildingMapped: true };
                    }
                    return p;
                })
            }
            return {
                ...state,
                surveys: Object.assign(...surveys, { [findIndex]: survey }),
                getSurveysError: null,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.MAP_BUILDING_TO_OBJECT_ERROR: {
            return {
                ...state,
                getSurveysError: action.error,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.GET_SURVEYS_MOMENTS_SUCCESS: {
            if (action.surveys && action.surveys.length) {
                action.surveys.forEach(p => {
                    p.internalStatusPlainText = internalSurveyingMomentStatus[p.internalStatus];
                    p.responsiblePersonPlainText = p.responsiblePerson ? p.responsiblePerson.relationName : '';
                    p.responsiblePersonCompanyPlainText = p.responsiblePerson ? p.responsiblePerson.name : '';
                    p.wbStatusPlainText = p.wbStatus ? t("general.agree") : p.wbStatus === false ? t("wkb.notAgreed") : t('wkb.notApplicableShort');
                    return p;
                });
            }
            return {
                ...state,
                surveys: action.surveys.sort((p, c) => p.object - c.object),
                getSurveysError: null,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.GET_SURVEYS_MOMENTS_ERROR: {
            return {
                ...state,
                surveys: [],
                getSurveysError: action.error,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.GET_SURVEYS_MOMENTS_LOADING: {
            return {
                ...state,
                surveys: action.refresh ? [] : state.surveys,
                selectedAssignment: action.refresh ? null : state.selectedAssignment,
                selectedMoment: action.refresh ? null : state.selectedMoment,
                getSurveysLoading: action.refresh,
            };
        }
        case surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_LOADING: {
            return {
                ...state,
                updateExecutorLoading: true
            };
        }
        case surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_SUCCESS: {
            return {
                ...state,
                updateExecutorLoading: false,
            };
        }
        case surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_ERROR: {
            return {
                ...state,
                updateExecutorLoading: false
            };
        }
        case surveyConstants.UPDATE_ASSIGNMENTS_LOCK_IDS: {
            return {
                ...state,
                assignmentLockIds: action.userLockIds
            };
        }
        case surveyConstants.UPDATE_ASSIGNMENT_LOCK: {
            if (action.isSurvey) {
                const ind = state.inspections.findIndex(p => p.surveyId === action.surveyId);
                if (ind >= 0) {
                    state.inspections[ind] = {
                        ...state.inspections[ind],
                        isCracked: false,
                        lockId: action.lockId
                    }
                    state.inspections = [...state.inspections];
                    if (action.lockId) {
                        if (state.assignmentLockIds)
                            state.assignmentLockIds = state.assignmentLockIds.concat({ userId: action.userId, lockId: action.lockId, isSecondSignature: action.isSecondSignature });
                        else state.assignmentLockIds = [{ userId: action.userId, lockId: action.lockId, isSecondSignature: action.isSecondSignature }]
                    } else {
                        state.assignmentLockIds = state.assignmentLockIds.filter(a => a.lockId !== action.oldLockId);
                    }
                    return {
                        ...state,
                    };
                }
            }
            if (state.assignments) {
                const ind = state.assignments.findIndex(a => a.assignmentId === action.assignmentId);
                if (ind >= 0) {
                    state.assignments[ind] = {
                        ...state.assignments[ind],
                        lockId: action.lockId,
                        isCracked: false
                    }
                    state.assignments = [...state.assignments];
                }
            }
            if (action.lockId) {
                if (state.assignmentLockIds)
                    state.assignmentLockIds = state.assignmentLockIds.concat({ userId: action.userId, lockId: action.lockId });
                else state.assignmentLockIds = [{ userId: action.userId, lockId: action.lockId }]
            } else {
                state.assignmentLockIds = state.assignmentLockIds.filter(a => a.lockId !== action.oldLockId);
            }
            return {
                ...state,
            };
        }
        case surveyConstants.GET_MOMENT_SUCCESS: {
            let controlPoints = action.moment.controlPoints ? action.moment.controlPoints.map(p => {
                if (p.assignments && p.assignments.length) {
                    p.assignments.forEach(assignment => {
                        assignment.assignmentAttachments = (assignment.assignmentAttachments && assignment.assignmentAttachments.length) ? assignment.assignmentAttachments.reduce((prevFiles, currentFile) => {
                            if (currentFile.name.split('.').pop() === "pdf") return [currentFile, ...prevFiles]
                            return [...prevFiles, currentFile];
                        }, []) : null;
                        return assignment;
                    })

                    return {
                        ...p,
                        internalStatusPlainText: internalAssignmentStatus[p.assignments[0].internalStatus],
                        internalStatusPlainNumber: p.assignments[0].internalStatus,
                        startDatePlainText: p.assignments[0].startDate,
                        endDatePlainText: p.assignments[0].endDate,
                        responsiblePlainText: p.assignments[0].responsiblePerson ? p.assignments[0].responsiblePerson.relationName : '',
                        assignedPersonPlainText: p.assignments[0].assignedPerson ? p.assignments[0].assignedPerson.relationName : '',
                        wbOordeePlainText: p.assignments[0].wbStatus,
                    }
                }
                return p
            }) : [];
            const { sortKey, sortType } = state;
            if (sortKey) {
                let data = controlPoints;
                if (sortKey === "name")
                    data = data && data.sort((a, b) => sortAlphaNumerically(a.description, b.description, sortType));
                else if (sortKey === "status")
                    data = data && data.sort((a, b) => sortAlphaNumerically(a.internalStatusPlainText, b.internalStatusPlainText, sortType));
                else if (sortKey.includes('Date'))
                    data = data && data.sort((a, b) => {
                        if (sortType === 'desc')
                            return new Date(a[sortKey + "PlainText"]) - new Date(b[sortKey + "PlainText"]);
                        return new Date(b[sortKey + "PlainText"]) - new Date(a[sortKey + "PlainText"]);
                    });
                else data = data && data.sort((a, b) => sortAlphaNumerically(a.serialNumber, b.serialNumber, sortType));
                controlPoints = data;
            }
            action.moment = {
                ...action.moment,
                controlPoints
            }
            return {
                ...state,
                selectedMoment: action.moment,
                getMomentError: null,
                getMomentLoading: false,
            };
        }
        case surveyConstants.GET_MOMENT_ERROR: {
            return {
                ...state,
                selectedMoment: null,
                getMomentError: action.error,
                getMomentLoading: false,
            };
        }
        case surveyConstants.GET_MOMENT_LOADING: {
            return {
                ...state,
                // selectedMoment: null,
                getMomentLoading: action.refresh,
            };
        }
        case surveyConstants.GET_ASSIGNMENT_SUCCESS: {
            if (action.assignment && action.assignment.assignmentAttachments && action.assignment.assignmentAttachments.length)
                action.assignment.assignmentAttachments = action.assignment.assignmentAttachments.reduce((prevFiles, currentFile) => {
                    if (currentFile.name.split('.').pop() === "pdf") return [currentFile, ...prevFiles]
                    return [...prevFiles, currentFile]
                }, [])
            return {
                ...state,
                getAssignmentLoading: false,
                getAssignmentError: null,
                selectedAssignment: action.assignment,
            };
        }
        case surveyConstants.GET_ASSIGNMENT_ERROR: {
            return {
                ...state,
                selectedAssignment: null,
                getAssignmentError: action.error,
                getAssignmentLoading: false,
            };
        }
        case surveyConstants.GET_ASSIGNMENT_LOADING: {
            return {
                ...state,
                selectedAssignment: action.refresh ? null : state.selectedAssignment,
                getAssignmentLoading: action.refresh,
            };
        }
        case surveyConstants.GET_PROJECTS_FOR_LINKING_SUCCESS: {
            return {
                ...state,
                projectsForLinking: action.data,
                getProjectsLikingError: null,
                getProjectsLikingLoading: false,
            };
        }
        case surveyConstants.GET_PROJECTS_FOR_LINKING_ERROR: {
            return {
                ...state,
                projectsForLinking: null,
                getProjectsLikingError: action.error,
                getProjectsLikingLoading: false,
            };
        }
        case surveyConstants.GET_PROJECTS_FOR_LINKING_LOADING: {
            return {
                ...state,
                projectsForLinking: null,
                getProjectsLikingLoading: true,
            };
        }
        case surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_SUCCESS: {
            const { attachmentId } = action;
            const index = state.selectedAssignment.assignmentAttachments.findIndex(p => p.id === attachmentId);
            if (index >= 0) {
                state.selectedAssignment.assignmentAttachments.splice(index, 1);
            }
            return {
                ...state,
                attachmentError: null,
                attachmentLoading: false,
            };
        }
        case surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_SUCCESS: {
            state.selectedAssignment.assignmentAttachments = state.selectedAssignment.assignmentAttachments.concat(action.attachments.uploadedAttachments).reduce(
                (prevFiles, currentFile) => {
                    if (currentFile.name.split('.').pop() === "pdf") return [currentFile, ...prevFiles]
                    return [...prevFiles, currentFile];
                }, []);
            return {
                ...state,
                attachmentError: null,
                attachmentLoading: false,
            };
        }
        case surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_LOADING: {
            return {
                ...state,
                attachmentError: null,
                attachmentLoading: true,
            };
        }
        case surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_ERROR: {
            return {
                ...state,
                attachmentError: action.error,
                attachmentLoading: false,
            };
        }
        case surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_ERROR: {
            return {
                ...state,
                attachmentError: action.error,
                attachmentLoading: false,
            };
        }
        case surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_LOADING: {
            return {
                ...state,
                attachmentLoading: true,
            };
        }
        case surveyConstants.GET_OBJECTS_LIST_SUCCESS: {
            return {
                ...state,
                objects: action.objects,
                getObjectListError: null,
                getObjectListLoading: false,
            };
        }
        case surveyConstants.GET_OBJECTS_LIST_ERROR: {
            return {
                ...state,
                getObjectListError: action.error,
                getObjectListLoading: false,
            };
        }
        case surveyConstants.GET_OBJECTS_LIST_LOADING: {
            return {
                ...state,
                getObjectListLoading: true,
            };
        }
        case surveyConstants.INTERNAL_SURVEYING_MOMENTS_SUCCESS: {
            state.surveys.forEach(p => {
                const isExist = action.data.find(a => a.surveyingMomentId === p.surveyingMomentId);
                if (isExist) {
                    p.internalStatus = 2;
                    p.internalStatusPlainText = internalSurveyingMomentStatus[p.internalStatus];
                }
                return p;
            })
            return {
                ...state,
                isMomentsSubmitError: null,
                isMomentsSubmitLoading: false,
            };
        }

        case surveyConstants.INTERNAL_SURVEYING_MOMENTS_ERROR: {
            return {
                ...state,
                isMomentsSubmitLoading: false,
                isMomentsSubmitError: action.error
            };
        }
        case surveyConstants.INTERNAL_SURVEYING_MOMENTS_LOADING: {
            return {
                ...state,
                isMomentsSubmitLoading: true,
                isMomentsSubmitError: null
            };
        }
        case surveyConstants.COMPLETE_SURVEYING_MOMENTS_SUCCESS: {
            state.surveys.forEach(p => {
                const isExist = action.data.find(a => a.surveyingMomentId === p.surveyingMomentId);
                if (isExist) {
                    const isFailure = action.list.failureList.find(a => a.surveyingMomentId === p.surveyingMomentId);
                    if (!isFailure) {
                        p.internalStatus = 2;
                        p.internalStatusPlainText = internalSurveyingMomentStatus[p.internalStatus];
                    }
                }
                return p;
            })
            return {
                ...state,
                completeList: action.list,
                isMomentsSubmitLoading: false,
                isMomentsSubmitError: null
            };
        }
        case surveyConstants.COMPLETE_SURVEYING_MOMENTS_ERROR: {
            return {
                ...state,
                completeList: null,
                isMomentsSubmitLoading: false,
                isMomentsSubmitError: action.error
            };
        }
        case surveyConstants.COMPLETE_SURVEYING_MOMENTS_LOADING: {
            return {
                ...state,
                isMomentsSubmitLoading: true,
                isMomentsSubmitError: null
            };
        }


        case surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_SUCCESS: {
            const { assignmentIds } = action;
            if (state.selectedAssignment) {
                const isExist = assignmentIds.find((assignmentId) => assignmentId === state.selectedAssignment.assignmentId);
                if (isExist) {
                    state.selectedAssignment = {
                        ...state.selectedAssignment,
                        internalStatus: 1
                    };
                }
            }
            let assignments = Object.assign([], state.assignments);
            const isExit = assignments.findIndex(a => assignmentIds.find((assignmentId) => assignmentId === a.assignmentId));
            if (isExit >= 0) {
                assignments = Object.assign(assignments, {
                    [isExit]: {
                        ...assignments[isExit],
                        internalStatus: 1
                    }
                });
            }
            return {
                ...state,
                assignments,
                isInternalCompleteAssignmentError: null,
                isInternalCompleteAssignmentLoading: false,
            };
        }
        case surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_ERROR: {
            console.log(action, "action")
            return {
                ...state,
                isInternalCompleteAssignmentError: action.error,
                isInternalCompleteAssignmentLoading: false,
            };
        }
        case surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_LOADING: {
            return {
                ...state,
                isInternalCompleteAssignmentError: null,
                isInternalCompleteAssignmentLoading: true,
            };
        }
        case surveyConstants.RESET_ASSIGNMENT_STATUS_ERROR: {
            return {
                ...state,
                resetAssignmentError: action.error,
                resetAssignmentLoading: false,
            };
        }
        case surveyConstants.RESET_ASSIGNMENT_STATUS_LOADING: {
            return {
                ...state,
                resetAssignmentError: null,
                resetAssignmentLoading: true,
            };
        }
        case surveyConstants.RESET_ASSIGNMENT_STATUS_SUCCESS: {
            const { assignmentIds } = action;
            if (state.selectedAssignment && assignmentIds.find(id => id === state.selectedAssignment.assignmentId)) {
                state.selectedAssignment.internalStatus = 0;
            }
            const assignments = state.assignments.map(a => {
                const isExist = assignmentIds.find(assignmentId => assignmentId === a.assignmentId)
                if (isExist) {
                    a.internalStatus = 0;
                };
                return a;
            });
            return {
                ...state,
                assignments,
                resetAssignmentError: null,
                resetAssignmentLoading: false,
            };
        }

        case surveyConstants.COMPLETE_ASSIGNMENT_SUCCESS: {
            const { assignmentIds } = action;
            let isCompleteAssignmentError = null;
            if (state.selectedAssignment) {
                const isExist = assignmentIds.find(assignmentId => assignmentId === state.selectedAssignment.assignmentId);
                const isFailure = action.data.failureList.find(p => p.assignmentId === state.selectedAssignment.assignmentId);
                if (isExist && !isFailure) {
                    state.selectedAssignment.internalStatus = 2;
                } else {
                    isCompleteAssignmentError = "wkb.assignment.notPossibleToComplete";
                }
            }
            const isExit = state.assignments.findIndex(a => assignmentIds.find(assignmentId => assignmentId === a.assignmentId));
            if (isExit >= 0) {
                const isFailure = action.data.failureList.find(f => f.assignmentId === state.assignments[isExit].assignmentId);
                if (!isFailure)
                    state.assignments[isExit].internalStatus = 2;
            }
            return {
                ...state,
                assignmentsComplationList: action.data,
                isCompleteAssignmentError,
                isCompleteAssignmentLoading: false,
            };
        }
        case surveyConstants.COMPLETE_ASSIGNMENT_ERROR: {
            return {
                ...state,
                isCompleteAssignmentError: action.error,
                isCompleteAssignmentLoading: false,
            };
        }
        case surveyConstants.COMPLETE_ASSIGNMENT_LOADING: {
            return {
                ...state,
                isCompleteAssignmentError: null,
                isCompleteAssignmentLoading: true,
            };
        }



        case surveyConstants.UPDATE_RESPONSIBLE_PERSON_LOADING: {
            return {
                ...state,
                isUpdatingPerson: true
            };
        }
        case surveyConstants.UPDATE_RESPONSIBLE_PERSON_ERROR: {
            return {
                ...state,
                isUpdatingPerson: false
            };
        }
        case surveyConstants.UPDATE_RESPONSIBLE_PERSON_SUCCESS: {
            return {
                ...state,
                isUpdatingPerson: false
            };
        }
        case surveyConstants.UPDATE_PERSON_FOR_MOMENT_LOADING: {
            return {
                ...state,
                isUpdatingPerson: true
            };
        }
        case surveyConstants.UPDATE_PERSON_FOR_MOMENT_ERROR: {
            return {
                ...state,
                isUpdatingPerson: false
            };
        }
        case surveyConstants.UPDATE_PERSON_FOR_MOMENT_SUCCESS: {
            if (action.isMulti) {
                state.surveys.forEach(p => {
                    const isExist = action.selected.find(id => id === p.surveyingMomentId)
                    if (isExist) p.responsiblePerson = action.data.data
                });
                return {
                    ...state,
                    isUpdatingPerson: false

                }
            }
            if (action.selected && action.selected.surveyMomentId) {
                const index = state.surveys.findIndex(p => p.surveyingMomentId === action.selected.surveyMomentId);
                if (index >= 0) {
                    state.surveys[index].responsiblePerson = action.data.data;
                }
            }
            return {
                ...state,
                selectedMoment: {
                    ...state.selectedMoment,
                    responsiblePerson: action.data.data
                },
                isUpdatingPerson: false
            };
        }
        case surveyConstants.UPDATE_RESPONSIBLE_PERSON_ASSIGNMENT_SUCCESS: {
            if (action.isMulti) {
                const isExist = state.assignments.findIndex(a => action.selected.find(id => id === a.assignmentId))
                if (isExist >= 0) {
                    state.assignments[isExist].assignedPerson = action.data.data;
                    state.assignments[isExist].assignedPersonPlainText = state.assignments[isExist].assignedPerson.relationName;
                }
                return {
                    ...state,
                    isUpdatingPerson: false
                }
            }
            if (action.selected && action.selected.assignmentId) {
                const ind = state.assignments.findIndex(p => p.assignmentId === action.selected.assignmentId)
                if (ind >= 0) {
                    state.assignments[ind].assignedPerson = action.data.data;
                    state.assignedPersonPlainText = action.data.data.relationName;
                }
                if (state.selectedAssignment) {
                    state.selectedAssignment.assignedPerson = action.data.data;
                }
            }
            return {
                ...state,
                isUpdatingPerson: false
            };
        }
        case surveyConstants.UPDATE_ASSIGNMENT_INFO_LOADING: {
            return {
                ...state,
                isUpdatingAssignment: true
            };
        }
        case surveyConstants.UPDATE_ASSIGNMENT_INFO_ERROR: {
            return {
                ...state,
                isUpdatingAssignment: false,
                assignmentUpdateError: action.error
            };
        }
        case surveyConstants.UPDATE_ASSIGNMENT_INFO_SUCCESS: {
            const { assignmentId, ...data } = action.data;
            const key = Object.keys(data)[0];
            let assignments = Object.assign([], state.assignments);
            let selectedAssignment = Object.assign({}, state.selectedAssignment);
            if (key === "message") {
                selectedAssignment = {
                    ...selectedAssignment,
                    assignmentMessages: [...selectedAssignment.assignmentMessages, { ...data, dateTime: new Date(), senderName: action.senderName }],
                }
            } else if (key === 'answer') {
                selectedAssignment = {
                    ...selectedAssignment,
                    answer: data.answer,
                };

                const index = assignments.findIndex(a => a.assignmentId === assignmentId);
                if (index >= 0)
                    assignments = Object.assign(assignments, { [index]: selectedAssignment })
            } else {
                selectedAssignment = {
                    ...selectedAssignment,
                    [key === 'comments' ? 'remark' : key]: data[key]
                }
                const index = assignments.findIndex(a => a.assignmentId === assignmentId);
                if (index >= 0)
                    assignments = Object.assign(assignments, {
                        [index]: selectedAssignment
                    });
            }
            return {
                ...state,
                assignments,
                selectedAssignment,
                isUpdatingAssignment: false
            };
        }
        case surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_ERROR: {
            return {
                ...state,
                getSettingsDataLoading: false,
                getSettingsDataError: action.error,
            };
        }
        case surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_SUCCESS: {
            return {
                ...state,
                getSettingsDataLoading: false,
                settingsData: action.data,
                getSettingsDataError: null,
            };
        }
        case surveyConstants.GET_WB_PROJECT_INFO_LOADING: {
            return {
                ...state,
                getWBProjectLoading: true,
                getWBProjectError: null,
                wbProjectInfo: null,
            };
        }
        case surveyConstants.GET_WB_PROJECT_INFO_SUCCESS: {
            return {
                ...state,
                getWBProjectLoading: false,
                getWBProjectError: null,
                wbProjectInfo: action.data,
            };
        }
        case surveyConstants.GET_WB_PROJECT_INFO_ERROR: {
            return {
                ...state,
                getWBProjectLoading: false,
                getWBProjectError: action.error,
                wbProjectInfo: null,
            };
        }
        case surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_LOADING: {
            return {
                ...state,
                getSettingsDataLoading: true,
                getSettingsDataError: null,
            };
        }
        case surveyConstants.SYNC_UPDATE_ERROR: {
            const isManual = action.isManual;
            if (isManual) return { ...state, isManualSync: false }
            return {
                ...state,
                isSyncing: false,
            };
        }
        case surveyConstants.SYNC_UPDATE_SUCCESS: {
            const isManual = action.isManual;
            if (isManual) return { ...state, isManualSync: false }
            return {
                ...state,
                isSyncing: false,
            };
        }
        case surveyConstants.SYNC_UPDATE_LOADING: {
            const isManual = action.isManual;
            if (isManual) return { ...state, isManualSync: true }
            return {
                ...state,
                isSyncing: true,
            };
        }
        case surveyConstants.GET_INSPECTIONS_SUCCESS: {
            const inspections = action.data ? action.data.map(p => {
                return {
                    ...p,
                    internalStatusPlainText: SurveyStatus[p.status],
                }
            }) : [];
            return {
                ...state,
                pageMetaData: action.pageMetaData,
                getSurveysError: null,
                inspections,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.GET_INSPECTIONS_ERROR: {
            return {
                ...state,
                getSurveysError: action.error,
                getSurveysLoading: false,
            };
        }
        case surveyConstants.GET_INSPECTIONS_LOADING: {
            return {
                ...state,
                getSurveysError: null,
                inspections: [],
                getSurveysLoading: true,
            };
        }
        case surveyConstants.GET_INSPECTION_DETAIL_SUCCESS: {
            return {
                ...state,
                inspectionError: null,
                selectedInspection: action.data,
                inspectionLoading: false,
            };
        }
        case surveyConstants.GET_INSPECTION_DETAIL_ERROR: {
            return {
                ...state,
                inspectionError: action.error,
                inspectionLoading: false,
            };
        }
        case surveyConstants.GET_INSPECTION_DETAIL_LOADING: {
            return {
                ...state,
                inspectionError: null,
                inspectionLoading: true,
            };
        }
        case surveyConstants.GET_REPAIR_REQUEST_LOADING: {
            return {
                ...state,
                repairRequests: [],
                repairRequestsError: null,
                loadingRepairRequests: true,
            };
        }
        case surveyConstants.GET_REPAIR_REQUEST_SUCCESS: {
            action.data && action.data.length && action.data.forEach((request, index) => {
                if (!request.textResolvers) request.textResolvers = [];
                if (request.resolvers && request.resolvers.length)
                    request.textResolvers = request.resolvers.map((x) => x.name);
            });
            return {
                ...state,
                repairRequests: action.data,
                repairRequestsError: null,
                loadingRepairRequests: false,
            };
        }
        case surveyConstants.GET_REPAIR_REQUEST_ERROR: {
            return {
                ...state,
                repairRequests: [],
                repairRequestsError: action.error,
                loadingRepairRequests: false,
            };
        }
        case surveyConstants.UPDATE_SURVEY_SUCCESS: {
            const data = action.data;
            const surveyIndex = state.inspections.findIndex(p => p.surveyId === data.surveyId);
            if (surveyIndex >= 0) {
                state.inspections[surveyIndex] = { ...state.inspections[surveyIndex], ...data };
                state.selectedInspection = { ...state.selectedInspection, ...data }
            }
            return {
                ...state,
                isSurveyUpdating: false,
                isSurveyUpdateError: null,
            };
        }
        case surveyConstants.UPDATE_SURVEY_LOADING: {
            return {
                ...state,
                isSurveyUpdating: true,
                isSurveyUpdateError: null,
            };
        }
        case surveyConstants.UPDATE_SURVEY_ERROR: {
            return {
                ...state,
                isSurveyUpdating: false,
                isSurveyUpdateError: action.error,
            };
        }
        case surveyConstants.SENDING_EMAILS_SUCCESS: {
            const data = action.data;
            const surveyIndex = state.inspections.findIndex(p => p.surveyId === action.surveyId);
            if (surveyIndex >= 0) {
                state.inspections[surveyIndex] = { ...state.inspections[surveyIndex], status: data.surveryStatus };
                state.selectedInspection = { ...state.selectedInspection, status: data.surveryStatus }
            }
            return {
                ...state,
                canSendMailSuccessfully: false,
                canSendMailError: null
            };
        }
        case surveyConstants.SENDING_EMAILS_LOADING: {
            return {
                ...state,
                canSendMailSuccessfully: true,
                canSendMailError: null
            };
        }
        case surveyConstants.SENDING_EMAILS_ERROR: {
            return {
                ...state,
                canSendMailSuccessfully: false,
                canSendMailError: action.error
            };
        }
        case surveyConstants.UPDATE_MOMENT_LAST_VIEW: {
            const { assignmentId, surveyingMomentId } = action;

            if (assignmentId && state.selectedMoment) {
                if (state.selectedAssignment) state.selectedAssignment = { ...state.selectedAssignment, hasUpdate: false }
                state.selectedMoment.controlPoints.forEach(controlPont => {
                    const index = controlPont.assignments.findIndex(p => p.assignmentId === assignmentId);
                    if (index >= 0) {
                        controlPont.assignments[index].hasUpdate = false;
                    }
                    return controlPont;
                });
            };

            if (state.selectedMoment && state.selectedMoment.controlPoints) {
                const hasUpdate = state.selectedMoment.controlPoints.some(controlPont => controlPont.assignments.some(p => p.hasUpdate === false) === false);

                state.surveys.forEach(p => {
                    if (p.surveyingMomentId === surveyingMomentId) {
                        p.hasUpdate = hasUpdate;
                    }
                    return p;
                });
            }

            return {
                ...state,
            };
        }
        case surveyConstants.ADD_SURVEY_LOADING: {
            return {
                ...state,
                addSurvey: true,
                addSurveyError: null,
            };
        }
        case surveyConstants.ADD_SURVEY_ERROR: {
            return {
                ...state,
                addSurvey: false,
                addSurveyError: action.error,
            };
        }
        case surveyConstants.ADD_SURVEY_SUCCESS: {
            const inspections = state.inspections.concat(action.data);
            return {
                ...state,
                inspections,
                addSurvey: false,
                addSurveyError: null,
            };
        }
        case surveyConstants.GET_ASSIGNMENTS_LOADING: {
            return {
                ...state,
                loadingAssignments: true,
                getAssignmentError: null,
                assignments: []
            };
        }
        case surveyConstants.GET_ASSIGNMENTS_SUCCESS: {
            return {
                ...state,
                loadingAssignments: false,
                getAssignmentError: null,
                assignments: action.assignments.map(a => ({
                    ...a,
                    moment: shortenSurveyingMomentTitle(a.surveyingMomentDetails.name),
                    object: a.surveyingMomentDetails.object,
                    surveyingMomentId: a.surveyingMomentDetails.surveyingMomentId,
                    objectId: a.surveyingMomentDetails.objectId
                }))
            };
        }
        case surveyConstants.GET_ASSIGNMENTS_ERROR: {
            return {
                ...state,
                loadingAssignments: false,
                getAssignmentError: action.error,
                assignments: []
            };
        }
        case surveyConstants.STORE_SURVEY_RECORDS_COUNTS: {
            return {
                ...state,
                surveyModuleCounts: action.allModulesCounts
            };
        }
        default:
            return state
    }
};
