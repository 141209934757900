import React, { useState } from "react";
import {
  Checkbox,
  Grid,
  Tooltip,
  useMediaQuery,
  Dialog,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { DialogTitle } from "@mui/material";
import { IconButton } from "@mui/material";
import {
  Close,
  ControlPoint,
  HighlightOff,
  PriorityHigh,
} from "@mui/icons-material";
import { DialogContent } from "@mui/material";
import { Slide } from "@mui/material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { formatDate } from "../../../_helpers";

const { webApiUrl } = window.appConfig;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImagesContainer({
  data = [],
  showFileTitle = false,
  rootClass = "",
  onSelectFiles = () => {},
  containerClass = "",
  isImagesContainer,
  onPreview = () => {},
  open,
  onClose = () => {},
  drawingPDFThumbnail = false,
  hideTitle = false,
  hideCheckBox = false,
  handleView,
  ...props
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isImagesContainer, isSmallScreen, hideTitle });
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSelect = (file) => {
    const files = Object.assign([], selectedFiles);
    const index = selectedFiles.findIndex(
      (p) => p.attachmentId === file.attachmentId
    );
    index >= 0 ? files.splice(index, 1) : files.push(file);
    setSelectedFiles(files);
  };

  return (
    <Dialog
      className={clsx(classes.dialogRoot, rootClass)}
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth="sm"
      TransitionComponent={Transition}
      open={open}
      onClose={() => {
        setSelectedFiles([]);
        onClose();
      }}
      {...props}
    >
      {!hideTitle ? (
        <DialogTitle className={classes.dialogTitle} id="dialog-objects-title">
          <Grid className={classes.dialogTitleContent} container spacing={1}>
            <Grid item className={classes.grow}>
              <Typography variant="h5" className={classes.dialogTitleTypo}>
                {t("general.image")}
              </Typography>
            </Grid>
            <Grid>
              <Tooltip title={t("wkb.assignment.addToEvidence.lable")}>
                <IconButton
                  className={classes.titleButtons}
                  disabled={!selectedFiles.length}
                  color="inherit"
                  aria-label="close"
                  onClick={() => {
                    onSelectFiles(selectedFiles);
                    setSelectedFiles([]);
                    onClose();
                  }}
                  size="large"
                >
                  <ControlPoint className={classes.plushIconStyle} />
                </IconButton>
              </Tooltip>
              <IconButton
                className={classes.titleButtons}
                color="inherit"
                aria-label="close"
                onClick={onClose}
                size="large"
              >
                <Close className={classes.iconStyle} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      ) : (
        <IconButton
          className={classes.titleButtonsClose}
          aria-label="close"
          color="error"
          size="small"
          variant="outlined"
          onClick={onClose}
        >
          <HighlightOff color={"error"} size="small" />
        </IconButton>
      )}
      <DialogContent className={clsx(classes.dialogContent, containerClass)}>
        <Grid item xs={12} className={classes.filesContainer}>
          <Grid container className={classes.gridContainer}>
            {data.map((image, i) => (
              <Grid
                key={image.attachmentId}
                className={classes.attachmentItemContainer}
                item
                onClick={onPreview}
              >
                {!hideCheckBox && (
                  <div className={classes.overlay}>
                    <div className={classes.isLeft}>
                      <Tooltip title={t("files.button.select.title")}>
                        <Checkbox
                          disabled={props.isReadOnly}
                          checked={
                            selectedFiles &&
                            selectedFiles.find(
                              (p) => p.attachmentId === image.attachmentId
                            )
                          }
                          onChange={() => handleSelect(image)}
                          onClick={(e) => e.stopPropagation()}
                          color="primary"
                          style={{ padding: 0 }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
                <Grid item>
                  <Grid
                    className={classes.thumnailContainer}
                    onClick={() => {
                      if (image.hasUpdates) handleView(image);
                    }}
                    onMouseEnter={() => {
                      if (image.hasUpdates) handleView(image);
                    }}
                    container
                    xs={12}
                    justifyContent="center"
                  >
                    {image.hasUpdates && (
                      <Tooltip title={t("dossier.sidebar.priority.high")}>
                        <PriorityHigh
                          color="error"
                          style={{ position: "absolute", left: -4, top: 3 }}
                        />
                      </Tooltip>
                    )}
                    <div
                      className={classes.thumbnail}
                      style={{
                        backgroundImage: `url(${`${webApiUrl}${
                          drawingPDFThumbnail
                            ? `api/Dossier/GetFileThumbnail/${image.fileId}`
                            : `api/RepairRequest/GetImage/${image.requestId}/${image.attachmentId}`
                        }`})`,
                      }}
                    />
                    {showFileTitle && (
                      <>
                        <Typography
                          variant="caption"
                          className={classes.caption}
                          noWrap
                        >
                          {image.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          className={classes.caption}
                          noWrap
                        >
                          {formatDate(new Date(image.lastModifiedOn))}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    marginTop: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer && isSmallScreen && "18%",
    "& > .MuiDialog-container > div:nth-child(1)": {
      borderRadius: ({ isImagesContainer, isSmallScreen }) =>
        isImagesContainer && isSmallScreen ? "16px 16px 0 0" : 10,
    },
    padding: 10,
  },
  caption: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width: 308,
    borderRadius: 7,
  },
  attachmentItemContainer: {
    position: "relative",
    background: theme.palette.common.white,
    boxShadow: "3px 3px 4px #eee",
    height: 87,
    overflow: "hidden",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      height: 76,
      width: 76,
    },
    // '&:hover': {
    //     "& $overlay": {
    //         opacity: 1
    //     },
    //     "& $attachmentStatus": {
    //         opacity: 1,
    //         right: 10
    //     },
    // },
  },
  overlay: {
    position: "absolute",
    borderRadius: 5,
    color: " #f1f1f1",
    transition: ".5s ease",
    // opacity: 0,
    fontSize: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 87,
    [theme.breakpoints.down("sm")]: {
      height: 76,
      width: 76,
    },
    zIndex: 1,
    top: 0,
    left: 0,
  },
  isLeft: {
    position: "absolute",
    left: 3,
    top: 2,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumbnail: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    display: "block",
  },
  thumnailContainer: {
    width: 87,
    height: 87,
    cursor: "pointer",
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  filesContainer: {
    overflow: "auto",
    padding: theme.spacing(1.25, 0),
  },
  gridContainer: {
    justifyContent: "space-around",
    gridTemplateColumns: "repeat(auto-fill, 87px)",
    display: "grid",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, 76px)",
    },
  },
  dialogContent: {
    padding: ({ hideTitle }) => theme.spacing(hideTitle ? 3 : 0),
    display: "flex",
    minHeight: "50vh",
  },
  dialogTitle: {
    padding: 5,
    background: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer && isSmallScreen ? "none" : theme.palette.primary.main,
    borderBottom: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer &&
      isSmallScreen &&
      `1px solid ${theme.palette.grey[100]}`,
  },
  dialogTitleTypo: {
    color: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer && isSmallScreen
        ? theme.palette.grey[700]
        : theme.palette.common.white,
    fontSize: 12,
    paddingLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  dialogTitleContent: {},
  iconStyle: {
    fill: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer && isSmallScreen
        ? theme.palette.grey[300]
        : theme.palette.common.white,
    width: "1em",
    height: "1em",
  },
  plushIconStyle: {
    fill: ({ isImagesContainer, isSmallScreen }) =>
      isImagesContainer && isSmallScreen
        ? theme.palette.grey[700]
        : theme.palette.common.white,
    width: "1em",
    height: "1em",
  },
  titleButtons: {
    margin: 0,
    "&:focus": {
      outline: "none !important",
    },
  },
  titleButtonsClose: {
    width: "fit-content",
    position: "absolute",
    top: 4,
    right: 5,
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
}));
